import PropTypes from 'prop-types';
import React from 'react';

import {Button} from '@salesforce/design-system-react';
import {Input} from '@salesforce/design-system-react';
import {Textarea} from '@salesforce/design-system-react';

import CampaignDetailsStateRef from 'apps/maps/state/campaign-details-state-ref';
import FormHandler from 'formlib/form-handler';
import ReactComponent from 'utils/react-component';
import {connectToComponentState} from 'statelib/fstate-react';


function CampaignDetailsOverviewView({
  async,
  campaign,
  fields,
  handleFieldChangeDescription,
  handleFieldChangeName,
  handleSubmit,
}) {
  return (
    <React.Fragment>
      {/* if */(async.isSuccess) ? (
        <form onSubmit={handleSubmit}>
          <Input
            label='Campaign Name'
            styleInput={{fontSize: '1.5rem'}}
            minLength='1'
            maxLength='50'
            type='text'
            value={fields.name.value}
            onChange={handleFieldChangeName}
          />
          <Textarea
            label='Description'
            maxLength='200'
            value={fields.description.value}
            onChange={handleFieldChangeDescription}
          />
          <Button
            label='Save'
            variant='brand'
            type='submit'
          />
        </form>
      )/* else */: (
        <p>Loading...</p>
      )/* endif */}
    </React.Fragment>
  );
}


export default class CampaignDetailsOverview extends ReactComponent {

  static propTypes = {
    campaignContainer: PropTypes.instanceOf(CampaignDetailsStateRef).isRequired,
  };

  constructor(props) {
    super(props);
    this.campaignContainer = this.constantProp('campaignContainer');
    this.campaignForm = new FormHandler(this.$b._campaignDetailsFormRefresh);
    this.campaignForm.initializeData(this.campaignContainer.campaign);
    connectToComponentState(this.campaignContainer, this, 'campaign');
    this.addCleanup(
      this.campaignForm.connectFieldsToComponentState(this, 'fields'),
      this.campaignForm.onSubmit(this.$b._campaignDetailsFormSubmit),
      this.campaignForm.$b.close,
    );
  }

  _campaignDetailsFormRefresh(campaign) {
    return {
      name: campaign.name || '',
      description: campaign.description || '',
    };
  }

  _handleFieldChangeName(event, data) {
    this.campaignForm.setFormFieldAndData('name', data.value);
  }

  _handleFieldChangeDescription(event) {
    // Text areas don't had a data parameter like other events
    this.campaignForm.setFormFieldAndData('description', event.target.value);
  }

  async _campaignDetailsFormSubmit(unusedData) {
    const campaignData = this.campaignForm.getData();
    await this.campaignContainer.patchCampaign(campaignData, true);
    this.campaignForm.initializeData(this.campaignContainer.campaign);
  }

  render() {
    return CampaignDetailsOverviewView({
      async: this.state.campaign.async,
      campaign: this.state.campaign.campaign,
      fields: this.state.fields,
      handleFieldChangeDescription: this.$b._handleFieldChangeDescription,
      handleFieldChangeName: this.$b._handleFieldChangeName,
      handleSubmit: this.campaignForm.$b.handleFormSubmitEvent,
    });
  }

}
