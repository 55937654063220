import memoizeOne from "memoize-one";

import FState from "statelib/fstate";
import GameObject from "./game-object";

// TODO: +++++++++++++++++++++
// TODO:
// TODO: THIS IS WRONG (but only a little bit)
// It needs ot track the focused / selected GAME objects, NOT map objects.


/**
 * State for maintaining the current selected and focused map objects.
 *
 * Multiple map objects can be selected at any point in time, but only a single
 * one can be focused.
 *
 * Note that the values of
 *
 * IDEA: Potentially add a canvasEngine dependency here.
 */
export default class GameObjectSelectionFocusState extends FState {

  static DEFAULT_NAME = 'GameObjectSelectionFocus';

  constructor(options: {name?: string} = {}) {
    super(options.name);
  }

  makeInitialData() {
    return {
      focusedGameObjectId: null,
      focusedGameObject: null,
      selectedGameObjects: {},
    };
  }

  getFocusedGameObjectId(): string | null {
    return this.data.focusedGameObjectId;
  }

  getFocusedGameObject(): GameObject | null {
    return this.data.focusedGameObject;
  }

  setFocusedGameObject(gameObject: GameObject | null): void {
    if (!gameObject) {
      if (this.data.focusedGameObjectId) {
        this.clearFocusedGameObject();
      }
      return;
    }
    if (!gameObject.id) {
      throw new Error("Game objects must have an id");
    }
    if (this.data.focusedGameObjectId !== gameObject.id) {
      this.patchData({
        focusedGameObjectId: gameObject.id,
        focusedGameObject: gameObject,
      });
    }
  }

  clearFocusedGameObject(): void {
    if (this.data.focusedGameObjectId) {
      this.patchData({
        focusedGameObjectId: null,
        focusedGameObject: null,
      });
    }
    this.setValueIfChanged("focusedGameObjectId", null);
  }

  getSelectedGameObjectIds(): Set<string> {
    return this._getSelectedGameObjectIdsMemo(this.data.selectedGameObjects);
  }

  private _getSelectedGameObjectIdsMemo = memoizeOne((selectedGameObjects): Set<string> => {
    if (!selectedGameObjects) {
      return new Set();
    }
    return new Set(Object.keys(selectedGameObjects));
  });

  clearSelection(): void {
    if (this.getSelectedGameObjectIds().size) {
      this.setValue("selectedGameObjectIds", {});
    }
  }

  addGameObjectToSelection(gameObject: GameObject): void {
    if (!gameObject.id) {
      throw new Error("Game objects must have an id");
    }
    if (!this.data.selectedGameObjects[gameObject.id]) {
      this.setValue("selectedGameObjects", {
        ...this.data.selectedGameObjects,
        [gameObject.id]: gameObject,
      });
    }
  }

  removeGameObjectFromSelection(gameObject: GameObject): void {
    if (!gameObject.id) {
      throw new Error("Game objects must have an id");
    }
    if (this.data.selectedGameObjects[gameObject.id]) {
      const nextSelectedGameObjects = {...this.data.selectedGameObjects};
      delete nextSelectedGameObjects[gameObject.id];
      this.setValue("selectedGameObjects", nextSelectedGameObjects);
    }
  }

}
