import Shapes from 'utils/geometry/shapes';
import Squares from 'utils/squares';

import BaseMaskPainterObject from 'components/mapview/map-objects/base-mask-painter-object';
import ToolUtils from 'components/mapview/map-objects/tool-utils';
import UIRectRenderable from 'components/mapview/map-objects/ui-rect-renderable';


/**
 * Map Object that can handle painting single cell areas onto visibility masks.
 */
export default class MaskPainterGridCell extends BaseMaskPainterObject {

  constructor() {
    super();
    this._isPainting = false;
    this._paintOnce = false;
    this._col = null;
    this._row = null;
    this._cellSquare = null;
    this._uiRect = new UIRectRenderable();
  }

  isVisible() {
    return this._cellSquare && super.isVisible();
  }

  handleClickTap() {
    this._paintOnce = true;
    // Forces onUpdate to do something even if the cursor hasn't moved
    this._col = null;
    this._row = null;
  }

  handleDragBegin() {
    this._isPainting = true;
    // Forces onUpdate to do something even if the cursor hasn't moved
    this._col = null;
    this._row = null;
  }

  handleDragEnd() {
    this._isPainting = false;
    this._paintOnce = false;
  }

  onUpdate(timestamp, timeDeltaMs, frameNo, render, layer) {
    super.onUpdate(timestamp, timeDeltaMs, frameNo, render, layer);
    const controller = this._mapViewController;
    const isPainting = this._isPainting || this._paintOnce;
    this._paintOnce = false;
    const [col, row] = ToolUtils.getCursorCellColRow(controller, this.grid);
    // Update the internal state if something has actually changed
    if (this._col === col && this._row === row) {
      return;
    }
    this._col = col;
    this._row = row;
    layer.setRedraw(this._uiRect.getRedrawRegion(render, layer));  // Erase previous
    this._cellSquare = null;
    if (this._col !== null && this._row !== null) {
      this._cellSquare = Shapes.GridCell.instance(this.grid, col, row).containingSquare;
    }
    this._uiRect.rect = this._cellSquare;
    layer.setRedraw(this._uiRect.getRedrawRegion(render, layer));
    // Paint the mask if there is something to paint
    if (!isPainting || !this._cellSquare) {
      return;
    }
    if (Squares.hasIntersection(this._cellSquare, controller.getImageSquare())) {
      this.updateMask((mask, context, erase) => {
        const cellSq = mask.gridCellToMaskSq(col, row);
        if (erase) {
          context.clearRect(cellSq.x, cellSq.y, cellSq.w, cellSq.h);
        } else {
          context.fillStyle = this.maskColor;
          context.clearRect(cellSq.x, cellSq.y, cellSq.w, cellSq.h);
          context.fillRect(cellSq.x, cellSq.y, cellSq.w, cellSq.h);
        }
        return cellSq;
      }, mask => mask.gridCellToMaskSq(col, row));
    }
  }

  onRender(context, layer, render) {
    this._uiRect.render(context, layer, render);
  }

}
