import React from 'react';
import classNames from 'classnames';
import Memoize from 'memoize-one';

import canvasengine from 'canvasengine';
import AppGlobal from 'global';
import AppReactComponent from 'utils/app-react-component';
import Squares from 'utils/squares';
import {shallowEqual} from 'utils/types';

import DialogBox from 'components/layouts/dialog-box';
import EmbarkMenu from 'apps/maps/components/embark-menu';
import EmbarkToolsState from 'apps/maps/state/embark-tools';
import EmbarkUserMenu from 'apps/maps/components/embark-user-menu';
import MapTokenManager from 'components/mapview/map-objects/map-token-manager';
import MapViewInteractive from 'components/mapview/map-view-interactive';
import MapZoneSelectDialog from 'apps/maps/components/embark-map-zone-select-dialog';
import MaskPainterGridCell from 'components/mapview/map-objects/mask-painter-grid-cell';
import MaskPainterRect from 'components/mapview/map-objects/mask-painter-rect';
import MultiAsyncState from 'statelib/multi-async-state';
import PositionedBox from 'components/layouts/positioned-box';
import ProgressBar from 'components/basic/progress-bar';
import TokenAddEditDialog from 'components/tokens/token-add-edit-dialog';
import TokenInterfaceManager from 'components/mapview/map-objects/token-interface-manager';
import tokenscmp from 'components/tokens';
import ToolboxLayout from 'components/layouts/toolbox-layout';
import ZoneFogOfWar from 'components/mapview/map-objects/zone-fog-of-war';
import ZoneGrid from 'components/mapview/map-objects/zone-grid';

import EmbarkNavBar from './embark-nav-bar';
import EmbarkToolbar from './embark-toolbar/embark-toolbar';

const _MAP_VIEW_INTERACTIVE_STYLE = Object.freeze({
  // TODO: Might need slight overscan
  left: '0',
  top: '0',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  position: 'absolute',
});

const _TOOLBOX_LAYOUT_STYLE = Object.freeze(
  {'height': '100%'}
);

const _VIEW_DEBUG_STYLE = Object.freeze({
  width: '150px',
  fontSize: '12px',
  background: '#FFF9',
});

const _TOKEN_TRAY_SIDEBAR_STYLE = Object.freeze({
  width: 'var(--size--block-small)',
  height: '85%',  // TODO: Calc based on vh?
  maxWidth: '50%',
})

const _EMPTY_PERMISSIONS = Object.freeze({});


export default class EmbarkMapInterface extends AppReactComponent {

  static whyDidYouRender = {
    logOnDifferentValues: true,
  }

  constructor(props) {
    super(props);
    // `isLoading` defines whether we're in the initial load phase (which occurs
    // when the page first loads or when the user changes maps/zones) or not.
    // The page reacts differently to the async state differently depednding on
    // whether we're loading.
    this.state = {
      permissions: _EMPTY_PERMISSIONS,
      isLoading: true,
    }
    this._embarkTools = new EmbarkToolsState();  // TODO: Should be global
    this._curMapViewController = null;
    this._curMapViewZoneId = null;
    this._zoneGrid = new ZoneGrid(AppGlobal.mapZoneDetails);
    this._zoneGrid.color = 'rgba(0, 0, 0, 0.3)';
    this._zoneGrid.glowColor = 'rgba(220, 220, 220, 0.3)';
    this._zoneGrid.enabled = this._embarkTools.showGrid;
    this._fogOfWar = new ZoneFogOfWar();
    this._maskPainterGridCell = new MaskPainterGridCell();
    this._maskPainterRect = new MaskPainterRect();
    this._tokenManager = new MapTokenManager(
      AppGlobal.mapZoneDetails, AppGlobal.mapObjectStore
    );
    this._mapPositionStorer = new canvasengine.ScriptGameObject(
      this.$b._checkStoreCurrentMapPosition
    );
    this._multiAsync = new MultiAsyncState([
      AppGlobal.campaignDetails.getAttachedState('async'),
      AppGlobal.mapDetails.getAttachedState('async'),
      AppGlobal.mapZoneDetails.getAttachedState('async'),
      AppGlobal.mapView.getAttachedState('async'),
      AppGlobal.mapObjectStore.getAttachedState('async'),
      AppGlobal.imageStore.getAttachedState('async'),
    ]);
    this.connect('editTokenRef', AppGlobal.editTokenRef);
    this.connect('campaignDetails', AppGlobal.campaignDetails);
    this.connect('mapDetails', AppGlobal.mapDetails);
    this.connect('mapZoneDetails', AppGlobal.mapZoneDetails);
    this.connect('mapView', AppGlobal.mapView);
    this.connect('tool', this._embarkTools, 'tool');
    this.connect('async', this._multiAsync);
    this.connect('embark', this._embarkTools);
    this.addClosableSetup(
      () => this._multiAsync.subscribe(this.$b.watchForSteadyStateErrors),
      () => this._multiAsync.subscribe(this.$b.refreshIsLoading),
      () => AppGlobal.rootRouter.subscribe(this.$b.refreshIsLoading),
      () => AppGlobal.campaignDetails.subscribe(this.$b.refreshPermissions),
      () => AppGlobal.mapDetails.subscribe(this.$b.refreshPermissions),
      () => AppGlobal.mapZoneDetails.subscribe(this.$b.refreshPermissions),
      () => AppGlobal.mapView.onData(this.$b.handleUpdatedMapView),
      () => AppGlobal.mapZoneDetails.subscribe(this.$b.handleUpdatedMapZone),
      () => this._embarkTools.subscribe(this.$b.handleUpdatedToolState),
      () => FState.subscribeMany(
        [
          {state: AppGlobal.mapZoneDetails, path: '.'},
          {state: this._embarkTools, path: 'tool'},
        ],
        this.$b._refreshToolComponents,
      ),
    );
  }

  get permissions() {
    return this.state.permissions;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    // Check for errors during initial load and bubble them up the component
    // stack. This will result in a full-screen error and possible an
    // unauthorized redirect. Only do this during main load because normal
    // usage may occasionally result in errors that should not be so
    // disruptive.
    if (this.state.isLoading) {
      this._multiAsync.checkThrowError();
    }
  }

  /**
   * Handles any errors during the "steady state" (normaly usage) of the page.
   * These will typically be handled via some sort of toast/unobtrusive popup.
   */
  watchForSteadyStateErrors(data) {
    if (this.state.isLoading) {
      return;
    }
    // Run in a `while`instead of `if` to clear out all of the errors.
    while (data.isFailed) {
      const {message, error} = this._multiAsync.receiveError();
      console.error(`Embark Async Error: ${message}`, error);
    }
  }

  refreshIsLoading() {
    const state = this.state;
    const routerParams = AppGlobal.rootRouter.data.params;
    const curCampaign = state.campaignDetails.campaign;
    const curMap = state.mapDetails.map;
    const curMapZone = state.mapZoneDetails.mapZone;
    const requestedCampaignId = routerParams.campaignId || null;
    const requestedMapId = routerParams.mapId || null;
    const requestedMapZoneId = routerParams.zoneId || null;
    const loadedCampaignId = curCampaign ? (curCampaign.id || null) : null;
    const loadedMapId = curMap ? (curMap.id || null) : null;
    const loadedMapZoneId = curMapZone ? (curMapZone.id || null) : null;
    if (
      requestedCampaignId !== loadedCampaignId  ||
      requestedMapId !== loadedMapId ||
      requestedMapZoneId !== loadedMapZoneId
    ) {
      // TODO: We need to make sure that some things display even if
      // we are loading. For example, the "no map found" or "select a zone"
      // dialogs need to happen no matter what.

      // We don't have to wait for map object store since MapZoneDetailsRef
      // awaits `loadObjectsForZone` as part of its internal load.
      this.setState({isLoading: true});
    } else if (state.async.isSuccess) {
      this.setState({isLoading: false});
    }
    // Otherwise, `isLoading` should remain unchanged.
  }

  handleUpdatedMapZone(data) {
    if (data.async.isRunning) {
      return;
    }
    this.refreshMapViewZone();
    this.refreshVisibleBoundary();
  }

  handleUpdatedMapView(newData) {
    const controller = newData.controller;
    if (!controller) {
      return;
    }
    // Check for things that should happen only with a NEW controller
    if (controller !== this._curMapViewController) {
      this.initializeMapViewRenderPipeline();
      this._curMapViewController = controller;
    }
    // Handle anything else that happens each time the controller updates
    this.refreshMapViewZone();
    this.refreshVisibleBoundary();
  }

  handleUpdatedToolState() {
    this.refreshPermissions();
    this.refreshVisibleBoundary();
    this._zoneGrid.enabled = this._embarkTools.showGrid;
    if (this._curMapViewController) {
      this._curMapViewController.fullMapRerender();
    }
  }

  refreshPermissions() {
    const asPlayer = this._embarkTools.isImpersonatingPlayer;
    const nextPermissions = Object.freeze({
      ...AppGlobal.campaignDetails.getPermissions(asPlayer),
      ...AppGlobal.mapDetails.getPermissions(asPlayer),
      ...AppGlobal.mapZoneDetails.getPermissions(asPlayer),
    });
    if (!shallowEqual(this.state.permissions, nextPermissions)) {
      console.info('Permissions updated', nextPermissions);
      this._fogOfWar.privilegedAccess = nextPermissions.CAN_VIEW_FULL_MAP;
      this.setState({permissions: nextPermissions}, this.$b.refreshVisibleBoundary);
      if (this._curMapViewController) {
        this._curMapViewController.fullMapRerender();
      }
    }
  }

  /**
   * Updates the map view with a new map (zone)
   */
  refreshMapViewZone() {
    const controller = AppGlobal.mapView.controller;
    const zone = AppGlobal.mapZoneDetails.mapZone;
    if (!controller) {
      console.debug('embark: Map controller not ready');
      return;
    } else if (!zone) {
      console.debug('embark: Map zone not ready');
      return;
    } else if (this._curMapViewZoneId === zone.id) {
      console.debug('embark: Map controller already showing this zone');
      return;
    } else if (!zone.backgroundImage || !zone.backgroundImage.id) {
      throw new Error('Zone has not been fully configured (no image)');
    } else {
      const bgImage = zone.backgroundImage;
      this._curMapViewZoneId = zone.id;
      const setImagePromsie = AppGlobal.mapView.setImageAsync(
        bgImage.url, bgImage.width, bgImage.height
      );
      // Attempt to restore the users view if possible.
      const lastPositionStr = localStorage.getItem(
        `embark.viewPosition.${this._curMapViewZoneId}`,
      );
      if (lastPositionStr) {
        setImagePromsie.then(() => {
          try {
            const {viewX, viewY, zoom} = JSON.parse(lastPositionStr);
            AppGlobal.mapView.controller.updateView(viewX, viewY, zoom);
          } catch (err) {
            console.warn("Error restoring position", err);
          }
        });
      }
    }
  }

  /**
   * Updates the area that is considered "accessible" to the running user.
   *
   * For GMs (or anyone with `CAN_VIEW_FULL_MAP`), this means anything inside of
   * the bounds of the zone. For everyone else, it means only areas that are
   * visible or have been explored.
   */
  refreshVisibleBoundary() {
    const controller = AppGlobal.mapView.controller;
    const zone = AppGlobal.mapZoneDetails.mapZone;
    if (
      !controller
      || !controller.getImageSquare()
      || !zone
      || !this.state.permissions
    ) {
      return;
    }
    let boundsRect;
    if (AppGlobal.mapZoneBoundariesMask.mask) {
      boundsRect = AppGlobal.mapZoneBoundariesMask.mask.getAlphaBoundingSq(0);
    } else {
      boundsRect = controller.getImageSquare();
    }
    if (!this.state.permissions.CAN_VIEW_FULL_MAP) {
      // TODO: This doesn't remove areas that are override hidden which
      // could "expose" to savvy players that something might be hidden
      // somewhere. Getting that to work is a bit of a hassle, so I'm not going
      // to worry about it for now.
      let visSq = AppGlobal.mapZoneExploredMask.mask.getAlphaBoundingSq(20, true);
      visSq = Squares.getContainingSquare(
        visSq, AppGlobal.mapZoneOverrideVisibleMask.mask.getAlphaBoundingSq(20, true)
      );
      boundsRect = Squares.getIntersection(boundsRect, visSq);
      if (!boundsRect) {
        console.warn('There is no revealed area for this map zone');
        // TODO: This should set something in state so that the page can be
        // updated alerting the user that nothing in this zone has been
        // revealed.
        boundsRect = Squares.zero();
      }
    }

    // Update the state to indicate whether anything is visible
    if (!boundsRect || boundsRect === Squares.zero()) {
      this._embarkTools.setNoMapVisible(true);
    } else {
      this._embarkTools.setNoMapVisible(false);
    }

    // Constrain the view to the intersection of the different constraints
    controller.setVisibleBoundary(boundsRect.x, boundsRect.y, boundsRect.x2, boundsRect.y2);
    console.debug('embark: Refreshed visible boundary', boundsRect);
  }

  _refreshToolComponents(mapZoneDetails, tool) {
    if (!mapZoneDetails.async.isSuccess) {
      return;
    }
    // TODO: Do we need to check if the engine is ready to go?
    this._curMapViewController.engine.requestKeyframe();
    const toolId = tool.id || null;
    const toolOptions = tool.options || {};
    // Disable all of the tool map objects. They will be enabled by the
    // following conditional spaghetti code.
    this._maskPainterGridCell.enabled = false;
    this._maskPainterGridCell.grid = mapZoneDetails.grid;
    this._maskPainterRect.enabled = false;
    this._maskPainterRect.grid = mapZoneDetails.grid;
    // Handle tool options
    //
    // TODO: This code is a goddamn mess...
    if (toolId === EmbarkToolsState.TOOL_VIS_OVERRIDE) {
      this._fogOfWar.setLayerVisibility();
      this._tokenManager.disabled = true;
      let toolMapObject = null;
      if (toolOptions.shape === EmbarkToolsState.OPTION_DRAW_SHAPE_GRID_BRUSH) {
        toolMapObject = this._maskPainterGridCell;
      } else if (toolOptions.shape === EmbarkToolsState.OPTION_DRAW_SHAPE_RECT) {
        toolMapObject = this._maskPainterRect;
      } else {
        throw new Error(`Unknown visibility override shape: ${toolOptions.shape}`);
      }
      toolMapObject.enabled = true;
      if (toolOptions.operation === EmbarkToolsState.OPTION_VIS_HIDE) {
        toolMapObject.setPaintHide();
      } else if (toolOptions.operation === EmbarkToolsState.OPTION_VIS_DARK) {
        toolMapObject.setPaintDark();
      } else if (toolOptions.operation === EmbarkToolsState.OPTION_VIS_LIGHT) {
        toolMapObject.setPaintLight();
      } else {
        throw new Error(`Unknown visibility override operation: ${toolOptions.operation}`);
      }
    } else {
      this._tokenManager.disabled = false;
      this._fogOfWar.resetLayer();
    }
  }

  _handleDrawToolClickTap(event) {
    const tool = this._embarkTools.tool;
    const toolOptions = tool.options || {};
    if (toolOptions.shape === EmbarkToolsState.OPTION_DRAW_SHAPE_GRID_BRUSH) {
      this._maskPainterGridCell.handleClickTap();
    } else if (toolOptions.shape === EmbarkToolsState.OPTION_DRAW_SHAPE_RECT) {
      // No-op
    } else {
      throw new Error(`No draw tool for shape ${toolOptions.shape}`);
    }
  }

  _handleDrawToolDragBegin(event) {
    const tool = this._embarkTools.tool;
    const toolOptions = tool.options || {};
    if (toolOptions.shape === EmbarkToolsState.OPTION_DRAW_SHAPE_GRID_BRUSH) {
      this._maskPainterGridCell.handleDragBegin();
    } else if (toolOptions.shape === EmbarkToolsState.OPTION_DRAW_SHAPE_RECT) {
      this._maskPainterRect.handleDragBegin();
    } else {
      throw new Error(`No draw tool for shape ${toolOptions.shape}`);
    }
  }

  _handleDrawToolDragEnd(event) {
    const tool = this._embarkTools.tool;
    const toolOptions = tool.options || {};
    if (toolOptions.shape === EmbarkToolsState.OPTION_DRAW_SHAPE_GRID_BRUSH) {
      this._maskPainterGridCell.handleDragEnd();
    } else if (toolOptions.shape === EmbarkToolsState.OPTION_DRAW_SHAPE_RECT) {
      this._maskPainterRect.handleDragEnd();
    } else {
      throw new Error(`No draw tool for shape ${toolOptions.shape}`);
    }
  }

  /**
   * Resets/initializes the rendering pipeline for the map view
   */
  initializeMapViewRenderPipeline() {
    const controller = AppGlobal.mapView.controller;
    if (!controller || !controller.engine) {
      throw new Error('Cannot initialize render pipeline until map controller is set');
    }
    canvasengine.LegacyMapObject.legacyAttach(this._zoneGrid, controller, 'interface');
    canvasengine.LegacyMapObject.legacyAttach(this._maskPainterGridCell, controller, 'interface');
    canvasengine.LegacyMapObject.legacyAttach(this._maskPainterRect, controller, 'interface');
    this._fogOfWar.attach(controller.engine, 'special');
    this._tokenManager.attach(controller.engine, 'tokens');
    this._mapPositionStorer.attach(controller.engine, 'special');
  }

  _checkStoreCurrentMapPosition(frameContext) {
    // It's silly that this is frame-rate-dependent, but I can't be bothered to
    // store information like how long since the last save.
    if (frameContext.frameNo % 60 === 0) {
      const engine = AppGlobal.mapView.controller.engine;
      window.localStorage.setItem(
        `embark.viewPosition.${this._curMapViewZoneId}`,
        JSON.stringify({
          viewX: engine.getViewX(),
          viewY: engine.getViewY(),
          zoom: engine.getZoom(),
        }),
      );
    }
  }

  render() {
    const state = this.state;
    const campaignId = state.campaignDetails.campaignId;
    const mapId = state.mapDetails.mapId;
    const mapZoneId = state.mapZoneDetails.mapZoneId;
    const noMapVisible = state.embark.noMapVisible;
    const permissions = state.permissions;
    const toolUI = this._getToolUI(state.tool);
    let canvasEngine = null;
    if (AppGlobal.mapView.controller && AppGlobal.mapView.controller.engine) {
      canvasEngine = AppGlobal.mapView.controller.engine;
    }
    // TODO: Make the right toolbar width based on whether the right side is being
    // used or not. By using width instead of render/not render, it allows us to
    // rely on the transition css property on width to make it do a nice slide.
    return (
      <ToolboxLayout
        style={_TOOLBOX_LAYOUT_STYLE}
      >
        <PositionedBox>
          <DialogBox
            variant='opaque'
            rendered={state.isLoading}
          >
            Loading... <br/>
            <ProgressBar progress={state.async.progress} />
          </DialogBox>
          <DialogBox
            rendered={!state.isLoading && noMapVisible && permissions.CAN_VIEW_FULL_MAP}
            variant='semialpha'
          >
            <p>
              This area's boundaries are not properly configured. You will need
              to finish configuring the map before you can use it
              <a href={`/app/maps/${mapId}/zones/${mapZoneId}/configure/bounds`}>
                Finish Configuration
              </a>
            </p>
          </DialogBox>
          <DialogBox
            rendered={!state.isLoading && noMapVisible && !permissions.CAN_VIEW_FULL_MAP}
            variant='semialpha'
          >
            Nothing in this area has been revealed yet. Mysterious...
          </DialogBox>
          <MapZoneSelectDialog rendered={!state.isLoading && (!mapId || !mapZoneId)} />
          <TokenAddEditDialog
            campaignId={state.campaignDetails.campaignId}
            rendered={!!state.editTokenRef.key}
            tokenRef={AppGlobal.editTokenRef}
            afterSubmit={AppGlobal.editTokenRef.$b.clearKey}
            afterDelete={AppGlobal.editTokenRef.$b.clearKey}
            onCancel={AppGlobal.editTokenRef.$b.clearKey}
          />
          <EmbarkMenu />
          <EmbarkNavBar />
          <EmbarkUserMenu />
          <EmbarkToolbar
            permissions={permissions}
            toolStateRef={this._embarkTools}
            undoContext={`mapZone:${mapZoneId}`}
          />
          {/* if */(state.tool.id ===  EmbarkToolsState.TOOL_TOKENS) ? (
            <tokenscmp.TokenTray
              className={classNames(
                'layout--floating-right-middle',
              )}
              style={_TOKEN_TRAY_SIDEBAR_STYLE}
              campaignId={campaignId}
              mapZoneId={mapZoneId}
              variant='vertical'
            />
          )/* endif */ : null}
          <TokenInterfaceManager
            canvasEngine={canvasEngine}
          />
          <MapViewInteractive
            style={_MAP_VIEW_INTERACTIVE_STYLE}
            preventDragMovement={toolUI.preventDragMovement}
            onTap={toolUI.handleClickTap}
            onDragBegin={toolUI.handleDragBegin}
            onDragMove={toolUI.handleDragMove}
            onDragEnd={toolUI.handleDragEnd}
            mapViewContainer={AppGlobal.mapView}
            undoContext={`mapZone:${mapZoneId}`}
          />
          <div className='layout--floating-bottom-left hidden'>
            <pre style={_VIEW_DEBUG_STYLE} id='canvas-view-debug'></pre>
          </div>
        </PositionedBox>
      </ToolboxLayout>
    );
  }

  _getToolUI = Memoize((tool) => {
    switch(tool.id) {
      case EmbarkToolsState.TOOL_VIS_OVERRIDE:
      case EmbarkToolsState.TOOL_EXPLORATION:
        return {
          preventDragMovement: true,
          handleClickTap: this.$b._handleDrawToolClickTap,
          handleDragBegin: this.$b._handleDrawToolDragBegin,
          handleDragMove: null,
          handleDragEnd: this.$b._handleDrawToolDragEnd,
        };
      case EmbarkToolsState.TOOL_MOVE:
      case EmbarkToolsState.TOOL_SELECT:
      default:
        return {
          preventDragMovement: false,
          handleClickTap: null,
          handleDragBegin: null,
          handleDragMove: null,
          handleDragEnd: null,
        };
    }
  });

}
