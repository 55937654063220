import React from 'react';
import {withRouter} from 'react-router';

import FullErrorBoundary from 'components/errors/full-error-boundary';
import EmbarkMapEditDialog from 'apps/maps/components/embark-map-edit-dialog';
import EmbarkMapInterface from 'apps/maps/components/embark-map-interface';
import EmbarkMapZoneEditDialog from 'apps/maps/components/embark-map-zone-edit-dialog';
import SettingsFormDialog from 'apps/maps/components/settings-form-dialog';

import AppGlobal from 'global';

import undolib from 'undolib';
import EmbarkWebSocket from 'apps/maps/state/embark-websocket';
import MultiAsyncState from 'statelib/multi-async-state';
import AppReactComponent from 'utils/app-react-component';


function EmbarkPageView({
  undoEntityId,
}) {
  return (
    <FullErrorBoundary>
      <undolib.UndoContextProvider context={undoEntityId}>
        <EmbarkMapEditDialog />
        <EmbarkMapZoneEditDialog />
        <SettingsFormDialog />
        <EmbarkMapInterface />
      </undolib.UndoContextProvider>
    </FullErrorBoundary>
  );
}


class EmbarkPage extends AppReactComponent {

  constructor(props) {
    super(props);
    this.async = new MultiAsyncState([
      AppGlobal.campaignDetails.getAttachedState('async'),
      AppGlobal.mapDetails.getAttachedState('async'),
      AppGlobal.mapZoneDetails.getAttachedState('async'),
      AppGlobal.mapView.getAttachedState('async'),
    ], {watchOnly: true});
    this._websocket = new EmbarkWebSocket();
    this.addCleanup(this._websocket.$b.destroy);
    this.addSetup(this.$b.refreshIdsFromRouter);
    // Hook up all of the allowed IDs between different objects
    this.addCleanup(
      AppGlobal.campaignDetails.subscribe(this.$b.handleCampaignMapIds),
      AppGlobal.mapDetails.subscribe(this.$b.handleMapZoneIds),
      this.async.$b.close,
    );
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    this.refreshIdsFromRouter();
  }

  refreshIdsFromRouter() {
    AppGlobal.campaignDetails.campaignId = this.props.match.params.campaignId || null;
    AppGlobal.mapDetails.mapId = this.props.match.params.mapId || null;
    AppGlobal.mapZoneDetails.mapZoneId = this.props.match.params.zoneId || null;
  }

  handleCampaignMapIds(data) {
    if (data.async.isRunning) {
      return;
    }
    const campaignId = data.campaignId;
    const campaign = data.campaign;
    if (!campaign) {
      // TODO: Not sure what to do here, but I expect it should either be a 404
      // or nothing (allowing other UI elements to take care of it). Performing
      // navigation might interfere with OTHER things performing navigation.
      return;
    }
    const mapIds = data.maps.map(map => map.id);
    const included = AppGlobal.mapDetails.setAllowedIds(mapIds);
    if (!included) {
      console.log(`Redirecting to campaign ${campaignId}`);
      this.props.history.replace(AppGlobal.navigation.getEmbarkUrl(campaignId));
    }
  }

  handleMapZoneIds(data) {
    if (data.async.isRunning) {
      return;
    }
    const campaignId = AppGlobal.campaignDetails.campaignId;
    const mapId = data.mapId;
    const map = data.map;
    if (!map) {
      // TODO: Not sure what to do here, but I expect it should either be a 404
      // or nothing (allowing other UI elements to take care of it). Performing
      // navigation might interfere with OTHER things performing navigation.
      return;
    }
    const mapZoneIds = data.zones.map(map => map.id);
    const defaultMapZoneId = data.defaultZoneId || null;
    const included = AppGlobal.mapZoneDetails.setAllowedIds(mapZoneIds);
    if (defaultMapZoneId && !this.props.match.params.zoneId) {
      this.props.history.replace(AppGlobal.navigation.getEmbarkUrl(
        campaignId, mapId, defaultMapZoneId));
    } else if (!included) {
      // Note that `getEmbarkUrl` will ignore a null defaultZoneId
      this.props.history.replace(AppGlobal.navigation.getEmbarkUrl(
        campaignId, mapId, defaultMapZoneId));
    }
  }

  _getUndoEntityId() {
    if (this.props.match.params.zoneId) {
      return `mapZone:${this.props.match.params.zoneId}`;
    } else if (this.props.match.params.mapId) {
      return `map:${this.props.match.params.mapId}`;
    } else if (this.props.match.params.campaignId) {
      return `campaign:${this.props.match.params.campaignId}`;
    }
    return null;
  }

  render() {
    return EmbarkPageView({
      undoEntityId: this._getUndoEntityId(),
    });
  }

}


export default withRouter(EmbarkPage);
