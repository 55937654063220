import React from 'react';

import {Input} from '@salesforce/design-system-react';
import {Button} from '@salesforce/design-system-react';

import AppReactComponent from 'utils/app-react-component';
import canvasengine from 'canvasengine';
import ConnectedMapViewRef from 'apps/maps/state/connected-map-view-ref';
import FormHandler from 'formlib/form-handler';
import Grids from 'utils/geometry/grids';
import InterfaceGrid from 'components/mapview/map-objects/interface-grid';
import InterfaceMiniGrid from 'components/mapview/map-objects/interface-mini-grid';
import layouts from 'components/layouts';
import MapViewInteractive from 'components/mapview/map-view-interactive';
import MapZoneConfigNav from 'apps/maps/components/map-zone-config-nav';
import shared from 'global';
import {
  Transform,
  intToFormValue,
  formValueToInt,
  floatToFormValue,
  formValueToFloat,
} from 'formlib/form-functions';


function MapZoneConfigLayerGridView({
  adjustingFormFieldHandlers,
  adjustingFormFields,
  handleBeginSelection,
  handleCancel,
  handleCancelSelection,
  handleFinishedSelection,
  handleSave,
  handleSaveAndNext,
  isSelecting,
  onControllerReady,
  onDragBegin,
  onDragEnd,
  onDragMove,
  selectingFormFieldHandlers,
  selectingFormFields,
  mapId,
  mapZoneId,
}) {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      width: '100%',
      position: 'fixed',  // No idea, but it fixes overscan compatibility
    }}>
      <MapViewInteractive
        style={{width: '100%', height: '100%', flex: 'auto'}}
        controllerReady={onControllerReady}
        preventDragMovement={isSelecting}
        onDragBegin={onDragBegin}
        onDragMove={onDragMove}
        onDragEnd={onDragEnd}
        />
      <div style={{ width: '280px', height: '100%', flex: '0 1 auto'}}>
        <MapZoneConfigNav
          selectImageState='Done'
          selectGridState='Ready'
          selectBoundariesState='Ready'
          currentStep='SelectGrid'
          mapId={mapId}
          mapZoneId={mapZoneId}
        />
        {/* if */(isSelecting) ? (
          <React.Fragment>
            <span>test content</span>
            <Input
              label='Cells Wide'
              value={selectingFormFields.cellsWide.value}
              onChange={selectingFormFieldHandlers.cellsWide}
              errorText={selectingFormFields.cellsWide.error}
              />
            <Input
              label='Cells High'
              value={selectingFormFields.cellsHigh.value}
              onChange={selectingFormFieldHandlers.cellsHigh}
              errorText={selectingFormFields.cellsHigh.error}
              />
            <layouts.TwoButtonPanel>
              <Button onClick={handleFinishedSelection} >
                Done
              </Button>
              <Button onClick={handleCancelSelection} >
                Cancel
              </Button>
            </layouts.TwoButtonPanel>
          </React.Fragment>
        )/* else */ : (
          <React.Fragment>
            <Button onClick={handleBeginSelection}>Select Grid</Button>
            <Input
              label='Grid Cell Width'
              type='number'
              value={adjustingFormFields.gridCellWidth.value}
              step={0.1}
              onChange={adjustingFormFieldHandlers.gridCellWidth}
              variant='counter'
              />
            <Input
              label='Grid Cell Height'
              type='number'
              value={adjustingFormFields.gridCellHeight.value}
              step={0.1}
              onChange={adjustingFormFieldHandlers.gridCellHeight}
              variant='counter'
              />
            <Input
              label='Grid Horizontal'
              type='number'
              value={adjustingFormFields.gridOffsetX.value}
              step={1}
              onChange={adjustingFormFieldHandlers.gridOffsetX}
              variant='counter'
              />
            <Input
              label='Grid Vertical Offset'
              type='number'
              value={adjustingFormFields.gridOffsetY.value}
              step={1}
              onChange={adjustingFormFieldHandlers.gridOffsetY}
              variant='counter'
              />
            <layouts.TwoButtonPanel>
              <Button onClick={handleSave}>Save and Continue</Button>
              <Button onClick={handleCancel}>Back to Map</Button>
            </layouts.TwoButtonPanel>
          </React.Fragment>
        )/* endif */}
      </div>
    </div>
  );
}


export default class MapZoneConfigLayerGrid extends AppReactComponent {

  constructor() {
    super();
    this._mapContainer = shared.mapDetails;
    this._mapZoneRef = shared.mapZoneDetails;
    this._mapViewContainer = new ConnectedMapViewRef({
      mapContainer: this._mapContainer,
      mapZoneContainer: this._mapZoneRef,
    });
    this._selectingFormHandler = new FormHandler(data => {
      return {
        cellsWide: intToFormValue(data.cellsWide),
        cellsHigh: intToFormValue(data.cellsHigh),
      };
    }, 'SelectingForm');
    this._adjustingFormHandler = new FormHandler(data => {
      return {
        gridCellWidth: floatToFormValue(data.gridCellWidth, 2),
        gridCellHeight: floatToFormValue(data.gridCellHeight, 2),
        gridOffsetX: floatToFormValue(data.gridOffsetX, 1),
        gridOffsetY: floatToFormValue(data.gridOffsetY, 1),
        // TODO: Add fields for cell sizing
        gridCellSize: '5',
        gridCellUnits: 'feet',
      };
    }, 'AdjustingForm');
    this._x1 = null;
    this._x2 = null;
    this._y1 = null;
    this._y2 = null;
    this._grid = null;
    this.state = {
      isSelecting: false,
      selectingForm: null,
      adjustingForm: null,
    };
    this.gridMapObject = new InterfaceGrid(null, '#000');
    this.partialGridMapObject = new InterfaceMiniGrid();
    this.addCleanup(
      this._mapViewContainer.$b.close,
      this._selectingFormHandler.$b.close,
      this._adjustingFormHandler.$b.close,
      this._selectingFormHandler.connectFieldsToComponentState(this, 'selectingForm'),
      this._adjustingFormHandler.connectFieldsToComponentState(this, 'adjustingForm'),
    );
    this.addClosableSetup(
      () => this._mapZoneRef.subscribe(
        this.$b.handleNewZoneData,
        {immediate: true},
      ),
      () => this._mapViewContainer.subscribe(
        this.$b.initializeNewMapViewController,
        {condition: data => data.async.isSuccess, immediate: true},
      ),
    );
  }

  getMapViewController() {
    return this._mapViewContainer.controller;
  }

  handleNewZoneData(zoneData) {
    if (zoneData.async.isSuccess && zoneData.mapZone.gridConfiguration) {
      const gridConfig = zoneData.mapZone.gridConfiguration;
      if (gridConfig.rectangleGrid) {
        this._adjustingFormHandler.initializeData({
          gridCellWidth: gridConfig.rectangleGrid.cellWidth,
          gridCellHeight: gridConfig.rectangleGrid.cellHeight,
          gridOffsetX: gridConfig.rectangleGrid.anchorX,
          gridOffsetY: gridConfig.rectangleGrid.anchorY,
        });
      }
    }
  }

  handleMapViewControllerReady(controller) {
    this._mapViewContainer.controller = controller;
  }

  handleChangeCellsWide(event, data) {
    this._selectingFormHandler.setFormFieldAndData(
      'cellsWide', data.value, formValueToInt);
  }

  handleChangeCellsHigh(event, data) {
    this._selectingFormHandler.setFormFieldAndData(
      'cellsHigh', data.value, formValueToInt);
  }

  handleChangeGridCellWidth(event, data) {
    this._adjustingFormHandler.setFormFieldAndData('gridCellWidth', data.value,
      new Transform(formValueToFloat).rounded(2).required().greaterThanOrEqual(5).func);
  }

  handleChangeGridCellHeight(event, data) {
    this._adjustingFormHandler.setFormFieldAndData('gridCellHeight', data.value,
      new Transform(formValueToFloat).rounded(2).required().greaterThanOrEqual(5).func);
  }

  handleChangeGridOffsetX(event, data) {
    this._adjustingFormHandler.setFormFieldAndData('gridOffsetX', data.value,
      new Transform(formValueToFloat).rounded(1).required().func);
  }

  handleChangeGridOffsetY(event, data) {
    this._adjustingFormHandler.setFormFieldAndData('gridOffsetY', data.value,
      new Transform(formValueToFloat).rounded(1).required().func);
  }

  handleDragBegin(event, target) {
    this._x1 = target.x;
    this._y1 = target.y;
    //this.getMapViewController().refreshMap();
    this.refreshGridMapObjects();
  }

  handleDragMove(event, target) {
    this._x2 = target.x;
    this._y2 = target.y;
    //this.getMapViewController().refreshMap();
    this.refreshGridMapObjects();
  }

  handleDragEnd(event, target) {
    this._x2 = target.x;
    this._y2 = target.y;
    //this.getMapViewController().refreshMap();
    this.refreshGridMapObjects();
  }

  handleBeginSelection() {
    this._selectingFormHandler.initializeData({cellsWide: 1, cellsHigh: 1});
    this._x1 = null;
    this._y1 = null;
    this._x2 = null;
    this._y2 = null;
    this.setState({isSelecting: true});
    this._mapViewContainer.controller.fullMapRerender();
  }

  handleCancelSelection() {
    this.setState({isSelecting: false});
    this._mapViewContainer.controller.fullMapRerender();
  }

  handleFinishedSelection() {
    const selectingFormData = this._selectingFormHandler.getData();
    const cellWidth = Math.abs(this._x2 - this._x1) / selectingFormData.cellsWide;
    const cellHeight = Math.abs(this._y2 - this._y1) / selectingFormData.cellsHigh;
    this._adjustingFormHandler.initializeData({
      gridCellWidth: cellWidth,
      gridCellHeight: cellHeight,
      gridOffsetX: this._x1,
      gridOffsetY: this._y1,
    })
    this.setState({isSelecting: false});
    this._mapViewContainer.controller.fullMapRerender();
  }

  handleCancel(event) {
    this.props.onCancel();
  }

  handleSave(event) {
    this.props.onSave({
      gridConfig: this._grid.getGridConfiguration({normalizeOrigin: true}),
    });
  }

  handleSaveAndNext(event) {
    this.props.onSave({
      gridConfig: this._grid.getGridConfiguration({normalizeOrigin: true}),
    });
  }

  refreshGridMapObjects() {
    this.gridMapObject.visible = false;
    this.partialGridMapObject.visible = false;
    // Updating the grid from state should probably NOT be bound to the map
    // rendering pipeline. However, it's fine for now. The alternative would be
    // to include selecting x1, x2, y1, y2 as part of the selecting form data and
    // to configure this function as a listener to both forms.
    if (this.state.isSelecting) {
      if (this._x1 && this._x2 && this._y1 && this._y2) {
        const originX = Math.round(10 * this._x1) / 10;
        const originY = Math.round(10 * this._y1) / 10;
        const cellWidth = Math.round(100 * Math.abs(this._x1 - this._x2)) / 100;
        const cellHeight = Math.round(100 * Math.abs(this._y1 - this._y2)) / 100;
        this._grid = Grids.makeRectangleGrid({
          originX: originX,
          originY: originY,
          anchorX: originX,
          anchorY: originY,
          cellWidth: cellWidth,
          cellHeight: cellHeight,
        });
        this.partialGridMapObject.grid = this._grid;
        this.partialGridMapObject.visible = true;
      }
    } else {
      const gridData = this._adjustingFormHandler.getData();
      if (
        gridData.gridCellWidth !== null &&
        gridData.gridCellHeight !== null &&
        gridData.gridOffsetX !== null &&
        gridData.gridOffsetY !== null
      ) {
        this._grid = Grids.makeRectangleGrid({
          originX: gridData.gridOffsetX,
          originY: gridData.gridOffsetY,
          anchorX: gridData.gridOffsetX,
          anchorY: gridData.gridOffsetY,
          cellWidth: gridData.gridCellWidth,
          cellHeight: gridData.gridCellHeight,
        });
        this.gridMapObject.grid = this._grid;
        this.gridMapObject.visible = true;
      }
    }
  }

  render() {
    this.refreshGridMapObjects();
    return MapZoneConfigLayerGridView({
      isSelecting: this.state.isSelecting,
      selectingFormFields: this.state.selectingForm,
      selectingFormFieldHandlers: {
        cellsWide: this.$b.handleChangeCellsWide,
        cellsHigh: this.$b.handleChangeCellsHigh,
      },
      adjustingFormFields: this.state.adjustingForm,
      adjustingFormFieldHandlers: {
        gridCellWidth: this.$b.handleChangeGridCellWidth,
        gridCellHeight: this.$b.handleChangeGridCellHeight,
        gridOffsetX: this.$b.handleChangeGridOffsetX,
        gridOffsetY: this.$b.handleChangeGridOffsetY,
      },
      onDragBegin: this.$b.handleDragBegin,
      onDragMove: this.$b.handleDragMove,
      onDragEnd: this.$b.handleDragEnd,
      onControllerReady: this.$b.handleMapViewControllerReady,
      handleBeginSelection: this.$b.handleBeginSelection,
      handleCancel: this.$b.handleCancel,
      handleCancelSelection: this.$b.handleCancelSelection,
      handleFinishedSelection: this.$b.handleFinishedSelection,
      handleSave: this.$b.handleSave,
      handleSaveAndNext: this.$b.handleSaveAndNext,
      mapId: this._mapContainer.mapId,
      mapZoneId: this._mapZoneRef.mapZoneId,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // MAP RENDERING PIPELINE
  /////////////////////////////////////////////////////////////////////////////

  initializeNewMapViewController(eventObj) {
    const controller = this._mapViewContainer.controller;
    if (controller) {
      canvasengine.LegacyMapObject.legacyAttach(this.gridMapObject, controller, 'interface');
      canvasengine.LegacyMapObject.legacyAttach(this.partialGridMapObject, controller, 'interface');
    }
  }

}
