import CanvasEngine from "canvasengine/canvas-engine";
import FrameUpdateContext from "canvasengine/frame-update-context";
import FStateListener from "statelib/fstate-listener";
import MapObjectStoreState from "apps/maps/state/map-object-store-state";
import MapZoneDetailsRef from "apps/maps/state/map-zone-details-ref";
import ScriptGameObject from "canvasengine/gameobjects/script-game-object";

import StandardMapToken from "./standard-map-token";


export default class MapTokenManager extends ScriptGameObject {

  protected _curMapZoneId: string | null;
  protected _mapObjectStore: MapObjectStoreState;
  protected _mapZoneRef: MapZoneDetailsRef;
  protected _disabled: boolean = false;
  protected _tokens: Map<String, StandardMapToken>;
  private _tokenListener: FStateListener;

  constructor(mapZoneRef, mapObjectStore) {
    super();
    this._mapObjectStore = mapObjectStore;
    this._mapZoneRef = mapZoneRef;
    this._curMapZoneId = null;
    this._tokens = new Map();
    this._tokenListener = new FStateListener(
      this.$b.reconcileTokenData, {immediate: true}
    );
    this._tokenListener.listen(this._mapObjectStore);
    this._tokenListener.listen(this._mapZoneRef);
  }

  destroy() {
    this._tokenListener.destroy();
    this._tokenListener = null;
    for (const token of this._tokens.values()) {
      token.destroy();
    }
    this._tokens = new Map();
    this._mapZoneRef = null;
    this._mapObjectStore = null;
    super.destroy();
  }

  attach(engine: CanvasEngine, layerName: string): void {
    super.attach(engine, layerName);
    this.reconcileTokenData();
  }

  detach(): void {
    super.detach();
    this.reconcileTokenData();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    if (value === this._disabled) {
      return;
    }
    this._disabled = value;
    for (const token of this._tokens.values()) {
      token.disabled = value;
    }
  }

  /**
   * Triggered whenever new map zone or token data is available
   */
  protected reconcileTokenData() {
    // If we have no canvas engine, destroy any tokens available and immediately
    // exit (this can happen when the token manager is detached) or if it
    // receives token data before it is attached)
    if (!this._engine) {
      this._destroyAllTokens();
      return;
    }
    // If the map zone has changed, clear out all existing tokens (they will be
    // re-added later)
    const nextMapZoneId: string | null = this._mapZoneRef.mapZoneId;
    if (nextMapZoneId !== this._curMapZoneId || !this._engine) {
      this._curMapZoneId = nextMapZoneId;
      this._destroyAllTokens();
    }
    // Short circuit if we don't have a map zone yet
    if (!this._curMapZoneId || !this._mapZoneRef.grid) {
      return;
    }
    // Check for any tokens related to the map zone that aren't already
    // added in to the engine
    const grid = this._mapZoneRef.grid;
    const recordsByKey = this._mapObjectStore.data.recordsByKey;
    for (const mapObjectId of this._mapObjectStore.data.loadedKeys) {
      const mapObject = recordsByKey[mapObjectId] || null;
      const token = mapObject as any;
      if (!this._tokens.has(mapObjectId)) {
        if (token === null) {
          // Do nothing - the token does not exist, don't add it
          continue;
        } else if (token.mapZoneId === this._curMapZoneId && token.token) {
          const tokenGameObject = this.newToken(grid, mapObjectId);
          tokenGameObject.attach(this._engine, 'tokens');
          this._tokens.set(mapObjectId, tokenGameObject);
        }
      } else {
        if (!token) {
          // The token was deleted and needs to be removed
          console.log('Removing token object due to deletion', mapObjectId);
          const tokenGameObject = this._tokens.get(mapObjectId);
          tokenGameObject.destroy();
          this._tokens.delete(mapObjectId);
        } else if (token.mapZoneId !== this._curMapZoneId) {
          // The token has moved to a different map zone and needs to be removed
          const tokenGameObject = this._tokens.get(mapObjectId);
          tokenGameObject.destroy();
          this._tokens.delete(mapObjectId);
        }
      }
    }

  }

  protected newToken(grid, mapObjectId): StandardMapToken {
    const token = new StandardMapToken(grid, mapObjectId);
    token.disabled = this._disabled;
    return token;
  }

  protected _destroyAllTokens(): void {
    if (!this._tokens.size) {
      return;
    }
    for (const token of this._tokens.values()) {
      token.destroy();
    }
    this._tokens.clear();
  }

  protected run(frameUpdateContext: FrameUpdateContext): void {
    // console.log('TODO: Reconcile new token data');
  }
}
