import React from 'react';

/**
 * A special element that takes up all available space and is considered
 * "positioned" for the purpose of anchoring child elements that want to use
 * `absolute` positioning.
 *
 * This is necessary because flex containers are not necessarily considered
 * positioned elements.
 */
export default function PositionedBox({children}) {
  return (
    <div className='layout--positioned-box'>{children}</div>
  );
}
