import lodashUniqueId from 'lodash.uniqueid';

import CanvasUtils from 'utils/graphics/canvas-utils';
import Squares from 'utils/squares';
import UserAgent from 'utils/user-agent';

const IDENTITY_MATRIX = CanvasUtils.IDENTITY_MATRIX;


// IDEA: The ideal state of background-image would be to pre-cache tile data
// in the form of bitmaps. These will extend slightly outside of the actual
// viewport. As the user moves around, it will load and unload tiles. This
// keep a relatively small footprint, does tile loading async instead of
// synchronously and gives access to some of the cool zoom features.

// TODO: Refactor this to be a subclass of `Bitmap`.


/**
 * Handles all of the complexities of working with large background images.
 *
 * Some notes in no particular order:
 *
 * This class is meant to handle large images that are partially drawn to a
 * canvas. Unlike a spritesheet, the size and shape and scale of the area may
 * change.
 *
 * This takes care of a "quirk" of chrome where it can "intervene" on behalf of
 * the user and reduce the size of an image. Ultimately, this behavior is good
 * because it prevents images from consuming too much graphical memory. However,
 * it is annoying because the images themselves aren't "original size", so we
 * need to do some magical scaling behind the scenes to draw the correct content.
 */
export default class BackgroundImage {

  constructor(document, imageUrl, width, height) {
    if (width === undefined || typeof(width) !== 'number') {
      throw new Error('Width must be specified and must be a number');
    } else if (height === undefined || typeof(height) !== 'number') {
      throw new Error('Width must be specified and must be a number');
    }
    this._name = lodashUniqueId('BackgroundImage_');
    this._document = document;
    this._imageUrl = imageUrl;
    this._isDestroyed = false;
    this._originalImageWidth = width;
    this._originalImageHeight = height;
    this._originalImageRect = Squares.fromDimensions(0, 0, width, height);
    this._imageElement = null;
    this._image = null;
    this._imageHalf = null;
    this._imageQuarter = null;
    this._loadPromise = null;
    this._imageScale = null;
    this._imageWidth = null;
    this._imageHeight = null;
    this._imageRect = null;
    console.log(`[${this._name}]: Object created`);
  }

  get url() {
    return this._imageUrl;
  }

  get width() {
    return this._originalImageWidth;
  }

  get height() {
    return this._originalImageHeight;
  }

  get rect() {
    return this._originalImageRect;
  }

  get image() {
    return this._imageElement;
  }

  get isReady() {
    return !!this._sourceImage;
  }

  get _sourceImage() {
    return (UserAgent.isCreateImageBitmapSupported) ? this._image : this._imageElement;
  }

  destroy() {
    this._document = null;
    this._isDestroyed = true;
    this._imageElement = null;
    if (this._image) {
      this._image.close();
    }
    this._image = null;
    if (this._imageHalf) {
      this._imageHalf.close();
    }
    this._imageHalf = null;
    if (this._imageQuarter) {
      this._imageQuarter.close();
    }
    this._imageQuarter = null;
    this._loadPromise = null;
    console.log(`[${this._name}]: Image object has been destroyed`, this._imageUrl);
  }

  throwIfDestroyed() {
    if (this._isDestroyed) {
      throw new Error(`Background Image has previously been destroyed (${this._name})`);
    }
  }

  /**
   * Async method for loading the image. Resolves when the image itself is
   * loaded.
   */
  load() {
    this.throwIfDestroyed();
    // Short circuit if loading or already loaded
    if (this._loadPromise) {
      return this._loadPromise;
    }
    // TODO: For graphics quality 1, attempt to load
    this._loadPromise = new Promise((resolve, reject) => {
      const nextImage = new Image();  // TODO: figure out height and width
      const loadedCallback = () => {
        if (this._isDestroyed) {
          // TODO: image loaded after the object was destroyed. Bad?
          resolve();
        } else {
          this._imageScale = this._imageElement.width / this._originalImageWidth;
          this._imageWidth = this._imageElement.width;
          this._imageHeight = this._imageElement.height;
          this._imageRect = Squares.fromDimensions(
            0, 0, this._imageWidth, this._imageHeight
          );
          if (UserAgent.isCreateImageBitmapSupported) {
            this._generateImageBitmapsWithCanvas(this._imageElement)
              .catch((err) => {
                reject(err);
              })
              .then(() => {
                console.log(`[${this._name}]: Image loaded (bitmap)`, this._imageUrl);
                resolve();
              });
          } else {
            console.log(`[${this._name}]: Image loaded (basic)`, this._imageUrl);
            resolve();
          }
        }
      }
      nextImage.addEventListener('load', loadedCallback);
      nextImage.addEventListener('error', reject);
      nextImage.src = this._imageUrl;
      this._imageElement = nextImage;
    });
    return this._loadPromise;
  }


  async _generateImageBitmaps(imageElement) {
    if (UserAgent.isCreateImageBitmapResizeSupported) {
      return await this._generateImageBitmapsWithResizeOptions(imageElement);
    }
    return await this._generateImageBitmapsWithCanvas(imageElement);
  }


  /**
   * Generates scaled bitmaps for the background image using a temporary canvas.
   *
   * This gets around limitations of some browsers that do not allow the use of
   * options when calling createImageBitmap
   */
  async _generateImageBitmapsWithCanvas(imageElement) {
    const image = await createImageBitmap(imageElement);
    let context;
    const tmpCanvas = this._document.createElement('canvas');
    tmpCanvas.width = Math.round(this._imageWidth * 0.5);
    tmpCanvas.height = Math.round(this._imageHeight * 0.5);
    context = tmpCanvas.getContext('2d');
    context.drawImage(
      image,
      0,
      0,
      imageElement.width,
      imageElement.height,
      0,
      0,
      tmpCanvas.width,
      tmpCanvas.height,
    );
    const imageHalf = await createImageBitmap(tmpCanvas);
    tmpCanvas.width = Math.round(this._imageWidth * 0.25);
    tmpCanvas.height = Math.round(this._imageHeight * 0.25);
    context = tmpCanvas.getContext('2d');
    context.drawImage(
      image,
      0,
      0,
      imageElement.width,
      imageElement.height,
      0,
      0,
      tmpCanvas.width,
      tmpCanvas.height,
    );
    const imageQuarter = await createImageBitmap(tmpCanvas);
    this._image = image;
    this._imageHalf = imageHalf;
    this._imageQuarter = imageQuarter;
  }


  async _generateImageBitmapsWithResizeOptions(imageElement) {
    // TODO: Among the resize options, also do resize quality
    const image = await createImageBitmap(imageElement);
    const imageHalf = await createImageBitmap(imageElement, {
      resizeWidth: this._imageWidth * 0.5,
      resizeHeight: this._imageHeight * 0.5,
    });
    const imageQuarter = await createImageBitmap(imageElement, {
      resizeWidth: this._imageWidth * 0.25,
      resizeHeight: this._imageHeight * 0.25,
    });
    this._image = image;
    this._imageHalf = imageHalf;
    this._imageQuarter = imageQuarter;
  }


  draw(context, sx, sy, dx, dy, dw, dh, ds) {
    // Note: pixel density doesn't matter - it should be handled in the
    // destination width and height
    const srcRect = Squares.round(Squares.fromDimensions(sx, sy, dw / ds, dh / ds));
    const dstRect = Squares.round(Squares.fromDimensions(dx, dy, dw, dh));
    // Short circuit if the image isn't ready yet
    if (!this._sourceImage) {
      console.warn('Attempt to draw background image before loaded');
      context.clearRect(
        dstRect.x,
        dstRect.y,
        dstRect.w,
        dstRect.h,
      );
      return;
    }
    // Draw from the appropriately scaled bitmap. Note that the buckets for when
    // each version is used are slightly higher than the natural scale of that
    // version to make things a little faster as the amount of data starts to
    // get high.
    let sourceImage;
    let imageScale;
    let imageRect;
    if (ds < 0.3) {
      sourceImage = this._imageQuarter;
      imageScale = this._imageScale * 0.25;
      imageRect = Squares.round(Squares.scale(this._imageRect, 0.25));
    } else if (ds < 0.65) {
      sourceImage = this._imageHalf;
      imageScale = this._imageScale * 0.5;
      imageRect = Squares.round(Squares.scale(this._imageRect, 0.5));
    } else {
      sourceImage = this._image;
      imageScale = this._imageScale;
      imageRect = this._imageRect;
    }
    CanvasUtils.safeDrawImageRect(
      context,
      sourceImage,
      imageRect,
      Squares.round(Squares.reorient(srcRect, imageScale)),
      dstRect,
    );
  }

  /**
   * Like `draw` except that it resets the transformation matrix ahead
   * of time. This can be useful for
   */
  drawDirect(context, sx, sy, dx, dy, dw, dh, ds) {
    context.save();
    try {
      context.setTransform(IDENTITY_MATRIX);
      this.draw(context, sx, sy, dx, dy, dw, dh, ds);
    } finally {
      context.restore();
    }
  }

}
