import Memoize from 'memoize-one';
import PropTypes from 'prop-types';
import React from 'react';

import MiniToolbar from 'components/basic/mini-toolbar';
import MapZoneSelect from 'apps/maps/components/embark-map-zone-select';

import ReactComponent from 'utils/react-component';
import {connectToComponentState} from 'statelib/fstate-react';


function EmbarkNavBarView({
  availableMaps,
  availableMapZones,
  campaignId,
  mapId,
  navigation,
  mapZoneId,
}) {
  return (
    <MiniToolbar attach='top'>
      <MapZoneSelect
        availableMaps={availableMaps}
        availableMapZones={availableMapZones}
        campaignId={campaignId}
        mapId={mapId}
        navigation={navigation}
        mapZoneId={mapZoneId}
      />
    </MiniToolbar>
  );
}


/**
 * Displays the current map and zone on the embark page.
 */
export default class EmbarkNavBar extends ReactComponent {

  constructor(props) {
    super(props);
    connectToComponentState(AppGlobal.campaignDetails, this, 'campaignDetails');
    connectToComponentState(AppGlobal.mapDetails, this, 'mapDetails');
    connectToComponentState(AppGlobal.mapZoneDetails, this, 'mapZoneDetails');
  }

  render() {
    const state = this.state;
    return EmbarkNavBarView({
      availableMaps: state.campaignDetails.maps,
      availableMapZones: state.mapDetails.zones,
      campaignId: state.campaignDetails.campaignId,
      mapId: state.mapDetails.mapId,
      mapZoneId: state.mapZoneDetails.mapZoneId,
      navigation: AppGlobal.navigation,
    });
  }

}

