import React from 'react';

import {Button} from '@salesforce/design-system-react';
import {ButtonGroup} from '@salesforce/design-system-react';
import {Slider} from '@salesforce/design-system-react';

import memoBind from 'utils/memo-bound';
import AppGlobal from 'global';
import FormHandler from 'formlib/form-handler';
import ReactComponent from 'utils/react-component';
import FlexBarLayout from 'components/layouts/flex-bar-layout';

import {connectToComponentState} from 'statelib/fstate-react';


function MapZoneDetailsFormView({
  formView,
  handleCancel,
  handleChangeGraphicsQuality,
  handleSubmit,
  setFrameskip1,
  setFrameskip12,
  setFrameskip13,
  setFrameskip14,
  setPixelDensityAuto,
  setPixelDensity4,
  setPixelDensity3,
  setPixelDensity2,
  setPixelDensity1,
  setPixelDensity12,

}) {
  return (
    <form onSubmit={handleSubmit}>
      {/* TODO: Update the form */}
      <Slider
        label='Graphics Quality'
        min={1}
        max={5}
        step={1}
        value={formView.graphicsQuality.value}
        onChange={handleChangeGraphicsQuality}
        errorText={formView.graphicsQuality.error}
      />
      <p>
        Higher graphics quality will improve the overall look of the
        application. Lower graphics quality may improve framerate and reduce
        power consumption.
      </p>
      <ButtonGroup labels={{label: 'Frame Rate'}}>
        <Button
          label={'¼'}
          variant={(formView.graphicsFrameSkip.value === 3) ? 'brand' : 'neutral'}
          onClick={setFrameskip14}
        />
        <Button
          label={'⅓'}
          variant={(formView.graphicsFrameSkip.value === 2) ? 'brand' : 'neutral'}
          onClick={setFrameskip13}
        />
        <Button
          label={'½'}
          variant={(formView.graphicsFrameSkip.value === 1) ? 'brand' : 'neutral'}
          onClick={setFrameskip12}
        />
        <Button
          label={'1'}
          variant={(formView.graphicsFrameSkip.value === 0) ? 'brand' : 'neutral'}
          onClick={setFrameskip1}
        />
      </ButtonGroup>
      <p>
        A higher framerate will look smoother. A lower framerate may improve
        performance and reduce power consumption.
      </p>
      <ButtonGroup labels={{label: 'Pixel Density (Render Resolution)'}}>
        <Button
          label={'½'}
          variant={(formView.graphicsPixelRatio.value === 0.5) ? 'brand' : 'neutral'}
          onClick={setPixelDensity12}
        />
        <Button
          label={'1'}
          variant={(formView.graphicsPixelRatio.value === 1) ? 'brand' : 'neutral'}
          onClick={setPixelDensity1}
        />
        <Button
          label={'2'}
          variant={(formView.graphicsPixelRatio.value === 2) ? 'brand' : 'neutral'}
          onClick={setPixelDensity2}
        />
        <Button
          label={'3'}
          variant={(formView.graphicsPixelRatio.value === 3) ? 'brand' : 'neutral'}
          onClick={setPixelDensity3}
        />
        <Button
          label={'4'}
          variant={(formView.graphicsPixelRatio.value === 4) ? 'brand' : 'neutral'}
          onClick={setPixelDensity4}
        />
        <Button
          label={'Auto'}
          variant={(formView.graphicsPixelRatio.value === null) ? 'brand' : 'neutral'}
          onClick={setPixelDensityAuto}
        />
      </ButtonGroup>
      <p>
        A higher pixel density will result in a sharper image. A lower pixel
        density may improve framerate and reduce power consumption. "Auto" will
        select the appropriate value for your display.
      </p>
      <FlexBarLayout
        leftChildren={(
          <Button
            label={'Cancel'}
            variant='neutral'
            type='button'
            onClick={handleCancel}
          />
        )}
        rightChildren={(
          <Button
            label={'Save and Refresh'}
            variant='brand'
            type='submit'
          />
        )}
      >
        <div className='flex--item-fill-lazy' />
      </FlexBarLayout>
    </form>
  );
}


export default class SettingsForm extends ReactComponent {

  // TODO: Allow this to take a prop for whether it references a "new" map zone
  // or not. It can call different submit functions if it is.

  constructor(props) {
    super(props);
    this.formHandler = new FormHandler((data) => {
      return {
        graphicsQuality: data.graphicsQuality || 4,
        graphicsFrameSkip: data.graphicsFrameSkip || 0,
        graphicsPixelRatio: data.graphicsPixelRatio || null,
      };
    }, 'SettingsFormHandler');
    connectToComponentState(this.formHandler, this, 'view', 'form');
    this.addSetup(this.$b._refreshDataFromSettings);
    this.addCleanup(this.formHandler.onSubmit(this.$b.onFormSubmit));
  }

  _refreshDataFromSettings() {
    this.formHandler.initializeData({
      graphicsQuality: AppGlobal.settings.value('graphics.quality'),
      graphicsFrameSkip: AppGlobal.settings.value('graphics.frameSkip'),
      graphicsPixelRatio: AppGlobal.settings.value('graphics.pixelRatio'),
    });
  }

  handleChangeGraphicsQuality(event, data) {
    this.formHandler.setFormFieldAndData('graphicsQuality', data.value);
  }

  handleChangeGraphicsFrameSkip(value) {
    this.formHandler.setFormFieldAndData('graphicsFrameSkip', value);
  }

  handleChangeGraphicsPixelRatio(value) {
    this.formHandler.setFormFieldAndData('graphicsPixelRatio', value);
  }

  async onFormSubmit(data) {
    AppGlobal.settings.patchData({
      'graphics.quality': data.graphicsQuality,
      'graphics.frameSkip': data.graphicsFrameSkip,
      'graphics.pixelRatio': data.graphicsPixelRatio,
    });
    if (this.props.afterSubmit) {
      this.props.afterSubmit(data);
    }
  }

  onCancel() {
    if (this.props.afterCancel) {
      this.props.afterCancel();
    }
  }

  render() {
    return MapZoneDetailsFormView({
      formView: this.state.form,
      handleChangeGraphicsQuality: this.$b.handleChangeGraphicsQuality,
      handleChangeGraphicsFrameSkip: this.$b.handleChangeGraphicsFrameSkip,
      handleSubmit: this.formHandler.$b.handleFormSubmitEvent,
      handleCancel: this.$b.onCancel,
      setFrameskip1: memoBind(this.handleChangeGraphicsFrameSkip, this, 0),
      setFrameskip12: memoBind(this.handleChangeGraphicsFrameSkip, this, 1),
      setFrameskip13: memoBind(this.handleChangeGraphicsFrameSkip, this, 2),
      setFrameskip14: memoBind(this.handleChangeGraphicsFrameSkip, this, 3),
      setPixelDensityAuto: memoBind(this.handleChangeGraphicsPixelRatio, this, null),
      setPixelDensity4: memoBind(this.handleChangeGraphicsPixelRatio, this, 4),
      setPixelDensity3: memoBind(this.handleChangeGraphicsPixelRatio, this, 3),
      setPixelDensity2: memoBind(this.handleChangeGraphicsPixelRatio, this, 2),
      setPixelDensity1: memoBind(this.handleChangeGraphicsPixelRatio, this, 1),
      setPixelDensity12: memoBind(this.handleChangeGraphicsPixelRatio, this, 0.5),
    });
  }

}
