import React from 'react';

import AppGlobal from 'global';
import CenteredBox from 'components/layouts/centered-box';
import LoginForm from 'components/authentication/login-form';
import AppPageTemplateConnected from 'components/templates/app-page-template-connected';
import AppReactComponent from 'utils/app-react-component';

const _REASON_TO_TEXT = {
  [null]: null,
  [undefined]: null,
  'unauthenticated': 'You must be logged in to continue',
  'logout_success': 'You have successfully logged out',
};


export default class LoginPage extends AppReactComponent {

  constructor(props) {
    super(props);
    this.connect('query', AppGlobal.rootRouter, 'query');
  }

  _navigateToNextLoc() {
    let nextloc = this.state.query.nextloc;
    if (!nextloc || !nextloc.startsWith('/')) {
      nextloc = '/app/auth/session';  // Temporary - replace with home dashboard
    }
    AppGlobal.rootRouter.history.push(nextloc);
  }

  render() {
    const query = this.state.query;
    const message = _REASON_TO_TEXT[query.reason];
    return (
      <AppPageTemplateConnected
        headerVisible={true}
        footerVisible={false}
      >
        <CenteredBox className='border-box' style={{width: '400px'}}>
          {/* if */(message) ? (
            <div className='layout--info-container-box layout--center-contents'>
              {message}
            </div>
          )/* end if */ : null}
          <h3 className='slds-text-heading_medium'>Login</h3>
          <LoginForm
            onSuccessfulLogin={this.$b._navigateToNextLoc}
            message={_REASON_TO_TEXT[AppGlobal.rootRouter.query.reason]}
          />
        </CenteredBox>
      </AppPageTemplateConnected>
    );
  }

}
