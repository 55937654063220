import React from 'react';

import DialogBox from 'components/layouts/dialog-box';

import AppGlobal from 'global';
import MapZoneDetailsForm from 'apps/maps/components/map-zone-details-form';
import ReactComponent from 'utils/react-component';
import {connectToComponentState} from 'statelib/fstate-react';


function EmbarkMapZoneEditDialogView({
  rendered,
  onClose,
}) {
  return (
    <DialogBox
      variant='semialpha'
      onClose={onClose}
      rendered={rendered}
    >
      <MapZoneDetailsForm afterSubmit={onClose} />
    </DialogBox>
  );
}


export default class EmbarkMapZoneEditDialog extends ReactComponent {

  static propTypes = {};  // No props - everything is pulled from global state

  constructor(props) {
    super(props);
    this.routerRef = AppGlobal.rootRouter;
    connectToComponentState(this.routerRef, this, 'router');
  }

  _closeDialog() {
    this.routerRef.history.replace(this.routerRef.url);
  }

  render() {
    return EmbarkMapZoneEditDialogView({
      rendered: this.state.router.hash === 'map_zone_edit',
      onClose: this.$b._closeDialog,
    });
  }

}
