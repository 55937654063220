import Squares from 'utils/squares';

import GameObject from './game-object';

const _DEFAULT_BOUNDING_BOX = Squares.zero();


/**
 * Acts as a shim layer between old Map Object's and the new GameObject.
 */
export default class LegacyMapObject extends GameObject {

  static legacyAttach(mapObject, mapViewController, layerName) {
    mapObject._mapViewController = mapViewController;
    const wrappedObject = new LegacyMapObject(mapObject);
    wrappedObject.attach(mapViewController.engine, layerName);
    return wrappedObject;
  }

  constructor(mapObject) {
    super();
    this._mapObject = mapObject;
    this._mapObject.attach = this.$b.attach;
    this._mapObject.detach = this.$b.detach;
    this._mapObject.destroy = this.$b.destroy;
  }

  getBoundingRect() {
    const boundingBox = this._mapObject.getBoundingRect();
    if (boundingBox === _DEFAULT_BOUNDING_BOX) {
      return null;
    }
  }

  destroy() {
    this._mapObject.destroy();
    super.destroy();
  }

  isVisible() {
    return this._mapObject.isVisible();
  }

  isEnabled() {
    return this._mapObject.isEnabled();
  }

  onUpdate(frameUpdateContext) {
    this._mapObject.onUpdate(
      frameUpdateContext.timestamp,
      frameUpdateContext.timeDelta,
      frameUpdateContext.frameNo,
      frameUpdateContext.frame,
      frameUpdateContext.layer,
    );
  }

  onRender(frameRenderContext) {
    this._mapObject.onRender(
      frameRenderContext.context,
      frameRenderContext.layer,
      frameRenderContext.frame
    );
  }

}
