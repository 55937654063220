import lodashUniqueId from 'lodash.uniqueid';

import makeBindable from 'utils/bound-methods.js'
import Rect from 'utils/rect';

import type CanvasEngine from '../canvas-engine';
import type FrameRenderContext from '../frame-render-context';
import type FrameUpdateContext from '../frame-update-context';

// A default bounding box used if the implementing class does NOT specify a
// bounding box. Note that this may mess things up if the object does actually
// take up space
const _DEFAULT_BOUNDING_RECT = Rect.ZERO;


/**
 * A single "game" object associated with a Canvas Engine
 *
 * IDEA: Have some `cleanRender` property that defines whether we need to save
 * and restore context when rendering this object. That may help performance.
 */
export default class GameObject {

  id: string;
  enabled: boolean;
  visible: boolean;
  protected _isDestroyed: boolean;
  protected _engine: CanvasEngine | null;
  protected _engineLayerName: string | null;

  $b: any;
  private $bdestroy: any;

  constructor() {
    makeBindable(this);
    this._isDestroyed = false;
    this.id = lodashUniqueId('GameObject_');
    this.enabled = true;
    this.visible = true;
    this._engine = null;
    this._engineLayerName = null;
  }

  isEnabled(): boolean {
    return this.enabled;
  }

  isVisible(): boolean {
    return this.visible;
  }

  get engine(): CanvasEngine | null {
    return this._engine;
  }

  attach(engine: CanvasEngine, layerName: string): void {
    if (!engine || !layerName) {
      throw new Error('Must include a canvas engine and layer when attaching');
    }
    if (this._engine) {
      this.detach();
    }
    this._engine = engine;
    this._engineLayerName = layerName;
    this._engine.addGameObject(layerName, this);
  }

  /**
   * Detaches this object from its associated engine. Typically
   */
  detach(): void {
    this._engine.removeGameObject(this._engineLayerName, this);
    this._engineLayerName = null;
    this._engine = null;
  }

  /**
   * Returns the box that bounds that map object in map-pixel-space (sometimes
   * called source-pixel-space). A bounding rect of `null` assumes that the
   * object is always visible and will be rendered no matter what. Otherwise,
   * it is possible that an object may be skipped during rendering if it is
   * not contained within the redraw region or view.
   */
  getBoundingRect(): Rect {
    return _DEFAULT_BOUNDING_RECT;
  }

  /**
   * Cleans up any open resources required for the object.
   *
   * If an implementing class adds functionality, it is crucial that it call
   * `super.destroy()` so that the object is cleaned up in the parent controller
   */
  destroy(): void {
    if (this._engine) {
      this.detach();
    }
    this.enabled = false;
    this.visible = false;
    this.$bdestroy();
    this._isDestroyed = true;
  }

  /**
   * Simple way to raise a more meaningful error if the object has already
   * been destroyed.
   */
  throwIfDestroyed(): void {
    if (this._isDestroyed) {
      throw new Error('Object has been destroyed');
    }
  }

  /**
   * Called once per frame _before_ any rendering is performed. This is meant
   * for any calculations that need to occur for the object. This function will
   * only be called if the object is `enabled`. Note that this function will
   * be called EVEN IF the object is ultimately not rendered / off screen.
   *
   * IDEA: Could possible allow this to return the explicit value `false` to
   * prevent the object from being rendered this frame.
   */
  onUpdate(frameUpdateContext: FrameUpdateContext): void {
    // Virtual function - implement in derivative class
  }

  /**
   * Called to draw the object onto the render layer.
   *
   * Note that this should ONLY be used for drawing. Depending on system
   * load, position of the view, etc a component may not always have its
   * `onRender` method called. Note that a component will also not have its
   * `onRender` method called unless it is both `enabled` and `visible`
   */
  onRender(frameRenderContext: FrameRenderContext): void {
    // Virtual function - implement in derivative class
  }

}
