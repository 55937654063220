import React from 'react';
import Memoize from 'memoize-one';

import {VerticalNavigation} from '@salesforce/design-system-react';

import ReactComponent from 'utils/react-component';

const NEW_CAMPAIGN_ITEM = Object.freeze({
  id: '$new',
  label: '\u2795 Add a New Campaign',
  url: 'app/maps/new-campaign',
});


function CampaignSelectorView({
  selectedCampaignId,
  onSelect,
  navCategories,
}) {
  return (
    <div style={{width: '200px'}}>
      <VerticalNavigation
        categories={navCategories}
        selectedId={selectedCampaignId}
        onSelect={onSelect}
      />
    </div>
  );
}

export default class CampaignSelector extends ReactComponent {

  handleSelect(event, data) {
    event.preventDefault();
    if (data.item.id === '$new') {
      this.props.navigateToNewCampaign();
    } else {
      this.props.navigateToCampaign(data.item.id);
    }
  }

  render() {
    return CampaignSelectorView({
      selectedCampaignId: this.props.selectedCampaignId,
      onSelect: this.$b.handleSelect,
      navCategories: this._campaignItemsToNavCategories(
        this._campaignsToNavItems(this.props.campaigns)),
    });
  }

  _campaignsToNavItems = Memoize((campaigns) => {
    campaigns = campaigns || [];
    const campaignItems = [];
    for (const campaign of campaigns) {
      campaignItems.push({
        id: campaign.id,
        label: campaign.name,
        url: `/app/maps/campaigns/${campaign.id}`,
      })
    }
    return campaignItems;
  });

  _campaignItemsToNavCategories = Memoize((campaignItems) => {
    return [
      {
        id: 'control',
        items: [NEW_CAMPAIGN_ITEM],
      },
      {
        id: 'campaigns',
        label: 'Campaigns',
        items: campaignItems,
      },
    ];
  });

}
