import classNames from 'classnames';
import Memoize from 'memoize-one';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from '@salesforce/design-system-react';
import {Input} from '@salesforce/design-system-react';
import {Radio} from '@salesforce/design-system-react';
import {VisualPicker} from '@salesforce/design-system-react';

import AppGlobal from 'global';
import AppReactComponent from 'utils/app-react-component';
import compoundcmp from 'components/form/compound';
import FStateWithAsync from 'statelib/fstate-with-async';
import layouts from 'components/layouts';
import memoBind from 'utils/memo-bound';

import ImageManagerImageEdit from './image-manager-image-edit';

//
//
// TODO 2022-07-12
//
// 1. Make it so that when the user clicks "EDIT" it sets the form data with the
// image being edited
// 2. Hook up the form inputs to the actual form fields (onChange handers)
// 3. Handle submitting the form.
// 4. Handle cancel (which just goes back to the other view)
// 5. Problems if the current image isn't in the sidebar? Might need to always
//    do one extra image search by ID call so that it's available.
//
// FINALLY Fix the sizing on the search bar stuff. It's bad. Way too small.
//


const _PAGE_SIZE = 50;

const _OWNER_TYPE_TO_KEY = Object.freeze({
  'maps': 'mapId',
  'map': 'mapId',
  'campaigns': 'campaignId',
  'campaign': 'campaignId',
});

const _CATEGORY_TO_LABEL = Object.freeze({
  'map': 'Maps',
  'token': 'Tokens',
});

const _IMAGE_SORT_OPTIONS = Object.freeze([
  Object.freeze({label: 'Name', value:'name asc'}),
  Object.freeze({label: 'Recent', value:'created desc'}),
])

const _IMAGE_PREVIEW_STYLE = {
  maxWidth: '100%',
  maxHeight: '100%',
  display: 'block',
  margin: 'auto',
  objectFit: 'scale-down',
  height: '100%',
  width: '100%',
}

class _ImageManagerBrowserState extends FStateWithAsync {

  static DEFAULT_NAME = 'ImageManagerBrowserState';

  constructor(options) {
    super(options);
  }

  makeInitialData() {
    return {
      searchTerm: null,
      sorting: null,
      searchCategory: null,
      images: [],
      nextSearchOffset: 0,  // -1 if there are no more images to search
    };
  }

}


/**
 * docstring
 */
export default class ImageManagerBrowser extends AppReactComponent {

  static propTypes = {
    categories: PropTypes.arrayOf(PropTypes.string),
    isEditing: PropTypes.bool,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onChangeIsEditing: PropTypes.func,
    onSubmit: PropTypes.func,
    ownerId: PropTypes.string.isRequired,
    ownerType: PropTypes.string.isRequired,
    selectedImageId: PropTypes.string,
    imageChangeSubscribe: PropTypes.func,
  };

  static defaultProps = {
    selectedImageId: null,
  };

  constructor(props) {
    super(props);
    this._imagesState = new _ImageManagerBrowserState();
    this._imagesState.patchData({
      searchCategory: props.categories ? props.categories[0] : null
    })
    this.pubSubscribe('imageChangeSubscribe', this.$b._handleImageChanged);
    this.connect('async', this._imagesState, 'async');
    this.connect('search', this._imagesState);
    this.addSetup(this.$b._loadNextSearchPage);
    this._scrollableContainer = null;
  }

  _setScrollableContainerRef(elem) {
    const prevScrollableContainer = this._scrollableContainer;
    this._scrollableContainer = elem;
    if (elem && elem !== prevScrollableContainer) {
      elem.addEventListener("scroll", this.$b._handleContainerScroll);
    }
  }

  _handleImageChanged({image, select}) {
    // `select` is handled by the image manager (since it owns the selected image)
    this._refreshSearch();
  }

  _handleContainerScroll(event) {
    const elem = this._scrollableContainer;
    if (event.target !== elem) {
      console.warn("Scrolling target mismatch");
    }
    if (
      this._imagesState.getValue('nextSearchOffset') >= 0 &&
      elem.scrollHeight - elem.scrollTop - elem.clientHeight < 1
    ) {
      this._loadNextSearchPage();
    }
  }

  _refreshSearch() {
    this._imagesState.patchData({
      nextSearchOffset: 0,
    });
    this._loadNextSearchPage();
  }

  async _loadNextSearchPage() {
    await this._imagesState.asyncState.wrap(async (throwIfCanceled) => {
      const ownerId = this.props.ownerId;
      const ownerType = this.props.ownerType;
      const searchOffset = this._imagesState.getValue('nextSearchOffset');
      const searchTerm = this._imagesState.getValue('searchTerm');
      const sorting = this._imagesState.getValue('sorting');
      const searchCategory = this._imagesState.getValue('searchCategory');
      const searchObj = {
        [_OWNER_TYPE_TO_KEY[ownerType]]: ownerId,
        searchTerm: searchTerm,
        categories: (searchCategory) ? [searchCategory] : null,
        offset: searchOffset,
        limit: _PAGE_SIZE,
        sorting: sorting,
      }
      const selectedImageId = this.props.selectedImageId;
      // If it's known that there are no images, don't try to load anything
      if (searchOffset < 0) {
        return;
      }
      let prevImages = this._imagesState.getValue('images');
      if (searchOffset === 0 || !prevImages) {
        prevImages = [];
      }
      const prevImageIds = new Set();
      for (const prevImage of prevImages) {
        prevImageIds.add(prevImage.id);
      }
      let nextImages = [...prevImages];
      // If there is a selected ID and it does not exist yet, be sure that it
      // is included in the search results
      if (selectedImageId) {
        if (!prevImageIds.has(selectedImageId)) {
          const selectedImage = await AppGlobal.imageStore.getRecord(selectedImageId, true);
          throwIfCanceled();
          if (selectedImage) {
            nextImages.push(selectedImage);
            prevImageIds.add(selectedImage.id);
          }
        }
      }
      // Search for any matching images and add them as well (assuming they
      // don't already exist in the list of images
      const foundImages = await AppGlobal.imageStore.searchImages(searchObj);
      throwIfCanceled();
      const newImages = foundImages.filter(newImage => !prevImageIds.has(newImage.id));
      nextImages = nextImages.concat(newImages);
      // TODO: Check to see that none of the search criteria in state/props
      // have changed since this request started. If they have abandon the results.
      this._imagesState.patchData({
        images: nextImages,
        nextSearchOffset: (foundImages.length < _PAGE_SIZE) ? -1 : searchOffset + _PAGE_SIZE,
      });
    });
  }

  _handleChangeImageRadio(imageId, event) {
    if (this.props.isEditing) {
      this._handleCancelEditImage(event);
    }
    if (this.props.onChange) {
      this.props.onChange(event, imageId);
    }
  }

  _handleClickSave(event) {
    if (this.props.onSubmit) {
      this.props.onSubmit(event, this.props.selectedImageId);
    }
  }

  _handleClickCancel(event) {
    if (this.props.onCancel) {
      this.props.onCancel(event);
    }
  }

  _handleClickEditImage(event) {
    this.props.onChangeIsEditing(true);
  }

  _handleCancelEditImage(event) {
    this.props.onChangeIsEditing(false);
  }

  async _handleSubmitEditImage(event, imageData) {
    // TODO: Probably need to share the async context between this and the
    // edit form.
    await this._imagesState.asyncState.wrap(async (throwIfCanceled) => {
      await AppGlobal.imageStore.patchImage(imageData);
    });
    this._handleImageChanged({image: imageData, select: false});
    this.props.onChangeIsEditing(false);
  }

  _handleChangeFilter(event, filterData) {
    this._imagesState.setValueIfChanged('searchTerm', filterData.filterText);
    this._imagesState.setValueIfChanged('sorting', filterData.sortValue);
    this._imagesState.setValueIfChanged('searchCategory', filterData.entityValue);
    this._refreshSearch();
  }

  render() {
    const props = this.props;
    const state = this.state;
    const images = state.search.images || [];
    const selection = images.filter(image => image.id === props.selectedImageId);
    const selectedImage = selection.length ? selection[0] : null;
    // TODO: This seems like an efficiency disaster. VisualPicker is probably
    // not the right choice in the long run, but it's good enough for the time
    // being
    return (
      <layouts.TwoPanelLayout
        classNamePanel={classNames(
          'flex--container-vertical',
          'layout--padding-all-xsmall',
        )}>
        <React.Fragment>
          <compoundcmp.FilterAndSort
            onChange={this.$b._handleChangeFilter}
            placeholderFilterText='Search images'
            initialSortValue='name asc'
            initialEntityValue={(props.categories) ? props.categories[0] : ''}
            entityOptions={this._getEntityOptions(props.categories)}
            sortOptions={_IMAGE_SORT_OPTIONS}
            className='layout--margin-bottom-small flex--item-fixed'
            compact={true}
            hasSpinner={state.async.isRunning}
          />
          <div
            className='flex--item-fill'
            ref={this.$b._setScrollableContainerRef}
          >
            <VisualPicker
              className={'image-visual-picker'}
              vertical={true}
            >
              {images.map((value, idx) => (
                <Radio
                  key={value.id}
                  value={value.id}
                  onRenderVisualPicker={() => (
                    <span>{value.name}</span>
                  )}
                  checked={value.id === props.selectedImageId}
                  onChange={memoBind(this._handleChangeImageRadio, this, value.id)}
                >
                </Radio>
              ))}
            </VisualPicker>
          </div>
        </React.Fragment>
        <React.Fragment>
          {/* if */(!selectedImage) ? (
            <React.Fragment>
            <div className='flex--item-fill'>Select an image</div>
            <layouts.TwoButtonPanel className='flex--item-fixed'>
                <Button
                  label='Use this Image'
                  variant='brand'
                  disabled={true}
                />
                <Button
                  label='Cancel'
                  variant='neutral'
                  onClick={this.$b._handleClickCancel}
                />
              </layouts.TwoButtonPanel>
            </React.Fragment>
          )/* else */ : (
            <React.Fragment>
              {/* if */(props.isEditing) ? (
                <ImageManagerImageEdit
                  className={classNames(
                    'flex--container-vertical',
                    'layout--fill-box',
                  )}
                  onSubmit={this.$b._handleSubmitEditImage}
                  onCancel={this.$b._handleCancelEditImage}
                  initialImage={selectedImage}
                />
              )/* else */ : (
                <div
                  className={classNames(
                    'flex--container-vertical',
                    'layout--fill-box',
                  )}
                >
                  <div
                    className={classNames(
                      'flex--item-fixed',
                      'flex--container-horizontal',
                      'layout--margin-bottom-small',
                    )}
                  >
                    <Input
                      className='flex--item-fill layout--margin-right-small'
                      disabled={true}
                      value={selectedImage.name}
                    />
                    <Button
                      label='Edit'
                      variant='neutral'
                      onClick={this.$b._handleClickEditImage}
                    />
                  </div>
                  <div className='flex--item-fill layout--margin-bottom-small'>
                    <a href={selectedImage.url} target='_blank'>
                      <img
                        src={selectedImage.thumbnailUrl}
                        style={_IMAGE_PREVIEW_STYLE}
                        alt={`Preview of "${selectedImage.name}" image`}
                      />
                    </a>
                  </div>
                  <div
                    className={classNames(
                      'image-subtitle',
                      'flex--item-fixed',
                      'layout--margin-bottom-small',
                    )}
                  >
                    <a href={selectedImage.url} target='_blank'>
                      View full image ({selectedImage.width} x {selectedImage.height})
                    </a>
                  </div>
                  <layouts.TwoButtonPanel className='flex--item-fixed'>
                    <Button
                      label='Use this Image'
                      variant='brand'
                      onClick={this.$b._handleClickSave}
                    />
                    <Button
                      label='Cancel'
                      variant='neutral'
                      onClick={this.$b._handleClickCancel}
                    />
                  </layouts.TwoButtonPanel>
                </div>
              )/* endif */}
            </React.Fragment>
          )/* endif */}
        </React.Fragment>
      </layouts.TwoPanelLayout>
    );
  }

  _getEntityOptions = Memoize((categories) => {
    const options = [];
    if (categories) {
      for (const category of categories) {
        options.push({label: _CATEGORY_TO_LABEL[category], value: category});
      }
    }
    options.push({label: 'All', value: ''});
    return options;
  })

}
