import React from 'react';

import {Input} from '@salesforce/design-system-react';
import {Button} from '@salesforce/design-system-react';
import {Checkbox} from '@salesforce/design-system-react';

import AppGlobal from 'global';
import FormHandler from 'formlib/form-handler';
import ReactComponent from 'utils/react-component';
import QueuedAsyncState from 'statelib/queued-async-state';
import {connectToComponentState} from 'statelib/fstate-react';


function MapDetailsFormView({
  async,
  formView,
  // handleCancel,
  handleChangeName,
  handleChangePublic,
  handleChangePublicName,
  handleSubmit,
}) {
  const isLoading = async.isRunning;
  return (
    <form onSubmit={handleSubmit}>
      <Input
        name='name'
        label='Name'
        value={formView.name.value}
        onChange={handleChangeName}
        disabled={isLoading}
        errorText={formView.name.error}
        required={true}
      />
      <Input
        name='publicName'
        label='Public Name'
        value={formView.publicName.value}
        onChange={handleChangePublicName}
        disabled={isLoading}
        errorText={formView.publicName.error}
        required={true}
      />
      <Checkbox
        labels={{
          label: 'Show to Players',
        }}
        id='checkbox-toggle-example'
        variant='toggle'
        checked={formView.public.value}
        onChange={handleChangePublic}
      />
      <div>
        <Button
          disabled={isLoading}
          className='big-button'
          label={'Save Changes'}
          variant='brand'
          type='submit'
        />
      </div>
    </form>
  );
}


export default class MapDetailsForm extends ReactComponent {

  // TODO: Allow this to take a prop for whether it references a "new" map or
  // not. It can call different submit functions if it is.

  constructor(props) {
    super(props);
    this.mapRef = this.constantProp('mapRef', AppGlobal.mapDetails);
    this.async = new QueuedAsyncState();
    this.formHandler = new FormHandler((data) => {
      return {
        name: data.name || '',
        publicName: data.publicName || '',
        public: data.public || false,
      };
    }, 'MapDetailsFormHandler');
    connectToComponentState(this.async, this, 'async');
    connectToComponentState(this.mapRef, this, 'mapDetails');
    this.formHandler.connectFieldsToComponentState(this, 'form');
    this.addCleanup(
      this.mapRef.subscribe(this.formHandler.$b.initializeData, {immediate: true, path: 'map'}),
      this.formHandler.onSubmit(this.$b.onFormSubmit),
    );
  }

  handleChangeName(event, data) {
    this.formHandler.setFormFieldAndData('name', data.value);
  }

  handleChangePublicName(event, data) {
    this.formHandler.setFormFieldAndData('publicName', data.value);
  }

  handleChangePublic(event, data) {
    this.formHandler.setFormFieldAndData('public', data.checked);
  }

  async onFormSubmit(data) {
    await this.async.wrap(async (throwIfCanceled) => {
      await this.mapRef.patchDetails(data, true);
    });
    if (this.props.afterSubmit) {
      this.props.afterSubmit(data);
    }
  }

  render() {
    return MapDetailsFormView({
      async: this.state.async,
      formView: this.state.form,
      handleChangeName: this.$b.handleChangeName,
      handleChangePublic: this.$b.handleChangePublic,
      handleChangePublicName: this.$b.handleChangePublicName,
      handleSubmit: this.formHandler.$b.handleFormSubmitEvent,
    });
  }

}
