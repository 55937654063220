import MapObjectStoreRef from "apps/maps/state/map-object-store-ref";
import frameUpdateContext from "canvasengine/frame-update-context";
import BaseMapToken from "./base-map-token";


/**
 * A version of Map Token that is meant to show the user where a token is
 * currently being placed. For example, when the user is dragging a new token
 * onto the map. This token is not meant to be a true reflection of the
 * underlying token data - instead it's sort of a depiotion of where a token
 * COULD be.
 */
export default class GhostMapToken extends BaseMapToken {

  constructor(grid: unknown, tokenStoreRef: MapObjectStoreRef);
  constructor(grid: unknown, tokenId: string);
  constructor(grid: unknown, tokenInfo: MapObjectStoreRef | string) {
    super(grid, tokenInfo as any);  // `as any` to handle weird typscript nonsense
    this._opacity = 0.7;
  }

  // Ghost map tokens are not selectable/focusable.
  get isFocused(): boolean {
    return false;
  }

  // Ghost map tokens are not selectable/focusable.
  set isFocused(value: boolean) {
    return;
  }

  // Ghost map tokens are not selectable/focusable.
  get isSelected(): boolean {
    return false;
  }

  // Ghost map tokens are not selectable/focusable.
  set isSelected(value: boolean) {
    return;
  }

  /**
   * A dragging map toke ignores the token's "real" location.
   */
  getRealCoordinates(): [x: number, y: number] {
    if (this._localX !== null && this._localY !== null) {
      return [this._localX, this._localY];
    }
    return [null, null];
  }

  setGridLocationFromPointer(snapping?: number) {
    // TODO: The cursor should reference the middle of the token rather than
    // the upper-left corner
    const x = this._engine.getCursorX();
    const y = this._engine.getCursorY();
    if (x === null || y === null) {
      this._localX = null;
      this._localY = null;
    } else {
      const tokenDetails = this._tokenData.token;
      const tokenGridWidth: number = tokenDetails.gridWidth || 1;
      const tokenGridHeight: number = tokenDetails.gridHeight || 1;
      this.setGridLocation(
        this._grid.pixelToGridCol(x, y) - ((tokenGridWidth / 2) - (snapping / 2)),
        this._grid.pixelToGridRow(x, y) - ((tokenGridHeight / 2) - (snapping / 2)),
        snapping,
      );
    }
  }

  setGridLocation(col: number, row: number, snapping?: number) {
    if (col === null || row === null) {
      this._localX = null;
      this._localY = null;
    } else if (!snapping || snapping >= 1) {
      col = Math.floor(col);
      row = Math.floor(row);
      this._localX = this._grid.gridExactToPixelX(col);
      this._localY = this._grid.gridExactToPixelY(row);
    } else {
      col = col - (col % snapping);
      row = row - (row % snapping);
      this._localX = this._grid.gridExactToPixelX(col);
      this._localY = this._grid.gridExactToPixelY(row);
    }
  }

  onUpdate(frameUpdateContext: frameUpdateContext): void {
    super.onUpdate(frameUpdateContext);
  }

}
