
export function identity(value) {
  return value;
}


export function protoEnumToFormValue(dataValue) {
  if (dataValue && dataValue !== '0') {
    return `${dataValue}`;
  }
  return '';
}


export function formValueToProtoEnum(formValue) {
  if (formValue && formValue !== '0') {
    return parseInt(formValue);
  }
  return 0;  // `0` is null in proto enum terms
}


export function intToFormValue(dataValue) {
  if (dataValue === null || dataValue === undefined) {
    return '';
  }
  return `${dataValue}`;
}


export function formValueToInt(formValue) {
  if (!formValue || !formValue.trim()) {
    return null;
  }
  if (!formValue.match(/^\s*((-?)\d+)\s*$/)) {
    throw new Error('Value must be a number');
  }
  return parseInt(formValue);
}


export function floatToFormValue(dataValue, fixed) {
  if (dataValue || dataValue === 0) {
    if (fixed !== undefined && fixed !== null) {
      dataValue = dataValue.toFixed(fixed);
    }
    return `${dataValue}`;
  }
  return '';
}


export function formValueToFloat(formValue) {
  if (formValue) {
    let floatValue = parseFloat(formValue);
    return floatValue;
  }
  return null;
}


/**
 * An object designed to apply changes to form field values.
 *
 * This is specifically meant to transform/validate form field values into
 * well defined data values.
 */
export class Transform {

  constructor(func, curTransform) {
    if (curTransform) {
      this._chain = [...curTransform._chain];
    } else {
      this._chain = [];
    }
    if (func) {
      this._chain.push(func);
    }
  }

  get func() {
    const chain = [...this._chain];
    const chainReducer = (curValue, chainFn) => chainFn(curValue);
    return (formValue) => {
      if (formValue === undefined) {
        formValue = null;
      }
      return chain.reduce(chainReducer, formValue);
    }
  }

  rounded(decimalPlaces) {
    const factor = 10 ** decimalPlaces;
    return new Transform((value) => {
      return Math.round(factor * value) / factor;
    }, this);
  }

  required() {
    return new Transform((value) => {
      if (value === null || value === undefined || value === '') {
        throw new Error('A value is required');
      }
      return value;
    }, this);
  }

  greaterThanOrEqual(threshold) {
    return new Transform((value) => {
      if (value < threshold) {
        throw new Error(`Value must be at least ${threshold}`);
      }
      return value;
    }, this);
  }

}

