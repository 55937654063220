import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from '@salesforce/design-system-react';
import {Input} from '@salesforce/design-system-react';

import FormReactComponent from 'utils/form-react-component';
import layouts from 'components/layouts';

import ImageManagerCategorySelect from './image-manager-category-select';

/**
 * A form for modifying an existing image
 *
 * The weirdest thing about this form is that images (and thus the underlying
 * data model) has categories plural, but the form operates on a single
 * category. It works fine, but ends up being a little odd.
 */
export default class ImageManagerImageEdit extends FormReactComponent {

  static propTypes = {
    className: PropTypes.string,
    initialImage: PropTypes.object,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props, {initialDataProp: 'initialImage'});
  }

  dataToForm(data) {
    return {
      id: data.id,
      name: data.name || '',
      category: data.categories && data.categories.length ? data.categories[0] : null,
    };
  }

  handleSubmit(data, form) {
    // TODO: Ho do we get this to have an event?
    const event = null;
    form.validateFieldDataTruthy('name');
    form.validateFieldData(
      'categories', value => value && value.length === 1, 'Please select a category'
    );
    if (!form.hasErrors) {
      if (this.props.onSubmit) {
        this.props.onSubmit(event, data);
      }
    }
  }

  _handleCancel(event) {
    if (this.props.onCancel) {
      this.props.onCancel(event);
    }
  }

  _handleChangeName(event, data) {
    this.form.setFormFieldAndData('name', data.value);
  }

  _handleChangeCategory(event, value) {
    this.form.setFormField('category', value);
    if (value) {
      this.form.setDataField('categories', [value]);
    } else {
      this.form.setDataField('categories', []);
    }
  }

  render() {
    const props = this.props;
    const state = this.state;
    return (
      <form
        className={classNames(props.className)}
        onSubmit={this.form.$b.handleFormSubmitEvent}
      >
        <div className='flex--item-fill'>
          <Input
            name='name'
            label='Image Name'
            value={state.fields.name.value}
            onChange={this.$b._handleChangeName}
            required={true}
            disabled={state.async.isRunning}
          />
          <ImageManagerCategorySelect
            label='Category'
            value={state.fields.category.value}
            onChange={this.$b._handleChangeCategory}
            required={true}
            disabled={state.async.isRunning}
          />
        </div>
        <layouts.TwoButtonPanel className='flex--item-fixed'>
          <Button
            label='Save Changes'
            variant='brand'
            type='submit'
          />
          <Button
            label='Cancel'
            variant='neutral'
            onClick={this.$b._handleCancel}
          />
        </layouts.TwoButtonPanel>
      </form>
    );
  }

}
