import MapsRPCAPI from 'apis/maps-rpc-api';

import MapStoreRef from './map-store-ref';
import CampaignScopedRecordStoreState from './campaign-scoped-record-store-state';

type MapRecordType = any;  // TODO: Fix me


/**
 * Handles loading map information
 */
export default class MapStoreState extends CampaignScopedRecordStoreState<MapRecordType>{

  static DEFAULT_NAME = 'MapStore';

  constructor(options) {
    super({...options, keyField: 'id'});
  }

  destroy() {
    super.destroy();
  }

  protected _handleCampaignChange(data) {
    super._handleCampaignChange(data);
    if (data.maps) {
      const mapIds = data.maps.map(value => value.id);
      this.loadLazy(mapIds);
    }
  }

  getRecordRef(options, key?: KeyType): MapStoreRef {
    const recordRef = new MapStoreRef(this, options);
    if (key) {
      recordRef.key = key;
    }
    return recordRef;
  }

  /**
   * Loads multiple maps by key (id).
   *
   * Note that "loading" new keys is safe - they will be ignored. This is
   * currently EXTREMELY slow.
   */
  async load(keys: KeyType[]) {
    if (keys) {
      keys = keys.filter(key => !this.isNewKey(key));
    }
    const campaignId = this.campaignId;
    if (!campaignId) {
      console.warn('Attempting to load Map Zones but no campaign');
      return;
    }
    try {
      await this._async.wrap(async (throwIfCanceled) => {
        const result = await MapsRPCAPI.getMaps({mapIds: keys});
        throwIfCanceled();
        this.setRecords(result.maps || [], {expectedKeys: keys});
      });
    } catch (err) {
      console.error(`${this.name}: Unable to load maps (${keys})`, err);
      throw err;
    }
  }

  async getAllMapsForCampaign(): Promise<MapRecordType[]> {
    const campaignId = this.campaignId;
    return await this._async.wrap(async (throwIfCanceled) => {
      const result = await MapsRPCAPI.searchMaps({campaignId: campaignId});
      throwIfCanceled();
      const maps: MapRecordType[] = result.maps || [];
      this.setRecords(maps);
      return maps;
    });
  }

}
