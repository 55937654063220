import Memoize from 'memoize-one';
import React from 'react';

import {Button} from '@salesforce/design-system-react';
import {Combobox} from '@salesforce/design-system-react';
import {Dropdown} from '@salesforce/design-system-react';
import {Input} from '@salesforce/design-system-react';
import {Textarea} from '@salesforce/design-system-react';

import FlexBarLayout from 'components/layouts/flex-bar-layout';

import AppGlobal from 'global';
import FormHandler from 'formlib/form-handler';
import MapsRPCAPI from 'apis/maps-rpc-api';
import QueuedAsyncState from 'statelib/queued-async-state';
import ReactComponent from 'utils/react-component';
import RecordCollectionState from 'statelib/record-collection-state';
import {connectToComponentState} from 'statelib/fstate-react';
import {extractRecordById} from 'utils/collections';


export default class NewMapForm extends ReactComponent {

  constructor() {
    super();
    this.async = new QueuedAsyncState();
    this.campaignList = new RecordCollectionState(
      this.$b._getCampaigns, {async: this.async}
    );
    this.formHandler = new FormHandler((data) => {
      return {
        campaignId: data.campaignId || null,
        name: data.name || '',
        publicName: data.publicName || '',
        description: data.description || '',
      };
    }, 'NewMapForm');
    connectToComponentState(this.campaignList, this, 'campaignList');
    connectToComponentState(this.async, this, 'async');
    this.addSetup(this.campaignList.$b.refresh);
    this.addCleanup(
      AppGlobal.rootRouter.subscribe(this.$b.setValuesFromQuery, {immediate: true, path: 'query'}),
      this.formHandler.connectFieldsToComponentState(this, 'form'),
      this.campaignList.$b.close,
    );
  }

  setValuesFromQuery(query) {
    const dataFromQuery = {};
    if (query.campaignId) {
      dataFromQuery.campaignId = query.campaignId;
    }
    this.formHandler.initializeData(dataFromQuery, true);
  }

  handleSelectedCampaignChange(event, data) {
    if (data.selection && data.selection.length) {
      this.formHandler.setFormFieldAndData('campaignId', data.selection[0].id);
    } else {
      this.formHandler.setFormFieldAndData('campaignId', null);
    }
  }

  handleChangeName(event, data) {
    this.formHandler.setFormFieldAndData('name', data.value);
  }

  handleChangePublicName(event, data) {
    this.formHandler.setFormFieldAndData('publicName', data.value);
  }

  handleChangeDescription(event) {
    this.formHandler.setFormFieldAndData('description', event.target.value);
  }

  async _getCampaigns(throwIfCanceled) {
    const campaignsResult = await MapsRPCAPI.getMyCampaigns({});
    throwIfCanceled();
    if (!campaignsResult.campaigns || campaignsResult.campaigns.length < 1) {
      console.warn('No campaigns found. Create a campaign first');
      return [];
      // TODO: In this case, redirect them to the new campaign form.
    }
    return campaignsResult.campaigns;
  }

  async submit(event) {
    event.preventDefault();
    try {
      const map = await this.async.wrap(async () => {
        return await MapsRPCAPI.newMap(this.formHandler.getData());
      });
      this.props.afterSubmit(map);
    } catch (err) {
      console.error('Failed to create map', err);
      this.formHandler.setFormFieldError('$all', `${err}`);
    }
  }

  cancel(event) {
    this.props.afterCancel();
  }

  _getCampaignItems = Memoize((campaigns) => {
    return campaigns.map(campaign => ({
      label: campaign.name,
      id: campaign.id,
    }));
  });

  render() {
    const isLoading = this.state.async.isRunning;
    const fields = this.state.form;
    const campaignOptions = this._getCampaignItems(this.state.campaignList.records);
    const campaignSelection = campaignOptions.filter(
      (campaignOption) => campaignOption.id === fields.campaignId.value
    );
    // TODO: I think using `Drodown` here is incorrect - this is meant to be
    // for a menu (like the map zone select) not a field input.
    return (
      <form onSubmit={this.$b.submit}>
        <Combobox
          labels={{
            label: 'Campaign',
            placeholder: 'Select a Campaign',
          }}
          events={{
            onSelect: this.$b.handleSelectedCampaignChange,
          }}
          onSelect={this.$b.handleSelectedCampaignChange}
          options={campaignOptions}
          selection={campaignSelection}
          variant='readonly'
          required={true}
        />
        <Input
          name='name'
          label='Name'
          value={fields.name.value}
          onChange={this.$b.handleChangeName}
          errorText={fields.name.error}
          disabled={isLoading}
          required={true}
        />
        <Input
          name='publicName'
          label='Public Name'
          value={fields.publicName.value}
          onChange={this.$b.handleChangePublicName}
          errorText={fields.publicName.error}
          placeholder={fields.name.value}
          disabled={isLoading}
        />
        <Textarea
          name='description'
          label='Description'
          value={fields.description.value}
          onChange={this.$b.handleChangeDescription}
          errorText={fields.description.error}
          disabled={isLoading}
        />
        <FlexBarLayout className='slds-p-top_large'>
          <Button
            disabled={isLoading}
            className='big-button'
            label={isLoading ? 'Create Map' : 'Create Map'}
            variant='brand'
            type='submit'
          />
        </FlexBarLayout>
      </form>
    );
  }

}
