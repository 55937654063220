import uniqueId from 'lodash/uniqueid';


class _Task {

  constructor(taskFn, args) {
    this._id = `SerialAsyncTask_${uniqueId()}`;
    this._taskFn = taskFn;
    this._args = args || [];
    this._resolve = null;
    this._reject = null;
    this._promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  get id() {
    return this._id;
  }

  get taskFn() {
    return this._taskFn;
  }

  get promise() {
    return this._promise;
  }

  async execute() {
    try {
      const res = await this._taskFn(...this._args);
      this._resolve(res);
    } catch (err) {
      this._reject(err);
    }
  }

  cancel(err) {
    if (!err) {
      err = new Error('Task canceled');
    }
    this._reject(err);
  }

}


export default class SerialAsync {

  constructor() {
    this._tasks = [];
    this._curTask = null;
    this._boundExecuteNext = this._executeNext.bind(this);
  }

  async submit(fn, ...args) {
    const task = new _Task(fn, args);
    this._tasks.push(task);
    if (!this._curTask) {
      this._executeNext();
    }
    return await task.promise;
  }

  cancelAll() {
    for (const task of _tasks) {
      task.cancel();
    }
  }

  _executeNext() {
    if (this._tasks.length < 1) {
      return;  // Nothing to execute
    }
    const nextTask = this._tasks.shift();
    this._curTask = nextTask;
    nextTask.execute().finally(() => this._executeComplete(nextTask));
  }

  _executeComplete(task) {
    if (task !== this._curTask) {
      console.warn('Synchronized async task runner out of sync');
      return;
    }
    this._curTask = null;
    this._executeNext();
  }

}
