import React from 'react';
import {withRouter} from 'react-router';

import shared from 'global';
import MapZoneDetailsRef from 'apps/maps/state/map-zone-details-ref';


/**
 * Provides router-based Shared Map Zone Details.
 */
class SharedMapZoneDetailsLoader extends React.PureComponent {

  constructor() {
    super();
    shared.provideFactory('mapZoneDetails', MapZoneDetailsRef.factory);
  }

  componentDidMount() {
    shared.mapZoneDetails.mapZoneId = this.props.match.params.zoneId;
  }

  componentDidUpdate(prevProps) {
    shared.mapDetails.mapZoneId = this.props.match.params.zoneId;
  }

  render() {
    return this.props.children;
  }

}


export default withRouter(SharedMapZoneDetailsLoader);
