import AppGlobal from 'global';
import Squares from 'utils/squares';
import makeBindable from 'utils/bound-methods';

import {maps as mapsPb} from 'proto-bundle';

const ACK_MESSAGE = new TextEncoder().encode("ack");


function compareUInt8Buffers(buf1, buf2) {
  if (buf1 === buf2) {
    return true;
  } else if (!buf1 || !buf2) {
    return false;
  } else if (buf1.length !== buf2.length) {
    return false;
  }
  const len = buf1.length;
  for (let i = 0; i < len; i++) {
    if (buf1[i] !== buf2[i]) {
      return false;
    }
  }
  return true;
}


export default class EmbarkWebSocket {

  constructor() {
    makeBindable(this);
    this._campaignId = null;
    this._wsConnection = null;
    this._campaignUnsub = AppGlobal.campaignDetails.subscribe(this.$b.onCampaignData);
    this._requestTracker = AppGlobal.requestTracker;
    this._lastEventId = null;
    // Ping the websocket periodically to keep Heroku from killing it.
    this._pingInterval = setInterval(this.$b.ping, 30000);
  }

  destroy() {
    this._campaignUnsub();
    if (this._wsConnection) {
      this._wsConnection.onclose = null;
      try {
        this._wsConnection.close();
      } catch (err) {
        console.warn('Unable to close websocket', err);
      }
      this._wsConnection = null;
    }
    clearInterval(this._pingInterval);
  }

  onCampaignData(data) {
    if (data.async.isRunning) {
      // Wait for it to finish loading before connecting
    } else {
      const campaignId = data.campaignId;
      if (campaignId !== this._campaignId) {
        this._campaignId = campaignId;
        if (this._wsConnection) {
          this._wsConnection.onclose = null;
          this._wsConnection.close();
          this._wsConnection = null;
        }
        this.connect();
      }
    }
  }

  connect() {
    const campaignId = this._campaignId;
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
    const url = `${protocol}//${window.location.host}/ws/maps/v1/campaign/${campaignId}`;
    console.info('Connecting to embark websocket', url);
    try {
      this._wsConnection = new WebSocket(url);
    } catch (err) {
      console.info('Failed to establish embark websocket', err);
      throw err;
    }
    this._wsConnection.onclose = this.$b.reconnect;
    this._wsConnection.onerror = null;
    this._wsConnection.onmessage = this.$b.handleMessage;
  }

  reconnect() {
    // Triggers a reconnect on the next frame
    this._wsConnection.onclose = null;
    this._wsConnection.onerror = null;
    this._wsConnection.onmessage = null;
    this._wsConnection = null;
    setTimeout(this.$b.connect);
  }

  ping() {
    if (!this._wsConnection) {
      console.warn("Ping interval occurred but there is no websocket");
      return;
    }
    this._wsConnection.send("ping");
    console.debug("Sent websocket ping");
  }

  /**
   * Handles messages from the websocket server.
   *
   * Note that all messages are assumed to be in binary format. In the future we
   * could use the protocol options to specify whether they are in json or
   * binary, but for not everything else is binary encoded so.... :shrug:
   */
  async handleMessage(event) {
    let eventObj;
    try {
      const buffer = await event.data.arrayBuffer();
      const bufferArr = new Uint8Array(buffer);
      if (compareUInt8Buffers(bufferArr, ACK_MESSAGE)) {
        console.debug("Received ping-ack");
        return;
      }
      eventObj = mapsPb.CampaignEvent.decode(bufferArr);
    } catch (err) {
      console.error('Failed to process websocket event', event, err);
    }
    if (eventObj.id === this._lastEventId) {
      console.info(`Duplicate event '${eventObj.id}'`, eventObj);
      return;
    } else if (this._requestTracker.hasRequest(eventObj.clientRequestId)) {
      console.debug(`Skipping self-triggered event '${eventObj.id}' / '${eventObj.clientRequestId}'`);
      return;
    }
    this._lastEventId = eventObj.id;
    console.debug('Received websocket event', eventObj);
    if (eventObj.campaignId !== this._campaignId) {
      return;
    }
    if (eventObj.mapZoneExploredPatch !== null) {
      await this.updateVisibilityOverride(
        eventObj.mapZoneExploredPatch, 'EXPLORED_PATCH_TARGET'
      );
    } else if (eventObj.mapZoneOverrideHiddenPatch !== null) {
      await this.updateVisibilityOverride(
        eventObj.mapZoneOverrideHiddenPatch, 'HIDDEN_PATCH_TARGET'
      );
    } else if (eventObj.mapZoneOverrideVisiblePatch !== null) {
      await this.updateVisibilityOverride(
        eventObj.mapZoneOverrideVisiblePatch, 'VISIBLE_PATCH_TARGET'
      );
    } else if (eventObj.mapDetailsUpdate !== null) {
      await this.updateMapDetails(eventObj.mapDetailsUpdate.mapId);
    } else if (eventObj.mapZoneDetailsUpdate !== null) {
      await this.updateMapZoneDetails(
        eventObj.mapZoneDetailsUpdate.mapId, eventObj.mapZoneDetailsUpdate.mapZoneId
      );
    } else if (eventObj.mapObjectUpdate !== null) {
      await this.updateMapObjectStore(eventObj.mapObjectUpdate.mapObjectId);
    } else {
      console.warn('Event object data unrecognized', eventObj);
    }
  }

  async updateVisibilityOverride(maskPatch, target) {
    const mapId = maskPatch.mapId;
    const mapZoneId = maskPatch.mapZoneId;
    const mapZoneContainer = AppGlobal.mapZoneDetails;
    if (mapZoneContainer.mapZoneId !== mapZoneId) {
      console.debug('Skipping mask update because ids dont match');
      return;
    }
    let maskContainer = null;
    if (target === 'EXPLORED_PATCH_TARGET') {
      maskContainer = AppGlobal.mapZoneExploredMask;
    } else if (target === 'HIDDEN_PATCH_TARGET') {
      maskContainer = AppGlobal.mapZoneOverrideHiddenMask;
    } else if (target === 'VISIBLE_PATCH_TARGET') {
      maskContainer = AppGlobal.mapZoneOverrideVisibleMask;
    }
    console.debug('Applying visibility update', target, maskPatch);
    const mask = maskContainer.mask;
    const area = Squares.fromPatch(maskPatch.patchData);
    mask.partialUpdateMaskFromPatch(maskPatch.patchData);
    await mapZoneContainer.setMapZoneVisibility(mask, area, target, false);
    console.debug('Visibility update applied', target, maskPatch);
  }

  async updateMapDetails(mapId) {
    AppGlobal.campaignDetails.refresh();
    const mapDetails = AppGlobal.mapDetails;
    if (mapDetails.mapId === mapId) {
      mapDetails.refresh();
    }
  }

  async updateMapZoneDetails(mapId, mapZoneId) {
    const mapDetails = AppGlobal.mapDetails;
    if (mapDetails.mapId === mapId) {
      mapDetails.refresh();
    }
    const mapZoneDetails = AppGlobal.mapZoneDetails;
    if (mapZoneDetails.mapZoneId === mapZoneId) {
      await mapZoneDetails.refresh();
      AppGlobal.mapZoneLiteStore.setRecordFromFull(mapZoneDetails.mapZone);
    } else if (mapZoneId) {
      AppGlobal.mapZoneLiteStore.load([mapZoneId]);
    }
  }

  async updateMapObjectStore(mapObjectId) {
    // It might be cool to do this in bulk (some level of debouncing)
    const mapObjectStore = AppGlobal.mapObjectStore;
    mapObjectStore.load([mapObjectId]);
  }

}
