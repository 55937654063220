/**
 * Loops through `records` until one is found with id.
 *
 * Returns either the record or null if no record is found.
 */
export function extractRecordById(id, records) {
  if (!records || !id) {
    return null;
  }
  for (const record of records) {
    if (id === record.id) {
      return record;
    }
  }
  return null;
}
