import MapObject from 'components/mapview/map-object';
import Shapes from 'utils/geometry/shapes';
import Squares from 'utils/squares';


/**
 * An object that can draw a map mask
 *
 * This is intended to be a base class with modifications based on the type of
 * mask and its source. For example, one could write an implementation that
 * refreshes with the boundaries of a map zone by connecting it to a
 * MapZoneContainer and completely masking anything underneath.
 */
export default class BaseMask extends MapObject {

  constructor() {
    super();
    this._grid = null;
    this._maskConfig = null;
    this._maskShape = null;
    // Defines how the mask is handled. See options for `globalCompositeOperation`
    this._compositeOperation = 'destination-in';
    this.fillStyle = 'rgba(0, 0, 0, 0.6)';
    this._lastVersion = null;
    this._dirty = false;
  }

  getMaskShape() {
    return this._maskShape;
  }

  setGrid(grid) {
    grid = grid || null;
    if (grid !== this._grid) {
      this._grid = grid;
      this.refreshMask();
    }
  }

  setMaskConfiguration(maskConfiguration) {
    // IDEA: This may be a performance bottleneck if we have to rebuild these
    // canvas objects EVERY time the mask is slightly changed. We'll deal with
    // how that works later. For now, leaving in the inefficient mode.
    maskConfiguration = maskConfiguration || null;
    if (maskConfiguration !== this._maskConfig) {
      this._maskConfig = maskConfiguration;
      this.refreshMask();
    }
  }

  /**
   * Generally not needed. This directly sets the mask object and updates the
   * internal configuration accordingly. It can be used if the app code needs
   * to create a fresh mask without having access to configuration.
   *
   * If you are unsure, it's better to use `setGrid` and `setMaskConfiguration`
   * instead.
   */
  setMask(mask) {
    mask = mask || null;
    if (mask !== this._maskShape) {
      this._maskShape = mask;
      if (mask) {
        this._grid = mask.grid;
        this._maskConfig = mask.toProto();
      } else {
        this._maskConfig = null;
      }
    }
    this._dirty = true;
  }

  refreshMask() {
    if (this._maskConfig && this._grid) {
      this._maskShape = Shapes.Mask.fromProto(this._grid, this._maskConfig);
      this._maskShape.initCanvas(document.createElement('canvas'));
    } else {
      this._maskShape = null;
    }
    this._dirty = true;
  }

  isEnabled() {
    return this.enabled && this._maskShape;
  }

  isVisible() {
    return this.visible;
  }

  onUpdate(timestamp, timeDeltaMs, frameno, render, layer) {
    if (
      this._dirty ||
      render.isKeyframe ||
      this._version !== this._maskShape.version
    ) {
      this._dirty = true;
      // TODO: this is kinda lame. Full redraw for any change in the mask?
      render.getLayer('fog').setFullRedraw();
      this._version = this._maskShape.version;
    } else {
      this._dirty = false;
    }
  }

  onRender(context, renderLayer, render) {
    // TODO: I think this is a somewhat naive approach. I'd much rather somehow
    // apply the mask to a separate drawing operation rather than it mask the
    // entire layer.
    this._dirty = false;
    this.maskFill(context, renderLayer, render);
    const maskShape = this._maskShape;
    context.imageSmoothingEnabled = false;
    context.globalCompositeOperation = this._compositeOperation;
    context.drawImage(
      maskShape.canvas,
      0,
      0,
      maskShape.maskWidth,
      maskShape.maskHeight,
      maskShape.containingSquare.x,
      maskShape.containingSquare.y,
      maskShape.containingSquare.w,
      maskShape.containingSquare.h,
    );
  }

  /**
   * Virtual function to verwrite the way that masks are "filled" - the content
   * that is drawn to the canvas before it is clipped by the mask.
   */
  maskFill(context, renderLayer, render) {
    // TODO: You can do better than fill the ENTIRE image...
    const imgSq = this._mapViewController.getImageSquare();
    if (!imgSq) {
      return;
    }
    context.fillStyle = this.fillStyle;
    context.fillRect(imgSq.x, imgSq.y, imgSq.w, imgSq.h);
  }

}
