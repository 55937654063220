import AppGlobal from 'global';
import FrameHandler from 'utils/graphics/frame-handler';
import QueuedAsyncState from 'statelib/queued-async-state';
import {requestTrackerInstance} from 'utils/async';

import CampaignDetailsStateRef from 'apps/maps/state/campaign-details-state-ref';
import GameObjectSelectionFocusState from 'canvasengine/gameobjects/game-object-selection-focus-state';
import MapDetailsStateRef from 'apps/maps/state/map-details-state-ref';
import MapImageStoreState from 'apps/maps/state/map-image-store-state';
import MapObjectStoreState from 'apps/maps/state/map-object-store-state';
import MapStoreState from 'apps/maps/state/map-store-state';
import MapViewRef from 'apps/maps/state/map-view-ref';
import MapZoneBoundariesMaskRef from 'apps/maps/state/map-zone-boundaries-mask-ref';
import MapZoneDetailsRef from 'apps/maps/state/map-zone-details-ref';
import MapZoneExploredMaskRef from 'apps/maps/state/map-zone-explored-mask-ref';
import MapZoneLiteStoreState from 'apps/maps/state/map-zone-lite-store-state';
import MapZoneOverrideHiddenMaskRef from 'apps/maps/state/map-zone-override-hidden-mask-ref';
import MapZoneOverrideVisibleMaskRef from 'apps/maps/state/map-zone-override-visible-mask-ref';
import PriorityEvents from 'utils/priority-events';
import SettingsState from 'apps/maps/state/settings';
import StaticImages from 'utils/graphics/static-images';
import undolib from 'undolib';


function _getEmbarkUrl(campaignId, mapId, mapZoneId) {
  const parts = [];
  if (campaignId) {
    parts.push('campaign');
    parts.push(campaignId);
  } else {
    parts.push('map');
  }
  if (mapId) {
    parts.push(mapId);
  }
  if (mapId && mapZoneId) {
    parts.push(mapZoneId);
  }
  return `/app/maps/embark/${parts.join('/')}`;
}


export default function initAppSingletons(config) {
  console.info('Initializing maps app singletons');
  AppGlobal.provide('settings', new SettingsState());
  AppGlobal.provide('images', new StaticImages('/static'));
  AppGlobal.provide('asyncQueue', new QueuedAsyncState());
  AppGlobal.provide('priorityEvents', new PriorityEvents());
  AppGlobal.provide('campaignDetails', new CampaignDetailsStateRef());
  AppGlobal.provide('imageStore', new MapImageStoreState({
    campaignRef: AppGlobal.campaignDetails,
  }));
  AppGlobal.provide('mapStore', new MapStoreState({
    campaignRef: AppGlobal.campaignDetails,
  }));
  AppGlobal.provide('mapDetails', new MapDetailsStateRef());
  AppGlobal.provide('mapZoneLiteStore', new MapZoneLiteStoreState({
    campaignRef: AppGlobal.campaignDetails,
    mapStore: AppGlobal.mapStore,
  }));
  AppGlobal.provide('mapZoneDetails', new MapZoneDetailsRef());
  AppGlobal.provide('mapZoneBoundariesMask', new MapZoneBoundariesMaskRef(AppGlobal.mapZoneDetails));
  AppGlobal.provide('mapZoneExploredMask', new MapZoneExploredMaskRef(AppGlobal.mapZoneDetails));
  AppGlobal.provide('mapZoneOverrideHiddenMask', new MapZoneOverrideHiddenMaskRef(AppGlobal.mapZoneDetails));
  AppGlobal.provide('mapZoneOverrideVisibleMask', new MapZoneOverrideVisibleMaskRef(AppGlobal.mapZoneDetails));
  AppGlobal.provide('mapObjectStore', new MapObjectStoreState({
    campaignRef: AppGlobal.campaignDetails,
    imageStore: AppGlobal.imageStore,
  }));
  AppGlobal.provide('gameObjectSelectionFocus', new GameObjectSelectionFocusState());
  AppGlobal.provide('editTokenRef', AppGlobal.mapObjectStore.getRecordRef());
  AppGlobal.provide('undo', new undolib.UndoState(undolib.instance));
  AppGlobal.provide('frameLoop', new FrameHandler());
  AppGlobal.provide('mapView', new MapViewRef());
  AppGlobal.provide('requestTracker', requestTrackerInstance);
  AppGlobal.provide('navigation', Object.freeze({
    configureMap: (mapId) => {
      AppGlobal.rootRouter.history.push(`/app/maps/${mapId}/details`);
    },
    configureNewMap: (campaignId) => {
      if (campaignId) {
        AppGlobal.rootRouter.history.push(`/app/maps/new?campaignId=${campaignId}`);
      } else {
        AppGlobal.rootRouter.history.push(`/app/maps/new`);
      }
    },
    embarkCampaign: (campaignId) => {
      AppGlobal.rootRouter.history.push(_getEmbarkUrl(campaignId));
    },
    embarkMap: (campaignId, mapId) => {
      AppGlobal.rootRouter.history.push(_getEmbarkUrl(campaignId, mapId));
    },
    embarkMapZone: (campaignId, mapId, mapZoneId) => {
      AppGlobal.rootRouter.history.push(_getEmbarkUrl(campaignId, mapId, mapZoneId));
    },
    replaceHash: (hashValue) => {
      if (hashValue.startsWith('#')) {
        AppGlobal.rootRouter.history.replace(hashValue);
      } else {
        throw new Error('Hash navigation targets must start with `#`')
      }
    },
    getEmbarkUrl: _getEmbarkUrl,
  }));
  AppGlobal.campaignDetails.listenToMapState(AppGlobal.mapDetails);
  AppGlobal.mapDetails.listenToZoneState(AppGlobal.mapZoneDetails);
  console.info('Starting global frame loop');
  AppGlobal.frameLoop.start();
}
