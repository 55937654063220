import React from 'react';
import { withRouter } from 'react-router';

import SessionInfoConnected from 'components/authentication/session-info-connected';
import CenteredBox from 'components/layouts/centered-box';
import FlexBarLayout from 'components/layouts/flex-bar-layout';
import AppPageTemplateConnected from 'components/templates/app-page-template-connected';

function SessionPage(props) {
  return (
    <AppPageTemplateConnected
      headerVisible={true}
      footerVisible={false}
    >
      <CenteredBox className='border-box' style={{width: '400px'}}>
        <h3 className="slds-text-heading_medium">You are Logged In!</h3>
        <SessionInfoConnected />
        <FlexBarLayout
          leftChildren={
            <a href=''>Log Out</a>
          }
          rightChildren={
            <a href='/app/maps/campaigns'>My Campaigns</a>
          }
        />
      </CenteredBox>
    </AppPageTemplateConnected>
  );
}


export default withRouter(SessionPage);
