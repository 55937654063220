import MapObject from 'components/mapview/map-object';
import Shapes from 'utils/geometry/shapes';
import Rect from 'utils/rect';
import Squares from 'utils/squares';


/**
 * Highlights the currently hovered cell in a grid
 */
export default class InterfaceGridCellHighlight extends MapObject {

  constructor(grid) {
    super();
    this.grid = grid || null;
    this.style = 'rgba(255, 255, 255, .9)';
    this.glowStyle = 'rgba(230, 230, 230, .3)';
    this.fillStyle = null;
    this.lineWidth = 1;
    this._cellSquare = null;
  }

  getLineWidthPixels(pixelRatio) {
    return Math.max(1, this.lineWidth * pixelRatio)
  }

  getGlowWidthPixels(pixelRatio) {
    return this.getLineWidthPixels(pixelRatio) + (2 * pixelRatio);
  }

  isEnabled() {
    return !!(this.enabled && this.grid);
  }

  isVisible() {
    return !!(this.visible && this._cellSquare);
  }

  onUpdate(timestamp, timeDeltaMs, frameNo, render, layer) {
    const controller = this._mapViewController;
    const grid = this.grid;
    const cursorX = controller.getCursorX();
    const cursorY = controller.getCursorY();
    let nextCellSquare = null;
    if (cursorX !== null && cursorY !== null) {
      nextCellSquare = Rect.fromObject(
        Shapes.GridCell.fromPixelCoordinates(
          this.grid, cursorX, cursorY
        ).containingSquare
      );
    }
    // Add 1 to the glow width to handle half pixel offset
    if (this._cellSquare !== nextCellSquare) {
      // Number of pixels beyond the square that need refreshing. Add 1 to
      // handle potential half-pixel offets
      const expandPixels = Math.ceil(
        (this.getGlowWidthPixels(render.pixelRatio) + 1) / render.scale
      );
      if (this._cellSquare) {
        layer.setRedraw(
          layer.getRenderSquare(this._cellSquare).growCentered(expandPixels)
        );
      }
      if (nextCellSquare) {
        layer.setRedraw(
          layer.getRenderSquare(nextCellSquare).growCentered(expandPixels)
        );
      }
    }
    this._cellSquare = nextCellSquare;
  }

  onRender(context, layer, render) {
    const renderSquare = layer.getRenderSquare(this._cellSquare);
    const lineWidthPixels = this.getLineWidthPixels(render.pixelRatio);
    const glowWidthPixels = this.getGlowWidthPixels(render.pixelRatio);
    layer.resetTransform();
    if (lineWidthPixels % 2 === 1) {
      context.translate(0.5, 0.5);
    }
    if (this.glowStyle) {
      context.lineWidth = glowWidthPixels;
      context.strokeStyle = this.glowStyle;
      context.strokeRect(renderSquare.x, renderSquare.y, renderSquare.w, renderSquare.h);
    }
    if (this.fillStyle) {
      context.fillStyle = this.fillStyle;
      context.fillRect(renderSquare.x, renderSquare.y, renderSquare.w, renderSquare.h);
    }
    if (this.style) {
      context.lineWidth = lineWidthPixels;
      context.strokeStyle = this.style;
      context.strokeRect(renderSquare.x, renderSquare.y, renderSquare.w, renderSquare.h);
    }
  }
}
