import statelib from 'statelib';

type MapImageRecordType = any;  // TODO: Fix me


export default class MapImageStoreRef extends statelib.RecordStoreRef<MapImageRecordType> {

  /**
   * Handles changes in a MapObject state ref to keep an image in sync with the
   * map object's `tokenImageId`.
   */
  setKeyFromMapObjectTokenImageId(mapObject) {
    let imageId = null;
    if (mapObject && mapObject.token && mapObject.token.imageId) {
      imageId = mapObject.token.imageId;
      this._store.loadLazy([imageId]);
    }
    this.key = imageId;
  }
}
