import classsNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Mini-toolbar sits just inside of it's parent and overlaps sibling content
 */
export default function MiniToolbar({
  attach,
  children,
}) {
  return (
    <div
      className={classsNames({
        'mini-toolbar': true,
        'mini-toolbar--horizontal': attach === 'top' || attach === 'bottom',
        'mini-toolbar--veritical': attach === 'left' || attach === 'right',
        'mini-toolbar--attach-top': attach === 'top',
        'mini-toolbar--attach-bottom': attach === 'bottom',
        'mini-toolbar--attach-left': attach === 'left',
        'mini-toolbar--attach-right': attach === 'right',
      })}
    >
      {children}
    </div>
  )
}


MiniToolbar.propTypes = {
  attach: PropTypes.string,
  children: PropTypes.node,
};

MiniToolbar.defaultProps = {
  attach: 'top',
};
