import React from 'react';


export default function IdDisplay({
  value,
  ...props
}) {
  if (!value) {
    return (
      <span className='id-display-none'></span>
    );
  } else {
    // TODO: It would be cool if this could link to the record maybe? Or
    // displayed only 6 characters and displayed a little copy button
    return (
      <span className='id-display-value'>{value.substring(0, 9)}</span>
    );
  }
}
