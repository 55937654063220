import type Frame from './frame';
import type FrameLayer from './frame-layer';

/**
 * A simple class that combines all of the information about a frame so that
 * it can be passed to GameObjects' ``onUpdate` method.
 */
export default class FrameUpdateContext {

  timestamp: number;
  timeDelta: number;
  frameNo: number;
  layer: FrameLayer;
  frame: Frame;

  constructor({
    timestamp,
    timeDelta,
    frameNo,
    layer,
    frame,
  }: {
    timestamp: number,
    timeDelta: number,
    frameNo: number,
    layer: FrameLayer,
    frame: Frame,
  }) {
    this.timestamp = timestamp;
    this.timeDelta = timeDelta;
    this.frameNo = frameNo;
    this.layer = layer;
    this.frame = frame;
  }

}
