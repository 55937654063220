import React from 'react';

import SinglePicklist from 'components/form/single-picklist-legacy';
import {Combobox} from '@salesforce/design-system-react';

import { common as commonPb } from 'proto-bundle';

// Note that you generally cannot select OWNER as an option when picking a user
// role. Ownership as a role comes from being the owner id on a given object

const USER_ROLE_GM = {
  id: commonPb.Role[commonPb.Role.GM],
  label: 'Game Manager',
  subTitle: null,
};

const USER_ROLE_TRUSTED_PLAYER = {
  id: commonPb.Role[commonPb.Role.TRUSTED_PLAYER],
  label: 'Trusted Player',
  subTitle: null,
};

const USER_ROLE_PLAYER = {
  id: commonPb.Role[commonPb.Role.PLAYER],
  label: 'Player',
  subTitle: null,
};

const USER_ROLE_OBSERVER = {
  id: commonPb.Role[commonPb.Role.OBSERVER],
  label: 'Observer',
  subTitle: null,
};


export default function UserRolePicklist(props) {
  return (
    <SinglePicklist
      label='Role'
      placeholder='Select a Role'
      onChangeFilter={() => null}
      filterValue=''
      onSelect={props.onSelect}
      value={props.value}
      options={[
        USER_ROLE_GM,
        USER_ROLE_TRUSTED_PLAYER,
        USER_ROLE_PLAYER,
        USER_ROLE_OBSERVER,
      ]}
    />
  );
}
