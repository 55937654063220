
interface HammerEvent {
  srcEvent: PointerEvent,
}


export default class EventUtils {

  /**
   * Returns true if the event has the correct modifiers to consider it
   * a "forced pan"
   */
  static isEventForcedPan(event: MouseEvent): boolean {
    return !!(event && event.metaKey);
  }

  /**
   * Returns true if the target is a hitbox-type element. See EventHitBox.
   */
  static isEventTargetHitbox(event: MouseEvent): boolean {
    return !!(event && event.target && (event.target as HTMLElement).dataset.hitbox);
  }

  /**
   * Attach to an event handled to pretend the event never happened.
   */
  static noEvent(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * Returns true if the pointerId of event1 is not the same as that from
   * event2. If either of the events does not exists (or if they are not
   * poitner events), return false.
   *
   * One special note: All `isPrimary` pointers are considered the same pointer.
   * This helps stabilize touch inputs where a pointer may get lost.
   */
  static isDifferentPointer(event1: PointerEvent, event2: PointerEvent): boolean {
    if (!event1 || !event1.pointerId || !event2 || !event2.pointerId) {
      return false;
    } else if (event1.pointerId === event2.pointerId) {
      return false;
    } else if (event1.isPrimary && event2.isPrimary) {
      return false;
    }
    return true;
  }

}
