import MaskRef from 'apps/maps/state/mask-ref';


export default class MapZoneMaskRef extends MaskRef {

  constructor(zoneContainer) {
    super();
    this._zoneUnsubscribe = zoneContainer.onData(this.$b.handleZoneData);
    this._lastMaskConfig = null;
    this._lastGrid = null;
  }

  close() {
    this._zoneUnsubscribe();
    super.close();
  }

  getMaskConfig(mapZone) {
    throw new Error('Abstract function');
  }

  handleZoneData(zoneData) {
    if (zoneData.async.isRunning) {
      return;
    }
    const maskConfig = this.getMaskConfig(zoneData.mapZone);
    const grid = zoneData.grid;
    if (grid && maskConfig) {
      // It's important that we only update the grid/mask configuration if it
      // has changed at the zone level. Otherwise an unrelated map zone change
      // could trigger an uncommitted mask update to be overwritten with stale
      // data.
      if (this._lastGrid !== grid || this._lastMaskConfig !== maskConfig) {
        this._lastGrid = grid;
        this._lastMaskConfig = maskConfig;
        this.setGridAndMaskConfig(grid, maskConfig);
      }
    } else {
      this.setGridAndMaskConfig(null, null);
    }
  }

}
