import React from 'react';

import FlexBarLayout from './flex-bar-layout';

/**
 * A simple layout containing a header (with optional right and left
 * content), main body content, and a footer with optional right and left
 * content. Basically, the focus is in the middle of the layout, with options
 * to anchor smaller bits of content outside in the corners.
 */
export default function OrionLayout(props) {
  return (
    <div className='orion-layout-wrapper' style={props.style}>
      <FlexBarLayout
        className='orion-header-row'
        leftChildren={props.headerLeft}
        rightChildren={props.headerRight}
      />
      <div className='orion-layout-body'>
        {props.children}
      </div>
      <FlexBarLayout
        className='orion-footer-row'
        leftChildren={props.footerLeft}
        rightChildren={props.footerRight}
      />
    </div>
  );
}
