const REQUEST_TRACKER_LEN = 1000;


/**
 * Generates a pseudorandom UUID
 *
 * See https://stackoverflow.com/a/8809472/703040
 */
function _fallbackGenerateUUID() { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16;//random number between 0 and 16
    if(d > 0){//Use timestamp until depleted
      r = (d + r)%16 | 0;
      d = Math.floor(d/16);
    } else {//Use microseconds since page-load if supported
      r = (d2 + r)%16 | 0;
      d2 = Math.floor(d2/16);
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}


function _getGenerateUUID() {
  if (!crypto || !crypto.randomUUID) {
    return _fallbackGenerateUUID;
  }
  try {
    let _ = crypto.randomUUID();
    return crypto.randomUUID.bind(crypto);
  } catch (err) {
    return _fallbackGenerateUUID;
  }
}


/**
 * Class for tracking a collection of previous requests. This is primarily used
 * so that a client can ignore (some) websocket data for changes initiated by
 * that client that would otherwise result in weird synchronization issues.
 */
export default class RequestTracker {

  constructor() {
    this._values = new Array(REQUEST_TRACKER_LEN);
    this._valueSet = new Set();
    this._curIndex = 0;
    // This function gets lazy-loaded because sometimes the browser doesn't
    // fail to call `crypto.randomUUID` statically but then fails later
    this._generateUUID = null;
  }

  get generateUUID() {
    if (!this._generateUUID) {
      this._generateUUID = _getGenerateUUID();
    }
    return this._generateUUID;
  }

  newRequest() {
    const requestUUID = this.generateUUID();
    const oldUUID = this._values[this._curIndex];
    if (oldUUID) {
      this._valueSet.delete(oldUUID);
    }
    this._values[this._curIndex] = requestUUID;
    this._valueSet.add(requestUUID);
    this._curIndex = (this._curIndex + 1) % REQUEST_TRACKER_LEN;
    return requestUUID;
  }

  hasRequest(requestUUID) {
    return this._valueSet.has(requestUUID);
  }

}
