import CanvasUtils from 'utils/graphics/canvas-utils';
import makeBindable from 'utils/bound-methods';
import Rect from 'utils/rect';

import type Frame from './frame';
import type CanvasLayer from './canvas-layer';


/**
 * Combines frame and layer information into a single object that can make
 * some stuff easier to deal with
 */
export default class FrameLayer {

  private _frame: Frame;
  private _layer: CanvasLayer;
  private _layerName: string;
  private _sourceRect: any;
  private _targetRect: any;
  private _scale: number;

  constructor(frame: Frame, layer: CanvasLayer) {
    makeBindable(this);
    this._frame = frame;
    this._layer = layer;
    this._layerName = this._layer.name;
    this._sourceRect = this._frame.sourceRect;
    this._targetRect = this._frame.targetRect;
    this._scale = this._frame.scale;
  }

  get name(): string {
    return this._layerName;
  }

  getCanvas() {
    return this._layer.getCanvas();
  }

  getContext() {
    return this._layer.getContext();
  }

  /**
   * Incorporates a new Redraw Region into this layer. The `redrawRect` should
   * be in local-pixel-space
   */
  addRedrawRegion(redrawRect) {
    if (!redrawRect || redrawRect === Rect.ZERO) {
      return;
    }
    this._frame.addRedrawRegion(this._layerName, redrawRect);
  }

  /**
   * Deprecated alias for `addRedrawRegion`
   */
  setRedraw(regionSq) {
    this.addRedrawRegion(regionSq);
  }

  /**
   * Like `addRedrawRegion` except `redrawRect` is in source-pixel-space
   */
  addRedrawRegionSource(redrawRect) {
    if (!redrawRect || redrawRect === Rect.ZERO) {
      return;
    }
    this._frame.addRedrawRegionSource(this._layerName, redrawRect);
  }

  /**
   * Deprecated alias for `addRedrawRegionSource`
   */
  setRedrawSource(regionSq) {
    this.addRedrawRegionSource(regionSq);
  }

  /**
   * Triggers the layer to be fully redrawn.
   *
   * See `Frame.setFullRedraw`
   */
  setFullRedraw() {
    this._frame.setFullRedraw(this._layerName);
  }

  /**
   * Given an X coordinate in source-pixel-space, returns the corresponding
   * value in local-pixel-space.
   *
   * NOTE: You typically don't need to use the `getRender` coordinates since the
   * canvas context is properly scaled already. However, these can be useful
   * for drawing unscaled elements.
   */
  getRenderX(x) {
    return Math.round((x - this._sourceRect.x) * this._scale);
  }

  /**
   * Given a Y coordinate in source-pixel-space, returns the corresponding value
   * in local-pixel-space.
   *
   * NOTE: You typically don't need to use the `getRender` coordinates since the
   * canvas context is properly scaled already. However, these can be useful
   * for drawing unscaled elements.
   */
  getRenderY(y) {
    return Math.round((y - this._sourceRect.y) * this._scale);
  }

  /**
   * Given an X coordinate and width value in source-pixel-space, returns the
   * corresponding width value in local-pixel-space.
   *
   * NOTE: You typically don't need to use the `getRender` coordinates since the
   * canvas context is properly scaled already. However, these can be useful
   * for drawing unscaled elements.
   */
  getRenderW(x, w): number {
    // Weird logic to handle an issue with the sum of 2 truncated ints resulting
    // in a lost pixel.
    return this.getRenderX(x + w) - this.getRenderX(x);
  }

  /**
   * Given a Y coordinate and height value in source-pixel-space, returns the
   * corresponding height value in local-pixel-space.
   *
   * NOTE: You typically don't need to use the `getRender` coordinates since the
   * canvas context is properly scaled already. However, these can be useful
   * for drawing unscaled elements.
   */
  getRenderH(y, h): number {
    // Weird logic to handle an issue with the sum of 2 truncated ints resulting
    // in a lost pixel.
    return this.getRenderY(y + h) - this.getRenderY(y);
  }

  /**
   * Converts dimensions (or a Squares object) from source-pixel-space into a
   * Squares object in local-pixel-space.
   *
   * NOTE: You typically don't need to use the `getRender` coordinates since the
   * canvas context is properly scaled already. However, these can be useful
   * for drawing unscaled elements.
   */
  getRenderRect(sourceRect): Rect;
  getRenderRect(x: number, y: number, w: number, h: number): Rect;
  getRenderRect(x, y?, w?, h?): Rect {
    // Handle case where a squares object is passed for `x`
    if (x !== undefined && y === undefined && w === undefined && h === undefined) {
      h = x.h;
      w = x.w;
      y = x.y;
      x = x.x;
    }
    return Rect.fromDimensions(
      this.getRenderX(x),
      this.getRenderY(y),
      this.getRenderW(x, w),
      this.getRenderH(y, h),
    )
  }

  /**
   * Deprecated conveneince alias for `getRenderRect`
   */
  getRenderSquare(x, y, w, h): Rect {
    return this.getRenderRect(x, y, w, h);
  }

  /**
   * Convenience function for resetting the context transform to the identity.
   * This is primarily used for drawing interface elements that should not
   * scale based on the current map zoom.
   */
  resetTransform(): void {
    CanvasUtils.resetTransform(this.getContext(), this._frame.pixelRatio);
  }

}
