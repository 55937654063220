import {makeRpcCall} from 'utils/async';
import {maps as mapsPb, common as commonPb} from 'proto-bundle';


export default class MapsRPCAPI {

  static searchImages = makeRpcCall(
    '/rpc/maps/v1/search_images',
    mapsPb.ImageSearchRequest,
    mapsPb.ImageSearchResponse,
  );

  static patchImage = makeRpcCall(
    '/rpc/maps/v1/patch_image',
    mapsPb.MapImage,
    mapsPb.MapImage,
  );

  static updateMapZoneImage = makeRpcCall(
    '/rpc/maps/v1/set_map_zone_image',
    mapsPb.MapZone,
    mapsPb.MapZone,
  );

  static searchMaps = makeRpcCall(
    '/rpc/maps/v1/search_maps',
    mapsPb.MapSearchRequest,
    mapsPb.MapSearchResponse,
  );

  static getMaps = makeRpcCall(
    '/rpc/maps/v1/get_maps',
    mapsPb.GetMapsRequest,
    mapsPb.GetMapsResponse,
  );

  static newMap = makeRpcCall(
    '/rpc/maps/v1/new_map',
    mapsPb.Map,
    mapsPb.Map,
  );

  static patchMap = makeRpcCall(
    '/rpc/maps/v1/patch_map',
    mapsPb.Map,
    mapsPb.Map,
  );

  static getZonesForMap = makeRpcCall(
    '/rpc/maps/v1/get_zones_for_map',
    mapsPb.GetZonesForMapRequest,
    mapsPb.GetZonesForMapResponse,
  );

  static reorderMapZones = makeRpcCall(
    '/rpc/maps/v1/reorder_map_zones',
    mapsPb.Map,
    commonPb.EmptySuccess,
  )

  static getMapZone = makeRpcCall(
    '/rpc/maps/v1/get_map_zone',
    mapsPb.GetMapZoneRequest,
    mapsPb.GetMapZoneResponse,
  );

  static patchMapZone = makeRpcCall(
    '/rpc/maps/v1/patch_map_zone',
    mapsPb.MapZone,
    mapsPb.MapZone,
  );

  static setMapZoneGridDetails = makeRpcCall(
    '/rpc/maps/v1/set_map_zone_grid_details',
    mapsPb.MapZone,
    mapsPb.MapZone,
  );

  static setMapZoneBoundaries = makeRpcCall(
    '/rpc/maps/v1/set_map_zone_boundaries',
    mapsPb.SetBoundariesRequest,
    mapsPb.MapZone,
  );

  static patchMapZoneVisibility = makeRpcCall(
    '/rpc/maps/v1/patch_map_zone_visibility',
    mapsPb.PatchVisibilityRequest,
    mapsPb.PatchVisibilityResponse,
  );

  static getMyCampaigns = makeRpcCall(
    '/rpc/maps/v1/get_my_campaigns',
    mapsPb.GetMyCampaignsRequest,
    mapsPb.GetMyCampaignsResponse,
  );

  static getCampaignById = makeRpcCall(
    '/rpc/maps/v1/get_campaign_by_id',
    mapsPb.GetCampaignByIdRequest,
    mapsPb.Campaign,
  );

  static getCampaignMembers = makeRpcCall(
    '/rpc/maps/v1/get_campaign_members',
    mapsPb.GetCampaignMembersRequest,
    mapsPb.GetCampaignMembersResponse,
  );

  static putCampaign = makeRpcCall(
    '/rpc/maps/v1/put_campaign',
    mapsPb.Campaign,
    mapsPb.Campaign,
  );

  static addCampaignMember = makeRpcCall(
    '/rpc/maps/v1/add_campaign_member',
    mapsPb.AddCampaignMemberRequest,
    mapsPb.AddCampaignMemberResponse,
  );

  static removeCampaignMember = makeRpcCall(
    '/rpc/maps/v1/remove_campaign_member',
    mapsPb.RemoveCampaignMemberRequest,
    mapsPb.RemoveCampaignMemberResponse,
  );

  static modifyCampaignMaps = makeRpcCall(
    '/rpc/maps/v1/modify_campaign_maps',
    mapsPb.ModifyCampaignMapsRequest,
    mapsPb.ModifyCampaignMapsResponse,
  );

  static createMapObject = makeRpcCall(
    '/rpc/maps/v1/create_map_object',
    mapsPb.MapObject,
    mapsPb.MapObject,
  );

  static putMapObject = makeRpcCall(
    '/rpc/maps/v1/put_map_object',
    mapsPb.MapObject,
    mapsPb.MapObject,
  );

  static patchMapObjectLocation = makeRpcCall(
    '/rpc/maps/v1/patch_map_object_location',
    mapsPb.MapObject,
    mapsPb.MapObject,
  );

  static deleteMapObjects = makeRpcCall(
    '/rpc/maps/v1/delete_map_objects',
    mapsPb.DeleteByIdsRequest,
    mapsPb.DeleteByIdsResponse,
  );

  static findMapObjects = makeRpcCall(
    '/rpc/maps/v1/find_map_objects',
    mapsPb.MapObjectSearchRequest,
    mapsPb.MapObjectSearchResponse,
  );

}
