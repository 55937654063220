import React from 'react';
import classNames from 'classnames';

// TODO: Fix width (or at least centering) if the buttons wrap. Ideally, if the
// buttons don't wrap, we want them to be "actual size" where the primary
// button is pinned to the right and the secondary button to the left. However,
// if the buttons wrap, we want them to both have 100% width and stack
// veritcally (secondary button on top, primary button on the bottom)


export default function TwoButtonPanel({
  className,
  children
}) {
  let primaryButton: React.ReactNode;
  let secondaryButton: React.ReactNode | null;
  if (React.Children.count(children) === 1) {
    primaryButton = React.Children.only(children);
    secondaryButton = null;
  } else if (React.Children.count(children) === 2) {
    const buttons = React.Children.toArray(children);
    primaryButton = buttons[0];
    secondaryButton = buttons[1];
  } else {
    throw new Error('TwoButtonPanel must have exactly 1 or 2 children');
  }
  return (
    <div
      className={classNames(
        'flex--container-horizontal-center',
        'flex--wrap',
        'flex--justify-space-between',
        className,
      )}
    >
      <div className='flex--item-fixed flex--container-horizontal'>
        {secondaryButton}
      </div>
      <div className='flex--item-fixed flex--container-horizontal'>
        {primaryButton}
      </div>
    </div>
  );
}
