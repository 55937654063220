import MapsRPCAPI from 'apis/maps-rpc-api';

import MapZoneLiteStoreRef from './map-zone-lite-store-ref';
import CampaignScopedRecordStoreState from './campaign-scoped-record-store-state';
import MapStoreState from './map-store-state';

type MapZoneLiteRecordType = any;  // TODO: Fix me


/**
 * Handles loading map zone metadata (map zone lite) information. This
 * data is much cheaper to keep in sync and maintain in memory, so it is
 * safe to keep it around for much longer than a full map zone (which contains
 * items such as visibility masks)
 */
export default class MapZoneLiteStoreState extends CampaignScopedRecordStoreState<MapZoneLiteRecordType>{

  static DEFAULT_NAME = 'MapZoneLiteStore';

  protected _mapStore: MapStoreState;

  constructor(options) {
    super({...options, keyField: 'id'});
    this._mapStore = options.mapStore;
  }

  destroy() {
    super.destroy();
  }

  get mapStore() {
    return this._mapStore;
  }

  getRecordRef(options, key?: KeyType): MapZoneLiteStoreRef {
    const recordRef = new MapZoneLiteStoreRef(this, options);
    if (key) {
      recordRef.key = key;
    }
    return recordRef;
  }

  /**
   * Loads multiple map zones by key (id).
   *
   * Note that "loading" new keys is safe - they will be ignored.
   *
   * TODO: The above statement seems like a lie. It will re-load map zones over
   * and over even if it was just loaded.
   */
  async load(keys: KeyType[]) {
    if (keys) {
      keys = keys.filter(key => !this.isNewKey(key) && !this.isKeyLoaded(key));
    }
    const campaignId = this.campaignId;
    if (!campaignId) {
      console.warn('Attempting to load Map Zones but no campaign');
      return;
    }
    try {
      await this._async.wrap(async (throwIfCanceled) => {
        const uniqueKeys = Array.from(new Set(keys));
        console.info("Refreshing lite data for map zones", uniqueKeys);
        for (const key of uniqueKeys) {
          // TODO: I dont' know if I like this... I feel like it just shouldn't
          // call load if the things is already loaded.
          if (this.isKeyLoaded(key)) {
            console.debug("Skipping lite map zone data (already loaded)", key);
            continue;
          }
          console.log("Loading lite map zone data", key);
          const result = await MapsRPCAPI.getMapZone({
            mapZoneId: key,
            metadataOnly: true,
          });
          throwIfCanceled();
          this.setRecordFromFull(key, result.mapZone);
        }
      });
    } catch (err) {
      console.error(`${this.name}: Unable to load images (${keys})`, err);
      throw err;
    }
  }

  async getZonesForMap(mapId: string): Promise<MapZoneLiteRecordType[]> {
    const campaignId = this.campaignId;
    return await this._async.wrap(async (throwIfCanceled) => {
      const result = await MapsRPCAPI.getZonesForMap({mapId: mapId});
      throwIfCanceled();
      const mapZones: MapZoneLiteRecordType[] = result.mapZones || [];
      this.setRecords(mapZones);
      return mapZones;
    });
  }

  /**
   * Sets the internal record based on a full map zone.
   *
   * This is useful if you have a full map zone loaded that is "fresh" and
   * want to keep the lite data in sync.
   */
  setRecordFromFull(key, mapZone): void {
    if (mapZone) {
      // See `MAP_ZONES_BASIC_FIELD_MASK` for list of fields
      const mapZoneLite = {
        id: mapZone.id,
        name: mapZone.name,
        publicName: mapZone.publicName,
        public: mapZone.public,
        mapId: mapZone.mapId,
        backgroundImage: mapZone.backgroundImage,
        permissions: mapZone.permissions,
      };
      this.setRecords([mapZoneLite], {expectedKeys: [key]});
    } else {
      this.setRecords([], {expectedKeys: [key]});
    }
  }

}
