
/**
 * Given the current suffix (like a token annotation), provide the next one
 * in order.
 */
export function getNextSuffix(value: string | null): string {
  if (!value) {
    return "II";
  } else if (!isNaN(parseInt(value))) {
    return _getNextNumber(value)
  } else if (value.match(/^[IVXL]+$/) && deromanize(value) !== false) {
    return _getNextRoman(value);
  } else if (value.match(/^[A-Y]{1}$/)) {  // Stop before Z for overflow reasons
    return _getNextLetter(value);
  }
  return value;
}

function _getNextNumber(value): string {
  const numberValue = parseInt(value);
  return `${Math.round(numberValue) + 1}`;
}

function _getNextRoman(value): string {
  const numberValue = deromanize(value) as number;
  return romanize(numberValue + 1) as string;
}

function _getNextLetter(value): string {
  return String.fromCharCode(value.charCodeAt(0) + 1);
}


// romanize and deromanize lifted verbatim from
// https://blog.stevenlevithan.com/archives/javascript-roman-numeral-converter

function romanize(num): string | boolean {
  if (!+num) return false;
  var digits = String(+num).split('');
  var key = ['','C','CC','CCC','CD','D','DC','DCC','DCCC','CM',
             '','X','XX','XXX','XL','L','LX','LXX','LXXX','XC',
             '','I','II','III','IV','V','VI','VII','VIII','IX'];
  var roman = '', i = 3;
  while (i--) roman = (key[+digits.pop() + (i * 10)] || '') + roman;
  return Array(+digits.join('') + 1).join('M') + roman;
}

function deromanize(str): number | boolean {
  var str = str.toUpperCase();
  var validator = /^M*(?:D?C{0,3}|C[MD])(?:L?X{0,3}|X[CL])(?:V?I{0,3}|I[XV])$/;
  var token = /[MDLV]|C[MD]?|X[CL]?|I[XV]?/g;
  var key = {M:1000,CM:900,D:500,CD:400,C:100,XC:90,L:50,XL:40,X:10,IX:9,V:5,IV:4,I:1};
  var num = 0, m;
  if (!(str && validator.test(str))) return false;
  while (m = token.exec(str)) num += key[m[0]];
  return num;
}
