import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {TabsPanel} from '@salesforce/design-system-react';
import {Tabs} from '@salesforce/design-system-react';

import AppReactComponent from 'utils/app-react-component';

import ImageManagerBrowser from './image-manager-browser';
import ImageManagerUploader from './image-manager-uploader';

const INPUT_STATE_SELECT = 'INPUT_STATE_SELECT';
const INPUT_STATE_EDIT = 'INPUT_STATE_EDIT';
const INPUT_STATE_UPLOAD = 'INPUT_STATE_UPLOAD';


/**
 * Component for managing all uploaded images.
 *
 * Recommended minimum width: 30rem. Any smaller gets really cramped.
 *
 * NOTE: This MUST be a child of strictly sized parent. For example, if putting
 * this inside of a dialog, the dialog must have a size. Something about the
 * way the flex sizing works gets really weird and the size of this component
 * is allowed to dictate the size of the parent, even if that would somehow
 * make the parent extend beyond its max size (clearly there are a few aspects
 * of flex sizing that I still don't understand)
 */
export default class ImageManager extends AppReactComponent {

  static propTypes = {
    defaultCategories: PropTypes.arrayOf(PropTypes.string),
    initialSelectedImageId: PropTypes.string,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    ownerId: PropTypes.string.isRequired,
    ownerType: PropTypes.string.isRequired,
  };

  static defaultProps = {
    onCancel: null,
    onChange: null,
    onSubmit: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      inputState: INPUT_STATE_SELECT,
      selectedImageId: props.initialSelectedImageId,
    };
    this._imagesChangedPipe = this.makePubSub();
    this.addCleanup(this._imagesChangedPipe.subscribe(this.$b._handleImageChanged));
    // TODO: If there are no images available for the current category, default
    // to the upload a new iamge tab. Otherwise the select an image tab should
    // be used.
  }

  _handleImageChanged({image, select}) {
    if (select) {
      this.setState({
        inputState: INPUT_STATE_SELECT,
        selectedImageId: image.id,
      })
    }
  }

  _handleChangeSelectedImage(event, imageId) {
    // Delay notifying parent of a change until state is changed just in case
    // they decide to modify `initialSelectedImageId`. Waiting until the state
    // is updated ensures that any change to `initialSelectedImageId` will be
    // a no-op (since it will match the current state)
    this.setState({selectedImageId: imageId}, () => {
      if (this.props.onChange) {
        this.props.onChange(event, imageId);
      }
    });
  }

  _handleSaveSelectedImage(event, imageId) {
    if (this.props.onSubmit) {
      this.props.onSubmit(event, imageId);
    }
  }

  _handleCancelSelectedImage(event) {
    if (this.props.onCancel) {
      this.props.onCancel(event);
    }
  }

  _handleSelectTab(newTabIndex) {
    if (newTabIndex === 1) {
      this.setState({inputState: INPUT_STATE_UPLOAD});
    } else {
      this.setState({inputState: INPUT_STATE_SELECT});
    }
  }

  _handleChangeIsEditing(isEditing) {
    if (this.state.inputState === INPUT_STATE_UPLOAD) {
      // Do nothing - wrong tab
    } else if (isEditing) {
      this.setState({inputState: INPUT_STATE_EDIT});
    } else {
      this.setState({inputState: INPUT_STATE_SELECT});
    }
  }

  /**
   * Allows the component to react to changes in `initialSelectedImageId`.
   */
  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    if (
      prevProps.initialSelectedImageId !== this.props.initialSelectedImageId &&
      this.state.selectedImageId !== this.props.initialSelectedImageId
    ) {
      this.setState({selectedImageId: this.props.initialSelectedImageId})
    }
  }

  render() {
    const props = this.props;
    const state = this.state;
    return (
      <Tabs
        onSelect={this.$b._handleSelectTab}
        selectedIndex={state.inputState === INPUT_STATE_UPLOAD ? 1 : 0}
        className={classNames('flex--container-vertical', props.className)}
      >
        <TabsPanel label="Select an Image">
          <ImageManagerBrowser
            categories={props.defaultCategories}
            ownerId={props.ownerId}
            ownerType={props.ownerType}
            onChange={this.$b._handleChangeSelectedImage}
            onChangeIsEditing={this.$b._handleChangeIsEditing}
            onSubmit={this.$b._handleSaveSelectedImage}
            onCancel={this.$b._handleCancelSelectedImage}
            selectedImageId={state.selectedImageId}
            isEditing={state.inputState === INPUT_STATE_EDIT}
            imageChangeSubscribe={this._imagesChangedPipe.$b.subscribe}
          />
        </TabsPanel>
        <TabsPanel label="Upload a New Image">
          <ImageManagerUploader
            className='layout--fill-box'
            defaultCategories={props.defaultCategories}
            ownerId={props.ownerId}
            ownerType={props.ownerType}
            afterSubmit={this._imagesChangedPipe.$b.publish}
            onCancel={this.$b._handleCancelSelectedImage}
          />
        </TabsPanel>
      </Tabs>
    );
  }

}
