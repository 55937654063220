import React from 'react';

import {Button} from '@salesforce/design-system-react';
import {Dropdown} from '@salesforce/design-system-react';

import AppGlobal from 'global';

import IdDisplay from 'components/basic/id-display.view';
import TableOrderable from 'components/basic/table-orderable';
import MapThumbnail from 'components/images/map-thumnail';


const TMP_CELL_STYLE={
  backgroundColor: 'rgba(228, 222, 211, 0.7)',
  backdropFilter: 'blur(2px)',
  textShadow: '#FFF 1px 0 5px',
};


function _handleSetImageClick(event) {
  const mapId = event.target.getAttribute('data-map-id');
  const mapZoneId = event.target.getAttribute('data-zone-id');
  AppGlobal.rootRouter.history.push(
     `/app/maps/${mapId}/zones/${mapZoneId}/image`
  );
}


function _handleViewImageClick(event) {
  window.location = event.target.getAttribute('data-image-url');
}


function _handleSetupGridClick(event) {
  const mapId = event.target.getAttribute('data-map-id');
  const mapZoneId = event.target.getAttribute('data-zone-id');
  AppGlobal.rootRouter.history.push(
     `/app/maps/${mapId}/zones/${mapZoneId}/configure/grid`
  );
}


function _handleSetupBoundariesClick(event) {
  const mapId = event.target.getAttribute('data-map-id');
  const mapZoneId = event.target.getAttribute('data-zone-id');
  AppGlobal.rootRouter.history.push(
     `/app/maps/${mapId}/zones/${mapZoneId}/configure/bounds`
  );
}


function _getHeaderChildren() {
  return (
    <React.Fragment>
      <th>Map Zone ID</th>
      <th>Name</th>
      <th>Public Name</th>
      <th>Setup</th>
    </React.Fragment>
  );
}


function _getRowChildren(history, item) {
  // TODO: The "view" should open the image in a modal
  const hasImage = item.backgroundImage && item.backgroundImage.id;
  return (
    <React.Fragment>
      <td style={TMP_CELL_STYLE}><IdDisplay value={item.id}/></td>
      <td style={TMP_CELL_STYLE}>{item.name}</td>
      <td style={TMP_CELL_STYLE}>{item.publicName}</td>
      <td style={TMP_CELL_STYLE}>
        <Button
          variant='neutral'
          data-map-id={item.mapId}
          data-zone-id={item.id}
          onClick={_handleSetImageClick}
          iconCategory='utility'
          label='Set Image'
        />
        <Button
          variant='neutral'
          data-image-url={hasImage ? item.backgroundImage.url : ''}
          onClick={_handleViewImageClick}
          disabled={!hasImage}
          iconCategory='utility'
          label='View Image'
        />
        <Button
          variant='neutral'
          data-map-id={item.mapId}
          data-zone-id={item.id}
          onClick={_handleSetupGridClick}
          disabled={!hasImage}
          label='Setup Grid'
        />
        <Button
          variant='neutral'
          data-map-id={item.mapId}
          data-zone-id={item.id}
          onClick={_handleSetupBoundariesClick}
          disabled={!hasImage}
          label='Setup Boundaries'
        />
      </td>
    </React.Fragment>
  );
}


function _getRowStyle(item) {
  if (item.backgroundImage && item.backgroundImage.url) {
    return {
      backgroundImage: `url('${item.backgroundImage.url}')`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      height: '3rem',
    };
  }
  return {
    height: '3rem',
  };
}


export default function MapZonesTable({
  zones,
  history,
  onChangeOrder,
}) {
  const changeMapZoneOrder = (moveIdx, afterIdx) => {
    if (afterIdx === -1) {
      onChangeOrder(zones[moveIdx].id, null);
    } else {
      onChangeOrder(zones[moveIdx].id, zones[afterIdx].id);
    }
  }
  const getRowChildren = item => _getRowChildren(history, item);
  return (
    <TableOrderable
      items={zones}
      getHeaderChildren={_getHeaderChildren}
      getRowChildren={getRowChildren}
      getRowStyle={_getRowStyle}
      onChangeOrder={changeMapZoneOrder}
    />
  );
}
