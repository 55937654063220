import uniqueId from 'lodash/uniqueid';

import UndoStepConfig from './undo-step-config';

const UNDO_STEP_VALID = 2;
const UNDO_STEP_ALTERED = 1;
const UNDO_STEP_INVALID = 0;

/**
 * Contains a single undo (or redo) step.
 *
 * In general, callers should not generate Undo/Redo steps directly. Instead
 * they should use the functions on the UndoHandler.
 */
export default class UndoStep {

  static UNDO_STEP_VALID = UNDO_STEP_VALID;
  static UNDO_STEP_ALTERED = UNDO_STEP_ALTERED;
  static UNDO_STEP_INVALID = UNDO_STEP_INVALID;

  constructor(undoHandler, context, isRedo, config) {

    /** @member {UndoHandler} The parent handled to which this step is
      *  assigned. */
    this._undoHandler = undoHandler;

    /** @member {string} A unique ID for debugging */
    this.id = `UndoStep_${uniqueId()}`;

    /** @member {string} the context key (i.e. stack key) that this step was
      *  originally pushed to. Any inverse operations will be pushed to the
      *  same context. */
    this.context = context;

    /** @member {bool} Indicates whether this is an undo operation (false) or a
      *  redo operation (true). This will generally be used by the execute
      *  function to push the inverse operation to the alternate stack */
    this.isRedo = isRedo;

    /** @member {UndoStepConfig} The configuration for this step */
    this.config = config;
  }

  /**
   * Gets the step's handler. This can be used for pushing additional undo/redo
   * steps during execution of the current step.
   */
  get handler() {
    return this._undoHandler;
  }

  /**
   * Executes the current step. This will typically be called by the undo
   * handler itself and should not be run directly.
   */
  async executeStep(handleAlteredAsync) {
    const checkPrecondition = this.config.checkPrecondition;
    const execute = this.config.execute;
    const precon = (checkPrecondition) ? checkPrecondition(this) : UNDO_STEP_VALID;
    if (precon === UNDO_STEP_INVALID) {
      console.info(`${this.id}: Skipping step because it is not valid`);
      return false;
    } else if (precon === UNDO_STEP_ALTERED) {
      if (handleAlteredAsync) {
        if (await handleAlteredAsync()) {
          await execute(this);
          return true;
        } else {
          console.info(`${this.id}: Skipping step because something changed`);
          return false;
        }
      } else {
        await execute(this);
        return true;
      }
    } else if (precon === UNDO_STEP_VALID) {
      await execute(this);
      return true;
    } else {
      throw new Error('Unexpected precondition result', precon);
    }
  }

  /**
   * Pushes a step onto the relevant handler. The step will be pushed as the
   * inverse to the current step (i.e. if this step is an undo, pushing an
   * inverse step will add a redo and vice-versa).
   *
   * @param {StepConfig} stepConfig is the configuration of the step to execute
   */
  pushInverseStep(stepConfig) {
    if (this.isRedo) {
      // Maintains the redo stack in this case because this is not a branch
      // in the history.
      return this._undoHandler.pushUndo(this.context, stepConfig, true);
    }
    return this._undoHandler.pushRedo(this.context, stepConfig);
  }

}
