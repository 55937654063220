import classNames from 'classnames';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from '@salesforce/design-system-react';

import AppGlobal from 'global';
import AppReactComponent from 'utils/app-react-component';
import compoundcmp from 'components/form/compound';
import EventUtils from 'utils/events';
import FStateWithAsync from 'statelib/fstate-with-async';
import TwoButtonPanel from 'components/layouts/two-button-panel';
import undolib from "undolib";
import {getNextSuffix} from "utils/suffixes";

import TokenTrayItem from './token-tray-item';

import type MapObjectStoreState from 'apps/maps/state/map-object-store-state';
import type MapObjectStoreRef from 'apps/maps/state/map-object-store-ref';
import appGlobal from 'global';
import GhostMapToken from 'components/mapview/map-objects/ghost-map-token';

const _PAGE_SIZE = 50;

const _SORT_OPTIONS = Object.freeze([
  Object.freeze({label: 'Name', value:'name asc'}),
  Object.freeze({label: 'Recent', value:'created desc'}),
])

const _STYLE_COLLAPSED_VERITCAL = Object.freeze({
  width: '2.7rem',
});

const _STYLE_COLLAPSED_HORIZONTAL = Object.freeze({
  height: '2.7rem',
});


class _TokenTrayState extends FStateWithAsync {

  static DEFAULT_NAME = 'TokenTrayState';

  constructor(options?) {
    super(options);
  }

  makeInitialData() {
    return {
      searchTerm: null,
      sorting: null,
      tokens: [],
      nextSearchOffset: 0,  // -1 if there are no more tokens to search
    };
  }

}

interface PropsType {
  className: string,
  classNameItem: string,
  campaignId: string,
  mapZoneId: string,
  style: any,
  variant: 'vertical' | 'horizontal',
  editTokenRef?: MapObjectStoreRef,
  minimized: boolean,
};

interface StateType {
  async: any,
  campaignPermissions: any,
  focusTokenId: string | null,
  isDragging: boolean,
  minimized: boolean,
  search: any,
  tempMinimized: boolean,
};


/**
 * docstring
 */
export default class TokenTray extends AppReactComponent<PropsType, StateType, any> {

  static propTypes = {
    className: PropTypes.string,
    classNameItem: PropTypes.string,
    campaignId: PropTypes.string,
    mapZoneId: PropTypes.string,
    style: PropTypes.object,
    variant: PropTypes.oneOf(['vertical', 'horizontal']),
    minimized: PropTypes.bool,
    dragTarget: PropTypes.node,
  };

  static defaultProps = {
    variant: 'vertical',
    minimized: false,
  };

  _campaignRef: any;
  _searchState: _TokenTrayState;
  _scrollableContainer: Element | null;
  _mapObjectStore: MapObjectStoreState;
  _editTokenRef: MapObjectStoreRef;
  _dragTarget: Element;
  _dragTokenEvent: PointerEvent | null;
  _dragTokenId: string | null;
  _dragTokenGhost: GhostMapToken | null;

  constructor(props: PropsType) {
    super(props);
    this.state = {
      async: null,
      campaignPermissions: null,
      focusTokenId: null,
      isDragging: false,
      minimized: false,
      search: null,
      tempMinimized: false,  // When dragging a token, minimize the tray until dropped
    };
    this._campaignRef = appGlobal.campaignDetails;
    this._mapObjectStore = this.constantProp('mapObjectStore', AppGlobal.mapObjectStore);
    this._editTokenRef = this.constantProp('editTokenRef', AppGlobal.editTokenRef);
    this._searchState = new _TokenTrayState();
    this.connect('async', this._searchState, 'async');
    this.connect('search', this._searchState);
    this.connect('campaignPermissions', this._campaignRef, 'campaign.permissions');
    this.addSetup(this.$b._loadNextSearchPage);
    this._scrollableContainer = null;
    this.addCleanup(
      this._mapObjectStore.subscribe(this.$b._refreshTokenDataLocally),
      this._mapObjectStore.newRecordPubSub.subscribe(this.$b._refreshOnNewToken),
    );
    // Drag and drop features. Note that we also clean up the drag target stuff
    // here just in case the drag and drop process didn't clean then up naturally
    this._dragTokenEvent = null;
    this._dragTokenId = null;
    this._dragTokenGhost = null;
    this.addSetup(() => {
      this._dragTarget = AppGlobal.mapView.controller.engine.viewParent;
      this._dragTarget.addEventListener('pointermove', this.$b._handleDragToken);
      this._dragTarget.addEventListener('pointerup', this.$b._handleDropToken);
      this._dragTarget.addEventListener('keydown', this.$b._handleCheckCancelDragToken);
    });
    this.addCleanup(() => {
      if (this._dragTarget) {
        this._dragTarget.removeEventListener('pointermove', this.$b._handleDragToken);
        this._dragTarget.removeEventListener('pointerup', this.$b._handleDropToken);
        this._dragTarget.removeEventListener('keydown', this.$b._handleCheckCancelDragToken);
      }
    });
  }

  _setScrollableContainerRef(elem?: Element) {
    const prevScrollableContainer = this._scrollableContainer;
    this._scrollableContainer = elem;
    if (elem && elem !== prevScrollableContainer) {
      elem.addEventListener('scroll', this.$b._handleContainerScroll);
    }
  }

  _handleContainerScroll(event) {
    const elem = this._scrollableContainer;
    if (event.target !== elem) {
      console.warn("Scrolling target mismatch");
    }
    if (
      this._searchState.getValue('nextSearchOffset') >= 0 &&
      elem.scrollHeight - elem.scrollTop - elem.clientHeight < 1
    ) {
      this._loadNextSearchPage();
    }
  }

  _handleEditToken(event, tokenId) {
    this._editTokenRef.key = tokenId;
  }

  _handleCopyToken(event, tokenId) {
    if (!tokenId) {
      throw new Error('Must highlight a token to copy');
    }
    const token = this._mapObjectStore.getRecordSync(this.state.focusTokenId);
    const tokenDetails = token.token;
    if (!token || !tokenDetails) {
      throw new Error('Attempted to copy something that is not a token');
    }
    const newToken = this._mapObjectStore.makeNewRecord();
    const newTokenDetails = newToken.token || {};
    const cloneToken: any = {
      ...newToken,
      campaignId: token.campaignId,
      ownerId: token.ownerId,
      name: token.name,
      layer: token.layer,
      token: {
        ...newTokenDetails,
        annotation: getNextSuffix(tokenDetails.annotation),
        gridWidth: tokenDetails.gridWidth,
        gridHeight: tokenDetails.gridHeight,
        imageScale: tokenDetails.imageScale,
        imageId: tokenDetails.imageId,
        image: tokenDetails.image,
        smallImageId: tokenDetails.smallImageId,
        smallImage: tokenDetails.smallImage,
        protraitImageId: tokenDetails.protraitImageId,
        portraitImage: tokenDetails.portraitImage,
        shortName: tokenDetails.shortName,
        isSecret: tokenDetails.isSecret,
        faction: tokenDetails.faction,
        border: (!tokenDetails.border) ? null : {
          style: tokenDetails.border.style,
          color: tokenDetails.border.color,
        }
      },
    };
    this._mapObjectStore.setRecords([cloneToken]);
    this._editTokenRef.key = cloneToken.id;
  }


  _handleClickToken(event, tokenId) {
    // Double-click to immediately edit the token.
    // See: https://stackoverflow.com/a/53939059/703040
    if (event.detail === 2) {
      const token = this._mapObjectStore.getRecordSync(this.state.focusTokenId);
      if (token && token.permissions.canWrite) {
        this._editTokenRef.key = tokenId;
      }
      // TODO: Open up some sort of simplified view dialog.
    }
  }

  _handleFocusToken(event, tokenId) {
    if (this.state.focusTokenId !== tokenId) {
      this.setState({focusTokenId: tokenId});
    }
  }

  _handleBlurToken(event, tokenId) {
    if (this.state.focusTokenId === tokenId) {
      this.setState({focusTokenId: null});
    }
  }

  _handleBeginDragToken(event, tokenId, dragAngle) {
    // Ignore any drag events where the angle is roughly parallel to the scroll
    // angle. This prevents triggering a token pick-up when the user is just
    // trying to scroll through the list.
    const angleDeg = dragAngle * 180 / Math.PI;
    if (this.props.variant === "horizontal") {
      if (angleDeg <= 45 || angleDeg >= 315 || (angleDeg >= 135 && angleDeg <= 225)) {
        return;
      }
    } else {
      if ((angleDeg >= 45 && angleDeg <= 135) || (angleDeg >= 225 && angleDeg <= 325)) {
        return;
      }
    }
    // The event is a token pick-up. Handle accordingly.
    event.preventDefault();
    if (this._dragTokenEvent) {
      console.info('Canceled in-progress token drag due to new one');
      this._finishDragToken(event, true);
    }
    // Consider attaching the ghost to 'interface' instead of 'tokens' to be above the fog?
    this._dragTokenEvent = event;
    this._dragTokenGhost = new GhostMapToken(AppGlobal.mapZoneDetails.grid, tokenId);
    this._dragTokenId = tokenId;
    this._dragTokenGhost.attach(AppGlobal.mapView.controller.engine, 'tokens');
    this.setState({isDragging: true});
    // Touch has a bunch of issues related to direct manipulation that have been
    // REALLY hard to get around. As a compromise, touch users will simply need
    // to tap once more to place their token
    //
    // TODO: Create an overlay (div using `.overlay` to handle this)
    if (event.target.hasPointerCapture(event.pointerId)) {
      console.info('Please tap once more to place your token');
      event.target.releasePointerCapture(event.pointerId);
      this._dragTarget.setPointerCapture(event.pointerId);
    }
  }

  _handleDragToken(event: PointerEvent) {
    if (!this._dragTokenEvent) {
      return;
    } else if (EventUtils.isDifferentPointer(this._dragTokenEvent, event)) {
      return;
    }
    event.preventDefault();
    this._dragTokenGhost.setGridLocationFromPointer(0.5);
  }

  _handleDropToken(event: PointerEvent) {
    if (!this._dragTokenEvent) {
      return;
    } else if (EventUtils.isDifferentPointer(this._dragTokenEvent, event)) {
      return;
    }
    this._finishDragToken(event, false);
  }

  _handleCheckCancelDragToken(event: KeyboardEvent) {
    if (!this._dragTokenEvent) {
      return;
    } else if (event.code !== "Escape") {
      return;
    }
    this._finishDragToken(event);
  }

  _handleCancelDragToken(event) {
    this._finishDragToken(event, true);
  }

  _finishDragToken(event, canceled?) {
    try {
      if (canceled) {
        return;
      }
      const tokenId = this._dragTokenId;
      const tokenGhost = this._dragTokenGhost;
      tokenGhost.setGridLocationFromPointer(0.5);
      const [tokenX, tokenY] = tokenGhost.getRealCoordinates();
      if (tokenX === null || tokenY === null) {
        return;
      }
      const store = this._mapObjectStore;
      const [tokenCol, tokenRow] = tokenGhost.getRealGridCoordinates();
      store.moveToken(tokenId, this.props.mapZoneId, tokenCol, tokenRow);
    } finally {
      this._dragTokenEvent = null;
      this._dragTokenGhost.destroy();
      this._dragTokenGhost = null;
      this._dragTokenId = null;
      this.setState({isDragging: false});
    }
  }

  _refreshSearch() {
    this._searchState.patchData({
      nextSearchOffset: 0,
    });
    this._loadNextSearchPage();
  }

  async _loadNextSearchPage() {
    if (!this.props.campaignId || !this.props.mapZoneId) {
      this._searchState.patchData({
        tokens: [],
        nextSearchOffset: -1,
      });
      return;
    }
    try {
      await this._searchState.asyncState.wrap(async (throwIfCanceled) => {
        const searchOffset = this._searchState.getValue('nextSearchOffset')
        const searchObj: any = {
          campaignId: this.props.campaignId,
          offset: searchOffset,
          limit: _PAGE_SIZE,
          sorting: this._searchState.getValue('sorting'),
          canChangeLocation: true,
        }
        if (this._searchState.getValue('searchTerm')) {
          searchObj.searchTerm = this._searchState.getValue('searchTerm');
        } else {
          searchObj.all = true;
        }
        // If it's known that there are no tokens, don't try to load anything
        if (searchOffset < 0) {
          return;
        }
        let prevTokens = this._searchState.getValue('tokens');
        if (searchOffset === 0 || !prevTokens) {
          prevTokens = [];
        }
        const prevTokenIds = new Set();
        for (const prevToken of prevTokens) {
          prevTokenIds.add(prevToken.id);
        }
        let nextTokens = [...prevTokens];
        // Search for any matching tokens and add them as well (assuming they
        // don't already exist in the list of tokens
        const foundTokens = await this._mapObjectStore.searchMapObjects(searchObj);
        throwIfCanceled();
        const newTokens = foundTokens.filter(token => !prevTokenIds.has(token.id));
        nextTokens = nextTokens.concat(newTokens);
        // TODO: Check to see that none of the search criteria in state/props
        // have changed since this request started. If they have abandon the results.
        this._searchState.patchData({
          tokens: nextTokens,
          nextSearchOffset: (foundTokens.length < _PAGE_SIZE) ? -1 : searchOffset + _PAGE_SIZE,
        });
      });
    } catch (err) {
      console.error('Failed to find tokens', err);
      this._searchState.asyncState.clearError(err);
    }
  }

  _handleChangeFilter(event, filterData) {
    this._searchState.setValueIfChanged('searchTerm', filterData.filterText);
    this._searchState.setValueIfChanged('sorting', filterData.sortValue);
    this.setState({focusTokenId: null});
    this._refreshSearch();
  }

  /**
   * Called when the map object store state changes. This will ensure that the
   * searched list of tokens will be updated if data in the map object store
   * changes.
   */
  _refreshTokenDataLocally() {
    const prevTokens = this._searchState.data.tokens;
    const nextTokens = [];
    let changed = false;
    for (const prevToken of prevTokens) {
      const nextToken = this._mapObjectStore.getRecordSync(prevToken.id, true);
      if (!nextToken) {
        changed = true;  // Token no longer exists
      } else if (nextToken !== prevToken) {
        nextTokens.push(nextToken);
        changed = true;
      } else {
        nextTokens.push(prevToken);
      }
    }
    if (changed) {
      this._searchState.setValue('tokens', nextTokens);
    }
  }

  /**
   * If a new token is added, refresh token data.
   */
  _refreshOnNewToken(message) {
    const {id, record} = message;
    if (record.token) {
      this._refreshSearch();
    }
  }

  /**
   * Creates a new placeholder Token and sets the global add/edit for it.
   */
  _openNewToken() {
    const newToken = this._mapObjectStore.makeNewRecord();
    this._mapObjectStore.setRecords([newToken]);
    this._editTokenRef.key = newToken.id;
  }

  _handleEditClicked(event) {
    this._editTokenRef.key = this.state.focusTokenId;
  }

  _handleCopyClicked(event) {
    this._handleCopyToken(event, this.state.focusTokenId);
  }

  _handleMinimizeToggleClicked(event) {
    if (this.state.isDragging) {
      console.info('Canceled in-progress token drag by expanding sidebar');
      this._finishDragToken(event, true);
    } else if (this.state.minimized) {
      this.setState({minimized: false});
    } else {
      this.setState({minimized: true});
    }
  }

  _getMinimizeToggleIcon() {
    const state = this.state;
    const props = this.props;
    const minimized = state.isDragging || state.minimized;
    if (props.variant === 'horizontal' && minimized) {
      return 'chevronup';
    } else if (props.variant === 'horizontal' && !minimized) {
      return 'chevrondown';
    } else if (minimized) {
      return 'chevronleft';
    } else {
      return 'chevronright';
    }
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    if (
      prevProps.campaignId !== this.props.campaignId ||
      prevProps.mapZoneId !== this.props.mapZoneId
    ) {
      this._refreshSearch();
    }
  }

  render() {
    const props = this.props;
    const state = this.state;
    const canAddTokens = state.campaignPermissions && state.campaignPermissions.canCreateTokens;
    if (state.minimized || state.isDragging) {
      return this._renderCollapsed();
    }
    const focusToken = this._mapObjectStore.getRecordSync(this.state.focusTokenId);
    return (
      <div
        className={classNames(props.className, {
          'flex--container-horizontal': props.variant === 'horizontal',
          'flex--container-vertical': props.variant === 'vertical',
          'tokens--tray-horizontal': props.variant === 'horizontal',
          'tokens--tray-vertical': props.variant === 'vertical',
          'panel--simple': true,
        })}
        style={props.style}
      >
        <div className='dialog--header-container flex--container-horizontal'>
          <h3>Tokens</h3>
          <Button
            iconCategory='utility'
            iconName={this._getMinimizeToggleIcon()}
            iconSize='small'
            assistiveText={{icon: 'Collapse/Restore'}}
            iconVariant='border-filled'
            variant='icon'
            onClick={this.$b._handleMinimizeToggleClicked}
          />
        </div>
        <compoundcmp.FilterAndSort
          onChange={this.$b._handleChangeFilter}
          placeholderFilterText='Search Tokens'
          initialSortValue='name asc'
          sortOptions={_SORT_OPTIONS}
          className='flex--item-fixed'
          compact={true}
          hasSpinner={state.async.isRunning}
        />
        <div
          className='flex--item-fill '
          ref={this.$b._setScrollableContainerRef}
        >
          {state.search.tokens.map((token, idx) => (
            <TokenTrayItem
              annotation={token.token.annotation}
              className={props.classNameItem}
              description={token.name + (token.token.annotation ? ` ${token.token.annotation}` : '')}
              editable={true}
              id={token.id}
              key={token.id}
              imageUrl={token.token.image ? token.token.image.thumbnailUrl : undefined}
              border={token.token.border}
              isFocused={state.focusTokenId && token.id === state.focusTokenId}
              mapZoneId={token.mapZoneId}
              name={token.token.shortName || token.name}
              onBeginDrag={this.$b._handleBeginDragToken}
              onBlur={this.$b._handleBlurToken}
              onClick={this.$b._handleClickToken}
              onEdit={this.$b._handleEditToken}
              onFocus={this.$b._handleFocusToken}
            />
          ))}
        </div>
        {/* These buttons are an accessibility mess since a user may need to
          tab (changing focus) to get to the appropriate edit button. We'll
          need some way to edit/copy in-line as well */}
        <TwoButtonPanel
          className={classNames({
            'flex--item-fixed': true,
            'hidden': !state.focusTokenId,
          })}
        >
          <Button
            onMouseDown={EventUtils.noEvent /* Prevent blur */}
            onClick={this.$b._handleEditClicked}
            variant='brand'
            className={classNames({
              'hidden': !focusToken || !focusToken.permissions.canWrite,
            })}
          >
            Edit
          </Button>
          <Button
            onMouseDown={EventUtils.noEvent /* Prevent blur */}
            onClick={this.$b._handleCopyClicked}
            className={classNames({
              'hidden': !canAddTokens,
            })}
          >
            Copy
          </Button>
        </TwoButtonPanel>
        <TwoButtonPanel
          className={classNames({
            'flex--item-fixed': true,
            'hidden': state.focusTokenId || !canAddTokens
          })}
        >
          <Button onClick={this.$b._openNewToken}>New token</Button>
        </TwoButtonPanel>
      </div>
    );
  }

  protected _renderCollapsed() {
    const props = this.props;
    return (
      <div
        className={classNames(props.className, {
          'flex--container-horizontal': props.variant === 'horizontal',
          'flex--container-vertical': props.variant === 'vertical',
          'panel--simple': true,
        })}
        style={{
          ...props.style,
          ...(props.variant === 'horizontal' ? _STYLE_COLLAPSED_HORIZONTAL : _STYLE_COLLAPSED_VERITCAL),
        }}
      >
        <Button
          iconCategory='utility'
          iconName={this._getMinimizeToggleIcon()}
          iconSize='small'
          assistiveText={{icon: 'Collapse/Restore'}}
          iconVariant='border-filled'
          variant='icon'
          onClick={this.$b._handleMinimizeToggleClicked}
        />
      </div>
    );
  }

}
