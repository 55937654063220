import type FrameLayer from "./frame-layer";
import type Frame from "./frame";


/**
 * A simple class that combines all of the information about a frame so that
 * it can be passed to GameObjects' `onRender` method.
 */
export default class FrameRenderContext {

  public context: CanvasRenderingContext2D;
  public layer: FrameLayer;
  public frame: Frame;

  constructor({
    context,
    layer,
    frame,
  }: {
    context: CanvasRenderingContext2D,
    layer: FrameLayer,
    frame: Frame,
  }) {
    this.context = context;
    this.layer = layer;
    this.frame = frame;
  }

}
