import React from 'react';
import {withRouter} from 'react-router';

import AppPageTemplateConnected from 'components/templates/app-page-template-connected';

import MapZoneConfig from './components/map-zone-config';
import SharedMapDetailsLoader from './components/shared-map-details-loader';
import SharedMapZoneDetailsLoader from './components/shared-map-zone-details-loader';


function MapZoneConfigPage(props) {
  return (
    <AppPageTemplateConnected>
      <SharedMapDetailsLoader>
        <SharedMapZoneDetailsLoader>
          <MapZoneConfig layer={props.match.params.configLayer} />
        </SharedMapZoneDetailsLoader>
      </SharedMapDetailsLoader>
    </AppPageTemplateConnected>
  );
}

export default withRouter(MapZoneConfigPage);
