import debounce from 'lodash/debounce';

import AppGlobal from 'global';
import MapObject from 'components/mapview/map-object';
import SerialAsync from 'utils/serial-async';
import Squares from 'utils/squares';
import undolib from 'undolib';

// IDEA: A lot of the functionality of this class should be built into the
// MaskContainer object itself - namely it should be possible to tell the mask
// container how much was changed so that the container can track that and
// commit it.

/**
 * Base object for handling tools that can paint to visibility masks.
 *
 * This handles the different paint operations while being agnostic to the
 * the actual drawing part.
 */
export default class BaseMaskPainterObject extends MapObject {

  constructor() {
    super();
    this.grid = null;
    this._serialized = new SerialAsync();
    this._drawParts = [];
    this._curUndoStep = null;  // Needs to collect the debounced things to draw
    this.pushUpdatesDebounce = debounce(this.$b.pushUpdates, 100, {maxWait: 250});
    this._boundPushUpdates = this._pushUpdates.bind(this);
    this._diffsToApply = new Map();
  }

  isEnabled() {
    return this.grid && this._drawParts && this._drawParts.length && this.enabled;
  }

  isVisible() {
    return this.visible;
  }

  /**
   * Sub-classes should call super.onUpdate at some point to ensure that undos
   * are handled correctly.
   */
  onUpdate(timestamp, timeDeltaMs, frameNo, render, layer) {
    this._applyDiffs(layer);
  }

  _drawPartExplored(erase) {
    return {
      zoneLayerName: 'EXPLORED_PATCH_TARGET',
      maskRef: AppGlobal.mapZoneExploredMask,
      erase: erase,
    };
  }

  _drawPartHidden(erase) {
    return {
      zoneLayerName: 'HIDDEN_PATCH_TARGET',
      maskRef: AppGlobal.mapZoneOverrideHiddenMask,
      erase: erase,
    };
  }

  _drawPartVisible(erase) {
    return {
      zoneLayerName: 'VISIBLE_PATCH_TARGET',
      maskRef: AppGlobal.mapZoneOverrideVisibleMask,
      erase: erase,
    };
  }

  _setDrawParts(drawParts) {
    this.pushUpdates();
    // TODO: There is still a race condition here if someone manages to change
    // the tool AND being drawing before a stale call to `pushUpdatesDebounce`
    // has finished firing. We can figure something out there, but it should be
    // very unlikely to occur.
    this._drawParts = drawParts;
  }

  // TODO: Any time `_drawParts` is set, it should flush any outstanding
  // draw area to the server by immediately calling `pushUpdates`

  // TODO: Calling one of the setPaint* functions triggers `_setDrawParts` even
  // if nothing has changed. This isn't ideal since it will trigger push
  // updates for no good reason (especially since the caller may not be super
  // aware of the current tool and coule be calling this too often)

  setPaintNull() {
    this._setDrawParts([]);
  }

  setPaintHide() {
    this._setDrawParts([
      this._drawPartVisible(true),
      this._drawPartExplored(true),
    ]);
  }

  setPaintDark() {
    this._setDrawParts([
      this._drawPartVisible(true),
    ]);
  }

  setPaintLight() {
    this._setDrawParts([
      this._drawPartVisible(false),
      this._drawPartExplored(false),
      this._drawPartHidden(true),
    ]);
  }

  /**
   * Updates the appropriate mask(s). The `drawFunc` must be a callback that
   * takes (mask, context, erase) where:
   *
   * - mask is the actual mask shape being draw to
   * - context is a canvas context that can be used for drawing
   * - erase is a boolean, if true, the data should be erased rather than drawn
   *
   * `drawFunc` should also return a single Squares object representing an area
   * that was changed by the draw. This will be used to determine what needs to
   * be sent to the server.
   *
   * Note that callback may be executed multiple times per call to `updateMask`
   * in the event that the update requires writing data to more than one mask.
   * Also important to note that each mask MAY be a different resolution, so
   * drawing should take into account the target mask in addition to the
   * context.
   *
   * IDEA: There is a chance that the `_maskAreaToSend` part and
   * `pushUpdatesDebounce` part should be up to the caller to handle. If
   * anything gets weird, consider doing that.
   */
  updateMask(drawFunc, scope) {
    const drawParts = [...this._drawParts];
    if (!drawParts || !drawParts.length) {
      return;
    }
    for (const drawPart of drawParts) {
      const maskRef = drawPart.maskRef;
      const erase = drawPart.erase;
      let dirtySq;
      const diff = maskRef.withMaskContext((context, mask) => {
        dirtySq = drawFunc(mask, context, erase);
      }, scope);
      if (!drawPart.dirtySq) {
        drawPart.dirtySq = dirtySq;
      } else {
        drawPart.dirtySq = Squares.getContainingSquare(drawPart.dirtySq, dirtySq);
      }
      // Add this to the undo history, initializing a new step if needed
      if (diff.isDifferent) {
        if (this._curUndoStep === null) {
          const mapZoneId = AppGlobal.mapZoneDetails.mapZoneId;
          this._curUndoStep = undolib.instance.pushUndo(
            `mapZone:${mapZoneId}`,
            new undolib.UndoStepConfig({
              description: 'Undo change visibility',
              data: {
                diffs: {
                  EXPLORED_PATCH_TARGET: [],
                  HIDDEN_PATCH_TARGET: [],
                  VISIBLE_PATCH_TARGET: [],
                },
              },
              checkPrecondition: this.$b._checkUndoRedo,
              execute: this.$b._executeUndoRedo,
            }),
          );
        }
        this._curUndoStep.config.data.diffs[drawPart.zoneLayerName].push(diff.getInverse());
      }
    }
    this.pushUpdatesDebounce();
  }

  /**
   * Push updates to all of the layers that have been modified by the tool.
   */
  async pushUpdates() {
    this._curUndoStep = null;  // breaks up the current undo step
    const mapZoneId = AppGlobal.mapZoneDetails.mapZoneId;
    if (!mapZoneId) {
      return;
    }
    const drawParts = [...this._drawParts];
    if (drawParts.length) {
      await this._serialized.submit(this._boundPushUpdates, drawParts);
    }
  }

  async _pushUpdates(drawParts) {
    if (!drawParts.length) {
      return;
    }
    try {
      for (const drawPart of drawParts) {
        const dirtySq = drawPart.dirtySq;
        drawPart.dirtySq = null;
        if (!dirtySq) {
          continue;
        }
        await AppGlobal.mapZoneDetails.setMapZoneVisibility(
          drawPart.maskRef.mask,
          dirtySq,
          drawPart.zoneLayerName,
          true,
        );
      }
    } catch (err) {
      // TODO: Add `patchArea` back in so it can be picked up next time?
      // Honestly, probably better for async to just have some semblance of
      // retries.
      throw err;
    }
  }

  _submitDiffs(layerName, diffs) {
    if (diffs && diffs.length) {
      if (!this._diffsToApply.has(layerName)) {
        this._diffsToApply.set(layerName, []);
      }
      this._diffsToApply.get(layerName).push(...diffs);
    }
  }

  _applyDiffs(layer) {
    if (this._diffsToApply.size) {
      let drawParts = [];
      for (const [layerKey, diffs] of this._diffsToApply.entries()) {
        let maskRef;
        if (layerKey === 'EXPLORED_PATCH_TARGET') {
          maskRef = AppGlobal.mapZoneExploredMask;
        } else if (layerKey === 'HIDDEN_PATCH_TARGET') {
          maskRef = AppGlobal.mapZoneOverrideHiddenMask;
        } else if (layerKey === 'VISIBLE_PATCH_TARGET') {
          maskRef = AppGlobal.mapZoneOverrideVisibleMask;
        } else {
          throw new Error('Bad layer name?');
        }
        let redrawRegion = maskRef.applyDiffsToMask(diffs);
        if (redrawRegion && redrawRegion !== Squares.zero()) {
          layer.setRedraw(redrawRegion);
        }
        drawParts.push({
          zoneLayerName: layerKey,
          maskRef: maskRef,
          erase: false,
          dirtySq: redrawRegion,
        });
      }
      this._diffsToApply.clear();
      this._serialized.submit(this._boundPushUpdates, drawParts);
    }
  }

  _checkUndoRedo(undoStep) {
    // TODO: Implement by checking if the diff still applies
    return undolib.UndoStep.UNDO_STEP_VALID;
  }

  async _executeUndoRedo(undoStep) {
    // Submit the diffs to be handled on the next frame
    const diffMap = undoStep.config.data.diffs;
    for (const [layerKey, diffs] of Object.entries(diffMap)) {
      this._submitDiffs(layerKey, diffs);
    }
    // Update the history with the inverse of the current operation
    undoStep.pushInverseStep(
      new undolib.UndoStepConfig({
        description: 'Redo change visibility',
        data: {
          diffs: {
            EXPLORED_PATCH_TARGET: diffMap.EXPLORED_PATCH_TARGET.map(diff => diff.getInverse()),
            HIDDEN_PATCH_TARGET: diffMap.HIDDEN_PATCH_TARGET.map(diff => diff.getInverse()),
            VISIBLE_PATCH_TARGET: diffMap.VISIBLE_PATCH_TARGET.map(diff => diff.getInverse()),
          },
        },
        checkPrecondition: this.$b._checkUndoRedo,
        execute: this.$b._executeUndoRedo,
      })
    );
  }

}
