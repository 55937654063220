import UndoStep from './undo-step';
import UndoStack from './undo-stack';

const DEBUG = false;

const MISSING_STACK = Object.freeze({exists: false, length: 0, steps: []});


/**
 * Class for managing and executing Undo/Redo actions.
 */
export default class UndoHandler {

  constructor() {
    this._contexts = new Map();

    /** @member {function?} An optional callback triggered after the internal
      *  state changes. This is mainly used as a hook for the UndoFState
      *  object. TODO: This has problems if multiple states try to listen to
      *  it. Fine for now but something to try and figure out in the future */
    this._onUpdate = null;
  }

  getContexts() {
    return [...this._contexts.entries()];
  }

  getUndoStack(context, maxLength) {
    const stack = this._contexts.get(context);
    if (!stack) {
      return MISSING_STACK;
    }
    return {
      exists: true,
      length: stack.undoLength,
      steps: stack.getUndoState(maxLength)
    };
  }

  getRedoStack(context, maxLength) {
    const stack = this._contexts.get(context);
    if (!stack) {
      return MISSING_STACK;
    }
    return {
      exists: true,
      length: stack.redoLength,
      steps: stack.getRedoState(maxLength)
    };
  }

  /**
   * Sets a callback that will be called whenever a context state changes. The
   * callback will be passed the context name as a parameter.
   */
  setOnUpdate(callback) {
    if (this._onUpdate) {
      throw new Error('Only one update handler is allowed');
    }
    this._onUpdate = callback;
  }

  /**
   * Calls onUpdate if it has been defined.
   */
  _doOnUpdate(context) {
    if (this._onUpdate) {
      this._onUpdate(context);
    }
  }

  /**
   * Optionally initialize a context. It is a warning to push a step to a
   * context that has not been initialized.
   */
  initContext(context) {
    if (!this._contexts.has(context)) {
      if (DEBUG) console.debug(`undo-handler: Initializing "${context}" stack`);
      this._contexts.set(context, new UndoStack());
      this._doOnUpdate(context);
    } else {
      console.warn('Context has already been initialized', context);
    }
  }

  /**
   * Destroys the named undo context, removing all of the steps and removing it
   * from the registry. Any new steps added to the context will result in a
   * warning.
   */
  destroyContext(context) {
    if (this._contexts.has(context)) {
      if (DEBUG) console.debug(`undo-handler: Destroying "${context}" stack`);
      this._contexts.delete(context);
      this._doOnUpdate(context);
    } else {
      if (DEBUG) console.debug(`undo-handler: Nothing to destroy for "${context}"`);
    }
  }

  /**
   * Clears all steps from the context without deleting it. I'm not sure if this
   * is actually useful or not - don't do anything until we actually need it.
   */
  clearContext(context) {
    if (this._contexts.has(context)) {
      this._contexts.set(context, new UndoStack());
      this._doOnUpdate(context);
    } else {
      if (DEBUG) console.debug(`undo-handler: Nothing to clear for "${context}"`);
    }
  }

  /**
   * Returns the context stack for a given context, initializing it if it hasn't
   * been used before. Lazy initialization in this way is a warning because it
   * typically means that nothing is planning on cleaning the context up.
   */
  _safeGetContextStack(context) {
    if (!this._contexts.has(context)) {
      console.warn('Context was accessed before being initialized', context);
      this.initContext(context);
    }
    return this._contexts.get(context);
  }

  async executeUndo(context, handleAlteredAsync) {
    const step = this._safeGetContextStack(context).popUndo();
    this._doOnUpdate(context);
    if (!step) {
      if (DEBUG) console.debug(`undo-handler: Skip "${context}" undo because stack is empty`);
      return null;
    }
    if (DEBUG) console.debug(`undo-handler: Execute "${context}" undo: ${step.id}`);
    try {
      return await step.executeStep(handleAlteredAsync);
    } finally {
      if (DEBUG) console.debug(`undo-handler: Complete "${context}" undo: ${step.id}`);
    }
  }

  async executeRedo(context, handleAlteredAsync) {
    const step = this._safeGetContextStack(context).popRedo();
    this._doOnUpdate(context);
    if (!step) {
      if (DEBUG) console.debug(`undo-handler: Skip "${context}" redo because stack is empty`);
      return null;
    }
    if (DEBUG) console.debug(`undo-handler: Execute "${context}" redo: ${step.id}`);
    try {
      return await step.executeStep(handleAlteredAsync);
    } finally {
      if (DEBUG) console.debug(`undo-handler: Complete "${context}" redo: ${step.id}`);
    }
  }

  /**
   * Add a new undo step to the stack.
   *
   * In general, pushing a step to the undo stack will clear the associated
   * redo stack. This is because doing a new thing generally means that the
   * redo stack is no longer valid. However, there are cases (such as when
   * stepping through the redo stack itself) where the redo stack should NOT
   * be cleared out. In those cases, pass `keepRedoStack` as true.
   */
  pushUndo(context, config, keepRedoStack) {
    const step = new UndoStep(this, context, false, config);
    if (DEBUG) console.debug(`undo-handler: Push "${context}" undo: ${step.id}`, config);
    const stack = this._safeGetContextStack(context);
    stack.pushUndo(step);
    if (!keepRedoStack && stack.redoLength) {
      if (DEBUG) console.debug(`undo-handler: Clear "${context}" redo stack`);
      stack.clearRedo();
    }
    this._doOnUpdate(context);
    return step;
  }

  pushRedo(context, config) {
    const step = new UndoStep(this, context, true, config);
    if (DEBUG) console.debug(`undo-handler: Push "${context}" redo: ${step.id}`, config);
    this._safeGetContextStack(context).pushRedo(step);
    this._doOnUpdate(context);
    return step;
  }

  peekUndo(context) {
    return this._safeGetContextStack(context).peekUndo();
  }

  peekRedo(context) {
    return this._safeGetContextStack(context).peekRedo();
  }

}
