import FStateWithAsync from './fstate-with-async';
import FStateListener from './fstate-listener';


/**
 * A standard "container" for an ordered collection of records ("record" meaning
 * any object that has an ID).
 */
export default class RecordCollectionState extends FStateWithAsync {

  static DEFAULT_NAME = 'RecordCollection';

  constructor(refresher, options) {
    super(options);
    this._refresh = refresher;
    this._relatedStateListener = new FStateListener(this.$b.pathUpdatedRecord);
  }

  get ids() {
    return this.getValue('ids');
  }

  get records() {
    return this.getValue('records');
  }

  makeInitialData() {
    return {
      ids: [],
      records: [],
      recordsById: {},
    };
  }

  destroy() {
    this._refresh = null;
    this._relatedStateListener.destroy();
    super.destroy();
  }

  /**
   * Called when a record is patched either manually or because a related state
   * has updated. The default implementation is pretty naive so it is
   * recommended that implementing classes improve the functinoality.
   */
  makeNewRecord(prevRecord, newData) {
    return {
      ...prevRecord,
      ...newData,
    };
  }

  getRecordById(recordId) {
    const record = this.data.recordsById[recordId];
    return record || null;
  }

  /**
   * Refreshes the list using the supplied refresher function.
   */
  async refresh() {
    if (!this._refresh) {
      throw new Error('List container does not have a refresh function');
    }
    try {
      await this._async.wrap(async (throwIfCanceled) => {
        const nextRecords = await this._refresh(throwIfCanceled);
        throwIfCanceled();
        this.setRecords(nextRecords);
      });
    } catch (err) {
      console.warn('TODO: Check for async base canceled error');
      console.error(`${this.name}: Unable to refresh records`, err);
      this.setData(this.makeInitialData());
      throw err;
    }
  }

  /**
   * Manually set record values.
   *
   * This can be useful in situations where a client wants to adjust
   * the data without needing to refresh/rebuild it from the main
   * refresh function.
   */
  setRecords(records) {
    const nextRecords = records ? [...records] : [];
    const nextIds = this._idsFromRecords(nextRecords);
    const nextRecordsById = this._idLookupFromRecords(nextRecords);
    this.patchData({
      ids: nextIds,
      records: nextRecords,
      recordsById: nextRecordsById,
    });
  }

  /**
   * Pushes a single record into the collection.
   */
  pushRecord(record) {
    const nextRecords = [...this.records, record];
    const nextIds = this._idsFromRecords(nextRecords);
    const nextRecordsById = this._idLookupFromRecords(nextRecords);
    this.patchData({
      ids: nextIds,
      records: nextRecords,
      recordsById: nextRecordsById,
    });
  }

  /**
   * Patches one of the records in the collection.
   *
   * Keep in mind that record collections typically don't commit any of their
   * changes to the server, so this will typically be a local-only change.
   *
   * The `quiet` argument will silently return if the record is not included in
   * the collection. This can be useful when listening to data updates from a
   * State Ref but don't know if the underlying record is actually part of this
   * collection.
   */
  patchRecordById(recordId, partialRecord, quiet) {
    const prevRecord = this.getRecordById(recordId);
    if (!prevRecord) {
      if (quiet) {
        return;
      }
      throw new Error(`Record ${recordId} not found in collection`);
    }
    const nextRecord = this.makeNewRecord(prevRecord, partialRecord);
    // TODO: If nextRecord === null remove the record from the collection?
    if (nextRecord === prevRecord) {
      return;  // No change in data
    }
    const prevRecords = this.data.recrds;
    const nextRecords = prevRecords.map(
      record => (record.id === recordId) ? nextRecord : record
    );
    const nextRecordsById = {...this.data.recordsById, [recordId]: nextRecord};
    // Don't update `ids` collection since it's unchanged
    this.patchData({
      records: nextRecords,
      recordsById: nextRecordsById,
    });
  }

  /**
   * Used when listening for changes in other states
   */
  pathUpdatedRecord(record) {
    if (record && record.id) {
      this.patchRecordById(record.id, record, true);
    }
  }

  /**
   * Applies sorting to the records without calling the collection refresh.
   */
  sort(sortFunc) {
    const prevIds = this.data.ids;
    const prevRecords = this.data.records;
    const nextRecords = [...prevRecords];
    nextRecords.sort(sortFunc);
    const nextIds = _idsFromRecords(nextRecords);
    // Return early if the order is unchanged (since it means that the sort was
    // a no-op)
    let same = true;
    for (let i = 0; i < nextIds.length; i++) {
      if (nextIds[i] !== prevIds[i]) {
        same = false;
        break;
      }
    }
    if (same) {
      return;
    }
    // Don't update the map because it does not care about order
    this.patchData({
      ids: nextIds,
      records: nextRecords,
    });
  }

  /**
   * Waits for the list to be ready (e.g. waits for async to finish)
   */
  async waitForListReady(timeoutMs) {
    return await this.waitForDataCondition((data) => {
      return data.async.isComplete;
    }, timeoutMs);
  }

  /**
   * Listens to `stateRef` for changes to `recordKey`. If a change is
   * detected,
   */
  listenToRef(stateRef, recordKey) {
    this._relatedStateListener.listen(stateRef, recordKey);
  }

  _getRecordId(record) {
    if (!record.id) {
      console.error(`${this.name}: Record is missing id`, record);
      throw new Error('All records must have an ID');
    }
    return record.id;
  }

  _idsFromRecords(records) {
    const recordIds = [];
    for (const record of records) {
      recordIds.push(this._getRecordId(record));
    }
    return recordIds;
  }

  _idLookupFromRecords(records) {
    const recordLookup = {};
    for (const record of records) {
      recordLookup[this._getRecordId(record)] = record;
    }
    return recordLookup;
  }

  /////////////////////////////////////////////////////////////////////////////
  // COMPATIBILITY
  // These functions are compatibility wrappers between eventlib and fstate.
  // Once everything has been consolidated into fstate, then these should be
  // fully deprecated and removed.

  /**
   * Similar to `onData` except that it will compare list data and run callback
   * whenever the actual list changes.
   *
   * Deprecated in favor of subscribing to the path `records`
   */
  onListChange(callback, options) {
    options = {...(options || {}), path: 'records'};
    return this.subscribe(callback, options);
  }

}
