import statelib from 'statelib';
import MapStoreRef from './map-store-ref';

import type MapZoneLiteStoreState from './map-zone-lite-store-state';

type MapZoneLiteRecordType = any;  // TODO: Fix me


export default class MapZoneLiteStoreRef extends statelib.RecordStoreRef<MapZoneLiteRecordType> {

  protected _mapStoreRef: MapStoreRef | null = null;

  constructor(store: MapZoneLiteStoreState, options) {
    super(store, options);
    // TODO: It might make sense to only include a mapStoreRef if included in
    // options - I'm not sure if ALL zone refs need the parent map data.
    if (options.includeMap) {
      this._mapStoreRef = store.mapStore.getRecordRef({});
      this._mapStoreRef.subscribe(this.$b._onMapData, {path: 'record'});
    }
  }

  destroy() {
    if (this._mapStoreRef) {
      this._mapStoreRef.destroy();
      this._mapStoreRef = null;
    }
    super.destroy();
  }

  getExtraData(nextRecord: MapZoneLiteRecordType | null): {} {
    if (!this._mapStoreRef) {
      return {};
    }
    if (nextRecord && nextRecord.mapId) {
      this._mapStoreRef.key = nextRecord.mapId;
    } else {
      this._mapStoreRef.key = null;
    }
    return {
      map: this._mapStoreRef.data.record,
    };
  }

  protected _onMapData(data) {
    data = data || null;
    this.setValueIfChanged('map', data);
  }

}
