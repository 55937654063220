import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const _EMPTY_STYLE = Object.freeze({});


export default function ProgressBar({
  progress,
  style,
  variant,
}) {
  return (
    <div
      className={classNames({
        'progress-bar-wrapper': true,
        'progress-bar-wrapper--neutral': variant === 'neutral',
      })}
      style={style || _EMPTY_STYLE}
    >
      <div
        className={classNames({
          'progress-bar-inner': true,
          'progress-bar-inner--neutral': variant === 'neutral',
        })}
        style={{width: `${progress * 100}%`}}
      />
    </div>
  );
}


ProgressBar.propTypes = {
  progress: PropTypes.number,
  style: PropTypes.object,
  variant: PropTypes.oneOf(['neutral']),
};

ProgressBar.defaultProps = {
  variant: 'neutral',
};
