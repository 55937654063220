import memoizeOne from "memoize-one";

interface RectLike {
  x: number,
  y: number,
  w: number,
  h: number,
  x2: number,
  y2: number,
};

function isNotNumber(value: any) {
  return (value === null || value === undefined || isNaN(value))
}


/**
 * Class for working with Rectangles.
 *
 * All rectangle objects are immutable.
 *
 * This is meant to replace the "Squares" class eventually. All `Rect` objects
 * are compatible with Squares functions, although the output will be a squares
 * object which may not be compatible with Rect functions.
 */
export default class Rect {

  public static ZERO: Rect;  // Populated after with a static value

  /**
   * Safely checks if two rect-like objects are equal.
   */
  public static eq(rect1: RectLike | null, rect2: RectLike | null): boolean {
    if (rect1 === rect2) {
      return true;
    } else if (!rect1 && !rect2) {
      return true;
    } else if (!rect1 !== !rect2) {
      return false;
    }
    return (
      rect1.x === rect2.x &&
      rect1.y === rect2.y &&
      rect1.w === rect2.w &&
      rect1.h === rect2.h
    );
  }

  public static containingRect(rect1: RectLike, rect2: RectLike): Rect {
    return Rect.fromCoordinates(
      Math.min(rect1.x, rect2.x),
      Math.min(rect1.y, rect2.y),
      Math.max(rect1.x2, rect2.x2),
      Math.max(rect1.y2, rect2.y2),
    );
  }

  public static fromCanvas(canvas: HTMLCanvasElement, offsetX?: number, offsetY?: number): Rect {
    offsetX = offsetX || 0;
    offsetY = offsetY || 0;
    return Rect.fromDimensions(offsetX, offsetY, canvas.width, canvas.height);
  }

  public static fromElement(elem: HTMLElement, offsetX?: number, offsetY?: number): Rect {
    offsetX = offsetX || 0;
    offsetY = offsetY || 0;
    return Rect.fromDimensions(offsetX, offsetY, elem.offsetWidth, elem.offsetHeight);
  }

  public static fromCoordinates(x: number, y: number, x2: number, y2: number): Rect {
    if (isNotNumber(x) || isNotNumber(y) || isNotNumber(x2) || isNotNumber(y2)) {
      throw new Error('All arguments must be valid numbers');
    }
    if (x2 < x) {
      [x, x2] = [x2, x];
    }
    if (y2 < y) {
      [y, y2] = [y2, y];
    }
    return new Rect(x, y, x2 - x, y2 - y, x2, y2);
  }

  public static fromDimensions(x: number, y: number, w: number, h: number): Rect {
    if (isNotNumber(x) || isNotNumber(y) || isNotNumber(w) || isNotNumber(h)) {
      throw new Error('All arguments must be valid numbers');
    }
    if (w < 0 || h < 0) {
      throw new Error('Width and height must be positive');
    }
    return new Rect(x, y, w, h, x + w, y + h);
  }

  /**
   * Similar to `fromDimensions` except that the resulting rect is always at
   * x=0 and y=0. There are a number of situations where the position of the
   * rect isn't actually important, only the size of the rect.
   */
  public static fromSize(w: number, h: number) {
    return Rect.fromDimensions(0, 0, w, h);
  }

  public static fromObject(squaresObj: RectLike | Rect | null): Rect {
    if (squaresObj === null) {
      return Rect.ZERO;
    } else if (squaresObj instanceof Rect) {
      return squaresObj;
    } else if (
      squaresObj.x === 0 &&
      squaresObj.y === 0 &&
      squaresObj.w === 0 &&
      squaresObj.h === 0 &&
      squaresObj.x2 === 0 &&
      squaresObj.y2 === 0
    ) {
      return Rect.ZERO;
    }
    return new Rect(
      squaresObj.x,
      squaresObj.y,
      squaresObj.w,
      squaresObj.h,
      squaresObj.x2,
      squaresObj.y2,
    );
  }

  public readonly x: number;
  public readonly y: number;
  public readonly w: number;
  public readonly h: number;
  public readonly x2: number;
  public readonly y2: number;

  protected constructor(
    x: number,
    y: number,
    w: number,
    h: number,
    x2: number,
    y2: number,
  ) {
    this.x = x;
    this.y = y;
    this.w = w;
    this.h = h;
    this.x2 = x2;
    this.y2 = y2;
    Object.freeze(this);
  }

  getCenter(): [x: number, y: number] {
    return [
      this.x + (this.w / 2.0),
      this.y + (this.h / 2.0),
    ];
  }

  /**
   * Grows (or shrinks) the rect about is upper-left corner. This is less
   * useful, but faster, than `growCentered`. It is typically only good
   * when the square itself may be moved or you are comparing the sizes of
   * squares rather than their locations.
   */
  grow(wAmount: number, hAmount?: number): Rect {
    hAmount = (hAmount === undefined) ? wAmount : hAmount;
    return Rect.fromDimensions(
      this.x,
      this.y,
      this.w + wAmount,
      this.h + hAmount,
    );
  }

  /**
   * Scales the provided square by `scaleValue`
   */
  scale(scaleValue: number): Rect {
    if (scaleValue === 1.0) {
      return this;
    }
    return Rect.fromDimensions(
      this.x,
      this.y,
      this.w * scaleValue,
      this.h * scaleValue,
    );
  }

  /**
   * As `scale`, except that it occurs around the square's center.
   */
  scaleCentered(scaleValue: number): Rect {
    if (scaleValue === 1.0) {
      return this;
    }
    return this.growCentered(
      this.w * (scaleValue - 1),
      this.h * (scaleValue - 1),
    );
  }

  /**
   * Inversely scales the provided square by `scaleValue`. This is often
   * used when trying to return to source coordinates after a zoom.
   */
  invScale(scaleValue: number): Rect {
    if (scaleValue === 1.0) {
      return this;
    }
    return Rect.fromDimensions(
      this.x,
      this.y,
      this.w / scaleValue,
      this.h / scaleValue
    );
  }

  /**
   * As `invScale`, except that it occurs around the square's center.
   */
  invScaleCentered(scaleValue: number): Rect {
    if (scaleValue === 1.0) {
      return this;
    }
    return this.growCentered(
      this.w / (scaleValue - 1),
      this.h / (scaleValue - 1),
    );
  }

  /**
   * Grows (or shrinks) the rect by the scaler values. The square will
   * grow or shrink about its current center point.
   */
  growCentered(wAmount: number, hAmount?: number): Rect {
    hAmount = (hAmount === undefined) ? wAmount : hAmount;
    return Rect.fromDimensions(
      this.x - (wAmount / 2),
      this.y - (hAmount / 2),
      this.w + wAmount,
      this.h + hAmount,
    );
  }

  /**
   * Rounds all coordinates to integer values. This can be useful immediately
   * before working with canvases to remove subpixel nonsense.
   */
  round(): Rect {
    if (this === Rect.ZERO) {
      return this;
    }
    return Rect.fromCoordinates(
      Math.round(this.x),
      Math.round(this.y),
      Math.round(this.x2),
      Math.round(this.y2),
    );
  }

  /**
   * Truncates all coordinates to integer values. This can be useful immediately
   * before working with canvases to remove subpixel nonsense.
   */
  trunc(): Rect {
    if (this === Rect.ZERO) {
      return this;
    }
    return Rect.fromCoordinates(
      Math.trunc(this.x),
      Math.trunc(this.y),
      Math.trunc(this.x2),
      Math.trunc(this.y2),
    );
  }

  /**
   * Removes any decimal values from rect, similar to `round` or `trunc`. This
   * will ensure that subpixels are contained within the resulting rect - the
   * top and left sides will be `floor`d while the bottom and right sides will
   * be `ceil`d.
   *
   * This is particularly useful when it comes to redraw regions. It will create
   * a redraw where the borders are all integer values but will still contain
   * within it any original subpixel values.
   */
  roundOuter(): Rect {
    if (this === Rect.ZERO) {
      return this;
    }
    return Rect.fromCoordinates(
      Math.floor(this.x),
      Math.floor(this.y),
      Math.ceil(this.x2),
      Math.ceil(this.y2),
    );
  }

  /**
   * Returns true if the point indicated by `x` and `y` is within the square or
   * if the point lies on one of the squares edges.
   *
   * If `exlusive` is true, then this acts like a range and is exclusive on the
   * "upperbounds" of the square. This can be useful when a grid of rectangs
   *  share edges but only one should contain any given point.
   */
  containsPoint(x: number, y: number, exclusive?: boolean): boolean {
    if (isNotNumber(x) || isNotNumber(y)) {
      return false;
    }
    if (exclusive) {
      return (
        x >= this.x &&
        x <=this.x2 &&
        y >= this.y &&
        y < this.y2
      );
    } else {
      return (
        x >= this.x &&
        x <= this.x2 &&
        y >= this.y &&
        y <= this.y2
      );
    }
  }

  /**
   * Returns true if there is an intersection between this and other
   *
   * See: https://stackoverflow.com/a/306332/703040
   *
   * TODO: Does this handle the case where other is completely inside of this?
   * I'm not sure if it does.
   */
  hasIntersection(other: Rect | RectLike): boolean {
    return (
      this.x < other.x2 &&
      this.x2 > other.x &&
      this.y < other.y2 &&
      this.y2 > other.y
    );
  }

  /**
   * Gets the intersection between the two squares. If no such intersection
   * exists, returns null.
   */
  getIntersection(other: Rect | RectLike): Rect {
    if (this === Rect.ZERO || other === Rect.ZERO) {
      return Rect.ZERO;
    } else if (!this.hasIntersection(other)) {
      return Rect.ZERO;
    }
    return Rect.fromCoordinates(
      Math.max(this.x, other.x),
      Math.max(this.y, other.y),
      Math.min(this.x2, other.x2),
      Math.min(this.y2, other.y2),
    );
  }

  /**
   * Gets the scaling factor that is sufficient so that `this` could fully cover
   * `other`.
   */
  getCoverScale(other: Rect): number {
    const wRatio = other.w / this.w;
    const hRatio = other.h / this.h;
    return Math.max(wRatio, hRatio);
  }

  /**
   * Gets the scaling factor that is sufficient such that `this` could fit
   * entirely within `other`.
   */
  getFitWithinScale(other: Rect): number {
    const wRatio = other.w / this.w;
    const hRatio = other.h / this.h;
    return Math.min(wRatio, hRatio);
  }

  /**
   * Returns a rect with `this` rects aspect ratio that is _just_ large enough
   * to cover the `other` rect. The returned rect will be centered relative to
   * `other`. This can be useful for ensuring that an image is large enough to
   * cover a rectangular area. If `this` rect is larger than necessary to cover
   * `other`, it will be scaled down instead.
   */
  cover(other: Rect): Rect {
    if (this === Rect.ZERO) {
      return other;
    } else if (other === Rect.ZERO) {
      return Rect.ZERO;
    }
    const coverScale = this.getCoverScale(other);
    return this.scale(coverScale).alignCenterToRect(other);
  }

  /**
   * Similar to `cover` except that the Rect isn't moved, only resized
   */
  resizeToCover(other: Rect): Rect {
    if (this === Rect.ZERO) {
      return other;
    } else if (other === Rect.ZERO) {
      return Rect.ZERO;
    }
    return this.scale(this.getCoverScale(other));
  }

  centerWithin(other: Rect): Rect {
    if (this === Rect.ZERO) {
      return other;
    } else if (other === Rect.ZERO) {
      return Rect.ZERO;
    }
    const fitWithinScale = this.getFitWithinScale(other);
    return this.scale(fitWithinScale).alignCenterToRect(other);
  }

  /**
   * Returns a rect with the same aspect ratio of `this` but resized such
   * that it could fit within `other`.
   */
  resizeToFitWithin(other: Rect): Rect {
    if (this === Rect.ZERO) {
      return other;
    } else if (other === Rect.ZERO) {
      return Rect.ZERO;
    }
    return this.scale(this.getFitWithinScale(other));
  }

  /**
   * Returns a rect that is centered relative to the specified point.
   */
  alignCenterToPoint(x: number, y: number): Rect {
    return Rect.fromDimensions(
      x - (this.w / 2.0),
      y - (this.h / 2.0),
      this.w,
      this.h,
    );
  }

  /**
   * Returns a rect that is centered relative to the center of the other rect.
   */
  alignCenterToRect(other: Rect): Rect {
    const [x, y] = other.getCenter();
    return this.alignCenterToPoint(x, y);
  }

  /**
   * Moves the rect by `xAmount` and `yAmount`. If `yAmount` is omitted, then
   * it will move by `xAmount` along bot axis.
   */
  shift(xAmount: number, yAmount?: number): Rect {
    yAmount = (yAmount === undefined) ? xAmount : yAmount;
    return Rect.fromDimensions(
      this.x + xAmount,
      this.y + yAmount,
      this.w,
      this.h,
    );
  }

  /**
   * Returns true if this square is equal to another
   */
  isEqualTo(other: Rect | RectLike): boolean {
    return this === other || (
      this.x === other.x &&
      this.y === other.y &&
      this.x2 === other.x2 &&
      this.y2 === other.y2
    );
  }

  /**
   * Returns true if this square is NOT equal to another
   */
  isNotEqualTo(other: Rect | RectLike): boolean {
    return this !== other && (
      this.x !== other.x ||
      this.y !== other.y ||
      this.x2 !== other.x2 ||
      this.y2 !== other.y2
    );
  }

  /**
   * Returns a scaling factors that would need to be applied to `other` so that
   * it would be the same size as this rect. Returns the horizontal (w) and
   * verital (h) scaling factors independently. Throws an error if `other` is
   * null or otherwise has a 0 width or height value.
   */
  getScale(other: Rect | RectLike): [w: number, h: number] {
    if (!other || !other.w || !other.h) {
      throw new Error("The other rect is not valid for scaling");
    }
    return [this.w / other.w, this.h / other.h]
  }

  toString = memoizeOne((): string => {
    return `(${this.x},${this.y});(${this.x2},${this.y2});(${this.w}x${this.h})`;
  });

  /**
   * Returns the rect as an array. Used for unpacking as arguments to various
   * graphics functions.
   *
   * The result of the call is memoized.
   */
  toXYWH = memoizeOne((): readonly [x: number, y: number, w: number, h: number] => {
    // Weirdly enough need to case this. Doesn't seem like typescript
    // understands that we are freezing a 4-element array.
    return Object.freeze(
      [this.x, this.y, this.w, this.h]
    ) as readonly [x: number, y: number, w: number, h: number];
  });

  /**
   * Converts the rect to a plain, rect-like object, memoizing the result.
   */
  toPlainObject = memoizeOne((): RectLike => {
    return Object.freeze({
      x: this.x,
      y: this.y,
      w: this.w,
      h: this.h,
      x2: this.x2,
      y2: this.y2,
    });
  });

}


// Set the singleton ZERO rect.
Rect.ZERO = Rect.fromDimensions(0, 0, 0, 0);
