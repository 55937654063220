import Rect from "utils/rect";
import makeBindable from 'utils/bound-methods';

import type CanvasEngine from "./canvas-engine";

/** Makes hitboxes visible */
const DEBUG = false;

/**
 * A simple data class for managing information about a Game Object's
 * event target.
 *
 * One thing to keep in mind: If you add mouse events, you should consider
 * setting the engine focus location based on the mouse event. Otherwise it can
 * do weird stuff.
 */
export default class EventHitBox {

  protected _isDestroyed: boolean;
  protected _engine: CanvasEngine;
  protected _parent: HTMLElement;
  protected _element: HTMLElement;
  protected _sourceRect: Rect | null;
  protected _onFocus: (event: FocusEvent) => void;
  protected _onBlur: (event: FocusEvent) => void;

  public $b: any;
  private $bdestroy: any;

  constructor(engine: CanvasEngine, elementId: string) {
    makeBindable(this);
    this._isDestroyed = false;
    this._engine = engine;
    this._parent = engine.viewParent;
    this._element = engine.viewParent.ownerDocument.createElement('div');
    this._sourceRect = Rect.ZERO;
    this._element.id = elementId;
    this._element.dataset.hitbox = 'true';
    this.initStyles();
    this._parent.appendChild(this._element);
  }

  get element(): HTMLElement {
    return this._element;
  }

  destroy() {
    if (this._isDestroyed) {
      return;
    }
    this._element.remove();
    this._element = null;
    this._sourceRect = null;
    this._parent = null;
    this._engine = null;
    this.$bdestroy();
  }

  initStyles() {
    this._element.style.left = '0px';
    this._element.style.top = '0px';
    this._element.style.width = '0px';
    this._element.style.height = '0px';
    if (DEBUG) {
      this._element.className = 'canvasengine--event-hitbox canvasengine--event-hitbox--debug';
    } else {
      this._element.className = 'canvasengine--event-hitbox';
    }
  }

  /**
   * Refreshes the event target element's position based on the source rect.
   */
  refreshPosition() {
    // Use view-browser instead of view-canvas since we're working with DOM elements
    const viewRect = this._engine.viewBrowserRect;
    if (
      this._sourceRect === null ||
      this._sourceRect === Rect.ZERO ||
      viewRect === null ||
      viewRect === Rect.ZERO
    ) {
      this._setElementPosition(null);
      return;
    }
    const browserRect = this._engine.sourceRectToBrowserRect(this._sourceRect);
    const intersection = viewRect.getIntersection(browserRect);
    if (intersection === null) {
      this._setElementPosition(null);
      return;
    }
    this._setElementPosition(intersection);
  }

  private _setElementPosition(browserRect: Rect | null) {
    // TODO: Potentially detach elements that aren't there?
    if (browserRect === null) {
      this._element.style.left = '0px';
      this._element.style.top = '0px';
      this._element.style.width = '0px';
      this._element.style.height = '0px';
      this._element.style.display = 'none';
    } else {
      browserRect = browserRect.round();
      this._element.style.left = `${browserRect.x}px`;
      this._element.style.top = `${browserRect.y}px`;
      this._element.style.width = `${browserRect.w}px`;
      this._element.style.height = `${browserRect.h}px`;
      this._element.style.display = 'block';
    }
  }

  /**
   * Detaches the event handler from the DOM but keeps it in memory. This can be
   * useful if it needs to temporarily be disabled or if the elements
   * themselves need to be re-ordered.
   */
  detach() {
    this._element.remove();
  }

  /**
   * Appends the element to the parent. This is mostly useful for re-ordering
   * the different event handlers (the last child will be the "top" when it
   * comes to priority for handling events)
   */
  attach() {
    this._parent.appendChild(this._element);
  }

  /**
   * Sets the source rect. Returns true if this changes anything.
   */
  setSourceRect(sourceRect: Rect): boolean {
    sourceRect = sourceRect || Rect.ZERO;
    if (this._sourceRect.isNotEqualTo(sourceRect)) {
      this._sourceRect = sourceRect;
      return true;
    }
    return false;
  }

  withFocus(onFocus?, onBlur?) {
    this._element.tabIndex = 0;
    this._onFocus = onFocus;
    this._onBlur = onBlur;
    this._element.addEventListener('focus', this.$b._handleFocus);
    this._element.addEventListener('blur', this.$b._handleBlur);
  }

  _handleFocus(event: FocusEvent) {
    if (this._onFocus) {
      this._onFocus(event);
    }
  }

  _handleBlur(event: FocusEvent) {
    if (this._onBlur) {
      this._onBlur(event);
    }
  }
}
