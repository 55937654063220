// Maximum frame before looping _frame back to 0. This means that a frame
// handled can run for ~415 days at 60 fps before looping. Looping shouldn't
// really cause any major issues, either, except for perhaps causing a stutter
// for anything that runs every X frames
const MAX_INT = 2147483647;

// When an error occurs in a frame callback, the next frame will wait this many
// milliseconds before being scheduled. This prevents the log from being
// overwhelmed making it impossible to stop.
const FRAME_ERROR_BUBBLE_MS = 2000;


/**
 * Highly efficient class acting as a "game loop" that will run at the maximum
 * available frame rate.
 */
export default class FrameHandler {

  static factory = () => new FrameHandler();

  constructor(callback) {
    this._lastCallbackId = 0;  // Maybe switch to bigints?
    this._callbacks = new Map();
    // An immutable array of callbacks, refreshed whenever a callback is added or
    // removed. The thought is that callbacks are going to change much less
    // often than every frame, so calculating this value at that point is
    // better than on each frame tick.
    this._immutableCallbacks = [];
    this._running = false;
    this._frameno = null;
    this._lastTimestamp = null;
    this._boundHandleAnimationFrame = this._handleAnimationFrame.bind(this);
    // Backwards compatibility - set the initial callback
    if (callback) {
      this.onFrame(callback);
    }
  }

  _handleAnimationFrame(timestamp) {
    if (!this._running) {
      return;
    }
    const timeDeltaMs = (this._lastTimestamp) ? timestamp - this._lastTimestamp : 1;
    const frameno = (this._frameno >= MAX_INT) ? 0 : this._frameno + 1;
    this._lastTimestamp = timestamp;
    this._frameno = frameno;
    const callbacks = this._immutableCallbacks;
    try {
      for (const callback of callbacks) {
        callback(timestamp, timeDeltaMs, frameno);
      }
      requestAnimationFrame(this._boundHandleAnimationFrame);
    } catch (err) {
      setTimeout(() => {
        if (this._running) {
          requestAnimationFrame(this._boundHandleAnimationFrame);
        }
      }, FRAME_ERROR_BUBBLE_MS);
      throw err;
    }
  }

  /**
   * Callback will be provided 3 parameters. The first is a raw timestamp
   * (same as `requestAnimationFrame`), the next will be the time delta between
   * the timestamp and the previous one, and the final will be a frame number.
   *
   * Note that frame number is NOT guaranteed to increase forever. It will
   * eventually reset to 0 to avoid overflow issues.
   */
  onFrame(callback) {
    const callbackId = this._lastCallbackId + 1;
    if (callbackId >= MAX_INT) {
      throw new Error('Hit maximum number of callbacks');
    }
    this._lastCallbackId = callbackId;
    this._callbacks.set(callbackId, callback);
    this._immutableCallbacks = [...this._callbacks.values()];
    return callbackId;
  }

  /**
   * As `onFrame` except this returns an unsubscribe function
   */
  subscribeOnFrame(callback) {
    const callbackId = this.onFrame(callback);
    return () => this.removeCallback(callbackId);
  }

  /**
   * Like `onFrame` except that this will only call `callback` every `period`
   * frames. The `offset` can be used to make different callbacks run on
   * staggered frames. For example, if you had 2 slow operations, you could
   * have each run on a slow frame with a period of 2, but use an offset of 0
   * for the first and 1 for the second. Configured this way, they will run on
   * different frames from one another.
   *
   * The same result can be acheived by checking for frameNo manually in an
   * onTick callback and sum
   *
   * Since this wraps `callback`, the only way to unsubscribe is to call the
   * returned unsubscriber.
   */
  onSlowFrame(callback, period, offset) {
    const immPeriod = period || 1;
    const immOffset = offset || 0;
    let lastTimestamp = this._lastTimestamp;
    return this.onTick((timestamp, timeDelta, frameNo) => {
      if ((frameNo + immOffset) % immPeriod === 0) {
        try {
          callback(timestamp, timestamp - lastTimestamp, frameNo);
        } finally {
          lastTimestamp = timestamp;
        }
      }
    });
  }

  /**
   * As `onSlowFrame` except this returns an unsubscribe function
   */
  subscribeOnSlowFrame(callback, period, offset) {
    const callbackId = this.onSlowFrame(callback, period, offset);
    return () => this.removeCallback(callbackId);
  }

  /**
   * Removes an `onFrame` callback based on the returned ID.
   */
  removeCallback(callbackId) {
    if (this._callbacks.has(callbackId)) {
      this._callbacks.delete(callbackId);
      this._immutableCallbacks = [...this._callbacks.values()];
    } else {
      console.info(`frame-handler: Callback ${callbackId} already removed`);
    }
  }

  /**
   * Begins the frame handler. It will start calling any callbacks each frame as
   * appropriate.
   *
   * Remember that FrameHandler instances are not running by default.
   */
  start() {
    if (this._running) {
      throw new Error('Frame Handler already running');
    }
    this._running = true;
    this._frameno = 0;
    this._lastTimestamp = performance.now();
    requestAnimationFrame(this._boundHandleAnimationFrame);
    // TODO: performance.now() _might_ not work. In that case, need to instead
    // have the first frame be SKIPPED just so that we can store the last
    // timestamp. After that, it can run normally.
  }

  /**
   * Stops the frame handler. Any frame currently being processed will finish,
   * but future-scheduled frames will not run. Callbacks will be preserved for
   * when the frame handler is started again.
   */
  stop() {
    if (!this._running) {
      console.warn('Frame handler already stopped');
    }
    this._running = false;
    this._frameno = null;
    this._lastTimestamp = null;
  }

  /**
   * Stops the frame handler and removes all of it's callbacks. WHile there's
   * nothing wrong with re-using it, maybe not a good idea?
   *
   * This is primarily meant to be included when the owner of the frame handler
   * no longer needs it as a way to release references to any callbacks.
   */
  reset() {
    this._lastCallbackId = 0;
    this._callbacks = new Map();
    this._immutableCallbacks = [];
    this._running = false;
    this._frameno = null;
    this._lastTimestamp = null;
  }

}
