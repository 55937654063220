import Vector from "./vector";

export default class Pullable {

  protected velocity: Vector = new Vector();
  public x: number | null = null;
  public y: number | null = null;
  public minVelocity: number = 1000;
  public maxVelocity: number = 2200;  // TODO: Factor in pixel density
  public nearbyDistance: number = 200;

  get coordinates(): [x: number | null, y: number | null] {
    return [this.x, this.y];
  }

  get velocityRange(): number {
    return this.maxVelocity - this.minVelocity;
  }



  pull(x: number | null, y: number | null, timeDeltaMs: number): void {
    const deltaSec = timeDeltaMs / 1000.0;
    if (x === null || y === null) {
      this.velocity = new Vector();
      this.x = null;
      this.y = null;
    } else if (this.x === null || this.y === null) {
      this.velocity = new Vector();
      this.x = x;
      this.y = y;
    } else {
      const deltaVector = Vector.fromPoints(this.x, this.y, x, y);
      if (deltaVector.magnitude < (deltaSec * this.velocity.magnitude)) {
        // Prevent overshooting
        this.velocity = new Vector();
        this.x = x;
        this.y = y;
      } else {
        let maxVelocity = this.maxVelocity;
        if (deltaVector.magnitude <= this.nearbyDistance) {
          const nearVelocity = (
            (this.maxVelocity - this.minVelocity) *
            (deltaVector.magnitude / this.nearbyDistance) *
            (deltaVector.magnitude / this.nearbyDistance)
          );
          maxVelocity = this.minVelocity + nearVelocity;
        }
        const traveled = this.velocity.multiply(deltaSec);
        this.x += traveled.magX;
        this.y += traveled.magY;
        this.velocity = (new Vector(
          deltaVector.angle,
          this.velocity.magnitude + (this.velocityRange * 3 * deltaSec),
        )).clampMagnitude(this.minVelocity, maxVelocity);
      }
    }
  }

}
