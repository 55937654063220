import React from 'react';
import PropTypes from 'prop-types';

import {Input} from '@salesforce/design-system-react';
import {Button} from '@salesforce/design-system-react';

import QueuedAsyncState from 'statelib/queued-async-state';
import FlexBarLayout from 'components/layouts/flex-bar-layout';
import FormHandler from 'formlib/form-handler';
import ReactComponent from 'utils/react-component';
import UserInfo from 'utils/user-info';
import {connectToComponentState} from 'statelib/fstate-react';
import {makeRpcCall} from 'utils/async';
import {users as usersPb} from 'proto-bundle';

const callLoginRpc = makeRpcCall(
  '/rpc/users/v1/session_login',
  usersPb.LoginRequest,
  usersPb.User,
);


class _LoginFormHandler extends FormHandler {

  refreshForm(data) {
    this.updateFormFields({
      emailAddress: data.emailAddress || '',
      password: data.password || '',
    });
  }

  onEmailAddressChange(event, data) {
    this.setFormFieldAndData('emailAddress', data.value);
  }

  onPasswordChange(event, data) {
    this.setFormFieldAndData('password', data.value);
  }

}


export default class LoginForm extends ReactComponent {

  constructor() {
    super();
    this.formHandler = new _LoginFormHandler('LoginForm');
    this.async = new QueuedAsyncState('LoginFormAsync');
    this.addCleanup(
      this.formHandler.connectFieldsToComponentState(this, 'fields'),
      connectToComponentState(this.async, this, 'async'),
      this.formHandler.$b.close,
      this.async.$b.close,
    );
  }

  async handleSubmit(event) {
    event.preventDefault();
    var success = false;
    UserInfo.reset();
    try {
      let loginResult = await this.async.wrap(async () => {
        return await callLoginRpc(this.formHandler.getData());
      });
      console.log('Login Success', loginResult);
      if (this.props.onSuccessfulLogin) {
        this.props.onSuccessfulLogin();
      }
    } catch (err) {
      console.error('Login Failed', err);
      this.formHandler.setFormFieldError('$all', `${err}`);
      this.async.reset();
    } finally {
      UserInfo.reset();
      UserInfo.load();
    }
  }

  render() {
    const {fields, async} = this.state;
    const handler = this.formHandler;
    return (
      <form onSubmit={(event) => this.handleSubmit(event)}>
        <Input
          name='emailaddress'
          label='Email Address'
          value={fields.emailAddress.value}
          onChange={handler.$b.onEmailAddressChange}
          disabled={async.isRunning}
          errorText={fields.$all.error}
          required={true}
          autoComplete='username'
        />
        <Input
          name='password'
          label='Password'
          value={fields.password.value}
          onChange={handler.$b.onPasswordChange}
          disabled={async.isRunning}
          type='password'
          required={true}
          autoComplete='current-password'
        />
        <FlexBarLayout className='slds-p-top_large'>
          <Button
            disabled={async.isRunning}
            className='big-button'
            label={async.isRunning ? 'Logging in...' : 'Log in'}
            variant='brand'
            type='submit'
          />
        </FlexBarLayout>
      </form>
    );
  }

}
