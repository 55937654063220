import classNames from 'classnames';
import React from 'react';

import UserMenu from 'components/authentication/user-menu';

/**
 * A wrapper around `UserMenu` that floats it over content on the upper right
 */
export default function EmbarkUserMenu() {
  return (
    <div
      className={classNames({
        'layout--simple-container-box': true,
        'layout--floating-top-right': true,
      })}
    >
      <UserMenu />
    </div>
  );
}
