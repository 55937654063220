import BaseAsyncState from './base-async-state';


export default class QueuedAsyncState extends BaseAsyncState {

  constructor(name) {
    super(name || 'QueuedAsyncState');
    this._asyncQueue = [];
    this._curPromise = null;
  }

  destroy() {
    this._asyncQueue = null;
    super.destroy();
  }

  /**
   * Raises an error if the async is currently in a failed state.
   */
  _checkFailed() {
    this.checkDestroyed();
    if (this.data.isFailed) {
      throw new BaseAsyncState.AsyncHasFailedError(
        `A previous async request has failed: ${this.data.message}`
      );
    }
  }

  /**
   * Queues up `fn` to be called by the async handler.
   */
  async wrap(fn) {
    const promise = new Promise((resolve, reject) => {
      const needsStart = this._asyncQueue.length === 0 && !this._curPromise;
      this._asyncQueue.push({fn: fn, resolve: resolve, reject: reject});
      if (needsStart) {
        this.start();
        this._runNext();
      }
    });
    return await promise;
  }

  /**
   * Runs the next async callback in the queue
   */
  async _runNext() {
    const throwIfCanceled = () => {};  // TODO: Figure this out?
    if (this._asyncQueue.length) {
      const {fn, resolve, reject} = this._asyncQueue.shift();
      try {
        this._checkFailed();
        const nextPromise = fn(throwIfCanceled);
        this._curPromise = nextPromise;
        const result = await nextPromise;
        this._curPromise = null;
        resolve(result);
      } catch (err) {
        if (!(err instanceof BaseAsyncState.AsyncHasFailedError)) {
          this.error(err);
        }
        reject(err);
      } finally {
        if (!this.isDestroyed) {
          // Explicitly don't await the next, let it happen in the background.
          // This doesn't set timeout because we want to allow a series of
          // short, synchronous tasks to be processed immediately if they have
          // been queued. This is most important for things like received
          // websocket events.
          this._runNext();
        }
      }
    } else if (this.data.isFailed) {
      // Do nothing - one of the tasks has failed so nothing more to do.
    } else {
      this.success();
    }
  }

  /**
   * Resets any errors associated with the async state. Note that if reset is
   * called without first calling `cancel`, it is possible that requests will
   * still be queued. If this happens, the queued requests will immediately
   * become unblocked and start running again
   *
   * See `BaseAsyncState.reset()`
   */
  reset() {
    super.reset();
    this._curPromise = null;
    this._runNext();
  }

  cancel() {
    // TODO: Immediately go through everything in async queue and
    // reject it with a canceled error. If `_runNext` is active, it should
    // it will need to somehow handled that something has been canceled (how??
    // since it somehow needs to be associated with the current run? Otherwise
    // you couldn't immediately enqueue new things).
    //
    // TODO: Does cancel need to be followed by reset?
  }

}
