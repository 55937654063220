import AppGlobal from 'global';
import MapsRPCAPI from 'apis/maps-rpc-api';
import SimplePubSub from 'utils/simple-pub-sub';
import statelib from 'statelib';
import undolib from 'undolib';
import UndoStep from 'undolib/undo-step';
import UndoStepConfig from 'undolib/undo-step-config';

import MapImageStoreState from './map-image-store-state';
import MapObjectStoreRef from './map-object-store-ref';

import type FState from 'statelib/fstate';

type KeyType = string | number;
type MapObjectRecordType = any;  // TODO: Fix me

interface TokenMoveUndoRedoConfig {
  tokenId: string,
  prevMapZoneId: string | null,
  prevTokenCol: number | null,
  prevTokenRow: number | null,
  nextMapZoneId: string | null,
  nextTokenCol: number | null,
  nextTokenRow: number | null,
}

interface NewRecordEventType {
  id: string,
  record: MapObjectRecordType,
}


/**
 * Contains functinoality for working with MapObjects.
 *
 * Use MapObjectStoreRef to create references to this data store and interact
 * with it on a single-object basis.
 */
export default class MapObjectStoreState extends statelib.RecordStoreState<MapObjectRecordType>{

  static DEFAULT_NAME = 'MapObjectStore';

  _campaignId: string;
  _campaignRef: FState;
  _campaignRefUnsubscribe?: () => void;

  /** Used for publishing data when a new record is created (NOT when a new
    * record is retrieved - specifically created) */
  _newRecordPubSub: SimplePubSub<NewRecordEventType> = new SimplePubSub<NewRecordEventType>();

  /** An image store. If available, any images associated with map objects will
    * automatically be loaded into the image store when they are retrieved */
  _imageStore: MapImageStoreState | null = null;


  constructor(options) {
    super({...options, keyField: 'id'});
    this._campaignId = null;
    this._campaignRef = options.campaignRef || null;
    this._campaignRefUnsubscribe = null;
    if (this._campaignRef) {
      this._campaignRefUnsubscribe = this._campaignRef.subscribe(this.$b._handleCampaignChange);
    }
    this._imageStore = options.imageStore || null;
  }

  destroy() {
    if (this._campaignRefUnsubscribe) {
      this._campaignRefUnsubscribe();
      this._campaignRefUnsubscribe = null;
    }
    this._campaignRef = null;
    this._campaignId = null;
    super.destroy();
  }

  get campaignId() {
    return this._campaignId;
  }

  set campaignId(campaignId) {
    campaignId = campaignId || null;
    if (campaignId === this._campaignId) {
      return;
    }
    this.resetAll();
    this._campaignId = campaignId;
  }

  get newRecordPubSub() {
    return this._newRecordPubSub;
  }

  getRecordRef(options, key?: KeyType): MapObjectStoreRef {
    const recordRef = new MapObjectStoreRef(this, options);
    if (key) {
      recordRef.key = key;
    }
    return recordRef;
  }

  _handleCampaignChange(data) {
    if (this._campaignId !== data.campaignId) {
      this.campaignId = data.campaignId;  // Setter handles cleanup work.
    }
  }

  /**
   * See RecordStoreState.setRecords
   *
   * In addition to the normal `setRecords` functionality, this will set image
   * data in the image store.
   */
  setRecords(
    records: MapObjectRecordType[],
    {expectedKeys = [], previousKeys = []}: {expectedKeys?: KeyType[], previousKeys?: KeyType[]} = {},
  ): void {
    // First, load any images that may exist on the map objects. Doing this
    // first will ensure that image data is available BEFORE any listeners
    // receive updated map object data.
    if (this._imageStore) {
      const seenImages: Set<string> = new Set();
      const newImages = [];
      for (const record of records) {
        if (record.token) {
          const tokenDetails = record.token;
          if (tokenDetails.image && tokenDetails.image.url) {
            if (!seenImages.has(tokenDetails.image.id)) {
              newImages.push(tokenDetails.image);
              seenImages.add(tokenDetails.image.id);
            }
          }
          if (tokenDetails.smallImage && tokenDetails.smallImage.url) {
            if (!seenImages.has(tokenDetails.smallImage.id)) {
              newImages.push(tokenDetails.smallImage);
              seenImages.add(tokenDetails.smallImage.id);
            }
          }
          if (tokenDetails.protraitImage && tokenDetails.protraitImage.url) {
            if (!seenImages.has(tokenDetails.protraitImage.id)) {
              newImages.push(tokenDetails.protraitImage);
              seenImages.add(tokenDetails.protraitImage.id);
            }
          }
        }
      }
      if (newImages.length) {
        this._imageStore.setRecords(newImages);
      }
    }
    // Set the updated map object data.
    super.setRecords(records, {expectedKeys, previousKeys});
  }

  /**
   * Loads multiple records by key (id).
   *
   * Currently this is iterative. The other loader functions are preferred when
   * loading multiple map objects.
   *
   * TODO: If this is actually used, consider adding an API endpoint for getting
   * tokens by ID in bulk (note that we can't do it with the standard search
   * request since you can't do repeated inside of oneof)
   *
   * Note that "loading" new keys is safe - they will be ignored
   */
  async load(keys: KeyType[]) {
    if (keys) {
      keys = keys.filter(key => !this.isNewKey(key));
    }
    const campaignId = this._campaignId;
    if (!campaignId) {
      console.warn('Attempting to load Map Objects but no campaign');
      return;
    }
    try {
      await this._async.wrap(async (throwIfCanceled) => {
        for (const key of keys) {
          const searchResult = await MapsRPCAPI.findMapObjects({
            campaignId: campaignId,
            mapObjectId: key,
          });
          throwIfCanceled();
          this.setRecords(searchResult.mapObjects || [], {expectedKeys: [key]});
        }
      });
    } catch (err) {
      console.warn('TODO: Check for async base canceled error');
      console.error(`${this.name}: Unable to load records (${keys})`, err);
      throw err;
    }
  }

  /**
   * Triggers a load/refresh of ALL map objects for a given zone. This is
   * needed so that the map objects are in the store even if the user hasn't
   * done anything else to load them (such as opening the token tray)
   */
  async loadObjectsForZone(mapZoneId): Promise<void> {
    // We don't care about the search results - we're just calling search
    // so that everything gets loaded into memory
    await this.searchMapObjects({
      mapZoneId: {value: mapZoneId},
      all: true,
    });
  }

  /**
   * Performs a server-side search of map objects
   *
   * This will refresh the internal state of all found map objects and then
   * return the list of found objects.
   *
   * Note that the campaign ID from the search object will be overwritten with
   * the campaign
   */
  async searchMapObjects(searchObj): Promise<MapObjectRecordType[]> {
    const campaignId = this._campaignId;
    if (!campaignId) {
      console.warn('Attempting to load Map Objects but no campaign');
      return;
    }
    searchObj = {...searchObj, campaignId: campaignId};
    try {
      return await this._async.wrap(async (throwIfCanceled) => {
        const searchResult = await MapsRPCAPI.findMapObjects(searchObj);
        throwIfCanceled();
        const mapObjects = searchResult.mapObjects || [];
        if (campaignId === this._campaignId) {
          this.setRecords(mapObjects);
        }
        return mapObjects;
      });
    } catch (err) {
      console.warn('TODO: Check for async base canceled error');
      console.error(`${this.name}: Unable to load records (${searchObj})`, err);
      throw err;
    }
  }

  /**
   * Inserts/Puts a map object to the server and returns the hydrated value.
   *
   * The value will also be added/updated in the store.
   */
  async upsertMapObject(mapObject: MapObjectRecordType): Promise<MapObjectRecordType> {
    let nextMapObject: MapObjectRecordType;
    try {
      try {
        await this._async.wrap(async (throwIfCanceled) => {
          // Assume success first, makes the app feel more responsive
          this.setRecords([mapObject]);
          // Upsert the data
          const oldKeys: string[] = [];
          if (this.isNewKey(mapObject.id) || !mapObject.id) {
            if (mapObject.id) {
              oldKeys.push(mapObject.id)
            }
            nextMapObject = await MapsRPCAPI.createMapObject({...mapObject, id: null});
          } else {
            nextMapObject = await MapsRPCAPI.putMapObject(mapObject);
          }
          console.info('Map Object upserted', nextMapObject);
          throwIfCanceled();
          this.setRecords([nextMapObject], {previousKeys: oldKeys});
          if (oldKeys.length > 0) {
            this._newRecordPubSub.publish({id: nextMapObject.id, record: nextMapObject});
          }
        });
      } finally {
        // The state itself is global no owner nor UI to render the error. Throw
        // it up the stack.
        this._async.throwError();
      }
    } catch (err) {
      this.load([mapObject.id]);
      throw err;
    }
    return nextMapObject;
  }

  /**
   * A version of upsertMapObject specifically for updating object locations.
   *
   * This typically requires less strict permissions than upsertMapObject since
   * it can bypass standard write permissions based on the user's role.
   */
  async patchMapObjectLocation(mapObject: MapObjectRecordType): Promise<MapObjectRecordType> {
    let nextMapObject: MapObjectRecordType;
    try {
      try {
        await this._async.wrap(async (throwIfCanceled) => {
          // Assume success first, makes the app feel more responsive
          this.setRecords([mapObject]);
          nextMapObject = await MapsRPCAPI.patchMapObjectLocation(mapObject);
          console.info('Map Object location updated', nextMapObject);
          throwIfCanceled();
          this.setRecords([nextMapObject]);
        });
      } finally {
        // The state itself is global no owner nor UI to render the error. Throw
        // it up the stack.
        this._async.throwError();
      }
    } catch (err) {
      this.load([mapObject.id]);
      throw err;
    }
    return nextMapObject;
  }

  async deleteMapObject(mapObject: MapObjectRecordType): Promise<void> {
    try {
      try {
        await this._async.wrap(async (throwIfCanceled) => {
          await MapsRPCAPI.deleteMapObjects({ids: [mapObject.id]});
          throwIfCanceled();
          this.delete([mapObject.id]);
        });
      } finally {
        // The state itself is global no owner nor UI to render the error. Throw
        // it up the stack.
        this._async.throwError();
      }
    } catch (err) {
      this.load([mapObject.id]);
      throw err;
    }
  }

  protected _getTokenPosition(tokenMapObject): [col: number, row: number] {
    const tokenDetails = tokenMapObject.token;
    if (!tokenDetails.position) {
      return [null, null];
    } else if (!tokenDetails.position.sub) {
      return [
        tokenDetails.position.col || 0,
        tokenDetails.position.row || 0,
      ];
    } else {
      return [
        (tokenDetails.position.col || 0) + (tokenDetails.position.sub.col || 0),
        (tokenDetails.position.row || 0) + (tokenDetails.position.sub.row || 0),
      ];
    }
  }

  async moveToken(
    tokenId: string,
    mapZoneId: string | null,
    tokenCol: number | null,
    tokenRow: number | null,
  ) {
    const tokenMapObject = this.getRecordSync(tokenId, true);
    if (!tokenMapObject) {
      console.warn('No token map object available', tokenId);
      return;
    }
    const prevMapZoneId = tokenMapObject.mapZoneId || null;
    const [prevTokenCol, prevTokenRow] = this._getTokenPosition(tokenMapObject);
    const movePromise = this.moveTokenNoUndo(tokenId, mapZoneId, tokenCol, tokenRow);
    undolib.instance.pushUndo(
      `mapZone:${AppGlobal.mapZoneDetails.mapZoneId}`,
      this.makeTokenMoveUndoRedoStep({
        tokenId: tokenId,
        prevMapZoneId: prevMapZoneId,
        prevTokenCol: prevTokenCol,
        prevTokenRow: prevTokenRow,
        nextMapZoneId: mapZoneId,
        nextTokenCol: tokenCol,
        nextTokenRow: tokenRow,
      }),
    );
    await movePromise;
  }

  async moveTokenNoUndo(
    tokenId: string,
    mapZoneId: string | null,
    tokenCol: number | null,
    tokenRow: number | null,
  ) {
    const tokenMapObject = this.getRecordSync(tokenId, true);
    if (!tokenMapObject) {
      console.warn('No token map object available', tokenId);
      return;
    }
    const nextTokenMapObject = {
      ...tokenMapObject,
      mapZoneId: mapZoneId,
      token: {
        ...tokenMapObject.token,
        position: {
          col: Math.floor(tokenCol),
          row: Math.floor(tokenRow),
          sub: {
            col: tokenCol - Math.floor(tokenCol),
            row: tokenRow - Math.floor(tokenRow),
          }
        }
      }
    };
    await this.patchMapObjectLocation(nextTokenMapObject);
  }

  public makeTokenMoveUndoRedoStep(
    config: TokenMoveUndoRedoConfig, isRedo?
  ): UndoStepConfig {
    return new undolib.UndoStepConfig({
      description: `${isRedo ? 'Redo' : 'Undo'} move token`,
      data: config,
      checkPrecondition: this.$b._checkTokenMoveUndoRedo,
      execute: this.$b._executeTokenMoveUndoRedo,
    })
  }

  private _checkTokenMoveUndoRedo(undoStep: UndoStep) {
    // TODO: It _might_ be bad that we get the token synchronously since it
    // could have been evicted from the store?
    const data: TokenMoveUndoRedoConfig = undoStep.config.data;
    const tokenId = data.tokenId;
    const token = this.getRecordSync(tokenId, true);
    if (!token) {
      return undolib.UndoStep.UNDO_STEP_INVALID;
    }
    // TODO: Check if current position is the "next" position. if not, the
    // token has been altered since.
    return undolib.UndoStep.UNDO_STEP_VALID;
  }

  private _executeTokenMoveUndoRedo(undoStep: UndoStep) {
    // TODO: It _might_ be bad that we get the token synchronously since it
    // could have been evicted from the store?
    const data: TokenMoveUndoRedoConfig = undoStep.config.data;
    const tokenMapObject = this.getRecordSync(data.tokenId, true);
    if (!tokenMapObject) {
      console.warn('No token map object available', data.tokenId);
      return;
    }
    // One thing to verify is if redo stack should use the CURRENT location or
    // the original "Next" location. Current is probably more natural, but
    // the original next is more "technically" correct?
    const curMapZoneId = tokenMapObject.mapZoneId || null;
    const [curTokenCol, curTokenRow] = this._getTokenPosition(tokenMapObject);
    this.moveTokenNoUndo(
      data.tokenId, data.prevMapZoneId, data.prevTokenCol, data.prevTokenRow
    );
    undoStep.pushInverseStep(
      this.makeTokenMoveUndoRedoStep({
        tokenId: data.tokenId,
        prevMapZoneId: curMapZoneId,
        prevTokenCol: curTokenCol,
        prevTokenRow: curTokenRow,
        nextMapZoneId: data.prevMapZoneId,
        nextTokenCol: data.prevTokenCol,
        nextTokenRow: data.prevTokenRow,
      }, !undoStep.isRedo)
    )
  }

}
