import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import AppGlobal from 'global';
import AppReactComponent from 'utils/app-react-component';
import Tooltip from 'components/basic/tooltip';
import Vector from 'utils/geometry/vector';

import TokenImage from './token-image';
import { isUsableNumber } from 'utils/types';

// The distance moved (in pixels) after which we assume that the user is
// dragging (either to scroll the tray or to "pick up" a token)
const DRAG_BEGIN_DISTANCE = 20;


/**
 * A single item in a token tray.
 *
 * This will typically be a token itself but may include other things as well
 * (such as a token group or something else)
 */
export default class TokenTrayItem extends AppReactComponent {

  static propTypes = {
    annotation: PropTypes.string,
    border: PropTypes.object,
    className: PropTypes.string,
    classNameImage: PropTypes.string,
    classNameLabel: PropTypes.string,
    description: PropTypes.string,  // If provided, used as part of the tooltip.
    disabled: PropTypes.bool,
    editable: PropTypes.bool,
    id: PropTypes.string,
    imageUrl: PropTypes.string,
    isFocused: PropTypes.bool,
    mapZoneId: PropTypes.string,
    name: PropTypes.string.isRequired,
    onBeginDrag: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onEdit: PropTypes.func,
    variant: PropTypes.oneOf(['neutral']),  // TODO: Styling variants? Maybe use bools instead.
  };

  static defaultProps = {
    disabled: false,
    imageUrl: '',  // TODO: A placeholder token image.
    isFocused: false,
    variant: 'neutral',
  };

  constructor(props) {
    super(props);
    this._elem = null;
    this._dragInitEvent = null;
    this._dragAngle = null;
    this._mapZoneRef = AppGlobal.mapZoneLiteStore.getRecordRef(
      {includeMap: true}, props.mapZoneId
    );
    this.connect('mapZone', this._mapZoneRef, 'record');
    this.connect('map', this._mapZoneRef, 'map');
    this.addCleanup(() => this._mapZoneRef.destroy());
  }

  _initItemDomEvents(elem) {
    if (elem) {
      this._elem = elem;
      // Note: This does not use the "official" drag-and-drop APIs because
      // (a) they aren't well supported on mobile and (b) they are actually
      // ill-suited for this specific task (they are meant for dragging items
      // around the computer, not within a tab). Instead, implement very basic
      // drag and drop gestures using mouse down and mouse out.
      elem.addEventListener('pointerdown', this.$b._handlePointerDown);
      elem.addEventListener('click', this.$b._handleClick);
      elem.addEventListener('focus', this.$b._handleFocus);
      elem.addEventListener('blur', this.$b._handleBlur);
      // Add the additional event listeners as well. These cannot "wait" until
      // "pointerdown" due to some nuance with touch controls (the handlers
      // won't be called if they are added after the touch has started)
      elem.addEventListener('pointermove', this.$b._handleDragPointerMove);
      elem.addEventListener('pointerout', this.$b._handleDragPointerOut);
      elem.addEventListener('pointerup', this.$b._handleDragPointerUp);
      this.addCleanup(() => {
        elem.removeEventListener('pointerdown', this.$b._handlePointerDown);
        elem.removeEventListener('click', this.$b._handleClick);
        elem.removeEventListener('focus', this.$b._handleFocus);
        elem.removeEventListener('blur', this.$b._handleBlur);
        elem.removeEventListener('pointermove', this.$b._handleDragPointerMove);
        elem.removeEventListener('pointerout', this.$b._handleDragPointerOut);
        elem.removeEventListener('pointerup', this.$b._handleDragPointerUp);
      })
    }
  }

  _handlePointerDown(event) {
    // Prevent default to avoid drag-highlighting. Manually focus the element to
    // compensate.
    event.preventDefault();
    if (this._elem) {
      this._elem.focus();
    }
    this._dragInitEvent = event;
    this._dragAngle = null;
    // TODO: Do different stuff on right-click
  }

  _updateDragAngle(event) {
    if (this._dragInitEvent === null) {
      return null;
    } else if (event.screenX === 0 && event.screenY === 0) {
      // Some touch-pointer-events don't have a screenX or screenY resulting in
      // strange behaviors. Ignore these events.
      return null;
    } else if (
      !isUsableNumber(this._dragInitEvent.screenX) ||
      !isUsableNumber(this._dragInitEvent.screenY) ||
      !isUsableNumber(event.screenX) ||
      !isUsableNumber(event.screenY)
    ) {
      throw new Error(`Bad event coordinates: (${this._dragInitEvent.screenX}, ${this._dragInitEvent.screenY}) -> (${event.screenX, event.screenY})`);
    }
    const dragVector = Vector.fromPoints(
      this._dragInitEvent.screenX,
      this._dragInitEvent.screenY,
      event.screenX,
      event.screenY,
    );
    this._dragAngle = dragVector.angle;
    return dragVector;
  }

  _handleDragPointerMove(event) {
    if (this._dragInitEvent === null) {
      return;
    }
    const dragVector = this._updateDragAngle(event);
    if (dragVector.magnitude > DRAG_BEGIN_DISTANCE) {
      this._stopDragging(event, true);
    }
  }

  _handleDragPointerOut(event) {
    if (this._dragInitEvent === null) {
      return;
    }

    // Dragging out only works for mouse pointers, but try it anyway
    // Seems like a combination of:
    //
    // https://w3c.github.io/pointerevents/#implicit-pointer-capture
    // https://w3c.github.io/pointerevents/#suppressing-a-pointer-event-stream
    // https://w3c.github.io/pointerevents/#dfn-implicit-pointer-capture
    //
    // In all of my testing, there was always some moment when the mobile
    // browser simply stopped capturing pointer events. Event when the event
    // handler was at the document level and even if we set the pointer capture
    // target, as soon as the pointer left the initial target (the image) it
    // was over.
    if (event.pointerType !== "mouse") {
      return;
    }
    this._updateDragAngle(event);
    this._stopDragging(event, true);
  }

  _handleDragPointerUp(event) {
    if (this._dragInitEvent === null) {
      return;
    }
    this._stopDragging(event, false);
    event.preventDefault();
  }

  _stopDragging(event, emit) {
    const dragAngle = this._dragAngle;
    this._dragInitEvent = null;
    this._dragAngle = null;
    this.setState({dragDebug: null});
    if (emit && this.props.onBeginDrag) {
      this.props.onBeginDrag(event, this.props.id, dragAngle);
    }
  }

  _handleFocus(event) {
    if (this.props.onFocus) {
      this.props.onFocus(event, this.props.id);
    }
  }

  _handleBlur(event) {
    if (this.props.onBlur) {
      this.props.onBlur(event, this.props.id);
    }
  }

  _handleClick(event) {
    if (this.props.onClick) {
      this.props.onClick(event, this.props.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mapZoneId !== this.props.mapZoneId) {
      this._mapZoneRef.key = this.props.mapZoneId;
    }
  }

  render() {
    const props = this.props;
    const state = this.state;  // Will probably be used to handle UI events
    const isDraggable = props.onBeginDrag && !props.disabled;
    const location = this._getLocationName();
    const tooltip = (props.description || props.name) + (location ? ' • ' + location : '');
    return (
      <Tooltip content={tooltip}>
        <div
          ref={this.$b._initItemDomEvents}
          tabIndex='0'
          className={classNames(props.className, {
            'tokens--tray-item': true,
            'tokens--tray-item-focused': props.isFocused,
            'dragndrop--draggable': isDraggable,
          })}
        >
          <TokenImage
            ref={this.$b._initImageDomEvents}
            className={classNames(props.classNameImage, {
              'tokens--tray-item-image': true,
              'dragndrop--draggable': isDraggable,
            })}
            src={props.imageUrl}
            alt={`${props.name} token`}
            border={props.border}
            annotation={props.annotation}
          />
          <div
            className={classNames(
              'tokens--tray-item-label-container',
            )}
          >
            <span
              className={classNames(props.classNameLabel, {
                'tokens--tray-item-label': true,
                'dragndrop--draggable': isDraggable,
              })}
            >
              {props.name}
            </span>
          </div>
        </div>
      </Tooltip>
    );
  }

  _getLocationName() {
    const props = this.props;
    const state = this.state;
    if (!props.mapZoneId) {
      return null;
    }
    if (
      !state.mapZone ||
      !state.mapZone.permissions ||
      !state.mapZone.permissions.canRead ||
      !state.map ||
      !state.map.permissions ||
      !state.map.permissions.canRead
    ) {
      return 'Location Unknown';
    }
    // Getting the public/protected name may not be necessary because the
    // server performs this transformation as well. Do it here just for safety.
    const mapName = state.map.permissions.protectedFieldAccess.canRead ? state.map.name : state.map.publicName;
    const mapZoneName = state.mapZone.permissions.protectedFieldAccess.canRead ? state.mapZone.name : state.mapZone.publicName;
    return `${mapName} • ${mapZoneName}`;
  }

}
