import AppGlobal from 'global';

import CanvasUtils from 'utils/graphics/canvas-utils';
import FrameRenderContext from 'canvasengine/frame-render-context';
import FrameUpdateContext from 'canvasengine/frame-update-context';
import GameObject from 'canvasengine/gameobjects/game-object';
import MapObject from 'components/mapview/map-object';
import PerformanceCapture from 'utils/perf-capture';
import Rect from 'utils/rect';

const LAYER_NEUTRAL = 'LAYER_NEUTRAL';
const LAYER_EXPLORED = 'LAYER_EXPLORED';
const LAYER_VISIBILITY = 'LAYER_EXPLORED';


/**
 * An object that will display fog of war, attempting to combine all of the
 * visibility from various shapes into something cohesive.
 *
 * This ends up needing to be a single object so that it can combine and overlap
 * visibility without re-drawing stuff a zillion times.
 *
 * This works against the global masks
 *
 * TODO: We need a way to get a Path2D (or at least a clipping mask) from a
 * `BaseShape` object, specifically a Mask. This can be used to draw things
 * without needing to do quite so much drawing.
 */
export default class ZoneFogOfWar extends GameObject {

  protected _privilegedAccess: boolean = true;
  protected _exploredMaskContainer: any = null;
  protected _boundaryMaskContainer: any = null;
  protected _overrideHiddenMaskContainer: any = null;
  protected _overrideVisibleMaskContainer: any = null;
  protected _exploredMaskVersion: number | null = null;
  protected _boundaryMaskVersion: number | null = null;
  protected _overrideHiddenMaskVersion: number | null = null;
  protected _overrideVisibleMaskVersion: number | null = null;
  protected _layer: string = LAYER_NEUTRAL;
  protected _dirty: boolean = true;
  protected _captureMetrics: boolean = false;
  // The following support caching combined mask information.
  protected _cachedVisibleMask: any = null;
  protected _cachedKnownMask: any  = null;
  protected _cachedExploredMask: any  = null;  // Explored but NOT visible.
  protected _lastExploredMaskVersion: number = null;
  protected _lastBoundaryMaskVersion: number  = null;
  protected _lastOverrideHiddenMaskVersion: number  = null;
  protected _lastOverrideVisibleMaskVersion: number  = null;

  constructor() {
    super();
    this._exploredMaskContainer = AppGlobal.mapZoneExploredMask;
    this._boundaryMaskContainer = AppGlobal.mapZoneBoundariesMask;
    this._overrideHiddenMaskContainer = AppGlobal.mapZoneOverrideHiddenMask;
    this._overrideVisibleMaskContainer = AppGlobal.mapZoneOverrideVisibleMask;
  }

  get scratchId(): string {
    return `${this.id}_scratch`;
  }

  set privilegedAccess(value: boolean) {
    this._privilegedAccess = !!value;
    this._dirty = true;
  }

  _setLayer(layer: string): void {
    if (this._layer !== layer) {
      this._layer = layer;
      this._dirty = true;
    }
  }

  resetLayer(): void {
    this._setLayer(LAYER_NEUTRAL);
  }

  setLayerExplored(): void {
    this._setLayer(LAYER_EXPLORED);
  }

  setLayerVisibility(): void {
    this._setLayer(LAYER_VISIBILITY);
  }

  isEnabled(): boolean {
    return (
      this.enabled &&
      this._exploredMaskContainer.mask &&
      this._boundaryMaskContainer.mask &&
      this._overrideHiddenMaskContainer.mask &&
      this._overrideVisibleMaskContainer.mask
    );
  }

  onUpdate(frameUpdateContext: FrameUpdateContext) {
    const frameno = frameUpdateContext.frameNo;
    const frame = frameUpdateContext.frame;
    if (this._requiresMaskShapesRefresh()) {
      this._refreshMaskShapes();
      this._dirty = true;
    }
    // TODO: I think we eventually remove the following
    if (
      this._dirty ||
      frame.isKeyframe ||
      this._exploredMaskVersion !== this._exploredMaskContainer.mask.version ||
      this._boundaryMaskVersion !== this._boundaryMaskContainer.mask.version ||
      this._overrideHiddenMaskVersion !== this._overrideHiddenMaskContainer.mask.version ||
      this._overrideVisibleMaskVersion !== this._overrideVisibleMaskContainer.mask.version
    ) {
      this._dirty = true;
      // TODO: this is kinda lame. Full redraw for any change in fog?
      frame.getLayer('fog').setFullRedraw();
    } else {
      this._dirty = false;
    }
    if (this._privilegedAccess) {
      frame.setMaskingPaths(null, null);
    } else {
      const knownPath = this._cachedKnownMask.getPath(1);
      const visiblePath = this._cachedVisibleMask.getPath(1);
      frame.setMaskingPaths(knownPath, visiblePath);
    }
    this._captureMetrics = (frameno % 100 === 0);
  }

  onRender(frameRenderContext: FrameRenderContext) {
    const render = frameRenderContext.frame;
    // This keeps a scratch copy of the fog data since it's a little expensive
    // and complicated to generate. We'll need to figure that out eventually
    // since fog doesn't change much when it's 100% manual, but if we start
    // adding line of sight it's going to update often. However, this at least
    // saves a few cycles while the screen is idle.
    //
    // TODO: This isn't a perfect way to check if something is dirty. We'll
    // need some way to see if the map layer is dirty (in other words, we need
    // better layer-based dirty checking overall).
    const fogLayer = render.layers.get('fog');
    if (this._dirty) {
      if (!this._privilegedAccess) {
        this._renderNormal(render);
      } else if (this._layer === LAYER_NEUTRAL) {
        this._renderPrivilegedBland(render);
      } else if (this._layer === LAYER_EXPLORED) {
        this._renderPrivilegedColored(render);
      } else if (this._layer === LAYER_VISIBILITY) {
        this._renderPrivilegedColored(render);
      } else {
        console.info('Unable to select fog render technique', {
          _privilegedAccess: this._privilegedAccess,
          _layer: this._layer,
        });
        throw new Error('Unable to select fog render technique');
      }
      this._exploredMaskVersion = this._exploredMaskContainer.mask.version;
      this._boundaryMaskVersion = this._boundaryMaskContainer.mask.version;
      this._overrideHiddenMaskVersion = this._overrideHiddenMaskContainer.mask.version;
      this._overrideVisibleMaskVersion = this._overrideVisibleMaskContainer.mask.version;
      this._dirty = false;

      // Blur is REALLY slow except when applied as a CSS filter, so we want to
      // do it on the layer canvas instead
      let nextBlurFilter = 'blur(0)';
      if (
        AppGlobal.settings.value('graphics.quality') >= 3 &&
        this._exploredMaskContainer.mask
      ) {
        const blurBasePx = Math.min(
          this._exploredMaskContainer.mask.grid.gridToPixelWidth(0.015),
          this._exploredMaskContainer.mask.grid.gridToPixelWidth(0.015)
        );
        const blurPx = Math.max(0, blurBasePx * render.scale);
        nextBlurFilter = `blur(${Math.round(blurPx)}px)`;
      }
      fogLayer.getCanvas().style.filter = nextBlurFilter;
    }
  }

  _drawMask(context, maskShape) {
    context.drawImage(
      maskShape.canvas,
      0,
      0,
      maskShape.maskWidth,
      maskShape.maskHeight,
      maskShape.containingSquare.x,
      maskShape.containingSquare.y,
      maskShape.containingSquare.w,
      maskShape.containingSquare.h,
    );
  }

  _drawLayer(sourceContext, targetContext, sourceScale?) {
    if (sourceScale === undefined) {
      sourceScale = 1;
    }
    const sourceCanvas = sourceContext.canvas;
    targetContext.save();
    try {
      CanvasUtils.resetTransform(targetContext);
      targetContext.imageSmoothingEnabled = false;
      targetContext.drawImage(
        sourceCanvas,
        0,
        0,
        Math.floor(sourceCanvas.width * sourceScale),
        Math.floor(sourceCanvas.height * sourceScale),
        0,
        0,
        targetContext.canvas.width,
        targetContext.canvas.height,
      );
    } finally {
      targetContext.restore();
    }
  }

  /**
   * Draws to the scratch a player-representation of visibility
   */
  _renderNormal(render) {
    const contextFog = render.getLayer('fog').getContext();
    const contextMap = render.getLayer('map').getContext();
    const exploredPath = this._cachedExploredMask.getPath(1);
    const visiblePath = this._cachedVisibleMask.getPath(1);
    const sourceSq = render.sourceRect;
    contextFog.save();
    try {
      // Clip so that all of the fancy explored stuff only applies to relevant
      // areas(TODO: There might be a cool way to get the visible stuff out of
      // here as well)
      const perfMetrics = new PerformanceCapture('zone-fog-of-war.js:_renderNormal');

      // Start by masking everything then cut otu explored/visible
      contextFog.fillStyle = 'rgba(0, 0, 0, 1)';
      contextFog.fillRect(sourceSq.x, sourceSq.y, sourceSq.w, sourceSq.h);

      // Render previously explroed areas - copy the map data and render it
      if (exploredPath) {
        contextFog.save();
        contextFog.clip(exploredPath, 'evenodd');
        contextFog.globalCompositeOperation = 'source-over';
        this._drawLayer(contextMap, contextFog);
        perfMetrics.capture('point1');
        // Slightly de-saturate the explored, non-visible areas (just for fun)
        // Desaturation is a somewhat expensive operation so only do it as needed
        if (AppGlobal.settings.value('graphics.quality') >= 4) {
          contextFog.globalCompositeOperation = 'saturation';
          contextFog.fillStyle = 'rgba(0, 0, 0, 0.6)';
          contextFog.fillRect(sourceSq.x, sourceSq.y, sourceSq.w, sourceSq.h);
          perfMetrics.capture('point2');
        }
        // Slightly cover the explored, non-visible areas with fog color
        contextFog.globalCompositeOperation = 'source-over';
        contextFog.fillStyle = 'rgba(0, 0, 0, 0.5)';
        contextFog.fillRect(sourceSq.x, sourceSq.y, sourceSq.w, sourceSq.h);
        perfMetrics.capture('point3');
        // Pop the explored-area-only clip
        contextFog.restore();
      }

      // Clip out visible area
      if (visiblePath) {
        CanvasUtils.clearPath(contextFog, sourceSq, visiblePath, 'evenodd');
        perfMetrics.capture('point4');
      }

      if (this._captureMetrics) {
        perfMetrics.debug();
      }
    } finally {
      contextFog.restore();
    }
  }

  /**
   * Draws the DM-representation of the fog-of-war without the distracting
   * colors. This is very similar to the player view except that nothing is
   * 100% invisible so that the DM can see the underlying map.
   */
  _renderPrivilegedBland(render) {
    const contextFog = render.getLayer('fog').getContext();
    const boundaryMask = this._boundaryMaskContainer.mask;
    const exploredMask = this._exploredMaskContainer.mask;
    const overrideHiddenMask = this._overrideHiddenMaskContainer.mask;
    const overrideVisibleMask = this._overrideVisibleMaskContainer.mask;
    const boundaryPath = boundaryMask.getPath(1);
    const exploredPath = exploredMask.getPath(1);
    const overrideHiddenPath = overrideHiddenMask.getPath(1);
    const overrideVisiblePath = overrideVisibleMask.getPath(1);
    const sourceSq = render.sourceRect;
    contextFog.clearRect(sourceSq.x, sourceSq.y, sourceSq.w, sourceSq.h);
    contextFog.save();
    try {
      contextFog.globalCompositeOperation = 'source-over';
      // By default, everything is covered in fog color
      contextFog.fillStyle = 'rgba(0, 0, 0, 1)';
      contextFog.fillRect(sourceSq.x, sourceSq.y, sourceSq.w, sourceSq.h);
      // Display explored areas in a lighter color
      if (exploredPath) {
        contextFog.fillStyle = 'rgba(180, 210, 180, 1)';
        contextFog.fill(exploredPath, 'evenodd');
      }
      // Display override hidden areas with fog color as well
      if (overrideHiddenPath) {
        contextFog.fillStyle = 'rgba(0, 0, 0, 1)';
        contextFog.fill(overrideHiddenPath, 'evenodd');
      }
      // Make everything slightly transparent
      contextFog.globalCompositeOperation = 'destination-out';
      contextFog.fillStyle = 'rgba(0, 0, 0, 0.5)';
      contextFog.fillRect(sourceSq.x, sourceSq.y, sourceSq.w, sourceSq.h);
      // Lastly "cut out" anything that is explicitly visible
      if (overrideVisiblePath) {
        CanvasUtils.clearPath(contextFog, sourceSq, overrideVisiblePath, 'evenodd');
      }
      // Draw the border as opaque fog
      if (boundaryPath) {
        contextFog.globalCompositeOperation = 'source-over';
        contextFog.fillStyle = 'rgba(0, 0, 0, 1)';
        contextFog.fill(boundaryPath, 'evenodd');
      }
    } finally {
      contextFog.restore();
    }
  }

  /**
   * Draws to the scratch a colorful representation of visibility.
   *
   * TODO: Should have a pattern for this instead (using the weird fill with
   * pattern feature) that give sit both color and texture.
   */
  _renderPrivilegedColored(render) {
    const contextFog = render.getLayer('fog').getContext();
    const boundaryMask = this._boundaryMaskContainer.mask;
    const exploredMask = this._exploredMaskContainer.mask;
    const overrideHiddenMask = this._overrideHiddenMaskContainer.mask;
    const overrideVisibleMask = this._overrideVisibleMaskContainer.mask;
    const boundaryPath = boundaryMask.getPath(1);
    const exploredPath = exploredMask.getPath(1);
    const overrideHiddenPath = overrideHiddenMask.getPath(1);
    const overrideVisiblePath = overrideVisibleMask.getPath(1);
    const sourceSq = render.sourceRect;
    contextFog.clearRect(sourceSq.x, sourceSq.y, sourceSq.w, sourceSq.h);
    contextFog.save();
    try {
      contextFog.globalCompositeOperation = 'source-over';
      // Areas that are not overridden (invisible to players) are blue-ish
      contextFog.fillStyle = 'rgba(80, 80, 180, 1)';
      contextFog.fillRect(sourceSq.x, sourceSq.y, sourceSq.w, sourceSq.h);
      // Areas that are explored are gray-ish
      if (exploredPath) {
        contextFog.fillStyle = 'rgba(150, 150, 150, 1)';
        contextFog.fill(exploredPath, 'evenodd');
      }
      // Clip out the hidden override and fill with red
      if (overrideHiddenPath) {
        contextFog.fillStyle = 'rgba(130, 0, 0, 1)';
        contextFog.fill(overrideHiddenPath, 'evenodd');
      }
      // Anything with a visible override will show as yellow-ish
      if (overrideVisiblePath) {
        contextFog.fillStyle = 'rgba(200, 255, 150, 1)';
        contextFog.fill(overrideVisiblePath, 'evenodd');
      }
      // Make the colors somewhat transparent by "erasing" opacity
      contextFog.globalCompositeOperation = 'destination-out';
      contextFog.fillStyle = 'rgba(0, 0, 0, 0.5)';
      contextFog.fillRect(sourceSq.x, sourceSq.y, sourceSq.w, sourceSq.h);
      // Clip out the out-of-bounds and fill with fog color
      if (boundaryPath) {
        contextFog.globalCompositeOperation = 'source-over';
        contextFog.fillStyle = 'rgba(0, 0, 0, 1)';
        contextFog.fill(boundaryPath, 'evenodd');
      }
    } finally {
      contextFog.restore();
    }
  }

  _requiresMaskShapesRefresh() {
    const baseMask = this._exploredMaskContainer.mask;
    return (
      (this._cachedVisibleMask === null && !!baseMask) ||
      (this._cachedKnownMask === null && !!baseMask) ||
      (this._cachedExploredMask === null && !!baseMask) ||
      this._cachedVisibleMask.grid !== baseMask.grid ||
      this._cachedKnownMask.grid !== baseMask.grid ||
      this._cachedExploredMask.grid !== baseMask.grid ||
      this._lastExploredMaskVersion !== this._exploredMaskContainer.mask.version ||
      this._lastBoundaryMaskVersion !== this._boundaryMaskContainer.mask.version ||
      this._lastOverrideHiddenMaskVersion !== this._overrideHiddenMaskContainer.mask.version ||
      this._lastOverrideVisibleMaskVersion !== this._overrideVisibleMaskContainer.mask.version
    );
  }

  /**
   * Refreshes teh cached masks. These combine the other visibility masks to
   * make drawing faster so long as the overall visibility remains unchanged.
   * These can also be used by other layers to "punch out" areas that are not
   * visible to save processing
   */
  _refreshMaskShapes() {
    const baseMask = this._exploredMaskContainer.mask;
    if (!baseMask) {
      console.warn('No base mask yet - is that OK?');
      return;
    }
    const maskRect = Rect.fromSize(baseMask.maskWidth, baseMask.maskHeight);
    const boundaryMask = this._boundaryMaskContainer.mask;
    const exploredMask = this._exploredMaskContainer.mask;
    const overrideHiddenMask = this._overrideHiddenMaskContainer.mask;
    const overrideVisibleMask = this._overrideVisibleMaskContainer.mask;

    // The rect in the coordinate system of bounds mask containing THIS mask
    const boundsMaskSq = Rect.fromDimensions(
      baseMask.col * boundaryMask.detail,
      baseMask.row * boundaryMask.detail,
      baseMask.width * boundaryMask.detail,
      baseMask.height * boundaryMask.detail,
    );

    function safeFillOverrideMask(context, mask) {
      if (mask) {
        context.globalCompositeOperation = 'source-over';
        context.drawImage(
          mask.canvas,
          0,
          0,
          mask.maskWidth,
          mask.maskHeight,
          maskRect.x,
          maskRect.y,
          maskRect.w,
          maskRect.h,
        );
      }
    }

    function safeClearOverrideMask(context, mask) {
      if (mask) {
        context.globalCompositeOperation = 'destination-out';
        context.drawImage(
          mask.canvas,
          0,
          0,
          mask.maskWidth,
          mask.maskHeight,
          maskRect.x,
          maskRect.y,
          maskRect.w,
          maskRect.h,
        );
      }
    }

    function safeApplyBoundaryMask(context, mask) {
      if (mask) {
        context.globalCompositeOperation = 'destination-out';
        context.drawImage(
          mask.canvas,
          boundsMaskSq.x,
          boundsMaskSq.y,
          boundsMaskSq.w,
          boundsMaskSq.h,
          maskRect.x,
          maskRect.y,
          maskRect.w,
          maskRect.h,
        );
      }
    }

    // Calcuate the cached visible mask
    const nextVisibleMask = this._getCacheShape(baseMask, this._cachedVisibleMask);
    nextVisibleMask.withMaskContext((context) => {
      context.imageSmoothingEnabled = false;
      context.clearRect(0, 0, maskRect.w, maskRect.h);
      safeFillOverrideMask(context, overrideVisibleMask);
      safeApplyBoundaryMask(context, boundaryMask);
    }, null, {noDiff: true});
    this._cachedVisibleMask = nextVisibleMask;

    // Calculate the cached explored mask
    const nextKnownMask = this._getCacheShape(baseMask, this._cachedKnownMask);
    nextKnownMask.withMaskContext((context) => {
      context.imageSmoothingEnabled = false;
      context.clearRect(0, 0, maskRect.w, maskRect.h);
      safeFillOverrideMask(context, exploredMask);
      safeClearOverrideMask(context, overrideHiddenMask);
      safeFillOverrideMask(context, overrideVisibleMask);
      safeApplyBoundaryMask(context, boundaryMask);
    }, null, {noDiff: true});
    this._cachedKnownMask = nextKnownMask;

    // Calculate the cached explored but NOT visible mask
    const nextExploredMask = this._getCacheShape(baseMask, this._cachedExploredMask);
    nextExploredMask.withMaskContext((context) => {
      context.imageSmoothingEnabled = false;
      context.clearRect(0, 0, maskRect.w, maskRect.h);
      safeFillOverrideMask(context, exploredMask);
      safeClearOverrideMask(context, overrideHiddenMask);
      safeClearOverrideMask(context, overrideVisibleMask);
      safeApplyBoundaryMask(context, boundaryMask);
    }, null, {noDiff: true});
    this._cachedExploredMask = nextExploredMask;

    // Finally, remember these versions
    this._lastExploredMaskVersion = this._exploredMaskContainer.mask.version;
    this._lastBoundaryMaskVersion = this._boundaryMaskContainer.mask.version;
    this._lastOverrideHiddenMaskVersion = this._overrideHiddenMaskContainer.mask.version;
    this._lastOverrideVisibleMaskVersion = this._overrideVisibleMaskContainer.mask.version;
  }

  _getCacheShape(base, curValue) {
    if (
      !curValue ||
      base.grid !== curValue.grid ||
      base.maskWidth !== curValue.maskWidth
    ) {
      const maskShape = base.clone();
      maskShape.initCanvas(document.createElement('canvas'));
      return maskShape;
    }
    return curValue;
  }

}
