import React from 'react';
import classNames from 'classnames';

import SingleSelectField from 'components/form/single-select-field';
import layouts from 'components/layouts';

import ImageManager from 'components/images/image-manager';
import MapsRPCAPI from 'apis/maps-rpc-api';
import QueuedAsyncState from 'statelib/queued-async-state';
import ReactComponent from 'utils/react-component';
import {connectToComponentState} from 'statelib/fstate-react';
import {uploadFiles} from 'utils/async';

const DEFAULT_CATEGORIES = ['map'];


function MapZoneImageFormView({
  async,
  className,
  map,
  mapZone,
  selectedImageId,
  handleSubmit,
  handleCancel,
  handleChangeSelectedImageId,
}) {
  return (
    <React.Fragment>
      {(async.isSuccess) ? (
        <div className={classNames('flex--container-vertical', className)}>
          <div>Map: {map.name}</div>
          <div>Zone: {mapZone.name}</div>
          <ImageManager
            className='flex--item-fill'
            defaultCategories={DEFAULT_CATEGORIES}
            ownerId={map.id}
            ownerType='map'
            onChange={handleChangeSelectedImageId}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            initialSelectedImageId={selectedImageId}
          />
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </React.Fragment>
  );
}


export default class MapZoneImageForm extends ReactComponent {

  constructor() {
    super();
    this.state = {
      selectedImageId: '',
      map: null,
      mapZone: null,
    }
    this.async = new QueuedAsyncState();
    this.addCleanup(
      this.async.$b.close,
      connectToComponentState(this.async, this, 'async'),
    )
    this.addSetup(this.$b.refresh);
  }

  componentDidUpdate(prevProps, prevState) {
    const props = this.props;
    if (prevProps.mapId !== props.mapId || prevProps.zoneId !== props.zoneId) {
      this.refresh();
    }
    super.componentDidUpdate(prevProps, prevState);
  }

  async refresh() {
    try {
      const mapId = this.props.mapId;
      const zoneId = this.props.zoneId;
      if (!mapId || !zoneId) {
        return;
      }
      await this.async.wrap(async (throwIfCanceled) => {
        const map = (await MapsRPCAPI.getMaps({mapIds: [mapId]})).maps[0];
        throwIfCanceled();
        const mapZone = (await MapsRPCAPI.getMapZone({mapZoneId: zoneId})).mapZone;
        let curImageId = null;
        if (mapZone.backgroundImage && mapZone.backgroundImage.id) {
          curImageId = mapZone.backgroundImage.id;
        }
        throwIfCanceled();
        this.setState({
          map: map,
          mapZone: mapZone,
          selectedImageId: curImageId,
        });
      });
    } catch (err) {
      console.error('Bad refresh', err);
    }
  }

  handleChangeSelectedImageId(event, imageId) {
    this.setState({selectedImageId: imageId});
  }

  async handleSubmit(event, selectedImageId) {
    event.preventDefault();
    try {
      const mapZone = await this.async.wrap(async () => {
        return await MapsRPCAPI.updateMapZoneImage({
          id: this.props.zoneId,
          backgroundImage: {id: selectedImageId},
        });
      });
      this.props.afterSubmit(mapZone);
    } catch (err) {
      console.error('Failed to set image', err);
    }
  }

  handleCancel(event) {
    this.props.afterCancel();
  }

  render() {
    return MapZoneImageFormView({
      async: this.state.async,
      className: this.props.className,
      map: this.state.map,
      mapZone: this.state.mapZone,
      selectedImageId: this.state.selectedImageId,
      handleSubmit: this.$b.handleSubmit,
      handleCancel: this.$b.handleCancel,
      handleChangeSelectedImageId: this.$b.handleChangeSelectedImageId,
    });
  }

  _imagesToImageOptions(images) {
    let imageOptions = [{id:'', name:'-- Select Image --'}];
    if (images) {
      imageOptions = imageOptions.concat(images);
    }
    return imageOptions;
  }

}
