import React from 'react';
import ReactDOM from 'react-dom';

import AppGlobal from 'global';
import GameObjectSelectionFocusState from 'canvasengine/gameobjects/game-object-selection-focus-state';
import MapObjectStoreState from 'apps/maps/state/map-object-store-state';
import MapObjectStoreRef from 'apps/maps/state/map-object-store-ref';
import BaseMapToken from './base-map-token';
import CanvasEngineInteropComponent from './canvas-engine-interop-component';
import FrameUpdateContext from 'canvasengine/frame-update-context';
import CanvasEngine from 'canvasengine/canvas-engine';
import GameObject from 'canvasengine/gameobjects/game-object';
import {shallowEqual} from 'utils/types';
import MapTokenFocusedControlElem from './map-token-focused-control-elem';

interface PropsType {
  className?: string,
  style?: object,
  canvasEngine?: CanvasEngine,
};

interface StateType {
  focusedGameObjectId: string,
  focusedToken: any,
  focusedTokenInterfaceDetails: any,
};


// IDEAS BEFORE I FALL ASLEEP!!!!!
// 1. TokenInterfaceManager needs to have a canvas.
// 2. On setup, attach a `ScriptGameObject` that calls some internal function
// within `TokenInterfaceManager`.
// 3. The on update function will look at the current focused game object's data
// to calculate the positional props required for `MapTokenFocusedControlElem`
// It will set those values in state IFF they are changed.
// 4. This on-update function can actually just replace a lot of the subscriber
// stuff. It doesn't have to, but if it's confusing it might be for the best.
// (At least replace the `focusedGameObjectId` parts).
// 5. Using the onUpdate callback to manage the internal react state will keep
// react from updated TOO often and provide a (reasonably) nice channel between
// the actual game object data and the interface.
// 6. Get this pattern RIGHT. It will probably be useful in a lot of other
// places as well.


/**
 * Takes care of the DOM-rendering of token interfaces.
 *
 * Tokens are rendered onto the canvas engine. However, there are a lot of
 * benefits of using standard DOM elements to handling the actual UI for
 * interacting with tokens. This will listen in on the game object focus and
 * selection state and will render the appropriate UI elements.
 */
export default class TokenInterfaceManager extends CanvasEngineInteropComponent<PropsType, StateType, any> {

  protected _editTokenRef: MapObjectStoreRef;
  protected _focusState: GameObjectSelectionFocusState;
  protected _mapObjectStore: MapObjectStoreState;
  protected _focusedTokenRef: MapObjectStoreRef;

  constructor(props: PropsType) {
    super(props);
    this.state = {
      focusedGameObjectId: null,
      focusedToken: null,
      focusedTokenInterfaceDetails: null,
    };
    this._editTokenRef = AppGlobal.editTokenRef;
    this._focusState = AppGlobal.gameObjectSelectionFocus;
    this._mapObjectStore = AppGlobal.mapObjectStore;
    this._focusedTokenRef = this._mapObjectStore.getRecordRef({name: "TokenInterfaceFocusedObject"});
    this.connect("focusedGameObjectId", this._focusState, "focusedGameObjectId");
    this.connect("focusedToken", this._focusedTokenRef, "record");
    this.addClosableSetup(
      () => this._focusState.subscribe(this.$b.handleFocusChange, {immediate: true}),
    );
  }

  onUpdate(frameUpdateContext: FrameUpdateContext): void {
    const focusedGameObject = this._focusState.getFocusedGameObject();
    this._updateFocusedTokenInterface(focusedGameObject);
  }

  handleTokenRemove(event, token) {
    this._mapObjectStore.moveToken(token.id, null, null, null);
  }

  handleTokenEdit(event, token) {
    this._editTokenRef.key = token.id;
  }

  _updateFocusedTokenInterface(focusedGameObject: GameObject): void {
    let nextTokenData = null;
    if (focusedGameObject && focusedGameObject instanceof BaseMapToken) {
      nextTokenData = this._engine.sourceRectToBrowserRect(
        focusedGameObject.getGridLocationRect()
      ).toPlainObject();
    }
    // Only update state if something has changed.
    if (!shallowEqual(this.state.focusedTokenInterfaceDetails, nextTokenData)) {
      this.setState({focusedTokenInterfaceDetails: nextTokenData});
    }
  }

  handleFocusChange() {
    const focusedGameObject = this._focusState.getFocusedGameObject();
    if (!focusedGameObject) {
      this._focusedTokenRef.key = null;
    } else if (focusedGameObject instanceof BaseMapToken) {
      this._focusedTokenRef.key = focusedGameObject.tokenId;
    } else {
      this._focusedTokenRef.key = null;
    }
  }

  render() {
    if (!this._engine) {
      return null;
    }
    const props = this.props;
    const state = this.state;
    const focusedTokenSpaceRect = state.focusedTokenInterfaceDetails;
    return ReactDOM.createPortal((
      <React.Fragment>
        {/* if */(focusedTokenSpaceRect && state.focusedToken) ? (
          <MapTokenFocusedControlElem
            mapObject={state.focusedToken}
            tokenSpaceX={focusedTokenSpaceRect.x}
            tokenSpaceY={focusedTokenSpaceRect.y}
            tokenSpaceW={focusedTokenSpaceRect.w}
            tokenSpaceH={focusedTokenSpaceRect.h}
            onRemove={this.$b.handleTokenRemove}
            onEdit={this.$b.handleTokenEdit}
          />
        )/* endif */ : null}
      </React.Fragment>
    ), this._engine.viewParent);
  }

}
