/**
 * DTO-style class for configuring an Undo step. This will be passed into
 * the undo handler to register a new undo/redo step.
 */
export default class UndoStepConfig {

  constructor(options) {

    /** @member {string} A (very) short description of what will happen when the
      *  user executes this undo step. Should be a short sentence (e.g.
      *  "Undo move token") */
    this.description = options.description || 'Undo';

    /** @member {any} any data necessary to execute the step. Since the execute
      *  and checkPrecondition functions receive a reference to the step
      *  itself, the step's `data` variable should be used to store information
      *  necessary for those to function */
    this.data = options.data || null;

    /** @member {function} A function that will be called before the step is
      *  executed to see if it is still valid. Should return one of 3 values:
      *  UNDO_STEP_INVALID (0), UNDO_STEP_ALTERED (1), or UNDO_STEP_VALID
      *  (2). These can help guide the user about how the step will occur.
      *  INVALID implies that the step cannot occur (e.g. the moved token has
      *  been deleted). ALTERED implies that the step can occur BUT something
      *  else has changed so the undo might do more than expected. VALID
      *  implies that the undo operation will be "obvious" and undo only what
      *  the user originally did. An INVALID undo will never be executed, an
      *  ALTERED undo _may_ be executed, but it is not guaranteed and a VALID
      *  undo will always be executed. */
    this.checkPrecondition = options.checkPrecondition || null;

    /** @member {async function} A function that executes the undo (or redo)
      *  operation. The function will receive this step as a parameter which
      *  can be used to access the relevant undo stack. It is up to this
      *  function to push the appropriate inverse operation onto the stack as
      *  well (e.g., when running an undo operation the execute function must
      *  push the corresponding redo) */
    this.execute = options.execute;

    if (!this.execute) {
      throw new Error('All undo steps must have an execute function');
    }
  }

}
