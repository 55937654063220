

type TweeningFunction = (
  startTimestamp: number,
  startValue: number,
  endTimestamp: number,
  endValue: number,
  curTimestamp: number,
) => number;

type DurationCalcFunction = (startValue: number, endValue: number) => number;

// Any tween duration less than a frame is isn't useful, so we will treat those
// as immediately completed.
const MIN_TWEEN_DURATION_MS = 1000 / 120;

const TWEENING_LINEAR = "TWEENING_LINEAR"
const TWEENING_SMOOTH = "TWEENING_SMOOTH"
const TWEENING_EXP_LINEAR = "TWEENING_EXP_LINEAR"


function _getTweenValueLinear(
  startTimestamp: number,
  startValue: number,
  endTimestamp: number,
  endValue: number,
  curTimestamp: number,
): number {
  const timestampRange = endTimestamp - startTimestamp;
  const valueRange = endValue - startValue;
  const progress = Math.max(0, (curTimestamp - startTimestamp) / timestampRange);
  if (progress >= 1) {
    return endValue;
  }
  return startValue + (valueRange * progress);
}


function _getTweenValueSmooth(
  startTimestamp: number,
  startValue: number,
  endTimestamp: number,
  endValue: number,
  curTimestamp: number,
): number {
  // TODO: Implement
  return _getTweenValueLinear(startTimestamp, startValue, endTimestamp, endValue, curTimestamp);
}


function _getTweenValueExpLinear(
  startTimestamp: number,
  startValue: number,
  endTimestamp: number,
  endValue: number,
  curTimestamp: number,
): number {
  const timestampRange = endTimestamp - startTimestamp;
  const valueRange = endValue / startValue;
  const progress = Math.max(0, (curTimestamp - startTimestamp) / timestampRange);
  if (progress >= 1) {
    return endValue;
  }
  const scalingValue = startValue * Math.pow(valueRange, progress);
  return scalingValue;
}


const TWEENING_FUNCTIONS = {
  [TWEENING_LINEAR]: _getTweenValueLinear,
  [TWEENING_SMOOTH]: _getTweenValueSmooth,
  [TWEENING_EXP_LINEAR]: _getTweenValueExpLinear,
}


/**
 * Easing class for a scalar value
 *
 * Tween numbers are technically frozen. There may be performance reasons to
 * unfreeze them in the future, but we'll deal with that if it comes up.
 */
class TweenNumber {

  _startTimestamp: number;
  _startValue: number;
  _endTimestamp: number;
  _endValue: number;
  _tweenType: string;
  _func: TweeningFunction;
  _done: boolean = false;  // Cached value to prevent getting `now()` all the time

  static initStatic(
    value: number,
    tweenType?: string,
  ): TweenNumber {
    return TweenNumber.init(value, value, 0, tweenType);
  }

  static init(
    startValue: number,
    endValue: number,
    durationMs: number,
    tweenType?: string,
  ): TweenNumber {
    const now = Date.now();
    return new TweenNumber(
      now,
      startValue,
      now + durationMs,
      endValue,
      tweenType,
    );
  }

  constructor(
    startTimestamp: number,
    startValue: number,
    endTimestamp: number,
    endValue: number,
    tweenType?: string,
  ) {
    this._startTimestamp = startTimestamp;
    this._startValue = startValue;
    this._endTimestamp = endTimestamp;
    this._endValue = endValue;
    this._tweenType = tweenType || TWEENING_LINEAR;
    this._func = TWEENING_FUNCTIONS[this._tweenType];
    if (!this._func) {
      throw new Error(`Unrecognized tweening type: ${tweenType}`);
    }
    // Avoid unecessary calculations for tweens that are less than a frame.
    if (this._startTimestamp + MIN_TWEEN_DURATION_MS >= this._endTimestamp) {
      this._done = true;
    }
  }

  /**
   * The current (eased) value.
   *
   * TODO: Possibly cache this for a few milliseconds after initial generation.
   */
  get current() {
    if (this.isDone) {
      return this._endValue;
    }
    return this._func(
      this._startTimestamp,
      this._startValue,
      this._endTimestamp,
      this._endValue,
      Date.now(),
    );
  }

  /**
   * The "true" (end) value. The assumption is that easing is not a
   * represenation of the actual value of something, it is a way of perceiving
   * a value. That means that the real value is not eased in any way.
   *
   * To access the "eased" value, call `curValue`.
   */
  get real(): number {
    return this._endValue
  }

  /**
   * Returns whether the tween is done.
   */
  get isDone(): boolean {
    const curTimestamp = Date.now();
    if (this._done) {
      return true;
    } else if (
      this._endTimestamp <= this._startTimestamp ||
      curTimestamp >= this._endTimestamp
    ) {
      this._done = true;
      return true;
    }
    return false
  }


  /**
   * Updates the tween value. If the value is currently tweening, this will
   * updated it in-motion - in other words, the start timestamp/value will
   * remain unchanged. However, if the tween had completed, this creates a
   * whole new tween starting from now.
   *
   * Consider not returning a new value if there is no effective change.
   */
  withValue(
    newEndValue: number,
    durationMs?: number | DurationCalcFunction,
  ): TweenNumber {
    // Restart the tween if it previously finished
    let nextStartTimestamp = this._startTimestamp;
    let nextStartValue = this._startValue;
    if (this.isDone) {
      nextStartTimestamp = Date.now();
      nextStartValue = this._endValue;
    }
    // Determine the duration and end timestamp
    let nextEndTimestamp: number;
    if (durationMs === undefined || durationMs === null) {
      nextEndTimestamp = this._endTimestamp;
    } else if (typeof(durationMs) === "number") {
      if (durationMs < MIN_TWEEN_DURATION_MS) {
        durationMs = 0;
      }
      nextEndTimestamp = nextStartTimestamp + durationMs;
    } else {
      durationMs = durationMs(nextStartValue, newEndValue);
      if (durationMs < MIN_TWEEN_DURATION_MS) {
        durationMs = 0;
      }
      nextEndTimestamp = nextStartTimestamp + durationMs;
    }
    // Generate a new one.
    return new TweenNumber(
      nextStartTimestamp,
      nextStartValue,
      nextEndTimestamp,
      newEndValue,
      this._tweenType,
    )
  }

  /**
   * Shifts both the start and end values by `valueShift`. This effectively
   * adjusts the window allowing other things to affect that frame of reference
   * without modifying the tween itself in any way.
   *
   * For example, this can be used when tweening map location in conjunction
   * with map zoom. The zoom may need to affect the value of the location
   * without _technically_ changing any aspect of it - the tween remains the
   * same, but the zoom changed the frame of reference.
   */
  withShift(valueShift: number): TweenNumber {
    return new TweenNumber(
      this._startTimestamp,
      this._startValue + valueShift,
      this._endTimestamp,
      this._endValue + valueShift,
      this._tweenType,
    );
  }

}


export default Object.freeze({
  TWEENING_LINEAR,
  TWEENING_SMOOTH,
  TWEENING_EXP_LINEAR,
  TweenNumber,
});
