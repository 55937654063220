import React from 'react';
import {SLDSTooltip} from '@salesforce/design-system-react';


interface PropsType {
  content: any,
  position?: 'absolute' | 'overflowBoundaryElement' | 'relative',
  children?: any,
}

/**
 * Generates a tooltip wrapping the child content
 */
export default function Tooltip(props: PropsType) {
  if (props.content) {
    return (
      <SLDSTooltip
        content={props.content}
        hoverCloseDelay={0}
        position={props.position || 'overflowBoundaryElement'}
      >
        {props.children}
      </SLDSTooltip>
    );
  } else {
    return props.children;
  }
}
