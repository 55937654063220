import memoBind from 'utils/memo-bound';


const EMPTY_OBJ = Object.freeze({});


const proxyHandler = Object.freeze({
  get: (target, prop, receiver) => {
    const method = target[prop];
    if (typeof method !== 'function') {
      // Some tools will try to introspect the $b object, so pretend it's just a
      // boring, old object.
      return EMPTY_OBJ[prop];
    }
    return memoBind(method, target);
  },
})


/**
 * Adds a `$b` property to the object to reference bound methods.
 *
 * This is similar to functionality exposed via `memo-bound` except that it is
 * easier to work with so long as you don't need to bind any method arguments.
 * This simply exposes a lazy-loaded collection of bound methods on the `obj`
 * as `$b`. For example, if an object has `myMethod`, you can reference
 * `myInstance.$b.myMethod` and it will be a version of the function properly
 * bound to `myInstance`.
 *
 * This is useful to avoid things like anonymous methods purely for the sake of
 * binding `this`. It's also useful when working with React (or other tools
 * that do shallow comparisons) since the bound method will return a consistent
 * instance each time (e.g. `a.$b.myMethod === a.$b.myMethod`).
 */
export default function makeBindable(obj) {
  const bindProxy = new Proxy(obj, proxyHandler);
  obj.$b = bindProxy;
  obj.$b__cache = {};
  obj.$bdestroy = () => {
    delete obj.$b;
    delete obj.$b__cache;
    delete obj.$bDestroy;
  };
}
