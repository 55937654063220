import React from 'react';

import instance from './instance';
import UndoContext from './undo-react-context';


/**
 * Provides the name of the current undo context down the react tree.
 *
 * This is a lightweight wrapper around the `UndoContext` react context. The
 * value is a simple string that can be passed as the context value to the
 * static undo handler instance. It will initialize/destroy the undo and
 * redo contexts based on the state of the tree.
 *
 * I don't know 100% if this is the right way to deal with undo/redo, but I
 * think it's good enough to start. Whether anything actually uses the `UndoContext.consumer`
 * I'm not sure. However, this will at least handle init/cleanup of the context
 * in a useful way.
 *
 * See: https://reactjs.org/docs/context.html#contextconsumer
 */
export default class UndoContextProvider extends React.Component {

  constructor(props) {
    super(props);
    instance.initContext(this._safeVal(props.context));
  }

  _safeVal(value) {
    if (value) {
      return value;
    }
    console.warn('Falling back on root value for undo context');
    return 'root';
  }

  componentDidUpdate(prevProps) {
    const prevContext = this._safeVal(prevProps.context);
    const nextContext = this._safeVal(this.props.context);
    if (prevContext !== nextContext) {
      instance.destroyContext(prevContext);
      instance.initContext(nextContext);
    }
  }

  componentWillUnmount() {
    instance.destroyContext(this._safeVal(this.props.context));
  }

  render() {
    return (
      <UndoContext.Provider value={this.props.context}>
        {this.props.children}
      </UndoContext.Provider>
    );
  }

}
