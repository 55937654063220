import instance from './instance';
import UndoContext from './undo-react-context';
import UndoContextProvider from './undo-react-context-provider';
import UndoHandler from './undo-handler';
import UndoState from './undo-fstate';
import UndoStep from './undo-step';
import UndoStepConfig from './undo-step-config';

export default Object.freeze({
  instance,
  UndoContext,
  UndoContextProvider,
  UndoHandler,
  UndoState,
  UndoStep,
  UndoStepConfig,
});
