import React from 'react';
import Hammer from 'hammerjs';

import AppGlobal from 'global';
import ReactComponent from 'utils/react-component';
import tweening from 'utils/tweening';
import undolib from 'undolib';
import UserAgent from 'utils/user-agent';
import RollingAverage from 'utils/rolling-average';

import MapView from './map-view';
import EventUtils from 'utils/events';

const TAP_DISTANCE_THRESHOLD = 3;
const LONG_PRESS_DURATION_THRESHOLD_MS = 1500;
const LOG_UNUSED_EVENTS = false;

const WHEEL_ZOOM_RATE = 0.001;
const WHEEL_PINCH_ZOOM_RATE = UserAgent.browserName === 'Chrome' ? 0.00025 : 0.004;
const WHEEL_PAN_RATE = 0.3;

// The zoom delta required before two-finger interactions will begin zooming.
// This allows for two finger panning to work more smoothly.
const PINCH_SCALE_THRESHOLD = 0.2;

// To prevent fast 2-finger pans from accidentally turning into pinches, if the
// user pans this many pixels _before_ hitting the scale threshold, it will
// block the scale threshold from ever being hit, effectively locking the user
// out of scaling for the duration of the interaction. This should feel more
// intuitive.
const PINCH_SCALE_LOCKOUT_MOVEMENT_THRESHOLD = 40;

const VELOCITY_INITIAL_STATIC_FRICTION = 0.2;
const VELOCITY_MINIMUM = 0.05;
const VELOCITY_FRICTION = 0.006;  // Velocity reduction factor per millisecond
const VELOCITY_FRICTION_FACTOR = 1 - VELOCITY_FRICTION;

const DRAG_EVENT_INTERNAL = 'DRAG_EVENT_INTERNAL';
const DRAG_EVENT_EXTERNAL = 'DRAG_EVENT_EXTERNAL';
const DRAG_EVENT_IGNORE = 'DRAG_EVENT_IGNORE';


/**
 * Generates an interaction routine that will take the previous drag velociy
 * and slowly slow it due to "friction"
 */
function _panMomentumInteractRoutine(velocityX, velocityY) {
  let lastTimestamp = Date.now();
  let velX = velocityX;
  let velY = velocityY;
  return (timestamp, controller) => {
    let vx = velX;
    let vy = velY;
    const panVelocity = Math.sqrt(vx * vx + vy * vy);
    if (panVelocity < VELOCITY_MINIMUM) {
      return true;
    }
    // Limit it no less than a millisecond to avoid weird stuff
    const dTimeMs = Math.max(1, timestamp - lastTimestamp);
    lastTimestamp = timestamp;
    controller.panView(-1 * vx * dTimeMs, -1 * vy * dTimeMs);
    const frictionFactor = Math.pow(VELOCITY_FRICTION_FACTOR, Math.round(dTimeMs));
    velX = vx * frictionFactor;
    velY = vy * frictionFactor;
  };
}


/**
 * Focuses the map on an area at a certain zoom and locatino. Note that the
 * coordinates are the CENTER of the view.
 */
function _quickZoomInteractRoutine(curZoom, targetZoom, curX, targetX, curY, targetY) {
  const zoomTween = tweening.TweenNumber.init(curZoom, targetZoom, 450, tweening.TWEENING_EXP_LINEAR);
  const xTween = tweening.TweenNumber.init(curX, targetX, 450);
  const yTween = tweening.TweenNumber.init(curY, targetY, 450);
  return (timestamp, controller) => {
    const engine = controller.engine;
    const nextZoom = zoomTween.current;
    const nextX = xTween.current - (engine.viewCanvasRect.w / 2) / nextZoom;
    const nextY = yTween.current - (engine.viewCanvasRect.h / 2) / nextZoom;
    controller.updateView(nextX, nextY, nextZoom);
    if (controller.zoom < nextZoom) {
      return true;
    }
    return zoomTween.isDone && zoomTween.isDone && yTween.isDone;
  };
}


// TODO: Switch all of the function definitions from fat-arrow to normal
// functions and update the references to bound functions instead
export default class MapViewInteractive extends ReactComponent {

  constructor(props) {
    super(props);
    this._frameHandler = AppGlobal.frameLoop;
    this._mapViewContainer = this.constantProp('mapViewContainer');
    this._controller = null;
    this._hammer = null;
    this._lastDragEvent = null;
    this._dragForceMove = false;
    this._initialPinchZoom = null;
    this._averagePinchZoom = null;
    this._lastPinchEvent = null;
    this._pinchScaleThresholdReached = false;
    this._pinchTotalMovement = 0;
    this._interactRoutine = null;
    this._interactRoutineCallbackId = null;
    this.addCleanup(this._cleanupEvents);
    this.addCleanup(this._resetInteractionRoutine);
  }

  _onControllerReady = (controller) => {
    this._controller = controller;
    if (this.props.controllerReady) {
      this.props.controllerReady(controller)
    }
    this._initNativeEvents();
    this._initHammer();
  }

  _initNativeEvents = () => {
    this._controller.root.addEventListener('wheel', this._onWheel);
    this._controller.root.addEventListener('keydown', this._handleHotKeys);
  }

  _initHammer = () => {
    this._hammer = new Hammer.Manager(this._controller.root);
    if (this.props.hammerReady) {
      this.props.hammerReady(this._hammer);
    }

    this._hammer.add(new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 0}));
    this._hammer.on('panstart', this._onPanStart);
    this._hammer.on('panmove', this._onPanMove);
    this._hammer.on('panend', this._onPanEnd);

    this._hammer.add(new Hammer.Tap());
    this._hammer.on('tap', this._onTap);

    this._hammer.add(new Hammer.Tap({event: 'doubletap', taps: 2}));
    this._hammer.on('doubletap', this._onDoubleTap);

    this._hammer.add(new Hammer.Pinch());
    this._hammer.on('pinchstart', this._onPinchStart);
    this._hammer.on('pinchin', this._onPinchChange);
    this._hammer.on('pinchout', this._onPinchChange);
    this._hammer.on('pinchend', this._onPinchEnd);
  }

  _cleanupEvents = () => {
    // this._resetPanVelocity();
  }

  /**
   * Indicates if a drag event should be emitted (return true) or handled
   * internally (return false)
   */
  _checkDragEventRouting = (hammerEvent) => {
    // If movement is being forced using modified keys, must handle internally
    if (this._dragForceMove) {
      return DRAG_EVENT_INTERNAL;
    }
    // If multiple pointers are in use, must handle internally
    if (hammerEvent.maxPointers > 1) {
      return DRAG_EVENT_INTERNAL;
    }
    // Ingore events related to "hitbox"-type elements
    if (EventUtils.isEventTargetHitbox(hammerEvent.srcEvent)) {
      return DRAG_EVENT_IGNORE;
    }
    // If drag movement is prevented, may NOT handle internally
    if (this.props.preventDragMovement) {
      return DRAG_EVENT_EXTERNAL;
    }
    return DRAG_EVENT_INTERNAL;
  }

  _onPanStart = (hammerEvent) => {
    // TODO: Might want to do the drag-vs-tap logic before calling handlers.
    hammerEvent.preventDefault();
    this._resetInteractionRoutine();
    this._dragForceMove = EventUtils.isEventForcedPan(hammerEvent.srcEvent);
    const hammerTarget = this._getEventOffset(hammerEvent);
    this._controller.setFocusPoint(hammerTarget.x, hammerTarget.y);
    const eventRoute = this._checkDragEventRouting(hammerEvent);
    if (eventRoute === DRAG_EVENT_EXTERNAL) {
      this._callOnDragBeginHandler(hammerEvent);
    } else if (eventRoute === DRAG_EVENT_INTERNAL) {
      this._lastDragEvent = hammerEvent;
    }
  }

  _onPanMove = (hammerEvent) => {
    hammerEvent.preventDefault();
    const hammerTarget = this._getEventOffset(hammerEvent);
    this._controller.setFocusPoint(hammerTarget.x, hammerTarget.y);
    const eventRoute = this._checkDragEventRouting(hammerEvent);
    if (eventRoute === DRAG_EVENT_EXTERNAL) {
      this._callOnDragMoveHandler(hammerEvent);
    } else if (eventRoute === DRAG_EVENT_INTERNAL && this._lastDragEvent) {
      let dx = hammerEvent.center.x - this._lastDragEvent.center.x;
      let dy = hammerEvent.center.y - this._lastDragEvent.center.y;
      this._controller.panView(-1 * dx, -1 * dy);
      this._lastDragEvent = hammerEvent;
    }
  }

  _onPanEnd = (hammerEvent) => {
    hammerEvent.preventDefault();
    const eventRoute = this._checkDragEventRouting(hammerEvent);
    if (eventRoute === DRAG_EVENT_EXTERNAL) {
      this._callOnDragEndHandler(hammerEvent);
    } else if (eventRoute === DRAG_EVENT_INTERNAL && hammerEvent.distance < TAP_DISTANCE_THRESHOLD) {
      if (hammerEvent.deltaTime < LONG_PRESS_DURATION_THRESHOLD_MS) {
        this._callOnTapHandler(hammerEvent);
      } else {
        this._callOnLongPressHandler(hammerEvent);
      }
    } else if (eventRoute === DRAG_EVENT_INTERNAL && this._lastDragEvent) {
      this._checkStartPanVelocity(hammerEvent);
    }
    this._lastDragEvent = null;
    this._dragForceMove = false;
    if (hammerEvent.pointerType === 'touch') {
      this._controller.setFocusPoint(null, null);
    }
  }

  _onTap = (hammerEvent) => {
    this._resetInteractionRoutine();
    this._lastDragEvent = null;
    this._lastPinchEvent = null;
    if (
      hammerEvent.maxPointers === 1 &&
      !EventUtils.isEventTargetHitbox(hammerEvent.srcEvent)
    ) {
      if (hammerEvent.tapCount === 2 && !this.props.onTap) {
        this._onDoubleTap(hammerEvent);
      }
      this._callOnTapHandler(hammerEvent);
    }
  }

  _onDoubleTap = (hammerEvent) => {
    hammerEvent.preventDefault();
    if (this._allow)
    this._resetInteractionRoutine();
    const curZoom = this._controller.curZoom;
    const curX = this._controller.curX + (this._controller.engine.viewCanvasRect.w / 2) / curZoom;
    const curY = this._controller.curY + (this._controller.engine.viewCanvasRect.h / 2) / curZoom;
    let highZoom = 1.2 * this._controller.pixelRatio;
    let lowZoom = 0.3 * this._controller.pixelRatio;
    if (AppGlobal.mapZoneDetails && AppGlobal.mapZoneDetails.grid) {
      const grid = AppGlobal.mapZoneDetails.grid;
      const avgSize = (grid.gridToPixelWidth(1) + grid.gridToPixelHeight(1)) / 2;
      highZoom = this._controller.pixelRatio * (80 / avgSize);
      lowZoom = this._controller.pixelRatio * (20 / avgSize);
    }
    const threshold = (0.6 * (highZoom - lowZoom)) + lowZoom;
    const newZoom = curZoom > threshold ? lowZoom : highZoom;
    const targetXY = this._getEventTarget(hammerEvent);
    this._startInteractRoutine(
      _quickZoomInteractRoutine(curZoom, newZoom, curX, targetXY.x, curY, targetXY.y)
    );
  }

  _onPinchStart = (hammerEvent) => {
    // Pinch/two finger pan are higher priority than other interactions
    AppGlobal.priorityEvents.registerHammerEvent(hammerEvent);
    hammerEvent.preventDefault();
    this._resetInteractionRoutine();
    this._initialPinchZoom = this._controller.engine.getZoom();
    this._averagePinchZoom = new RollingAverage(30, this._initialPinchZoom);
    this._lastPinchEvent = hammerEvent;
  }

  _onPinchChange = (hammerEvent) => {
    if (this._lastPinchEvent) {
      // Pinch/two finger pan are higher priority than other interactions
      AppGlobal.priorityEvents.registerHammerEvent(hammerEvent);
      // For some reason, the hammerEvent delta x and y values were really weird
      let dx = this._lastPinchEvent.center.x - hammerEvent.center.x;
      let dy = this._lastPinchEvent.center.y - hammerEvent.center.y;
      this._controller.panView(dx, dy);
      // Attempt to reduce the need to trigger zoom when the change would be
      // otherwise impercetible. This probably needs some tweaks since it can
      // still feel a little jarring when the user zooms just right.
      const nextZoom = this._initialPinchZoom * hammerEvent.scale;
      if (
        nextZoom / this._averagePinchZoom.value > 1.04 ||
        this._averagePinchZoom.value / nextZoom > 1.04
      ) {
        let center = this._getEventOffset(hammerEvent);
        this._controller.zoomViewAbsolute(
          nextZoom,
          center.x / this._controller.engine.viewBrowserRect.w,
          center.y / this._controller.engine.viewBrowserRect.h,
        );
      }
      this._averagePinchZoom.add(nextZoom);
      this._lastPinchEvent = hammerEvent;
    }
  }

  _onPinchEnd = (hammerEvent) => {
    // Pinch/two finger pan are higher priority than other interactions
    AppGlobal.priorityEvents.unregisterHammerEvent(hammerEvent);
    hammerEvent.preventDefault();
    this._lastPinchEvent = null;
    this._initialPinchZoom = null;
    this._averagePinchZoom = null;
    if (hammerEvent.pointerType === 'touch') {
      this._controller.setFocusPoint(null, null);
    }
    this._checkStartPanVelocity(hammerEvent);
  }

  _onWheel = (event) => {
    event.preventDefault();
    this._resetInteractionRoutine();
    if (event.metaKey || event.ctrlKey) {
      // As a standard feature, allow a trackpad user to two-finger scroll with
      // the command key held to zoom naturally. In addition, some browsers
      // intepret a pinch-to-zoom gesture on a trackpad as a wheel event with
      // the control key held
      const rate = event.ctrlKey ? WHEEL_PINCH_ZOOM_RATE : WHEEL_ZOOM_RATE;
      let dZoom = event.wheelDeltaY * rate;
      this._controller.zoomView(
        dZoom,
        event.x / event.currentTarget.clientWidth,
        event.y / event.currentTarget.clientHeight,
      );
    } else {
      this._controller.panView(
        -1 * event.wheelDeltaX * WHEEL_PAN_RATE,
        -1 * event.wheelDeltaY * WHEEL_PAN_RATE,
      );
    }
  }

  _handleHotKeys = (event) => {
    if (
      (UserAgent.uiStyleMac && event.metaKey && event.shiftKey && event.code === 'KeyZ') ||
      (UserAgent.uiStyleWindows && event.ctrl && event.shiftKey && event.code === 'KeyZ')
    ) {
      if (this.props.undoContext) {
        event.preventDefault();
        undolib.instance.executeRedo(this.props.undoContext);
      }
    } else if (
      (UserAgent.uiStyleMac && event.metaKey && event.code === 'KeyZ') ||
      (UserAgent.uiStyleWindows && event.ctrl && event.code === 'KeyZ')
    ) {
      if (this.props.undoContext) {
        event.preventDefault();
        undolib.instance.executeUndo(this.props.undoContext);
      }
    }
  }

  _checkStartPanVelocity = (hammerEvent) => {
    const vx = hammerEvent.velocityX;
    const vy = hammerEvent.velocityY;
    const panVelocity = Math.sqrt(vx * vx + vy * vy);
    if (panVelocity >= VELOCITY_INITIAL_STATIC_FRICTION) {
      this._startInteractRoutine(
        _panMomentumInteractRoutine(
          hammerEvent.velocityX,
          hammerEvent.velocityY,
        ),
      );
    }
  }

  _startInteractRoutine = (routine) => {
    this._resetInteractionRoutine();
    this._interactRoutine = routine;
    this._interactRoutineCallbackId = this._frameHandler.onFrame(this._runInteractionRoutine);
  }

  _resetInteractionRoutine = () => {
    if (this._interactRoutineCallbackId) {
      this._frameHandler.removeCallback(this._interactRoutineCallbackId);
    }
    this._interactRoutineCallbackId = null;
    this._interactRoutine = null;
  }

  _runInteractionRoutine = (timestamp) => {
    if (!this._interactRoutine && this._interactRoutineCallbackId) {
      this._frameHandler.removeCallback(this._interactRoutineCallbackId);
      return;
    }
    const result = this._interactRoutine(timestamp, this._controller);
    if (result) {
      this._resetInteractionRoutine();
    }
  }

  _onViewUpdate = (viewInfo) => {
    this._callOnViewUpdateHandler(viewInfo);
  }

  _callOnTapHandler = (hammerEvent) => {
    if (this.props.onTap) {
      try {
        this.props.onTap(hammerEvent, this._getEventTarget(hammerEvent));
      } catch (err) {
        console.warn('mapview.map-view-interactive: Uncaught error in `onTap`', err);
      }
    } else if (LOG_UNUSED_EVENTS) {
      console.log('mapview/map-view-interactive: Tap event: ', hammerEvent, this._getEventTarget(hammerEvent));
    }
  }

  _callOnDoubleTapHandler = (hammerEvent) => {
    if (this.props.onDoubleTap) {
      try {
        this.props.onDoubleTap(hammerEvent, this._getEventTarget(hammerEvent));
      } catch (err) {
        console.warn('mapview.map-view-interactive: Uncaught error in `onDoubleTap`', err);
      }
    } else if (LOG_UNUSED_EVENTS) {
      console.log('mapview/map-view-interactive: Tap event: ', hammerEvent, this._getEventTarget(hammerEvent));
    }
  }

  _callOnLongPressHandler = (hammerEvent) => {
    if (this.props.onLongPress) {
      try {
        this.props.onLongPress(hammerEvent, this._getEventTarget(hammerEvent));
      } catch (err) {
        console.warn('mapview.map-view-interactive: Uncaught error in `onLongPress`', err);
      }
    } else if (LOG_UNUSED_EVENTS) {
      console.log('mapview/map-view-interactive: Long press event: ', hammerEvent, this._getEventTarget(hammerEvent));
    }
  }

  _callOnDragBeginHandler = (hammerEvent) => {
    if (this.props.onDragBegin) {
      try {
        this.props.onDragBegin(hammerEvent, this._getEventTarget(hammerEvent));
      } catch (err) {
        console.warn('mapview.map-view-interactive: Uncaught error in `onDragBegin`', err);
      }
    } else if (LOG_UNUSED_EVENTS) {
      console.log('mapview/map-view-interactive: Drag begin event: ', hammerEvent, this._getEventTarget(hammerEvent));
    }
  }

  _callOnDragMoveHandler = (hammerEvent) => {
    if (this.props.onDragMove) {
      try {
        this.props.onDragMove(hammerEvent, this._getEventTarget(hammerEvent));
      } catch (err) {
        console.warn('mapview.map-view-interactive: Uncaught error in `onDragMove`', err);
      }
    } else if (LOG_UNUSED_EVENTS) {
      console.log('mapview/map-view-interactive: Drag move event: ', hammerEvent, this._getEventTarget(hammerEvent));
    }
  }

  _callOnDragEndHandler = (hammerEvent) => {
    if (this.props.onDragEnd) {
      try {
        this.props.onDragEnd(hammerEvent, this._getEventTarget(hammerEvent));
      } catch (err) {
        console.warn('mapview.map-view-interactive: Uncaught error in `onDragEnd`', err);
      }
    } else if (LOG_UNUSED_EVENTS) {
      console.log('mapview/map-view-interactive: Drag end event: ', hammerEvent, this._getEventTarget(hammerEvent));
    }
  }

  /**
   * Given a hammer event, returns an object containing the x and y values that
   * would be equivalent of a native event's offsetX and offsetY properties
   */
  _getEventOffset = (hammerEvent) => {
    // Hammer event center coordinates are always (?) relative to window but
    // this is meant to return them relative to the element.
    //
    // TODO: The "relative to window" thing might have been because I recently
    // moved some elements to be absoltely positioned instead of relative...
    const targetRect = hammerEvent.target.getBoundingClientRect();
    return {
      x: hammerEvent.center.x - targetRect.left,
      y: hammerEvent.center.y - targetRect.top,
    }
  }

  /**
   * Returns target information from the hammer event. For example, this will
   * return the map coordinates for a given event on the view.
   */
  _getEventTarget = (hammerEvent) => {
    const offset = this._getEventOffset(hammerEvent);
    let [mapX, mapY] = this._controller.viewToMapCoordinates(offset.x, offset.y);
    return Object.freeze({
      x: mapX,
      y: mapY,
    });
  }

  render = () => {
    return (
      <MapView
        controllerReady={this._onControllerReady}
        style={this.props.style}
        mapViewContainer={this._mapViewContainer}
      />
    );
  }

}
