import MapZoneMaskRef from 'apps/maps/state/map-zone-mask-ref';


export default class MapZoneOverrideVisibleMaskRef extends MapZoneMaskRef {

  getMaskConfig(mapZone) {
    if (mapZone && mapZone.visibility) {
      return mapZone.visibility.overrideVisibleMask;
    }
    return null;
  }

}
