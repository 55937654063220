import makeBindable from 'utils/bound-methods';


export default class CanvasLayer {

  protected _rootDocument: Document;
  protected _name: string;
  protected _contextType: string;
  protected _contextOptions: CanvasRenderingContext2DSettings;
  protected _canvas: HTMLCanvasElement;
  protected _isDestroyed: boolean = false;

  public $b: any;
  private $bdestroy: any;

  constructor({
    rootDocument = window.document,
    name,
    width,
    height,
    contextType = '2d',
    contextOptions = {alpha: true},
  }) {
    makeBindable(this);
    this._rootDocument = rootDocument;
    this._name = name;
    this._contextType = contextType;
    this._contextOptions = contextOptions;
    this._canvas = this._rootDocument.createElement('canvas');
    this.resize(width, height);
    this._isDestroyed = false;
  }

  get name(): string {
    return this._name;
  }

  get canvas(): HTMLCanvasElement {
    console.warn('.canvas is deprecated, use `getCanvas` instead');
    return this.getCanvas();
  }

  destroy(): void {
    this._canvas = null;
    this.$bdestroy();
  }

  throwIfDestroyed(): void {
    if (this._isDestroyed) {
      throw new Error('Object has been destroyed');
    }
  }

  refreshCanvas(width: number, height: number) {
    this._canvas = this._rootDocument.createElement('canvas');
    this.resize(width, height);
  }

  resize(width: number, height: number): void {
    this._canvas.width = width;
    this._canvas.height = height;
  }

  getCanvas(): HTMLCanvasElement {
    return this._canvas;
  }

  getContext(): CanvasRenderingContext2D {
    const context = (
      this._canvas.getContext(this._contextType, this._contextOptions) as CanvasRenderingContext2D
    );
    context.imageSmoothingEnabled = false;  // TODO: manage this in options?
    return context;
  }

}
