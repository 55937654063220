import FStateWithAsync from 'statelib/fstate-with-async';
import QueuedAsyncState from 'statelib/queued-async-state';
import shared from 'global';
import {makeRpcCall} from './async';
import {users as usersPb, common as commonPb} from 'proto-bundle';

const checkSessionRpc = makeRpcCall(
  '/rpc/users/v1/check_session',
  commonPb.EmptyRequest,
  usersPb.Session,
);


// For legacy compatibility, all functions need to be defined with fat-arrow
// syntax instead of normal method syntax. This ends up being a wash since the
// instance is singleton anyway.
class UserInfo extends FStateWithAsync {

  static DEFAULT_NAME = 'UserInfo';

  constructor(options) {
    super(options);
    this._sessionInfo = null;
    this._userInfo = null;
    this._error = null;
  }

  makeInitialData() {
    return {
      emailAddress: null,
      id: null,
      name: null,
      session: {},
      username: null,
      userType: null,
    };
  }

  async load() {
    if (this._async.isRunning) {
      console.log('user-info: User Info currently loading');
    } else if (this._async.isFailed) {
      console.log('user-info: User Info failed to load', this._async.error);
    } else {
      await this._async.wrap(async (throwIfCanceled) => {
        const nextSession = await this._async.wrap(async () => {
          return await checkSessionRpc({});
        });
        throwIfCanceled();
        this.patchData({
          ...nextSession.user || {id: null},
          session: nextSession,
        });
      });
    }
  }

  reset() {
    this.setData(this.makeInitialData());
  }

  onUserInfo(callback) {
    // Very old-style listener for changes to userinfo.
    return this.subscribe(callback, {
      condition: data => !data.async.isRunning,
      immediate: true,
    });
  }

}


// Export a singleton instance of user info
const singletonUserInfo = new UserInfo('UserInfo');
shared.provide('UserInfo', singletonUserInfo);
export default singletonUserInfo;
