import debounce from 'lodash/debounce';
import Memoize from 'memoize-one';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import {Search} from '@salesforce/design-system-react';

import formcmp from 'components/form';
import AppReactComponent from 'utils/app-react-component';
import layoutscmp from 'components/layouts';


const _IMAGE_SORT_STYLE = Object.freeze({
  width: '5rem',
  marginLeft: '0.25rem',
});



/**
 * Simple component for filtering/sorting an output.
 *
 * A couple of notes:
 *
 * 1. `onChange` is a callback that will be passed the change event as well as
 *    an object containing `filterText` and `sortValue`. If there is a debounce
 *    configured, the event will always be `null`.
 */
export default class FilterAndSort extends AppReactComponent {

  static propTypes = {
    compact: PropTypes.bool,
    debounceMs: PropTypes.number,
    disabled: PropTypes.bool,
    hasSpinner: PropTypes.bool,
    initialEntityValue: PropTypes.string,
    initialFilterText: PropTypes.string,
    initialSortValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholderFilterText: PropTypes.string,
    sortOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      })
    ),
    entityOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      })
    ),
  };

  static defaultProps = {
    debounceMs: 180,
  };

  constructor(props) {
    super(props);
    this.state = {
      curFilterText: this.props.initialFilterText,
      curSortValue: this.props.initialSortValue,
      curEntityValue: this.props.initialEntityValue,
    }
    this._refreshDebouncedOnChange();
  }

  _refreshDebouncedOnChange() {
    if (!this.props.onChange) {
      this._onChange = () => null;
    } else if (!this.props.debounceMs) {
      this._onChange = this.props.onChange;
    } else {
      this._onChange = debounce(this.props.onChange, this.props.debounceMs);
    }
  }

  _handleChangeFilterText(event, data) {
    this.setState({curFilterText: data.value}, () => this._pushChange(event));
  }

  _handleClearFilterText(event) {
    this._handleChangeFilterText(event, {value: ''});
  }

  _handleChangeSortValue(event, sortId) {
    this.setState({curSortValue: sortId}, () => this._pushChange(event));
  }

  _handleChangeEntityValue(event, entityId) {
    this.setState({curEntityValue: entityId}, () => this._pushChange(event));
  }

  _pushChange(event) {
    this._onChange(event, {
      filterText: this.state.curFilterText,
      sortValue: this.state.curSortValue,
      entityValue: this.state.curEntityValue,
    });
  }

  _flush() {
    this._onChange.flush();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.debounceMs !== this.props.debounceMs ||
      prevProps.onChange !== this.props.onChange
    ) {
      this._refreshDebouncedOnChange();
    }
    if (
      prevProps.initialFilterText !== this.props.initialFilterText &&
      this.state.curFilterText !== this.props.initialFilterText
    ) {
      this.setState({curFilterText: this.props.initialFilterText})
    }
    if (
      prevProps.initialSortValue !== this.props.initialSortValue &&
      this.state.curSortValue !== this.props.initialSortValue
    ) {
      this.setState({curSortValue: this.props.initialSortValue})
    }
    if (
      prevProps.initialEntityValue !== this.props.initialEntityValue &&
      this.state.curEntityValue !== this.props.initialEntityValue
    ) {
      this.setState({curEntityValue: this.props.initialEntityValue})
    }
  }

  render() {
    const props = this.props;
    const state = this.state;
    if (props.compact) {
      return (
        <div className={classNames('flex--container-vertical', props.className)} >
          <div className='flex--container-horizontal flex--item-fixed layout--margin-bottom-xsmall'>
            {this._renderSearch(props, state)}
          </div>
          <div className='flex--container-horizontal flex--item-fixed'>
            {this._renderEntityPicklist(props, state)}
            {this._renderSortPicklist(props, state)}
          </div>
        </div>
      );
    }
    return (
      <div
        className={classNames('flex--container-horizontal', props.className)}
      >
        {this._renderEntityPicklist(props, state)}
        {this._renderSearch(props, state)}
        {this._renderSortPicklist(props, state)}
      </div>
    );
  }

  _renderSearch(props, state) {
    return (
      <Search
        className={classNames(props.classNameFilter, 'flex--item-fill')}
        clearable={true}
        disabled={props.disabled}
        value={state.curFilterText || ''}
        placeholder={props.placeholderFilterText}
        hasSpinner={props.hasSpinner}
        onChange={this.$b._handleChangeFilterText}
        onClear={this.$b._handleClearFilterText}
        onSearch={this.$b._flush}
      />
    );
  }

  _renderEntityPicklist(props, state) {
    if (props.entityOptions && props.entityOptions.length > 0) {
      return (
        <formcmp.SinglePicklist
          options={this._convertSortOptions(props.entityOptions)}
          onChange={this.$b._handleChangeEntityValue}
          label='Type'
          value={state.curEntityValue}
          className={
            classNames({
              'flex--item-fixed': !props.compact,
              'flex--item-fill': props.compact,
              'layout--margin-right-small': true,
              'layout--size-narrow': !props.compact,
            }, props.classNameEntity)
          }
        />
      );
    }
    return null;
  }

  _renderSortPicklist(props, state) {
    if (props.sortOptions && props.sortOptions.length > 0) {
      return (
        <formcmp.SinglePicklist
          options={this._convertSortOptions(props.sortOptions)}
          onChange={this.$b._handleChangeSortValue}
          label='Sort By'
          value={state.curSortValue}
          className={
            classNames({
              'flex--item-fixed': !props.compact,
              'flex--item-fill': props.compact,
              'layout--margin-left-small': true,
              'layout--size-narrow': !props.compact,
            }, props.classNameSorting)
          }
        />
      );
    }
    return null;
  }

  _convertSortOptions = Memoize(inputOptions => {
    if (!inputOptions || inputOptions.length < 1) {
      return [];
    }
    return inputOptions.map(item => {
      return {
        id: item.value,
        label: item.label,
      }
    });
  })

}
