import FState from './fstate';
import QueuedAsyncState from './queued-async-state';


export default class FStateWithAsync extends FState {

  static DEFAULT_ASYNC_CLASS = QueuedAsyncState;

  constructor(options) {
    options = options || {};
    const name = options.name || null;  // `super(name)` will use default name
    const async = options.async || null;
    super(name);
    if (async) {
      this._async = async;
      this.attachState('async', this._async, true);
    } else {
      const cls = this.constructor.DEFAULT_ASYNC_CLASS;
      this._async = new cls(this.name + '_Async');
      this.attachState('async', this._async, false);
    }
  }

  get asyncState() {
    return this._async;
  }

  get async() {
    return this.getValue('asunc')
  }

}
