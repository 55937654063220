

export default class RollingAverage {

  _curIdx: number = -1;
  _size: number;
  _values: number[] = [];
  _value: number = NaN;

  constructor(size: number, initialValue?: number) {
    this._size = size;
    this._values = [];
    if (initialValue !== undefined) {
      this.add(initialValue);
    }
  }

  get value(): number {
    return this._value;
  }

  add(value: number) {
    if (isNaN(value)) {
      throw new Error("Rolling average doesn't support NaN");
    }
    if (this._values.length < this._size) {
      this._values.push(value);
      this._curIdx = this._values.length - 1;
    } else {
      const nextIdx = (this._curIdx + 1) % this._size;
      this._values[nextIdx] = value;
      this._curIdx = nextIdx;
    }
    // TODO: There is a more efficient way to do this involving subtracting a
    // fraction of the removed number and adding a fraction of the new number,
    // but I'll deal with that later.
    this._value = this._values.reduce(
      (curSum, value) => curSum + value, 0
    ) / this._values.length;
  }

}
