import React from 'react';

import OrionLayout from 'components/layouts/orion-layout';

import MapZoneConfigLayerBounds from './map-zone-config-layer-bounds';
import MapZoneConfigLayerGrid from './map-zone-config-layer-grid';

import MultiAsyncState from 'statelib/multi-async-state';
import ReactComponent from 'utils/react-component';
import shared from 'global';
import {connectToComponentState} from 'statelib/fstate-react';


function MapZoneConfigView({
  async,
  configLayer,
  onSaveGridConfiguration,
  onSaveLayerBounds,
  goBackToMapDetails,
}) {
  return (
    <React.Fragment>
      {/* if */(!async.isComplete) ? (
        <div>Loading...</div>
      ) : null}
      {/* if */(async.isFailed) ? (
        <div>{`${async.error}`}</div>
      ) : null}
      {/* if */(async.isSuccess) ? (
        <OrionLayout
          style={{height:'100%', width:'100%'}}
        >
          {/* if */(configLayer === 'grid') ? (
            <MapZoneConfigLayerGrid
              onSave={onSaveGridConfiguration}
              onCancel={goBackToMapDetails}
            />
          ) : null}
          {/* if */(configLayer === 'bounds') ? (
            <MapZoneConfigLayerBounds
              onSave={onSaveLayerBounds}
              onCancel={goBackToMapDetails}
            />
          ) : null}
        </OrionLayout>
      ) : null}
    </React.Fragment>
  );
}


export default class MapZoneConfig extends ReactComponent {

  constructor() {
    super();
    this.router = shared.rootRouter;
    this.mapZoneRef = shared.mapZoneDetails;
    this.async = new MultiAsyncState([
      shared.mapDetails.getAttachedState('async'),
      shared.mapZoneDetails.getAttachedState('async'),
    ]);
    this.addCleanup(
      this.async.$b.close,
      connectToComponentState(this.router, this, 'router'),
      connectToComponentState(this.async, this, 'async'),
    );
  }

  async goBackToMapDetails() {
    const mapId = this.state.router.params.mapId;
    this.router.history.push(`/app/maps/${mapId}/details`);
  }

  async handleSaveGridConfiguration(configData) {
    this.mapZoneRef.raiseIfNotReady();
    const mapId = this.state.router.params.mapId;
    const mapZoneId = this.state.router.params.zoneId;
    try {
      //await this.saveAsync.wrap(async (throwIfCanceled) => {
      await this.mapZoneRef.patchGridConfiguration(configData.gridConfig, true);
      this.router.history.push(
        `/app/maps/${mapId}/zones/${mapZoneId}/configure/bounds`
      );
        // TODO: shared.mapZoneRef.refresh? I don't think we need it because
        // of the way that patchGridConfiguration works, but it is worth
        // checking.
      //});
    } catch (err) {
      console.error('Unable to save grid config', err);
    }
  }

  async handleSaveLayerBounds(protoBoundaries) {
    this.mapZoneRef.raiseIfNotReady();
    const mapId = this.state.router.params.mapId;
    try {
      //await this.saveAsync.wrap(async (throwIfCanceled) => {
      await this.mapZoneRef.patchMapZoneBoundaries(protoBoundaries, true);
      this.router.history.push(`/app/maps/${mapId}/details`);
        // TODO: shared.mapZoneRef.refresh? I don't think we need it because
        // of the way that patchGridConfiguration works, but it is worth
        // checking.
      //});
    } catch (err) {
      console.error('Unable to save grid config', err);
    }
  }

  render() {
    return MapZoneConfigView({
      async: this.state.async,
      configLayer: this.state.router.params.configLayer,
      onSaveGridConfiguration: this.$b.handleSaveGridConfiguration,
      onSaveLayerBounds: this.$b.handleSaveLayerBounds,
      goBackToMapDetails: this.$b.goBackToMapDetails,
    });
  }
}
