import React from 'react';

import DialogBox from 'components/layouts/dialog-box';

import AppGlobal from 'global';
import AppReactComponent from 'utils/app-react-component';
import EmbarkMapZoneSelect from 'apps/maps/components/embark-map-zone-select';


/**
 * Dialog version of the map/zone selector. This is useful for displaying to
 * users if no map or zone is currently selected to prompt the user to choose
 * one.
 */
export default class MapZoneSelectDialog extends AppReactComponent {

  constructor(props) {
    super(props);
    this.connect('campaignDetails', AppGlobal.campaignDetails);
    this.connect('mapDetails', AppGlobal.mapDetails);
    this.connect('mapZoneDetails', AppGlobal.mapZoneDetails);
  }

  render() {
    const props = this.props;
    const state = this.state;
    return (
      <DialogBox
        className='dialog--priority'
        variant='nonblocking'
        rendered={props.rendered}
        overflowing={true}
      >
        <p>Please select the map that you would like to view</p>
        <EmbarkMapZoneSelect
          availableMaps={state.campaignDetails.maps}
          availableMapZones={state.mapDetails.zones}
          campaignId={state.campaignDetails.campaignId}
          mapId={state.mapDetails.mapId}
          mapZoneId={state.mapZoneDetails.mapZoneId}
          navigation={AppGlobal.navigation}
        />
      </DialogBox>
    );
  }

}
