import Squares from 'utils/squares';
import AppGlobal from 'global';

const _IDENTITY_MATRIX = new DOMMatrixReadOnly([1, 0, 0, 1, 0, 0]);


/**
 * Utility functions for drawing and manipulating canvas contexts
 */
export default class CanvasUtils {

  static IDENTITY_MATRIX = _IDENTITY_MATRIX;

  static get pixelRatio() {
    return AppGlobal.settings.value('graphics.pixelRatio') || window.devicePixelRatio || 1;
  }

  static resetTransform(context, pixelRatio) {
    pixelRatio = pixelRatio || CanvasUtils.pixelRatio;
    context.setTransform(_IDENTITY_MATRIX);
  }

  /**
   * Translates by a half pixel, useful for drawing 1-pixel lines.
   */
  static translateHalfPixel(context) {
    return context.translate(0.5, 0.5);
  }

  /**
   * Clears the area within `path`, a Path2D object. Requires a bounding box
   * be specified since there is no way to know the overall size of the path.
   */
  static clearPath(context, boundingBox, path, fillRule) {
    context.save();
    try {
      context.clip(path, fillRule);
      context.clearRect(boundingBox.x, boundingBox.y, boundingBox.w, boundingBox.h)
    } finally {
      context.restore();
    }
  }

  /**
   * Like `clearRect` but takes a `Squares` object
   */
  static clearSquare(context, rectSq) {
    return context.clearRect(
      rectSq.x, rectSq.y, rectSq.w, rectSq.h
    );
  }

  /**
   * Like `fillRect` but takes a `Squares` object
   */
  static fillSquare(context, rectSq) {
    return context.fillRect(
      rectSq.x, rectSq.y, rectSq.w, rectSq.h
    );
  }

  /**
   * Like `strokeRect` but takes a `Squares` object
   */
  static strokeSquare(context, rectSq) {
    return context.strokeRect(
      rectSq.x, rectSq.y, rectSq.w, rectSq.h
    );
  }

  static setBlurFilter(context, blurPx) {
    blurPx = Math.max(1, Math.round(blurPx * CanvasUtils.pixelRatio))
    context.filter = `blur(${blurPx}px)`;
  }

  static drawImageRect(context, image, srcRect, destRect) {
    context.drawImage(
      image,
      srcRect.x,
      srcRect.y,
      srcRect.w,
      srcRect.h,
      destRect.x,
      destRect.y,
      destRect.w,
      destRect.h,
    );
  }

  /**
   * Draws a portion of `image` onto `context`. Safely ensures that nothing
   * tries to pull fomr outside of the source image (defined by `imageRect`)
   * since that can cause some issues in certain browsers.
   */
  static safeDrawImageRect(context, image, imageRect, srcRect, destRect) {
    const contstrained = Squares.constrain({
      container: imageRect,
      srcSquare: srcRect,
      dstSquare: destRect,
    });
    CanvasUtils.drawImageRect(
      context,
      image,
      Squares.round(contstrained.srcSquare),
      Squares.round(contstrained.dstSquare),
    );
  }

}
