import React from 'react';
import Memoize from 'memoize-one';
import classNames from 'classnames';

import {Dropdown} from '@salesforce/design-system-react';
import Spacer from 'components/layouts/spacer';

import ReactComponent from 'utils/react-component';
import {extractRecordById} from 'utils/collections';


function MapZoneSelectView({
  handleMapSelect,
  handleMapZoneSelect,
  mapItems,
  mapZoneItems,
  selectedMap,
  selectedMapZone,
}) {
  return (
    <React.Fragment>
      {/* if */(!mapItems) ? (
        <span>There are no maps yet</span>
      )/* else */: (
        <React.Fragment>
          <Dropdown
            buttonVariant='base'
            buttonClassName='sdlc-custom--button-short'
            align='center'
            iconCategory='utility'
            iconName='down'
            iconPosition='right'
            iconSize='small'
            label={selectedMap ? selectedMap.name : 'Select a Location'}
            onSelect={handleMapSelect}
            options={mapItems}
          />
          {/* if */(mapZoneItems) ? (
            <React.Fragment>
              <Spacer />
              <Dropdown
                buttonVariant='base'
                buttonClassName='sdlc-custom--button-short'
                align='center'
                iconCategory='utility'
                iconName='down'
                iconPosition='right'
                iconSize='small'
                label={selectedMapZone ? selectedMapZone.name : 'Select an Area'}
                onSelect={handleMapZoneSelect}
                options={mapZoneItems}
              />
            </React.Fragment>
          )/* endif */: null}
        </React.Fragment>
      )/* endif */}
    </React.Fragment>
  );
}


export default class MapZoneSelect extends ReactComponent {

  handleMapSelect(item) {
    if (item.value === 'edit-map') {
      this.props.navigation.replaceHash("#map_edit")
    } else {
      this.props.navigation.embarkMap(this.props.campaignId, item.value);
    }
  }

  handleMapZoneSelect(item) {
    if (item.value === 'edit-map-zone') {
      this.props.navigation.replaceHash("#map_zone_edit")
    } else {
      this.props.navigation.embarkMapZone(
        this.props.campaignId, this.props.mapId, item.value
      );
    }
  }

  _getMapItemDisplayName(map) {
    const parts = [];
    parts.push(map.name);
    if (map.publicName && map.name.localeCompare(map.publicName) !== 0) {
      parts.push(` (${map.publicName})`);
    }
    if (!map.public) {
      parts.push(' - hidden');
    }
    return parts.join('');
  }

  _getMapZoneItemDisplayName(mapZone) {
    const parts = [];
    parts.push(mapZone.name);
    if (mapZone.publicName && mapZone.name.localeCompare(mapZone.publicName) !== 0) {
      parts.push(` (${mapZone.publicName})`);
    }
    if (!mapZone.public) {
      parts.push(' - hidden');
    }
    return parts.join('');
  }

  render() {
    const props = this.props;
    const mapItems = this.getMapItems(
      props.navigation, props.campaignId, props.availableMaps, props.mapId
    );
    const mapZoneItems = this.getMapZoneItems(
      props.navigation, props.campaignId, props.mapId, props.availableMapZones, props.mapZoneId
    );
    return MapZoneSelectView({
      handleMapSelect: this.$b.handleMapSelect,
      handleMapZoneSelect: this.$b.handleMapZoneSelect,
      mapItems: mapItems,
      mapZoneItems: mapZoneItems,
      selectedMap: extractRecordById(props.mapId, props.availableMaps),
      selectedMapZone: extractRecordById(props.mapZoneId, props.availableMapZones),
    });
  }

  getMapItems = Memoize((navigation, campaignId, maps, mapId) => {
    if (!maps || maps.length < 1) {
      return null;
    }
    const editItems = [];
    if (mapId && true) { // TODO: Make sure user can edit
      editItems.push({
        label: `Edit`,
        value: `edit-map`,
        href: '#map_edit',
        leftIcon: {
          name: 'settings',
          category: 'utility'
        },
      });
      editItems.push({
        type: 'divider',
      })
    }
    const mapSelectItems = maps.map(map => ({
      label: this._getMapItemDisplayName(map),
      value: map.id,
      href: navigation.getEmbarkUrl(campaignId, map.id),
      disabled: map.id === mapId,
      className: classNames({
        'slds-custom--dropdown-item-selected': map.id === mapId,
        'slds-custom--dropdown-item-subtle': !map.public,
      }),
      leftIcon: (map.id !== mapId) ? null : {
        name: 'right',
        category: 'utility'
      },
    }));
    return [...editItems, ...mapSelectItems];
  })

  getMapZoneItems = Memoize((navigation, campaignId, mapId, mapZones, mapZoneId) => {
    if (!mapZones || mapZones.length < 1) {
      return null;
    }
    const editItems = [];
    if (mapZoneId && true) { // TODO: Make sure user can edit
      editItems.push({
        label: `Edit`,
        value: `edit-map-zone`,
        href: '#map_zone_edit',
        leftIcon: {
          name: 'settings',
          category: 'utility'
        },
      });
      editItems.push({
        type: 'divider',
      })
    }
    const mapZoneSelectItems = mapZones.map(mapZone => ({
      label: this._getMapZoneItemDisplayName(mapZone),
      value: mapZone.id,
      href: navigation.getEmbarkUrl(campaignId, mapId, mapZone.id),
      disabled: mapZone.id === mapZoneId,
      className: classNames({
        'slds-custom--dropdown-item-selected': mapZone.id === mapZoneId,
        'slds-custom--dropdown-item-subtle': !mapZone.public,
      }),
      leftIcon: (mapZone.id !== mapZoneId) ? null : {
        name: 'right',
        category: 'utility'
      },
    }));
    return [...editItems, ...mapZoneSelectItems];
  })

}

