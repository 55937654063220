import memoBind from 'utils/memo-bound';

import FState from './fstate';

const EMPTY = Object.freeze({});


/**
 * State object for handling router information.
 *
 * This doesn't publish ALL router information, just the useful stuff
 * (i.e. just the match params and url).
 *
 * Anything that REALLY needs something more granular can listen to history
 * directly, although I don't suspect it will be required.
 */
export default class RouterState extends FState {

  constructor() {
    super('RouterState');
    this.history = null;
    this.location = null;
    this._match = null;
    this._queryString = null;
    this.subscribe((data) => {
      console.info('router: New page detected', data);
    })
  }

  get match() {
    if (!this._match) {
      throw new Error('Match not yet initialized');
    }
    return this._match;
  }

  get url() {
    return this.getValue('url');
  }

  get origin() {
    return this.getValue('origin');
  }

  get params() {
    return this.getValue('params');
  }

  get query() {
    return this.getValue('query');
  }

  get hash() {
    return this.getValue('hash');
  }

  makeInitialData() {
    return {
      url: '',
      origin: window.location.origin,  // Will never change
      params: EMPTY,
      query: {},
      hash: null,
    };
  }

  getParam(paramName) {
    if (!this.data.params || this.data.params[paramName] === undefined) {
      throw new Error(`No parameter named "${paramName}" defined`);
    }
    return this.data.params[paramName];
  }

  updateLocationQuery() {
    // Updates an object with the current query string parameters. Note that
    // this uses a simple object to store them (even though that isn't 100%
    // accurate). If anything needs access to the more detailed thing, they
    // can do it themselves.
    //
    // TODO: use `qs` library?
    let nextQueryString = this.location ? this.location.search : null;
    if (!nextQueryString) {
      nextQueryString = null;
    }
    if (this._queryString !== nextQueryString) {
      this._queryString = nextQueryString;
      const parsed = new URLSearchParams(nextQueryString);
      const nextQuery = {};
      for (const [key, value] of parsed.entries()) {
        nextQuery[key] = value;
      }
      this.setValue('query', nextQuery);
    }
  }

  updateLocationHash() {
    const prevHash = this.hash;
    let nextHash = this.location ? this.location.hash : null;
    if (nextHash && nextHash[0] === '#') {
      nextHash = nextHash.substring(1);
    }
    if (!nextHash) {
      nextHash = null;
    }
    if (nextHash !== prevHash) {
      this.setValue('hash', nextHash);
    }
  }

  updateMatch(match) {
    this._match = (match) ? {...match} : null;
    const nextParams = (match) ? match.params : EMPTY;
    const nextUrl = (match) ? match.url : '';
    if (this._params !== nextParams || this._url !== nextUrl) {
      this.patchData({
        url: nextUrl,
        params: nextParams,
      });
    }
    // TODO: Does this need to be somewhere else? We'll have to see if it
    // updates every time the URL changes or only sometimes
    this.updateLocationQuery();
    this.updateLocationHash();
  }

}
