import statelib from 'statelib';

// TODO: I think we need to add some guarantee to AppGlobal that the references
// to these objects will NOT change. For example, if I have a component that
// relies on referencing the singleton `rootRouter` and then something sets a
// _new_ singleton root router, the component is going to be all messed up. If
// we can add some guarantee that once one of these is set it WILL NOT CHANGE
// it would help simplify some code.


class ProviderProxyHandler {

  static reservedProps = ['provide', 'provideFactory'];

  get(target, prop) {
    if (ProviderProxyHandler.reservedProps.includes(prop)) {
      return this[prop].bind(this, target);
    }
    let stateValue = target.getRealValue(prop);
    if (stateValue === null && target[`${prop}__factory`]) {
      // If the global has not been created yet, make one
      this._refreshPropFromFactory(target, prop);
      stateValue = target.getRealValue(prop);
    } else if (stateValue === null) {
      throw new Error(`Global value ${prop} has not been provided`);
    }
    return stateValue;
  }

  set(target, prop, value) {
    throw new Error('Global values cannot be set, use `provide` instead');
  }

  deleteProperty(target, prop) {
    throw new Error('Global values cannot be deleted, use `remove` instead');
  }

  provide(target, prop, value) {
    if (ProviderProxyHandler.reservedProps.includes(prop)) {
      throw new Error(`Cannot overwrite '${prop}'`);
    }
    let stateValue = target.getRealValue(prop);
    if (stateValue !== null) {
      throw new Error(`Global value ${prop} has already been provided`);
    }
    if (value.onData) {
      target.attachState(prop, value);
    } else {
      target.setValue(prop, value);
    }
  }

  /**
   * Providing a factory is somewhat easier than providing a value since it can
   * be called multiple times. So long as it is called with the same factory
   * function, it is a no-op.
   *
   * The underlying property will be eager-loaded if it is not currently
   * defined. This improves stack traces if there are any errors running the
   * factory.
   */
  provideFactory(target, prop, value) {
    const factoryProp = `${prop}__factory`;
    if (ProviderProxyHandler.reservedProps.includes(prop)) {
      throw new Error(`Cannot overwrite '${prop}'`);
    } else if (target[factoryProp] === value) {
      // Do nothing since this is a no-op
      return;
    } else if (target[factoryProp] !== null) {
      console.warn(`Factory '${prop}' has been provided and will be overwritten`, target[factoryProp]);
    }
    target[factoryProp] = value;
    if (target[prop] === null) {
      this._refreshPropFromFactory(target, prop);
    }
  }

  _refreshPropFromFactory(target, prop) {
    const factoryProp = `${prop}__factory`;
    if (target[factoryProp] === null) {
      throw new Error(`No factory defined for '${prop}'`);
    }
    const value = target[factoryProp]();
    if (value.onData) {
      target.attachState(prop, value);
    } else {
      target.setValue(prop, value);
    }
  }

  /**
   * Allows code to remove something it had provided. This should primarily be
   * used in the cleanup functions of page-level components that provided a
   * global specifically for that page so that it can be removed for the next
   * page to run.
   *
   * Only the code that provided a value should remove it. There's nothing
   * explicitly preventing other code from doing so, but it could put the
   * system in a weird state.
   *
   * There are some dangers with using remove and I might get rid of it
   * eventually. Namely, when switching pages, the new page is constructed
   * before the old page is unmounted, meaning that it is removed after the
   * other component has attempted to provide it, leaving things in a weird
   * state. Really, I think we need some type of request/release system that
   * acts more like a reference counting scheme. Whenever something requests to
   * hold a factory, it's reference count increases. It can then release a
   * factory dropping the count. If the count hits 0, delete the object and
   * factory.
   *
   * For now, it's not worth it. The worst thing that could happen is a bunch of
   * data sits in the global state for too long consuming a couple of MBs of
   * heap.
   */
  // remove(target, prop) {
  //   if (reservedProps.includes(prop)) {
  //     throw new Error(`Cannot overwrite '${prop}'`);
  //   } else if (target[prop] === undefined) {
  //     throw new Error(`Global value ${prop} has not been provided`);
  //   }
  //   delete target[prop];
  // }

}


// Any singleton components or data can be registered here.
const appGlobalRaw = new statelib.FState('global');
window.AppGlobal__object = appGlobalRaw;
const appGlobalProxyHandler = new ProviderProxyHandler();
window.AppGlobal__proxyhandler = appGlobalProxyHandler;
const appGlobal = new Proxy(appGlobalRaw, appGlobalProxyHandler);
export default appGlobal;
