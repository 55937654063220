import CanvasUtils from 'utils/graphics/canvas-utils';
import MapObject from 'components/mapview/map-object';
import Squares from 'utils/squares';


export default class InterfaceGrid extends MapObject {

  constructor(grid, initialColor) {
    super();
    this._grid = grid;
    this._dirty = true;
    this.color = initialColor || 'rgba(0, 0, 0, 0.8)';
    this.glowColor = 'rgba(220, 220, 220, 0.3)';
    this.lineWidth = 1;
  }

  get grid() {
    return this._grid;
  }

  set grid(value) {
    if (value !== this._grid) {
      this._grid = value;
      this._dirty = true;
    }
  }

  onUpdate(timestamp, timeDeltaMs, frameno, render, layer) {
    if (this._dirty || render.isKeyframe) {
      layer.setFullRedraw();
      this._dirty = false;
    }
  }

  onRender(context, layer, render) {
    const grid = this._grid;
    if (!grid) {
      return;
    }
    const getRenderX = layer.getRenderX.bind(layer);
    const getRenderY = layer.getRenderY.bind(layer);
    // Gather all of the information needed to render the grid
    const lineWidthPixels = Math.max(1, this.lineWidth * render.pixelRatio);
    const gridSq = Squares.fromDimensions(
      grid.gridToPixelX(0),
      grid.gridToPixelY(0),
      grid.gridToPixelWidth(1),
      grid.gridToPixelHeight(1),
    );
    const sourceSq = render.getRenderBounds();
    const colStart = Math.floor((sourceSq.x - gridSq.x) / gridSq.w);
    const rowStart = Math.floor((sourceSq.y - gridSq.y) / gridSq.h);
    const colLimit = colStart + Math.floor(sourceSq.w / gridSq.w) + 1;
    const rowLimit = rowStart + Math.floor(sourceSq.h / gridSq.h) + 1;
    // Prepare the context
    CanvasUtils.resetTransform(context);
    if (lineWidthPixels % 2 === 1) {
      CanvasUtils.translateHalfPixel(context);
    }
    // Draw all of the grid lines
    context.beginPath();
    for (let col = colStart; col <= colLimit; col++) {
      context.moveTo(
        getRenderX(gridSq.x + (col * gridSq.w)),
        getRenderY(sourceSq.y),
      );
      context.lineTo(
        getRenderX(gridSq.x + (col * gridSq.w)),
        getRenderY(sourceSq.y2),
      );
    }
    for (let row = rowStart; row <= rowLimit; row++) {
      context.moveTo(
        getRenderX(sourceSq.x),
        getRenderY(gridSq.y + (row * gridSq.h)),
      );
      context.lineTo(
        getRenderX(sourceSq.x2),
        getRenderY(gridSq.y + (row * gridSq.h)),
      );
    }
    if (this.glowColor) {
      context.lineWidth = lineWidthPixels + (2 * render.pixelRatio);
      context.strokeStyle = this.glowColor;
      context.stroke();
    }
    context.lineWidth = lineWidthPixels;
    context.strokeStyle = this.color;
    context.stroke();
  }

}
