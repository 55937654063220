import React from 'react';

import formcmp from 'components/form';

const _CATEGORY_OPTIONS = [
  {
    id: 'map',
    label: 'Map',
    disabled: false,
  },
  {
    id: 'token',
    label: 'Token',
    disabled: false,
  },
  {
    id: 'portrait',
    label: 'Portrait',
    disabled: false,
  },
  {
    id: 'artifact',
    label: 'Artifact',
    disabled: false,
  },
];


/**
 * docstring
 */
export default function ImageManagerCategorySelect({
  className,
  disabled,
  label,
  onChange,
  required,
  value,
}) {
  return (
    <formcmp.SinglePicklist
      className={className}
      disabled={disabled}
      label={label}
      onChange={onChange}
      options={_CATEGORY_OPTIONS}
      required={required}
      value={value}
    />
  );
}
