import FStateWithAsync from 'statelib/fstate-with-async';


// (This is the "Good" map view container since it's used in the embark flow
// which is the more modern of map view stuff)

export default class MapViewRef extends FStateWithAsync {

  static DEFAULT_NAME = 'MapViewRef';
  static factory = () => new MapViewRef();

  constructor(options) {
    super(options);
    this._element = null;
    this._async.start();
  }

  destroy() {
    this._mapView = null;
    this._element = null;
    super.destroy();
  }

  set element(value) {
    this._element = value;
  }

  get element() {
    return this._element;
  }

  set controller(value) {
    const nextController = value;
    nextController.onImageLoaded = this.$b.handleNewImage;
    this.setValue('controller', nextController);
    this._async.success();
  }

  get controller() {
    return this.data.controller;
  }

  makeInitialData() {
    return {
      controller: null,
      image: null,
    };
  }

  async setImageAsync(imageUrl, imageWidth, imageHeight) {
    await this._async.wrap(async (throwIfCanceled) => {
      await this.data.controller.setImage(imageUrl, imageWidth, imageHeight);
    });
  }

  handleNewImage(image) {
    this.setValue('image', image);
  }

}
