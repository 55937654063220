import statelib from 'statelib';

import type FState from 'statelib/fstate';


/**
 * An extension of RecordStoreState that is scoped to a specific campaign. If
 * the campaign changes, all data will be cleared out and reset.
 *
 * Since most things in the app are organized by campaign, and most API calls
 * require a campaign ID, this helps keep things organized.
 */
export default class CampaignScopedRecordStoreState<RecordType> extends statelib.RecordStoreState<RecordType>{

  private _campaignId: string;
  private _campaignRef: FState;
  private _campaignRefUnsubscribe?: () => void;


  constructor(options) {
    super(options);
    this._campaignId = null;
    this._campaignRef = options.campaignRef || null;
    this._campaignRefUnsubscribe = null;
    if (this._campaignRef) {
      this._campaignRefUnsubscribe = this._campaignRef.subscribe(this.$b._handleCampaignChange);
    }
  }

  destroy() {
    if (this._campaignRefUnsubscribe) {
      this._campaignRefUnsubscribe();
      this._campaignRefUnsubscribe = null;
    }
    this._campaignRef = null;
    this._campaignId = null;
    super.destroy();
  }

  get campaignId(): string {
    return this._campaignId;
  }

  set campaignId(campaignId: string) {
    if (this._campaignRef) {
      throw new Error('The campaign ID scope is managed by an external campaign reference');
    }
    this._setCampaignId(campaignId);
  }

  private _setCampaignId(campaignId: string) {
    campaignId = campaignId || null;
    if (campaignId === this._campaignId) {
      return;
    }
    this.resetAll();
    this._campaignId = campaignId;
  }

  protected _handleCampaignChange(data) {
    if (this._campaignId !== data.campaignId) {
      this._setCampaignId(data.campaignId);
    }
  }

}
