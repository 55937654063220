import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import layouts from 'components/layouts';
import AppReactComponent from 'utils/app-react-component';

const _FILE_INPUT_STYLE = Object.freeze({
  appearance: 'none',
  cursor: 'pointer',
  opacity: '0',
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: '0px',
  top: '0px',
  fontSize: '0px',
});


/**
 * A file select (input) region. When a file is selected, `onChange` will be
 * called with the event and selected files. `onController` will be called when
 * the component is initialized and will call back with some functions to
 * control the inner state.
 *
 * `Children` can be used to change the content displayed in the box. If there
 * is no child content, default content will be displayed instead.
 */
export default class FileSelectRegion extends AppReactComponent {

  static propTypes = {
    accept: PropTypes.string,  // e.g. 'image/png, image/jpeg'
    children: PropTypes.node,  // Optional inner content
    className: PropTypes.any,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onController: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      files: null,
    }
    this._inputElement = null;
    this.addSetup(this.$b._notifyController);
  }

  _setFileInput(elem) {
    this._inputElement = elem;
  }

  _notifyController() {
    if (this.props.onController) {
      this.props.onController({
        reset: this.$b.reset,
      });
    }
  }

  _notifyChange(event, files) {
    if (this.props.onChange) {
      this.props.onChange(event, files);
    }
  }

  _handleChangeFileInput(event) {
    const files = event.target.files;
    if (files && files.length) {
      this.setState({files: files});
      this._notifyChange(event, files);
    } else {
      this.setState({files: null});
      this._notifyChange(event, null);
    }
  }

  reset() {
    this._inputElement.value = null;
    this.setState({files: null});
    this._notifyChange(null, null);  // No event is ready
  }

  render() {
    const props = this.props;
    const state = this.state;
    let innerContent;
    if (React.Children.count(props.children) > 0) {
      innerContent = props.children;
    } else {
      innerContent = (
        <layouts.CenteredBox>
          {/* if */(state.files) ? (
            <span>{state.files[0].name}</span>
          )/* else */ : (
            <span>Drag and Drop or Click to select a file</span>
          )/* endif */}
        </layouts.CenteredBox>
      );
    }
    return (
      <div className={classNames('layout--positioned-box', props.className)}>
        {innerContent}
        {/* The `input` is rendered last so that it sits above other content */}
        <input
          ref={this.$b._setFileInput}
          type='file'
          name={props.name}
          disabled={props.disabled}
          accept={props.accept}
          onChange={this.$b._handleChangeFileInput}
          style={_FILE_INPUT_STYLE}
        />
      </div>
    );
  }

}
