import Memoize from 'memoize-one';

import FStateListener from 'statelib/fstate-listener';
import FStateWithAsync from 'statelib/fstate-with-async';
import BaseAsyncState from 'statelib/base-async-state';
import MapsRPCAPI from 'apis/maps-rpc-api';

import {common as commonPb} from 'proto-bundle';

const UNKNOWN_ROLE = commonPb.Role[commonPb.Role.UNKNOWN_ROLE];
const OWNER_ROLE = commonPb.Role[commonPb.Role.OWNER];
const GM_ROLE = commonPb.Role[commonPb.Role.GM];
const PLAYER_ROLE = commonPb.Role[commonPb.Role.PLAYER];
const TRUSTED_PLAYER_ROLE = commonPb.Role[commonPb.Role.TRUSTED_PLAYER];


/**
 *
 * listContainer can be used to limit the scope. Only a campaign whose ID shows
 * up within the list will be allowed to load. This will not begin loading
 * unless the associated listContainer is successful.
 */
export default class CampaignDetailsStateRef extends FStateWithAsync {

  static factory = () => new CampaignDetailsStateRef();

  constructor(options) {
    super(options);
    this._allowedIds = null;
    this._mapListener = new FStateListener(this.$b._onMapData);
  }

  get async() {
    return this.getValue('async');
  }

  set campaignId(value) {
    value = value || null;
    if (value !== this.data.campaignId) {
      if (value === null) {
        console.log(`campaign-details[${this.name}] clear campaign`);
        this.resetData();
      } else if (typeof value !== 'string') {
        throw new Error(`Expected id to be a string, got ${value}`);
      } else {
        this.patchData({
          ...this.makeInitialData(),
          campaignId: value,
        });
        this.refresh();
      }
    }
  }

  get campaignId() {
    return this.getValue('campaignId');
  }

  get campaign() {
    return this.getValue('campaign');
  }

  get maps() {
    return this.getValue('maps');
  }

  destroy() {
    this._allowedIds = null;
    this._mapListener.destroy();
    this._mapListener = null;
    super.destroy();
  }

  makeInitialData() {
    return {
      campaign: null,
      campaignId: null,
      maps: null,
      members: null,
    };
  }

  listenToMapState(mapRef) {
    this._mapListener.listen(mapRef, 'map');
  }

  resetAllButId() {
    this.patchData({
      campaign: null,
      maps: null,
      members: null,
    });
  }

  setAllowedIds(ids) {
    const curId = this.data.campaignId;
    const prevIds = this._allowedIds;
    const prevIncluded = !!(prevIds === null || prevIds.includes(curId));
    const nextIds = ids ? [...ids] : null;
    const nextIncluded = !!(nextIds === null || nextIds.includes(curId));
    this._allowedIds = nextIds;
    if (prevIncluded !== nextIncluded) {
      this.refresh();
    }
    return nextIncluded;
  }

  async refresh(reset) {
    const campaignId = this.data.campaignId;
    try {
      console.log(`campaign-details[${this.name}] campaign map ${campaignId}`);
      await this._async.wrap(async (throwIfCanceled) => {
        // Exit early if the campaign id is not allowed
        if (!(this._allowedIds === null || this._allowedIds.includes(campaignId))) {
          this.resetAllButId();
          return;
        } else if (reset) {
          this.resetAllButId();
        }
        // Get the campaign details
        const campaignResult = await MapsRPCAPI.getCampaignById(
          {campaignId: campaignId});
        throwIfCanceled();
        // Get the campaign members
        const membersResult = await MapsRPCAPI.getCampaignMembers(
          {campaignId: campaignId});
        throwIfCanceled();
        // Update the internal data
        this.patchData({
          campaign: campaignResult,
          maps: campaignResult.maps || [],
          members: membersResult.campaignMembers,
        });
        console.log(`campaign-details[${this.name}] loaded campaign`, this._campaign);
        console.log(`campaign-details[${this.name}] loaded campaign members`, this.data.members);
      });
    } catch (err) {
      if (!BaseAsyncState.isCanceled(err)) {
        console.warn(`Unable to load campaign ${campaignId}`, err);
        // Do not re-raise, rely on error stored in async state.
      }
    }
  }

  async patchCampaign(campaignData, commit) {
    const prevCampaign = this.data.campaign;
    const nextCampaign = {...prevCampaign, ...campaignData};
    this.setValue('campaign', nextCampaign);
    if (commit) {
      await this._async.wrap(async (throwIfCanceled) => {
        const result = await MapsRPCAPI.putCampaign(nextCampaign);
        throwIfCanceled();
        this.setValue('campaign', {...nextCampaign, ...result});
      });
    }
  }

  async addCampaignMemberPlayer(campaignMember, commit) {
    const campaignId = this.data.campaignId;
    const prevMembers = this.data.members || [];
    let nextMembers = null;
    if (commit) {
      await this._async.wrap(async (throwIfCanceled) => {
        const response = await MapsRPCAPI.addCampaignMember({
          campaignId: campaignId,
          playerName: campaignMember.name,
          playerEmail: campaignMember.email,
          role: campaignMember.role || PLAYER_ROLE,
        });
        throwIfCanceled();
        console.log('Added new player to campaign', campaignMember.name);
        nextMembers = [...prevMembers, response.campaignMember];
      });
    } else {
      nextMembers = [...prevMembers, campaignMember];
    }
    // TODO: Might need to fix campaign roles
    this.setValue('members', nextMembers);
  }

  async removeCampaignMembers(userIds, commit) {
    const campaignId = this.data.campaignId;
    const prevMembers = this.data.members || [];
    const nextMembers = prevMembers.filter((member) => {
      return !userIds.includes(member.userId);
    });
    if (commit) {
      // TODO: This should be a bulk endpoint eventually, but this is good
      // enough for now.
      await this._async.wrap(async (throwIfCanceled) => {
        for (const userId of userIds) {
          await MapsRPCAPI.removeCampaignMember({
            campaignId: campaignId,
            userId: userId,
          });
          throwIfCanceled();
          console.log('Removed member from campaign', userId);
        }
      });
    }
    // TODO: Might need to fix campaign roles
    this.setValue('members', nextMembers);
  }

  /**
   * Updates the list of maps associated with a campaign. This can add or remove
   * maps.
   */
  async updateCampaignMaps({addMaps, removeMaps}, commit) {
    addMaps = addMaps || [];
    removeMaps = removeMaps || [];
    const campaignId = this.data.campaignId;
    const prevMaps = this.campaign.maps || [];
    let nextMaps;
    if (commit) {
      await this._async.wrap(async (throwIfCanceled) => {
        const modifyMapsResponse = await MapsRPCAPI.modifyCampaignMaps({
          campaignId: campaignId,
          addMapIds: addMaps.map(map => map.id),
          removeMapIds: removeMaps.map(map => map.id),
        });
        throwIfCanceled();
        // Since modifyCampaignMaps doesn't return anything, get the latest list
        // of maps from the server.
        const campaignResult = await MapsRPCAPI.getCampaignById(
          {campaignId: campaignId});
        nextMaps = campaignResult.maps || [];
      });
    } else {
      nextMaps = prevMaps.filter(map => !removeMaps.includes(map.id));
      for (const addMap of addMaps) {
        nextMaps.push(addMap);
      }
    }
    this.patchData({
      campaign: this.data.campaign ? {...this.data.campaign, maps: nextMaps} : null,
      maps: nextMaps,
    })
  }

  /**
   * Internally updates the metadata about a map
   */
  async patchMapById(mapId, mapData) {
    if (!this.maps) {
      return;
    }
    const prevMaps = this.maps;
    const nextMaps = [];
    let isChanged = false;
    for (const map of prevMaps) {
      if (map.id === mapId) {
        const nextMap = {...map};
        if (mapData.name !== undefined) {
          nextMap.name = mapData.name;
        }
        if (mapData.publicName !== undefined) {
          nextMap.name = mapData.name;
        }
        if (mapData.public !== undefined) {
          nextMap.public = mapData.public;
        }
        nextMaps.push(nextMap);
        isChanged = true;
      } else {
        nextMaps.push(map);
      }
    }
    if (isChanged) {
      this.setValue('maps', nextMaps);
    }
  }

  /**
   * Called when an authoritative map zone state is updated
   */
  _onMapData(mapData){
    if (mapData && mapData.id) {
      this.patchMapById(mapData.id, mapData);
    }
  }

  getPermissions(asPlayer) {
    return this._getPermissionsForCampaign(this.campaign, asPlayer);
  }

  /**
   * Caching function for generating a permissions object for the map.
   *
   * Note that it is campaign role that affects whether a user is allowed to
   * impersonate a user (as well as some other permissions)
   */
  _getPermissionsForCampaign = Memoize((campaign, asPlayer) => {
    const trueRole = (campaign && campaign.role) ? campaign.role : UNKNOWN_ROLE;
    let role;
    if (!campaign) {
      role = UNKNOWN_ROLE;
    } else if (asPlayer) {
      role = TRUSTED_PLAYER_ROLE;
    } else {
      role = trueRole;
    }
    return {
      CAN_IMPERSONATE_PLAYERS: [OWNER_ROLE, GM_ROLE].includes(trueRole),
    };
  });

}
