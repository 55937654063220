import {pathParse, deepGet} from 'utils/obj-paths';

const _ROOT_PATH = pathParse('').path;


/**
 * Connects data from an FState object (`sourceState`) to the state of a React
 * component (`destComponent`). Data from the source state at path `sourcePath`
 * will be mapped to the component state property named `destName`.
 *
 * The value `init`, which defaults to `true`, should be true if the component's
 * state needs to be initialized (by directly setting it). This is typically
 * done during component construction.
 *
 * This does not allow setting anything below the top-level object - for more
 * complicated mappings have the component simply.
 *
 * As a convenience, you can pass the arguments as `(sourceState, destComponent,
 * destName)`. In which case, it assumes that the entire source state will be
 * attached at the destination and that init is required.
 *
 * This will handle both the `addSetup` and `addCleanup` aspect of the
 * subscriber. There is no need to do anything other than call this function.
 * This differs from historical functionality where it was necessary to cleanup
 * the result of this function.
 */
export function connectToComponentState(sourceState, destComponent, sourcePath, destName, init) {
  // Handle special 3-argument convenience overload.
  if (arguments.length === 3) {
    sourceState = arguments[0];
    destComponent = arguments[1];
    sourcePath = _ROOT_PATH;
    destName = arguments[2];
    init = undefined;
  }
  // Validate the inputs
  if (!sourceState) {
    throw new Error('Argument `sourceState` is required');
  } else if (!destComponent) {
    throw new Error('Argument `destComponent` is required');
  } else if (!destName) {
    throw new Error('Argument `destName` is required');
  }
  // Set up the subscribers to attach the state to the component.
  const needsInit = (init !== undefined) ? !!init : true;
  const finalState = sourceState;
  const finalComponent = destComponent;
  const key = destName;
  const options = {path: sourcePath, immediate: true};
  if (needsInit) {
    finalComponent.state = finalComponent.state || {};
    finalComponent.state[key] = finalState.getValue(sourcePath);
  }
  finalComponent.addClosableSetup(() => finalState.subscribe(
    (newData) => {
      finalComponent.setState({[key]: newData});
    },
    options,
  ));
  // For backwards compatibility, return a no-op
  return () => {
    console.warn('Attempted to close the result of `connectToComponentState`', finalComponent);
  };
}


/**
 * Like `connectToComponentState` except that this creates a single subscriber
 * that will update multiple fields.
   *
   * Note that the destination map (`destMap`) is relative to the output data
   * NOT the root of the state. Destination map keys are the component state
   * keys and values are the data paths.
 */
export function connectToComponentStateBulk(sourceState, destComponent, sourcePath, destMap, init) {
  let needsInit = (init !== undefined) ? !!init : true;
  const finalState = sourceState;
  const finalComponent = destComponent;
  const keyPathTuples = Object.entries(destMap);
  const options = {
    immediate: true,
    path: sourcePath,
  };
  const unsub = finalState.subscribe(
    (newData) => {
      const values = {};
      for (const [key, path] of keyPathTuples) {
        values[key] = deepGet(newData, path);
      }
      if (needsInit) {
        finalComponent.state = finalComponent.state || {};
        Object.assign(finalComponent.state, values);
      } else {
        finalComponent.setState(values);
      }
    },
    options,
  );
  needsInit = false;
  return unsub;
}
