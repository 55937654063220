import { FormText } from 'react-bootstrap';
import statelib from 'statelib';
import {connectToComponentState} from 'statelib/fstate-react';

type DataToFormType<DataT, FormT> = (data: DataT) => FormT;
type SubmitCallbackType<DataT, ThisT> = (data: DataT, form: ThisT) => void;

const DEBUG = true;


/**
 * NOTE: When writing classes that implement forms, keep in mind that you
 * probably will want to use arrow-notation to declare any functions that will
 * be passed as callbacks to things like react components (that way `this` is
 * handled appropriately)
 *
 * TODO: Need to do some work on this in regards to immutability and only
 * creating new objects when there are changes. This is important since react
 * will use the `===` to determine if things are different.
 *
 * There is a potential short form" way of dealing with `FormHandlers` where
 * we allow `refreshForm` implementations to be passed as into the constructor.
 * From there, components can contain a vanilla FormHandler (rather than a
 * derivative class) and call the various set/update/publish fields directly.
 */
export default class FormHandler<DataT = any, FormT = any> extends statelib.FState {

  static DEFAULT_NAME = 'Form';

  _onSubmit: SubmitCallbackType<DataT, this>;
  _injectedDataToForm: DataToFormType<DataT, FormT>;

  constructor(dataToForm?: string | DataToFormType<DataT, FormT>, name?: string) {
    if (typeof dataToForm === 'string') {
      name = dataToForm as string;
      dataToForm = undefined;
    }
    super(name);
    this._onSubmit = null;
    this._injectedDataToForm = (dataToForm as DataToFormType<DataT, FormT>) || null;
    this.refreshForm({});
  }

  get formFields() {
    return this.getValue('form');
  }

  get formData() {
    return this.getValue('data');
  }

  get formView() {
    return this.getValue('view');
  }

  get formErrors() {
    return this.getValue('errors');
  }

  get hasErrors() {
    for (const [key, value] of Object.entries(this.getValue('errors'))) {
      return true;
    }
    return false;
  }

  makeInitialData() {
    return {
      form: {},
      data: {},
      errors: {},
      view: {},
      hasError: false,
    };
  }

  destroy() {
    this._onSubmit = null;
    super.destroy();
  }

  /**
   * Completely sets the form's data.
   *
   * This should be used to initialize the data or overwrite it if new data is
   * available from a better source.
   */
  initializeData(newData, partial?: boolean) {
    newData = newData || {};
    let nextData;
    if (partial) {
      const prevData = this.formData || {};
      nextData = {...prevData, ...newData};
    } else {
      nextData = {...newData};
    }
    this.setValue('data', nextData);
    this.refreshForm(this.formData);
  }

  /**
   * Virtual function to refresh form field values.
   *
   * This will update (via something like `setValue`) the `form` object in the
   * state with the `data` provided.
   *
   * Forms must EITHER override this function OR specify a data-to-field mapping
   * function in the form constructor.
   */
  refreshForm(data) {
    if (this._injectedDataToForm) {
      this.updateFormFields(this._injectedDataToForm(data));
    } else {
      throw new Error('FormHandler classes must either implement `refreshForm` or pass a `dataToForm` function');
    }
  }

  /**
   * Gets the "real" data of the form.
   *
   * This is a legacy function and should be deprecated in favor of directly
   * accessing `formData`
   */
  getData() {
    return this.formData;
  }

  /**
   * Sets the form data for a single field.
   *
   * Equivalent to `updateFormData` with a single key/value. This will update
   * the underlying for data and refresh the form fields to match.
   */
  setFormDataField(key, value) {
    this.updateFormData({[key]: value});
  }

  // DEPRECATED - use `setFormDataField` instead
  setDataField(key, value) {
    console.debug('Calling deprecated `setDataField`');
    return this.setFormDataField(key, value);
  }

  /**
   * Updates multiple fields in the data object.
   *
   * This will update the underlying data that powers the form. If the `newData`
   * does not actually contain anything new, it will NOT trigger an update to
   * the state.
   */
  updateFormData(newData) {
    const prevData = this.formData || {};
    const nextData = {...prevData};
    let isChanged = false;
    for (const [key, value] of Object.entries(newData)) {
      if (value === undefined) {
        if (nextData[key] !== undefined) {
          delete nextData[key];
          isChanged = true;
        }
      } else {
        if (nextData[key] !== value) {
          nextData[key] = value;
          isChanged = true;
        }
      }
    }
    if (isChanged) {
      this.setValue('data', nextData);
      // TODO: Do we actually want to refresh the form?
      this.refreshForm(nextData);
    }
  }

  // DEPRECATED - use `updateFormData` instead
  updateDataFields(fields) {
    console.debug('Calling deprecated `updateDataFields`');
    return this.updateFormData(fields);
  }

  /**
   * Sets a single form field value.
   *
   * See `setDataField`
   */
  setFormField(key, value) {
    if (DEBUG && this.formFields[key] === undefined) {
      throw new Error(`WARNING: Setting unknown field ${key}. There is ` +
        `either a typo or a field was not initialized. Available fields ` +
        `are [${Object.keys(this.formFields)}]`);
    }
    this.updateFormFields({[key]: value});
  }

  /**
   * Updates multiple form fields in the data object.
   *
   * See `setDataFields`
   */
  updateFormFields(newFields) {
    const prevFields = this.formFields || {};
    const nextFields = {...prevFields};
    let isChanged = false;
    for (const [key, value] of Object.entries(newFields)) {
      if (value === undefined) {
        if (nextFields[key] !== undefined) {
          delete nextFields[key];
          isChanged = true;
        }
      } else {
        if (nextFields[key] !== value) {
          nextFields[key] = value;
          isChanged = true;
        }
      }
    }
    if (isChanged) {
      const nextFormView = this._getNextFormView(nextFields, this.formErrors);
      this.patchData({
        form: nextFields,
        view: nextFormView,
      });
    }
  }

  /**
   * Convenience function for setting both the form and data field value.
   *
   * This will set the form field followed by the data field. The data value can
   * be one of 3 things:
   *
   * - `undefined`: The data value will be set to the form value
   * - A function: The function will be called, passing the form value, and the
   *   result will be stored in the data field. If there is an exception, the
   *   data field will remain unchanged and the error will be added to the form
   *   field.
   * - Anything else: The data field will be set to the value.
   */
  setFormFieldAndData(key, formValue, dataValueOrTransform) {
    this.setFormField(key, formValue);
    if (dataValueOrTransform === undefined) {
      this.setFormDataField(key, formValue);
    } else if (typeof dataValueOrTransform === 'function') {
      try {
        this.setFormDataField(key, dataValueOrTransform(formValue));
        this.clearFormFieldError(key);
      } catch (err) {
        if (DEBUG) console.error(`form-handler: Field update failed ${key}`, err);
        this.setFormFieldError(key, `${err}`);
      }
    } else {
      // `dataValueOrTransform` is a plain form data value
      this.setFormDataField(key, dataValueOrTransform);
    }
  }

  // DEPRECATED - use `setFormFieldAndData` instead
  setFormAndDataField(key, formValue, dataValueOrTransform) {
    console.debug('Calling deprecated `setFormAndDataField`');
    return this.setFormFieldAndData(key, formValue, dataValueOrTransform);
  }

  /**
   * Sets an error.
   *
   * Use the key '$all' for the top-level form error. Note that this will not
   * make any changes nor publish if the error is unchanged (even if the form
   * value HAS changed)
   */
  setFormFieldError(key, error) {
    if (!error) {
      throw new Error('Must have an error value, use clearFormFieldError instead');
    } else if (error === this.formErrors[key]) {
      return;
    }
    const prevErrors = this.formErrors || {};
    const nextErrors = {...prevErrors};
    if (nextErrors[key] !== error) {
      nextErrors[key] = error;
      const nextFormView = this._getNextFormView(this.formFields, nextErrors);
      this.patchData({
        errors: nextErrors,
        view: nextFormView,
      });
    }
  }

  /**
   * Clears out the error stored in a form field.
   *
   * You may use the key `"$any"` to clear the top-level form error.
   */
  clearFormFieldError(key) {
    if (this.formErrors && this.formErrors[key] !== undefined) {
      const prevErrors = this.formErrors;
      const nextErrors = {...prevErrors};
      delete nextErrors[key];
      const nextFormView = this._getNextFormView(this.formFields, nextErrors);
      this.patchData({
        errors: nextErrors,
        view: nextFormView,
      });
    }
  }

  /**
   * Clear some (or all) form field errors.
   *
   * TODO: Add an optimization for all. It's pretty silly to loop through
   * errors when you know you want to get rid of all of them.
   */
  clearFormFieldErrors(keys) {
    const prevErrors = this.formErrors || {};
    const nextErrors = {...prevErrors};
    let isChanged = false;
    for (const key of Object.keys(prevErrors)) {
      if (!keys || keys.contains(key)) {
        delete nextErrors[key];
        isChanged = true;
      }
    }
    if (isChanged) {
      const nextFormView = this._getNextFormView(this.formFields, nextErrors);
      this.patchData({
        errors: nextErrors,
        view: nextFormView,
      });
    }
  }

  /**
   * Simple last-check data validator. This is meant to validate the form DATA
   * (rather than field values), typically done as a last check before clearing
   * the form for submission. In general, it's best to do these via data
   * transforms that can raise validation errors in real time.
   *
   * Typical usage is something like
   *
   * ```
   * handleSubmit(data, form) {
   *   form.simpleValidateTruthy('name');
   *   form.simpleValidateTruthy('category');
   *   if (!form.hasErrors) {
   *     if (this.props.onSubmit) {
   *       this.props.onSubmit(data);
   *     }
   *   }
   * }
   * ```
   */
  validateFieldData<ValueT>(
    fieldName: string,
    isValid: (value: ValueT) => boolean,
    invalidMessage: string
  ): void {
    let value: ValueT = fieldName === '$all' ? this.formData : this.formData[fieldName];
    if (value === undefined) {
      value = null;
    }
    try {
      if (!isValid(value)) {
        this.setFormFieldError(fieldName, invalidMessage);
      }
    } catch (err) {
      console.error('Validation function failed', err);
      this.setFormFieldError(fieldName, `Could not validate: ${err}`);
    }
  }

  validateFieldDataTruthy(fieldName) {
    this.validateFieldData<any>(fieldName, value => !!value, 'Please enter a value');
  }

  /**
   * Calls the `onSubmit` callback with the current data.
   *
   * In general, this should NOT be called with a data value so that it can
   * pick up the internal value instead. (I honestly don't even know what the
   * point of that was).
   */
  submit(data?) {
    if (!this._onSubmit) {
      return;
    }
    if (data === undefined) {
      data = this.formData;
    } else {
      console.warn('FormHandler submit called with data. This is deprecated.');
    }
    this._onSubmit(data, this);
  }

  // DEPRECATED - use `submit` instead
  publishFormSubmit(submissionType, data) {
    return this.submit(data);
  }

  /**
   * Subscribe to form submission.
   *
   * Whenever the form is submitted, `callback` will be called with two
   * arguments: The first is the form data. THe second argument is a reference
   * to the form itself which can be used for things like setting errors.
   *
   * Note that only ONE callback can be subscribed to form submission. A warning
   * will be raised if a new subscriber overwrites an old one.
   */
  onSubmit(callback) {
    if (this._onSubmit) {
      console.warn('An `onSubmit` handler has already been defined');
    }
    this._onSubmit = callback;
    // Since this is a single-callback handler, an unsubscribe function isn't
    // entirely necessary, but we'll add one for parity.
    return () => {
      if (this._onSubmit === callback) {
        this._onSubmit = null;
      }
    }
  }

  /**
   * Generates the next `view` object based on the form fields and errors.
   *
   * This will typically be called after either the next form fields or next
   * errors have been created but BEFORE they are stored into state. Once the
   * view fields have also been generated, the combination can be stored into
   * the state in a single pass.
   *
   * Unlike some other functions, this one is NOT smart enough to know if it
   * actually _needs_ to perform the update. It is up to the caller to decide
   * if there are likely any changes.
   */
  _getNextFormView(nextFormFields, nextErrors) {
    const prevFormView: {[key: string]: any} = this.getValue('view');
    const nextFormView: {[key: string]: any} = {};
    nextFormView.$all = {
      value: null,
      error: nextErrors.$all || '',
    };
    for (const key of Object.keys(nextFormFields)) {
      nextFormView[key] = {
        value: (nextFormFields[key] !== undefined) ? nextFormFields[key] : null,
        error: nextErrors[key] || '',
      }
    }
    return nextFormView;
  }

  // DEPRECATED - use `formView` property instead
  getViewFields() {
    return this.formView;
  }

  // DEPRECATED - use `formView` property instead
  getViewField(key) {
    return this.getValue(`view.${key}`);
  }

  // DEPRECATED - use `formView` property instead
  getRootViewField() {
    return this.getValue('view.$all');
  }

  // DEPRECATED - Use `connectToComponentState` instead
  connectFieldsToComponentState(component, key, init?: boolean) {
    return connectToComponentState(
      this,  // sourceState
      component,  // destComponent
      'view',  // sourcePath
      key,  // destName
      init,  // init
    );
  }

  // DEPRECATED - Use `connectToComponentState` instead
  connectDataToComponentState(component, key, init?: boolean) {
    return connectToComponentState(
      this,  // sourceState
      component,  // destComponent
      'data',  // sourcePath
      key,  // destName
      init,  // init
    );
  }

  connectSubmitToComponentProp(component, propName) {
    propName = propName || 'onSubmit';
    this.onSubmit((data) => {
      const callback = component.props[propName];
      if (callback) {
        callback(data, this);
      }
    });
    // Since this is a single-callback handler, an unsubscribe function isn't
    // entirely necessary, but we'll add one for parity.
    return () => {
      const callback = component.props[propName];
      if (this._onSubmit === callback) {
        this._onSubmit = null;
      }
    }
  }

  /**
   * Helper function for mapping a DOM `form` element's `onSubmit` property in
   * such a way that the form is correctly submitted.
   */
  handleFormSubmitEvent(event) {
    event.preventDefault();
    this.submit();
  }

}
