import CenteredBox from './centered-box';
import DialogBox from './dialog-box';
import FlexBarLayout from './flex-bar-layout';
import TwoButtonPanel from './two-button-panel';
import TwoPanelLayout from './two-panel-layout';

export default {
  CenteredBox,
  DialogBox,
  FlexBarLayout,
  TwoButtonPanel,
  TwoPanelLayout,
};
