import FStateWithAsync from './fstate-with-async';

import type RecordStoreState from './record-store-state';

type KeyType = string | number;

const NEW_RECORD_KEY_PREFIX = 'NEW$$';

const EMPTY_EXTRA_DATA = Object.freeze({});


/**
 * This references a single record in a RecordStoreState. It is technically and
 * FState but it's data is all read-only and is copied from its parent.
 *
 * This may be sub-classed so that it can expose more specific features based on
 * its related RecordStoreState.
 *
 * TODO: Need to fix some of the mutator functions so that the mutate the store
 * state instead. Then anything that actually needs to mutate local state should
 * instead call super.<mutator>
 */
export default class RecordStoreRef<RecordType> extends FStateWithAsync {

  static DEFAULT_NAME = 'RecordStoreRef';

  _store: RecordStoreState<RecordType>;
  _storeUnsubscribe: () => void;
  _keyField: string;

  constructor(store: RecordStoreState<RecordType>, options?) {
    super(options);
    this._store = store;  // RecordStoreState
    // TODO: this should actually be a multi-listener so that it can listen for
    // the record being in the list of loaded records even if it isn't found.
    this._storeUnsubscribe = null;
    this._keyField = this._store.keyField;
  }

  get key(): KeyType {
    return this.data.key;
  }

  set key(key: KeyType) {
    this._checkStoreDestroyed();
    key = key || null;
    if (key === this.data.key) {
      return;
    } else if (key === null) {
      this.setData({
        key: null,
        record: null,
        ...this.getExtraData(null)
      });
      if (this._storeUnsubscribe) {
        this._storeUnsubscribe();
        this._storeUnsubscribe = null;
      }
    } else {
      if (this._storeUnsubscribe) {
        this._storeUnsubscribe();
      }
      this._store.loadLazy([key]);  // Lazy load the key in the background
      this._storeUnsubscribe = this._store.subscribe(
        this.$b._handleStoreChange, {path: `recordsByKey.${key}`, immediate: true}
      );
      const nextRecord = this._store.getRecordSync(key, true);
      this.setData({
        key: key,
        record: nextRecord,
        ...this.getExtraData(nextRecord),
      });
    }
  }

  get record(): RecordType {
    return this.data.record;
  }

  get store(): RecordStoreState<RecordType> {
    return this._store;
  }

  makeInitialData() {
    return {
      key: null,
      record: null,
    };
  }

  destroy(): void {
    if (this._storeUnsubscribe) {
      this._storeUnsubscribe();
    }
    this._storeUnsubscribe = null;
    super.destroy();
  }

  _checkStoreDestroyed(): boolean {
    if (this._store.isDestroyed && !this.isDestroyed) {
      this.destroy();
      return true;
    }
    return false;
  }

  /**
   * Checks if the specified `key` is a "new" key or not. A "new" key is one
   * that only exists locally and has not been committed to the remote server
   * yet. It is mostly used as a placeholder while the user creates a new or a
   * copied record.
   */
  isNewRecord(): boolean {
    return this._store.isNewKey(this.data.key);
  }

  _handleStoreChange(data): void {
    if (this._checkStoreDestroyed()) {
      return;
    }
    // Sometimes a single data change can both destroy this reference and
    // trigger and update. Because of that, we want to simply abandon the
    // change.
    if (this.isDestroyed) {
      return;
    }
    const key: KeyType | null = this.data.key;
    // Manually manage the internal async state. This should give a close
    // approximation of the actual load state of the record. The one thing that
    // it loses is any progress refreshing the underlying data, but I think
    // we're OK without that for now.
    if (!key) {
      // No key yet, asume an unloaded state
      this._async.reset();
    } else if (this._store.isKeyLoaded(key)) {
      // There is a key and the store has that key loaded - record is either
      // available (success) or unavailable (error)
      const nextRecord = this._store.getRecordSync(key, true);
      if (nextRecord !== this.data.record) {
        this.patchData({
          record: nextRecord,
          ...this.getExtraData(nextRecord),
        });
        if (nextRecord) {
          this._async.success();
        } else if (this._store.isNewKey(key)) {
          // This happens a lot when a "new" record is converted. For now,
          // suppress the error. If this comes up a lot, we may need a way for
          // `getRecordSync` on a converted new record to somehow point to the
          // actual new record.
          console.info(`No record found for new key '${key}' (not an error)`);
        } else {
          this._async.error({}, 'Record could not be loaded');
        }
      }
    } else {
      // There is a key, but it's not loaded in the store yet. Assume it is
      // actively being loaded.
      // TODO: This should also reflect an error state in the store's async.
      if (this.data.record !== null) {
        this.patchData({
          record: null,
          ...this.getExtraData(null),
        });
        this._async.start();
      }
    }
  }

  /**
   * This will run whenever the record is about to be set. It must return an
   * object that will be merged with the set data or patch data call. This can
   * be used (a) to hook into right before data is set and (b) set supplemental
   * data on the ref.
   */
  getExtraData(nextRecord: RecordType | null): {} {
    return EMPTY_EXTRA_DATA;
  }

  /**
   * Refreshes the record. If `hard` is passed, this will clear the record's
   * data from the data store before refreshing it.
   */
  refresh(hard?: boolean): void {
    if (this._checkStoreDestroyed()) {
      return;
    }
    this.checkDestroyed();
    const key = this.data.key;
    if (!key) {
      return;  // Do nothing - no key
    }
    if (hard) {
      this._async.reset();
      this._store.reset([key]);
    }
    // Refresh the record in the background and `_handleStoreChange` will take
    // care of the rest.
    this._store.load([key]);
  }

  /**
   * Clears out the key and, if the record is "new", reset the record.
   *
   * This is similar to using the setter to set the key to null. However, it has
   * two additional benefits:
   *
   * - It can be bound and passed as a callback to things like form
   * submit/cancel callbacks
   * - If the record is "new", the memory for the new record will be released.
   */
  clearKey(): void {
    if (this.isNewRecord()) {
      this._store.reset([this.data.key]);
    }
    this.key = null;
  }

}
