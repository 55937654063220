import PropTypes from 'prop-types';
import React from 'react';

import {Button} from '@salesforce/design-system-react';
import {Input} from '@salesforce/design-system-react';

import CampaignMemberLoginLink from 'apps/maps/components/campaign-member-login-link';

import CampaignDetailsStateRef from 'apps/maps/state/campaign-details-state-ref';
import FormHandler from 'formlib/form-handler';
import ReactComponent from 'utils/react-component';
import {protoEnumToFormValue, formValueToProtoEnum} from 'formlib/form-functions';
import {connectToComponentState} from 'statelib/fstate-react';

import UserRolePicklist from 'apps/maps/components/user-role-picklist';


function CampaignDetailsMembersView({
  addPlayerToCampaignFields,
  campaign,
  campaignMembers,
  handleChangeNewPlayerEmail,
  handleChangeNewPlayerName,
  handleChangeNewPlayerRole,
  handleClickDeleteCampaignMember,
  handleSubmitAddPlayerForm,
}) {
  const baseUrl = window.location.origin
  return (
    <React.Fragment>
      {/* if */(campaignMembers) ? (
        <React.Fragment>
          {/* list of campaign members */}
          <ul>
            {campaignMembers.map((value, index, array) => (
              <li key={value.userId}>
                Name: {value.name} <br/>
                User Id: {value.userId} <br/>
                {/* if */(value.loginUrl) ? (
                  <CampaignMemberLoginLink loginUrl={value.loginUrl} />
                ) : null /* endif */}
                Role: {value.role} <br/>
                <Button
                  label='Delete Campaign Member'
                  variant='destructive'
                  type='button'
                  data-id={value.userId}
                  onClick={handleClickDeleteCampaignMember}
                />
              </li>
            ))}
          </ul>
          {/* Add player campaign member */}
          <div>
            <form onSubmit={handleSubmitAddPlayerForm}>
              <Input
                label='Player Name'
                type='text'
                value={addPlayerToCampaignFields.name.value}
                onChange={handleChangeNewPlayerName}
              />
              <Input
                label='Player Email'
                type='text'
                value={addPlayerToCampaignFields.email.value}
                onChange={handleChangeNewPlayerEmail}
              />
              <UserRolePicklist
                labels={{
                  label: 'Access Level',
                  placeholder: 'Select an Access Level',
                }}
                type='text'
                value={addPlayerToCampaignFields.role.value}
                onSelect={handleChangeNewPlayerRole}
              />
              <Button
                label='Add New Player to Campaign'
                variant='brand'
                type='submit'
              />
            </form>
          </div>
        </React.Fragment>
      )/* else */ : (
        <div>No campaign</div>
      )/* endif */}
    </React.Fragment>
  );
}


export default class CampaignDetailsMembers extends ReactComponent {

  static propTypes = {
    campaignContainer: PropTypes.instanceOf(CampaignDetailsStateRef).isRequired,
  };

  constructor(props) {
    super(props);
    this.campaignContainer = this.constantProp('campaignContainer');
    this.addPlayerForm = new FormHandler(this.$b._addPlayerFormRefresh);
      connectToComponentState(this.campaignContainer, this, 'campaign');
    this.addCleanup(
      this.addPlayerForm.connectFieldsToComponentState(this, 'addPlayerForm'),
      this.addPlayerForm.onSubmit(this.$b._addPlayerFormSubmit),
      this.addPlayerForm.$b.close,
    );
  }

  _addPlayerFormRefresh(campaignMember) {
    return {
      name: campaignMember.name || '',
      email: campaignMember.email || '',
      role: protoEnumToFormValue(campaignMember.role),
    };
  }

  async _addPlayerFormSubmit(data) {
    const campaignMember = data;
    const campaignContainer = this.campaignContainer;
    await campaignContainer.addCampaignMemberPlayer(campaignMember, true);
  }

  async _handleRemovePlayerClick(event) {
    const campaignContainer = this.campaignContainer;
    const userId = event.target.dataset.id;
    await campaignContainer.removeCampaignMembers([userId], true)
  }

  _handleChangeNewPlayerName(event, data) {
    this.addPlayerForm.setFormFieldAndData('name', data.value);
  }

  _handleChangeNewPlayerEmail(event, data) {
    this.addPlayerForm.setFormFieldAndData('email', data.value);
  }

  _handleChangeNewPlayerRole(event, data) {
    this.addPlayerForm.setFormFieldAndData('role', data.value, formValueToProtoEnum);
  }

  render() {
    return CampaignDetailsMembersView({
      addPlayerToCampaignFields: this.state.addPlayerForm,
      campaign: this.state.campaign.campaign,
      campaignMembers: this.state.campaign.members,
      handleChangeNewPlayerEmail: this.$b._handleChangeNewPlayerEmail,
      handleChangeNewPlayerName: this.$b._handleChangeNewPlayerName,
      handleChangeNewPlayerRole: this.$b._handleChangeNewPlayerRole,
      handleClickDeleteCampaignMember: this.$b._handleRemovePlayerClick,
      handleSubmitAddPlayerForm: this.addPlayerForm.$b.handleFormSubmitEvent,
    });
  }

}
