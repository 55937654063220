import AppGlobal from 'global';
import makeBindable from 'utils/bound-methods';
import CanvasUtils from 'utils/graphics/canvas-utils';
import mathutils from 'utils/math-utils';
import PerformanceCapture from 'utils/perf-capture';
import Rect from 'utils/rect';

import CanvasLayer from './canvas-layer';
import EventHitBox from './event-hit-box';
import Frame from './frame';
import GameObject from './gameobjects/game-object';

const GAME_OBJECT_LAYER_SPECIAL = 'special';

// Uses browser compositing (by simply layering the canvas layers on top of
// each other) rather than manual compositing. The big benefit of this is
// enabling blur filters on the entire fog layer without tanking (firefox still
// doesn't _love_ it, but at least it works).
//
// Drawbacks:
//
// 1. I don't know if this matters YET, but there may be a point where we want
//    access to the final composite data.
const USE_BROWSER_COMPOSITING = true;

const DEBUG = false;

// COORDINATE SYTSTEM DEFINITIONS:
//
// - source-pixel-space: This refers to the actual pixels relative to the grid's
//   origin. In other words, these are the "real" locations of the objects on
//   the map
// - local-pixel-space: This refers to the coordinate space of the canvas
//   itself, which is a pixel-density scaled version of the viewport.
// - browser-pixel-space: The coordinate system relative to the canvas' container
//   element. Any browser events on the parent (as well as any HTML elements
//   attached to the parent) should be in this space



export default class CanvasEngine {

  /** The animation frame handler. */
  private _frameHandler: any; // TODO: Fix

  /** The root document. This is necessary to create background canvas elements */
  private _document: Document;

  /** The target canvas onto which everything will be composed */
  private _viewCanvas: HTMLCanvasElement;

  /** The parent element containing the view canvas. This will also be used as
    * the parent element for all of the event objects */
  private _viewParent: HTMLElement;

  /** The list of layer names that will be converted into actual layers. */
  private _layerNames: string[];

  /** Flag that the canvas engine has been destroyed and cannot be used */
  private _isDestroyed: boolean;
  private _frameHandlerCallbackId: number;
  private _sourceX: number;
  private _sourceY: number;
  private _sourceScale: number;
  private _pixelRatio: number;
  private _layerRect: Rect;
  private _viewCanvasRect: Rect;
  private _viewBrowserRect: Rect;
  private _layers: null | Map<string, CanvasLayer>;
  private _scratchLayers: Map<any, any>;
  private _gameObjects: Map<any, any>;
  private _currentFrame: Frame | null;
  private _nextFrameTimeDelta: number;
  private _keyframeNext: boolean;
  private _volatileNext: boolean;
  private _cursorViewX: number | null;
  private _cursorViewY: number | null;
  private _cursorSourceX: number | null;
  private _cursorSourceY: number | null;
  private _debugOut: any;
  private _debugFrameRate: any;
  private _debugFrameRateUncapped: any;

  /** Game Objects can request HTML elements be creted over the canvas that can
    * act as targets for events (especially pointer-based events) */
  private _eventHitBoxes: Map<GameObject, EventHitBox>;

  /** Used for debugging redraw regions */
  private _redrawDebugElem: HTMLDivElement | null = null;

  $b: any;
  $bdestroy: any;


  /**
   * @param frameHandler the frame loop to attach to. Defaults to the global
   *  frame loop.
   * @param rootDocument The root document that background canvas elements will
   *  be attached to.
   * @param targetCanvas The canvas element that will be the "view" on which
   *  everything else will be drawn.
   * @param layers a list of string layer names associated with the canvas
   *  engine. Each layer will increase overall rendering time but gives more
   *  flexibility during rendering.
   */
  constructor({
    frameHandler = AppGlobal.frameLoop,
    rootDocument = window.document,
    pixelRatio = AppGlobal.settings.value('graphics.pixelRatio') || window.devicePixelRatio || 1,
    viewCanvas,
    layerNames,
  }) {
    makeBindable(this);
    this._frameHandler = frameHandler;
    if (!this._frameHandler) throw new Error('frameHandler is required');
    this._document = rootDocument;
    if (!this._document) throw new Error('document is required');
    this._viewCanvas = viewCanvas;
    if (!this._viewCanvas) throw new Error('viewCanvas is required');
    this._viewParent = this._viewCanvas.parentNode as HTMLElement;
    this._layerNames = layerNames;
    if (!this._layerNames) throw new Error('layers is required');
    this._isDestroyed = false;
    // Run the handler each frame.
    // TODO: It should be possible to configure frame skip here.
    this._frameHandlerCallbackId = this._frameHandler.onFrame(this.$b._onFrameTick);
    // Coordinates of the view in source-pixel-space. This is used to provide a
    // transformation matrix from source-pixel-space into the view canvas
    // itself.
    this._sourceX = 0;
    this._sourceY = 0;
    this._sourceScale = 1.0;
    // The pixel ratio of the canvas. This isn't used internally by the engine
    // but may be used by game objects to render certain aspects at the right
    // scale.
    this._pixelRatio = pixelRatio;
    // The rect representing the layer canvases in local-pixel-space
    this._layerRect = Rect.ZERO;
    // A rect representing the dimensions of the view canvas
    this._viewCanvasRect = Rect.ZERO;
    this._viewBrowserRect = Rect.ZERO;
    // All of the layers that will be used to compose the final graphics. They
    // will be composed in reverse-order. In other words, the first item in the
    // map (insertion order) will be the last thing drawn so that it appears on
    // top of everything else.
    this._layers = new Map();
    // Special layers that are not automatically merged into the final canvas,
    // allowing for image data to be cached. These layers will still be resized
    // when necessary. They will explicitly NOT be erased between frames, even
    // when a keyframe happens.
    this._scratchLayers = new Map();
    // Mapping of layer -> identity map of GameObject instances. These will be
    // executed on each frame.
    this._gameObjects = new Map();
    // The frame that is currently in progress. If a frame has somehow not
    // finished by the time a new frame happens, the next frame will be dropped.
    this._currentFrame = null;
    // In the event that we need a frame skip, this allows us to aggregate the
    // timeDelta from multiple frames.
    this._nextFrameTimeDelta = 0;
    // Tracks whether a keyframe has explicitly been requested for the next
    // frame. Note that a keyframe may occur even if it hasn't been requested.
    this._keyframeNext = false;
    // Tracks whether the next keyframe is "voltile". A a volatile keyframe is
    // one where the visual information is changing rapidly (such as with a
    // quick pan or zoom). Due to this, some graphical fidelity can be
    // sacrificed in the name of faster render times. The frame following a
    // volatile frame MUST be another keyframe - either a volatile one which
    // continues the chain or a non-volatile one which allows the system to
    // start performing non-keyframes
    this._volatileNext = false;
    // The x and y coordinates of the cursor in local-pixel-space.
    this._cursorViewX = null;
    this._cursorViewY = null;
    // The x and y coordinates of the cursor in source-pixel-space
    this._cursorSourceX = null;
    this._cursorSourceY = null;

    this._eventHitBoxes = new Map();

    if (DEBUG) {
      this._redrawDebugElem = this.document.createElement('div');
      this._redrawDebugElem.style.position = 'absolute';
      this._redrawDebugElem.style.left = '0px';
      this._redrawDebugElem.style.top = '0px';
      this._redrawDebugElem.style.width = '0px';
      this._redrawDebugElem.style.height = '0px';
      this._redrawDebugElem.style.border = '1px dashed #0FF';
      this.viewParent.appendChild(this._redrawDebugElem);
    }

    // Debug text output
    this._debugOut = document.getElementById('canvas-view-debug');
    this._debugFrameRate = new mathutils.RunningAverage();
    this._debugFrameRateUncapped = new mathutils.RunningAverage();
  }

  initialize(): void {
    this._checkViewCanvas();
    this._buildLayerCanvases();
    this._initializeGameObjectLayers();
    this._initializeDomEvents();
  }

  destroy(): void {
    this._isDestroyed = true;
    this._viewCanvas = null;
    this._document = null;
    this._frameHandler.removeCallback(this._frameHandlerCallbackId);
    this._frameHandlerCallbackId = null;
    this._frameHandler = null;
    this._gameObjects = null;  // TODO: Detach all game objects first
    this._scratchLayers = null;
    if (this._layers) {
      for (const layer of this._layers.values()) {
        if (USE_BROWSER_COMPOSITING) {
          layer.getCanvas().remove();
        }
        layer.destroy();
      }
    }
    this._layers = null;
    this.$bdestroy();
  }

  get document(): Document {
    return this._document;
  }

  get viewParent(): HTMLElement {
    return this._viewParent;
  }

  /** A rect representation canvas (on which everything is drawn). This is
    * already scaled up based on the pixel-density */
  get viewCanvasRect(): Rect {
    return this._viewCanvasRect;
  }

  get viewBrowserRect(): Rect {
    return this._viewBrowserRect;
  }

  _throwIfDestroyed(): void {
    if (this._isDestroyed === true) {
      throw new Error('The operation cannot be performed after a CanvasEngine that has been destroyed');
    }
  }

  /**
   * Checks the view canvas to see if it has changed in size. If it has, then
   * all of the internal layers need to be adjusted and a keyframe is required.
   */
  _checkViewCanvas(): void {
    const viewCanvasRect = Rect.fromCanvas(this._viewCanvas);
    const viewBrowserRect = Rect.fromElement(this._viewParent);
    if (this._viewCanvasRect.isNotEqualTo(viewCanvasRect)) {
      this._viewCanvasRect = viewCanvasRect;
      this._viewBrowserRect = viewBrowserRect;
      this.requestKeyframe();
    }
  }

  /**
   * Clears and rebuilds the internal layers map
   */
  _buildLayerCanvases(): void {
    for (const canvasLayer of this._layers.values()) {
      canvasLayer.destroy();
    }
    this._layers.clear();
    for (const layerName of this._layerNames) {
      const newLayer = this._makeCanvasLayer(layerName);
      this._layers.set(layerName, newLayer);
      if (USE_BROWSER_COMPOSITING) {
        const layerCanvas = newLayer.getCanvas();
        layerCanvas.classList.add('canvasengine--canvas-layer');
        this._viewParent.appendChild(layerCanvas);
      }
    }
  }

  _initializeGameObjectLayers(): void {
    this._gameObjects.set(GAME_OBJECT_LAYER_SPECIAL, new Map());
    for (const layerName of this._layerNames) {
      this._gameObjects.set(layerName, new Map());
    }
  }

  _initializeDomEvents(): void {
    this._viewParent.addEventListener('click', this.$b.handleClick);
    this._viewParent.addEventListener('pointerdown', this.$b.handlePointerDown);
    this._viewParent.addEventListener('pointerup', this.$b.handlePointerUp);
    // this._viewParent.addEventListener('mouseover', this.$b.handlePointerOverAndMove);
    this._viewParent.addEventListener('pointermove', this.$b.handlePointerOverAndMove);
    this._viewParent.addEventListener('pointerout', this.$b.handlePointerOut);
  }

  /**
   * Creates a single canvas layer based on the current view canvas' size.
   */
  _makeCanvasLayer(layerName: string): CanvasLayer {
    return new CanvasLayer({
      rootDocument: this._document,
      name: layerName,
      width: this._viewCanvasRect.w,
      height: this._viewCanvasRect.h,
    })
  }

  _onFrameTick(timestamp: number, deltaMs: number, frameNo: number): void {
    if (!this._frameHandlerCallbackId) {
      return;
    }
    // Track the start time for monitoring frame rate
    const startTime = performance.now();
    // Increase the current frame time delta and check for a frame skip
    this._nextFrameTimeDelta += deltaMs;
    if (this._currentFrame) {
      // Frame was not completed and needs to be continued.
      console.debug(`WARNING: Frame skip: ${frameNo}`);
    } else {
      // Prepare the next frame to run, checking for canvas changes
      this._checkViewCanvas();
      this._prepareCurrentFrame(timestamp, this._nextFrameTimeDelta, frameNo);
      this._nextFrameTimeDelta = 0;
    }
    // Execute (or continue executing) the frame, completing (compositing) if
    // the frame was able to completed
    const {done} = this._currentFrame.execute();
    if (done) {
      this._completeCurrentRender();
    }
    // For debugging, check how long the frame took
    const frameGenTime = performance.now() - startTime;
    this._debugFrameRate.add(Math.min(9999, 1000 / deltaMs));
    this._debugFrameRateUncapped.add(Math.min(9999, 1000 / frameGenTime));
    if (this._debugOut && frameNo % 12 === 0) {
      this._debugOut.textContent = [
        `frame rate:   ${this._debugFrameRate.value.toFixed(1)}`,
        ` theoretical: ${this._debugFrameRateUncapped.value.toFixed(1)}`,
      ].join('\n');
    }
  }

  /**
   * Sets up a frame to be executed.
   *
   * IDEA: It would be best to shallow-copy the list of objects and layers so
   * that, if they are modified mid-frame, it doesn't affect the current
   * frame's execution.
   *
   * IDEA: Somehow always have the "next" frame ready to go. One annoying thing
   * is needing to wait for onUpdate in order to actually DO anything - it
   * means that all of the async events need to prepare for the next frame
   * rather than just doing whatever they are going to do. If the next frame is
   * always available, it means that async things can interact with it (before
   * any of the onUpdates, etc are called). Really nice for asynchronously
   * requesing a rerender.
   */
  _prepareCurrentFrame(frameTimestamp: number, frameTimeDelta: number, frameNo: number): void {
    const nextFrame = new Frame(this);
    nextFrame.layers = this._layers;
    nextFrame.gameObjects = this._gameObjects;
    nextFrame.eventHitBoxes = this._eventHitBoxes;
    nextFrame.startTimestamp = frameTimestamp;
    nextFrame.frameTimeDelta = frameTimeDelta;
    nextFrame.frameNo = frameNo;
    nextFrame.scale = this._sourceScale;
    nextFrame.pixelRatio = this._pixelRatio;
    nextFrame.isKeyframe = this._keyframeNext;
    nextFrame.isVolatile = this._volatileNext;
    nextFrame.sourceRect = Rect.fromDimensions(
      this._sourceX,
      this._sourceY,
      this._viewCanvasRect.w / this._sourceScale,
      this._viewCanvasRect.h / this._sourceScale,
    ).round();
    nextFrame.targetRect = Rect.fromDimensions(
      0, 0, this._viewCanvasRect.w, this._viewCanvasRect.h
    );
    this._currentFrame = nextFrame;
    // Volatile frames MUST be followed by another keyframe.
    this._keyframeNext = !!nextFrame.isVolatile;
    this._volatileNext = false;
  }

  /**
   * Atomically finishes compositing all of the layers to the view canvas.
   *
   * IDEA: This could probably be run in parallel with the next frame up till
   * the point when the next frame starts calling `onRender` callbacks. if we
   * ever get REALLY serious about performance, that's an option.
   *
   * IDEA: Maybe figure out how to improve the way dirty regions are rendered?
   * Right now, we still composite the full layers even if they only have
   * small dirty regions to avoid any sort of compoinding. But we could get
   * the combined dirty regions of all layers, then refresh only that area.
   */
  _completeCurrentRender(): void {
    const frame = this._currentFrame;
    this._currentFrame = null;
    if (USE_BROWSER_COMPOSITING) {
      return;
    }
    // TODO: If keyframe, have the redrawRect = this._viewCanvasRect. Otherwise,
    // it should be the combined redrawRegions (`frame.getRedrawRegion()`) of
    // all layers. Then, only clear redrawRect and only copy image data from
    // redrawRect to reduce the amount of work that needs to be done
    let redrawRegion: Rect;
    if (frame.isKeyframe) {
      redrawRegion = this._viewCanvasRect;
    } else {
      redrawRegion = frame.getRedrawRegion();
    }
    if (!redrawRegion || redrawRegion === Rect.ZERO) {
      redrawRegion = null;
    }
    // Debug draw a div representing the redraw region
    if (DEBUG) {
      if (redrawRegion) {
        const browserRect = this.localRectToBrowserRect(redrawRegion);
        this._redrawDebugElem.style.left = `${browserRect.x}px`;
        this._redrawDebugElem.style.top = `${browserRect.y}px`;
        this._redrawDebugElem.style.width = `${browserRect.w}px`;
        this._redrawDebugElem.style.height = `${browserRect.h}px`;
        this._redrawDebugElem.style.display = 'block';
      } else {
        this._redrawDebugElem.style.display = 'none';
      }
    }
    // Only do the layer compositing if something has changed
    if (redrawRegion) {
      const perfMetrics = new PerformanceCapture('canvasengine:_completeCurrentRender');
      const targetContext = this._viewCanvas.getContext('2d', {alpha: false});
      targetContext.imageSmoothingEnabled = false;
      targetContext.clearRect(
        redrawRegion.x,
        redrawRegion.y,
        redrawRegion.w,
        redrawRegion.h,
      );
      perfMetrics.capture('clear');
      for (const [layerName, layer] of this._layers.entries()) {
        targetContext.drawImage(
          layer.getCanvas(),
          redrawRegion.x,
          redrawRegion.y,
          redrawRegion.w,
          redrawRegion.h,
          redrawRegion.x,
          redrawRegion.y,
          redrawRegion.w,
          redrawRegion.h,
        );
        perfMetrics.capture(`draw_${layerName}`);
      }
      if (frame.frameNo % 200 === 0) {
        perfMetrics.debug();
      }
    }
  }

  /**
   * Sets the focus point using coordinates in local-pixel-space. For desktop
   * users, this will typically be the cursor location. For touch users, this
   * may only be relevant if they are actively touching the display.
   */
  setFocusPoint(
    x: number | null, y: number | null
  ): [sourceX: number | null, sourceY: number | null] {
    let nextCursorViewX: number | null;
    let nextCursorViewY: number | null;
    if (
      x === null ||
      x === undefined ||
      isNaN(x) ||
      y === null ||
      y === undefined ||
      isNaN(y)
    ) {
      nextCursorViewX = null;
      nextCursorViewY = null;
    } else {
      nextCursorViewX = x;
      nextCursorViewY = y;
    }
    if (this._cursorViewX !== nextCursorViewX || this._cursorViewY !== nextCursorViewY) {
      this._cursorViewX = nextCursorViewX;
      this._cursorViewY = nextCursorViewY;
      this.refreshCursorPosition();
    }
    return [this._cursorSourceX, this._cursorSourceY];
  }

  setFocusPointFromEvent(event: PointerEvent): void {
    // Work in page coordinates to deal with pesky elements like hitboxes or
    // token interface buttons.
    const canvasOffsetRect = this._viewCanvas.getBoundingClientRect();
    this.setFocusPoint(
      event.pageX - canvasOffsetRect.left,
      event.pageY - canvasOffsetRect.top,
    );
  }

  handleClick(event: PointerEvent) {
    this.setFocusPointFromEvent(event);
  }

  handlePointerDown(event: PointerEvent) {
    this.setFocusPointFromEvent(event);
  }

  handlePointerUp(event: PointerEvent) {
    this.setFocusPointFromEvent(event);
  }

  handlePointerOverAndMove(event: PointerEvent) {
    this.setFocusPointFromEvent(event);
  }

  handlePointerOut(event: PointerEvent): void {
    this.setFocusPoint(null, null);
  }

  /**
   * Refreshes the cursor's position in source-pixel-space based on its current
   * position in the view element.
   */
  refreshCursorPosition(): void {
    if (this._cursorViewX === null || this._cursorViewY === null) {
      this._cursorSourceX = null;
      this._cursorSourceY = null;
    } else {
      [this._cursorSourceX, this._cursorSourceY] = this.getSourceCoordinates(
        this._cursorViewX, this._cursorViewY
      );
    }
  }

  /**
   * Gets the cursor/focus X coordinate in source-pixel-space. Note that this is
   * fairly unreliable for touch devices.
   */
  getCursorX(): number {
    return this._cursorSourceX;
  }

  /**
   * Gets the cursor/focus Y coordinate in source-pixel-space. Note that this is
   * fairly unreliable for touch devices.
   */
  getCursorY(): number {
    return this._cursorSourceY;
  }

  getViewX(): number {
    return this._sourceX;
  }

  getViewY(): number {
    return this._sourceY;
  }

  /**
   * Gets the current zoom (scale) for the engine. This does not include
   * adjustments for pixel density.
   */
  getZoom(): number {
    return this._sourceScale;
  }

  /**
   * Converts a rect in local-pixels-pace into source-pixel-space
   */
  localRectToSourceRect(localRect: Rect): Rect {
    // const localToSourceScale = this._pixelRatio / this._sourceScale;
    const localToSourceScale = 1 / this._sourceScale;
    return Rect.fromCoordinates(
      (localRect.x * localToSourceScale) + this._sourceX,
      (localRect.y * localToSourceScale) + this._sourceY,
      (localRect.x2 * localToSourceScale) + this._sourceX,
      (localRect.y2 * localToSourceScale) + this._sourceY,
    );
  }

  /**
   * Converts a rect in local-pixels-pace into browser-pixel-space
   */
  localRectToBrowserRect(localRect: Rect): Rect {
    return Rect.fromCoordinates(
      (localRect.x / this._pixelRatio),
      (localRect.y / this._pixelRatio),
      (localRect.x2 / this._pixelRatio),
      (localRect.y2 / this._pixelRatio),
    );
  }

  /**
   * Converts a rect in source-pixel-space into local-pixel-space
   */
  sourceRectToLocalRect(sourceRect: Rect): Rect {
    // const sourceToLocalScale = this._sourceScale / this._pixelRatio;
    const sourceToLocalScale = this._sourceScale;
    return Rect.fromCoordinates(
      (sourceRect.x - this._sourceX) * sourceToLocalScale,
      (sourceRect.y - this._sourceY) * sourceToLocalScale,
      (sourceRect.x2 - this._sourceX) * sourceToLocalScale,
      (sourceRect.y2 - this._sourceY) * sourceToLocalScale,
    );
  }

  /**
   * Converts a rect in source-pixel-space into browser-pixel-space.
   *
   * This is typically only used when interacting with DOM elements attached
   * to the canvas' parent. When drawing anything you probably want to use
   * sourceRectToLocalRect instead.
   */
  sourceRectToBrowserRect(sourceRect: Rect): Rect {
    const sourceToBrowserScale = this._sourceScale / this._pixelRatio;
    return Rect.fromCoordinates(
      (sourceRect.x - this._sourceX) * sourceToBrowserScale,
      (sourceRect.y - this._sourceY) * sourceToBrowserScale,
      (sourceRect.x2 - this._sourceX) * sourceToBrowserScale,
      (sourceRect.y2 - this._sourceY) * sourceToBrowserScale,
    );
  }

  /**
   * Given two coordinates in browser-pixel-space, get their corresponding
   * positions in source-pixel-space. Returns a 2-tuple.
   */
  getSourceCoordinates(x: number, y: number): [number, number] {
    return [
      (this._pixelRatio * x / this._sourceScale) + this._sourceX,
      (this._pixelRatio * y / this._sourceScale) + this._sourceY,
    ];
  }

  /**
   * Updates the view's location in pixel-space. This will be used to update
   * the internal transformation matrix as well as request a keyframe.
   */
  setView(x: number, y: number, scale: number): void {
    this._throwIfDestroyed();
    if (
      this._sourceX !== x ||
      this._sourceY !== y ||
      this._sourceScale !== scale
    ) {
      const isVolatile = !!(
        mathutils.delta(x, this._sourceX) >= 20 ||
        mathutils.delta(y, this._sourceY) >= 20 ||
        mathutils.fracDelta(scale, this._sourceScale) >= .1
      );
      this._sourceX = x;
      this._sourceY = y;
      this._sourceScale = scale;
      this.requestKeyframe(isVolatile);
      this.refreshCursorPosition();
    }
  }

  /**
   * Requests that the next frame be a keyframe (full re-render).
   *
   * If `volatile` is provided, it indicates that the keyframe is volatile
   * (focus on speed over fidelity).
   */
  requestKeyframe(volatile?: boolean): void {
    this._throwIfDestroyed();
    this._keyframeNext = true;
    if (volatile) {
      this._volatileNext = true;
    }
  }

  /**
   * Adds a game object to a layer within the engine.
   *
   * Typically this function should NOT be called directly. Instead use the
   * GameObject's `attach` function instead.
   */
  addGameObject(layerName: string, gameObject) {
    this._throwIfDestroyed();
    this._gameObjects.get(layerName).set(gameObject, gameObject);
  }

  /**
   * Remvoes a game object from a layer in the engine
   *
   * Typically this function should NOT be called directly. Instead use the
   * GameObject's `detach` function instead.
   *
   * Note that removing a game object will trigger a keyframe, so it's best to
   * either hide the object (if it is only meant to be temporarily removed) or
   * remove large number of objects at once.
   */
  removeGameObject(layerName: string, gameObject) {
    this._throwIfDestroyed();
    this._gameObjects.get(layerName).delete(gameObject);
    this.removeEventHitBox(gameObject);
    // TODO: This is pretty cheesy, but it's easier than trying to figure out
    // the render area of the removed object and queing that up to be
    // refreshed.
    this.requestKeyframe();
  }

  /**
   * Removes and destroys the event target associated with `gameObject`.
   */
  removeEventHitBox(gameObject: GameObject) {
    const eventHitBox = this._eventHitBoxes.get(gameObject);
    if (eventHitBox) {
      this._eventHitBoxes.delete(gameObject);
      eventHitBox.destroy();
    }
  }

  /**
   * Gets the event target for a game object, creating a new one if the game
   * object does not have one yet.
   */
  getEventHitbox(gameObject: GameObject): EventHitBox {
    let eventHitBox = this._eventHitBoxes.get(gameObject);
    if (!eventHitBox) {
      eventHitBox = new EventHitBox(this, `${gameObject.id}_eventhitbox`);
      this._eventHitBoxes.set(gameObject, eventHitBox);
    }
    return eventHitBox;
  }

  /**
   * Gets the event target for a game object, creating a new one if the game
   * object does not have one yet.
   */
  getEventTarget(gameObject: GameObject): HTMLElement {
    return this.getEventHitbox(gameObject).element;
  }

  /**
   * Sets the event target's location (in source-pixel-space)
   */
  setEventTargetRect(gameObject: GameObject, sourceRect: Rect | null): void {
    const eventHitBox = this.getEventHitbox(gameObject);
    const isChanged = eventHitBox.setSourceRect(sourceRect);
    if (isChanged) {
      eventHitBox.refreshPosition();
    }
  }

  getLayer(layerName: string) {
    const layer = this._layers.get(layerName);
    if (!layer) {
      throw new Error(`Unknown layer '${layerName}`);
    }
    return layer;
  }

  getScratchLayersEntries() {
    return this._scratchLayers.entries();
  }

  getScratchLayer(layerName: string) {
    if (this._layers.has(layerName)) {
      throw new Error(`Scratch layer name '${layerName}' collides with a real layer`);
    }
    let scratchLayer = this._scratchLayers.get(layerName);
    if (!scratchLayer) {
      scratchLayer = new CanvasLayer({
        name: layerName,
        width: this._viewCanvasRect.w,
        height: this._viewCanvasRect.h,
      });
      this._scratchLayers.set(layerName, scratchLayer);
    }
    return scratchLayer;
  }

  deleteScratchLayer(layerName: string) {
    if (this._scratchLayers.has(layerName)) {
      this._scratchLayers.get(layerName).destroy();
      this._scratchLayers.delete(layerName);
    }
  }

}
