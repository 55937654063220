import classNames from 'classnames';
import Memoize from 'memoize-one';
import React from 'react';
import {Button} from '@salesforce/design-system-react';
import {Input} from '@salesforce/design-system-react';
import {Textarea} from '@salesforce/design-system-react';

import AppGlobal from 'global';
import AppReactComponent from 'utils/app-react-component';
import BaseAsyncState from 'statelib/base-async-state';
import FormHandler from 'formlib/form-handler';
import ImageManager from 'components/images/image-manager';
import layouts from 'components/layouts';
import MapImageStoreRef from 'apps/maps/state/map-image-store-ref';
import MapObjectStoreRef from 'apps/maps/state/map-object-store-ref';
import MapObjectStoreState from 'apps/maps/state/map-object-store-state';
import QueuedAsyncState from 'statelib/queued-async-state';
import SinglePicklist from 'components/form/single-picklist';
import Tooltip from 'components/basic/tooltip';
import {almostEqual} from 'utils/types';
import {cleanFloat} from 'utils/types';
import {maps as mapsPb} from 'proto-bundle';

import TokenImage from './token-image';

interface PropsType {
  afterSubmit?: (token: object, form: FormHandler) => void,  // TODO: Form doesn't make a ton of sense here
  afterDelete?: (token: object) => void,
  async?: any,  // Optional. Can share async state.
  campaignId: string
  className?: string,
  tokenRef: MapObjectStoreRef,
  mapZoneId?: string,
  onCancel?: () => void,
  style?: any,
  token?: any,  // Optional. Specify this if editing.
};

interface StateType {
  async: any,
  confirmDelete: boolean,
  fields: any,
  formState: 'Main' | 'TokenImage',
  isToken: boolean,
  token: any,
  tokenImage: any,
};

const FACTION_OPTIONS = [
  {id: 'PLAYER_FACTION', label: 'Player'},
  {id: 'ALLY_FACTION', label: 'Ally'},
  {id: 'NEUTRAL_FACTION', label: 'Neutral'},
  {id: 'HOSTILE_FACTION', label: 'Hostile'},
  {id: 'MYSTERY_FACTION', label: 'Mystery'},
];

const SIZE_PRESET_OPTIONS = [
  {id: 'tiny', label: 'Tiny'},
  {id: 'small', label: 'Small'},
  {id: 'medium', label: 'Medium'},
  {id: 'large', label: 'Large'},
  {id: 'huge', label: 'Huge'},
  {id: '', label: 'Custom'},
];

const SIZE_PRESET_TO_FIELDS = {
  tiny: {imageScale: 0.95, width: 0.5, height: 0.5},
  small: {imageScale: 0.65, width: 1.0, height: 1.0},
  medium: {imageScale: 0.80, width: 1.0, height: 1.0},
  large: {imageScale: 0.95, width: 1.0, height: 1.0},
  huge: {imageScale: 0.70, width: 2, height: 2},
}

function getSizePresetFromFields(imageScale: number, width: number, height: number): string {
  if (!imageScale || isNaN(imageScale) || !width || isNaN(width)  || !height || isNaN(height)) {
    return '';
  }
  for (const [presetKey, fields] of Object.entries(SIZE_PRESET_TO_FIELDS)) {
    if (
      almostEqual(imageScale, fields.imageScale) &&
      almostEqual(width, fields.width) &&
      almostEqual(height, fields.height)
    ) {
      return presetKey;
    }
  }
  return '';
}

const DEFAULT_IMAGE_CATEGORIES = ['token'];

const TOKEN_IMAGE_STYLE = {
  width: '6rem',
  objectFit: 'contain',
}


/**
 * A form for creating new tokens (map objects) or modifying existing ones.
 *
 * This form specifically will contain fields for working with the token type
 * of map object. It is not a general map object modifier.
 *
 * Unlike some forms, this one specifically doesn't modify any of the underlying
 * data until submission as a way to prevent accidentally modifying the token
 * itself.
 */
export default class TokenAddEditForm extends AppReactComponent<PropsType, StateType, any>{

  _async: BaseAsyncState;
  _formHandler: FormHandler;
  _mapObjectStore: MapObjectStoreState;
  _tokenImageStoreRef: MapImageStoreRef;
  _tokenRef: MapObjectStoreRef;

  constructor(props: PropsType) {
    super(props);
    this.state = {
      async: null,
      confirmDelete: false,
      fields: null,
      formState: 'Main',
      isToken: false,
      token: null,
      tokenImage: null,
    }
    this._tokenImageStoreRef = new MapImageStoreRef(AppGlobal.imageStore);
    this._mapObjectStore = AppGlobal.mapObjectStore;  // TODO: Do better
    this._async = this.constantProp('async', new QueuedAsyncState());
    this._formHandler = new FormHandler(this.$b._dataToForm, 'TokenAddEditForm');
    this._formHandler.onSubmit(this.$b._handleSubmit);
    this._tokenRef = this.constantProp('tokenRef');
    this.connect('async', this._async);
    this.connect('token', this._tokenRef, 'record');
    this.connect('isToken', this._tokenRef, 'isToken');
    this.connect('tokenImage', this._tokenImageStoreRef, 'record');
    this._resetData();  // Safe to call before the form is connected to state
    this.connect('fields', this._formHandler, 'view');
    this.addCleanup(
      () => this._formHandler.close(),
      this._tokenRef.subscribe(
        this._tokenImageStoreRef.$b.setKeyFromMapObjectTokenImageId,
        {path: 'record', immediate: true},
      ),
      this._tokenImageStoreRef.$b.destroy,
    );
  }

  _getFieldChangeHandlers = Memoize(() => ({
    name: (event: Event, data) => {
      this._formHandler.setFormField('name', data.value);
    },
    shortName: (event: Event, data) => {
      this._formHandler.setFormField('shortName', data.value);
    },
    annotation: (event: Event, data) => {
      this._formHandler.setFormField('annotation', data.value);
    },
    borderColor: (event: Event, data) => {
      this._formHandler.setFormField('borderColor', data.value);
    },
    sizePreset: (event: Event, value: string) => {
      this._formHandler.setFormField('sizePreset', value);
      const sizeFields = SIZE_PRESET_TO_FIELDS[value];
      if (sizeFields) {
        this._formHandler.setFormField('imageScale', sizeFields.imageScale);
        this._formHandler.setFormField('width', sizeFields.width);
        this._formHandler.setFormField('height', sizeFields.height);
      }
    },
    imageScale: (event: Event, data) => {
      this._formHandler.setFormField('imageScale', data.value);
      this._formHandler.setFormField('sizePreset', getSizePresetFromFields(
        Number(this._formHandler.formFields.imageScale),
        Number(this._formHandler.formFields.width),
        Number(this._formHandler.formFields.height),
      ));
    },
    width: (event: Event, data) => {
      this._formHandler.setFormField('width', data.value);
      this._formHandler.setFormField('sizePreset', getSizePresetFromFields(
        Number(this._formHandler.formFields.imageScale),
        Number(this._formHandler.formFields.width),
        Number(this._formHandler.formFields.height),
      ));
    },
    height: (event: Event, data) => {
      this._formHandler.setFormField('height', data.value);
      this._formHandler.setFormField('sizePreset', getSizePresetFromFields(
        Number(this._formHandler.formFields.imageScale),
        Number(this._formHandler.formFields.width),
        Number(this._formHandler.formFields.height),
      ));
    },
    imageId: (event: Event, imageId) => {
      this._formHandler.setFormField('imageId', imageId);
      this._tokenImageStoreRef.key = imageId;
    },
    smallImageId: (event: Event, imageId) => {
      this._formHandler.setFormField('smallImageId', imageId);
    },
    portraitImageId: (event: Event, imageId) => {
      this._formHandler.setFormField('portraitImageId', imageId);
    },
    secret: (event: Event, data) => {
      this._formHandler.setFormField('secret', data.checked);
    },
    faction: (event: Event, itemId: string, item: any) => {
      this._formHandler.setFormField('faction', itemId);
    },
    description: (event: Event) => {
      const targetElem = event.target as HTMLTextAreaElement;
      this._formHandler.setFormField('description', targetElem.value);
    },
  }));

  _resetData() {
    if (this.state.token) {
      this._formHandler.initializeData(this.state.token);
    } else {
      this._formHandler.initializeData({token: {}});
    }
  }

  _dataToForm(data) {
    const token = data || {};
    const details = token.token || {};
    return {
      name: data.name || '',
      shortName: details.shortName || '',
      annotation: details.annotation || '',
      borderColor: details.border ? (details.border.color ?? '#ffffff') : '',
      imageScale: cleanFloat(details.imageScale || 0.8, 3),
      width: cleanFloat(details.gridWidth || 1, 3),
      height: cleanFloat(details.gridHeight || 1, 3),
      sizePreset: getSizePresetFromFields(
        details.imageScale || 0.8,
        details.gridWidth || 1,
        details.gridHeight || 1,
      ),
      imageId: details.imageId || null,
      smallImageId: details.smallImageId || null,
      portraitImageId: details.portraitImageId || null,
      secret: details.isSecret || false,
      faction: details.faction || mapsPb.Faction.UNKNOWN_FACTION,
      description: details.description || '',
    };
  }

  _formToData(formFields) {
    const isToken = this.state.token && this.state.token.token;
    const token = isToken ? this.state.token : null;
    const prevToken = token || {};
    const prevDetails = prevToken.token || {};
    const nextToken = {
      ...prevToken,
      campaignId: this.props.campaignId,
      id: this.state.token.id,
      name: formFields.name || null,
      token: {
        ...prevDetails,
        shortName: formFields.shortName || null,
        annotation: formFields.annotation || null,
        imageScale: Number(formFields.imageScale || '0.8'),
        gridWidth: Number(formFields.width || '1'),
        gridHeight: Number(formFields.height || '1'),
        imageId: formFields.imageId || null,
        smallImageId: formFields.smallImageId || null,
        portraitImageId: formFields.portraitImageId || null,
        isSecret: formFields.secret || false,
        faction: formFields.faction || mapsPb.Faction.UNKNOWN_FACTION,
        description: formFields.description || null,
      },
    }
    if (formFields.borderColor) {
      nextToken.token.border = {
        style: 'RING_BORDER_STYLE',
        color: formFields.borderColor,
      }
    } else {
      nextToken.token.border = null;
    }
    return nextToken;
  }

  async _handleSubmit(data, form: FormHandler): Promise<void> {
    this._validateForm(form);
    if (form.hasErrors) {
      return;
    }
    let nextToken = this._formToData(form.formFields);
    try {
      await this._async.wrap(async throwIfCanceled => {
        nextToken = await this._mapObjectStore.upsertMapObject(nextToken);
        throwIfCanceled();
      });
    } catch (_) {
      const err = this._async.receiveError();
      form.setFormFieldError('$all', `${err.message}`);
      return;
    }
    if (this.props.afterSubmit) {
      this.props.afterSubmit(nextToken, form);
    }
    // TODO: Do we need to reset data or just listen for changes on token?
  }

  _validateForm(form: FormHandler) {
    form.clearFormFieldError('$all');
    // TODO: Fix this. All of these were referencing form rather than
    // data which was causing some weird issues
    // form.validateFieldDataTruthy('name');
    // form.validateFieldData<number>(
    //   'imageScale',
    //   value => value === null || value >= 0.1 && value <= 10,
    //   'Image scale must be between 0.1 and 10.0',
    // );
    // form.validateFieldData<number>(
    //   'width',
    //   value => value === null || value >= 1 && value <= 15,
    //   'Token size must be between 1 and 15',
    // );
    // form.validateFieldData(
    //   'height',
    //   value => value === null || value >= 1 && value <= 15,
    //   'Token size must be between 1 and 15',
    // );
    // form.validateFieldDataTruthy('faction');
  }

  _handleCancel(event) {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  _handleEditTokenImage(event) {
    this.setState({formState: 'TokenImage'});
  }

  _handleImageCancel(event) {
    this.setState({formState: 'Main'});
  }

  _handleImageSelect(event, imageId) {
    this._getFieldChangeHandlers().imageId(event, imageId);
    this.setState({formState: 'Main'});
  }

  _handleDelete(event) {
    const token = this.state.token;
    this._mapObjectStore.deleteMapObject(token);
    if (this.props.afterDelete) {
      this.props.afterDelete(token);
    }
  }

  _handleDeleteCancel(event) {
    this.setState({confirmDelete: false});
  }

  _handleDeleteNeedsConfirm(event) {
    this.setState({confirmDelete: true});
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    if (
      prevState.token !== this.state.token ||
      prevState.isToken !== this.state.isToken
    ) {
      this._resetData();
    }
  }

  render() {
    const state = this.state;
    switch (state.formState) {
      case 'Main': return this._renderFormMain();
      case 'TokenImage': return this._renderFormTokenImage();
      default: throw new Error(`Unknown form state: ${state.formState}`);
    }
  }

  _renderFormMain() {
    const props = this.props;
    const state = this.state;
    const changeHandlers = this._getFieldChangeHandlers();
    const curImageUrl = state.tokenImage ? state.tokenImage.thumbnailUrl : null;
    const isNew = !state.token || this._mapObjectStore.isNewKey(state.token.id);
    return (
      <form
        onSubmit={this._formHandler.$b.handleFormSubmitEvent}
        style={props.style}
        className={classNames(
          props.className,
          'layout--positioned-box',
          'flex--container-vertical',
          'flex--item-fill',
        )}
      >
        <div
          className={classNames(
            'flex--container-vertical',
            'flex--item-fill',
          )}
        >
          {/* if */(state.fields.$all.error) ? (
            <p>{state.fields.$all.error}</p>
          )/* endif */ : null}
          <div
            className={classNames(
              'flex--container-horizontal',
              'flex--item-fixed',
            )}
          >
            <div
              className={classNames(
                'flex--container-vertical',
                'flex--item-fill',
                'layout--padding-right-small',
              )}
            >
              <Input
                name='name'
                label='Full Name'
                value={state.fields.name.value}
                onChange={changeHandlers.name}
                required={true}
                inlineHelpText="The token's full name"
                errorText={state.fields.name.error}
              />
              <Input
                name='shortName'
                label='Short Name'
                value={state.fields.shortName.value}
                onChange={changeHandlers.shortName}
                placeholder={state.fields.name.value}
                inlineHelpText="A short name for the token displayed on the map"
                errorText={state.fields.shortName.error}
              />
            </div>
            <div
              className={classNames(
                'flex--container-vertical',
                'flex--item-fixed',
                'layout--padding-left-small',
                'layout--width-4-12',
              )}
            >
              <TokenImage
                className='tokens--form-token-image layout--padding-all-small clickable'
                src={curImageUrl}
                annotation={state.fields.annotation.value}
                border={state.fields.borderColor.value ? {style: 'RING_BORDER_STYLE', color: state.fields.borderColor.value} : null}
                onClick={this.$b._handleEditTokenImage}
              />
              <Button
                label='Change'
                variant='neutral'
                type='button'
                onClick={this.$b._handleEditTokenImage}
              />
            </div>
          </div>
          <Input
            name='annotation'
            label='Annotation'
            value={state.fields.annotation.value}
            onChange={changeHandlers.annotation}
            inlineHelpText="Overlayed on the token."
            errorText={state.fields.annotation.error}
          />
          <Input
            name='borderColor'
            label='Border Color'
            value={state.fields.borderColor.value}
            onChange={changeHandlers.borderColor}
            placeholder='No border'
            inlineHelpText="Optional border color for the image. Leave blank for no border"
            errorText={state.fields.borderColor.error}
          />
          <div
            className={classNames(
              'flex--container-horizontal',
              'flex--item-fixed',
            )}
          >
            <SinglePicklist
            className='flex--item-fill-6-12 layout--margin-right-xsmall'
              label='Token Size'
              value={state.fields.sizePreset.value}
              onChange={changeHandlers.sizePreset}
              options={SIZE_PRESET_OPTIONS}
            />
            <Input
              className='flex--item-fill-2-12 layout--margin-right-xsmall'
              name='width'
              label='Width'
              value={state.fields.width.value}
              onChange={changeHandlers.width}
              errorText={state.fields.width.error}
              fieldLevelHelpTooltip={
                <Tooltip
                  position="overflowBoundaryElement"
                  content="How many cells wide the token is"
                />
              }
            />
            <Input
              className='flex--item-fill-2-12 layout--margin-right-xsmall'
              name='height'
              label='Height'
              value={state.fields.height.value}
              onChange={changeHandlers.height}
              errorText={state.fields.height.error}
              fieldLevelHelpTooltip={
                <Tooltip
                  position="overflowBoundaryElement"
                  content="How many cells tall the token is"
                />
              }
            />
            <Input
              className='flex--item-fill-2-12'
              name='imageScale'
              label='Scale'
              value={state.fields.imageScale.value}
              onChange={changeHandlers.imageScale}
              errorText={state.fields.imageScale.error}
              fieldLevelHelpTooltip={
                <Tooltip
                  position="overflowBoundaryElement"
                  content="How much of the token's space will be taken up by its image. A value of 1 will cause the image to fill the token's full space (without being distorted)."
                />
              }
            />
          </div>
          <SinglePicklist
            label='Faction'
            value={state.fields.faction.value}
            onChange={changeHandlers.faction}
            options={FACTION_OPTIONS}
          />
          <Textarea
            name='description'
            label='Description'
            value={state.fields.description.value}
            onChange={changeHandlers.description}
            errorText={state.fields.description.error}
            fieldLevelHelpTooltip={
              <Tooltip
                position="overflowBoundaryElement"
                content="An optional description of this token that is accessible to players"
              />
            }
          />
        </div>
        {/* if */(state.confirmDelete) ? (
          <layouts.TwoButtonPanel className='flex--item-fixed layout--margin-top-small'>
            <div className='flex--container-horizontal flex--item-fixed'>
              <Button
                label='Cancel'
                variant='neutral'
                type='button'
                onClick={this.$b._handleDeleteCancel}
                disabled={this.state.async.isRunning}
              />
              <Button
                label='Delete'
                variant='destructive'
                type='button'
                disabled={this.state.async.isRunning}
                onClick={this.$b._handleDelete}
              />
            </div>
            <div>Confirm Delete?</div>
          </layouts.TwoButtonPanel>

        )/* else */ : (
          <layouts.TwoButtonPanel className='flex--item-fixed layout--margin-top-small'>
            <div className='flex--container-horizontal flex--item-fixed'>
              {/* if */(!isNew) ? (
                <Button
                  label='Delete'
                  variant='destructive'
                  type='button'
                  disabled={this.state.async.isRunning}
                  onClick={this.$b._handleDeleteNeedsConfirm}
                />
              )/* endif */ : null}
              <Button
                label={isNew ? 'Create' : 'Update'}
                variant='brand'
                type='submit'
                disabled={this.state.async.isRunning}
              />
            </div>
            <Button
              label='Cancel'
              variant='neutral'
              type='button'
              onClick={this.$b._handleCancel}
              disabled={this.state.async.isRunning}
            />
          </layouts.TwoButtonPanel>
        )/* endif */}
      </form>
    );
  }

  _renderFormTokenImage() {
    const props = this.props;
    const state = this.state;
    return (
      <div
        style={props.style}
        className={classNames(
          props.className,
          'layout--positioned-box',
          'flex--container-vertical',
          'flex--item-fill',
        )}
      >
        <ImageManager
          className='flex--item-fill'
          defaultCategories={DEFAULT_IMAGE_CATEGORIES}
          ownerId={props.campaignId}
          ownerType='campaign'
          onSubmit={this.$b._handleImageSelect}
          onCancel={this.$b._handleImageCancel}
          initialSelectedImageId={state.fields.imageId.value}
        />
      </div>
    );
  }

}
