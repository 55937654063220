import React from 'react';

import {Dropdown} from '@salesforce/design-system-react';
import {Spinner} from '@salesforce/design-system-react';

import AppGlobal from 'global';
import AppReactComponent from 'utils/app-react-component';
import UserInfo from 'utils/user-info';


/**
 * A user menu component connected to the global UserInfo data.
 */
export default class UserMenu extends AppReactComponent {

  constructor(props) {
    super(props);
    this.connect('userInfo', UserInfo);
    this.connect('async', UserInfo, 'async');
    this.addSetup(this.$b.requestUserInfoLoad);
  }

  requestUserInfoLoad() {
    UserInfo.load();
  }

  render() {
    const state = this.state;
    return UserMenuView({
      async: state.async,
      userInfo: state.userInfo,
    });
  }

}


function UserMenuView({
  async,
  userInfo,
}) {
  // Display a loading indicator if the user info is not yet loaded
  if (!userInfo || async.isRunning) {
    return _UserMenuLoadingView();
  } else if (!userInfo.name) {
    return _UserMenuUnauthenticatedView();
  } else {
    return _UserMenuAuthenticatedView({
      userInfo: userInfo,
    });
  }
}


function _UserMenuLoadingView() {
  return (
    <Spinner
      size='small'
      variant='base'
      isInline={true}
      containerStyle={{position: 'relative', width: '100px'}}
    />
  );
}


function _UserMenuUnauthenticatedView() {
  // Note that these use traditional links instead of ReactRouter links because
  // it can be useful to force a true navigation between the login app and
  // anywhere else.
  return (
    <React.Fragment>
      <a
        className='slds-m-left_small slds-button slds-button_neutral'
        href='/app/auth/login'
      >
        Login
      </a>
      <a
        className='slds-m-left_small slds-button slds-button_brand'
        href='/app/auth/new-user'
      >
        Sign Up
      </a>
    </React.Fragment>
  );
}


function _UserMenuAuthenticatedView({
  userInfo,
}) {
  // TODO: Replace the dropdown menu content with a user image
  // TODO: Add the user's name as `Logged in as: ...` to the menu so that
  // the user can see the name that they are logged in as.
  return (
    <Dropdown
      align='right'
      iconCategory='utility'
      iconName='down'
      iconPosition='right'
      label={userInfo.name}
      onSelect={(option) => {
        if (option.value === 'logout') {
          window.location.assign(option.href);
        } else {
          AppGlobal.rootRouter.history.push(option.href);
        }
      }}
      options={[
        {
          href: '/app/auth/logout',
          label: 'Logout',
          value: 'logout',
        },
        {
          href: '/app/auth/session',
          label: 'Session',
          value: 'session',
        },
      ]}
    />
  );
}
