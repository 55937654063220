import CanvasUtils from 'utils/graphics/canvas-utils';
import {shallowEqual} from 'utils/types';

import Bitmap from './bitmap';

const DEFAULT_DOCUMENT = document;
const IDENTITY_MATRIX = CanvasUtils.IDENTITY_MATRIX;

/**
 * A bitmap that is dynamic based on the input parameters described by PT. This
 * acts as sort of a cache for image data that needs to be modified in some way
 * (such as scaled, drawn over, etc). So long as these parameters are modified
 * infrequently relative to how often the data is drawn, this can provide a
 * large performance increase.
 *
 * Since this only the data found in the rendering params are used for caching,
 * it's important not to use any closure data in the actual drawing callbacks.
 *
 * Note that, unlike `Bitmap`, all refreshes are synchronous since this uses a
 * canvas element.
 */
export default class DynamicBitmap<PT> {

  protected _isDestroyed: boolean = false;
  protected _lastParams: PT | null = null;
  protected _lastHasSource: boolean = false;
  protected _source: Bitmap;
  protected _contextOptions: CanvasRenderingContext2DSettings;
  protected _getSize: (params: PT) => [w: number, h: number];
  protected _draw: (context: CanvasRenderingContext2D, source: Bitmap, params: PT) => void;
  protected _drawNoSource: (context: CanvasRenderingContext2D, params: PT) => void;
  protected _canvas: HTMLCanvasElement;

  /**
   * Create and configure a new DynamicBitmap
   *
   * - options.document is an optional `Document` object that can be used for
   *   dependency injection. If not provided, the global `document` will be
   *   used
   * - options.contextOptions are optional settings that will be used when
   *   getting the 2D drawing context for the canvas.
   * - options.getSize is a function that will return the expected width and
   *   height of the resulting bitmap
   * - options.draw is a function that should actually draw the bitmap. Remember
   *   that it should ONLY use the parameters passed in and should not rely on
   *   any other state.
   * - options.drawNoSource is an optional draw function that will be called if
   *   the source BitMap is not ready or unavailable.
   */
  constructor(source: Bitmap, options: {
    contextOptions?: CanvasRenderingContext2DSettings,
    document?: Document,
    draw: (context: CanvasRenderingContext2D, source: Bitmap, params: PT) => void,
    drawNoSource?: (context: CanvasRenderingContext2D, params: PT) => void,
    getSize: (params: PT) => [w: number, h: number],
  }) {
    const document = options.document || DEFAULT_DOCUMENT;
    this._source = source;
    this._getSize = options.getSize;
    this._draw = options.draw;
    this._drawNoSource = options.drawNoSource;
    this._contextOptions = options.contextOptions || {};
    this._canvas = document.createElement('canvas');
  }

  get width() {
    return this._canvas.width;
  }

  get height() {
    return this._canvas.height;
  }

  get hasSource() {
    return this._source.isReady && !this._source.isDestroyed;
  }

  destroy() {
    if (this._isDestroyed) {
      return;
    }
    this._canvas.remove();
    this._canvas = null;
    this._getSize = null;
    this._draw = null;
    this._drawNoSource = null;
    this._source = null;
    this._lastParams = null;
    this._isDestroyed = true;
  }

  refresh(drawParams: PT): void {
    if (
      this._lastHasSource !== this.hasSource ||
      this._lastParams === null ||
      !shallowEqual(drawParams, this._lastParams)
    ) {
      this.refreshForce(drawParams);
    }
  }

  refreshForce(drawParams: PT): void {
    const [newWidth, newHeight] = this._getSize(drawParams);
    if (this._canvas.width !== newWidth || this._canvas.height !== newHeight) {
      this._canvas.width = newWidth;
      this._canvas.height = newHeight;
    }
    if (!this.hasSource) {
      this._execDrawNoSource(drawParams);
    } else {
      this._execDraw(drawParams);
    }
    this._lastHasSource = this.hasSource;
    this._lastParams = drawParams;
  }

  protected _execDrawNoSource(drawParams: PT): void {
    const context = this._getContext();
    context.clearRect(0, 0, this._canvas.width, this._canvas.height);
    context.save();
    try {
      if (this._drawNoSource) {
        this._drawNoSource(context, drawParams);
      } else {
        context.fillStyle = '#0000FF';
        context.fillRect(0, 0, this._canvas.width, this._canvas.height);
      }
    } finally {
      context.restore();
    }
  }

  protected _execDraw(drawParams: PT): void {
    const context = this._getContext();
    context.clearRect(0, 0, this._canvas.width, this._canvas.height);
    context.save();
    try {
      this._draw(context, this._source, drawParams);
    } finally {
      context.restore();
    }
  }

  protected _getContext(): CanvasRenderingContext2D {
    return this._canvas.getContext('2d', this._contextOptions);
  }

  /**
   * draws the entire dynamic bitmap to the specified X and Y coordinates. If
   * supplied, w and h will be used to scale the image.
   */
  draw(context: CanvasRenderingContext2D, x: number, y: number): void;
  draw(context: CanvasRenderingContext2D, x: number, y: number, w: number, h: number): void;
  draw(context: CanvasRenderingContext2D, x: number, y: number, w?: number, h?: number): void {
    if (this.width === 0 || this.height === 0) {
      console.info('Attempted to draw dynamic bitmap with no data');
      return;
    }
    context.drawImage(
      this._canvas,
      0,
      0,
      this.width,
      this.height,
      x,
      y,
      w !== undefined ? w : this.width,
      h !== undefined ? h : this.height,
    );
  }

  /**
   * Similar to `draw` but resets the transform first
   */
  drawDirect(context: CanvasRenderingContext2D, x: number, y: number): void;
  drawDirect(context: CanvasRenderingContext2D, x: number, y: number, w: number, h: number): void;
  drawDirect(context: CanvasRenderingContext2D, x: number, y: number, w?: number, h?: number): void {
    context.save();
    try {
      context.setTransform(IDENTITY_MATRIX);
      this.draw(context, x, y, w, h);
    } finally {
      context.restore();
    }
  }

}
