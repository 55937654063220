import statelib from 'statelib';


export default class EmbarkToolsState extends statelib.FState {

  static TOOL_MOVE = 'TOOL_MOVE';
  static TOOL_SELECT = 'TOOL_SELECT';
  static TOOL_TOKENS = 'TOOL_SELECT';
  static TOOL_VIS_OVERRIDE = 'TOOL_VIS_OVERRIDE';

  static OPTION_DRAW_SHAPE_GRID_BRUSH = 'OPTION_DRAW_SHAPE_GRID_BRUSH';
  static OPTION_DRAW_SHAPE_RECT = 'OPTION_DRAW_SHAPE_RECT';

  static OPTION_VIS_HIDE = 'OPTION_VIS_HIDE';
  static OPTION_VIS_DARK = 'OPTION_VIS_DARK';
  static OPTION_VIS_LIGHT = 'OPTION_VIS_LIGHT';

  static OPTION_SHAPE_BRUSH = 'OPTION_SHAPE_BRUSH';
  static OPTION_SHAPE_RECT = 'OPTION_SHAPE_RECT';

  static DEFAULT_NAME = 'UnknownEmbarkTools';

  get tool() {
    return this.getValue('tool');
  }

  get isImpersonatingPlayer() {
    return this.getValue('isImpersonatingPlayer');
  }

  get showGrid() {
    return this.getValue('showGrid');
  }

  makeInitialData() {
    // `currentOptions` is a way to set both the default options and remember
    // the user's selection if they switch between tools.
    return {
      tool: {
        id: EmbarkToolsState.TOOL_MOVE,
        options: {},
      },
      currentOptions: {
        [EmbarkToolsState.TOOL_MOVE]: {},
        [EmbarkToolsState.TOOL_VIS_OVERRIDE]: {
          shape: EmbarkToolsState.OPTION_DRAW_SHAPE_GRID_BRUSH,
          operation: EmbarkToolsState.OPTION_VIS_LIGHT,
        },
      },
      showGrid: false,
      isImpersonatingPlayer: false,
      noMapVisible: false,
    };
  }

  setTool(toolId) {
    const prevToolId = this.data.tool.id;
    this.applyMany([
      {
        action: FState.ACTION_PATCH_VALUE,
        data: {
          name: 'currentOptions',
          value: {
            ...this.data.currentOptions,
            [prevToolId]: this.data.tool.options,
          },
        },
      },
      {
        action: FState.ACTION_SET_VALUE,
        data: {
          name: 'tool',
          value: {
            id: toolId,
            options: this.data.currentOptions[toolId],
          }
        }
      }
    ]);
  }

  setToolOptions(options) {
    this.patchValue('tool', {options: options});
  }

  patchToolOptions(options) {
    const nextOptions = {...this.data.tool.options, ...options};
    this.patchValue('tool', {options: nextOptions});
  }

  setToolOption(name, value) {
    const curValue = this.data.tool.options[name];
    if (value !== curValue) {
      const nextOptions = {...this.data.tool.options, [name]: value};
      this.patchValue('tool', {options: nextOptions});
    }
  }

  setShowGrid(value) {
    this.setValueIfChanged('showGrid', !!value);
  }

  setIsImpersonatingPlayer(value) {
    this.setValueIfChanged('isImpersonatingPlayer', !!value);
  }

  setNoMapVisible(value) {
    this.setValueIfChanged('noMapVisible', !!value);
  }

}
