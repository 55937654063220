import React from 'react';
import { withRouter } from 'react-router';
import qs from 'qs';

import CenteredBox from 'components/layouts/centered-box';
import NewUserForm from 'components/authentication/new-user-form';
import AppPageTemplateConnected from 'components/templates/app-page-template-connected';

import QueuedAsyncState from 'statelib/queued-async-state';
import ReactComponent from 'utils/react-component';
import {connectToComponentState} from 'statelib/fstate-react';
import {makeRpcCall} from 'utils/async';
import {users as usersPb} from 'proto-bundle';

const callNewUserRpc = makeRpcCall(
  '/rpc/users/v1/new_user',
  usersPb.User,
  usersPb.User,
);


class NewUserPage extends ReactComponent {

  constructor(props) {
    super(props);
    this.async = new QueuedAsyncState('NewUserPageAsync');
    this.addCleanup(() => this.async.close());
    connectToComponentState(this.async, this, 'async')
  }

  handleFormSubmit = async (formData, form) => {
    const queryParams = (new URL(document.location)).searchParams;
    const inviteParam = queryParams.get('invite');
    try {
      form.clearFormFieldErrors();
      let res = await this.async.wrap(async () => {
        return await callNewUserRpc({
          emailAddress: formData.emailAddress,
          name: formData.name,
          password: {value: formData.password},
          registrationCode: inviteParam ? {value: inviteParam} : null,
        });
      })
      console.log('Create user success', res);
      this.props.history.push('/app/auth/login');
    } catch(err) {
      console.error('Create user failed', err);
      form.setFormFieldError('$all', `${err}`);
    }
  }

  render() {
    return (
      <AppPageTemplateConnected
        headerVisible={true}
        footerVisible={false}
      >
        <CenteredBox className='border-box' style={{width: '400px'}}>
          <h3 className="slds-text-heading_medium">Create new User</h3>
          <NewUserForm
            onSubmit={this.handleFormSubmit}
            isLoading={this.state.async.isRunning}
          />
        </CenteredBox>
      </AppPageTemplateConnected>
    );
  }
}


export default withRouter(NewUserPage);
