import Memoize from 'memoize-one';

import AppGlobal from 'global';

import FStateListener from 'statelib/fstate-listener';
import FStateWithAsync from 'statelib/fstate-with-async';
import BaseAsyncState from 'statelib/base-async-state';
import MapsRPCAPI from 'apis/maps-rpc-api';

import {common as commonPb} from 'proto-bundle';

const _Access = commonPb.AccessLevel;
const ACCESS_UNKNOWN_ACCESS_LEVEL = _Access[_Access.UNKNOWN_ACCESS_LEVEL];
const ACCESS_VIEW_PUBLIC = _Access[_Access.VIEW_PUBLIC];
const ACCESS_VIEW_ALL = _Access[_Access.VIEW_ALL];
const ACCESS_CONTROL = _Access[_Access.CONTROL];


export default class MapDetailsStateRef extends FStateWithAsync {

  static factory = () => new MapDetailsStateRef();

  constructor(options) {
    super(options);
    this._allowedIds = null;
    this._zoneListener = new FStateListener(this.$b._onZoneData);
  }

  get async() {
    return this.getValue('async');
  }

  set mapId(value) {
    value = value || null;
    if (value !== this.data.mapId) {
      if (value === null) {
        console.log(`map-details[${this.name}] clear map`);
        this.resetData();
      } else if (typeof value !== 'string') {
        throw new Error(`Expected id to be a string, got ${value}`);
      } else {
        this.patchData({
          ...this.makeInitialData(),
          mapId: value,
        });
        this.refresh();
      }
    }
  }

  get mapId() {
    return this.getValue('mapId');
  }

  get map() {
    return this.getValue('map');
  }

  get zones() {
    return this.getValue('zones');
  }

  get defaultZoneId() {
    return this.getValue('defaultZoneId');
  }

  destroy() {
    this._allowedIds = null;
    this._zoneListener.destroy();
    this._zoneListener = null;
    super.destroy();
  }

  makeInitialData() {
    return {
      defaultZoneId: null,
      map: null,
      mapId: null,
      zones: null,
    };
  }

  listenToZoneState(mapZoneRef) {
    this._zoneListener.listen(mapZoneRef, 'mapZone');
  }

  resetAllButMapId() {
    this.patchData({
      map: null,
      zones: null,
      defaultZoneId: null,
    });
  }

  setAllowedIds(ids) {
    const curId = this.mapId;
    const prevIds = this._allowedIds;
    const prevIncluded = !!(prevIds === null || prevIds.includes(curId));
    const nextIds = ids ? [...ids] : null;
    const nextIncluded = !!(nextIds === null || nextIds.includes(curId));
    this._allowedIds = nextIds;
    if (prevIncluded !== nextIncluded) {
      this.refresh();
    }
    return nextIncluded;
  }

  /**
   * Moves a map zone to a different spot in collection.
   */
  async moveMapZone(moveZoneId, afterZoneId, commit) {
    const prevZones = this.zones;
    const nextZones = [];
    let moveZone = null;
    for (const mapZone of prevZones) {
      if (mapZone.id === moveZoneId) {
        moveZone = mapZone;
        break;
      }
    }
    if (moveZone === null) {
      throw new Error(`Zone ${moveZoneId} is not associated with this map`);
    }
    let placed = false;
    // Special case for inserting to the beginning of the list
    if (afterZoneId === null) {
      nextZones.push(moveZone);
      placed = true;
    }
    // Insert all other items
    for (const mapZone of prevZones) {
      if (mapZone.id !== moveZoneId) {
        nextZones.push(mapZone);
      }
      if (mapZone.id === afterZoneId) {
        nextZones.push(moveZone);
        placed = true;
      }
    }
    if (!placed) {
      throw new Error(`Zone ${afterZoneId} is not associated with this map`);
    }
    if (commit) {
      await this._async.wrap(async (throwIfCanceled) => {
        await MapsRPCAPI.reorderMapZones({
          id: this.mapId,
          zones: nextZones,
        });
      });
    }
    this.setValue('zones', nextZones);
  }

  /**
   * Updates basic information about the map. Note that most fields will be
   * ignored outside of standard metadata.
   */
  async patchDetails(map, commit) {
    const prevMap = this.map;
    const nextMap = {...prevMap, ...map};
    if (commit) {
      const async = this.getAttachedState('async');
      await async.wrap(async (throwIfCanceled) => {
        const result = await MapsRPCAPI.patchMap({
          ...map,
          id: this.mapId,
        });
        throwIfCanceled();
        Object.assign(nextMap, result);
      });
    }
    this.setValue('map', nextMap);
    // Background refresh campaign details.
    // TODO: This probably shouldn't point to AppGlobal
    AppGlobal.campaignDetails.patchMapById(nextMap.id, nextMap);
  }

  async refresh(reset) {
    const mapId = this.mapId;
    try {
      console.log(`map-details[${this.name}] refreshing map ${mapId}`);
      await this._async.wrap(async (throwIfCanceled) => {
        if (!mapId || !(this._allowedIds === null || this._allowedIds.includes(mapId))) {
          // Reset and exit early if the map id is invalid
          this.resetAllButMapId();
          return;
        } else if (reset) {
          // Reset internal state if requested.
          this.resetAllButMapId()
        }
        // Get the map details
        const getMapsResult = await MapsRPCAPI.getMaps({mapIds: [mapId]});
        throwIfCanceled();
        // Exit early if the map id is not found.
        // TODO: Should this raise an error instead of return?
        if (!getMapsResult.maps || getMapsResult.maps.length < 1) {
          this.resetAllButMapId();
          throw new Error(`Could not find map with id ${mapId}`)
        }
        this.setValue('map', getMapsResult.maps[0]);
        console.log(`map-details[${this.name}] loaded map`, this.map);
        // Get the map zone details
        const zones = await AppGlobal.mapZoneLiteStore.getZonesForMap(mapId);
        throwIfCanceled();
        this.patchData({
          zones: zones,
          defaultZoneId: zones.length ? zones[0].id : null,
        });
        console.log(`map-details[${this.name}] loaded map zone ids`, this._zoneIds);
      });
    } catch (err) {
      if (!BaseAsyncState.isCanceled(err)) {
        console.warn(`Unable to load map ${mapId}`, err);
        // Do not re-raise, rely on error stored in async state.
      }
    }
  }

  /**
   * Internally updates the metadata about a map zones
   */
  patchZoneById(mapZoneId, mapZoneData) {
    if (!this.zones) {
      return;
    }
    const prevZones = this.zones;
    const nextZones = [];
    let isChanged = false;
    for (const zone of prevZones) {
      if (zone.id === mapZoneId) {
        const nextZone = {...zone};
        if (mapZoneData.name !== undefined) {
          nextZone.name = mapZoneData.name;
        }
        if (mapZoneData.publicName !== undefined) {
          nextZone.name = mapZoneData.name;
        }
        if (mapZoneData.public !== undefined) {
          nextZone.public = mapZoneData.public;
        }
        nextZones.push(nextZone);
        isChanged = true;
      } else {
        nextZones.push(zone);
      }
    }
    if (isChanged) {
      this.setValue('zones', nextZones);
    }
  }

  /**
   * Called when an authoritative map zone state is updated
   */
  _onZoneData(mapZoneData){
    if (mapZoneData && mapZoneData.id) {
      this.patchZoneById(mapZoneData.id, mapZoneData);
    }
  }

  getPermissions(asPlayer) {
    return this._getPermissionsForMap(this.map, asPlayer);
  }

  /**
   * Caching function for generating a permissions object for the map.
   *
   * Note that it is campaign role that affects whether a user is allowed to
   * impersonate a user (as well as some other permissions)
   */
  _getPermissionsForMap = Memoize((map, asPlayer) => {
    let access;
    if (!map) {
      access = ACCESS_UNKNOWN_ACCESS_LEVEL;
    } else if (asPlayer) {
      access = ACCESS_VIEW_PUBLIC;
    } else {
      access = map.accessLevel || ACCESS_UNKNOWN_ACCESS_LEVEL;
    }
    return {
      CAN_VIEW_FULL_MAP: [ACCESS_VIEW_ALL, ACCESS_CONTROL].includes(access),
      CAN_MODIFY_EXPLORATION: [ACCESS_CONTROL].includes(access),
      CAN_MODIFY_VISIBILITY: [ACCESS_CONTROL].includes(access),
    };
  });

}
