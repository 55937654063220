import React from 'react';
import {withRouter} from 'react-router';

import shared from 'global';
import MapDetailsStateRef from 'apps/maps/state/map-details-state-ref';


/**
 * Provides router-based Shared Map Details.
 */
class SharedMapDetailsLoader extends React.PureComponent {

  constructor() {
    super();
    shared.provideFactory('mapDetails', MapDetailsStateRef.factory);
  }

  componentDidMount() {
    shared.mapDetails.mapId = this.props.match.params.mapId;
  }

  componentDidUpdate(prevProps) {
    shared.mapDetails.mapId = this.props.match.params.mapId;
  }

  render() {
    return this.props.children;
  }

}


export default withRouter(SharedMapDetailsLoader);
