import UAParser from 'ua-parser-js';

// const _ONE_PIXEL_IMAGE = new Image(1, 1);

const _createImageBitmap = window['createImageBitmap'];
const _createElement = window['document']['createElement'].bind(window['document']);
const _testCanvas = _createElement('canvas');
_testCanvas.width = 1;
_testCanvas.height = 1;


class _UserAgent {

  constructor(userAgent) {
    this.userAgent = userAgent;
    this.browser = Object.freeze(this.userAgent.getBrowser());
    this.browserName = this.browser.name;
    this.browserVersion = this.browser.version;
    this.os = (this.userAgent.getOS().name || '?').toLowerCase();
    this.uiStyleMac = ["ios", "mac os"].includes(this.os);
    this.uiStyleWindows = !this.uiStyleMac;
    this._initCreateImageBitmapSupport();
  }

  async _initCreateImageBitmapSupport() {
    this.isCreateImageBitmapSupported = !!(_createImageBitmap);
    this.isCreateImageBitmapResizeSupported = false;
    if (this.isCreateImageBitmapSupported) {
      try {
        await _createImageBitmap(_testCanvas, {
          resizeWidth: 2,
          resizeHeight: 2,
          resizeQuality: 'low',
        });
        this.isCreateImageBitmapResizeSupported = true;
      } catch (err) {
        console.info('createImageBitmap resize is unsupported', err);
        this.isCreateImageBitmapResizeSupported = false;
      }
    }
  }

}


const UserAgent = new _UserAgent(new UAParser(window.navigator.userAgent));
window['AppUserAgent'] = UserAgent;
export default UserAgent;
