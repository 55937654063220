import React from 'react';
import PropTypes from 'prop-types';

import {Input} from '@salesforce/design-system-react';
import {Button} from '@salesforce/design-system-react';

import FlexBarLayout from 'components/layouts/flex-bar-layout';

import FormHandler from 'formlib/form-handler';
import ReactComponent from 'utils/react-component';


class NewUserFormHandler extends FormHandler {

  refreshForm(data) {
    this.updateFormFields({
      name: data.name || '',
      emailAddress: data.emailAddress || '',
      username: data.username || '',
      password: data.password || '',
    });
  }

  setName = (event, data) => {
    this.setFormFieldAndData('name', data.value);
  }

  setEmailAddress = (event, data) => {
    this.setFormFieldAndData('emailAddress', data.value);
  }

  setUsername = (event, data) => {
    this.setFormFieldAndData('username', data.value);
  }

  setPassword = (event, data) => {
    this.setFormFieldAndData('password', data.value);
  }

}


export default class NewUserForm extends ReactComponent {

  constructor(props) {
    super(props);
    this._formHandler = new NewUserFormHandler('NewUserForm');
    this.addCleanup(
      () => this._formHandler.close(),
      this._formHandler.connectFieldsToComponentState(this, 'fields'),
      this._formHandler.connectSubmitToComponentProp(this, 'onSubmit'),
    );
  }

  render() {
    const fields = this.state.fields;
    const handler = this._formHandler;
    return (
      <form onSubmit={handler.$b.handleFormSubmitEvent}>
        {/* if */(fields.$all.error) ? (
          <div className='layout--info-container-box layout--center-contents'>
            {fields.$all.error}
          </div>
        )/* end if */ : null}
        <Input
          name='name'
          label='Name'
          value={fields.name.value}
          onChange={handler.setName}
          disabled={this.props.isLoading}
          required={true}
          autoComplete='name'
        />
        <Input
          name='emailaddress'
          label='Email Address'
          value={fields.emailAddress.value}
          onChange={handler.setEmailAddress}
          disabled={this.props.isLoading}
          required={true}
          autoComplete='username'
        />
        <Input
          name='password'
          label='Password'
          value={fields.password.value}
          onChange={handler.setPassword}
          disabled={this.props.isLoading}
          required={true}
          type='password'
          autoComplete='new-password'
        />
        <FlexBarLayout className='slds-p-top_large'>
          <Button
            disabled={this.props.isLoading}
            className='big-button'
            label={this.props.isLoading ? 'Creating new user...' : 'Create User'}
            variant='brand'
            type='submit'
          />
        </FlexBarLayout>
      </form>
    );
  }
}


NewUserForm.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};
