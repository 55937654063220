import React from 'react';

import {Input} from '@salesforce/design-system-react';
import {Checkbox} from '@salesforce/design-system-react';
import {Button} from '@salesforce/design-system-react';

import FlexBarLayout from 'components/layouts/flex-bar-layout';

import QueuedAsyncState from 'statelib/queued-async-state';
import FormHandler from 'formlib/form-handler';
import ReactComponent from 'utils/react-component';
import MapsRPCAPI from 'apis/maps-rpc-api';
import {connectToComponentState} from 'statelib/fstate-react';


function NewCampaignFormView({
  async,
  handleSubmit,
  fields,
  handleCancelClick,
  handleFieldChangeActive,
  handleFieldChangeDescription,
  handleFieldChangeName,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Input
        name='campaignName'
        label='Name'
        value={fields.name.value}
        onChange={handleFieldChangeName}
        disabled={async.isRunning}
        errorText={fields.name.error}
        required={true}
      />
      <Checkbox
        labels={{
          label: 'Is Active',
          toggleDisabled: 'Inactive',
          toggleEnabled: 'Active',
        }}
        variant='toggle'
        checked={fields.active.value}
        onChange={handleFieldChangeActive}
        disabled={async.isRunning}
      />
      <Input
        name='description'
        label='Description'
        value={fields.description.value}
        onChange={handleFieldChangeDescription}
        disabled={async.isRunning}
      />
      <FlexBarLayout className='slds-p-top_large'>
        <Button
          disabled={async.isRunning}
          className='big-button'
          label={async.isRunning ? 'Saving...' : 'Create Campaign'}
          variant='brand'
          type='submit'
        />
      </FlexBarLayout>
      <FlexBarLayout className='slds-p-top_large'>
        <Button
          disabled={async.isRunning}
          className='big-button'
          label={'Cancel'}
          variant='outline-brand'
          type='button'
          onClick={handleCancelClick}
        />
      </FlexBarLayout>
    </form>
  );
}


export default class NewCampaignForm extends ReactComponent {

  constructor() {
    super();
    this.async = new QueuedAsyncState();
    this.formHandler = new FormHandler(this.$b._formRefresh);
    this.addCleanup(
      connectToComponentState(this.async, this, 'async'),
      this.async.$b.close,
      this.formHandler.connectFieldsToComponentState(this, 'fields'),
      this.formHandler.onSubmit(this.$b._formSubmit),
      this.formHandler.$b.close,
    );
  }

  _formRefresh(campaign) {
    return {
      name: campaign.name || '',
      active: campaign.active || false,
      description: campaign.description || '',
    };
  }

  async _formSubmit(formEvent) {
    const campaignData = this.formHandler.getData();
    const response = await MapsRPCAPI.putCampaign(campaignData, true);
    this.props.onSuccess(response);
  }

  _handleCancelClick(event) {
    this.props.onCancel();
  }

  _handleFieldChangeName(event, data) {
    this.formHandler.setFormFieldAndData('name', data.value);
  }

  _handleFieldChangeActive(event, data) {
    this.formHandler.setFormFieldAndData('active', data.checked);
  }

  _handleFieldChangeDescription(event, data) {
    this.formHandler.setFormFieldAndData('description', event.target.value);
  }

  render() {
    return NewCampaignFormView({
      async: this.state.async,
      fields: this.state.fields,
      handleCancelClick: this.$b._handleCancelClick,
      handleFieldChangeActive: this.$b._handleFieldChangeActive,
      handleFieldChangeDescription: this.$b._handleFieldChangeDescription,
      handleFieldChangeName: this.$b._handleFieldChangeName,
      handleSubmit: this.formHandler.$b.handleFormSubmitEvent,
    });
  }

}
