import React from 'react';
import {
  Route,
  Switch,
  BrowserRouter,
} from 'react-router-dom';
import lodashOnce from 'lodash.once';
import {SLDSSettings, IconSettings} from '@salesforce/design-system-react';

import RouterState from 'statelib/router-state';
import shared from 'global';
import initMapsAppSingletons from 'apps/maps/init-app-singletons';
import {shallowEqual} from 'utils/types';

import NotFound from './not-found-page';
import MapsPages from './maps/index';
import AuthPages from './auth/index';

// Set up the global router information
shared.provideFactory('rootRouter', () => new RouterState());

// Set up shared content for the global maps app
initMapsAppSingletons();

// Create a function that initializes the app once
const initializeAppElement = lodashOnce(() => {
  SLDSSettings.setAppElement('#root');
});

/**
 * Makes sure that `router` information is available in shared state.
 *
 * Note that, since matching isn't done until later, it's not possible to
 * globally store match information (also, it doesn't actually make sense
 * since at least the top level component should just run with router and pull
 * all useful match information out at that point).
 *
 * This, however, means that we don't need a bunch of components to include
 * routing if all that they need to do is push/replace history.
 */
class SharedStateRouter extends BrowserRouter {

  constructor(props) {
    super(props);
    this.history.listen((location, action) => {
      shared.rootRouter.location = location;
    });
    shared.rootRouter.history = this.history;
    shared.rootRouter.location = this.history.location;
  }

}


class SharingRoute extends Route {

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!shallowEqual(prevProps.computedMatch, this.props.computedMatch)) {
      shared.rootRouter.updateMatch(this.props.computedMatch);
    }
    super.componentDidUpdate(prevProps, prevState, snapshot);
  }

  componentDidMount() {
    shared.rootRouter.updateMatch(this.props.computedMatch);
    super.componentDidMount();
  }

}


export default function AppRouter(props) {
  initializeAppElement();
  console.log('Rendering App Router');
  return (
    <IconSettings iconPath='/static/icons'>
      {/*<React.StrictMode>*/}
        <SharedStateRouter>
          <Switch>
            <SharingRoute exact path='/app/maps/new-campaign'
              component={MapsPages.NewCampaignPage}
            />
            <SharingRoute exact path='/app/maps/campaigns'
              component={MapsPages.CampaignsListPage}
            />
            <SharingRoute exact path='/app/maps/campaigns/:campaignId'
              component={MapsPages.CampaignsListPage}
            />
            <SharingRoute exact path='/app/maps/campaigns/:campaignId/:campaignSection'
              component={MapsPages.CampaignsListPage}
            />
            <SharingRoute exact path='/app/maps/new'
              component={MapsPages.NewMapPage}
            />
            <SharingRoute exact path='/app/maps/:mapId/details'
              component={MapsPages.MapDetailsPage}
            />
            <SharingRoute exact path='/app/maps/:mapId/edit'
              component={MapsPages.MapEditPage}
            />
            <SharingRoute exact path='/app/maps/:mapId/zones/new'
              component={MapsPages.NewZonePage}
            />
            <SharingRoute exact path='/app/maps/:mapId/zones/:zoneId/details'
              component={MapsPages.MapZoneDetailsPage}
            />
            <SharingRoute exact path='/app/maps/:mapId/zones/:zoneId/image'
              component={MapsPages.UpdateMapZoneImagePage}
            />
            <SharingRoute exact path='/app/maps/:mapId/zones/:zoneId/configure/:configLayer'
              component={MapsPages.MapZoneConfigPage}
            />
            <SharingRoute exact path='/app/maps/embark/campaign/:campaignId'
              component={MapsPages.EmbarkPage}
            />
            <SharingRoute exact path='/app/maps/embark/campaign/:campaignId/:mapId'
              component={MapsPages.EmbarkPage}
            />
            <SharingRoute exact path='/app/maps/embark/campaign/:campaignId/:mapId/:zoneId'
              component={MapsPages.EmbarkPage}
            />
            <SharingRoute exact path='/app/maps/embark/map/:mapId'
              component={MapsPages.EmbarkPage}
            />
            <SharingRoute exact path='/app/maps/embark/map/:mapId/:zoneId'
              component={MapsPages.EmbarkPage}
            />
            <SharingRoute exact path='/app/auth/login'
              component={AuthPages.LoginPage}
            />
            <SharingRoute exact path='/app/auth/new-user'
              component={AuthPages.NewUserPage}
            />
            <SharingRoute exact path='/app/auth/session'
              component={AuthPages.SessionPage}
            />
            <SharingRoute exact path='*'
              component={NotFound}
            />
          </Switch>
        </SharedStateRouter>
      {/*</React.StrictMode>*/}
    </IconSettings>
  );
};
