const _UNBOUND = Object.freeze({});

// WeakMap of objects to their bound methods. Note that this is the ONLY place
// where a WeakMap is used due to the fact that everything else might not
// support it (e.g. attempting to do `weakMap.set(0, someValue)` is not
// allowed). This means that garbage collection only occurs when the top level
// `this` arg is collected (as opposed to when bind args are collected), but
// that shouldn't be an issue.
const _boundMethods = new WeakMap();

// For debugging purposes
window._boundMethodsCache = _boundMethods;


/**
 * Creates a singleton bound instance of a method.
 *
 * This operates similarly to the native `function.bind` functionality except it
 * memoizes the result gor a given instance/set of arguments. This means that
 * repeated calls for the same `this` and `bindArgs` will return the same
 * reference to a bound function. This is beneficial for things like react or
 * event handlers that perform shallow equality checks or lookups.
 *
 * Note that all `bindArgs` MUST be primitives.
 *
 * Caching is done through a series of weak maps and allows for objects and
 * other bind args to be garbage collected.
 *
 * This has similar functionality to `bound-methods.js` except that it has
 * more flexibility at the expense of being more verbose.
 */
export default function memoBind(method, thisArg, ...bindArgs) {
  // Traverse based on object instances.
  let methodsMap = _boundMethods.get(thisArg);
  if (!methodsMap) {
    methodsMap = new Map();
    _boundMethods.set(thisArg, methodsMap);
  }
  // Traverse based on function/method
  let boundArgsMap = methodsMap.get(method);
  if (!boundArgsMap) {
    boundArgsMap = new Map();
    methodsMap.set(method, boundArgsMap);
  }
  // Traverse bind args
  for (const bindArg of bindArgs) {
    let nextBoundArgsMap = boundArgsMap.get(bindArg);
    if (!nextBoundArgsMap) {
      nextBoundArgsMap = new Map();
      boundArgsMap.set(bindArg, nextBoundArgsMap);
    }
    boundArgsMap = nextBoundArgsMap;
  }
  // Get the cached value of the current bound function
  let curBoundFunction = boundArgsMap.get(_UNBOUND);
  if (!curBoundFunction) {
    curBoundFunction = method.bind(thisArg, ...bindArgs);
    boundArgsMap.set(_UNBOUND, curBoundFunction);
  }
  return curBoundFunction;
}


// IDEA! If you ever wanted to make this REALLY fancy, add a $b property to the
// bound functions themselves that allows you to basically call `memoBind` with
// other stuff pre-filled. That way you could do, like, `obj.$b.myMethod.$b
// (someArg)`
