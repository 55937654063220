import React from 'react';

import {Input} from '@salesforce/design-system-react';
import {Button} from '@salesforce/design-system-react';
import {Checkbox} from '@salesforce/design-system-react';

import AppGlobal from 'global';
import FormHandler from 'formlib/form-handler';
import AppReactComponent from 'utils/app-react-component';
import QueuedAsyncState from 'statelib/queued-async-state';
import {connectToComponentState} from 'statelib/fstate-react';


export default class MapZoneDetailsForm extends AppReactComponent {

  constructor(props) {
    super(props);
    this.mapZoneRef = this.constantProp('mapZoneRef', AppGlobal.mapZoneDetails);
    this.async = new QueuedAsyncState();
    this.formHandler = new FormHandler((data) => {
      return {
        name: data.name || '',
        publicName: data.publicName || '',
        public: data.public || false,
      };
    }, 'MapZoneDetailsFormHandler');
    this.connect('async', this.async);
    this.connect('mapZoneDetails', this.mapZoneRef);
    this.connect('form', this.formHandler, 'view');
    this.addCleanup(
      this.mapZoneRef.subscribe(this.formHandler.$b.initializeData, {immediate: true, path: 'mapZone'}),
      this.formHandler.onSubmit(this.$b.onFormSubmit),
    );
  }

  handleChangeName(event, data) {
    this.formHandler.setFormFieldAndData('name', data.value);
  }

  handleChangePublicName(event, data) {
    this.formHandler.setFormFieldAndData('publicName', data.value);
  }

  handleChangePublic(event, data) {
    this.formHandler.setFormFieldAndData('public', data.checked);
  }

  async onFormSubmit(data) {
    await this.async.wrap(async (throwIfCanceled) => {
      // Only path a subset of the map zone to avoid sending stuff like mask data.
      await this.mapZoneRef.patchDetails({
        name: data.name,
        publicName: data.publicName,
        public: data.public,
      }, true);
    });
    if (this.props.afterSubmit) {
      this.props.afterSubmit(data);
    }
  }

  render() {
    const isLoading = this.state.async.isRunning;
    const formView = this.state.form
    return (
      <form onSubmit={this.formHandler.$b.handleFormSubmitEvent}>
        <Input
          name='name'
          label='Name'
          value={formView.name.value}
          onChange={this.$b.handleChangeName}
          disabled={isLoading}
          errorText={formView.name.error}
          required={true}
        />
        <Input
          name='publicName'
          label='Public Name'
          value={formView.publicName.value}
          onChange={this.$b.handleChangePublicName}
          disabled={isLoading}
          errorText={formView.publicName.error}
          required={true}
        />
        <Checkbox
          labels={{
            label: 'Show to Players',
          }}
          id='checkbox-toggle-example'
          variant='toggle'
          checked={formView.public.value}
          onChange={this.$b.handleChangePublic}
        />
        <div>
          <Button
            disabled={isLoading}
            className='big-button'
            label={'Save Changes'}
            variant='brand'
            type='submit'
          />
        </div>
      </form>
    );
  }

}
