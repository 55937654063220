import Memoize from 'memoize-one';
import React from 'react';
import {Combobox} from '@salesforce/design-system-react';

import AppReactComponent from 'utils/app-react-component';

const _EMPTY_OPTIONS = [];

interface ItemType {
  id?: string,
  disabled?: boolean,
};

interface SLDSItemType extends ItemType {
  disabled: boolean,
}

type ChangeCallbackType = (event: Event, selectedId: string, selectedItem: ItemType) => void;

interface PropsType {
  className?: string,
  disabled?: boolean,
  errorText?: string,
  id?: string,
  label?: string,
  onChange?: ChangeCallbackType,
  options?: ItemType[],
  required?: boolean,
  value?: string,
};


/**
 * Intended to be a VERY basic picklist. No ability to search or do anything
 * fancy, just select one of a small number of options.
 */
export default class SinglePicklist extends AppReactComponent<PropsType, {}, any> {

  sldsEvents: {onSelect: (event: Event, data: {selection: any}) => void}

  constructor(props: PropsType) {
    super(props);
    this.sldsEvents = {
      onSelect: this.$b._handleSelect,
    }
  }

  _handleSelect(event: Event, data: {selection: any}): void {
    const items = (data) ? data.selection : _EMPTY_OPTIONS;
    let selectedId: string | null = null;
    let selectedItem: ItemType = {};
    if (items && items.length) {
      selectedId = items[0].id;
      selectedItem = items[0];
    }
    if (selectedId !== this.props.value) {
      if (this.props.onChange) {
        this.props.onChange(event, selectedId, selectedItem);
      }
    }
  }

  render() {
    const props = this.props;
    const options = this._normalizeItems(props.options);
    const selectedItems = options.filter(option => option.id === props.value);
    return (
      <Combobox
        id={props.id}
        events={this.sldsEvents}
        multiple={false}
        options={options}
        selection={selectedItems}
        variant='readonly'
        singleInputDisabled={props.disabled}
        classNameContainer={props.className}
        labels={this._getLabels(props.label)}
        required={props.required}
        menuPosition='overflowBoundaryElement'
      />
    );
  }

  _normalizeItems = Memoize((options: ItemType[] | null): SLDSItemType[] => {
    if (!options) {
      return _EMPTY_OPTIONS;
    }
    const normOptions = options.map((option) => {
      return {
        ...option,
        disabled: (option.disabled !== undefined) ? option.disabled : false,
      };
    });
    return normOptions;
  });

  _getLabels = Memoize((label: string, placeholder?: string) => {
    return {
      label: label,
      placeholder: placeholder,
    }
  })

}
