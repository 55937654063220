import Memoize from 'memoize-one';

import ImageDataDiff from 'utils/graphics/image-data-diff';
import Rect from 'utils/rect';
import Squares from 'utils/squares';
import {hashBytes} from 'utils/types';
import {rasterToPathCollection} from 'utils/geometry/raster-to-path';


/**
 * This is a base-class for other shape implementations.
 */
class BaseShape {

  /**
   * Gets the shape instance from a proto message
   *
   * Each type of shape should implement this function, as well as `instance`,
   * to get new instances (and possibly cache them)
   */
  static fromProto(grid, proto) {
    throw new Error('Not implemented');
  }

  constructor(grid) {
    this.grid = grid;
    this.containingSquare = Squares.fromDimensions(0, 0, 0, 0);
    if (new.target === BaseShape) {
      Object.freeze(this);
    }
  }

  withModifications(modifications) {
    throw new Error('Not implemented');
  }

  // Converts the shape to an object matching the proto message specification
  // for the given specific shape subtype (e.g. This should return a
  // `geometryPb.Rectangle` instead of a `geometryPb.Shape`)
  toProto() {
    throw new Error('Not implemented');
  }

  // Fills the shape in the given context.
  fill(context) {
    throw new Error('Not implemented');
  }

  // Strokes the shape in the given context.
  stroke(context) {
    throw new Error('Not implemented');
  }

}


/**
 * A shape containing the area of a single grid cell.
 *
 * The behavior of this is undefined in a Gridless environment.
 */
class GridCell extends BaseShape {

  static instance(grid, col, row) {
    // TODO: Caching
    return new GridCell(grid, col, row);
  }

  static fromProto(grid, proto) {
    if (protoGridCell.loc) {
      return GridCell.instance(
        grid,
        protoGridCell.loc.col,
        protoGridCell.loc.row,
      );
    } else {
      console.warn('Missing cell location');
      return GridCell.instance(grid, 0, 0);
    }
  }

  static fromPixelCoordinates(grid, x, y) {
    return GridCell.instance(
      grid,
      grid.pixelToContainingCol(x, y),
      grid.pixelToContainingRow(x, y),
    );
  }

  constructor(grid, col, row) {
    super(grid);
    this.col = col;
    this.row = row;
    this._x = this.grid.gridToPixelX(this.col);
    this._y = this.grid.gridToPixelY(this.row);
    this._w = this.grid.gridToPixelWidth(1);
    this._h = this.grid.gridToPixelHeight(1);
    this._sq = Squares.fromDimensions(this._x, this._y, this._w, this._h);
    this.containingSquare = this._sq;
    if (new.target === GridCell) {
      Object.freeze(this);
    }
  }

  withModifications(modifications) {
    if (!modifications) {
      return _shape;
    }
    let newX = modifications.x;
    let newY = modifications.y;
    let newGrid = this.grid;
    if (modifications.grid !== undefined) {
      newGrid = modifications.grid;
    }
    let newCol = this.col;
    if (modifications.col !== undefined) {
      newCol = modifications.col;
    } else if (newX !== undefined && newY !== undefined) {
      newCol = newGrid.pixelToContainingCol(newX, newY);
    }
    let newRow = this.row;
    if (modifications.row !== undefined) {
      newRow = modifications.row;
    } else if (newX !== undefined && newY !== undefined) {
      newRow = newGrid.pixelToContainingRow(newX, newY);
    }
    return GridCell.instance(newGrid, newCol, newRow);
  }

  toProto() {
    return {
      loc: {
        row: this.row,
        col: this.col,
      },
    };
  }

  fill(context) {
    context.fillRect(this._x, this._y, this._w, this._h);
  }

  stroke(context) {
    context.strokeRect(this._x, this._y, this._w, this._h);
  }

}


/**
 * Represents a mask (image) in grid-space.
 *
 * Masks, unlike other shapes ARE MUTABLE. In order to be modifiable in
 * a reasonably efficient manner, they must have a canvas and that canvas
 * can be manipulated. In this way, Masks have a lot in common with other
 * shapes but have some key differences in functionality.
 *
 * IDEA: It might be a good idea to be able to translate the mask into a shape
 * (using something like Path2D and .rect) that way it can be set up as a
 * clipping mask for other operations.
 */
class Mask extends BaseShape {

  static instance(grid, col, row, width, height, detail, dataField, data) {
    // Mutable, so we cannot cache.
    return new Mask(grid, col, row, width, height, detail, dataField, data);
  }

  static fromProto(grid, proto) {
    let dataField = 'rgbaImageData';
    if (proto.imageData || proto.empty) {
      throw new Error('Need to implement other than rgbaImageData')
    }
    let data = proto[dataField] || null;
    try {
      return Mask.instance(
        grid,
        proto.loc.col || 0,
        proto.loc.row || 0,
        proto.width || 0,
        proto.height || 0,
        proto.detailLevel || 0,
        dataField,
        data,
      );
    } catch (err) {
      console.error('Failed to load mask instance', grid, proto);
      throw err;
    }
  }

  constructor(grid, col, row, width, height, detail, dataField, data) {
    super(grid);
    this.col = col;
    this.row = row;
    this.width = width;
    this.height = height;
    this.detail = Math.round(detail);  // Should already be an int, but just in case
    this.maskWidth = width * detail;
    this.maskHeight = height * detail;
    this.dataField = dataField || 'rgbaImageData';
    this.data = data || null;
    this.containingSquare = Squares.fromDimensions(
      grid.gridToPixelX(col),
      grid.gridToPixelY(row),
      grid.gridToPixelWidth(width),
      grid.gridToPixelHeight(height),
    );
    this.rootCellSq = grid.getCellSquare(col, row);
    this.canvas = null;
    // this.dataHash = null;  // Try without the hash
    this.version = 1;
    this._updateVersionAndDataHash();
  }

  clone() {
    return new Mask(
      this.grid,
      this.col,
      this.row,
      this.width,
      this.height,
      this.detail,
      this.dataField,
      this.data,
    );
  }

  testAttachCanvas(parent) {
    if (!parent) {
      parent = document.getElementById('root');
    }
    this.canvas.style.float = 'left';
    this.canvas.style.padding = '5px';
    this.canvas.style.zIndex = '10';
    this.canvas.style.left = '0';
    this.canvas.style.top = '0';
    parent.appendChild(this.canvas);
  }

  /**
   * Loads `data` into a native ImageData object.
   *
   * This is used to allow different Mask data storage schemes to abstract
   * out storage
   */
  _loadImageData(imageData, data) {
    if (this.dataField === 'rgbaImageData') {
      for (let i = 0; i < data.length; i++) {
        imageData.data[i] = data[i];
      }
    } else {
      throw new Error(`Unrecognized mask encoding (dataField=${this.dataField}`);
    }
  }

  /**
   * Extracts raw data from `imageData`.
   *
   * This converts the raw image data into the correct encoded form
   * for the mask type (by default, it uses the plain image data form
   * which is a clone, but other types may have different values)
   */
  _getImageData(imageData) {
    if (this.dataField === 'rgbaImageData') {
      return Uint8ClampedArray.from(imageData.data);
    } else {
      throw new Error(`Unrecognized mask encoding (dataField=${this.dataField}`);
    }
  }

  async _updateVersionAndDataHash() {
    this.version += 1;
    // const data = this.data;
    // const newHash = await hashBytes(data);
    // this.dataHash = newHash;
  }

  initCanvas(canvas) {
    if (this.canvas) {
      throw new Error('Canvas already initialized');
    }
    this.canvas = canvas;
    canvas.width = this.maskWidth;
    canvas.height = this.maskHeight;
    const ctx = canvas.getContext('2d', {alpha: true, willReadFrequently: true});
    ctx.clearRect(0, 0, this.maskWidth, this.maskHeight);
    this.refreshCanvas();
  }

  withMaskContext(drawFunc, scope, options) {
    if (!this.canvas) {
      throw new Error('A Mask shape cannot be modified until it has a canvas');
    }
    const noDiff = options ? options.noDiff : false;
    const width = this.maskWidth;
    const height = this.maskHeight;
    const ctx = this.canvas.getContext('2d', {...options, willReadFrequently: true});
    let scopeRect;
    if (scope && typeof(scope) === 'function') {
      scopeRect = scope(this);
    } else if (scope) {
      scopeRect = scope;
    } else {
      scopeRect = Rect.fromSize(width, height);
    }
    const imageDiff = noDiff ? null : ImageDataDiff.fromContext(ctx, scopeRect);
    ctx.save();
    try {
      drawFunc(ctx, this);
    } finally {
      ctx.restore();
    }
    // TODO: Lazy create `this.data`. Whenever dirty, set an internal flag.
    // Use a getter to re-generate it and clear the flag when received. Could
    // really help
    if (!noDiff) {
      imageDiff.updateFromContext(ctx);
    }
    this.data = this._getImageData(ctx.getImageData(0, 0, width, height));
    this._updateVersionAndDataHash();
    return imageDiff;
  }

  fullUpdateMaskFromConfig(maskConfig, dataField) {
    this.col = maskConfig.loc.col || 0;
    this.row = maskConfig.loc.row || 0;
    this.width = maskConfig.width || 0;
    this.height = maskConfig.height || 0;
    this.detail = Math.round(maskConfig.detailLevel);
    this.maskWidth = this.width * this.detail;
    this.maskHeight = this.height * this.detail;
    this.dataField = dataField || 'rgbaImageData';
    this.data = maskConfig[this.dataField] || null;
    this.containingSquare = Squares.fromDimensions(
      this.grid.gridToPixelX(this.col),
      this.grid.gridToPixelY(this.row),
      this.grid.gridToPixelWidth(this.width),
      this.grid.gridToPixelHeight(this.height),
    );
    if (this.canvas) {
      this.canvas.width = this.maskWidth;
      this.canvas.height = this.maskHeight;
      this.refreshCanvas();
    }
  }

  partialUpdateMaskFromPatch(maskPatch) {
    if (!maskPatch.rgbaImageData) {
      throw new Error('Currently only rgba_image_data is supported for mask patch');
    }
    this.partialUpdateMask(
      maskPatch.rgbaImageData,
      maskPatch.x,
      maskPatch.y,
      maskPatch.width,
      maskPatch.height,
    );
  }

  /**
   * Allows for performing a partial update on the underlying data.
   *
   * This is useful for applying a `geometry.MaskPatch` directly to the image.
   * It should be faster than actually drawing on the image context since it is
   * a direct data replacement rather than engaging the graphics engine.
   */
  partialUpdateMask(data, x, y, width, height) {
    if (!this.canvas) {
      throw new Error('A Mask shape cannot be modified until it has a canvas');
    }
    if (x !== undefined && y === undefined && width === undefined && height === undefined) {
      height = x.h;
      width = x.w;
      y = x.y;
      x = x.x;
    }
    const ctx = this.canvas.getContext('2d', {willReadFrequently: true});
    const patchImageData = ctx.createImageData(width, height);
    patchImageData.data.set(data);
    ctx.putImageData(patchImageData, x, y);
    const imageData = ctx.getImageData(0, 0, this.maskWidth, this.maskHeight);
    this.data = this._getImageData(imageData);
    this._updateVersionAndDataHash();
  }

  /**
   * Applies a batch of `ImageDataDiff` to the current mask
   */
  applyDiffsToMask(diffs) {
    if (!this.canvas) {
      throw new Error('A Mask shape cannot be modified until it has a canvas');
    }
    const ctx = this.canvas.getContext('2d', {willReadFrequently: true});
    let redrawRegion = null;
    for (const diff of diffs) {
      let diffRedrawRegion = diff.applyToContext(ctx);
      if (redrawRegion === null) {
        redrawRegion = diffRedrawRegion;
      } else {
        redrawRegion = Squares.getContainingSquare(redrawRegion, diffRedrawRegion);
      }
    }
    const imageData = ctx.getImageData(0, 0, this.maskWidth, this.maskHeight);
    this.data = this._getImageData(imageData);
    this._updateVersionAndDataHash();
    return redrawRegion;
  }

  /**
   * Convenience versino of `applyDiffsToMask` that takes only a single diff.
   *
   * In general you'll want to use the bulk operation since it is more efficient
   * by only performing the overhead operations(recalculating hash/version/data
   * string) once.
   */
  applyDiffToMask(diff) {
    return this.applyDiffsToMask([diff]);
  }

  /**
   * Refreshes the underlying canvas with the mask data, if applicable.
   *
   * Some functions (like partialUpdateMask) don't necessarily update the
   * actualy imageData/canvas, allowing them to be called multiple times in
   * a row without incurring significant overhead. Use this function once all
   * of the image data is updated to refresh the imageData/canvas/data.
   */
  refreshCanvas() {
    const ctx = this.canvas.getContext('2d', {alpha: true, willReadFrequently: true});
    const imageData = ctx.createImageData(this.maskWidth, this.maskHeight);
    if (this.data && this.data.length) {
      this._loadImageData(imageData, this.data);
      ctx.putImageData(imageData, 0, 0);
    }
    this.data = this._getImageData(imageData);
    this._updateVersionAndDataHash();
  }

  /**
   * Gets a Squares object (in real-pixel space) that contains all of the cells
   * with an alpha value (0-255) less-than-or-equal-to maxAlpha.
   *
   * If `invert` is true, it uses the opposite: alpha needs to be
   * greater-than-or-equal to the value.
   *
   * TODO: Utilize the path collection to improve this.
   */
  getAlphaBoundingSq(alphaLimit, invert) {
    // While it's possible to do this without having a canvas, it's more
    // efficient to work with the canvas because the data is de-compressed.
    if (!this.canvas) {
      throw new Error('Reading image data requires the canvas be initialized');
    }
    const finalAlphaLimit = alphaLimit;
    const finalInvert = invert;
    const maskWidth = this.maskWidth;
    const maskHeight = this.maskHeight;
    const ctx = this.canvas.getContext('2d', {alpha: true, willReadFrequently: true});
    const imageDataArr = ctx.getImageData(0, 0, this.maskWidth, this.maskHeight).data;
    // Note, these are "swapped" high/low so that the first time we find a cell,
    // the mins and maxes will work themselves out.
    let minDetailCol = maskWidth;
    let maxDetailCol = 0;
    let minDetailRow = maskHeight;
    let maxDetailRow = 0;
    // Go through every pixel and see if the alpha value matches
    // TODO: This is a LOT. There should be some way where we can do an
    // ever-increasing detail for each of the 4 corners. (check ever 16 pixels
    // first, then, if found, only check outside of the square, etc.)
    const idxLimit = maskWidth * maskHeight;
    for (let idx = 0; idx < idxLimit; idx++) {
      if (finalInvert) {
        if (imageDataArr[3 + (idx << 2)] >= finalAlphaLimit) {
          const col = idx % maskWidth;
          const row = (idx - col) / maskWidth;
          minDetailCol = Math.min(minDetailCol, col);
          maxDetailCol = Math.max(maxDetailCol, col);
          minDetailRow = Math.min(minDetailRow, row);
          maxDetailRow = Math.max(maxDetailRow, row);
        }
      } else {
        if (imageDataArr[3 + (idx << 2)] <= finalAlphaLimit) {
          const col = idx % maskWidth;
          const row = (idx - col) / maskWidth;
          minDetailCol = Math.min(minDetailCol, col);
          maxDetailCol = Math.max(maxDetailCol, col);
          minDetailRow = Math.min(minDetailRow, row);
          maxDetailRow = Math.max(maxDetailRow, row);
        }
      }
    }
    // Handle the case where none is found
    if (maxDetailCol < minDetailCol) {
      return Squares.fromDimensions(0, 0, 0, 0);
    }
    // Return the information in grid-pixel space
    return Squares.fromCoordinates(
      this.grid.gridExactToPixelX(this.col + (minDetailCol / this.detail)),
      this.grid.gridExactToPixelY(this.row + (minDetailRow / this.detail)),
      this.grid.gridExactToPixelX(this.col + ((maxDetailCol + 1) / this.detail)),
      this.grid.gridExactToPixelY(this.row + ((maxDetailRow + 1) / this.detail)),
    );
  }

  /**
   * Returns a path wrapping the mask. If there is no path (no segments given
   * the parameters) this instead returns null.
   *
   * The returned path will be in source-pixel space with (0, 0) being the upper
   * left point in the grid.
   */
  getPath(alphaLimit, invert) {
    return this._getPath(this.version, alphaLimit, invert);
  }

  _getPath = Memoize((version, alphaLimit, invert) => {
    const pathCollection = this._getPathCollection(version, alphaLimit, invert);
    if (!pathCollection) {
      return null;
    }
    const path = new Path2D();
    const gridMatrix = this.getMaskToPixelMatrix();
    path.addPath(pathCollection.toPath2D(), gridMatrix);
    return path;
  });

  getMaskToPixelMatrix() {
    return (new DOMMatrixReadOnly())
      .translate(this.rootCellSq.x, this.rootCellSq.y)
      .scale(1 / this.detail, 1 / this.detail)
      .scale(this.rootCellSq.w, this.rootCellSq.h);
  }

  getPixelToMaskMatrix() {
    return (new DOMMatrixReadOnly())
      .scale(1 / this.rootCellSq.w, 1 / this.rootCellSq.h)
      .scale(this.detail, this.detail)
      .translate(-1 * this.rootCellSq.x, -1 * this.rootCellSq.y);
  }

  /**
   * Helper to get a square representing a grid cell in mask-space.
   */
  gridCellToMaskSq(col, row) {
    return Squares.fromDimensions(
      this.gridToMaskX(col),
      this.gridToMaskY(row),
      this.detail,
      this.detail,
    )
  }

  pixelSqToMaskSq(gridSq) {
    return Squares.fromCoordinates(
      this.pixelToMaskX(gridSq.x),
      this.pixelToMaskY(gridSq.y),
      this.pixelToMaskX(gridSq.x2),
      this.pixelToMaskY(gridSq.y2),
    );
  }

  /**
   * Gets the current path collection. Always pass in `this.version` as
   * `curVersion` - it is used as a cache key.
   *
   * Returns `null` if the path collection does not contain any paths
   */
  _getPathCollection = Memoize((curVersion, alphaLimit, invert) => {
    if (!this.canvas) {
      throw new Error('Reading image data requires the canvas be initialized');
    }
    const finalAlphaLimit = alphaLimit;
    const finalInvert = !!invert;
    const finalWidth = this.maskWidth;
    const ctx = this.canvas.getContext('2d', {alpha: true, willReadFrequently: true});
    const imageDataArr = ctx.getImageData(0, 0, this.maskWidth, this.maskHeight).data;
    const pathCollection = rasterToPathCollection((col, row) => {
      const pixelIdx = (col + (row * finalWidth)) * 4;  // TODO: Check bitshift performance
      if (finalInvert === true) {
        return imageDataArr[pixelIdx + 3] <= finalAlphaLimit;
      } else {
        return imageDataArr[pixelIdx + 3] >= finalAlphaLimit;
      }
    }, this.maskWidth, this.maskHeight);
    return pathCollection.hasPaths ? pathCollection : null;
  })

  /**
   * Checks if a given point is contained within the mask. "Contained" means
   * that the alpha in the mask is >= to the
   */
  isPointInMask(x, y, alphaLimit, invert) {
    const maskX = this.pixelToMaskXF(x);
    const maskY = this.pixelToMaskYF(y);
    if (maskX >= 0 && maskX < this.maskWidth && maskY >= 0 && maskY < this.maskHeight) {
      const alpha = this.data[((maskX + (maskY * this.maskWidth)) * 4) + 3];
      return !!(
        (invert && alpha <= alphaLimit) ||
        (!invert && alpha >= alphaLimit)
      );
    } else {
      return false;
    }
  }

  gridToMaskX(col) {
    return Math.round(this.detail * (col - this.col));
  }

  gridToMaskY(row) {
    return Math.round(this.detail * (row - this.row));
  }

  pixelToMaskX(x) {
    return Math.round(this.detail * ((x - this.rootCellSq.x) / this.rootCellSq.w));
  }

  pixelToMaskY(y) {
    return Math.round(this.detail * ((y - this.rootCellSq.y) / this.rootCellSq.h));
  }

  pixelToMaskXF(x) {
    return Math.floor(this.detail * ((x - this.rootCellSq.x) / this.rootCellSq.w));
  }

  pixelToMaskYF(y) {
    return Math.floor(this.detail * ((y - this.rootCellSq.y) / this.rootCellSq.h));
  }

  toProto() {
    return {
      loc: {
        col: this.col,
        row: this.row,
      },
      width: this.width,
      height: this.height,
      detailLevel: this.detail,
      [this.dataField]: (this.data) ? this.data : [],
    };
  }

  toProtoPatch(x, y, w, h) {
    if (!this.canvas) {
      throw new Error('Reading image data requires the canvas be initialized');
    }
    let patchSquare;
    if (x !== undefined && y === undefined && w === undefined && h === undefined) {
      patchSquare = x;
    } else {
      patchSquare = Squares.fromDimensions(x, y, w, h);
    }
    patchSquare = Squares.getIntersection(patchSquare, Squares.fromDimensions(
      0, 0, this.maskWidth, this.maskHeight
    ));
    let imageData;
    if (!patchSquare) {
      console.warn('Requested patch has no overlap with mask');
      patchSquare = Squares.zero();
      imageData = [];
    } else {
      imageData = this.canvas.getContext('2d').getImageData(
        patchSquare.x,
        patchSquare.y,
        patchSquare.w,
        patchSquare.h,
      ).data
    }
    return {
      x: patchSquare.x,
      y: patchSquare.y,
      width: patchSquare.w,
      height: patchSquare.h,
      rgbaImageData: imageData,
    };
  }

  fill(context) {
    if (!this.canvas) {
      throw new Error('A Mask cannot be used for drawing until it has a canvas');
    }
    context.save();
    context.globalCompositeOperation = 'destination-in';
    // TODO: Use some masking to make this work with the current
    // fill styles rather than just appling
    context.drawImage(
      this.canvas,
      0,
      0,
      this.maskWidth,
      this.maskHeight,
      this.containingSquare.x,
      this.containingSquare.y,
      this.containingSquare.w,
      this.containingSquare.h,
    );
    context.restore();
  }

  stroke(context) {
    throw new Error('TODO: How do implement stroke?');
  }

}


/**
 * Static class for creating shapes
 */
export default class Shapes {

  static GridCell = GridCell;
  static Mask = Mask;

  /**
   * Like `fromProtoShape` but for a list.
   */
  static fromProtoShapes(grid, protoShapes) {
    return protoShapes.map((protoShape) => {
      return Shapes.fromProtoShape(grid, protoShape);
    });
  }

  /**
   * Get a new shape object from the polymorphic Shape message.
   *
   * This takes a polymorphic shape (geometryPb.Shape) and returns the
   * appropriate immutable shape implementation for it.
   */
  static fromProtoShape(grid, protoShape) {
    if (protoShape.gridCell) {
      return GridCell.fromProto(grid, protoShape.gridCell);
    } else if (protoShape.mask) {
      return Mask.fromProto(grid, protoShape.mask);
    } else {
      throw new Error('Unexpected shape proto', protoShape);
    }
  }

  /**
   * Like `toProtoShape` but for a list.
   */
  static toProtoShapes(shapes) {
    return shapes.map((shape) => {
      return Shapes.toProtoShape(shape);
    });
  }

  /**
   * Convert a shape object to a proto Shape message
   *
   * This takes a shape object (like one created via fromProtoShape) and
   * converts it to an object matching the geometryPb.Shape spec. This relies on
   * the individual shape having properly implemented `shapeType` and `toProto`
   */
  static toProtoShape(shape) {
    const protoShape = {};
    if (shape instanceof GridCell) {
      protoShape.gridCell = shape.toProto();
    } else if (shape instanceof Mask) {
      protoShape.mask = shape.toProto();
    } else {
      throw new Error('Unexpected shape', shape);
    }
    return protoShape;
  }

}
