import React from 'react';
import {withRouter} from 'react-router';

import CenteredBox from 'components/layouts/centered-box';
import AppPageTemplateConnected from 'components/templates/app-page-template-connected';

import NewCampaignForm from './components/new-campaign-form';

import ReactComponent from 'utils/react-component';


class NewCampaignPage extends ReactComponent {

  _onSuccess(campaign) {
    this.props.history.replace(`/app/maps/campaigns/${campaign.id}`);
  }

  _onCancel() {
    // TODO: Get previous location and history push that - allows for someone
    // to press back to return to the form which makes more sense
    this.props.history.push(`/app/maps/campaigns`);
  }

  render() {
    return (
      <AppPageTemplateConnected>
        <CenteredBox className='border-box' style={{width: '400px'}}>
          <h3 className='slds-text-heading_medium'>Create New Campaign</h3>
          <NewCampaignForm
            onSuccess={this.$b._onSuccess}
            onCancel={this.$b._onCancel}
          />
        </CenteredBox>
      </AppPageTemplateConnected>
    );
  }

}


export default withRouter(NewCampaignPage);
