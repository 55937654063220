import React from 'react';

import DialogBox from 'components/layouts/dialog-box';

import AppGlobal from 'global';
import SettingsForm from 'apps/maps/components/settings-form';
import ReactComponent from 'utils/react-component';
import {connectToComponentState} from 'statelib/fstate-react';


function EmbarkMapZoneEditDialogView({
  rendered,
  close,
  closeAndRefresh,
}) {
  return (
    <DialogBox
      variant='semialpha'
      onClose={close}
      rendered={rendered}
    >
      <SettingsForm
        afterSubmit={closeAndRefresh}
        afterCancel={close}
      />
    </DialogBox>
  );
}


export default class EmbarkMapZoneEditDialog extends ReactComponent {

  static propTypes = {};  // No props - everything is pulled from global state

  constructor(props) {
    super(props);
    this.routerRef = AppGlobal.rootRouter;
    connectToComponentState(this.routerRef, this, 'router');
  }

  _closeDialog() {
    this.routerRef.history.replace(this.routerRef.url);
  }

  _closeAndRefresh() {
    this.routerRef.history.replace(this.routerRef.url);
    location.reload();
  }

  render() {
    return EmbarkMapZoneEditDialogView({
      rendered: this.state.router.hash === 'app_settings',
      close: this.$b._closeDialog,
      closeAndRefresh: this.$b._closeAndRefresh,
    });
  }

}
