import classNames from 'classnames';
import memoizeOne from 'memoize-one';
import React, {type CSSProperties, type ReactNode} from 'react';

import AppReactComponent from 'utils/app-react-component.js';

interface BorderDetailsType {
  style: null | 'UNKNOWN_BORDER_STYLE' | 'RING_BORDER_STYLE',
  color: null | string,
}

interface PropsType {
  alt?: string,
  annotation?: string,
  border?: BorderDetailsType,
  className?: string,
  classNameImage?: string,
  onClick?: any,
  src?: string,
  style?: object,
};

interface StateType {
};


/**
 * An image that can be displayed in the UI representing a token. It takes into
 * account any special token styling
 */
export default class TokenImage extends AppReactComponent<PropsType, StateType, any> {

  constructor(props: PropsType) {
    super(props);
  }

  render(): ReactNode {
    const props = this.props;
    if (
      !props.border ||
      props.border.style === null ||
      props.border.style === 'UNKNOWN_BORDER_STYLE'
    ) {
      return this._renderPlain();
    } else if (props.border.style === 'RING_BORDER_STYLE') {
      return this._renderWithRingBorder();
    } else {
      throw new Error('Unknown token image configuration');
    }
  }

  protected _renderPlain(): ReactNode {
    const props = this.props;
    return (
      <img
        className={classNames(props.className, {
          'layout--image-contain': true,
        })}
        style={props.style}
        onClick={props.onClick}
        src={props.src}
        alt={props.alt}
      />
    );
  }

  protected _renderWithRingBorder(): ReactNode {
    const props = this.props;
    return (
      <svg
        className={classNames(props.className, {
          'layout--image-contain': true,
        })}
        style={props.style}
        onClick={props.onClick}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
      >
        <style>{`
          .annotation {
            font: 16px serif;
          }
        `}</style>
        <defs>
          <clipPath id="clipOuterCircle">
            <circle
              cx="50"
              cy="50"
              r="48"
            />
          </clipPath>
          <clipPath id="clipInnerCircle">
            <circle
              cx="50"
              cy="50"
              r="39"
            />
          </clipPath>
        </defs>
        <circle
          cx="50"
          cy="50"
          r="48"
          clipPath="url(#clipOuterCircle)"
          stroke="none"
          fill={props.border.color}
        />
        <circle
          cx="50"
          cy="50"
          r="48"
          clipPath="url(#clipInnerCircle)"
          stroke="none"
          fill="#DDD"
        />
        <image
          href={props.src}
          preserveAspectRatio="xMidYMid slice"
          clipPath="url(#clipInnerCircle)"
          x="11"
          y="11"
          height="78"
          width="78"
        />
        <circle
          cx="50"
          cy="50"
          r="48"
          stroke="#000"
          strokeWidth="1"
          fill="none"
        />
        <circle
          cx="50"
          cy="50"
          r="39"
          stroke="#000"
          strokeWidth="1"
          fill="none"
        />
        {/* if */(props.annotation) ? (
          <React.Fragment>
            <text
              x="50"
              y="94"
              stroke="#222"
              strokeWidth="4"
              className="annotation"
              textAnchor="middle"
            >
              {props.annotation}
            </text>
            <text
              x="50"
              y="94"
              fill="#DDD"
              className="annotation"
              textAnchor="middle"
            >
              {props.annotation}
            </text>
          </React.Fragment>
        )/* endif */ : null}
      </svg>
    );
  }

}
