import ReactComponent from 'utils/react-component';
import SimplePubSub from 'utils/simple-pub-sub';
import {connectToComponentState} from 'statelib/fstate-react';
import {pathParse} from 'utils/obj-paths';

const _ROOT_PATH = pathParse('').path;

function _noopSubscribe(callback) {
  return () => null;
}

/**
 * ReactComponent that has improved functionality for working with FStates
 */
export default class AppReactComponent<P, S, SS> extends ReactComponent<P, S, SS>{

  /**
   * Attaches a read-only copy of `sourceState`s data at `stateKey`.
   *
   * This function can ony be called during the constructor. It will handle
   * setting up the subscribers and updating the initial state value.
   */
  connect(stateKey, sourceState, sourceStatePath?: string) {
    if (!sourceStatePath) {
      sourceStatePath = _ROOT_PATH;
    }
    connectToComponentState(sourceState, this, sourceStatePath, stateKey, true);
  }

  /**
   * Creates a simple pub-sub connection. Pass either the `$b.publish` or
   * `$b.subscribe` functions to child components to allow them to more freely
   * communicate.
   *
   * Sets up the instance to be destroyed on cleanup.
   */
  makePubSub() {
    const pubSub = new SimplePubSub<any>();
    this.addCleanup(pubSub.$b.destroy);
    return pubSub;
  }

  /**
   * Macro for handling subscriptions to `SimplePublSub` subscription functions
   */
  pubSubscribe(subscribeProp, callback) {
    const subscribe = this.constantProp(subscribeProp, _noopSubscribe);
    this.addCleanup(subscribe(callback));
  }

}
