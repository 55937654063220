import Memoize from 'memoize-one';

import AppGlobal from 'global';
import BaseAsyncState from 'statelib/base-async-state';
import FStateWithAsync from 'statelib/fstate-with-async';
import Grids from 'utils/geometry/grids';
import MapsRPCAPI from 'apis/maps-rpc-api';

import {common as commonPb} from 'proto-bundle';

const _Access = commonPb.AccessLevel;
const ACCESS_UNKNOWN_ACCESS_LEVEL = _Access[_Access.UNKNOWN_ACCESS_LEVEL];
const ACCESS_VIEW_PUBLIC = _Access[_Access.VIEW_PUBLIC];


export default class MapZoneDetailsRef extends FStateWithAsync {

  static factory = () => new MapZoneDetailsRef();

  constructor(options) {
    super(options);
    this._allowedIds = null;
  }

  get async() {
    return this.getValue('async');
  }

  set mapZoneId(value) {
    value = value || null;
    if (value !== this.data.mapZoneId) {
      if (value === null) {
        console.log(`map-zone-details[${this.name}] clear zone`);
        this.resetData();
      } else if (typeof value !== 'string') {
        throw new Error(`Expected id to be a string, got ${value}`);
      } else {
        this.patchData({
          ...this.makeInitialData(),
          mapZoneId: value,
        });
        this.refresh();
      }
    }
  }

  get mapZoneId() {
    return this.getValue('mapZoneId');
  }

  get mapZone() {
    return this.getValue('mapZone');
  }

  get grid() {
    return this.getValue('grid');
  }

  destroy() {
    this._allowedIds = null;
    super.destroy();
  }

  makeInitialData() {
    return {
      grid: null,
      mapZone: null,
      mapZoneId: null,
    };
  }

  resetAllButId() {
    this.patchData({
      map: null,
      zones: null,
    });
  }

  raiseIfNotReady() {
    if (!this.async.isSuccess) {
      throw new Error('The Map Zone is not ready yet');
    }
  }

  setAllowedIds(ids) {
    // Default map zones should be handled in navigation. When the zones are
    // loaded for a map and this function is called, use the return value to
    // determine if you should navigate to a default zone for the map or stay.
    const curId = this.mapZoneId;
    const prevIds = this._allowedIds;
    const prevIncluded = !!(prevIds === null || prevIds.includes(curId));
    const nextIds = ids ? [...ids] : null;
    const nextIncluded = !!(nextIds === null || nextIds.includes(curId));
    this._allowedIds = nextIds;
    if (prevIncluded !== nextIncluded) {
      this.refresh();
    }
    return nextIncluded;
  }

  async refresh(reset) {
    const async = this.getAttachedState('async');
    const mapZoneId = this.mapZoneId;
    try {
      console.log(`zone-details[${this.name}] refreshing map zone ${mapZoneId}`);
      await async.wrap(async (throwIfCanceled) => {
        if (
          mapZoneId === null ||
          !(this._allowedIds === null || this._allowedIds.includes(mapZoneId))
        ) {
          // Return early if no map zone or if map zone id is not allowed
          this.resetAllButId();
          return;
        } else if (reset) {
          // Reset internal state if requested. Otherwise, patch inline
          this.resetAllButId();
        }
        // Get the map zone details
        const mapZoneResult = await MapsRPCAPI.getMapZone({mapZoneId: mapZoneId});
        const newData = {mapZone: mapZoneResult.mapZone};
        if (mapZoneResult.mapZone.gridConfiguration) {
          newData.grid = Grids.fromGridConfiguration(
            mapZoneResult.mapZone.gridConfiguration
          );
        }
        // Pre-load all of the objects associated with the zone
        await AppGlobal.mapObjectStore.loadObjectsForZone(mapZoneId);
        throwIfCanceled();
        // Finally, set the map zone since it (and its dependencies) are loaded
        this.patchData(newData);
        // TODO: Do we want any other weird stuff in here?
        console.log(`zone-details[${this.name}] loaded map zone`, this.mapZone);
      });
    } catch (err) {
      if (!BaseAsyncState.isCanceled(err)) {
        console.warn(`Unable to load map zone ${mapZoneId}`, err);
        // Do not re-raise, rely on error stored in async state.
      }
    }
  }

  /**
   * Updates basic information about the map zone. Note that most fields will be
   * ignored outside of standard metadata.
   */
  async patchDetails(mapZone, commit) {
    const prevMapZone = this.mapZone;
    const nextMapZone = {...prevMapZone, ...mapZone};
    if (commit) {
      const async = this.getAttachedState('async');
      await async.wrap(async (throwIfCanceled) => {
        const result = await MapsRPCAPI.patchMapZone({
          ...mapZone,
          id: this.mapZoneId,
        });
        throwIfCanceled();
        Object.assign(nextMapZone, result);
      });
    }
    this.setValue('mapZone', nextMapZone);
    // Background refresh campaign details.
    // TODO: This probably shouldn't point to AppGlobal
    AppGlobal.mapDetails.patchZoneById(nextMapZone.id, nextMapZone);
  }

  async patchGridConfiguration(gridConfiguration, commit) {
    const prevMapZone = this.mapZone;
    this.setValue('mapZone', {...prevMapZone, gridConfiguration: gridConfiguration});
    if (commit) {
      const async = this.getAttachedState('async');
      await async.wrap(async (throwIfCanceled) => {
        const result = await MapsRPCAPI.setMapZoneGridDetails({
          id: this.mapZone.id,
          gridConfiguration: this.mapZone.gridConfiguration,
        });
        throwIfCanceled();
        this.setValue('mapZone', {...this.mapZone, gridConfiguration: result.gridConfiguration});
        // For safety, request a background refresh as well
        this.refresh();
      });
    }
  }

  async patchMapZoneBoundaries(boundaryDetails, commit) {
    const async = this.getAttachedState('async');
    const prevMapZone = this.mapZone;
    this.setValue('mapZone', {...prevMapZone, boundaries: boundaryDetails});
    if (commit) {
      await async.wrap(async (throwIfCanceled) => {
        const result = await MapsRPCAPI.setMapZoneBoundaries({
          mapZoneId: this.mapZone.id,
          boundaries: this.mapZone.boundaries,
        });
        throwIfCanceled();
        this.setValue('mapZone', {...this.mapZone, ...result});
      });
    }
  }

  /**
   * Patches the map zone data.
   *
   * For now, this requires providing the full (post-patch) mask shape so that
   * it can update the map zone's data. I don't really like the way that this
   * works, but until we have a `MaskContainer` that understands that it is a
   * visibility layer mask, there's not a ton that can be done.
   *
   * `mask` is the `shapes.Mask` object and `patchArea` is a Squares object
   * representing the area of the mask that should be included in the patch.
   *
   * TODO: This should handle things like erasing hidden override when visible
   * override is called. Right now it's possible for all of the masks to overlap
   * which can result in some weird behaviors. Instead, painting to hidden
   * should erase explored, painting to explored should erase hidden, painting
   * to visible should erase hidden and also paint explored.
   */
  async setMapZoneVisibility(mask, patchArea, target, commit) {
    const async = this.getAttachedState('async');
    const prevMapZone = this.mapZone;
    let nextMapZone = {...prevMapZone};
    if (target === 'EXPLORED_PATCH_TARGET') {
      nextMapZone.visibility.exploredMask = mask.toProto();
    } else if (target === 'HIDDEN_PATCH_TARGET') {
      nextMapZone.visibility.overrideHiddenMask = mask.toProto();
    } else if (target === 'VISIBLE_PATCH_TARGET') {
      nextMapZone.visibility.overrideVisibleMask = mask.toProto();
    }
    // Publish once before going into async so that any listeners  have the
    // latest without waiting for the fire-and-forget commit
    this.setValue('mapZone', nextMapZone);
    if (commit) {
      await async.wrap(async (throwIfCanceled) => {
        const request = {
          mapZoneId: this.mapZone.id,
          patchData: mask.toProtoPatch(patchArea),
          target: target,
        };
        try {
          await MapsRPCAPI.patchMapZoneVisibility(request);
          throwIfCanceled();
        } catch (err) {
          console.info('Unable to set visibility', err);
        }
      });
    }
  }

  getPermissions(asPlayer) {
    return this._getPermissionsForMapZone(this.mapZone, asPlayer);
  }

  /**
   * Caching function for generating a permissions object for the map.
   *
   * Note that it is campaign role that affects whether a user is allowed to
   * impersonate a user (as well as some other permissions)
   */
  _getPermissionsForMapZone = Memoize((mapZone, asPlayer) => {
    let access;
    if (!mapZone) {
      access = ACCESS_UNKNOWN_ACCESS_LEVEL;
    } else if (asPlayer) {
      access = ACCESS_VIEW_PUBLIC;
    } else {
      access = mapZone.accessLevel || ACCESS_UNKNOWN_ACCESS_LEVEL;
    }
    return {};
  });

}
