export default class PerformanceCapture {

  constructor(name) {
    this.name = name;
    this._points = [];
    this.capture('__start__');
  }

  capture(name) {
    this._points.push({name: name, timestamp: performance.now()});
  }

  debug() {
    let lastTime = null;
    const times = [];
    for (const {name, timestamp} of this._points) {
      if (lastTime !== null) {
        times.push(`${name}:${((timestamp - lastTime) * 0.001).toFixed(6)}s`)
      }
      lastTime = timestamp;
    }
    console.debug(`performance[${this.name}]: ${times.join(', ')}`);
  }

}
