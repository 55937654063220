// Only enable `whyDidYouRender` when necessary. Slows the application way down.
// import React from 'react';
// import whyDidYouRender from '@welldone-software/why-did-you-render';
// whyDidYouRender(React, {
//   trackAllPureComponents: true,
// });

import {initializeApp, SINGLE_PAGE_APP} from './apps';
import appGlobal from 'global';

function initializeMainApp(rootElement) {
  initializeApp(rootElement, SINGLE_PAGE_APP);
}

// Bootstrap the initializer to the window
window['initializeApp'] = initializeMainApp;
window['AppGlobal'] = appGlobal;

export default Object.freeze({
  initializeMainApp,
});
