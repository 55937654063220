import AppGlobal from "global";
import CanvasEngine from "canvasengine/canvas-engine";
import FrameUpdateContext from "canvasengine/frame-update-context";
import MapObjectStoreRef from "apps/maps/state/map-object-store-ref";
import MapObjectStoreState from "apps/maps/state/map-object-store-state";

import BaseMapToken from "./base-map-token";
import GhostMapToken from "./ghost-map-token";


/**
 * A version of Map Token that is meant to show the user where a token is
 * currently being placed. For example, when the user is dragging a new token
 * onto the map. This token is not meant to be a true reflection of the
 * underlying token data - instead it's sort of a depiotion of where a token
 * COULD be.
 */
export default class StandardMapToken extends BaseMapToken {

  /** Indicates when the user began holding the token */
  protected _pointerDownEvent: PointerEvent | null = null;
  /** Indicates that the user is currently dragging the token */
  protected _isDragging: boolean = false;
  /** The ghost token to show the drop position of this token */
  protected _dragGhostToken: GhostMapToken | null = null;
  /** Indicates that the token is disabled, the token will still be rendered
   * normally but it's hitbox will be removed */
  protected _disabled: boolean = false;
  protected _hitboxRefreshFrame: number = Math.floor(Math.random() * 10);

  constructor(grid: unknown, tokenStoreRef: MapObjectStoreRef);
  constructor(grid: unknown, tokenId: string);
  constructor(grid: unknown, tokenInfo: MapObjectStoreRef | string) {
    super(grid, tokenInfo as any);  // `as any` to handle weird typscript nonsense
    // this._opacity = 0.7;
  }

  get disabled(): boolean {
    return this._disabled || this.getIsMaskHidden();
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }

  get isSelected(): boolean {
    return super.isSelected;
  }

  set isSelected(value: boolean) {
    if (this.isSelected !== value) {
      super.isSelected = value;
      if (value) {
        this._engine.viewParent.addEventListener('keydown', this.$b.handleKeyDownWhileSelected);
      } else {
        this._engine.viewParent.removeEventListener('keydown', this.$b.handleKeyDownWhileSelected);
      }
    }
  }

  attach(engine: CanvasEngine, layerName: string): void {
    super.attach(engine, layerName);
    const eventHitbox = this._engine.getEventHitbox(this);
    const eventTarget = eventHitbox.element;
    eventTarget.addEventListener('click', this.$b.onClick);
    eventTarget.addEventListener('pointerdown', this.$b.onPointerDown);
    eventHitbox.withFocus(this.$b.handleFocus, this.$b.handleBlur);
  }

  detach(): void {
    // For safety, remove all of the possible event listeners that have been added.
    this._engine.document.removeEventListener('pointermove', this.$b.onDraggingHandlePointerMove);
    this._engine.document.removeEventListener('pointerup', this.$b.onDraggingPointerUp);
    this._engine.document.removeEventListener('keydown', this.$b.onDraggingKeyDown);
    this._engine.viewParent.removeEventListener('keydown', this.$b.handleKeyDownWhileSelected);
    super.detach();
  }

  onClick(event: MouseEvent): void {
    // TODO: Edit on double-click
  }

  onPointerDown(event: PointerEvent): void {
    // If reserved for panning/zooming, ignore (prevent default to prevent the
    // token from becoming focused)
    if (event.metaKey || event.ctrlKey || !event.isPrimary) {
      event.preventDefault();
      return;
    }
    this._pointerDownEvent = event;
    this._engine.document.addEventListener('pointermove', this.$b.onDraggingHandlePointerMove);
    this._engine.document.addEventListener('pointerup', this.$b.onDraggingPointerUp);
    this._engine.document.addEventListener('keydown', this.$b.onDraggingKeyDown);
  }

  onDraggingHandlePointerMove(event: PointerEvent): void {
    let snapping = 0.5
    if (
      this._tokenData.token && (
        this._tokenData.token.gridWidth <= 0.5 ||
        this._tokenData.token.gridHeight <= 0.5
      )
    ) {
      snapping = 0.25
    }

    if (
      !this._pointerDownEvent ||
      this._pointerDownEvent.pointerId !== event.pointerId
    ) {
      // If the current pointer is not the one that started dragging, abort
      return;
    } else if (
      !this._tokenData.permissions.canWrite &&
      !this._tokenData.permissions.canChangeLocation
    ) {
      // User cannot write token location, ignore attempts to drag/move it
      return;
    } else if (AppGlobal.priorityEvents.isPriorityPointerEvent(event)) {
      // If the current pointer is not part of a priority event, revert
      this.finishDragging(false);
      return;
    } else if (!this._isDragging) {
      // Set up the drag interface since this is the beginning
      const dx = this._pointerDownEvent.pageX - event.pageX;
      const dy = this._pointerDownEvent.pageY - event.pageY;
      if (
        event.timeStamp - this._pointerDownEvent.timeStamp > 500 ||
        Math.sqrt((dx * dx) + (dy * dy)) > 10
      ) {
        this._isDragging = true;
        this._dragGhostToken = new GhostMapToken(this._grid, this._tokenStoreRef);
        this._dragGhostToken.attach(this._engine, 'tokens');
        this._dragGhostToken.setGridLocationFromPointer(snapping);
      }
    } else {
      // Update the dragging interface
      this._dragGhostToken.setGridLocationFromPointer(snapping);
    }
  }

  onDraggingPointerUp(event: PointerEvent): void {
    if (!this._pointerDownEvent || this._pointerDownEvent.pointerId !== event.pointerId) {
      return;
    } else if (!this._isDragging) {
      this.finishDragging(false);
    } else {
      this.finishDragging(true);
    }
  }

  onDraggingKeyDown(event: KeyboardEvent): void {
    if (event.code === "Escape") {
      this.finishDragging(false);
    }
  }

  handleFocus(event: FocusEvent): void {
    this.isFocused = true;
    this.isSelected = true;
  }

  handleBlur(event: FocusEvent): void {
    this.isFocused = false;
    this.isSelected = false;
  }

  handleKeyDownWhileSelected(event: KeyboardEvent): void {
    if (!this.isSelected) {
      console.warn("Needed to use backup selected keydown event remove", this);
      this._engine.viewParent.removeEventListener('keydown', this.$b.handleKeyDownWhileSelected);
      return;
    }
    if (event.code === 'Backspace' || event.code === 'Delete') {
      event.preventDefault();
      const tokenMapObject = this._tokenStoreRef.record;
      if (
        tokenMapObject.permissions.canWrite ||
        tokenMapObject.permissions.canChangeLocation
      ) {
        const store = this._tokenStoreRef.store as MapObjectStoreState;
        store.moveToken(tokenMapObject.id, null, null, null);
      }
      return;
    }
    console.debug(`Token ${this._tokenData.name} keyboard event`, event);
  }

  finishDragging(save) {
    this._engine.document.removeEventListener('pointermove', this.$b.onDraggingHandlePointerMove);
    this._engine.document.removeEventListener('pointerup', this.$b.onDraggingPointerUp);
    this._engine.document.removeEventListener('keydown', this.$b.onDraggingKeyDown);
    if (save) {
      this.updateTokenPosition(this._dragGhostToken);
    }
    if (this._dragGhostToken) {
      this._dragGhostToken.destroy();
      this._dragGhostToken = null;
    }
    // Reset these again since they may have been missed if dragging never
    // fully started.
    this._isDragging = false;
    this._pointerDownEvent = null;
  }

  updateTokenPosition(ghostToken: GhostMapToken): void {
    const [tokenX, tokenY] = ghostToken.getRealCoordinates();
    if (tokenX === null || tokenY === null) {
      return;
    }
    const tokenMapObject = this._tokenStoreRef.record;
    if (!tokenMapObject) {
      console.warn('No token map object available', this._tokenStoreRef.key);
      return;
    }
    const store = this._tokenStoreRef.store as MapObjectStoreState;
    const [tokenCol, tokenRow] = ghostToken.getRealGridCoordinates();
    store.moveToken(tokenMapObject.id, tokenMapObject.mapZoneId, tokenCol, tokenRow);
  }

  onUpdate(frameUpdateContext: FrameUpdateContext): void {
    super.onUpdate(frameUpdateContext);
    if (this._curX === null || this._curY === null) {
      return;
    }
    if (frameUpdateContext.frameNo % 10 === this._hitboxRefreshFrame) {
      // The getter is used here to allow for custom implementations of disabled
      if (this.disabled) {
        this._engine.setEventTargetRect(this, null);
      } else {
        this._engine.setEventTargetRect(this, this.getPaddedGridLocationRect());
      }
    }
  }

}
