import React from 'react';
import {withRouter} from 'react-router';

import AppPageTemplateConnected from 'components/templates/app-page-template-connected';
import CenteredBox from 'components/layouts/centered-box';

import NewMapForm from './components/new-map-form';

export function NewMapPage(props) {
  return (
    <AppPageTemplateConnected
        headerVisible={true}
        footerVisible={false}
    >
      <CenteredBox className='border-box' style={{width: '400px'}}>
        <h3 className='slds-text-heading_medium'>Create a new Map</h3>
        <NewMapForm
          afterSubmit={(mapData) => {
            props.history.replace(`/app/maps/${mapData.id}/details`);
          }}
          afterCancel={() => {
            props.history.goBack();
          }}
        />
      </CenteredBox>
    </AppPageTemplateConnected>
  );
}

export default withRouter(NewMapPage);
