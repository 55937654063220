import AppReactComponent from 'utils/app-react-component';
import FormHandler from 'formlib/form-handler';
import QueuedAsyncState from 'statelib/queued-async-state';
import {pathParse} from 'utils/obj-paths';

const _ROOT_PATH = pathParse('').path;

/**
 * ReactComponent that has improved functionality for working with forms.
 *
 * This takes care of some of the boilerplate for working with forms and makes
 * it easier to build the logic into the component itself.
 *
 * This object has two key instance variables available:
 *
 * - `async` is a utility if any sort of asynchornous functionality is needed.
 *   It is also automatically attached to the `async` property of state. If
 *   shared async is needed, this can be injected via `options`
 * - `form` the form handler for managing form data. The `fields` part of the
 *   form handler will be included in state at the `fields` property.
 *
 * TODO: Use `onChangeProp` to run a callback whenever the internal form data
 * changes. This allows the form to remain in sync with parent data if they
 * want it. Note that, in general, this isn't necessary since handleSubmit will
 * have the data object at submission time.
 */
export default class FormReactComponent extends AppReactComponent{

  constructor(props, options) {
    super(props);
    this.initialDataProp = options.initialDataProp || 'initialData';
    this.onChangeProp = options.onChangeProp || 'onChange';
    this.async = options.async || new QueuedAsyncState();
    this.form = new FormHandler(this.$b.dataToForm);
    this.form.onSubmit(this.$b.handleSubmit);
    this.connect('async', this.async);
    this.addCleanup(
      () => this.form.close(),
      this.form.connectFieldsToComponentState(this, 'fields'),
    );
    this.addSetup(this.$b.reset);
  }

  /**
   * Resets the form to the current value of the initial data property
   */
  reset() {
    const initialData = this.props[this.initialDataProp] || {};
    this.form.initializeData(initialData);
  }

  /**
   * Converts the underlying data model into form-field values.
   *
   * This is an abstract method that must be implemented by child classes.
   */
  dataToForm(data) {
    throw new Error('Must implement abstract method `dataToForm`');
  }

  /**
   * Handles form submission. This will be called whenever `this.form.submit()`
   * or similar function occurrs.
   */
  handleSubmit(data, form) {
    throw new Error('Must implement abstract method `handleSubmit`');
  }

}
