import Squares from 'utils/squares';

import MapRenderable from 'components/mapview/map-renderable';
import Rect from 'utils/rect';

// The width of the glow _per side_. When drawing, the glow line will be this
// many pixels in each direction
const GLOW_SIZE_FACTOR = 2;


/**
 * A non-scaled rectangle drawn over the map
 */
export default class UIRectRenderable extends MapRenderable {

  public nextRect: Rect | null = null;
  public rect: Rect | null = null;
  public maskColor: string | null = 'rgba(255, 255, 255, 1)';
  public style: string | null = 'rgba(255, 255, 255, .9)';
  public glowStyle: string | null = 'rgba(230, 230, 230, .3)';
  public fillStyle: string | null = null;
  public lineWidth: number = 2;

  constructor() {
    super();
  }

  _getLineWidthPixels(render) {
    return Math.max(1, this.lineWidth * render.pixelRatio);
  }

  _getGlowWidthPixels(render) {
    return this._getLineWidthPixels(render) + (2 * GLOW_SIZE_FACTOR * render.pixelRatio);
  }

  getRedrawRegion(render, layer) {
    if (this.rect === null || this.rect === Squares.zero()) {
      return null;
    }
    return Squares.growCentered(
      layer.getRenderSquare(this.rect), this._getGlowWidthPixels(render)
    );
  }

  render(context, layer, render) {
    if (this.rect === null) {
      return;
    }
    const renderSquare = layer.getRenderSquare(this.rect);
    const lineWidthPixels = this._getLineWidthPixels(render);
    layer.resetTransform();
    if (lineWidthPixels % 2 === 1) {
      context.translate(0.5, 0.5);
    }
    if (this.fillStyle) {
      context.fillStyle = this.fillStyle;
      context.fillRect(renderSquare.x, renderSquare.y, renderSquare.w, renderSquare.h);
    }
    if (this.glowStyle) {
      context.lineJoin = 'round';
      context.lineWidth = this._getGlowWidthPixels(render);
      context.strokeStyle = this.glowStyle;
      context.strokeRect(renderSquare.x, renderSquare.y, renderSquare.w, renderSquare.h);
    }
    if (this.style) {
      context.lineJoin = 'round';
      context.lineWidth = lineWidthPixels;
      context.strokeStyle = this.style;
      context.strokeRect(renderSquare.x, renderSquare.y, renderSquare.w, renderSquare.h);
    }
  }

}
