import classNames from 'classnames';
import React from 'react';

import AppGlobal from 'global';
import AppReactComponent from 'utils/app-react-component.js';
import DialogBox from 'components/layouts/dialog-box';
import FormHandler from 'formlib/form-handler';
import MapObjectStoreRef from 'apps/maps/state/map-object-store-ref';

import TokenAddEditForm from './token-add-edit-form';

interface PropsType {
  afterSubmit: (token: object, form: FormHandler) => void;
  afterDelete: () => void;
  campaignId: string,
  className?: string,
  tokenRef: MapObjectStoreRef,
  onCancel: () => void;
  rendered?: boolean,
  style?: object,
};

interface StateType {
  mapObject?: object,
};


/**
 * Dialog containing a form to add/edit a token
 */
export default class TokenAddEditDialog extends AppReactComponent<PropsType, StateType, any> {

  _tokenRef: MapObjectStoreRef;

  constructor(props: PropsType) {
    super(props);
    this._tokenRef = this.constantProp('tokenRef');
    this.connect('mapObject', this._tokenRef, 'record');
  }

  render() {
    const props = this.props;
    const state = this.state;
    return (
      <DialogBox
        className={classNames(props.className, {
          "dialog-box-large": true,
          "dialog-box-max-height": true,
        })}
        style={props.style}
        rendered={props.rendered}
        variant="semialpha"
      >
        <TokenAddEditForm
          afterSubmit={props.afterSubmit}
          afterDelete={props.afterDelete}
          campaignId={props.campaignId}
          onCancel={props.onCancel}
          tokenRef={props.tokenRef}
        />
      </DialogBox>
    );
  }

}
