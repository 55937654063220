import React from 'react';

/**
 * A mask that will cover all sibling content. The only thing that
 * will appear over the mask are its children
 */
export default function CenteredBox(props) {
  return (
    <div className='global-mask'>
      {props.children}
    </div>
  );
}
