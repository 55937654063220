import Memoize from 'memoize-one';


/**
 * Specialized form of arctan that calculates arc tan in 90-degree increments
 * (we use degrees instead of radians to avoid floating point math)
 *
 * TODO: Check performance of this and make sure that it's actually better than
 * just doing regular `Math.arctan2`. It's possible that special math chips
 * actually make it faster to do real arctans than to do this switch
 * statement.
 */
function _specialArcTan(x1, y1, x2, y2) {
  if (x1 === x2 && y1 === y2) {
    throw new Error('Vector has no magnitude');
  } else if (x1 === x2 && y1 > y2) {
    return 270;
  } else if (x1 === x2 && y1 < y2) {
    return 90;
  } else if (y1 === y2 && x1 > x2) {
    return 180;
  } else if (y1 === y2 && x1 < x2) {
    return 0;
  }
  throw new Error('Vector angle is not an increment of 90 degrees');
}


// TODO: Split between path segmetn (immutable) and Path Segment Node (linked
// list node containing a path segment)
class PathSegment {

  constructor(tCol, tRow, hCol, hRow) {
    this.tCol = tCol;
    this.tRow = tRow;
    this.hCol = hCol;
    this.hRow = hRow;
    this.angle = _specialArcTan(tCol, tRow, hCol, hRow);
    this.prev = null;
    this.next = null;
  }

  get headKey() {
    return `(${this.hCol},${this.hRow})`;
  }

  get tailKey() {
    return `(${this.tCol},${this.tRow})`;
  }

  get minCol() {
    return (this.tCol <= this.hCol) ? this.tCol : this.hCol;
  }

  get minRow() {
    return (this.tRow <= this.hRow) ? this.tRow : this.hRow;
  }

  get maxCol() {
    return (this.tCol >= this.hCol) ? this.tCol : this.hCol;
  }

  get maxRow() {
    return (this.tRow >= this.hRow) ? this.tRow : this.hRow;
  }

  clone() {
    return new PathSegment(this.tCol, this.tRow, this.hCol, this.hRow);
  }

  /**
   * Attaches `nextHead` to this segment as a new head segment. Combines
   * segments if necessary. Returns the new head segment based on whether
   * the segments were merged or not.
   *
   * NOTE: The merging code is currently commented out because I don't think
   * that we want it here (keeping it might make it easier to rebuild part of
   * the path later on if needed). Instead, we will use the merging technique
   * when building the "real" path to make sure that we aren't doing any extra
   * work.
   */
  attachHead(nextHead) {
    if (this.next) {
      throw new Error('Segment already has a `next` segment');
    }
    this.next = nextHead;
    nextHead.prev = this;
    return nextHead;
  }

  /**
   * Attaches `nextTail` to this segment as a new tail segment. Combines
   * segments if necessary. Returns the new tail segment based on whether
   * the segments were merged or not.
   */
  attachTail(nextTail) {
    if (this.prev) {
      throw new Error('Segment already has a `next` segment');
    }
    this.prev = nextTail;
    nextTail.next = this;
    return nextTail;
  }

}


class Path {

  constructor() {
    this.head = null;
    this.tail = null;
    this.isComplete = false;
    this.minCol = null;
    this.minRow = null;
    this.maxCol = null;
    this.maxRow = null;
    this.length = 0;
    // TODO: Optimization for rectangles. Rectangles make a lot of things
    // SIGNIFICANTLY faster (they are much easier to truncate and they don't
    // require any sort of following paths since you can simple `rect` the path
    // using the min/max col/row). Because of this, even if rectangles don't
    // occur naturally that often, it is a wothwhile optimization because it is
    // also very easy to calculate (are there exactly 4 +90deg angles). Also,
    // no idea if this is true, but there might be optimizations in the
    // underlying browser engine for clipping rectangles vs. paths.
  }

  addInitialSegment(segment) {
    if (this.head || this.tail) {
      throw new Error('Path has already been initialized with a segment');
    }
    this.head = segment;
    this.tail = segment;
    this.length += 1;
    this._adjustBoundingBox(segment);
  }

  addHeadSegment(segment) {
    if (this.head === null || this.tail === null) {
      throw new Error('Path must be initialized with a segment first');
    } else if (segment.tCol !== this.head.hCol && segment.tRow !== this.head.hRow) {
      throw new Error('Segment does not connect to path head');
    } else if (segment.hCol === this.tail.tCol && segment.hRow === this.tail.tRow) {
      throw new Error('Segment should not close path. Use `closePath` instead');
    }
    this.head = this.head.attachHead(segment);
    this.length += 1;
    this._adjustBoundingBox(segment);
  }

  addTailSegment(segment) {
    if (this.head === null || this.tail === null) {
      throw new Error('Path must be initialized with a segment first');
    } else if (segment.hCol !== this.tail.tCol && segment.hRow !== this.tail.tRow) {
      throw new Error('Segment does not connect to path tail');
    } else if (segment.tCol === this.head.hCol && segment.tRow === this.head.hRow) {
      throw new Error('Segment should not close path. Use `closePath` instead');
    }
    this.tail = this.tail.attachTail(segment);
    this.length += 1;
    this._adjustBoundingBox(segment);
  }

  closePath(segment) {
    if (this.head === null || this.tail === null) {
      throw new Error('Path must be initialized with a segment first');
    } else if (segment.hCol !== this.tail.tCol && segment.hRow !== this.tail.tRow) {
      throw new Error('Segment does not connect to path tail');
    } else if (segment.tCol !== this.head.hCol && segment.tRow !== this.head.hRow) {
      throw new Error('Segment does not connect to path head');
    }
    this.head = this.head.attachHead(segment);
    this.length += 1;
    this._adjustBoundingBox(segment);
    this._complete();
  }

  addPathToHead(otherPath) {
    // TODO: This is a garbage (but safe) implementation. It could be HEAVILY
    // optimized
    let segment;  // Declare early so we can do one final iteration
    for (segment = otherPath.tail; segment.next; segment = segment.next) {
      this.addHeadSegment(segment.clone());
    }
    this.addHeadSegment(segment.clone());
  }

  /**
   * Converts the path data into a Path2D object which can be used for drawing
   * to canvases.
   */
  toPath2D = Memoize(() => {
    // TODO: This may need to do some sort of "truncation" later on
    // (basically ignore paths outside of the renderable area). Normally you
    // can just ignore anything outside BUT it gets a little wird if it leave
    // the area then comes back in to create an "island". Will need to figure
    // out how to handle that case.
    if (!this.tail || !this.head) {
      throw new Error('Path is incomplete or in a bad state');
    } else if (!this.isComplete) {
      throw new Error('Path must be complete before converting to Path2D');
    }
    const path2d = new Path2D();
    path2d.moveTo(this.tail.tCol, this.tail.tRow);
    // Connect all of the segments together. Be sure to seamlessly connect any
    // co-lienar segments (if the next segment is co-linear, the `lineTo` can
    // be safely deferred to that segment)
    for (let segment = this.tail; segment !== null; segment = segment.next) {
      if (segment.next === null || segment.angle !== segment.next.angle) {
        path2d.lineTo(segment.hCol, segment.hRow);
      }
    }
    // In general, this should be a no-op since the path _should_ be closed.
    // However, close the path here for safety anyway.
    path2d.closePath();
    return path2d;
  });

  _adjustBoundingBox(segment) {
    if (this.minCol === null || segment.minCol < this.minCol) {
      this.minCol = segment.minCol;
    }
    if (this.minRow === null || segment.minRow < this.minRow) {
      this.minRow = segment.minRow;
    }
    if (this.maxCol === null || segment.maxCol > this.maxCol) {
      this.maxCol = segment.maxCol;
    }
    if (this.maxRow === null || segment.maxRow > this.maxRow) {
      this.maxRow = segment.maxRow;
    }
  }

  /**
   * Completes the path and freezes the object so that it can no longer be
   * modified.
   */
  _complete() {
    this.isComplete = true;
    Object.freeze(this);
  }

}


/**
 * Collection of paths representing borders in a rasterized image between "on"
 * pixels and "off" pixels.
 *
 * Add segments that are borders between ON and OFF pixels. Segments MUST go
 * clockwise around ON pixels. This invariant allows us to make certain
 * assumptions about the system.
 *
 * 1. There are only two scenarios in which paths can share the same head and
 *    tail position (checkerboard scenario). Because these are specific cases,
 *    we can carve out exceptions. In all other cases no two paths will ever
 *    share the same head or the same tail positions.
 */
class PathCollection {

  constructor() {
    this.openPaths = new Set();
    this.completePaths = new Set();
  }

  get hasPaths() {
    return this.completePaths.size > 0;
  }

  /**
   * Returns the Path (if available) with an open head connection at col, row.
   */
  _getOpenHead(col, row) {
    for (const path of this.openPaths) {
      if (!path.isComplete && path.head.hCol === col && path.head.hRow === row) {
        return path;
      }
    }
    return null;
  }

  /**
   * Returns the Path (if available) with an open tail connection at col, row.
   */
  _getOpenTail(col, row) {
    for (const path of this.openPaths) {
      if (!path.isComplete && path.tail.tCol === col && path.tail.tRow === row) {
        return path;
      }
    }
    return null;
  }

  addSegment(segment) {
    const matchingTail = this._getOpenTail(segment.hCol, segment.hRow);
    const matchingHead = this._getOpenHead(segment.tCol, segment.tRow);
    if (matchingHead && matchingTail) {
      if (matchingHead === matchingTail) {
        // This should result in a completed path
        matchingHead.closePath(segment);
        if (!matchingHead.isComplete) {
          throw new Error('Expected segment to complete path');
        }
        this.completePaths.add(matchingHead);
        this.openPaths.delete(matchingHead);
      } else {
        // Join the two paths into one and "delete" the other.
        matchingHead.addHeadSegment(segment);
        matchingHead.addPathToHead(matchingTail);
        this.openPaths.delete(matchingTail);
      }
    } else if (matchingHead) {
      matchingHead.addHeadSegment(segment);
    } else if (matchingTail) {
      matchingTail.addTailSegment(segment);
    } else {
      const newPath = new Path();
      newPath.addInitialSegment(segment);
      this.openPaths.add(newPath);
    }
  }

  addNewSegment(tCol, tRow, hCol, hRow) {
    this.addSegment(new PathSegment(tCol, tRow, hCol, hRow));
  }

  throwIfIncomplete() {
    for (const path of this.openPaths) {
      if (!path.isComplete) {
        throw new Error('One or more paths were not closed');
      }
    }
    for (const path of this.completePaths) {
      if (!path.isComplete) {
        throw new Error('One or more paths were not closed');
      }
    }
  }

  toPath2D = Memoize(() => {
    // TODO: Need to figure out truncation. That will mostly consist of
    // truncating the individual paths as well as ignoring any that are outside
    // of the view port.
    const combinedPath = new Path2D();
    for (const path of this.completePaths) {
      combinedPath.addPath(path.toPath2D());
    }
    return combinedPath;
  });
}


export function rasterToPathCollection(getCellValue, width, height) {
  const pathCollection = new PathCollection();
  // Begin by normalizing/pre-caching all of the cell values. Note that we must
  // look at all cells at least once anyway, so this isn't a huge extra cost
  // (although maybe we should test if it actually saves any time vs. just
  // calling `getCellValue` over and over - it could be that the worst case of
  // calling `getCellValue` 5 times per cell is actually faster than generating
  // this array)
  const finalWidth = width;
  const finalHeight = height;
  const cellValues = new Uint8Array(finalWidth * finalHeight);
  for (let row = 0; row < finalHeight; row++) {
    for (let col = 0; col < finalWidth; col++) {
      // By default, Uint8Array is filled with 0s, so only set if it's a 1
      if (getCellValue(col, row)) {
        cellValues[(row * finalWidth) + col] = 1;
      }
    }
  }
  const getCell = (col, row) => {
    if (col < 0 || col >= finalWidth || row < 0 || row >= finalHeight) {
      return 0;
    }
    return cellValues[(row * finalWidth) + col];
  }
  // Loop through all of the cells, adding paths (clockwise) surrounding any
  // "true" cells
  for (let row = 0; row < finalHeight; row++) {
    for (let col = 0; col < finalWidth; col++) {
      const cell = getCell(col, row);
      if (cell === 0) {
        continue;
      }
      // Check north edge
      if (getCell(col, row - 1) === 0) {
        pathCollection.addNewSegment(col, row, col + 1, row);
      }
      // Check south edge
      if (getCell(col, row + 1) === 0) {
        pathCollection.addNewSegment(col + 1, row + 1, col, row + 1);
      }
      // Check west edge
      if (getCell(col - 1, row) === 0) {
        pathCollection.addNewSegment(col, row + 1, col, row);
      }
      // Check east edge
      if (getCell(col + 1, row) === 0) {
        pathCollection.addNewSegment(col + 1, row, col + 1, row + 1);
      }
    }
  }
  pathCollection.throwIfIncomplete();
  return pathCollection;
}
