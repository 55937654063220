import React from 'react';

import { makeRpcCall } from 'utils/async';
import { users as usersPb, common as commonPb } from 'proto-bundle';

import ReactComponent from 'utils/react-component';

import SessionInfo from './session-info';

const checkSessionRpc = makeRpcCall(
  '/rpc/users/v1/check_session',
  commonPb.EmptyRequest,
  usersPb.Session,
);


// TOOD: Remove fat-arrow functions and use bound methods insteac
export default class SessionInfoConnected extends ReactComponent {

  constructor() {
    super();
    this.state = {
      isLoading: null,
      curSession: null,
    };
    this.addSetup(this.refreshSession);
  }

  refreshSession = async () => {
    this.setState({curSession: null, isLoading: true})
    try {
      let res = await checkSessionRpc({});
      this.setState({
        curSession: {
          isAuthenticated: !!res.id,
          message: `User ${res.user.emailAddress} is logged in`,
          userId: res.userId,
          sessionId: res.id,
          expiresAt: res.expiresAt,
        },
        isLoading: false,
      });
    } catch (err) {
      this.setState({
        curSession: {
          isAuthenticated: false,
          message: 'Error occurred getting session: ' + err,
          userId: null,
          sessionId: null,
          expiresAt: null,
        },
        isLoading: false,
      });
      throw err;
    }
  }

  render = () => {
    return (
      <SessionInfo
        isLoading={this.state.isLoading}
        sessionData={this.state.curSession}
      />
    );
  }

}
