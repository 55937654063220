import React from 'react';

import {DataTableCell} from '@salesforce/design-system-react';

import IdDisplay from './id-display.view';

export default function IdDisplayTableCell({
  item,
  property,
  ...props
}) {
  return (
    <DataTableCell {...props}>
      <IdDisplay value={item[property]} />
    </DataTableCell>
  );
}

IdDisplayTableCell.displayName = DataTableCell.displayName;
