import MapObject from 'components/mapview/map-object';
import Shapes from 'utils/geometry/shapes';
import Squares from 'utils/squares';


/**
 * Highlights the currently hovered cell in a grid
 */
export default class InterfaceGridAreaHighlight extends MapObject {

  constructor(grid) {
    super();
    this.grid = grid || null;
    this.style = 'rgba(255, 255, 255, .9)';
    this.glowStyle = 'rgba(230, 230, 230, .3)';
    this.fillStyle = null;
    this.lineWidth = 1;
    this._areaSquare = null;
    this._startX = null;
    this._startY = null;
  }

  isEnabled() {
    return !!(this.enabled && this.grid && this._startX && this._startY);
  }

  isVisible() {
    return !!(this.visible && this._areaSquare);
  }

  setStartingLocation(cursorX, cursorY) {
    this._startX = cursorX;
    this._startY = cursorY;
  }

  unsetStartingLocation() {
    this._startX = null;
    this._startY = null;
  }

  onUpdate(timestamp, timeDeltaMs, frameNo, render, layer) {
    const controller = this._mapViewController;
    const grid = this.grid;
    const cursorX = controller.getCursorX();
    const cursorY = controller.getCursorY();
    let nextCellSquare = null;
    if (cursorX !== null && cursorY !== null) {
      const selSquare = Squares.fromCoordinates(
        this._startX, this._startY, controller.getCursorX(), controller.getCursorY()
      );
      const minCell = Shapes.GridCell.fromPixelCoordinates(grid, selSquare.x, selSquare.y);
      const maxCell = Shapes.GridCell.fromPixelCoordinates(grid, selSquare.x2, selSquare.y2);
      nextCellSquare = Squares.fromCoordinates(
        minCell.containingSquare.x,
        minCell.containingSquare.y,
        maxCell.containingSquare.x2,
        maxCell.containingSquare.y2,
      );
    }
    if (this._areaSquare !== nextCellSquare) {
      if (this._areaSquare) {
        layer.setRedrawSource(Squares.growCentered(this._areaSquare, this.lineWidth * 2));
      }
      if (nextCellSquare) {
        layer.setRedrawSource(Squares.growCentered(nextCellSquare, this.lineWidth * 2));
      }
    }
    this._areaSquare = nextCellSquare;
  }

  onRender(context, layer, render) {
    const renderSquare = layer.getRenderSquare(this._areaSquare);
    const lineWidthPixels = Math.max(1, this.lineWidth * render.pixelRatio);
    layer.resetTransform();
    if (lineWidthPixels % 2 === 1) {
      context.translate(0.5, 0.5);
    }
    if (this.glowStyle) {
      context.lineWidth = lineWidthPixels + (2 * render.pixelRatio);
      context.strokeStyle = this.glowStyle;
      context.strokeRect(renderSquare.x, renderSquare.y, renderSquare.w, renderSquare.h);
    }
    if (this.fillStyle) {
      context.fillStyle = this.fillStyle;
      context.fillRect(renderSquare.x, renderSquare.y, renderSquare.w, renderSquare.h);
    }
    if (this.style) {
      context.lineWidth = lineWidthPixels;
      context.strokeStyle = this.style;
      context.strokeRect(renderSquare.x, renderSquare.y, renderSquare.w, renderSquare.h);
    }
  }
}
