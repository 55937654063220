import CampaignsListPage from './campaigns-list-page';
import EmbarkPage from './embark-page';
import MapDetailsPage from './map-details-page';
import MapEditPage from './map-edit-page';
import MapZoneConfigPage from './map-zone-config-page';
import MapZoneDetailsPage from './map-zone-details-page';
import NewCampaignPage from './new-campaign-page';
import NewMapPage from './new-map-page';
import NewZonePage from './new-map-zone-page';
import UpdateMapZoneImagePage from './update-map-zone-image-page';

export default {
  CampaignsListPage,
  EmbarkPage,
  MapDetailsPage,
  MapEditPage,
  MapZoneConfigPage,
  MapZoneDetailsPage,
  NewCampaignPage,
  NewMapPage,
  NewZonePage,
  UpdateMapZoneImagePage,
};
