import classNames from 'classnames';
import Hammer from 'hammerjs';
import PropTypes from 'prop-types';
import React from 'react';

import memoBind from 'utils/memo-bound';
import ReactComponent from 'utils/react-component';

const _emptyStyle = Object.freeze({});
const _noopStyle = () => _emptyStyle;
const _noopRef = () => undefined;


/**
 * A table component that allows re-ordering rows.
 *
 * A row can be dragged into a new position within the table. When the user
 * releases the row, a callback will execute which should move rearrange the
 * values
 *
 * `getRowChildren` takes a row object and should return the INNER content
 * for that row (not including the tr)
 */
export default class TableOrderable extends ReactComponent {

  static propTypes = {
    items: PropTypes.array.isRequired,
    getHeaderChildren: PropTypes.func,
    getRowChildren: PropTypes.func.isRequired,
    onChangeOrder: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      grabbedItemIdx: null,
      insertAfterIdx: null,  // Can be -1 to insert at front of list
      lastInsertAfterIdx: null,  // Can be -1 to insert at front of list
      insertAnimationStartMs: null,
      insertAnimationMs: null,
    };
    this._rootNode = null;
    this._rowNodes = {};  // item idx -> row node mapping
    this._hammers = new WeakMap();
    // TODO: In render, you need to have `ref={...}` to point to the on X node
    // change functions. In addition, it makes sense to pre-cache bound instances
    // for each of the row callbacks (via memoize once or something)
    // TODO: Rest of the implementation: When someone drags the left side of
    // the row (the row should have padding / special column on the left side)
    // Add a style to the original row (grabbedItemIdx) so that it is gray.
    // insert a new row as necessary based on the `insertAfterIdx`. Height %
    // should be variable based on insertAnimationMs
    this.addCleanup(
      this.$b._removeAllRowHammerEvents,
      this.$b._removeRootHammerEvents,
      () => {this._hammers = null},
    );
  }

  _onRootNodeChange(node) {
    // React sends through `null` even when the dom element is unchanged
    if (node === null || node === this._rootNode) {
      return;
    }
    this._removeRootHammerEvents();
    this._rootNode = node;
    this._addRootHammerEvents();
  }

  _onRowNodeChange(rowIdx, node) {
    // React sends through `null` even when the dom element is unchanged
    if (node === null || node === this._rowNodes[rowIdx]) {
      return;
    }
    this._removeRowHammerEvents(rowIdx);
    this._rowNodes[rowIdx] = node;
    this._addRowHammerEvents(rowIdx);
  }

  _removeRootHammerEvents() {
    if (!this._rootNode || !this._hammers) {
      return;
    }
    const hammerMgr = this._hammers.get(this._rootNode);
    if (hammerMgr !== undefined) {
      this._hammers.delete(this._rootNode);
      hammerMgr.destroy();  // TODO: Need to do more?
    }
  }

  _addRootHammerEvents() {
    if (!this._rootNode || !this._hammers) {
      return;
    }
    // TODO: Not actually sure that root events are needed
  }

  _removeRowHammerEvents(itemIdx) {
    const rowNode = this._rowNodes[itemIdx];
    if (!rowNode || !this._hammers){
      return;
    }
    const hammerMgr = this._hammers.get(this._rootNode);
    if (hammerMgr !== undefined) {
      this._hammers.delete(this._rootNode);
      hammerMgr.destroy();  // TODO: Need to do more?
    }
  }

  _addRowHammerEvents(itemIdx) {
    const rowNode = this._rowNodes[itemIdx];
    if (!rowNode || !this._hammers){
      return;
    }
    const hammerMgr = Hammer(rowNode);
    this._hammers.set(rowNode, hammerMgr);
    hammerMgr.add(new Hammer.Pan({
      direction: Hammer.DIRECTION_ALL,
      threshold: 0,
    }));
    hammerMgr.on('pan', memoBind(this.$b._handleRowDragAndDrop, this, itemIdx));
  }

  _removeAllRowHammerEvents() {
    for (const key of Object.keys(this._rowNodes)) {
      this._removeRowHammerEvents(key);
    }
  }

  _handleRowDragAndDrop(itemIdx, hammerEvt) {
    const isStart = this.state.grabbedItemIdx === null;
    let grabbedItemIdx;
    if (isStart) {
      this.setState({grabbedItemIdx: itemIdx});
      grabbedItemIdx = itemIdx;
    } else {
      grabbedItemIdx = this.state.grabbedItemIdx;
    }

    if (hammerEvt.isFinal) {
      const movedItemIdx = this.state.grabbedItemIdx;
      const insertAfterIdx = this.state.insertAfterIdx;
      this.setState({
        grabbedItemIdx: null,
        insertAfterIdx: null,
        lastInsertAfterIdx: null,
      });
      if (
        insertAfterIdx === null
        || movedItemIdx === insertAfterIdx
        || movedItemIdx - 1 === insertAfterIdx
      ) {
        // Moving an item before or after its current position is a no-op
        console.info('Item is not moved');
      } else {
        // Callback for the changed order. Note that both item indexes refer to
        // the list BEFORE the item was removed.
        console.info(`Item ${movedItemIdx} moved after ${insertAfterIdx}`);
        this.props.onChangeOrder(movedItemIdx, insertAfterIdx);
      }
    } else {
      // Check the event position against each row.
      let nextInsertAfterIdx = null;
      for (let i = 0; i < this.props.items.length; i++) {
        const domNode = this._rowNodes[i];
        const boundingRect = domNode.getBoundingClientRect();
        const nodeTop = Math.floor(boundingRect.top);
        const nodeBottom = Math.floor(boundingRect.bottom);
        const nodeMid = Math.floor((boundingRect.top + boundingRect.bottom) / 2);
        const eventY = Math.floor(hammerEvt.center.y);
        if (i === grabbedItemIdx) {
          if (eventY >= nodeTop && eventY < nodeBottom) {
            nextInsertAfterIdx = i - 1;
          }
        } else if (i === 0) {
          if (eventY < nodeMid) {
            nextInsertAfterIdx = i - 1;
          } else if (eventY >= nodeMid && eventY < nodeBottom) {
            nextInsertAfterIdx = i;
          }
        } else if (i === this.props.items.length - 1) {
          if (eventY >= nodeTop && eventY < nodeMid) {
            nextInsertAfterIdx = i - 1;
          } else if (eventY >= nodeMid) {
            nextInsertAfterIdx = i;
          }
        } else {
          if (eventY >= nodeTop && eventY < nodeMid) {
            nextInsertAfterIdx = i - 1;
          } else if (eventY >= nodeMid && eventY < nodeBottom) {
            nextInsertAfterIdx = i;
          }
        }
        if (nextInsertAfterIdx !== null) {
          break;
        }
      }
      if (nextInsertAfterIdx !== null && nextInsertAfterIdx !== this.state.insertAfterIdx) {
        const nextState = {};
        if (this.state.insertAfterIdx !== null) {
          nextState.lastInsertAfterIdx = this.state.insertAfterIdx;
        }
        nextState.insertAfterIdx = nextInsertAfterIdx;
        this.setState(nextState);
      }
    }
  }

  _beginAnimation(frameMs) {
    if (grabbedItemIdx === null || lastInsertAfterIdx === null) {
      return;
    }
    if (this.state.insertAnimationMs >= 500) {
      return;
    }
    this.setState(
      {insertAnimationMs: Math.min(500, frameMs - insertAnimationStartMs)},
      () => requestAnimationFrame(this.$b._beginAnimation)
    );
  }

  render() {
    const getHeaderChildren = this.props.getHeaderChildren;
    const grabbedItemIdx = this.state.grabbedItemIdx;
    return (
      <table
        className={classNames({
          'slds-table': true,
          'slds-table_cell-buffer': true,
          'slds-table_bordered': true,
          'slds-no-row-hover': grabbedItemIdx !== null,
        })}
        ref={this.$b._onRootNodeChange}
      >
        <thead>
          <tr className={classNames('slds-line-height_reset')}>
            {getHeaderChildren()}
          </tr>
        </thead>
        <tbody>
          {this._getAllRowChildren()}
        </tbody>
      </table>
    );
  }

  _getAllRowChildren() {
    const items = this.props.items || [];
    const grabbedItemIdx = this.state.grabbedItemIdx;
    const grabbedItem = (grabbedItemIdx === null) ? null : this.props.items[grabbedItemIdx];
    const insertAfterIdx = this.state.insertAfterIdx;
    const rowChildren = [];
    // Handle special case of inserting the item at the front of the list
    if (insertAfterIdx === -1 && grabbedItemIdx !== 0) {
      rowChildren.push(this._getRow(grabbedItem, grabbedItemIdx, false, true));
    }
    for (let itemIdx = 0; itemIdx < items.length; itemIdx++) {
      const item = items[itemIdx];
      const isGrabbedCurrentRow = (itemIdx === this.state.grabbedItemIdx)
      const isInsertCurrentRow = (
        isGrabbedCurrentRow
        && (insertAfterIdx === itemIdx -1 || insertAfterIdx === itemIdx)
      );
      rowChildren.push(this._getRow(
        item, itemIdx, isGrabbedCurrentRow, isInsertCurrentRow
      ));
      // Handle the insert row after the current item
      if (
        itemIdx === insertAfterIdx
        && grabbedItemIdx !== itemIdx
        && grabbedItemIdx !== itemIdx + 1
      ) {
        rowChildren.push(this._getRow(grabbedItem, grabbedItemIdx, false, true));
      }
    }
    return rowChildren;
  }

  _getRow(item, itemIdx, isGrabbed, isInsert) {
    const getRowChildren = this.props.getRowChildren;
    const getRowStyle = this.props.getRowStyle || _noopStyle;
    const baseKey = `${item.key || item.id || 'X'}__${itemIdx}`;
    const key = isInsert ? `${baseKey}__ins` : baseKey;
    const ref = (isInsert) ? _noopRef : memoBind(
      this._onRowNodeChange, this, itemIdx
    );
    return (
      <tr
        ref={ref}
        key={key}
        className={classNames({
          'slds-line-height_reset': true,
          'table_orderable-dragging': isGrabbed && !isInsert,
          'table_orderable-insert': isInsert,
        })}
        style={getRowStyle(item, itemIdx)}
      >
        {getRowChildren(item, itemIdx)}
      </tr>
    );
  }

  _renderInsertRow(getRowChildren) {
    const itemIdx = this.state.grabbedItemIdx;
    if (itemIdx === null) {
      console.warn('Attempted to render grabbed row but no row grabbed');
      return null;
    }
    const item = this.props.items[itemIdx];
    const key = `${item.key || item.id}__${itemIdx}__ins`;
    return (
      <tr
        key={key}
        className={classNames({
          'slds-line-height_reset': true,
          'table_orderable-insert': true,
        })}
      >
        <td>&nbsp;</td>
        {getRowChildren(item, itemIdx)}
      </tr>
    );
  }

}
