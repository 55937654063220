
function byteToHex(b) {
  return b.toString(16).padStart(2, '0');
}


export function isPlainObject(obj) {

  // Basic check for Type object that's not null
  if (typeof obj == 'object' && obj !== null) {

    // If Object.getPrototypeOf supported, use it
    if (typeof Object.getPrototypeOf == 'function') {
      var proto = Object.getPrototypeOf(obj);
      return proto === Object.prototype || proto === null;
    }

    // Otherwise, use internal class
    // This should be reliable as if getPrototypeOf not supported, is pre-ES5
    return Object.prototype.toString.call(obj) == '[object Object]';
  }

  // Not an object
  return false;
}


/**
 * Performs a simlple shallow compare between two objects. This can be useful
 * when deciding whether or not a given object has changed and should be
 * updated. It is definitely more expensive than doing `===`, so use it only
 * when needed.
 *
 * One such case when this is useful is when updating a react component state -
 * any state change requires a re-render, so checking if the object properties
 * have actually changed before setting state can save a LOT more cycles than
 * it costs to do the compare.
 */
export function shallowEqual(obj1, obj2) {
  if (obj1 === obj2) {
    // If two objects are strict equal, then they are automatically shallow
    // equal so we can avoid doing extra checks
    return true;
  } else if (!obj1 !== !obj2) {
    // If the truthiness of the two object is NOT the same, then the objects
    // will not shallow-equal either. This is primarily to test for one of the
    // objects being null and the other a proper object
    return false;
  }
  // Otherwise, test that the objects have the same number of keys and that the
  // key/value pairs are equivalent.
  return (
    Object.keys(obj1).length === Object.keys(obj2).length
    && Object.keys(obj1).every(key => obj1[key] === obj2[key])
  );
}

export function isUsableNumber(num) {
  return num !== null && num !== undefined && !isNaN(num);
}


/**
 * Returns true if num1 and num2 are roughly equal - in other words, they are
 * within 0.01% of each other. This helps deal with floating point precision
 * issues.
 */
export function almostEqual(num1, num2) {
  if (num1 === num2) {
    return true;
  } else if ((num1 === null || num1 === undefined) && (num2 === null || num2 === undefined)) {
    return true;
  } else if (num1 === null || num1 === undefined || num2 === null || num2 === undefined) {
    return false;
  }
  return Math.min(num1, num2) > Math.max(num1, num2) * 0.9999;
}


export function cleanFloat(num, maxFixedPrecision) {
  if (typeof(num) === 'string') {
    num = Number(num);
  }
  if (num === null || num === undefined || isNaN(num)) {
    return '';
  }
  return num.toFixed(maxFixedPrecision).replace(/\.?[0]+$/, '');
}


export async function hashBytes(byteArray) {
  if (!byteArray) {
    return '';
  } else if (crypto && crypto.subtle) {
    return await _secureHashBytes(byteArray);
  } else {
    // For running in non HTTPS environment (e.g. connecting to a local instance
    // without localhost)
    return _cheesyUnsecureHashBytes(byteArray);
  }
}


async function _secureHashBytes(byteArray) {
  const hashBuffer = await crypto.subtle.digest('SHA-1', byteArray);
  const hashBytes = new Uint8Array(hashBuffer);
  // Not my favorite way to convert bytes to hex, but it's short so should be fine
  return Array.from(hashBytes).map(byteToHex).join('');
}


function _cheesyUnsecureHashBytes(byteArray) {
  // Taken from https://stackoverflow.com/a/8831937/703040
  let hash = 0;
  for (let i = 0, len = byteArray.length; i < len; i++) {
    let chr = byteArray[i];
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
