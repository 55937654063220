import React from 'react';
import classNames from 'classnames';


/**
 * A very basic container that displays its two children side-by-side. By
 * default, the children will take up equal space.
 *
 * TODO: Do we want an optional spacer between the panels? Might not be worth
 * it since we could just apply classes to the panels themselves
 *
 * @param {string} className A `className` value applied to the container
 * @param {string} classNamePanel A `className` value applied to both of the
 *   panels.
 * @param {string} classNamePanel1 A `className` value applied only to the
 *   panel containing the first child
 * @param {string} classNamePanel2 A `className` value applied only to the
 *   panel containing the second child
 */
export default function TwoPanelLayout({
  id,
  children,
  className,
  classNamePanel,
  classNamePanel1,
  classNamePanel2,
  style,
}) {
  if (React.Children.count(children) !== 2) {
    throw new Error('The two-panel layout requires exactly two children');
  }
  const childrenArray = React.Children.toArray(children);
  return (
    <div
      id={id}
      className={classNames(
        'layout--positioned-box',
        'flex--container-horizontal',
        className,
      )}
      style={style}
    >
      <div
        id={id ? `${id}-panel-1` : null}
        className={classNames(
          'flex--item-fill',
          classNamePanel,
          classNamePanel1,
        )}
      >
        {childrenArray[0]}
      </div>
      <div
        id={id ? `${id}-panel-2` : null}
        className={classNames(
          'flex--item-fill',
          classNamePanel,
          classNamePanel2,
        )}
      >
        {childrenArray[1]}
      </div>
    </div>

  );
}
