import React from 'react';

import AppPageTemplateConnected from 'components/templates/app-page-template-connected';
import CenteredBox from 'components/layouts/centered-box';
import OrionLayout from 'components/layouts/orion-layout';
import MapZoneConfigNav from 'apps/maps/components/map-zone-config-nav';

import MapZoneImageForm from './components/map-zone-image-form';
import SharedMapDetailsLoader from './components/shared-map-details-loader';

import ReactComponent from 'utils/react-component';
import shared from 'global';
import {connectToComponentState} from 'statelib/fstate-react';


function UpdateMapZoneImagePageView({
  mapId,
  zoneId,
  onSuccess,
  onCancel,
}) {
  return (
    <AppPageTemplateConnected
      headerVisible={true}
      footerVisible={false}
    >
      <SharedMapDetailsLoader>
        <OrionLayout
          style={{height:'100%', width:'100%'}}
        >
          <div style={{display: 'flex', flexDirection: 'row', height: '100%', width: '100%'}}>
            <CenteredBox
              className='border-box flex--container-vertical'
              style={{width: '40rem', maxWidth: '90%', height: '35rem', maxHeight: '90%'}}
            >
              <h3 className='slds-text-heading_medium'>Update Zone Image</h3>
              <MapZoneImageForm
                className='flex--item-fill'
                mapId={mapId}
                zoneId={zoneId}
                afterSubmit={onSuccess}
                afterCancel={onCancel}
              />
            </CenteredBox>
            <div style={{ width: '230px', height: '100%', flex: '0 1 auto'}}>
              {/* if */(mapId && zoneId) ? (
                <MapZoneConfigNav
                  selectImageState='Done'
                  selectGridState='NotReady'
                  selectBoundariesState='NotReady'
                  currentStep='SelectImage'
                  mapId={mapId}
                  mapZoneId={zoneId}
                />
              )/* endif */ : null}
            </div>
          </div>
        </OrionLayout>

      </SharedMapDetailsLoader>
    </AppPageTemplateConnected>
  );
}


export default class UpdateMapZoneImagePage extends ReactComponent {

  constructor() {
    super();
    connectToComponentState(shared.rootRouter, this, 'router');
  }

  handleSuccess(mapZoneData) {
    const mapId = this.state.router.params.mapId;
    const zoneId = this.state.router.params.zoneId;
    shared.mapDetails.refresh();
    this.props.history.replace(`/app/maps/${mapId}/zones/${zoneId}/configure/grid`);
  }

  handleCancel() {
    const mapId = this.state.router.params.mapId;
    shared.mapDetails.refresh();
    this.props.history.replace(`/app/maps/${mapId}/details`);
  }

  render() {
    return UpdateMapZoneImagePageView({
      mapId: this.state.router.params.mapId,
      zoneId: this.state.router.params.zoneId,
      onSuccess: this.$b.handleSuccess,
      onCancel: this.$b.handleCancel,
    });
  }
}
