import Shapes from 'utils/geometry/shapes';
import Squares from 'utils/squares';

import BaseMaskPainterObject from 'components/mapview/map-objects/base-mask-painter-object';
import ToolUtils from 'components/mapview/map-objects/tool-utils';
import UIRectRenderable from 'components/mapview/map-objects/ui-rect-renderable';

const STATE_HOVERING = 'STATE_HOVERING';
const STATE_DRAGGING = 'STATE_DRAGGING';
const STATE_NEEDS_DRAW = 'STATE_NEEDS_DRAW';


/**
 * Map Object that can handle painting single cell areas onto visibility masks.
 */
export default class MaskPainterRect extends BaseMaskPainterObject {

  constructor() {
    super();
    this._state = STATE_HOVERING;
    this._stopDragging
    this._startCol = null;
    this._startRow = null;
    this._col = null;
    this._row = null;
    this._paintRect = null;
    this._uiRect = new UIRectRenderable();
  }

  isVisible() {
    return this._paintRect && super.isVisible();
  }

  handleDragBegin() {
    const controller = this._mapViewController;
    this._state = STATE_DRAGGING;
    const [col, row] = ToolUtils.getCursorCellColRow(controller, this.grid);
    this._startCol = col;
    this._startRow = row;
  }

  handleDragEnd() {
    this._state = STATE_NEEDS_DRAW;
  }

  onUpdate(timestamp, timeDeltaMs, frameNo, render, layer) {
    super.onUpdate(timestamp, timeDeltaMs, frameNo, render, layer);
    const controller = this._mapViewController;
    let col, row;
    if (this._state === STATE_NEEDS_DRAW) {
      col = this._col;
      row = this._row;
    } else {
      [col, row] = ToolUtils.getCursorCellColRow(controller, this.grid);
    }
    // Update the internal state if something has actually changed
    if (this._col === col && this._row === row && this._state !== STATE_NEEDS_DRAW) {
      return;
    }
    this._col = col;
    this._row = row;
    layer.setRedraw(this._uiRect.getRedrawRegion(render, layer));  // Erase previous
    this._paintRect = null;
    if (this._state === STATE_HOVERING || this._state === STATE_NEEDS_DRAW) {
      if (this._col !== null && this._row !== null) {
        this._paintRect = Shapes.GridCell.instance(this.grid, col, row).containingSquare;
      }
    } else {
      if (
        this._startCol !== null &&
        this._startRow !== null &&
        this._col !== null &&
        this._row !== null
      ) {
        this._paintRect = Squares.getContainingSquare(
          Shapes.GridCell.instance(this.grid, this._startCol, this._startRow).containingSquare,
          Shapes.GridCell.instance(this.grid, this._col, this._row).containingSquare,
        );
      }
    }
    this._uiRect.rect = this._paintRect;
    layer.setRedraw(this._uiRect.getRedrawRegion(render, layer));
    // Paint the mask if there is something to paint
    if (this._state !== STATE_NEEDS_DRAW) {
      return;
    }
    this._state = STATE_HOVERING;
    if (!this._paintRect) {
      return;
    }
    if (Squares.hasIntersection(this._paintRect, controller.getImageSquare())) {
      this.updateMask((mask, context, erase) => {
        const startCellSq = mask.gridCellToMaskSq(this._startCol, this._startRow);
        const endCellSq = mask.gridCellToMaskSq(this._col, this._row);
        const paintSq = Squares.getContainingSquare(startCellSq, endCellSq);
        if (erase) {
          context.clearRect(paintSq.x, paintSq.y, paintSq.w, paintSq.h);
        } else {
          context.fillStyle = this.maskColor;
          context.clearRect(paintSq.x, paintSq.y, paintSq.w, paintSq.h);
          context.fillRect(paintSq.x, paintSq.y, paintSq.w, paintSq.h);
        }
        return paintSq;
      }, mask => {
        const startCellSq = mask.gridCellToMaskSq(this._startCol, this._startRow);
        const endCellSq = mask.gridCellToMaskSq(this._col, this._row);
        return Squares.getContainingSquare(startCellSq, endCellSq);
      });
    }
  }

  onRender(context, layer, render) {
    this._uiRect.render(context, layer, render);
  }

}
