import memoizeOne from 'memoize-one';

import AppGlobal from 'global';
import Bitmap from 'utils/graphics/bitmap';
import CanvasEngine from 'canvasengine/canvas-engine';
import DynamicBitmap from 'utils/graphics/dynamic-bitmap';
import FrameRenderContext from 'canvasengine/frame-render-context';
import FrameUpdateContext from 'canvasengine/frame-update-context';
import FStateListener from 'statelib/fstate-listener';
import GameObject from 'canvasengine/gameobjects/game-object';
import MapImageStoreRef from 'apps/maps/state/map-image-store-ref';
import MapImageStoreState from 'apps/maps/state/map-image-store-state';
import MapObjectStoreRef from 'apps/maps/state/map-object-store-ref';
import mathutils from 'utils/math-utils';
import Pullable from 'utils/geometry/pullable';
import Rect from 'utils/rect';
import Squares from 'utils/squares';
import StaticImages from 'utils/graphics/static-images';

import GameObjectSelectionFocusState from 'canvasengine/gameobjects/game-object-selection-focus-state';

interface BitmapParamsType {
  width: number,
  height: number,
  spacePt: number,  // A normalized value representing 1/100 of the occupied space
  pixelRatio: number,
  tokenScale: number,
  imageScale: number,
  srcImageScale: number,
  annotation: string | null,
  borderStyle: string | null,
  borderColor: string | null,
}


class _TokenDrawer {

  static draw(context: CanvasRenderingContext2D, source: Bitmap, params: BitmapParamsType) {
    const instance = new _TokenDrawer(context, source, params);
    instance._draw();
  }

  _context: CanvasRenderingContext2D;
  _source: Bitmap;
  _params: BitmapParamsType;
  _images: StaticImages;
  _ringImageRect: Rect | null = null;
  _ringOuterRect: Rect | null = null;
  _ringInnerRect: Rect | null = null;
  _ringInnerScale: number | null = null;

  private constructor(context: CanvasRenderingContext2D, source: Bitmap, params: BitmapParamsType) {
    this._context = context;
    this._source = source;
    this._params = params;
    this._images = AppGlobal.images;
  }

  private _draw() {
    this._context.imageSmoothingEnabled = true;
    this._context.imageSmoothingQuality = 'medium';
    const borderStyle = this._params.borderStyle;
    if (!borderStyle || borderStyle === 'UNKNOWN_BORDER_STYLE') {
      this._drawBorderStyleNone();
    } else if (borderStyle === 'RING_BORDER_STYLE') {
      this._drawBorderStyleRing();
    } else {
      throw new Error('Unknown border style');
    }
  }

  private _drawBorderStyleNone() {
    this._drawSource();
    this._drawAnnotation();
  }

  private _drawBorderStyleRing() {
    this._initRingLocs();
    this._fillRing();
    this._drawSourceInRing();
    this._drawRing();
    this._drawAnnotation();
    // Un-comment to debug issues with rendering areas.
    // this._context.fillStyle = '#FFF3';
    // this._context.fillRect(0, 0, this._params.width, this._params.height);
    // this._context.fillStyle = '#F002';
    // this._context.fillRect(...this._ringImageRect.toXYWH());
    // this._context.fillStyle = '#0F02';
    // this._context.fillRect(...this._ringOuterRect.toXYWH());
    // this._context.fillStyle = '#00F2';
    // this._context.fillRect(...this._ringInnerRect.toXYWH());
  }

  private _drawSource() {
    const outerRect = Rect.fromSize(this._params.width, this._params.height);
    const sourceRect = this._source.rect;
    const drawSourceRect = sourceRect.centerWithin(outerRect);
    const drawScale = sourceRect.getFitWithinScale(outerRect);
    this._source.draw(
      this._context,
      0,
      0,
      drawSourceRect.x,
      drawSourceRect.y,
      drawSourceRect.w,
      drawSourceRect.h,
      drawScale,
    );
  }

  private _drawAnnotation() {
    if (this._params.annotation) {
      const annotationSize = Math.round(Math.min(this._params.height / 4, this._params.pixelRatio * 20));
      this._context.fillStyle = '#DDD';
      this._context.strokeStyle = `#222`;
      this._context.lineWidth = Math.round(this._params.pixelRatio * 4);
      this._context.font = `bold ${annotationSize}px serif`;
      this._context.textAlign = 'center';
      this._context.textBaseline = 'bottom';
      const textX = Math.round(this._params.width / 2);
      let textY: number;
      if (this._params.borderStyle === 'RING_BORDER_STYLE') {
        textY = Math.round(this._params.height * 0.92);
      } else {
        textY = Math.round(this._params.height * 0.95);
      }
      this._context.strokeText(this._params.annotation, textX, textY);
      this._context.fillText(this._params.annotation, textX, textY);
    }
  }

  private _initRingLocs() {
    const outerRect = Rect.fromSize(this._params.width, this._params.height);
    this._ringImageRect = Rect.fromSize(300, 300).centerWithin(outerRect);
    const ringBaseLength = outerRect.w;
    this._ringInnerRect = Rect.fromCoordinates(
      ringBaseLength * (43 / 300),
      ringBaseLength * (34 / 300),
      ringBaseLength * (260 / 300),
      ringBaseLength * (252 / 300),
    ).shift(this._ringImageRect.x, this._ringImageRect.y);
    this._ringOuterRect = Rect.fromCoordinates(
      ringBaseLength * (18 / 300),
      ringBaseLength * (4 / 300),
      ringBaseLength * (285 / 300),
      ringBaseLength * (277 / 300),
    ).shift(this._ringImageRect.x, this._ringImageRect.y);
    this._ringInnerScale = this._ringInnerRect.w / ringBaseLength;
  }

  private _fillRing() {
    this._context.save();
    const [centerX, centerY] = this._ringInnerRect.getCenter();
    this._context.beginPath();
    this._context.arc(
      centerX,
      centerY,
      this._ringInnerRect.w * 0.5,
      0,
      2 * Math.PI,
    );
    this._context.arc(
      centerX,
      centerY,
      this._ringOuterRect.w * 0.5,
      0,
      2 * Math.PI,
    );
    this._context.clip('evenodd');
    this._context.fillStyle = this._params.borderColor;
    this._context.fillRect(
      this._ringOuterRect.x,
      this._ringOuterRect.y,
      this._ringOuterRect.w,
      this._ringOuterRect.h,
    );
    this._context.restore();
  }

  private _drawSourceInRing() {
    this._context.save();
    const [centerX, centerY] = this._ringInnerRect.getCenter();
    const sourceRect = this._source.rect;
    const drawSourceRect = sourceRect.cover(this._ringInnerRect);
    const drawSourceScale = sourceRect.getCoverScale(this._ringInnerRect);
    this._context.beginPath();
    this._context.arc(
      centerX,
      centerY,
      this._ringInnerRect.w * 0.5,
      0,
      2 * Math.PI,
    );
    this._context.clip();
    this._source.draw(
      this._context,
      0,
      0,
      drawSourceRect.x,
      drawSourceRect.y,
      drawSourceRect.w,
      drawSourceRect.h,
      drawSourceScale,
    );
    this._context.restore();
  }

  private _drawRing() {
    this._context.save();
    this._context.globalCompositeOperation = 'luminosity';
    this._context.drawImage(
      this._images.tokenRing,
      0,
      0,
      this._images.tokenRing.width,
      this._images.tokenRing.height,
      this._ringImageRect.x,
      this._ringImageRect.y,
      this._ringImageRect.w,
      this._ringImageRect.h,
    );
    this._context.restore();
  }

}


/**
 * Highlights the currently hovered cell in a grid
 *
 * Some things to do:
 * - TODO: Have a known bitmap that can be used if the token's bitmap isn't
 *   loaded. This will allow a token to be displayed so long as the object
 *   itself exists even if the image isn't there yet.
 * -
 */
export default class BaseMapToken extends GameObject {

  /** A copy of the current grid. Necessary to do things like snap the token to
    * a grid location. Only `null` if this object has been destroyed. */
  protected _grid: any | null;
  /** A store reference for the underlying token data, allowing this object to
    * remain up-to-date if the token's state changes in the background. Only
    * null if this object has been destroyed */
  protected _tokenStoreRef: MapObjectStoreRef | null;
  /** A local, read-only copy of the actual token data or `null` if the token
    * does not exist or is not yet loaded */
  protected _tokenData: any | null = null;
  /** Set to true if this object created a local `_tokenStoreRef`. False if a
    * tokenStoreRef was injected. If true, the ref state must be destroyed when
    * this object is destroyed. */
  protected _mustDestroyRef: boolean;
  /** A reference to the token's image data */
  protected _tokenImageRef: MapImageStoreRef | null;
  /** Set to `true` whenever underlying data changes occur in
    * `_tokenStoreRef` */
  protected _needsRedraw: boolean;
  /** The "real" x coordinate of the token in source-pixel space. This should
    * directly correspond to the grid x on the token data in the server */
  protected _realX: number;
  /** The "real" y coordinate of the token in source-pixel space. This should
    * directly correspond to the grid y on the token data in the server */
  protected _realY: number;
  /** The current x location in source-pixel space, used for animating the token
    * when it is moved between points. */
  protected _curX: number | null = null;
  /** The current y location in source-pixel space, used for animation */
  protected _curY: number | null = null;
  /** When a user is dragging a token around, this represents the temporary x
    * coordinate in source-pixel-space before it is written to the server */
  protected _localX: number;
  /** When a user is dragging a token around, this represents the temporary y
    * coordinate in source-pixel-space before it is written to the server */
  protected _localY: number;
  /** Handles the pull calculations between current location and next */
  protected _pull: Pullable = new Pullable();
  /** The bitmap for the token's main image */
  protected _srcBitmap: Bitmap | null = null;
  /** The actual scale that the image is rendered at. For example, on a 50 x 50
    * grid, if an image is 1x1 and the image itself is 100px x 100px, the
    * bitmapScale will be 0.5 (since it needs to shrink by half to fit in its
    * real container. */
  protected _srcBitmapScale: number;
  /** The generated bitmap data for this token. This acts as a cache for things
    * like scaling and other decoration that are not part of the source
    * bitmap */
  protected _bitmap: DynamicBitmap<BitmapParamsType> | null = null;
  /** The opacity at which to draw the token */
  protected _opacity: number = 1;
  /** Combined listener for any state changes. Null only after beingn
    * destroyed */
  private _tokenDataListener: FStateListener | null;

  constructor(grid: any, tokenStoreRef: MapObjectStoreRef);
  constructor(grid: any, tokenId: string);
  constructor(grid: any, tokenInfo: MapObjectStoreRef | string) {
    super();
    if (!grid) {
      throw new Error('Grid is required');
    } else if (!tokenInfo) {
      throw new Error('A token id or reference is required');
    }
    this._grid = grid || null;
    if (typeof tokenInfo === 'string') {
      this._mustDestroyRef = true;
      this._tokenStoreRef = new MapObjectStoreRef(AppGlobal.mapObjectStore);
      this._tokenStoreRef.key = tokenInfo;
    } else {
      this._mustDestroyRef = false;
      this._tokenStoreRef = tokenInfo;
    }
    this._tokenImageRef = new MapImageStoreRef(AppGlobal.imageStore);
    this._needsRedraw = true;
    this._tokenDataListener = new FStateListener(
      this.$b._onTokenOrImageChange, {immediate: true}
    );
    this._tokenDataListener.listen(this._tokenStoreRef, 'record');
    this._tokenDataListener.listen(this._tokenImageRef, 'record');
    this._pull = new Pullable();
    this.rescalePullHandler();
  }

  isEnabled() {
    return !!(this.enabled && this._grid && this._tokenData && this._srcBitmap);
  }

  isVisible() {
    const [x, y] = this.getRealCoordinates();
    return !!(this.visible && x !== null && y !== null);
  }

  public get tokenId(): string {
    return this._tokenData.id;
  }

  protected get isFocused(): boolean {
    const focusState: GameObjectSelectionFocusState = AppGlobal.gameObjectSelectionFocus;
    return focusState.getFocusedGameObjectId() === this.id;
  }

  protected set isFocused(value: boolean) {
    const focusState: GameObjectSelectionFocusState = AppGlobal.gameObjectSelectionFocus;
    if (value && !this.isFocused) {
      focusState.setFocusedGameObject(this);
      this._needsRedraw = true;
    } else if (!value && this.isFocused) {
      focusState.clearFocusedGameObject();
      this._needsRedraw = true;
    }
  }

  protected get isSelected(): boolean {
    const focusState: GameObjectSelectionFocusState = AppGlobal.gameObjectSelectionFocus;
    return focusState.getSelectedGameObjectIds().has(this.id);
  }

  protected set isSelected(value: boolean) {
    const focusState: GameObjectSelectionFocusState = AppGlobal.gameObjectSelectionFocus;
    if (value && !this.isSelected) {
      focusState.addGameObjectToSelection(this);
      this._needsRedraw = true;
    } else if (!value && this.isSelected) {
      focusState.removeGameObjectFromSelection(this);
      this._needsRedraw = true;
    }
  }

  /**
   * Handle changes in either the token or relevant image data, keeping the
   * internal state in sync
   */
  protected _onTokenOrImageChange() {
    const nextTokenData = this._tokenStoreRef.record || null;
    if (!nextTokenData) {
      // Either waiting for initial token load OR token no longer exists and
      // this object will soon be destroyed. Either way, don't perform any
      // internal updates.
      this._tokenData = nextTokenData;
      return;
    }
    if (this._tokenData !== nextTokenData) {
      this._tokenData = nextTokenData;
      this._needsRedraw = true;
      this._reconcileTokenImageId();
      this._reconcileTokenRealLocation();
    }
    this._reconcileImageBitmap();
  }

  /**
   * Normalizes and synchronizes the token's image ID with the internal image
   * ref. This will allow the image ref to update it's internal state with the
   * loaded image data.
   */
  protected _reconcileTokenImageId() {
    // Normalize the current image ID for the token
    const tokenRecord = this._tokenStoreRef.record;
    let tokenImageId: string | null = null;
    if (tokenRecord && tokenRecord.token && tokenRecord.token.imageId) {
      tokenImageId = tokenRecord.token.imageId;
    }
    // Sync token and image ref if necessary. Note this will trigger a
    // subsequent change that will remove the image data itself.
    if (tokenImageId !== this._tokenImageRef.key) {
      this._tokenImageRef.key = tokenImageId || null;
    }
  }

  /**
   * Pulls the token bitmap from the image store. After this is run, the
   * internal bitmap will either be `null` (because no image URL is currently
   * unavailable) or it will be a bitmap object for the appropriate token image.
   *
   * Note that tokens _should_ automatically load their images into the store
   * before updating their internal state. Therefore it should be safe to use
   * getBitmapSync.
   */
  protected _reconcileImageBitmap() {
    const imageRecord = this._tokenImageRef.record;
    let imageBitmap: Bitmap | null;
    if (imageRecord && imageRecord.id) {
      const store = this._tokenImageRef.store as MapImageStoreState;
      imageBitmap = store.getBitmapSync(imageRecord.id, true);
    } else {
      imageBitmap = null
    }
    const tokenDetails = this._tokenData.token;
    if (imageBitmap) {
      const srcBitmapRect = Rect.fromSize(imageBitmap.width, imageBitmap.height);
      const gridImageRect = Rect.fromSize(
        this._grid.gridToPixelWidth(tokenDetails.gridWidth),
        this._grid.gridToPixelHeight(tokenDetails.gridHeight),
      );
      this._srcBitmap = imageBitmap;
      this._srcBitmapScale = srcBitmapRect.getFitWithinScale(gridImageRect);
      this._bitmap = this._makeDynamicBitmap(this._srcBitmap);
    } else {
      this._srcBitmap = null;
      this._srcBitmapScale = 1;
      if (this._bitmap) {
        this._bitmap.destroy();
      }
      this._bitmap = null;
    }
  }

  /**
   * Updates the "real" location of the game object to match the token data.
   */
  protected _reconcileTokenRealLocation() {
    const loc = this._tokenData.token.position;
    let nextX = null;
    let nextY = null;
    if (loc && loc.col !== null && loc.col !== undefined && loc.row !== null && loc.row !== undefined) {
      nextX = this._grid.gridToPixelX(loc.col, loc.sub ? loc.sub.col : null);
      nextY = this._grid.gridToPixelY(loc.row, loc.sub ? loc.sub.row : null);
    }
    if (this._realX !== nextX || this._realY !== nextY) {
      this._realX = nextX;
      this._realY = nextY;
    }
  }

  destroy(): void {
    if (this._isDestroyed) {
      console.warn('Attempting to destroy already destroyed object');
      return;
    }
    this.isFocused = false;
    this.isSelected = false;
    if (this._tokenDataListener) {
      this._tokenDataListener.destroy();
      this._tokenDataListener = null;
    }
    if (this._mustDestroyRef) {
      this._tokenStoreRef.destroy();
    }
    if (this._tokenImageRef) {
      this._tokenImageRef.destroy();
    }
    this._tokenStoreRef = null;
    if (this._bitmap) {
      this._bitmap.destroy;
      this._bitmap = null;
    }
    super.destroy();
  }

  attach(engine: CanvasEngine, layerName: string): void {
    super.attach(engine, layerName);
  }

  detach(): void {
    super.detach();
  }

  /**
   * Gets the current coordinates of the token - using either the real or the
   * local coordinates depending on what's currently defined
   */
  getRealCoordinates(): [x: number, y: number] {
    if (this._localX && this._localY) {
      return [this._localX, this._localY];
    }
    return [this._realX, this._realY];
  }

  /**
   * Like `getRealCoordinates` except it returns the excact col/row location in
   * grid terms instead of pixel location in source-pixel-space.
   */
  getRealGridCoordinates(): [col: number, row: number] {
    const [x, y] = this.getRealCoordinates();
    return [
      this._grid.pixelToGridCol(x, y),
      this._grid.pixelToGridRow(x, y),
    ];
  }

  /**
   * Gets the size of the token in the grid regardless of draw scale, image,
   * or anythign like that. For example, a 1x1 token will return one grid cell.
   */
  getGridLocationRect(): Rect {
    if (!this._tokenData || !this._tokenData.token) {
      return Rect.ZERO;
    }
    const gridWidth = this._tokenData.token.gridWidth;
    const gridHeight = this._tokenData.token.gridHeight;
    if (!gridWidth || !gridHeight) {
      return Rect.ZERO;
    }
    return Rect.fromDimensions(
      this._curX,
      this._curY,
      this._grid.gridToPixelWidth(gridWidth),
      this._grid.gridToPixelHeight(gridHeight),
    );
  }

  /**
   * Like `getGridLocationRect` except this will come with reasonable padding
   * based on the grid cell size itself.
   */
  getPaddedGridLocationRect(): Rect {
    const gridLocationRect = this.getGridLocationRect();
    if (!gridLocationRect || gridLocationRect === Rect.ZERO) {
      return Rect.ZERO;
    }
    const minDimension = Math.min(
      this._grid.gridToPixelWidth(1),
      this._grid.gridToPixelHeight(1),
    );
    const padding = minDimension * 0.1;
    return gridLocationRect.growCentered(-2 * padding);
  }

  /**
   * Returns whether the token is "masked" by the specified mask shape.
   *
   * See Shapes.Mask
   */
  getIsMasked(mask: any, alphaLimit?: number, invert?: boolean): boolean {
    if (!mask) {
      return false;
    }
    if (alphaLimit === undefined) {
      alphaLimit = 0.5;
    }
    if (invert === undefined) {
      invert = false;
    }
    // Shrink by 1 pixel along each side to reduce the likelihood of points
    // being exactly on a grid edge (which can be a hassle)
    const spaceRect = this._getSpaceRect(this._curX, this._curY).growCentered(-2);
    const points = [
      [spaceRect.x, spaceRect.y],
      [spaceRect.x, spaceRect.y2],
      [spaceRect.x2, spaceRect.y],
      [spaceRect.x2, spaceRect.y2],
    ];
    // Currently, check if ANY points are
    for (const [x, y] of points) {
      if (!mask.isPointInMask(x, y, alphaLimit, invert)) {
        return false;
      }
    }
    return true;
  }

  getIsMaskHidden(privileged?: boolean): boolean {
    if (privileged === undefined) {
      // TODO: This is inaccurate since it doesn't handle impersonation. We'll
      // need to have some sort of global permissions state if we want to do
      // that (which is really something we out to have). It's fine for now
      // since it just means that the DM is allowed to move tokens in and out
      // of masked areas even when viewing the map as a player.
      privileged = AppGlobal.mapDetails.getPermissions().CAN_VIEW_FULL_MAP;
    }
    if (this.getIsMasked(AppGlobal.mapZoneBoundariesMask.mask)) {
      // Out-of-bounds is always hidden
      return true;
    } else if (privileged) {
      // Visibility is ignored for privileged users
      return false;
    } else if (!this.getIsMasked(AppGlobal.mapZoneOverrideVisibleMask.mask, 0.5, true)) {
      // Token is in a partially in visibility override, so it's visible. Note
      // that we double-invert because we're actually testing if the token is
      // NOT masked by the NOT visibility override. In other words, we're
      // testing if any part of the token is contained within the mask.
      return false;
    }
    // No need to check for explored/hidden since those are both not actively
    // visible. If we did, though, we'd probably want to check with inverse=true
    return true;
  }

  protected _getImageRect(tokenX: number, tokenY: number): Rect {
    const tokenDetails = this._tokenData.token;
    return this._getImageRectMemo(
      tokenX,
      tokenY,
      this._srcBitmap.width,
      this._srcBitmap.height,
      this._srcBitmapScale,
      tokenDetails.imageScale,
    );
  }

  /**
   * Gets the width/height that the image should be drawn at (source coordinates)
   */
  protected _getImageRectMemo = memoizeOne((
    tokenX,
    tokenY,
    srcBitmapWidth,
    srcBitmapHeight,
    srcBitmapScale,
    imageScale,
  ): Rect => {
    if (tokenX === null || tokenX === undefined || tokenY === null || tokenY === undefined) {
      return Rect.ZERO;
    }
    return this._getSpaceRect(tokenX, tokenY).scaleCentered(imageScale);
  });

  /**
   * Gets a rect representing the "space" occupied by the token.
   */
  protected _getSpaceRect = memoizeOne((tokenX: number, tokenY: number): Rect => {
    if (tokenX === null || tokenX === undefined || tokenY === null || tokenY === undefined) {
      return Rect.ZERO;
    }
    const tokenDetails = this._tokenData.token;
    return Rect.fromDimensions(
      tokenX,
      tokenY,
      this._grid.gridToPixelWidth(tokenDetails.gridWidth),
      this._grid.gridToPixelHeight(tokenDetails.gridHeight),
    );
  });

  /**
   * Gets the render area (in source-pixel-space) for a given token location.
   * This will be used for defining render areas when the token moves around.
   */
  protected _getRenderArea(tokenX: number, tokenY: number) {
    if (
      tokenX === null ||
      tokenX === undefined ||
      tokenY === null ||
      tokenY === undefined
    ) {
      return Squares.zero();
    }
    // TODO: Improve this
    return Squares.growCentered(this._getImageRect(tokenX, tokenY), 5);
  }

  protected _makeDynamicBitmap(source: Bitmap): DynamicBitmap<BitmapParamsType> {
    return new DynamicBitmap(source, {
      contextOptions: {alpha: true},
      getSize: (params) => {
        return [params.width, params.height];
      },
      draw: _TokenDrawer.draw,
    });
  }

  protected _refreshDynamicBitmap(pixelRatio: number, curScale: number): void {
    if (!this._bitmap || (this._curX ?? null) == null || (this._curY ?? null) == null) {
      return;
    }
    const tokenDetails = (this._tokenData && this._tokenData.token) ? this._tokenData.token : {};
    const renderRect = this._getImageRect(this._curX, this._curY).scale(curScale);
    this._bitmap.refresh({
      width: renderRect.w,
      height: renderRect.h,
      pixelRatio: pixelRatio,
      spacePt: Math.min(
        this._grid.gridToPixelWidth(tokenDetails.gridWidth),
        this._grid.gridToPixelHeight(tokenDetails.gridHeight),
      ) / 100,
      tokenScale: curScale,
      imageScale: tokenDetails.imageScale * curScale,
      srcImageScale: this._srcBitmapScale * tokenDetails.imageScale * curScale,
      annotation: tokenDetails.annotation,
      borderStyle: tokenDetails.border ? tokenDetails.border.style : null,
      borderColor: tokenDetails.border ? tokenDetails.border.color : null,
    });
  }

  protected rescalePullHandler(pixelDensity?: number, scale?: number): void {
    const pull = this._pull;
    const gridFactor = this._grid.gridToPixelWidth(1);
    const scaleFactor = (
      gridFactor * (pixelDensity ?? 1) * (1 / (scale ?? 1))
    );
    pull.minVelocity = scaleFactor * 30;
    pull.maxVelocity = scaleFactor * 150;
    pull.nearbyDistance = scaleFactor * 15;
  }

  onUpdate(frameUpdateContext: FrameUpdateContext): void {
    let nextX = this._curX ?? null;
    let nextY = this._curY ?? null;
    const layer = frameUpdateContext.layer;
    const [targetX, targetY] = this.getRealCoordinates();
    // Move the token if necessary
    if (nextX !== targetX || nextY !== targetY) {
      this.rescalePullHandler(
        frameUpdateContext.frame.pixelRatio, frameUpdateContext.frame.scale
      );
      this._pull.x = nextX;
      this._pull.y = nextY;
      this._pull.pull(targetX, targetY, frameUpdateContext.timeDelta);
      nextX = this._pull.x;
      nextY = this._pull.y;
    }
    // Trigger redraw on movement
    if (this._needsRedraw || nextX !== this._curX || nextY !== this._curY) {
      this._needsRedraw = false;
      layer.addRedrawRegionSource(
        this._getRenderArea(this._curX, this._curY)
      );
      this._curX = nextX;
      this._curY = nextY;
      layer.addRedrawRegionSource(
        this._getRenderArea(this._curX, this._curY)
      );
    }
  }

  onRender(frameRenderContext: FrameRenderContext): void {
    if (this._bitmap) {
      const context = frameRenderContext.context;
      if (this._opacity < 1) {
        context.globalAlpha = this._opacity;
      }
      const renderRect = frameRenderContext.frame.sourceRectToRenderRect(
        this._getImageRect(this._curX, this._curY)
      );
      const forceRefresh = mathutils.fracDelta(this._bitmap.width, renderRect.w) > .8
      if (frameRenderContext.frame.isVolatile && !forceRefresh) {
        // For volatile frames, don't bother refreshing to bitmap since it will
        // likely change (and is expensive)
        this._bitmap.drawDirect(
          context,
          renderRect.x,
          renderRect.y,
          renderRect.w,
          renderRect.h,
        );
      } else {
        // Can draw direct without destination coordinates because the refresh
        // takes care of the scaling
        this._refreshDynamicBitmap(
          frameRenderContext.frame.pixelRatio,
          frameRenderContext.frame.scale,
        );
        this._bitmap.drawDirect(context, renderRect.x, renderRect.y);
      }
    }
  }
}
