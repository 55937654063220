import React from 'react';
import propTypes from 'prop-types';

import AppGlobal from 'global';
import EmbarkToolsState from 'apps/maps/state/embark-tools';
import MiniToolbar from 'components/basic/mini-toolbar';
import ReactComponent from 'utils/react-component';
import {connectToComponentState} from 'statelib/fstate-react';

import EmbarkToolbarMainToolSelect from './embark-toolbar-main-tool-select';
import EmbarkToolbarOptionsVisOverride from './embark-toolbar-options-vis-override';


function EmbarkToolbarView({
  permissions,
  role,
  setToolOption,
  tool,
  embarkToolsState,
  undoContext,
}) {
  if (!tool) {
    // No tool state yet
    return null;
  }
  return (
    <MiniToolbar attach='bottom'>
      <div className='flex--container-vertical'>
        {/* TODO: Only display this if it is visible + add flag for hiding */}
        <div className='flex--container-horizontal'>
          <div className='flex--item-fill' />
          {/* if */(tool.id === EmbarkToolsState.TOOL_VIS_OVERRIDE) ? (
            <EmbarkToolbarOptionsVisOverride
              role={role}
              setToolOption={setToolOption}
              tool={tool}
            />
          )/* endif */:null}
          <div className='flex--item-fill' />
        </div>
        <div className='flex--container-horizontal'>
          <div className='flex--item-fill' />
          <EmbarkToolbarMainToolSelect
            permissions={permissions}
            embarkToolsState={embarkToolsState}
            undoContext={undoContext}
          />
          <div className='flex--item-fill' />
        </div>
      </div>
    </MiniToolbar>
  );
}


export default class EmbarkToolbar extends ReactComponent {

  static propTypes = {
    // The state-ref which contains the tool state. This cannot be changed after
    // the component is created.
    toolStateRef: propTypes.instanceOf(EmbarkToolsState),
    permissions: propTypes.object,
  };

  constructor(props) {
    super(props);
    this._toolState = this.constantProp('toolStateRef');
    this._setTool = this._toolState.$b.setTool;
    this._setToolOption = this._toolState.$b.setToolOption;
    connectToComponentState(this._toolState, this, 'tool', 'tool', true);
  }

  render() {
    return EmbarkToolbarView({
      embarkToolsState: this._toolState,
      permissions: this.props.permissions,
      role: undefined,  // TODO: Limit tools based on role/permissions
      setToolOption: this._toolState.$b.setToolOption,
      tool: this.state.tool,
      undoContext: this.props.undoContext,
    });
  }

}
