import React from 'react';
import classNames from 'classnames';

/**
 * A special box that will be centered (vertically and horizontally) within
 * its container. Useful for things like modals.
 */
export default function CenteredBox(props) {
  return (
    <div className={classNames('centered-box-container', props.classNameContainer)}>
      <div
        className={classNames('centered-box', props.className)}
        style={props.style}
      >
        {props.children}
      </div>
    </div>
  );
}
