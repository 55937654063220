import Rect from 'utils/rect';

const DEBUG = true;


class _Segment {

  public readonly start: number;
  public readonly length: number;
  public readonly dataA: Uint8ClampedArray;
  public readonly dataB: Uint8ClampedArray;

  static inverse(otherSegment: _Segment): _Segment {
    return new _Segment(
      otherSegment.start,
      otherSegment.length,
      otherSegment.dataB,
      otherSegment.dataA,
    );
  }

  static fromData(dataA: Uint8ClampedArray, dataB: Uint8ClampedArray, start: number, length: number): _Segment {
    return new _Segment(
      start,
      length,
      new Uint8ClampedArray(dataA, start, length),
      new Uint8ClampedArray(dataB, start, length),
    );
  }

  constructor(start: number, length: number, dataA: Uint8ClampedArray, dataB: Uint8ClampedArray) {
    this.start = start;
    this.length = length;
    this.dataA = dataA;
    this.dataB = dataB;
  }

}


/**
 * Represents a delta between two arrays of image data. The delta can later be
 * applied to other image data.
 *
 * `scope` is used to represent a scope within the source image that is being
 * operated on - the "image within the image". This can grealy reduce memory
 * consumption when there are known boundaries for the diff.
 */
export default class ImageDataDiff {

  protected _data: Uint8ClampedArray | null;
  protected _scopeRect: Rect;
  protected _segments: Array<_Segment> | null;
  protected _diffRect: Rect | null;

  static fromContext(context: CanvasRenderingContext2D, scopeRect: Rect) {
    return new ImageDataDiff(
      context.getImageData(scopeRect.x, scopeRect.y, scopeRect.w, scopeRect.h),
      scopeRect,
    )
  }

  constructor(baseImageData: ImageData | null, scopeRect?: Rect) {
    this._data = (baseImageData) ? new Uint8ClampedArray(baseImageData.data) : null;
    if (scopeRect) {
      // If a scope rect is provided, do a quick test to make sure that it
      // lines up with the provided image data. Only do this if image data is
      // actually provided.
      if (
        baseImageData &&
        (scopeRect.w !== baseImageData.width || scopeRect.h !== baseImageData.height)
      ) {
        throw new Error("Size mismatch between baseImageData and scopeRect");
      }
      this._scopeRect = scopeRect;
    } else {
      // If no scope provided, assume scoped to the entire image.
      this._scopeRect = Rect.fromSize(baseImageData.width, baseImageData.height);
    }
    this._segments = null;
    this._diffRect = null;
  }

  get isDifferent(): boolean {
    return this._segments.length > 0;
  }

  get rect(): Rect | null {
    return this._diffRect.shift(this._scopeRect.x, this._scopeRect.y);
  }

  get scope(): Rect {
    return this._scopeRect;
  }

  //
  // TODO: A diff MUST also remember the region (rect) containing the change.
  // This is necessary so that we can push the patch back to the server
  //

  updateFromContext(context: CanvasRenderingContext2D): void {
    this.update(context.getImageData(
      this._scopeRect.x, this._scopeRect.y, this._scopeRect.w, this._scopeRect.h
    ));
  }

  update(newImageData: ImageData): void {
    const dataA = this._data;
    const dataB = newImageData.data;
    if (!this._data) {
      throw new Error('Either the diff was not initialized properly or it was already udpated');
    } else if (dataA.length !== dataB.length) {
      throw new Error('Cannot diff arrays of different length');
    }
    this._verifyScopeDimensions(newImageData);
    this._data = null;
    const startMs = performance.now();
    const len = dataA.length;
    const segments = [];
    let diffStart = -1;
    // Iterate 4 octets (1 pixel) at a time
    for (let i = 0; i < len; i += 4) {
      if (diffStart === -1) {
        // Working through an equivalent segment
        if (
          dataA[i] !== dataB[i] ||
          dataA[i + 1] !== dataB[i + 1] ||
          dataA[i + 2] !== dataB[i + 2] ||
          dataA[i + 3] !== dataB[i + 3]
        ) {
          diffStart = i;
        }
      } else {
        // Working through a divergent segment
        if (
          dataA[i] === dataB[i] &&
          dataA[i + 1] === dataB[i + 1] &&
          dataA[i + 2] === dataB[i + 2] &&
          dataA[i + 3] === dataB[i + 3]
        ) {
          segments.push(_Segment.fromData(dataA, dataB, diffStart, i - diffStart));
          diffStart = -1;
        }
      }
    }
    // Capture the last segment (if one was in progress)
    if (diffStart !== -1) {
      segments.push(_Segment.fromData(dataA, dataB, diffStart, len - diffStart));
    }
    this._segments = segments;
    this._diffRect = this._getDiffRect(newImageData);
    // TODO: This seems a little slow. I'm not entirely sure how to fix it other
    // than maybe dipping into Rust or something for this diffing stuff. Other
    // than that, the only thing I can think of is adding some sort of "hint"
    // adding a scope so that it only checks a limited area.
    if (DEBUG) console.debug(`Image diff took ${performance.now() - startMs} ms`);
  }

  /**
   * Checks if the diff applies cleanly to the provided `data` ImageData. A
   * diff is considered to apply cleanly if the dataA of all segments matches
   * the provided data.
   */
  check(data) {
    throw new Error('Need to implement');
  }

  /**
   * Applies the diff to the image data of the specified context, manipulating
   * as few pixels as possible.
   *
   * Returns the rect representing the difference.
   */
  applyToContext(context: CanvasRenderingContext2D): Rect {
    const targetImageData = context.getImageData(
      this._scopeRect.x, this._scopeRect.y, this._scopeRect.w, this._scopeRect.h
    );
    const diffRect = this.apply(targetImageData);
    // TODO: Does the scopeRect x, y need to be shifted by diffRect x, y?
    context.putImageData(
      targetImageData,
      this._scopeRect.x,
      this._scopeRect.y,
      diffRect.x,
      diffRect.y,
      diffRect.w,
      diffRect.h
    );
    return diffRect;
  }

  /**
   * Applies this diff to the provided `data` ImageData (uint8Array). Returns
   * the rect ("squares" object) that represents the "dirty" region for the
   * apply.
   */
  apply(targetImageData: ImageData): Rect {
    if (!this._segments) {
      throw new Error('Diff must be updated before applying');
    }
    this._verifyScopeDimensions(targetImageData);
    const targetData = targetImageData.data;
    for (const segment of this._segments) {
      // It feels like there is a better way to do this...
      const start = segment.start;
      const limit = segment.start + segment.length;
      const source = segment.dataB;
      if (targetData.length < limit) {
        throw new Error('Target data array is not long enough to accomodate this diff');
      }
      for (let i = start; i < limit; i++) {
        targetData[i] = source[i];
      }
    }
    return this._diffRect;
  }

  /**
   * Gets the inverse diff from this diff.
   */
  getInverse() {
    if (!this._segments) {
      throw new Error('Diff must be updated before inverting');
    }
    const inverse = new ImageDataDiff(null, this._scopeRect);
    const inverseSegments = [];
    for (const segment of this._segments) {
      inverseSegments.push(_Segment.inverse(segment));
    }
    inverse._segments = inverseSegments;
    inverse._diffRect = this._diffRect;
    return inverse;
  }

  protected _verifyScopeDimensions(imageData: ImageData): void {
    if (this._scopeRect.w !== imageData.width || this._scopeRect.h !== imageData.height) {
      throw new Error(
        "Dimensions mismatch: " +
        `Scope=(${this._scopeRect.w}, ${this._scopeRect.h}), ` +
        `Image=(${imageData.width}, ${imageData.height})`
      );
    }
  }

  _getDiffRect(imageData: ImageData): Rect {
    if (this._segments.length < 1) {
      return Rect.ZERO
    }
    let width = imageData.width;
    let minX = null;
    let minY = null;
    let maxX = null;
    let maxY = null;
    for (const segment of this._segments) {
      const start = segment.start >> 2;
      const limit = (segment.start + segment.length) >> 2;
      for (let pxl = start; pxl < limit; pxl++) {
        const x = pxl % width;
        const y = Math.floor(pxl / width);
        if (minX === null) {
          minX = x;
          maxX = x;
          minY = y;
          maxY = y;
        } else {
          minX = Math.min(minX, x);
          maxX = Math.max(maxX, x);
          minY = Math.min(minY, y);
          maxY = Math.max(maxY, y);
        }
      }
    }
    return Rect.fromCoordinates(minX, minY, maxX + 1, maxY + 1);
  }

}
