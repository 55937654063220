import statelib from 'statelib';

type MapObjectRecordType = any;  // TODO: Fix me


export default class MapObjectStoreRef extends statelib.RecordStoreRef<MapObjectRecordType> {

  getExtraData(nextRecord: MapObjectRecordType): {} {
    return {
      isToken: nextRecord ? !!nextRecord.token : false
    };
  }

}
