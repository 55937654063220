/**
 * Big collection of all of the static images that need to be available for the
 * application to function.
 *
 * These will be loaded, in serial, when the application loads
 */
export default class StaticImages {

  protected _rootUrl: string;
  protected _document: Document;
  protected _loading: Promise<void>;
  public tokenRing: HTMLImageElement;

  constructor(staticRootUrl?: string) {
    this._rootUrl = staticRootUrl || '';
    this._document = document;
    this._loading = Promise.resolve();
    this.tokenRing = this._newImage('/images/token_ring_v02.png');
  }

  public get loading() {
    return this._loading;
  }

  private _newImage(path: string): HTMLImageElement {
    const image = new Image();
    const curLoading = this._loading;
    this._loading = curLoading.then(() => new Promise((resolve, reject) => {
      image.addEventListener('load', (event) => {resolve()});
      image.addEventListener('error', reject);
      image.src = `${this._rootUrl}${path}`;
    }));
    return image;
  }

}
