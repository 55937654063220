import react from 'react';

import makeBindable from 'utils/bound-methods';
import memoBind from 'utils/memo-bound';

type CleanupCallType = () => void;
type SetupCallType = () => void;
type ClosableSetupCallType = () => CleanupCallType;


/**
 * Slightly improved react component.
 */
export default class ReactComponent<P, S, SS> extends react.PureComponent<P, S, SS> {

  _cleanupCalls: Set<CleanupCallType>;
  _setupCalls: Set<SetupCallType>;
  _constantPropNames: Set<string>;

  $b: any;
  $bdestroy: any;

  constructor(props) {
    super(props);
    this._cleanupCalls = new Set();
    this._setupCalls = new Set();
    this._constantPropNames = new Set();
    makeBindable(this);
  }

  /**
   * Registers a property as "constant", returning its value. This is useful in
   * cases where a property is meant to pass in a state object and things will
   * get messed up if that object were to change.
   *
   * Typical usage is something like:
   *
   * ```
   * constructor(props) {
   *   super(props);
   *   this.mapContainer = this.constantProp('mapContainer');
   *   // Do some other things that assume `this.mapContainer` will not change
   *   // like connecting it to the component state.
   * }
   * ```
   */
  constantProp(propName: string, defaultValue?: any) {
    if (!this.props) {
      throw new Error('Component props not defined. Did you call `super(props)`?');
    }
    this._constantPropNames.add(propName);
    if (this.props[propName] === undefined) {
      return defaultValue;
    }
    return this.props[propName];
  }

  /**
   * Adds one or more setup functions to be called.
   *
   * Setup functions will be called when the component is monuted. This can be
   * useful for some cases where something cannot run in the constructor but
   * instead needs to wait for the component to be mounted.
   *
   * Note that, when subscribing to states, it's best to use `addClosableSetup`
   * so that it automatically registers the return value as a cleanup
   * function.
   */
  addSetup(...setupFns: SetupCallType[]): void {
    for (let i = 0; i < setupFns.length; i++) {
      if (typeof(setupFns[i]) !== 'function') {
        throw new Error(`Expected setup function, got ${typeof(setupFns[i])}`);
      }
      this._setupCalls.add(setupFns[i]);
    }
  }

  /**
   * Adds one or more cleanup functions to be called.
   *
   * This allows for one or more cleanup functions to be passed as arguments.
   * These will each be called exactly once when the component would unmount.
   */
  addCleanup(...cleanupFns: CleanupCallType[]): void {
    for (let i = 0; i < cleanupFns.length; i++) {
      if (typeof(cleanupFns[i]) !== 'function') {
        throw new Error(`Expected cleanup function, got ${typeof(cleanupFns[i])}`);
      }
      this._cleanupCalls.add(cleanupFns[i]);
    }
  }

  /**
   * Like `addSetup` except that resources will be closed.
   *
   * When each of the setupFns is called, the assumption is that their return
   * values are closing functions. These will then immediately be sent to
   * `addCleanup` so that they can be cleaned up when the component is
   * unmounted.
   *
   * This can be useful for times when you need to add a subscriber via
   * `addSetup` since it will automatically capture the return value
   * (the unsubscribe function) and set that up to be called later.
   */
  addClosableSetup(...setupFns: ClosableSetupCallType[]): void {
    for (let i = 0; i < setupFns.length; i++) {
      if (typeof(setupFns[i]) !== 'function') {
        throw new Error(`Expected setup function, got ${typeof(setupFns[i])}`);
      }
      this._setupCalls.add(this._getClosableSetupFunction(setupFns[i]));
    }
  }

  /**
   * Turns `method` into a bound, partial function. The results are memoized for
   * each combination of `bindArgs` for the duration of the component's
   * lifecycle.
   *
   * The main purpose of this is to create functions suitable for calling in
   * react components. When called twice with the same `bindArgs`, the result
   * is guaranteed to be the same reference to the function. In other words:
   *
   * ```
   * this.partial(this.method, 1, 'a') === this.partial(this.method, 1, 'a')  # true
   * this.partial(this.method, 1, 'a') === this.partial(this.method, 9, 'a')  # false
   * this.partial(this.method, 1, 'a') === this.partial(this.method, 1, 'b')  # false
   * ```
   *
   * If you don't need to bind any arguments, it is easier to use `$b` to get a
   * bound version of a method, e.g., `this.$b.method`.
   *
   * Due to the fact that partial functions are cached for the duration of the
   * component's life, this typically should not be called with arbitrary
   * arguments. Doing so could result in high memory usage.
   */
  partial(method, ...bindArgs) {
    return memoBind(method, this, ...bindArgs);
  }

  _getClosableSetupFunction(setupFn) {
    return () => {
      this.addCleanup(setupFn());
    }
  }

  componentDidUpdate(prevProps: P, prevState: S): void {
    if (this._constantPropNames.size) {
      for (const propName of this._constantPropNames.values()) {
        if (this.props[propName] !== prevProps[propName]) {
          console.info(
            `react-component: Changed constant prop ${propName}`,
            prevProps[propName],
            this.props[propName],
            this
          );
          throw new Error(`Changed constant prop ${propName}`);
        }
      }
    }
  }

  componentDidMount(): void {
    try {
      for (const setupFn of this._setupCalls) {
        // Unlike cleanup, setup functions SHOULD raise an error
        setupFn();
      }
    } finally {
      this._setupCalls = new Set();
    }
  }

  componentWillUnmount(): void {
    for (const cleanupFn of this._cleanupCalls.values()) {
      try {
        cleanupFn();
      } catch (err) {
        console.warn('react-component: Cleanup call failed', err);
      }
    }
    this._cleanupCalls = new Set();
    this.$bdestroy();  // Inverse of `makeBindable`
  }

}
