import classNames from 'classnames';
import React from 'react';

import DialogBox from 'components/layouts/dialog-box';
import FlexBarLayout from 'components/layouts/flex-bar-layout';
import ReactComponent from 'utils/react-component';
import {UnauthenticatedError} from 'utils/async';


export default class FullErrorBoundary extends ReactComponent {

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error: error,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    }
  }

  componentDidCatch(error, info) {
    // TODO: If caught an unauthenticated error, redirect to login page.
    if (error instanceof UnauthenticatedError) {
      const nextUrl = location.href.slice(location.origin.length);
      const encoded = encodeURIComponent(nextUrl);
      location.replace(`/app/auth/login?nextloc=${encoded}&reason=unauthenticated`);
      return;
    }
    console.info('Component Error', this, error, info);
  }

  render() {
    const props = this.props;
    const state = this.state;
    if (!state.hasError) {
      return props.children;
    }
    // Error page content
    // TODO: The links should be different based on whether the user is
    // logged in or not (if not logged in, they should go to home and login)
    return (
      <DialogBox closable={false}>
        <h3
          className={classNames({
            'slds-text-heading_medium': true,
            'slds-text-align_center': true,
            'slds-m-bottom_medium': true,
          })}
        >
          Error
        </h3>
        <p>{state.error.message}</p>
        <FlexBarLayout
          leftChildren={(
            <a href='/app/maps/campaigns'>Home</a>
          )}
          rightChildren={(
            <a href='/app/auth/login'>Login</a>
          )}
        />
      </DialogBox>
    );
  }

}
