import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import ReactComponent from 'utils/react-component';


function _NavItemLink({
  label,
  href,
  state,
}) {
  switch (state) {
    case 'NotReady':
      return (
        <React.Fragment>
          <span>☐</span><a href={href} disabled={true}>{label}</a>
        </React.Fragment>
      );
    case 'Ready':
      return (
        <React.Fragment>
          <span>☐</span><a href={href}>{label}</a>
        </React.Fragment>
      );
    case 'Active':
      return (
        <React.Fragment>
          <span>✎</span><a href={href} disabled={true}>{label}</a>
        </React.Fragment>
      );
    case 'Done':
      return (
        <React.Fragment>
          <span>☑</span><a href={href}>{label}</a>
        </React.Fragment>
      );
    default:
      throw new Error('Unexpected state');
  }
}


function _NavItem({
  label,
  href,
  state,
}) {
  return (
    <div
      className={classNames({
        'nav-bar': true,
      })}
    >
      <_NavItemLink
        label={label}
        href={href}
        state={state}
      />
    </div>
  );
}


/**
 * Provides links for navigating between the map zone configuration steps.
 *
 * This will be used during map zone configuration to tell the user (a) where
 * they are in the current process and (b) indicate how much is left to do
 */
export default class MapZoneConfigNav extends ReactComponent {

  static propTypes = {
    selectImageState: PropTypes.oneOf(['NotReady', 'Ready', 'Done']),
    selectGridState: PropTypes.oneOf(['NotReady', 'Ready', 'Done']),
    selectBoundariesState: PropTypes.oneOf(['NotReady', 'Ready', 'Done']),
    currentStep: PropTypes.oneOf(
      ['SelectImage', 'SelectGrid', 'SelectBoundaries']
    ).isRequired,
    mapId: PropTypes.string.isRequired,
    mapZoneId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    selectImageState: 'NotReady',
    selectGridState: 'NotReady',
    selectBoundariesState: 'NotReady',
  };

  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props;
    return (
      <div>
        <_NavItem
          label='Select Image'
          href={`/app/maps/${props.mapId}/zones/${props.mapZoneId}/image`}
          state={(props.currentStep === 'SelectImage') ? 'Active' : props.selectImageState}
        />
        <_NavItem
          label='Set Up Grid'
          href={`/app/maps/${props.mapId}/zones/${props.mapZoneId}/configure/grid`}
          state={(props.currentStep === 'SelectGrid') ? 'Active' : props.selectGridState}
        />
        <_NavItem
          label='Set Up Boundaries'
          href={`/app/maps/${props.mapId}/zones/${props.mapZoneId}/configure/bounds`}
          state={(props.currentStep === 'SelectBoundaries') ? 'Active' : props.selectBoundariesState}
        />
      </div>
    );
  }

}
