import Memoize from 'memoize-one';
import PropTypes from 'prop-types';
import React from 'react';

import {DataTable} from '@salesforce/design-system-react';
import {DataTableCell} from '@salesforce/design-system-react';
import {DataTableColumn} from '@salesforce/design-system-react';
import {Button} from '@salesforce/design-system-react';

import IdDisplayTableCell from 'components/basic/id-display-table-cell.view';

import AppGlobal from 'global';
import AppReactComponent from 'utils/app-react-component';
import CampaignDetailsStateRef from 'apps/maps/state/campaign-details-state-ref';
import FormHandler from 'formlib/form-handler';
import MapsRPCAPI from 'apis/maps-rpc-api';
import RecordCollectionState from 'statelib/record-collection-state';
import {connectToComponentState} from 'statelib/fstate-react';


function _AddRemoveMapCell({
  item,
  ...props
}) {
  return (
    <DataTableCell {...props}>
      <Button label='Configure' onClick={item.configure} />
    </DataTableCell>
  );
}
_AddRemoveMapCell.displayName = DataTableCell.displayName;


function _EmbarkActionCell({
  item,
  ...props
}) {
  return (
    <DataTableCell {...props}>
      <Button label='Embark' onClick={item.embark} />
    </DataTableCell>
  );
}
_EmbarkActionCell.displayName = DataTableCell.displayName;


const _MAP_TABLE_COLUMNS = [
  <DataTableColumn
    key='map-id'
    label='ID'
    property='id'
  >
    <IdDisplayTableCell />
  </DataTableColumn>,
  <DataTableColumn
    key='map-name'
    label='Name'
    property='name'
  />,
  <DataTableColumn
    key='map-public-name'
    label='Public Name'
    property='publicName'
  />,
  <DataTableColumn
    key='actions-configure'
    label=''
    property='__fakeActionsAddRemove__'
  >
    <_AddRemoveMapCell/>
  </DataTableColumn>,
  <DataTableColumn
    key='actions-embark'
    label=''
    property='__fakeActionsEmbark__'
  >
    <_EmbarkActionCell/>
  </DataTableColumn>,
];


function CampaignDetailsMapsView({
  campaign,
  maps,
  newMap,
  filterFields,
  handleChangeFilter,
}) {
  return (
    <React.Fragment>
      {/* if */(campaign) ? (
        <React.Fragment>
          <div className='flex--container-horizontal layout--bottom-margin-medium'>
            <div className='flex--item-fill' />
            <div className='flex--item-fixed'>
              <Button label='Add New Map' onClick={newMap}/>
            </div>
            <div className='flex--item-fill' />
          </div>
          {/* Need to add link to create new map */}
          <DataTable
            items={maps}
            id='campaign-maps-table'
          >
            {_MAP_TABLE_COLUMNS}
          </DataTable>
        </React.Fragment>
      )/* endif */: null}
    </React.Fragment>
  );
}


export default class CampaignDetailsMaps extends AppReactComponent {

  static propTypes = {
    campaignContainer: PropTypes.instanceOf(CampaignDetailsStateRef).isRequired,
  };

  constructor(props) {
    super(props);
    this.campaignContainer = this.constantProp('campaignContainer');
    this.mapListContainer = new RecordCollectionState(this.$b._getMaps);
    this.filterForm = new FormHandler(this.$b._filterFormRefresh);
    this.addSetup(this.mapListContainer.$b.refresh);
    this.connect('campaign', this.campaignContainer);
    this.connect('filteredMaps', this.mapListContainer);
    this.connect('filterForm', this.filterForm, 'view');
    this.addCleanup(
      this.mapListContainer.$b.close,
      this.filterForm.$b.close,
    );
  }

  async _getMaps(throwIfCanceled) {
    // TODO: Include the filter (if available) in the search
    const campaignId = this.state.campaign.campaignId;
    const mapsResult = await MapsRPCAPI.searchMaps({campaignId: {value: campaignId}});
    throwIfCanceled();
    const maps = mapsResult.maps || [];
    maps.sort((mapA, mapB) => mapA.name.localeCompare(mapB.name));
    return maps;
  }

  _filterFormRefresh(data) {
    return {filter: data.filter || ''};
  }

  _handleChangeFilter(event, data) {
    // TODO: Trigger a this.mapListContainer refresh on a debounce
  }

  _navigateToNewMap() {
    AppGlobal.navigation.configureNewMap(this.state.campaign.campaignId);
  }

  async _addMapToCampaign(mapId) {
    const campaignId = this.campaignContainer.campaignId;
    const map = this.mapListContainer.getRecordById(mapId);
    await this.campaignContainer.updateCampaignMaps({addMaps: [map]}, true);
    this.mapListContainer.patchRecordById(mapId, {campaignId: campaignId});
  }

  async _removeMapFromCampaign(mapId) {
    const map = this.mapListContainer.getRecordById(mapId);
    await this.campaignContainer.updateCampaignMaps({removeMaps: [map]}, true);
    this.mapListContainer.patchRecordById(mapId, {campaignId: null});
  }

  render() {
    const state = this.state;
    return CampaignDetailsMapsView({
      newMap: this.$b._navigateToNewMap,
      campaign: state.campaign.campaign,
      maps: this._makeViewMaps(state.campaign.campaignId, state.filteredMaps.records),
      filterFields: state.filterForm,
      handleChangeFilter: this.$b._handleChangeFilter,
    });
  }

  /**
   * Function for generating map objects that can be used in the table more
   * easily.
   */
  _makeViewMaps = Memoize((campaignId, maps) => {
    if (!maps) {
      return [];
    }
    return maps.map((map) => {
      return {
        id: map.id,
        name: map.name,
        publicName: map.publicName,
        campaignId: map.campaignId,
        isThisCampaign: map.campaignId === campaignId,
        embark: (event) => AppGlobal.navigation.embarkMap(campaignId, map.id),
        configure: (event) => AppGlobal.navigation.configureMap(map.id),
      }
    })
  });

}

