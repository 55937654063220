import makeBindable from 'utils/bound-methods';

type UnsubscribeType = () => void;


/**
 * An extremely simple pub-sub mechanism primarily used as a way to communicate
 * between sibling components. The intended usage pattern is:
 *
 * Parent component creates the SimpleEventer. It passes the SimpleEventer
 * `subscribe` function as a constant-prop to one or more children. It also
 * passes the `publish` function as a constant-prop to one or more children. No
 * some children can listen to events from others. This can be used in cases
 * where it doesn't quite make sense to fully share state between components.
 *
 * I'm sure this isn't "best practice" or whatever but I don't know if I care.
 * Sometimes fully sharing state among all components in a tree is just weird.
 *
 * It can also be used to handle certain special state events. For example,
 * there may be a benefit in publishing a message whenever a new Record is
 * added into a store (i.e. when a "new" record is converted to a real one).
 * This can allow anything that relies on that store to potentially refresh.
 */
export default class SimplePubSub<DT> {

  _subscribers: Map<(data: DT) => void, (data: DT) => void>;

  $b: any;
  $bdestroy: any;

  constructor() {
    makeBindable(this);
    this._subscribers = new Map();
  }

  destroy(): void {
    this._subscribers = null;
    this.$bdestroy();
  }

  subscribe(callback: (data: DT) => void): UnsubscribeType {
    if (!callback) {
      throw new Error('A callback is required');
    }
    this._subscribers.set(callback, callback);
    return () => this.unsubscribe(callback);
  }

  publish(data: any): void {
    for (const callback of this._subscribers.values()) {
      callback(data);
    }
  }

  unsubscribe(callback: (data: DT) => void): void {
    if (this._subscribers && this._subscribers.has(callback)) {
      this._subscribers.delete(callback);
    }
  }

}
