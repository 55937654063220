import React from 'react';
import {withRouter} from 'react-router';

import AppPageTemplateConnected from 'components/templates/app-page-template-connected';
import CenteredBox from 'components/layouts/centered-box';
import SharedMapDetailsLoader from './components/shared-map-details-loader';

import MapDetailsForm from 'apps/maps/components/map-details-form';


function MapZoneDetailsPage(props) {
  return (
    <AppPageTemplateConnected>
      <SharedMapDetailsLoader>
        <CenteredBox className='border-box' style={{width: '400px'}}>
          <h3 className='slds-text-heading_medium'>Update Map Details</h3>
          <MapDetailsForm />
        </CenteredBox>
      </SharedMapDetailsLoader>
    </AppPageTemplateConnected>
  );
}

export default withRouter(MapZoneDetailsPage);
