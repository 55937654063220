import React from 'react';
import classNames from 'classnames';


/**
 * A layout with flexible-siezd content and fixed, optional header/footers.
 */
export default function FixedHeaderFooter({
  header,
  footer,
  overflowing,
  children,
}) {
  return (
    <div
      className={classNames({
        'fixed-hf-container': !overflowing,
        'fixed-hf-container-overflowing': overflowing,
      })}
      >
      {/* if */(header) ? (
        <div
          className={classNames({
            'fixed-hf-header': !overflowing,
            'fixed-hf-header-overflowing': overflowing,
          })}
        >
          {header}
        </div>
      )/* endif */:null}
      <div
        className={classNames({
          'fixed-hf-content': !overflowing,
          'fixed-hf-content-overflowing': overflowing,
        })}
      >
        {children}
      </div>
      {/* if */(footer) ? (
        <div
          className={classNames({
            'fixed-hf-footer': !overflowing,
            'fixed-hf-footer-overflowing': overflowing,
          })}
        >
          {footer}
        </div>
      )/* endif */:null}
    </div>
  );
}
