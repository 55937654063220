

export default class ToolUtils {

  static getCursorCellColRow(controller, grid) {
    const cursorX = controller.getCursorX();
    const cursorY = controller.getCursorY();
    let col = null;
    let row = null;
    if (cursorX !== null && cursorY !== null) {
      col = grid.pixelToContainingCol(cursorX, cursorY);
      row = grid.pixelToContainingRow(cursorX, cursorY);
    }
    return [col, row];
  }


}
