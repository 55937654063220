
function delta(v1: number, v2: number): number {
  return Math.abs(v1 - v2);
}


function fracDelta(v1: number, v2: number): number {
  if (v1 === 0 || v2 === 0) {
    return NaN;
  } else if (v1 >= v2) {
    return (v1 / v2) - 1;
  } else {
    return (v2 / v1) - 1;
  }
}


class RunningAverage {

  _prev: null | number;
  _prevFactor: number;
  _nextFactor: number;

  constructor() {
    this._prev = null;
    this._prevFactor = 0.6;
    this._nextFactor = 1 - this._prevFactor;
  }

  get value(): number {
    return this._prev || 0;
  }

  add(value: number) {
    let next: number;
    if (this._prev === null) {
      next = value;
    } else {
      next = (value * this._nextFactor) + (this._prev * this._prevFactor);
    }
    this._prev = next;
    return next;
  }

}


export default Object.freeze({
  RunningAverage,
  delta,
  fracDelta,
});
