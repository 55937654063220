import Memoize from 'memoize-one';
import propTypes from 'prop-types';
import React from 'react';

import {ButtonGroup} from '@salesforce/design-system-react';
import {ButtonIcon} from '@salesforce/design-system-react';
import {ButtonStateful} from '@salesforce/design-system-react';
import {Tooltip} from '@salesforce/design-system-react';

import EmbarkToolsState from 'apps/maps/state/embark-tools';
import ReactComponent from 'utils/react-component';
import Spacer from 'components/layouts/spacer';


function EmbarkToolbarOptionsVisOverrideView({
  tool,
  toolSelector,
}) {
  return (
    <React.Fragment>
      <ButtonGroup id='button-group-icon-group-2'>
        <Tooltip content='Change single grid cell' hoverCloseDelay={0}>
          <ButtonStateful
            icon={<ButtonIcon category='utility' name='edit'/>}
            assistiveText={{icon: 'Change single grid cell'}}
            active={tool.options.shape === EmbarkToolsState.OPTION_DRAW_SHAPE_GRID_BRUSH}
            onClick={toolSelector.modifyDrawShapeGridBrush}
            variant='icon-filled'
          />
        </Tooltip>
        <Tooltip content='Change rectangular area' hoverCloseDelay={0}>
          <ButtonStateful
            icon={<ButtonIcon category='utility' name='new_direct_message'/>}
            assistiveText={{icon: 'Change rectangular area'}}
            active={tool.options.shape === EmbarkToolsState.OPTION_DRAW_SHAPE_RECT}
            onClick={toolSelector.modifyDrawShapeRect}
            variant='icon-filled'
          />
        </Tooltip>
      </ButtonGroup>
      <Spacer />
      <ButtonGroup id='button-group-icon-group-3'>
        <Tooltip content='Completely hide the area from players' hoverCloseDelay={0}>
          <ButtonStateful
            icon={<ButtonIcon category='utility' name='ban'/>}
            assistiveText={{icon: 'Completely remove from player vision'}}
            active={tool.options.operation === EmbarkToolsState.OPTION_VIS_HIDE}
            onClick={toolSelector.modifyVisOverrideHide}
            variant='icon-filled'
          />
        </Tooltip>
        <Tooltip content='Leave the area explored but remove it from player vision' hoverCloseDelay={0}>
          <ButtonStateful
            icon={<ButtonIcon category='utility' name='hide'/>}
            assistiveText={{icon: 'Leave the area explored but remove it from player vision'}}
            active={tool.options.operation === EmbarkToolsState.OPTION_VIS_DARK}
            onClick={toolSelector.modifyVisOverrideDark}
            variant='icon-filled'
          />
        </Tooltip>
        <Tooltip content='Show the area to players' hoverCloseDelay={0}>
          <ButtonStateful
            icon={<ButtonIcon category='utility' name='preview'/>}
            assistiveText={{icon: 'Leave the area explored but remove it from player vision'}}
            active={tool.options.operation === EmbarkToolsState.OPTION_VIS_LIGHT}
            onClick={toolSelector.modifyVisOverrideLight}
            variant='icon-filled'
          />
        </Tooltip>
      </ButtonGroup>
    </React.Fragment>
  );
}


export default class EmbarkToolbarOptionsVisOverride extends ReactComponent {

  static propTypes = {
    role: propTypes.any,
    setToolOption: propTypes.any,
    tool: propTypes.any,
  };

  render() {
    return EmbarkToolbarOptionsVisOverrideView({
      toolSelector: this._getToolSelector(this.props.setToolOption),
      role: undefined,  // TODO: Limit tools based on role/permissions
      tool: this.props.tool,
    });
  }

  _getToolSelector = Memoize((setToolOption) => {
    return Object.freeze({
      modifyDrawShapeGridBrush: () => {
        setToolOption('shape', EmbarkToolsState.OPTION_DRAW_SHAPE_GRID_BRUSH);
      },
      modifyDrawShapeRect: () => {
        setToolOption('shape', EmbarkToolsState.OPTION_DRAW_SHAPE_RECT);
      },
      modifyVisOverrideHide: () => {
        setToolOption('operation', EmbarkToolsState.OPTION_VIS_HIDE);
      },
      modifyVisOverrideDark: () => {
        setToolOption('operation', EmbarkToolsState.OPTION_VIS_DARK);
      },
      modifyVisOverrideLight: () => {
        setToolOption('operation', EmbarkToolsState.OPTION_VIS_LIGHT);
      },
    });
  });

}
