const TWO_PI = 2 * Math.PI;


export default class Vector {

  /**
   * Returns a normalized angle in the range [0, 2π)
   */
  static normalizeAngle(angle: number): number {
    angle = angle % TWO_PI;
    if (angle < 0) {
      angle += TWO_PI;
    }
    return angle;
  }

  static fromCoordinates(x2: number | null, y2: number | null) {
    if (x2 === null || y2 === null) {
      return new Vector();
    }
    return new Vector(
      Math.atan2(y2, x2),
      Math.sqrt((x2 * x2) + (y2 * y2)),
    );
  }

  static fromPoints(x1: number, y1: number, x2: number, y2: number) {
    const dx = x2 - x1;
    const dy = y2 - y1;
    return Vector.fromCoordinates(dx, dy);
  }

  /** The angle of the vector in radians. In the range [0, 2π). */
  public readonly angle: number = 0;
  /** The magnitude (length) of the vector */
  public readonly magnitude: number = 0;
  /** The x-coordinate of the vector starting position */
  public readonly x: number = 0;
  /** The y-coordinate of the vector starting position */
  public readonly y: number = 0;
  /** The projection of the vector's magnitude on the x-axis */
  public readonly magX: number = 0;
  /** The projection of the vector's magnitude on the y-axis */
  public readonly magY: number = 0;

  constructor(angle?: number, magnitude?: number) {
    if (angle === undefined) {
      this.x = 0;
      this.y = 0;
      this.angle = 0;
      this.magnitude = 0;
      this.magX = 0;
      this.magY = 0;
    } else if (magnitude === undefined) {
      this.x = 0;
      this.y = 0;
      this.angle = Vector.normalizeAngle(angle);
      this.magnitude = 1;
      this.magX = Math.cos(this.angle) * this.magnitude;
      this.magY = Math.sin(this.angle) * this.magnitude;
    } else {
      this.x = 0;
      this.y = 0;
      this.angle = Vector.normalizeAngle(angle);
      this.magnitude = magnitude;
      this.magX = Math.cos(this.angle) * this.magnitude;
      this.magY = Math.sin(this.angle) * this.magnitude;
    }
  }

  get angleDeg(): number {
    return this.angle * 180 / Math.PI;
  }

  add(other: Vector): Vector {
    return Vector.fromCoordinates(this.magX + other.magX, this.magY + other.magY);
  }

  withAngle(newAngle: number): Vector {
    if (newAngle === this.angle) {
      return this;
    }
    return new Vector(newAngle, this.magnitude);
  }

  clampMagnitude(min: number, max: number): Vector {
    const nextMagnitude = Math.max(min, Math.min(this.magnitude, max));
    if (nextMagnitude === this.magnitude) {
      return this;
    }
    return new Vector(this.angle, nextMagnitude);
  }

  withMagnitude(newMagnitude: number): Vector {
    if (newMagnitude === this.magnitude) {
      return this;
    }
    return new Vector(this.angle, newMagnitude);
  }

  multiply(value: number): Vector {
    if (value === 1) {
      return this;
    }
    return new Vector(this.angle, this.magnitude * value);
  }

  toMagnitudeComponents(): [x: number, y: number] {
    return [this.magX, this.magY];
  }



}
