import classsNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Creates visual spacer content
 */
export default function Spacer({
  direction,
  size,
}) {
  const isRow = direction === 'row';
  const isCol = direction === 'col' || direction === 'column';
  return (
    <div
      className={classsNames({
        'spacer': true,
        'spacer--row-medium': isRow && size === 'medium',
        'spacer--row-large': isRow && size === 'large',
        'spacer--col-medium': isCol && size === 'medium',
        'spacer--col-large': isCol && size === 'large',
      })}
      aria-hidden='true'
    >
      <div
        className={classsNames({
          'spacer--line-veritcal': isRow,
          'spacer--line-horizontal': isCol,
        })}
      />
    </div>
  )
}


Spacer.propTypes = {
  // "row" for horizontal elements (a veritcal spacer line), and "column" for
  //  veritcal elements (a horizontal spacer line)
  direction: PropTypes.string,

  // SLDS-style size identifier (`x-small, small, medium, large`) defining
  // both how much space the spacer itself takes up as well as the margins
  // on each side of it.
  // Currently supports `medium | large`.
  size: PropTypes.string,
};

Spacer.defaultProps = {
  direction: 'row',
  size: 'medium',
};
