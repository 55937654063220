

interface EventType {
  eventId: string,
}

// For the sake of compatibility, primary pointer events and all mouse events
// use this id to identify them. That way the primary pointer event will always
// match an associated mouse event.
const EVENT_ID_PRIMARY_POINTER = '__primary__';


/**
 *
 */
export default class PriorityEvents {

  protected _priorityEvents: Map<string, Event> = new Map();

  constructor() {

  }

  protected _addEvent(id: string, event: Event): void {
    if (this._priorityEvents.has(id)) {
      console.warn('Event is already a priority event', id, event);
      return;
    }
    this._priorityEvents.set(id, event);
  }

  protected _removeEvent(id: string, event: Event): void {
    if (!this._priorityEvents.has(id)) {
      console.warn('Event is not a priority event', id, event);
      return;
    }
    this._priorityEvents.delete(id);
  }

  protected _getPointerEventId(event: PointerEvent): string {
    return event.isPrimary ? EVENT_ID_PRIMARY_POINTER : `${event.pointerId}`;
  }

  protected _getMouseEventId(event: MouseEvent): string {
    return EVENT_ID_PRIMARY_POINTER;
  }

  protected _getTouchEventId(event: TouchEvent): string {
    throw new Error('TODO: Implement');
  }

  registerPointerEvent(event: PointerEvent): void {
    const eventId = this._getPointerEventId(event);
    if (event.type === 'pointerdown') {
      this._addEvent(eventId, event);
    } else if (event.type === 'pointermove' || event.type === 'pointerout') {
      if (!this._priorityEvents.has(eventId)) {
        this._addEvent(eventId, event);
      }
    } else if (event.type === 'pointerup' || event.type === 'pointerlost') {
      this._removeEvent(eventId, event);
    } else {
      throw new Error('Unknown pointer event!');
    }
  }

  unregisterPointerEvent(event: PointerEvent): void {
    this._removeEvent(this._getPointerEventId(event), event);
  }

  registerMouseEvent(event: MouseEvent): void  {
    const eventId = this._getMouseEventId(event);
    if (event.type === 'mousedown') {
      this._addEvent(eventId, event);
    } else if (event.type === 'mousemove' || event.type === 'mouseout') {
      if (!this._priorityEvents.has(eventId)) {
        this._addEvent(eventId, event);
      }
    } else if (event.type === 'mouseup') {
      this._removeEvent(eventId, event);
    } else {
      throw new Error('Unknown pointer event!');
    }
  }

  registerTouchEvent(event: TouchEvent): void  {
    this._addEvent(this._getTouchEventId(event), event);
  }

  registerHammerEvent(event: any): void  {
    for (const pointer of event.pointers) {
      this.registerPointerEvent(pointer as PointerEvent);
    }
  }

  unregisterHammerEvent(event: any): void  {
    for (const pointer of event.pointers) {
      this.unregisterPointerEvent(pointer as PointerEvent);
    }
  }

  isPriorityPointerEvent(event: PointerEvent): boolean {
    const eventId = this._getPointerEventId(event);
    return this._priorityEvents.has(eventId);
  }

  isPriorityMouseEvent(event: MouseEvent): boolean {
    const eventId = this._getMouseEventId(event);
    return this._priorityEvents.has(eventId);
  }

  isPriorityTouchEvent(event: TouchEvent): boolean {
    const eventId = this._getTouchEventId(event);
    return this._priorityEvents.has(eventId);
  }

}
