import Bitmap from 'utils/graphics/bitmap';
import MapsRPCAPI from 'apis/maps-rpc-api';
import statelib from 'statelib';

import MapImageStoreRef from './map-image-store-ref';

import type Fstate from 'statelib/fstate';

type MapImageRecordType = any;  // TODO: Fix me


/**
 * Handles loading and refreshing images.
 *
 * TODO: If you need to clone this again, make another subclass called
 * CampaignRecordStore that shares all of the campaign stuff. Most objects
 * require a campaign reference, so it makes a lot of sense to have that all be
 * common.
 */
export default class MapImageStoreState extends statelib.RecordStoreState<MapImageRecordType>{

  static DEFAULT_NAME = 'MapImageStore';

  /** Bitmaps by image ID. Note that bitmaps will not change even if other image
    * data does (since the content is pre-loaded and there is no metadata).
    * This means that they can safely be stored in here rather than in
    * `data` */
  _bitmaps: Map<string, Bitmap> = new Map();

  _campaignId: string;
  _campaignRef: Fstate;
  _campaignRefUnsubscribe?: () => void;


  constructor(options) {
    super({...options, keyField: 'id'});
    this._campaignId = null;
    this._campaignRef = options.campaignRef || null;
    this._campaignRefUnsubscribe = null;
    if (this._campaignRef) {
      this._campaignRefUnsubscribe = this._campaignRef.subscribe(this.$b._handleCampaignChange);
    }
  }

  destroy() {
    if (this._campaignRefUnsubscribe) {
      this._campaignRefUnsubscribe();
      this._campaignRefUnsubscribe = null;
    }
    this._campaignRef = null;
    this._campaignId = null;
    if (this._bitmaps) {
      for (const bitmap of this._bitmaps.values()) {
        bitmap.destroy();
      }
    }
    this._bitmaps.clear();
    super.destroy();
  }

  get campaignId() {
    return this._campaignId;
  }

  set campaignId(campaignId) {
    campaignId = campaignId || null;
    if (campaignId === this._campaignId) {
      return;
    }
    this.resetAll();
    this._campaignId = campaignId;
  }

  getRecordRef(options, key?: KeyType): MapImageStoreRef {
    const recordRef = new MapImageStoreRef(this, options);
    if (key) {
      recordRef.key = key;
    }
    return recordRef;
  }

  _handleCampaignChange(data) {
    if (this._campaignId !== data.campaignId) {
      this.campaignId = data.campaignId;  // Setter handles cleanup work.
    }
  }

  /**
   * Loads multiple images by key (id).
   *
   * Note that "loading" new keys is safe - they will be ignored
   */
  async load(keys: KeyType[]) {
    if (keys) {
      keys = keys.filter(key => !this.isNewKey(key));
    }
    const campaignId = this._campaignId;
    if (!campaignId) {
      console.warn('Attempting to load Map Images but no campaign');
      return;
    }
    try {
      await this._async.wrap(async (throwIfCanceled) => {
        // TODO: Set up batches of, like, 100 or something
        const searchResult = await MapsRPCAPI.searchImages({
          campaignId: campaignId,
          bulk: {imageIds: keys},
        });
        throwIfCanceled();
        this.setRecords(searchResult.images || [], {expectedKeys: keys});
      });
    } catch (err) {
      console.error(`${this.name}: Unable to load images (${keys})`, err);
      throw err;
    }
  }

  /**
   * Returns the bitmap associated with the image. This will raise an error if
   * the image record has not been loaded yet. It will _not_ raise an error if
   * the bitmap data has not been loaded yet - the caller may wait for the
   * image data to exist by awaiting Bitmap.load().
   */
  getBitmapSync(imageId: string, quiet?: boolean): Bitmap {
    if (!this._bitmaps.has(imageId)) {
      const imageRecord = this.getRecordSync(imageId, quiet);
      if (quiet && !imageRecord) {
        return null;
      }
      this._setupImageBitmap(imageRecord);
    }
    return this._bitmaps.get(imageId);
  }

  async getBitmap(imageId: string, awaitLoad?: boolean): Promise<Bitmap> {
    if (!this._bitmaps.has(imageId)) {
      const imageRecord = await this.getRecord(imageId);
      // One last check to see if another "thread" loaded the bitmap while we
      // waited for `getRecord` to finish.
      if (!this._bitmaps.has(imageId)) {
        this._setupImageBitmap(imageRecord);
      }
    }
    const imageBitmap = this._bitmaps.get(imageId);
    if (awaitLoad && !imageBitmap.isReady) {
      await imageBitmap.load();
    }
    return imageBitmap;
  }

  /**
   * Creates a new Bitmap for the supplied image and stores it locally
   */
  protected _setupImageBitmap(imageRecord: MapImageRecordType): Bitmap {
    const imageId = imageRecord.id;
    if (!imageRecord || !imageRecord.url) {
      throw new Error(`Image ${imageId} cannot be loaded`);
    }
    const imageBitmap = new Bitmap(
      document,  // Should document be injected as a dependency in the constructor?
      imageRecord.url,
      imageRecord.width,
      imageRecord.height,
    );
    // Begin loading the image. If the caller wants to `await` the image
    // loading, it can `await imageBitmap.load()` as well.
    imageBitmap.load();
    this._bitmaps.set(imageId, imageBitmap);
    return imageBitmap;
  }

  async searchImages(searchObj: any): Promise<MapImageRecordType[]> {
    // TODO: There may be some weirdness around campaigns, but I think this
    // is fine for now. Might want to eventually filter out any `imageObjects`
    // with a campaign ID mismatch.
    if (!searchObj.campaignId && !searchObj.mapId) {
      searchObj = {...searchObj, campaignId: this._campaignId};
    }
    return await this._async.wrap(async (throwIfCanceled) => {
      const searchResult = await MapsRPCAPI.searchImages(searchObj);
      throwIfCanceled();
      const imageObjects = searchResult.images || [];
      this.setRecords(imageObjects);
      return imageObjects;
    });
  }

  async patchImage(newImageData: any): Promise<MapImageRecordType> {
    return await this._async.wrap(async (throwIfCanceled) => {
      const nextImage = await MapsRPCAPI.patchImage(newImageData);
      throwIfCanceled();
      this.setRecords([nextImage]);
      return nextImage;
    })
  }

}
