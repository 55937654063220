import classNames from 'classnames';
import React from 'react';

import {Dropdown} from '@salesforce/design-system-react';


function _handleMenuSelect(option) {
  AppGlobal.rootRouter.history.push(option.href);
}

/**
 * Creates visual spacer content
 */
export default function EmbarkMenu() {
  return (
    <div
      className={classNames({
        'layout--simple-container-box': true,
        'layout--floating-top-left': true,
      })}
    >
      <Dropdown
        align='left'
        iconCategory='utility'
        iconName='rows'
        iconSize='large'
        buttonVariant='base'
        assistiveText={{ icon: 'Main Menu' }}
        onSelect={_handleMenuSelect}
        options={[
          {
            href: '/app/maps/campaigns',
            label: 'My Campaigns',
            value: 'my_campaigns',
          },
          {
            href: '#app_settings',
            label: 'Settings',
            value: 'settings',
          },
        ]}
      />
    </div>
  );
}
