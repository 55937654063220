
const _PATH_SEP = '.';
const _ROOT_PATH = '.';
const DEBUG = true;

// Cache of all previously parsed paths. The results of this are relatively
// small so I'm not super worried about the fact that the cache is unbounded.
const _pathParseMemos = {};


function _pathParseImpl(pathStr) {

  if (typeof(pathStr) !== 'string') {
    throw new Error('Paths must be strings');
  }

  const parts = (!pathStr || pathStr === _ROOT_PATH) ? [] : pathStr.split(_PATH_SEP);

  // Remove leading empty part. This allows for paths to start with '.'
  // which may be used to signify the root.
  if (parts.length && !parts[0]) {
    parts.shift();
  }

  const path = _ROOT_PATH + parts.join(_PATH_SEP);
  const parentPath = (parts.length)
    ? _ROOT_PATH + parts.slice(0, -1).join(_PATH_SEP) : null;
  const childPath = (parts.length)
    ? _ROOT_PATH + parts.slice(1).join(_PATH_SEP) : null;

  return Object.freeze({
    isRoot: parts.length === 0,
    isLocal: parts.length === 1,
    localKey: (parts.length) ? parts[0] : null,
    path: path,
    pathArr: parts,
    parentPath: parentPath,
    childPath: childPath,
  });
}


export function pathParse(path) {
  const memoValue = _pathParseMemos[path];
  if (memoValue) {
    return memoValue;
  }
  const newValue = _pathParseImpl(path);
  _pathParseMemos[path] = newValue;
  return newValue;
}


export function deepGet(obj, path, defaultValue) {
  if (!path) {
    throw new Error('Please specify a path');
  }
  const pathArr = pathParse(path).pathArr;
  const pathLen = pathArr.length;
  let curValue = obj;
  for (let i = 0; i < pathLen; i++) {
    // If we have remaining parts BUT can't go any further, break early
    if (curValue === undefined || curValue === null) {
      return defaultValue;
    }
    // Get the next value in the path
    try {
      curValue = curValue[pathArr[i]];
    } catch (err) {
      console.warn(`Requested invalid get of ["${pathArr[i]}"]`, curValue);
      curValue = undefined;
    }
  }
  return (curValue === undefined) ? defaultValue : curValue;
}
