import GameObject from './game-object';
import type FrameUpdateContext from '../frame-update-context';

type ScriptType = (frameUpdateContext: FrameUpdateContext) => void;


/**
 * A very basic wrapper around a simple function that will be executed every
 * frame but will never be rendered.
 *
 * You can either pass in a script function OR don't pass in a script function
 * and override `run` instead.
 */
export default class ScriptGameObject extends GameObject {

  protected _scriptFn: ScriptType;

  constructor(scriptFn?: ScriptType) {
    super();
    this.visible = false;
    this._scriptFn = scriptFn ? scriptFn : this.$b.run as ScriptType;
  }

  onUpdate(frameUpdateContext: FrameUpdateContext): void {
    this._scriptFn(frameUpdateContext);
  }

  protected run(frameUpdateContext: FrameUpdateContext): void {
    throw new Error('Must either provide a script function or implement `run`');
  }

}
