import Shapes from 'utils/geometry/shapes';
import statelib from 'statelib';


/**
 * Note that, in general, `mask` is mutable. The `config` is not and can be
 * an effective way to see when the associated configuration has changed.
 *
 * Because Mask objects are complicated, the
 */
export default class MaskRef extends statelib.FState {

  constructor(name) {
    super(name || 'MaskRef');
    // Can't have a mask without both the mask and grid config. Because of this,
    // they are stored separately (the config is eventually exposed once the
    // mask itself is created)
    this._maskConfig = null;
    this._gridConfig = null;
  }

  get mask() {
    return this.data.mask;
  }

  get version() {
    return this.data.version;
  }

  get config() {
    return this.data.config;
  }

  makeInitialData() {
    return {
      mask: null,
      config: null,
      version: 0,
    };
  }

  setMaskConfig(maskConfig, noRecycle) {
    if (maskConfig === undefined) {
      maskConfig = null;
    }
    if (this._maskConfig !== maskConfig) {
      const recycle = (
        !noRecycle &&
        this._maskConfig &&
        maskConfig &&
        this._maskConfig.width === maskConfig.width &&
        this._maskConfig.height === maskConfig.height &&
        this._maskConfig.detailLevel === maskConfig.detailLevel
      );
      this._maskConfig = maskConfig;
      this._refreshMask(recycle);
    }
  }

  setGridConfig(gridConfig) {
    if (gridConfig === undefined) {
      gridConfig = null;
    }
    if (this._gridConfig !== gridConfig) {
      this._gridConfig = gridConfig;
      this._refreshMask();
    }
  }

  setGridAndMaskConfig(gridConfig, maskConfig) {
    const noRecycle = gridConfig !== this._gridConfig;
    this.setGridConfig(gridConfig);
    this.setMaskConfig(maskConfig, noRecycle);
  }

  /**
   * Use this sot draw to the mask context. This is the "safest" way to update
   * the mask and it will trigger update events. Returns an image diff object
   * containing the image data that was changed (useful for undo)
   */
  withMaskContext(drawFn, scope, options) {
    const diff = this.data.mask.withMaskContext(drawFn, scope, options);
    this._maskConfig = this.data.mask.toProto();
    this.patchData({
      config: this._maskConfig,
      version: this.data.mask.version,
    });
    return diff;
  }

  /**
   * Applies the specified `ImageDataDiff` to the current mask. Returns the
   * region of the image that was changed (in Squares object format)
   */
  applyDiffsToMask(diffs) {
    const diffRegion = this.data.mask.applyDiffsToMask(diffs);
    this._maskConfig = this.data.mask.toProto();
    this.patchData({
      config: this._maskConfig,
      version: this.data.mask.version,
    });
    return diffRegion;
  }

  /**
   * Updates the internal mask shape with the latest config data. If `recycle`
   * is true, the mask object will be modified in place (which is a more
   * efficient option)
   */
  _refreshMask(recycle) {
    if (this._maskConfig && this._gridConfig) {
      if (recycle && this.data.mask !== null) {
        this.data.mask.fullUpdateMaskFromConfig(this._maskConfig);
        this._maskConfig = this.data.mask.toProto();
        this.patchData({
          config: this._maskConfig,
          version: this.data.mask.version,
        });
      } else {
        const nextMask = Shapes.Mask.fromProto(this._gridConfig, this._maskConfig);
        nextMask.initCanvas(document.createElement('canvas'));
        this._maskConfig = nextMask.toProto();
        this.patchData({
          mask: nextMask,
          config: this._maskConfig,
          version: nextMask.version,
        });
      }
    } else if (this.data.mask !== null) {
      this.patchData({
        mask: null,
        config: null,
        version: 0,
      });
    }
  }

}
