import {pathParse} from 'utils/obj-paths';


/**
 * A read-only view into an FState at a certain path.
 */
export default class FStateView {

  constructor(state, path) {
    this._state = fstate;
    this._path = path;
  }

  get data() {
    return this._state.getValue(path);
  }

  getFullPath(path) {
    if (path) {
      const parsed = pathParse(path);
      return this._path + parsed.path;
    } else {
      return this._path;
    }
  }

  getValue(path, defaultValue) {
    return this._state.getValue(this.getFullPath(path), defaultValue);
  }

  subscribe(callback, options) {
    options = options || {};
    options.path = this.getFullPath(options.path);
    return this._state.subscribe(callback, options);
  }

  onData(callback, options) {
    options = options || {};
    options.path = this.getFullPath(options.path);
    return this._state.onData(callback, options);
  }

  unsubscribe(callback, path) {
    return this._state.unsubscribe(callback, this.getFullPath(path));
  }

  async waitForCondition(condition, options) {
    options = options || {};
    options.path = this.getFullPath(options.path);
    return await this._state.waitForCondition(condition, options);
  }

  checkDestroyed() {
    return this._state.checkDestroyed();
  }

}
