import PropTypes from 'prop-types';
import React from 'react';

import {Tabs} from '@salesforce/design-system-react';
import {TabsPanel} from '@salesforce/design-system-react';

import CampaignDetailsStateRef from 'apps/maps/state/campaign-details-state-ref';
import ReactComponent from 'utils/react-component';
import {connectToComponentState} from 'statelib/fstate-react';

import CampaignDetailsMaps from 'apps/maps/components/campaign-details-maps';
import CampaignDetailsMembers from 'apps/maps/components/campaign-details-members';
import CampaignDetailsOverview from 'apps/maps/components/campaign-details-overview';

const TAB_INDEXES = Object.freeze({
  [undefined]: 0,
  [null]: 0,
  'overview': 0,
  'members': 1,
  'maps': 2,
});

const INVERSE_TAB_INDEXES = Object.freeze({
  0: 'overview',
  1: 'members',
  2: 'maps',
})


function CampaignDetailsView({
  campaign,
  campaignContainer,
  handleSelectCampaignSection,
  navigateToEmbark,
  selectedCampaignSection,
}) {
  return (
    <div>
      {/* if */(!campaign) ? (
        <p>Please select a campaign</p>
      )/* else */: (
        <Tabs
          id='campaign-details-tabs'
          selectedIndex={TAB_INDEXES[selectedCampaignSection]}
          onSelect={handleSelectCampaignSection}
        >
          <TabsPanel label='Overview'>
            <CampaignDetailsOverview campaignContainer={campaignContainer} />
          </TabsPanel>
          <TabsPanel label='Members'>
            <CampaignDetailsMembers campaignContainer={campaignContainer} />
          </TabsPanel>
          <TabsPanel label='Maps'>
            <CampaignDetailsMaps
              campaignContainer={campaignContainer}
              navigateToEmbark={navigateToEmbark}
            />
          </TabsPanel>
        </Tabs>
      )/* endif */}
    </div>
  );
}


export default class CampaignDetails extends ReactComponent {

  static propTypes = {
    campaignContainer: PropTypes.instanceOf(CampaignDetailsStateRef).isRequired,
    navigateToCampaign: PropTypes.func.isRequired,
    navigateToEmbark: PropTypes.func.isRequired,
    selectedCampaignSection: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.campaignContainer = this.constantProp('campaignContainer');
    connectToComponentState(this.campaignContainer, this, 'campaign');
  }

  handleSelectCampaignSection(newIndex, oldIndex) {
    const section = INVERSE_TAB_INDEXES[newIndex];
    this.props.navigateToCampaign(this.campaignContainer.campaignId, section);
  }

  render() {
    return CampaignDetailsView({
      campaign: this.state.campaign.campaign,
      campaignContainer: this.campaignContainer,
      handleSelectCampaignSection: this.$b.handleSelectCampaignSection,
      navigateToEmbark: this.props.navigateToEmbark,
      selectedCampaignSection: this.props.selectedCampaignSection,
    });
  }

}
