import React from 'react';
import {withRouter} from 'react-router';

import ToolboxLayout from 'components/layouts/toolbox-layout';
import AppPageTemplateConnected from 'components/templates/app-page-template-connected';

import MapsRPCAPI from 'apis/maps-rpc-api';
import ReactComponent from 'utils/react-component';
import MultiAsyncState from 'statelib/multi-async-state';
import {connectToComponentState} from 'statelib/fstate-react';

import RecordCollectionState from 'statelib/record-collection-state';
import CampaignDetailsStateRef from 'apps/maps/state/campaign-details-state-ref';

import CampaignSelector from 'apps/maps/components/campaign-selector';
import CampaignDetails from 'apps/maps/components/campaign-details';


function CampaignsListPageView({
  async,
  campaignContainer,
  campaigns,
  selectedCampaignId,
  selectedCampaignSection,
  navigateToNewCampaign,
  navigateToCampaign,
  navigateToEmbark,
}) {
  return (
    <AppPageTemplateConnected
      globalLoadingVisible={async.isRunning}
      globalLoading='Loading'
      headerVisible={true}
      globalErrorVisible={!!(!async.isRunning && async.error)}
      globalError={`${async.error}`}
    >
      <ToolboxLayout
        hasLeftToolbar={true}
        leftToolbarWidth={null}
        leftChildren={(
          <CampaignSelector
            campaigns={campaigns}
            selectedCampaignId={selectedCampaignId}
            navigateToCampaign={navigateToCampaign}
            navigateToNewCampaign={navigateToNewCampaign}
          />
        )}
      >
        <CampaignDetails
          campaignContainer={campaignContainer}
          navigateToCampaign={navigateToCampaign}
          navigateToEmbark={navigateToEmbark}
          selectedCampaignSection={selectedCampaignSection}
        />
      </ToolboxLayout>
    </AppPageTemplateConnected>
  );
}


class CampaignsListPage extends ReactComponent {

  constructor(props) {
    super(props);
    this.campaignListContainer = new RecordCollectionState(this.$b._getCampaigns);
    this.campaignContainer = new CampaignDetailsStateRef();
    this.multiAsync = new MultiAsyncState([
      this.campaignListContainer.getAttachedState('async'),
      this.campaignContainer.getAttachedState('async'),
    ], {name: 'CampaignsListPage_MultiAsync'});
    connectToComponentState(this.campaignListContainer, this, 'campaignList');
    this.addSetup(
      this.campaignListContainer.$b.refresh,
    );
    this.addCleanup(
      this.campaignListContainer.onListChange(this.$b._handleUpdatedCampaigns),
      this.campaignListContainer.$b.close,
    );
    this.addCleanup(this.campaignContainer.$b.close);
    connectToComponentState(this.multiAsync, this, 'async');
    this.addCleanup(this.multiAsync.$b.close);
  }

  /**
   * Get all of the campaigns that the user has access to.
   */
  async _getCampaigns(throwIfCanceled) {
    const campaignsResult = await MapsRPCAPI.getMyCampaigns({});
    throwIfCanceled();
    return campaignsResult.campaigns;
  }

  _handleUpdatedCampaigns() {
    this.campaignContainer.setAllowedIds(this.campaignListContainer.ids);
  }

  _navigateToNewCampaign() {
    this.props.history.push(`/app/maps/new-campaign`);
  }

  _navigateToCampaign(campaignId, section) {
    if (section) {
      this.props.history.push(`/app/maps/campaigns/${campaignId}/${section}`);
    } else {
      this.props.history.push(`/app/maps/campaigns/${campaignId}`);
    }
  }

  _navigateToEmbark(campaignId, mapId) {
    if (mapId) {
      this.props.history.push(`/app/maps/embark/campaign/${campaignId}/${mapId}`);
    } else {
      this.props.history.push(`/app/maps/embark/campaign/${campaignId}`);
    }
  }

  componentDidUpdate() {
    // Setting campaignId is idempotent
    this.campaignContainer.campaignId = this.props.match.params.campaignId;
  }

  render() {
    return CampaignsListPageView({
      async: this.state.async,
      campaignContainer: this.campaignContainer,
      campaigns: this.state.campaignList.records,
      navigateToCampaign: this.$b._navigateToCampaign,
      navigateToEmbark: this.$b._navigateToEmbark,
      navigateToNewCampaign: this.$b._navigateToNewCampaign,
      selectedCampaignId: this.props.match.params.campaignId,
      selectedCampaignSection: this.props.match.params.campaignSection,
    });
  }

}


export default withRouter(CampaignsListPage);
