import {pathParse} from 'utils/obj-paths';

import FState from './fstate';

const _ROOT_PATH = pathParse('').path;

type CallbackType = () => void;
type UnsubscribeType = () => void;


/**
 * A class designed to subscribe to multiple states and call a single
 * function whenever any of them changes.
 */
export default class FStateListener {

  protected _callback: CallbackType;
  protected _immediate: boolean;
  protected _sourceStates: Map<FState, Map<string, UnsubscribeType>>;

  constructor(callback: CallbackType, options?: {immediate?: boolean}) {
    options = options || {};
    this._callback = callback;
    this._immediate = (options.immediate !== undefined) ? options.immediate : false;
    this._sourceStates = new Map();
  }

  destroy() {
    this._callback = null;
    for (const subscriptions of this._sourceStates.values()) {
      for (const unsubscribe of subscriptions.values()) {
        unsubscribe();
      }
    }
  }

  listen(state: FState, path?: string) {
    if (!path) {
      path = _ROOT_PATH;
    }
    let stateSubscribers = this._sourceStates.get(state);
    if (!stateSubscribers) {
      stateSubscribers = new Map();
      this._sourceStates.set(state, stateSubscribers);
    }
    if (stateSubscribers.has(path)) {
      console.info(`Error: Already listening to ${state.name}.${path}`, this, state);
      throw new Error(`Already listening to ${state.name}.${path}`);
    }
    stateSubscribers.set(
      path, state.subscribe(this._callback, {path: path, immediate: this._immediate})
    );
  }

  _cleanup() {
    const toCleanup = [];
    for (const state of this._sourceStates.keys()) {
      if (state.isDestroyed) {
        toCleanup.push(state);
      }
    }
    for (const state of toCleanup) {
      this._sourceStates.delete(state);
    }
  }

}
