import React from 'react';
import ReactDOM from 'react-dom';

import AppRouter from './router';
//import {initialize as _mapsInitializer} from './maps/index';

let _store = null;

export const SINGLE_PAGE_APP = { router: AppRouter };


export function registerStore(store) {
  if (_store) {
    throw new Error('An app-level has already been registered')
  }
  _store = store;
}


export function getStore() {
  if (!_store) {
    throw new Error('An app-level has not yet been registered')
  }
  return _store;
}


export function initializeApp(rootElement, app) {
  if (!rootElement) {
    rootElement = document.getElementById('root');
  }
  if (app.initializer) {
    app.initializer();
  };
  ReactDOM.render(app.router(), rootElement);
}
