import FStateWithAsync from 'statelib/fstate-with-async';


/**
 * A more complicated versino of `MapViewRef` that will prepare the controller
 * with relevant map/map zone details and will only be "async.isSuccess" once
 * all aspects of the map have been fully loaded.
 */
export default class ConnectedMapViewRef extends FStateWithAsync {

  static DEFAULT_NAME = 'ConnectedMapViewRef';

  constructor({
    mapContainer,
    mapZoneContainer,
    options,
  }) {
    super(options);
    this._mapContainer = mapContainer;
    this._mapZoneContainer = mapZoneContainer;
    this._unsubs = [
      this._mapContainer.subscribe(this.$b.handleAsyncChange),
      this._mapZoneContainer.subscribe(this.$b.handleAsyncChange),
    ];
  }

  get grid() {
    return this.getValue('grid');
  }

  set controller(value) {
    if (value !== this.controller) {
      if (value === null) {
        console.log(`[${this.name}]: cleared controller`);
        this.patchData('mutableController', null);
      } else {
        console.log(`[${this.name}]: received new controller`, value);
        value.onImageLoaded = this.$b.handleAsyncChange;
        this.setValue('mutableController', value);
      }
      this.handleAsyncChange();
    }
  }

  get controller() {
    return this.getValue('mutableController');
  }

  makeInitialData() {
    return {
      mutableController: null,
      grid: null,
    };
  }

  destroy() {
    this._unsubs.map(unsub => unsub());
    super.destroy();
    console.log(`[${this.name}]: State has been destroyed`);
  }

  /**
   * Does a clean update on the internal state based on various factors like the
   * whether the controller's image is loaded and whether the associated map
   * and zone data are ready.
   */
  handleAsyncChange() {
    let nextGrid = null;
    let nextIsReady = false;
    let nextError = null;
    if (
      !this.controller ||
      this._mapContainer.async.isRunning ||
      this._mapZoneContainer.async.isRunning
    ) {
      console.debug(`[${this.name}]: Map view container still loading...`);
    } else if (this._mapContainer.async.isFailed) {
      nextError = this._mapContainer.async.error;
      console.error(`[${this.name}]: Map view container load failed`, nextError);
    } else if (this._mapZoneContainer.async.isFailed) {
      nextError = this._mapZoneContainer.async.error;
      console.error(`[${this.name}]: Map view container load failed'`, nextError);
    } else if (!this.controller.getImageSquare()) {
      console.info(`[${this.name}]: Updating map view image`);
      const zone = this._mapZoneContainer.mapZone;
      const bgImage = zone.backgroundImage;
      this.controller.setImage(bgImage.url, bgImage.width, bgImage.height);
    } else {
      nextGrid = this._mapZoneContainer.grid || null;
      nextIsReady = true;
    }
    // Update the combined state
    this.setValue('grid', nextGrid);
    if (nextError) {
      this._async.error(nextError);
    } else if (nextIsReady) {
      this._async.success();
    } else {
      this._async.progress();
    }
  }

}
