import React from 'react';
import {withRouter} from 'react-router';

import AppPageTemplateConnected from 'components/templates/app-page-template-connected';
import CenteredBox from 'components/layouts/centered-box';
import SharedMapDetailsLoader from './components/shared-map-details-loader';
import SharedMapZoneDetailsLoader from './components/shared-map-zone-details-loader';

import MapZoneDetailsForm from 'apps/maps/components/map-zone-details-form';


function MapZoneDetailsPage(props) {
  return (
    <AppPageTemplateConnected>
      <SharedMapDetailsLoader>
        <SharedMapZoneDetailsLoader>
          <CenteredBox className='border-box' style={{width: '400px'}}>
            <h3 className='slds-text-heading_medium'>Update Map Zone Details</h3>
            <MapZoneDetailsForm />
          </CenteredBox>
        </SharedMapZoneDetailsLoader>
      </SharedMapDetailsLoader>
    </AppPageTemplateConnected>
  );
}

export default withRouter(MapZoneDetailsPage);
