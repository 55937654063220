import BaseAsyncState from './base-async-state';


/**
 * MultiAsyncState is a read-only state that combines multiple other async state
 * objects into a single one.
 *
 * This can be useful for cases where a component needs to have an indicator
 * that may show multiple states in one. While this can sometimes be better
 * achieved by using a shared QueuedAsyncState (since that is serialized and is
 * therefore more "consistent"), that may not always be possible.
 */
export default class MultiAsyncState extends BaseAsyncState {

  constructor(asyncStates, options) {
    options = options || {};
    super(options.name || 'MultiAsyncState');
    // FUTURE: Allow a multi-async to reflect but not consolidate errors
    this._watchOnly = options.watchOnly;
    this._foundErrors = [];
    this._asyncStates = [...asyncStates];
    this._unsubscribes = this._asyncStates.map(
      asyncState => asyncState.subscribe(this.$b._refresh, {immediate: false})
    );
    this._refresh();
  }

  destroy() {
    // Note that this does not destroy any of the async states that it is
    // attached to since it doesn't "own" any of them.
    for (const unsubscribe of this._unsubscribes) {
      unsubscribe();
    }
    super.destroy();
  }

  _refresh() {
    const asyncLen = this._asyncStates.length;
    const nextData = {
      isRunning: false,
      isComplete: true,
      isFailed: false,
      isSuccess: true,
      error: null,
      message: null,
      progress: 0.0,
    };
    if (this._watchOnly) {
      // The watch-only version does not try to receive/consolidate errors,
      // it just reports on the current state
      for (const asyncState of this._asyncStates) {
        const async = asyncState.data;
        nextData.isRunning = nextData.isRunning || async.isRunning;
        nextData.isComplete = !nextData.isRunning;
        nextData.isFailed = nextData.isComplete && (nextData.isFailed || async.isFailed);
        nextData.isSuccess = nextData.isComplete && !nextData.isFailed;
        nextData.error = nextData.error ? nextData.error : async.error;
        nextData.message = nextData.message ? nextData.message : async.message;
      }
    } else {
      // The more robust multi-async will attempt to receive errors it
      // encounters so that they are consolidated and can be more easily
      // handled.
      for (const asyncState of this._asyncStates) {
        const async = asyncState.data;
        if (async.isFailed) {
          this._foundErrors.push(asyncState.receiveError());
        }
        const hasErrors = this._foundErrors.length > 0;
        nextData.isRunning = !hasErrors && (nextData.isRunning || async.isRunning);
        nextData.isComplete = hasErrors || !nextData.isRunning;
        nextData.isFailed = hasErrors;
        nextData.isSuccess = nextData.isComplete && !nextData.isFailed;
        nextData.error = hasErrors ? this._foundErrors[0].error : null;
        nextData.message = hasErrors ? this._foundErrors[0].message : null;
      }
    }
    // Calculate progress based on final state.
    if (!nextData.isRunning && !nextData.isComplete) {
      nextData.progress = 0.0;
    } else if (!nextData.isComplete) {
      const asyncDatas = this._asyncStates.map(asyncState => asyncState.data);
      nextData.progress = asyncDatas.reduce(
        (stateVal, async) => stateVal + (async.progress / asyncLen), 0.0
      );
    } else {
      nextData.progress = 1.0;
    }
    // Next state is ready to go! Replace the internal state accordingly
    this.setDataIfChanged(nextData);
  }

  /**
   * Receives the next queued error. Note that a multi-async may have many
   * queued errors so this is not guaranteed to put it back into a reset state.
   *
   * Note that if this is a "watch only" state it never has errors of its own so
   * receiving errors will do nothing (you must receive the underlying errors
   * to clear them out)
   */
  receiveError() {
    if (this._foundErrors.length < 1) {
      console.warn('Called `receiveError` with no errors queued');
      return null;
    }
    const nextError = this._foundErrors.shift();
    this._refresh();
    return nextError;
  }

  /**
   * Removes the next error from the queue. Like `receiveError`, this works one
   * at a time, so if multiple errors are queued up, clearing one will not
   * necessarily restore the async to a "reset" state.
   */
  clearError() {
    if (this._foundErrors.length < 1) {
      console.warn('Called `receiveError` with no errors queued');
      return null;
    }
    const nextError = this._foundErrors.shift();
    this._refresh();
  }

  cancel() {
    // Attempt to cancel each of the underlying async jobs.
    for (const asyncState of this._asyncStates) {
      try {
        asyncState.cancel();
      } catch (err) {
        console.warn('Unable to cancel async: ', err);
      }
    }
  }

}
