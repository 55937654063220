import lodashUniqueId from 'lodash.uniqueid';
import {shallowEqual} from 'utils/types';

import FState from './fstate';


class AsyncHasFailedError extends Error {}


class AsyncCanceledError extends Error {}


export default class BaseAsyncState extends FState {

  static AsyncCanceledError = AsyncCanceledError;
  static AsyncHasFailedError = AsyncHasFailedError;
  static isCanceled = (err) => err instanceof AsyncCanceledError;

  _errorWatcher: any | null = null;
  _errorId: string | null = null;

  constructor(name: string) {
    super(name || 'QueuedAsyncState');
    this._errorWatcher = null;
  }

  get isRunning() {
    return this.getValue('isRunning');
  }

  get isComplete() {
    return this.getValue('isComplete');
  }

  get isSuccess() {
    return this.getValue('isSuccess');
  }

  get isFailed() {
    return this.getValue('isFailed');
  }

  get errorObject() {
    return this.getValue('error');
  }

  get errorMessage() {
    return this.getValue('message');
  }

  makeInitialData() {
    return {
      isRunning: false,
      isComplete: false,
      isSuccess: false,
      isFailed: false,
      progress: 0.0,
      error: null,
      message: '',
      errorId: null,
    };
  }

  /**
   * Raises an error if the async is currently in a failed state. Called before
   * starting a new asynchronous request.
   */
  _checkFailed() {
    this.checkDestroyed();
    if (this.data.isFailed) {
      throw new BaseAsyncState.AsyncHasFailedError(
        `A previous async request has failed: ${this.data.message}`
      );
    }
  }

  /**
   * Resets the async data. For some Async States, this will need to be called
   * if the state is "errored" before other calls can be made
   * (otherwise subsequent calls will automatically fail).
   */
  reset() {
    if (this._errorWatcher) {
      clearTimeout(this._errorWatcher);
    }
    const nextData = {
      isRunning: false,
      isComplete: false,
      isSuccess: false,
      isFailed: false,
      progress: 0.0,
      error: null,
      message: '',
      errorId: null,
    };
    this.setDataIfChanged(nextData);
  }

  start() {
    this._checkFailed();
    const nextData = {
      isRunning: true,
      isComplete: false,
      isSuccess: false,
      isFailed: false,
      progress: 0.0,
      error: null,
      message: '',
      errorId: null,
    };
    this.setDataIfChanged(nextData);
  }

  /**
   * Updates progress on the async state. Serves two main purposes: First, this
   * allows multi-phase async to set some sort of progress indicator(by setting
   * `amount` to a value between 0 and 1). It also allows for code to "force"
   * the async into a running state without needing to know its previous state.
   * For example, something that is waiting on 2 other objects to be ready can
   * call `progress` when 0 or 1 of them are ready, the success when they both
   * are.
   */
  progress(amount, message) {
    const nextData = {
      isRunning: true,
      isComplete: false,
      isSuccess: false,
      isFailed: false,
      progress: amount || 0.0,
      error: null,
      message: message || '',
      errorId: null,
    };
    this.setDataIfChanged(nextData);
  }

  success() {
    const nextData = {
      isRunning: false,
      isComplete: true,
      isSuccess: true,
      isFailed: false,
      progress: 1.0,
      error: null,
      message: 'OK',
      errorId: null,
    };
    this.setDataIfChanged(nextData);
  }

  error(errorData, message) {
    if (!message) {
      message = `${errorData}`;
    }
    const errorId = lodashUniqueId('async_error_');
    const nextData = {
      isRunning: false,
      isComplete: true,
      isSuccess: false,
      isFailed: true,
      progress: 1.0,
      error: errorData,
      message: message,
      errorId: errorId,
    };
    // Output a warning here to retain the original stack trace in the logs.
    console.warn(`Async error (${errorId}): ${message}`);
    if (!shallowEqual(this.data, nextData)) {
      // In addition to setting the error data, start a timer to ensure that the
      // error is `receive`d in a timely manner. Errors not received can result
      // in bad behavior. If this message ever shows up in the logs, it means
      // that code changes are required to properly handle the async error.
      this._errorWatcher = setTimeout(() => {
        if (this.data.errorId === errorId) {
          console.warn(`Async error not received (${errorId}): ${this.errorMessage}`, errorData);
          this.reset();
        }
      }, 350);
      this.setData(nextData);
    }
  }

  /**
   * Retrieves the stored error data, if any, and resets the async so that it
   * can accept new requests. This should be used for error handling to notify
   * the user (if applicable) and set everything right again.
   *
   * If this is called when a request has not yet finished, it will result in a
   * warning and returning `null` as though the request were successful.
   */
  receiveError() {
    if (!this.data.isComplete) {
      // TODO: This warning may be unnecessary. There are valid use-cases where
      // code will ALWAYS attempt to receive an error after a call to `wrap`.
      console.warn('Called `receiveError` with no complete request');
      return null;
    }
    let errorData = null;
    if (this.data.isFailed) {
      errorData = {
        error: this.data.error,
        message: this.data.message,
      };
    }
    this.reset();
    return errorData;
  }

  /**
   * Clears out a blocking error. This can be called in situations where code
   * and has a try/catch around a `wrap` call. Since wrap will throw any
   * encountered errors, the caller already has all of the error information
   * from catch and does not need to receive it. This allows the caller to
   * simply pass the error that they caught so that it can be cleared out.
   */
  clearError(err) {
    if (err !== this.data.error) {
      console.warn('Attempted to clear the wrong error');
      return;
    }
    this.reset();
  }

  /**
   * Like `receiveError` except that the error itself is thrown. This can be
   * useful to trigger error handlers higher up in the stack.
   */
  throwError() {
    const errorData = this.receiveError();
    if (errorData && errorData.error) {
      throw errorData.error;
    } else if (errorData && errorData.message) {
      throw new Error(errorData.message);
    }
  }

  /**
   * A quiet form of `throwError` that first checks if the internal request is
   * failed before doing anything else. Because this can update state, it is
   * not safe to call in render. Instead, assuming a component has an internal
   * async state, consider something like:
   *
   * ```
   * componentDidUpdate(prevProps, prevState, snapshot) {
   *   super.componentDidUpdate(prevProps, prevState, snapshot);
   *   this._async.checkThrowError();
   * }
   * ```
   *
   * That will check for errors immediately after a failure occurs and will
   * raise the error up the component tree. This technically shows the
   * underlying UI for a frame before it is overridden by wahtever content the
   * error boundary dislays, but I think that's good enough for now
   * (the alternative would be that this function raises immediately then
   * asynchronously does a reset which might get a little weird).
   *
   * Remember, `receiveError` (which is called by this function) will only
   * return the error to the first one who requests it and goes back to a reset
   * state once the error has been received.
   */
  checkThrowError() {
    if (this.isFailed) {
      this.throwError();
    }
  }

  /**
   * Returns when the async state is no longer running.
   *
   * If the state is currently not running, returns immediately. Otherwise waits
   * until `isRunning` is no longer true. If there are race conditions, and
   * another task puts the async state back into `running` before this task
   * waits, it will loop and wait again. Due to the fact that Maps are ordered
   * based on insertion order, it should not be possible for a single task to
   * starve in this way.
   *
   * TODO: Fix bug with `timeoutMs` since it doesn't handle repeated iterations
   * properly.
   */
  async waitForStopped(timeoutMs) {
    while (this.data.isRunning) {
      await this.waitForCondition(data => !data.isRunning, {timeoutMs: timeoutMs});
    }
  }

  /**
   * Same as `waitForStopped` except waits for the async to complete. This can
   * be useful to prevent returning before the async has even started.
   */
  async waitForComplete(timeoutMs) {
    await this.waitForCondition(data => !data.isComplete, {timeoutMs: timeoutMs});
  }

  /**
   * Same as `waitForStopped` except waits for the async to complete
   * successfully. If there is an error (TODO: Raise an error or keep waiting?)
   */
  async waitForSuccess(timeoutMs) {
    await this.waitForCondition(data => !data.isSuccess, {timeoutMs: timeoutMs});
  }

  /**
   * Sets the async state as `running` then executes `fn`, returning the result.
   * It is assumed that `fn` should take a function argument,
   * `throwIfCanceled`, that can be called to check if the request was
   * canceled. This is used for multi-part requests to make sure that extra
   * work isn't performed if the request was canceled while waiting for an
   * async resource.
   */
  async wrap(fn) {
    throw new Error('Cannot call abstract method `wrap`');
  }

  /**
   * Cancels the running request (and any queued requests) if applicable.
   */
  cancel() {
    throw new Error('Cannot call abstract method `cancel`');
  }

}
