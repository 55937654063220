import React from 'react';

import {Combobox} from '@salesforce/design-system-react';


export default function SinglePicklist(props) {
  let filteredOptions = props.options;
  if (props.filter) {
    filteredOptions = props.options.filter((item, idx) => {
      return props.filter(props.filterValue, item, idx);
    });
  }
  let selection = [];
  if (props.value && props.options) {
    for (const option of props.options) {
      if (option.id === props.value) {
        selection = [option];
        break;
      }
    }
  }
  return (
    <Combobox
      labels={{
        label: props.label,
        placeholder: props.placeholder,
      }}
      events={{
        onSelect: (event, data) => {
          props.onSelect(event, {
            value: (data.selection.length) ? data.selection[0].id : '',
            item: (data.selection.length) ? data.selection[0] : null,
          });
        },
        onChange: (event, data) => {
          props.onChangeFilter(event, data);
        }
      }}
      type='text'
      selection={selection}
      value={props.filterValue}
      options={filteredOptions}
      variant={(props.filter) ? '' : 'readonly'}
    />
  );
}
