import Memoize from 'memoize-one';
import propTypes from 'prop-types';
import React from 'react';

import {Button} from '@salesforce/design-system-react';
import {ButtonGroup} from '@salesforce/design-system-react';
import {ButtonIcon} from '@salesforce/design-system-react';
import {ButtonStateful} from '@salesforce/design-system-react';

import AppGlobal from 'global';
import AppReactComponent from 'utils/app-react-component';
import EmbarkToolsState from 'apps/maps/state/embark-tools';
import Spacer from 'components/layouts/spacer';
import Tooltip from 'components/basic/tooltip';


function EmbarkToolbarMainToolSelectView({
  doRedo,
  doUndo,
  embarkState,
  handleChangeImpersonate,
  handleChangeShowGrid,
  permissions,
  redoState,
  tool,
  toolSelector,
  undoState,
}) {
  return (
    <React.Fragment>
      <ButtonGroup>
        <Tooltip content='Move around the map'>
          <ButtonStateful
            icon={<ButtonIcon category='utility' name='move'/>}
            assistiveText={{icon: 'Move around the map'}}
            active={tool.id === EmbarkToolsState.TOOL_MOVE}
            onClick={toolSelector.move}
            variant='icon-filled'
          />
        </Tooltip>
        <Tooltip content='Manage the tokens on the map'>
          <ButtonStateful
            icon={<ButtonIcon category='utility' name='target'/>}
            assistiveText={{icon: 'Manage the tokens on the map'}}
            active={tool.id === EmbarkToolsState.TOOL_TOKENS}
            onClick={toolSelector.tokens}
            variant='icon-filled'
          />
        </Tooltip>
        {/* if */(permissions.CAN_MODIFY_VISIBILITY) ? (
          <Tooltip content='Change what players can see'>
            <ButtonStateful
              icon={<ButtonIcon category='utility' name='preview'/>}
              assistiveText={{icon: 'Change was players can see'}}
              active={tool.id === EmbarkToolsState.TOOL_VIS_OVERRIDE}
              onClick={toolSelector.visibilityOverride}
              variant='icon-filled'
            />
          </Tooltip>
        )/* end if */ : (<React.Fragment/>)}
      </ButtonGroup>
      <Spacer />
      <ButtonGroup>
        {/* if */(permissions.CAN_IMPERSONATE_PLAYERS) ? (
          <Tooltip
            content={embarkState.isImpersonatingPlayer ? 'View as GM' : 'View as Player'}
          >
            <ButtonStateful
              icon={<ButtonIcon category='utility' name='advertising' />}
              assistiveText={{
                icon: embarkState.isImpersonatingPlayer ? 'View as GM' : 'View as Player'
              }}
              active={embarkState.isImpersonatingPlayer}
              onClick={handleChangeImpersonate}
              variant='icon-filled'
            />
          </Tooltip>
        )/* end if */ : null}
        <Tooltip content={embarkState.showGrid ? 'Hide Grid' : 'Show Grid'}>
          <ButtonStateful
            icon={<ButtonIcon category='utility' name='tile_card_list' />}
            assistiveText={{
              icon: embarkState.showGrid ? 'Hide Grid' : 'Show Grid'
            }}
            active={embarkState.showGrid}
            onClick={handleChangeShowGrid}
            variant='icon-filled'
          />
        </Tooltip>
      </ButtonGroup>
      <Spacer />
      <ButtonGroup>
        <Tooltip
          content={undoState && undoState.length ? undoState.steps[0].description : null}
        >
          <Button
            iconCategory='utility'
            iconName='undo'
            iconSize='medium'
            assistiveText={{icon: 'Undo'}}
            iconVariant='border-filled'
            variant='icon'
            onClick={doUndo}
            disabled={!undoState || !undoState.length}
          />
        </Tooltip>
        <Tooltip
          content={redoState && redoState.length ? redoState.steps[0].description : null}
        >
          <Button
            iconCategory='utility'
            iconName='redo'
            iconSize='medium'
            assistiveText={{icon: 'Redo'}}
            iconVariant='border-filled'
            variant='icon'
            onClick={doRedo}
            disabled={!redoState || !redoState.length}
          />
        </Tooltip>
      </ButtonGroup>
    </React.Fragment>
  );
}


export default class EmbarkToolbarMainToolSelect extends AppReactComponent {

  static propTypes = {
    permissions: propTypes.any,
    undoContext: propTypes.string,
  };

  constructor(props) {
    super(props);
    this._embarkToolsState = this.constantProp('embarkToolsState');
    this.connect('tool', this._embarkToolsState, 'tool');
    this.connect('embarkToolsState', this._embarkToolsState);
    this.connect('undo', AppGlobal.undo);
  }

  handleChangeImpersonate() {
    this._embarkToolsState.setIsImpersonatingPlayer(
      !this.state.embarkToolsState.isImpersonatingPlayer
    );
  }

  handleChangeShowGrid() {
    this._embarkToolsState.setShowGrid(!this.state.embarkToolsState.showGrid);
  }

  _doUndo() {
    AppGlobal.undo.handler.executeUndo(this.props.undoContext);
  }

  _doRedo() {
    AppGlobal.undo.handler.executeRedo(this.props.undoContext);
  }

  render() {
    const props = this.props;
    const state = this.state;
    return EmbarkToolbarMainToolSelectView({
      doRedo: this.$b._doRedo,
      doUndo: this.$b._doUndo,
      embarkState: state.embarkToolsState,
      handleChangeImpersonate: this.$b.handleChangeImpersonate,
      handleChangeShowGrid: this.$b.handleChangeShowGrid,
      permissions: props.permissions,
      redoState: state.undo.redo[props.undoContext],
      tool: state.tool,
      toolSelector: this._getToolSelector(this._embarkToolsState.$b.setTool),
      undoState: state.undo.undo[props.undoContext],
    });
  }

  _getToolSelector = Memoize((setTool) => {
    return Object.freeze({
      move: () => {
        setTool(EmbarkToolsState.TOOL_MOVE);
      },
      tokens: () => {
        setTool(EmbarkToolsState.TOOL_TOKENS);
      },
      visibilityOverride: () => {
        setTool(EmbarkToolsState.TOOL_VIS_OVERRIDE);
      },
    });
  });

}
