import Squares from 'utils/squares';


// TODO: Make sure that the way we deal with the configuration stuff is
// ACTUALLY helpful. I'll admit, this is probably some pretty serious micro-
// optimizations, but I do wonder if there's something good about having
// constant values so that there's no need for null checks. In theory, since
// these things basically only reference constant closure variables, it could
// be possible to inline all of the configuration values. If these functions
// are being called hundreds or thousands of times, that may result in big
// CPU savings.

// SOME GENERAL NOTES:
//
// We don't want to truncate pixel values here even though pixels should be
// whole numbers (to prevent blurry stuff). Pixel values should ONLY be
// truncated immediately before rendering to save effort in truncating a value
// over-and-over as it goes between different places.
//
// The functions provided must exist for all grid implementations.
//
// - getGridConfiguration: Returns an object that conforms to the proto
//   GridConfiguration shape.
// - withConfiguration: Sends updated configuration parameters to the grid
//   (requires that you know what parameters a given grid uses). Returns a
//   new grid of the same type applying the specified configuration values.
// - getCellSquare: Return a square that contains the entire cell.
// - gridToPixelX, gridToPixelY: Converts a grid column or row (inluding
//   optional sub-grid locations) to the corresponding X or Y coordinate in
//   pixel space.
// - gridToPixelWidth, gridToPixelHeight: Converts a width or height value in
//   grid space to the corresponding width or height in pixel space.
// - pixelToGridCol, pixelToGridRow: Converts an X or Y coordinate into the
//   precise column or row value (including a fractional component). This
//   requires both x and y values to account for hex grids where columns and
//   row values depend on both coordinates.
// - pixelToContainingCol, pixelToContainingRow: Gets the column or row which
//   contains the provided pixel value. This requires both x and y values to
//   account for hex grids where columns and row values depend on both
//   coordinates.
// - pixelToGridLoc: Returns an object that maps to a proto GridLocation
//   object and can be sent to the server.
// - pixelToGridWidth, pixelToGridHeight: Converts width or height values from
//   pixel space into grid space.
//
// Right now grids are immutable. The intention is that grid objects are created
// with relative infrequency while they are going to be accessed hundreds of
// times per frame. The way they are laid out provides the browser an option to
// inline the configuration and make for some very efficient funtion calls.




export default class Grids {

  static fromGridConfiguration = (gridConfiguration) => {
    if (gridConfiguration.rectangleGrid) {
      return Grids.makeRectangleGrid(gridConfiguration.rectangleGrid);
    } else {
      throw new Error('Unable to generate grid');
    }
  }

  static makeRectangleGrid = (rectangleGridConfig) => {
    if (!rectangleGridConfig) {
      rectangleGridConfig = {};
    }
    const originX = rectangleGridConfig.originX || 0;
    const originY = rectangleGridConfig.originY || 0;
    const anchorX = rectangleGridConfig.anchorX || 0;
    const anchorY = rectangleGridConfig.anchorY || 0;
    const cellWidth = rectangleGridConfig.cellWidth || 50.0;
    const cellHeight = rectangleGridConfig.cellHeight || 50.0;
    const dpi = rectangleGridConfig.dpi || 50.0;

    return Object.freeze({

      getGridConfiguration: (options) => {
        options = options || {};
        const normalizeOrigin = options.normalizeOrigin || false;
        let outOriginX = originX;
        let outOriginY = originY;
        if (normalizeOrigin) {
          outOriginX = 0 - (cellWidth - (anchorX % cellWidth));
          outOriginY = 0 - (cellHeight - (anchorY % cellHeight));
        }
        // geometryPb.GridConfiguration
        return {
          rectangleGrid: {
            originX: outOriginX,
            originY: outOriginY,
            anchorX: anchorX,
            anchorY: anchorY,
            cellWidth: cellWidth,
            cellHeight: cellHeight,
            dpi: dpi,
          }
        };
      },

      withConfiguration: (configValues) => {
        const nextConfig = {
          originX: originX,
          originY: originY,
          anchorX: anchorX,
          anchorY: anchorY,
          cellWidth: cellWidth,
          cellHeight: cellHeight,
          dpi: dpi,
          ...configValues
        };
        return Grids.makeRectangleGrid(nextConfig);
      },

      getCellSquare: (col, row) => {
        return Squares.fromDimensions(
          originX + (cellWidth * col),
          originY + (cellHeight * row),
          cellWidth,
          cellHeight,
        );
      },

      gridToPixelX: (col, subCol) => {
        const exactCol = (subCol) ? col + subCol : col;
        return originX + (cellWidth * exactCol);
      },

      gridToPixelY: (row, subRow) => {
        const exactRow = (subRow) ? row + subRow : row;
        return originY + (cellHeight * exactRow);
      },

      gridExactToPixelX: (exactCol) => {
        return originX + (cellWidth * exactCol);
      },

      gridExactToPixelY: (exactRow) => {
        return originY + (cellHeight * exactRow);
      },

      gridToPixelWidth: (width) => {
        return width * cellWidth;
      },

      gridToPixelHeight: (height) => {
        return height * cellHeight;
      },

      pixelToGridCol: (x, y) => {
        if (x === undefined || y === undefined) {
          throw new Error('Both x and y coordinates must be specified');
        }
        return (x - originX) / cellWidth;
      },

      pixelToGridRow: (x, y) => {
        if (x === undefined || y === undefined) {
          throw new Error('Both x and y coordinates must be specified');
        }
        return (y - originY) / cellHeight;
      },

      pixelToContainingCol: (x, y) => {
        if (x === undefined || y === undefined) {
          throw new Error('Both x and y coordinates must be specified');
        }
        return Math.floor((x - originX) / cellWidth);
      },

      pixelToContainingRow: (x, y) => {
        if (x === undefined || y === undefined) {
          throw new Error('Both x and y coordinates must be specified');
        }
        return Math.floor((y - originY) / cellHeight);
      },

      pixelToGridLoc: (x, y) => {
        // This _must_ use `floor` instead of `trunc` so that negative rows
        // and columns work correctly (otherwise 0,0 would be a 2x2 square)
        const exactCol = (x - originX) / cellWidth;
        const exactRow = (y - originY) / cellHeight;
        const wholeRow = Math.floor(exactRow);
        const wholeCol = Math.floor(exactCol);
        // geometryPb.GridLocation
        return {
          row: wholeRow,
          col: wholeCol,
          sub: {
            row: exactRow - wholeRow,
            col: exactCol - wholeCol,
          },
        };
      },

      pixelToGridWidth: (width) => {
        return width / cellWidth;
      },

      pixelToGridHeight: (height) => {
        return height / cellHeight;
      },

    });
  }

}
