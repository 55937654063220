import CanvasUtils from 'utils/graphics/canvas-utils';
import MapObject from 'components/mapview/map-object';
import Squares from 'utils/squares';


export default class InterfaceMiniGrid extends MapObject{

  constructor(grid, initialColor) {
    super();
    this.grid = grid;
    this.color = initialColor || 'rgba(0, 0, 0, 0.5)';
    this.lineWidth = 1;
    this.nCells = 5;
    this._period = 3000.0;
    this._cur = 0
    this._lastBoundSq = null;
  }

  onUpdate(timestamp, timeDeltaMs, frameNo, render, layer) {
    this._cur = (this._cur + timeDeltaMs) % this._period;
    const brightness = (1.0 + Math.sin(Math.PI * this._cur / this._period)) / 2.0;
    const rgbValue = Math.floor(brightness * 255);
    this.color = `rgba(${rgbValue}, ${rgbValue}, ${rgbValue}, 0.9)`;
    // Handle redraw regions
    const grid = this.grid;
    if (!grid) {
      return;
    }
    const gridSq = Squares.fromDimensions(
      grid.gridToPixelX(0),
      grid.gridToPixelY(0),
      grid.gridToPixelWidth(1),
      grid.gridToPixelHeight(1),
    );
    const boundSq = layer.getRenderSquare(Squares.fromDimensions(
      gridSq.x - (this.nCells * gridSq.w),
      gridSq.y - (this.nCells * gridSq.h),
      2 * this.nCells * gridSq.w,
      2 * this.nCells * gridSq.h
    ));
    const buffer = 4 * Math.max(1, this.lineWidth * render.pixelRatio);
    if (this._lastBoundSq) {
      layer.setRedraw(Squares.growCentered(this._lastBoundSq, buffer));
    }
    layer.setRedraw(Squares.growCentered(boundSq, buffer));
    this._lastBoundSq = boundSq;
  }

  onRender(context, layer, render) {
    const grid = this.grid;
    if (!grid) {
      return;
    }
    const getRenderX = layer.getRenderX.bind(layer);
    const getRenderY = layer.getRenderY.bind(layer);
    // Gather all of the information needed to render the grid
    const lineWidthPixels = Math.max(1, this.lineWidth * render.pixelRatio);
    const gridSq = Squares.fromDimensions(
      grid.gridToPixelX(0),
      grid.gridToPixelY(0),
      grid.gridToPixelWidth(1),
      grid.gridToPixelHeight(1),
    );
    const boundSq = Squares.fromDimensions(
      gridSq.x - (this.nCells * gridSq.w),
      gridSq.y - (this.nCells * gridSq.h),
      2 * this.nCells * gridSq.w,
      2 * this.nCells * gridSq.h
    );
    // Prepare the context
    CanvasUtils.resetTransform(context);
    if (lineWidthPixels % 2 === 1) {
      CanvasUtils.translateHalfPixel(context);
    }
    context.lineWidth = lineWidthPixels;
    context.strokeStyle = this.color;
    // Draw all of the grid lines
    context.beginPath();
    for (let col = -1 * this.nCells; col <= this.nCells; col++) {
      context.moveTo(
        getRenderX(gridSq.x + (col * gridSq.w)),
        getRenderY(boundSq.y),
      );
      context.lineTo(
        getRenderX(gridSq.x + (col * gridSq.w)),
        getRenderY(boundSq.y2),
      );
    }
    for (let row = -1 * this.nCells; row <= this.nCells; row++) {
      context.moveTo(
        getRenderX(boundSq.x),
        getRenderY(gridSq.y + (row * gridSq.h)),
      );
      context.lineTo(
        getRenderX(boundSq.x2),
        getRenderY(gridSq.y + (row * gridSq.h)),
      );
    }
    context.lineWidth = lineWidthPixels + (2 * render.pixelRatio);
    context.strokeStyle = 'rgba(255, 255, 255, 0.3)';
    context.stroke();
    context.lineWidth = lineWidthPixels;
    context.strokeStyle = 'rgba(30, 30, 30, 1)';
    context.stroke();
  }

}
