import React from 'react';
import {withRouter} from 'react-router';

import AppPageTemplateConnected from 'components/templates/app-page-template-connected';
import CenteredBox from 'components/layouts/centered-box';

import NewMapZoneForm from './components/new-map-zone-form';
import SharedMapDetailsLoader from './components/shared-map-details-loader';

import shared from 'global';


export function NewMapZonePage(props) {
  return (
    <AppPageTemplateConnected
      headerVisible={true}
      footerVisible={false}
    >
      <SharedMapDetailsLoader>
        <CenteredBox className='border-box' style={{width: '400px'}}>
          <h3 className='slds-text-heading_medium'>Create a new Map Zone</h3>
          <NewMapZoneForm
            afterSubmit={(mapZone) => {
              shared.mapDetails.refresh();
              props.history.replace(`/app/maps/${mapZone.mapId}/details`);
            }}
            afterCancel={() => {
              props.history.goBack();
            }}
          />
        </CenteredBox>
      </SharedMapDetailsLoader>
    </AppPageTemplateConnected>
  );
}

export default withRouter(NewMapZonePage);
