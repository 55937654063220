import React from 'react';
import {withRouter} from 'react-router';

import AppPageTemplateConnected from 'components/templates/app-page-template-connected';
import MapDetails from './components/map-details';
import SharedMapDetailsLoader from './components/shared-map-details-loader';

import DialogBox from 'components/layouts/dialog-box';


function MapDetailsPage(props) {
  return (
    <AppPageTemplateConnected>
      <SharedMapDetailsLoader>
        <MapDetails shared={true} history={props.history} />
      </SharedMapDetailsLoader>
    </AppPageTemplateConnected>
  );
}


export default withRouter(MapDetailsPage);
