import classNames from 'classnames';
import Memoize from 'memoize-one';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from '@salesforce/design-system-react';
import {Checkbox} from '@salesforce/design-system-react';
import {Input} from '@salesforce/design-system-react';

import QueuedAsyncState from 'statelib/queued-async-state';
import AppReactComponent from 'utils/app-react-component';
import FlexBarLayout from 'components/layouts/flex-bar-layout';
import FormHandler from 'formlib/form-handler';
import layouts from 'components/layouts';
import formcmp from 'components/form';
import {uploadFiles} from 'utils/async';

import ImageManagerCategorySelect from './image-manager-category-select';


/**
 * docstring
 */
export default class ImageManagerUploader extends AppReactComponent {

  static propTypes = {
    className: PropTypes.string,
    defaultCategories: PropTypes.arrayOf(PropTypes.string),
    ownerId: PropTypes.string.isRequired,
    ownerType: PropTypes.string.isRequired,
    async: PropTypes.object,
    afterSubmit: PropTypes.func,
    onCancel: PropTypes.func,
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props);
    this.state = {uploadMore: false};
    this._async = this.constantProp('async', new QueuedAsyncState());
    this._formHandler = new FormHandler(this.$b._dataToForm, 'ImageUploadForm');
    this._formHandler.onSubmit(this.$b._handleSubmit);
    this._resetData();
    this._resetFileInput = null;
    this.connect('async', this._async);
    this._formHandler.connectFieldsToComponentState(this, 'fields');
    this.addCleanup(() => this._formHandler.close());
  }

  _handleOnControllerFileInput(controller) {
    this._resetFileInput = controller.reset;
  }

  _resetData() {
    this._formHandler.initializeData({
      imageFiles: null,
      name: null,
      category: (this.props.defaultCategories) ? this.props.defaultCategories[0] : null,
    })
  }

  _dataToForm(data) {
    return {
      imageFiles: data.imageFiles || null,
      name: data.name || '',
      category: data.category || null,
    };
  }

  async _handleSubmit(data, form) {
    let idField;
    if (this.props.ownerType === 'user') {
      idField = 'user_id';
    } else if (this.props.ownerType === 'campaign') {
      idField = 'campaign_id';
    } else if (this.props.ownerType === 'map') {
      idField = 'map_id';
    } else {
      throw new Error('Invalid image owner type');
    }
    // Validation
    if (!data.imageFiles || data.imageFiles.length < 1) {
      form.setFormFieldError('imageFiles', 'Please select an image');
    }
    form.validateFieldDataTruthy('name');
    form.validateFieldDataTruthy('category');
    if (form.hasErrors) {
      return;
    }
    // Submission
    let newImage;
    try {
      newImage = await this._async.wrap(async (throwIfCanceled) => {
        console.info('Uploading files', data.imageFiles);
        const xhr = await uploadFiles(
          '/web/images/v1/upload_image',
          data.imageFiles,
          {
            [idField]: this.props.ownerId,
            name: data.name,
            category: data.category,
          },
        );
        const imageId = xhr.responseText;
        this._resetData();
        this._resetFileInput();  // Raw element for the time being
        throwIfCanceled();
        // TODO: Somehow return the new image data from the XHR response
        // That will allow us to more easily select the image that was just
        // uploaded
        return {id: imageId};
      });
    } catch (err) {
      console.error('Failed to upload image', err);
      form.setFormFieldError('$all', `${err}`);
    }
    if (this.state.uploadMore) {
      if (this.props.afterSubmit) {
        this.props.afterSubmit({image: newImage, select: false});
      }
    } else {
      if (this.props.afterSubmit) {
        this.props.afterSubmit({image: newImage, select: true});
      }
    }
  }

  _getFieldChangeHandlers = Memoize(() => ({
    imageFile: (event, files) => {
      if (files && files.length) {
        this._formHandler.setFormDataField('imageFiles', files);
        if (!this._formHandler.data.name) {
          let newFileName = files[0].name.replace(/\.[^/.]+$/, "");
          this._formHandler.setFormDataField('name', newFileName);
        }
      } else {
        this._formHandler.setFormDataField('imageFiles', null);
      }
    },
    category: (event, value) => {
      this._formHandler.setFormFieldAndData('category', value);
    },
    name: (event, data) => {
      this._formHandler.setFormFieldAndData('name', data.value);
    },
    uploadMore: (event, data) => {
      this.setState({uploadMore: data.checked});
    },
  }));

  _handleCancel(event) {
    if (this.props.onCancel) {
      this.props.onCancel(event);
    }
  }

  render() {
    const props = this.props;
    const state = this.state;
    const changeHandlers = this._getFieldChangeHandlers();
    return (
      <form
        className={props.className}
        onSubmit={this._formHandler.$b.handleFormSubmitEvent}
      >
        <layouts.TwoPanelLayout
          classNamePanel={classNames(
            'flex--container-vertical',
            'layout--padding-all-xsmall',
          )}
        >
          <div className='layout--positioned-box'>
            <formcmp.FileSelectRegion
              name='imageFile'
              accept='image/png, image/jpeg'
              onChange={changeHandlers.imageFile}
              onController={this.$b._handleOnControllerFileInput}
            />
          </div>
          <div className='layout--positioned-box flex--container-vertical'>
            <div
              className={classNames(
                'flex--container-vertical',
                'flex--item-fill',
              )}
            >
              <Input
                name='name'
                label='Image Name'
                value={state.fields.name.value}
                onChange={changeHandlers.name}
                required={true}
                disabled={!state.fields.imageFiles.value}
              />
              <ImageManagerCategorySelect
                label='Category'
                value={state.fields.category.value}
                onChange={changeHandlers.category}
                required={true}
                disabled={!state.fields.imageFiles.value}
              />
            </div>
            <layouts.TwoButtonPanel className='flex--item-fixed layout--margin-top-small'>
              <div className='flex--item-fixed flex--container-horizontal-center'>
                <Checkbox
                  assistiveText={{label: 'Upload More'}}
                  id="checkbox-example"
                  labels={{label: 'Upload More'}}
                  onChange={changeHandlers.uploadMore}
                  checked={state.uploadMore}
                />
                <Button
                  label='Upload'
                  variant='brand'
                  type='submit'
                  disabled={this.state.async.isRunning || !state.fields.imageFiles.value}
                />
              </div>
              <Button
                label='Cancel'
                variant='neutral'
                type='button'
                onClick={this.$b._handleCancel}
                disabled={this.state.async.isRunning}
              />
            </layouts.TwoButtonPanel>
          </div>
        </layouts.TwoPanelLayout>
      </form>
    );
  }

}
