import React from 'react';

//import Button from 'components/basic/button';
import {Button} from '@salesforce/design-system-react';
import ToolButton from 'components/basic/tool-button';
import layouts from 'components/layouts';

import MapViewInteractive from 'components/mapview/map-view-interactive';
import MapZoneConfigNav from 'apps/maps/components/map-zone-config-nav';

import canvasengine from 'canvasengine';
import ConntectedMapViewRef from 'apps/maps/state/connected-map-view-ref';
import ReactComponent from 'utils/react-component';
import Shapes from 'utils/geometry/shapes';
import shared from 'global';
import Squares from 'utils/squares';

import BaseMask from 'components/mapview/map-objects/base-mask';
import InterfaceGrid from 'components/mapview/map-objects/interface-grid';
import InterfaceGridAreaHighlight from 'components/mapview/map-objects/interface-grid-area-highlight';
import InterfaceGridCellHighlight from 'components/mapview/map-objects/interface-grid-cell-highlight';

const TOOL_MOVE = 'TOOL_MOVE';
const TOOL_PAINT = 'TOOL_PAINT';
const TOOL_SQUARE = 'TOOL_SQUARE';
const COLOR_INACCESSIBLE = 'COLOR_INACCESSIBLE';
const COLOR_ACCESSIBLE = 'COLOR_ACCESSIBLE';
const DEFAULT_COLOR = COLOR_ACCESSIBLE;


function MapZoneConfigLayerBoundsView({
  color,
  handleCancel,
  handleSave,
  mapId,
  mapZoneId,
  onControllerReady,
  onDragBegin,
  onDragEnd,
  onDragMove,
  setColor,
  setTool,
  tool,
}) {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      width: '100%',
      position: 'fixed',  // No idea, but it fixes overscan compatibility
    }}>
      <MapViewInteractive
        style={{ width: '100%', height: '100%', flex: 'auto' }}
        controllerReady={onControllerReady}
        preventDragMovement={tool !== TOOL_MOVE}
        onDragBegin={onDragBegin}
        onDragMove={onDragMove}
        onDragEnd={onDragEnd}
      />
      <div style={{ width: '230px', height: '100%', flex: '0 1 auto'}}>
        <MapZoneConfigNav
          selectImageState='Done'
          selectGridState='Done'
          selectBoundariesState='Ready'
          currentStep='SelectBoundaries'
          mapId={mapId}
          mapZoneId={mapZoneId}
        />
        <div>
          <ToolButton
            label='Move'
            icon='move'
            onToggle={() => setTool(TOOL_MOVE)}
            selected={tool === TOOL_MOVE}
          />
          <ToolButton
            label='Draw'
            icon='paint'
            onToggle={() => setTool(TOOL_PAINT)}
            selected={tool === TOOL_PAINT}
          />
          <ToolButton
            label='Draw Square'
            icon='square'
            onToggle={() => setTool(TOOL_SQUARE)}
            selected={tool === TOOL_SQUARE}
          />
        </div>
        <div>
          <ToolButton
            label='Paint Inacessible'
            icon='hidden'
            onToggle={() => setColor(COLOR_INACCESSIBLE)}
            selected={color === COLOR_INACCESSIBLE}
          />
          <ToolButton
            label='Paint Accessible'
            icon='visible'
            onToggle={() => setColor(COLOR_ACCESSIBLE)}
            selected={color === COLOR_ACCESSIBLE}
          />
        </div>
        <layouts.TwoButtonPanel>
          <Button onClick={handleSave}>Save and Finish</Button>
          <Button onClick={handleCancel}>Back to Map</Button>
        </layouts.TwoButtonPanel>
      </div>
    </div>
  );
}


export default class MapZoneConfigLayerBounds extends ReactComponent {

  constructor() {
    super();
    this._mapContainer = shared.mapDetails;
    this._mapZoneRef = shared.mapZoneDetails;
    this._mapViewContainer = new ConntectedMapViewRef({
      mapContainer: this._mapContainer,
      mapZoneContainer: this._mapZoneRef,
    });
    this._grid = null;
    this._startSelectionX = null;
    this._startSelectionY = null;
    this._curSquareSelection = null;
    this.state = {
      tool: TOOL_MOVE,
      color: DEFAULT_COLOR,
      selecting: false,
    };
    this.gridMapObject = new InterfaceGrid(null, '#0009');
    this.cellHighlight = new InterfaceGridCellHighlight();
    this.areaHighlight = new InterfaceGridAreaHighlight();
    this.boundsMask = new BaseMask();
    this.addCleanup(
      this._mapViewContainer.$b.close,
    );
    this.addClosableSetup(
      () => this._mapViewContainer.subscribe(
        this.$b.initializeReadyMapViewController,
        {condition: data => data.async.isSuccess, immediate: true},
      ),
    );
  }

  getMapViewController() {
    return this._mapViewContainer.controller;
  }

  handleMapViewControllerReady(controller) {
    this._mapViewContainer.controller = controller;
  }

  refreshGrid() {
    this._grid = this._mapViewContainer.grid;
    this.gridMapObject.grid = this._grid;
    this.cellHighlight.grid = this._grid;
    this.areaHighlight.grid = this._grid;
    this.boundsMask.setGrid(this._grid);
  }

  refreshBoundsMask() {
    const mapZone = this._mapZoneRef.mapZone;
    const controller = this.getMapViewController();
    const imgSq = controller.getImageSquare();
    if (imgSq.w > 0 && imgSq.h > 0) {
      if (mapZone.boundaries) {
        this.boundsMask.setMaskConfiguration(mapZone.boundaries.mask);
      } else {
        // Initializes a new mask. This should be done by the server
        console.warn('New boundaries mask initialized - this should be done by the server');
        const col1 = this._grid.pixelToContainingCol(imgSq.x, imgSq.y);
        const col2 = this._grid.pixelToContainingCol(imgSq.x2, imgSq.y2);
        const row1 = this._grid.pixelToContainingRow(imgSq.x, imgSq.y);
        const row2 = this._grid.pixelToContainingRow(imgSq.x2, imgSq.y2);
        const maskWidth = 1 + col2 - col1;
        const maskHeight = 1 + row2 - row1;
        const maskShape = new Shapes.Mask(
          this._grid, col1, row1, maskWidth, maskHeight, 6, 'bitImageData', null
        );
        maskShape.initCanvas(document.createElement('canvas'));
        this.boundsMask.setMask(maskShape);
      }
    }
  }

  handleDragBegin(event, target) {
    if (this.state.tool === TOOL_PAINT) {
      this.handlePaintEvent(event, target);
    } else if (this.state.tool === TOOL_SQUARE) {
      this.handleSquareBegin(event, target);
    }
  }

  handleDragMove(event, target) {
    if (this.state.tool === TOOL_PAINT) {
      this.handlePaintEvent(event, target);
    } else if (this.state.tool === TOOL_SQUARE) {
      this.handleSquareMove(event, target);
    }
  }

  handleDragEnd(event, target) {
    if (this.state.tool === TOOL_PAINT) {
      this.handlePaintEvent(event, target);
    } else if (this.state.tool === TOOL_SQUARE) {
      this.handleSquareEnd(event, target);
    }
  }

  handlePaintEvent(event, target) {
    this.paintCell(
      this._grid.pixelToContainingCol(target.x, target.y),
      this._grid.pixelToContainingRow(target.x, target.y),
      this.state.color,
    );
  }

  handleSquareBegin(eventt, target) {
    this.setState({selecting: true});
    this._startSelectionX = target.x;
    this._startSelectionY = target.y;
    this._curSquareSelection = Squares.fromDimensions(target.x, target.y, 0, 0);
    this.areaHighlight.setStartingLocation(target.x, target.y);
  }

  handleSquareMove(eventt, target) {
    this._curSquareSelection = Squares.fromCoordinates(
      this._startSelectionX,
      this._startSelectionY,
      target.x,
      target.y,
    );
  }

  handleSquareEnd(event, target) {
    this.setState({selecting: false});
    let selSq = Squares.fromCoordinates(
      this._startSelectionX,
      this._startSelectionY,
      target.x,
      target.y,
    );
    this._startSelectionX = null;
    this._startSelectionY = null;
    this._curSquareSelection = null;
    this.areaHighlight.unsetStartingLocation();
    const minCol = this._grid.pixelToContainingCol(selSq.x, selSq.y);
    const maxCol = this._grid.pixelToContainingCol(selSq.x2, selSq.y2);
    const minRow = this._grid.pixelToContainingRow(selSq.x, selSq.y);
    const maxRow = this._grid.pixelToContainingRow(selSq.x2, selSq.y2);
    const topLeftCell = Shapes.GridCell.instance(this._grid, minCol, minRow);
    const bottomRightCell = Shapes.GridCell.instance(this._grid, maxCol, maxRow);
    const areaSq = Squares.getContainingSquare(
      topLeftCell.containingSquare, bottomRightCell.containingSquare
    );
    this._paintArea(areaSq, this.state.color);
  }

  paintCell(col, row, color) {
    const gridCell = Shapes.GridCell.instance(this._grid, col, row);
    const paintedCellSq = gridCell.containingSquare;
    this._paintArea(paintedCellSq, color);
  }

  _paintArea(areaSq, color) {
    const controller = this.getMapViewController();
    const mask = this.boundsMask.getMaskShape();
    // Only touch cells within the map image
    if (Squares.hasIntersection(areaSq, controller.getImageSquare())) {
      const maskSq = mask.pixelSqToMaskSq(areaSq);
      mask.withMaskContext((ctx) => {
        if (this.state.color === DEFAULT_COLOR) {
          ctx.clearRect(maskSq.x, maskSq.y, maskSq.w, maskSq.h);
        } else {
          ctx.fillStyle = 'rgba(0, 0, 0, 1)';
          ctx.fillRect(maskSq.x, maskSq.y, maskSq.w, maskSq.h);
        }
      }, maskSq);
    }

  }

  setTool(value) {
    if (value !== this.state.tool) {
      if (this.state.tool === TOOL_SQUARE) {
        this._curSquareSelection = null;
      }
      this.setState({tool: value});
      this.getMapViewController().fullMapRerender();
    }
  }

  setColor(value) {
    this.setState({color: value});
  }

  handleCancel(event) {
    this.props.onCancel();
  }

  handleSave() {
    this.props.onSave({
      mask: this.boundsMask.getMaskShape().toProto(),
    });
  }

  render() {
    const state = this.state;
    this.cellHighlight.visible = !!(state.tool === TOOL_PAINT || (
      state.tool === TOOL_SQUARE && !state.selecting
    ));
    this.areaHighlight.visible = !!(state.tool === TOOL_SQUARE && state.selecting)
    return MapZoneConfigLayerBoundsView({
      color: state.color,
      handleCancel: this.$b.handleCancel,
      handleSave: this.$b.handleSave,
      mapId: this._mapContainer.mapId,
      mapZoneId: this._mapZoneRef.mapZoneId,
      onControllerReady: this.$b.handleMapViewControllerReady,
      onDragBegin: this.$b.handleDragBegin,
      onDragEnd: this.$b.handleDragEnd,
      onDragMove: this.$b.handleDragMove,
      setColor: this.$b.setColor,
      setTool: this.$b.setTool,
      tool: state.tool,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // MAP RENDERING PIPELINE
  /////////////////////////////////////////////////////////////////////////////

  initializeReadyMapViewController(eventObj) {
    this.refreshGrid();
    this.refreshBoundsMask();
    const controller = this._mapViewContainer.controller;
    if (controller) {
      canvasengine.LegacyMapObject.legacyAttach(this.gridMapObject, controller, 'interface');
      canvasengine.LegacyMapObject.legacyAttach(this.cellHighlight, controller, 'interface');
      canvasengine.LegacyMapObject.legacyAttach(this.areaHighlight, controller, 'interface');
      canvasengine.LegacyMapObject.legacyAttach(this.boundsMask, controller, 'fog');
    }
  }

}
