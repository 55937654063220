import Memoize from 'memoize-one';
import lodashUniqueId from 'lodash.uniqueid';

import FStateWithAsync from './fstate-with-async';
import RecordStoreRef from './record-store-ref';

type KeyType = string | number;

const NEW_RECORD_KEY_PREFIX = 'NEW$$';

/**
 * An un-ordered library of record states.
 *
 * This is primarily meant to be a base class for lazy-loaded databases of
 * records. For example, this allows local storage of multiple Map Objects
 * without needing to load them all at once.
 *
 * `loader` is optional and allows a developer to instantiate a RecordStoreState
 * without needing to create a sub-class and overwrite the `load` method. It
 * must take two parameters, `throwIfCanceled` and `recordKeys` which is an
 * array of key values that can be used to load records. Note that if a record
 * already exists in the store, it should be refreshed on load.
 *
 * Typically this will be sub-classed for a specific type of resource and can
 * include special functionality such as that which could save/store changes
 * to the given resource.
 */
export default class RecordStoreState<RecordType> extends FStateWithAsync {

  static DEFAULT_NAME = 'RecordStore';

  /**
   * A special key for a "new" record. This will behave roughly the same as a
   * null key but will sometimes be passed by refs to indicate that they are
   * working with a recor dnot yet in the store.
   */
  static NEW_RECORD_KEY_PREFIX = NEW_RECORD_KEY_PREFIX;

  _keyField: string;
  _load?: (throwIfCanceled: any, keys: KeyType[]) => any;

  constructor(options) {
    super(options);
    this._keyField = options.keyField || 'id';
    this._load = options.loader;
    // this._relatedStateListener = new FStateListener(this.$b.pathUpdatedRecord);
  }

  destroy() {
    this._load = null;
    super.destroy();
  }

  get keyField() {
    return this._keyField;
  }

  makeInitialData() {
    return {
      loadedKeys: [],
      recordsByKey: {},
    };
  }

  /**
   * Generates a "new" key - a key that represents a new record that has not
   * been committed to the remote service yet. This allows the key to exist
   * locally before being saved.
   *
   * New keys will always be strings even if the key for an object is typically
   * a number.
   */
  makeNewKey(): string {
    return lodashUniqueId(NEW_RECORD_KEY_PREFIX);
  }

  /**
   * Checks if the specified `key` is a "new" key or not. A "new" key is one
   * that only exists locally and has not been committed to the remote server
   * yet. It is mostly used as a placeholder while the user creates a new or a
   * copied record.
   */
  isNewKey(key: KeyType): boolean {
    if (!key) {
      return true;
    } else if (typeof(key) === 'number') {
      return false;
    }
    return key.startsWith(NEW_RECORD_KEY_PREFIX);
  }

  /**
   * Creates a new record with a unique, "new" key value
   */
  makeNewRecord() {
    return {
      [this._keyField]: this.makeNewKey(),
    };
  }

  /**
   * Retrieves the set of loaded keys. This is memoized and only changes when
   * the list of internal loaded keys changes.
   */
  getLoadedKeySet(): Set<KeyType> {
    const keys: KeyType[] = this.data.loadedKeys;
    return this._makeLoadedKeysSet(keys);
  }

  // Memoized set constructor for `getLoadedKeySet`
  _makeLoadedKeysSet = Memoize(
    (loadedKeys: KeyType[]): Set<KeyType> => new Set(loadedKeys)
  );

  isKeyLoaded(key: KeyType | null): boolean {
    if (!key) {
      return false;
    }
    return this.getLoadedKeySet().has(key);
  }

  /**
   * Gets an array of `keys` without any that have already been loaded.
   */
  getUnloadedKeys(keys: KeyType[]): KeyType[] {
    const unloadedKeys = new Set(keys);
    for (const key of keys) {
      if (this.isKeyLoaded(key)) {
        unloadedKeys.delete(key);
      }
    }
    return [...unloadedKeys];
  }

  /**
   * Returns a record reference to this record store.
   *
   * This method can be overwritten by child classes to return a specific type
   * of RecordStoreRef
   */
  getRecordRef(options?, key?: KeyType | null): RecordStoreRef<RecordType> {
    const recordRef = new RecordStoreRef(this, options);
    if (key) {
      recordRef.key = key;
    }
    return recordRef;
  }

  /**
   * Gets a record immediately if it has been loaded. Typically this isn't
   * desirable since the record simply may not be loaded yet.
   */
  getRecordSync(key: KeyType | null, quiet?: boolean): RecordType | null {
    const record = this.data.recordsByKey[key];
    if (!record && !quiet && this.isKeyLoaded(key)) {
      if (this.isKeyLoaded(key)) {
        throw new Error(`Record ${key} not found`);
      } else {
        throw new Error(`Record ${key} not yet loaded`);
      }
    }
    return record || null;
  }

  /**
   * Gets a single record by key, waiting for it to be loaded if it has not
   * been loaded yet.
   */
  async getRecord(key: KeyType, quiet?: boolean): Promise<RecordType | null> {
    if (!this.isKeyLoaded(key)) {
      await this._async.waitForStopped();
      // Waiting for outstanding async may have given the record a chance to load.
      if (!this.isKeyLoaded(key)) {
        await this.load([key]);
      }
    }
    return this.getRecordSync(key, quiet);
  }

  /**
   * Loads/refreshes records with the specified `keys` in the store.
   *
   *
   */
  async load(keys: KeyType[]): Promise<void> {
    if (!this._load) {
      throw new Error(
        'List container does not have a load function. It needs to be passed ' +
        'either as the `loader` option or override the `load` function.'
      );
    }
    try {
      await this._async.wrap(async (throwIfCanceled) => {
        const nextRecords = await this._load(throwIfCanceled, keys);
        throwIfCanceled();
        this.setRecords(nextRecords);
      });
    } catch (err) {
      console.warn('TODO: Check for async base canceled error');
      console.error(`${this.name}: Unable to load records (${keys})`, err);
      throw err;
    }
  }

  /**
   * Loads only those keys that have NOT been loaded yet.
   *
   * This operates in two passes: If all of the `keys` are loaded when the
   * function is called, it will immediately return. Otherwise, it will wait
   * for async operations to stop, then check once more to filter out any
   * loaded keys. Finally, if any remain, it will load them.
   */
  async loadLazy(keys: KeyType[]): Promise<void> {
    keys = this.getUnloadedKeys(keys);
    if (!keys.length) {
      return;
    }
    await this._async.waitForStopped();
    keys = this.getUnloadedKeys(keys);
    if (!keys.length) {
      return;
    }
    await this.load(keys);
  }

  /**
   * Manually sets records in the collection.
   *
   * This has two uses. First, it should be called by `load` to put the loaded
   * records into the collection while ensuring that the internal state remains
   * consistent. In addition, it can be used by external code that may have an
   * authoratative instance of a record that it wants to load into the store.
   *
   * Note that the values in `records` should be immutable. They will be stored
   * as-is into the state.
   *
   * `expectedKeys` can be provided for all of the keys that SHOULD have been
   * loaded. This allows us to track records that maybe weren't returned even
   * though they should have been (i.e. not found)
   *
   * `previousKeys` is mostly used when setting records whose keys have changed
   * (such as when inserting a "new" record and getting a permanent key back
   * from the server). In that case, pass the old key to `previousKeys` and it
   * will be removed from the internal lookup, although it will still be
   * considered "loaded" (any reference will return a not found rather than
   * attempt to look up the record in the server).
   *
   * TODO: Is this the right behavior for replacing new records?
   */
  setRecords(
    records: RecordType[],
    {expectedKeys = [], previousKeys = []}: {expectedKeys?: KeyType[], previousKeys?: KeyType[]} = {},
  ): void {
    this._keysFromRecords(records);  // Side effect: throws error on duplicate key
    if (previousKeys && previousKeys.length) {
      this.reset(previousKeys);
    }
    let changed = false;
    const nextLoadedKeySet = new Set(this.data.loadedKeys);
    const nextRecordsByKey = {...this.data.recordsByKey}
    for (const record of records) {
      const key = this._getRecordKey(record);
      if (nextRecordsByKey[key]) {
        if (nextRecordsByKey[key] !== record) {
          nextRecordsByKey[key] = record;
          changed = true;
        }
      } else {
        nextLoadedKeySet.add(key);
        nextRecordsByKey[key] = record;
        changed = true;
      }
    }
    if (expectedKeys) {
      for (const expectedKey of expectedKeys) {
        nextLoadedKeySet.add(expectedKey);
      }
    }
    if (changed) {
      this.setData({
        loadedKeys: [...nextLoadedKeySet],
        recordsByKey: nextRecordsByKey,
      });
    }
  }

  /**
   * Patches one of the records in the collection.
   *
   * Keep in mind that record collections typically don't commit any of their
   * changes to the server, so this will typically be a local-only change.
   *
   * The `quiet` argument will silently return if the record has not yet been
   * loaded or cannot be found. Otherwise these two cases will result in an
   * error (see `getRecordSync`)
   */
  patchRecordByKey(key: KeyType, partialRecord: RecordType, quiet?: boolean): void {
    const prevRecord = this.getRecordSync(key, quiet);
    if (!prevRecord) {
      return;  // Only happens if record not found and `quiet`
    }
    const nextRecord = {...prevRecord, ...partialRecord};
    this.setRecords([nextRecord]);
  }

  /**
   * Like `patchRecordByKey` except that it extracts the key from the record.
   */
  patchRecord(partialRecord: RecordType, quiet?: boolean): void {
    if (!partialRecord || !partialRecord[this._keyField]) {
      if (quiet) {
        return;
      }
      throw new Error('Patching requires a valid record with a key');
    }
    const key = this._getRecordKey(partialRecord);
    this.patchRecordByKey(key, partialRecord, quiet);
  }

  /**
   * Resets (removes) all loaded records. They will need to be re-loaded.
   *
   * TODO: Need to cancel any in-process loads.
   */
  resetAll(): void {
    this.setData(this.makeInitialData());
    this._async.cancel();
  }

  /**
   * Resets (removes) individual records by key.
   *
   * Note that this will not unload any "new" keys. It will delete their data
   * but retain them in the list of loaded keys since they are known to not
   * exist on the server. All other keys will be removed both from the record
   * lookup AND the list of loaded key values.
   */
  reset(keys: KeyType[]): void {
    if (!keys) {
      throw new Error('Must specify one or more keys to reset');
    }
    let changed = false;
    const nextLoadedKeySet = new Set(this.data.loadedKeys);
    const nextRecordsByKey = {...this.data.recordsByKey};
    for (const key of keys) {
      if (nextRecordsByKey[key]) {
        delete nextRecordsByKey[key];
        changed = true;
      }
      if (nextLoadedKeySet.has(key) && !this.isNewKey(key)) {
        nextLoadedKeySet.delete(key);
        changed = true;
      }
    }
    if (changed) {
      this.setData({
        loadedKeys: [...nextLoadedKeySet],
        recordsByKey: nextRecordsByKey,
      });
    }
  }

  /**
   * Deletes records from the store. Note that this is NOT the same as `reset` -
   * this should only be called after the given UUID has been deleted by the
   * system. The internal state will be updated so that it looks like the
   * record has been loaded but not found.
   */
  delete(keys: KeyType[]): void {
    if (!keys) {
      throw new Error('Must specify one or more keys to reset');
    }
    let changed = false;
    const nextLoadedKeySet = new Set(this.data.loadedKeys);
    const nextRecordsByKey = {...this.data.recordsByKey};
    for (const key of keys) {
      if (nextRecordsByKey[key]) {
        delete nextRecordsByKey[key];
        changed = true;
      }
      if (!nextLoadedKeySet.has(key)) {
        nextLoadedKeySet.add(key);
        changed = true;
      }
    }
    if (changed) {
      this.setData({
        loadedKeys: [...nextLoadedKeySet],
        recordsByKey: nextRecordsByKey,
      });
    }
  }

  _getRecordKey(record: RecordType): KeyType {
    const key = record[this._keyField];
    if (!key) {
      console.info(`${this.name}: Record is missing key (${this._keyField})`, record);
      throw new Error('All records must have a unique key value');
    }
    return key;
  }

  _keysFromRecords(records: RecordType[]): KeyType[] {
    const keySet: Set<KeyType> = new Set();
    for (const record of records) {
      const key = this._getRecordKey(record);
      if (keySet.has(key)) {
        console.info(`${this.name}: Duplicate key (${this._keyField}): ${key}`, record, records);
        throw new Error('All records must have a unique key value');
      }
      keySet.add(key);
    }
    return [...keySet];  // Sets are insertion order
  }

}
