import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ReactComponent from 'utils/react-component';
import FixedHeaderFooter from 'components/layouts/fixed-header-footer';

const _EMPTY_STYLE = Object.freeze({});

/**
 * Renders a dialog box for the user.
 *
 * This is generally something that should be rendered based on current state
 * (i.e. within some `if/else` kinda deal). It will
 *
 * `className` and `style` are optional and, if provided, will be combined
 * with the className and style of the dialog box (allowing for manipulation
 * of, for example, the size)
 */

function DialogBoxView({
  backdropRef,
  children,
  className,
  overflowing,
  style,
  title,
  variant,
}) {
  return (
    <React.Fragment>
      {/* if */(variant === 'nonblocking') ? (
        <div
          className={classNames(className, {
            'border-box': true,
            'dialog-box-base': true,
          })}
          style={style || _EMPTY_STYLE}
        >
          <FixedHeaderFooter header={title} overflowing={overflowing}>
            {children}{/* TODO: Add header with optional title and close button */}
          </FixedHeaderFooter>
        </div>
      )/* else */ : (
        <div
          className={classNames({
            'dialog-box-backdrop': true,
            'dialog-box-backdrop--neutral': variant === 'neutral',
            'dialog-box-backdrop--opaque': variant === 'opaque',
            'dialog-box-backdrop--semialpha': variant === 'semialpha',
          })}
          style={style || _EMPTY_STYLE}
          ref={backdropRef}
        >
          <div
            className={classNames({
              'border-box': true,
              'dialog-box-base': true,
            }, className || '')}
            style={style || _EMPTY_STYLE}
          >
            <FixedHeaderFooter header={title} overflowing={overflowing}>
              {children}{/* TODO: Add header with optional title and close button */}
            </FixedHeaderFooter>
          </div>
        </div>
      )/* endif */}
    </React.Fragment>
  );
}


/**
 * A modal dialog box prompting the user for some interaction before continuing.
 *
 * Props:
 *
 * - `obsure` indicates whether the area behind the box should be obscured to
 *   better highlight the dialog box.
 * - `onClose` is a callback called whenever a user interacts with the dialog in
 *   some way that would manually close it (click the close button, click
 *   outside etc.). The owner of the dialog box should cancel the operation
 *   requiring th box in that case. This is basically a passthrough of the
 *   underlying event handler so it will receive event information as well. If
 *   not provided the modal MUST be interacted with to close and no close
 *   button will be displayed (since it wouldn't do anything)
 * - `className` optionally adds one or more classes to the dialog box
 *   container.
 * - `style` optionally adds styles to the container. Most useful for overriding
 *   the normal size or position of the box.
 * - `rendered` determines if the dialog box is rendered at all. Defaults to
 *   true if undefined. This can make it slightly easier to include multiple
 *   dialog boxes in the parent view without needing a ton of conditionals.
 */
export default class DialogBox extends ReactComponent {

  static propTypes = {
    children: PropTypes.node,  // Child content
    className: PropTypes.string,
    onClose: PropTypes.func,
    overflowing: PropTypes.bool,
    rendered: PropTypes.bool,
    style: PropTypes.object,
    title: PropTypes.node,  // Title content
    variant: PropTypes.oneOf(['nonblocking', 'neutral', 'semialpha', 'opaque']),
  };

  static defaultProps = {
    overflowing: false,
    variant: 'neutral',
  };

  constructor(props) {
    super(props);
    this._closable = this.constantProp('closable', true);
    this._backdropNode = null;
  }

  _onBackdropClick(event) {
    if (event.target !== this._backdropNode) {
      return;
    }
    if (this.props.onClose) {
      this.props.onClose(event);
    }
  }

  _backdropRef(node) {
    if (!node) {
      return;
    }
    if (this._closable) {
      this._backdropNode = node;
      this._backdropNode.onclick = this.$b._onBackdropClick;
    }
  }

  render() {
    const props = this.props;
    if (props.rendered === undefined || props.rendered) {
      return DialogBoxView({
        backdropRef: this.$b._backdropRef,
        children: props.children,
        className: props.className,
        overflowing: props.overflowing,
        style: props.style,
        title: props.title || null,
        variant: props.variant,
      });
    }
    return null;
  }

}
