import React from 'react';
import PropTypes from 'prop-types';

// See: https://fontawesome.com/icons?d=gallery&m=free
const ICON_CLASSES = {
  home: 'fas fa-home',
  move: 'fas fa-arrows-alt',
  paint: 'fas fa-paint-brush',
  hidden: 'fas fa-eye-slash',
  square: 'fas fa-expand',
  visible: 'fas fa-eye',
}

// In the future, this should look better...
export default function ToolButton(props) {
  return (
    <button
      className={(props.className || 'tool-button') + (props.selected ? ' selected' : '')}
      onClick={(evt) => { evt.preventDefault(); props.onToggle(evt); }}
      >
      <i
        className={ICON_CLASSES[props.icon]}
        aria-hidden='true'
        title={props.label}
        >
      </i>
    </button>
  );
}

ToolButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  onToggle: PropTypes.func,
  selected: PropTypes.bool,
};
