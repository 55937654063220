import React from 'react';
import {Link} from 'react-router-dom';

import OrionLayout from 'components/layouts/orion-layout';

import MapZonesTable from './map-zones-table';

import ReactComponent from 'utils/react-component';
import shared from 'global';
import {connectToComponentState} from 'statelib/fstate-react';


export default class MapDetails extends ReactComponent {

  constructor(props) {
    super(props);
    // TODO: This is NOT the way to do this - better to have the ref passed in
    // and handled as a constant prop (and default to shared).
    this._mapDetails = props.shared ? shared.mapDetails : new MapDetailsStateRef();
    this.addCleanup(connectToComponentState(this._mapDetails, this, 'mapDetails'));
  }

  componentDidUpdate(prevProps) {
    if (this.props.mapId) {
      this._mapDetails.mapId = this.props.mapId;
    }
  }

  async _reorderMapZones(moveZoneId, afterZoneId) {
    await this._mapDetails.moveMapZone(moveZoneId, afterZoneId, true);
  }

  render() {
    const map = this.state.mapDetails.map;
    const zones = this.state.mapDetails.zones;
    const mapId = map ? map.id : null;
    const async = this.state.mapDetails.async;
    return (
      <React.Fragment>
        {(async.isSuccess && map) ? (
          <div>
            <div>Id: {map.id}</div>
            <div>Name: {map.name}</div>
            <div>Public name: {map.publicName}</div>
            <div>
              Map Zones:
              <OrionLayout
                headerRight={
                  <Link to={`/app/maps/${mapId}/zones/new`}>
                    New Map Zone
                  </Link>
                }
              >
                <div style={{width:'100%'}}>
                  <MapZonesTable
                    zones={zones}
                    history={this.props.history}
                    onChangeOrder={this.$b._reorderMapZones}
                  />
                </div>
              </OrionLayout>
            </div>
          </div>
        ) : (
          (async.isError) ? (
            <p>ERROR: {async.error}</p>
          ) : (
            <p>Loading...</p>
          )
        )}
      </React.Fragment>
    );
  }

}
