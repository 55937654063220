import React from 'react';

/**
 * A simple layout for having a single row of buttons. You can specify
 * items stacked to the left or right via `leftChildren` and `rightChildren`,
 * respectively.
 */
export default function FlexBarLayout({
  children,
  className,
  leftChildren,
  rightChildren,
  style,
}) {
  return (
    <div
      className={className ? `flex-bar-layout ${className}` : 'flex-bar-layout'}
      style={style}
    >
      {/* if */(React.Children.count(leftChildren) >= 1) ? (
        <div className='flex-bar-layout-left'>
          {leftChildren}
        </div>
      )/* endif */ : null}
      {/* if */(React.Children.count(children) >= 1) ? (
        children
      )/* endif */ : null}
      {/* if */(React.Children.count(rightChildren) >= 1) ? (
        <div className='flex-bar-layout-right'>
          {rightChildren}
        </div>
      )/* endif */ : null}
    </div>
  );
}
