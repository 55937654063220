import React from 'react';
import lodashUniqueId from 'lodash.uniqueid';

import ReactComponent from 'utils/react-component';

import MapViewController from './map-view-controller';


// TODO: Remove fat-arrow functions and use binding
export default class MapView extends ReactComponent {

  constructor() {
    super();
    this._viewId = lodashUniqueId();
    this._canvasId = lodashUniqueId('mapViewCanvas');
    this._controller = null;
    this._rootElem = null;
    this.addSetup(this.$b._initViewCanvas);
  }

  _setRootElemRef(elem) {
    this._rootElem = elem;
    if (this.props.mapViewContainer) {
      this.props.mapViewContainer.element = this;
    }
    console.log(`map-view:${this._viewId}: Root object ref callback`, this._rootElem);
  };

  _initViewCanvas() {
    console.log(`map-view:${this._viewId}: Initializing map view controller`, this._rootElem);
    this._controller = new MapViewController(document, this._rootElem);
    this._controller.initialize();
    this.addCleanup(() => this._controller.destroy());
    if (this.props.controllerReady) {
      this.props.controllerReady(this._controller);
    }
    if (this.props.mapViewContainer) {
      this.props.mapViewContainer.controller = this._controller;
    }
  };

  render() {
    // Note that this uses nested divs so that the inner div can "overscan"
    return (
      <div className='layout--positioned-box layout--masking-container' >
        <div
          id={`mapViewRoot-${this._viewId}`}
          ref={this.$b._setRootElemRef}
          style={{
            ...this.props.style,
            touchAction: 'none',
            position: 'relative',
            left: '-10px',
            top: '-10px',
            width: 'calc(100% + 20px)',
            height: 'calc(100% + 20px)',
          }}
          tabIndex='-1'
        />
      </div>
    );
  }

}
