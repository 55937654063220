import statelib from 'statelib';

const LOCAL_STORAGE_KEY = 'settings';


/**
 * State meant for storing local user settings for the application.
 *
 * Any changes made to the state are synced to local storage. This saved data
 * will be restored when the settings are initially loaded.
 *
 * In general, callers should use the `value` getter/setter method
 * (single argument calls the getting, 2-argument will instead set the value).
 * Using standard `getValue` may result in strange behavior since `.`
 * characters generally mean walking through the object tree.
 */
export default class SettingsState extends statelib.FState {

  // The number of "off" frames between each active frame. A frame skip of 0
  // means the maximum frame rate allowed. A frame skip of 1 is a 1/2
  // framerate, frame skip of 2 is a 1/3 framerate, and so forth. A higher
  // frame skip value may result in a more even framerate but is mostly useful
  // for reducing energy consumption (since the browser will be doing less
  // rendering work overall)
  static GRAPHICS_FRAME_SKIP = 'graphics.frameSkip';
  // Overrides the pixel density for the display. A null value should instead
  // use the pixel density provided by the browser
  // (e.g. window.devicePixelRatio)
  static GRAPHICS_PIXEL_RATIO = 'graphics.pixelRatio';
  // A value between 1 and 5 with 1 being the lowest graphics settings and 5
  // being the highest. Certain graphical features will only be enabled if the
  // graphics quality is above a certain threshold.
  static GRAPHICS_QUALITY = 'graphics.quality';

  // All of the default settings values. These will be used when no settings
  // have been saved as well as if the user elects to reset to defaults.
  static DEFAULTS = Object.freeze({
    [SettingsState.GRAPHICS_FRAME_SKIP]: 0,
    [SettingsState.GRAPHICS_PIXEL_RATIO]: null,
    [SettingsState.GRAPHICS_QUALITY]: 4,
  });

  makeInitialData() {
    const restoreData = this._getFromLocalStorage();
    if (restoreData === null) {
      return SettingsState.DEFAULTS;
    }
    return {
      ...SettingsState.DEFAULTS,
      ...restoreData,
    };
  }

  value(settingName, value) {
    if (arguments.length === 1) {
      return this.data[settingName];
    } else if (arguments.length === 2) {
      return this.setValue(settingName, value);
    }
    throw new Error('Must specify a setting name');
  }

  afterDataPublish() {
    this._saveToLocalStorage();
  }

  _getFromLocalStorage() {
    const rawValue = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (rawValue) {
      return JSON.parse(rawValue);
    }
    return null;
  }

  _saveToLocalStorage() {
    const rawValue = JSON.stringify(this.data);
    try {
      localStorage.setItem(LOCAL_STORAGE_KEY, rawValue);
    } catch (err) {
      logger.warn('Unable to save settings', err);
    }
  }

}
