import Squares from 'utils/squares';


/**
 * An object that can be rendered to a map view.
 *
 * This is meant to be used in conjunction with MapObjects to contain things
 * that only have display logic. They can be contained within a map object to
 * handle the dislay of certain sub components (for examle, the paint tool map
 * object needs to draw a rectangle UI element)
 */
export default class MapRenderable {

  /**
   * Returns the rectangle that will need to be re-rendered if this object is
   * rendered onto the canvas. See `MapObject.onUpdate`
   */
  getRerenderRect(render, layer) {
    return Squares.zero();
  }

  /**
   * Renders the object to the canvas context.
   *
   * Note that this should have the same signature as `MapObject.render`.
   */
  render(context, layer, render) {
    return;
  }

}
