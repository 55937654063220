import PropTypes from 'prop-types';
import React from 'react';
import {Dropdown} from '@salesforce/design-system-react';
import {Link} from 'react-router-dom';
import {Spinner} from '@salesforce/design-system-react';

import AppGlobal from 'global';
import UserMenu from 'components/authentication/user-menu';
import CenteredBox from 'components/layouts/centered-box';
import FlexBarLayout from 'components/layouts/flex-bar-layout';
import FullErrorBoundary from 'components/errors/full-error-boundary';
import GlobalMask from 'components/layouts/global-mask';
import {users as usersPb} from 'proto-bundle';

/**
 * Generates content for a global loading spinner.
 *
 * See AppPageTemplate.props for `props` definition.
 */
function GlobalLoadingView({
  visible,
  text,
}) {
  if (!visible) {
    return null;
  }
  return (
    <GlobalMask>
      <CenteredBox style={{'width': '500px'}}>
        <Spinner
          size='small'
          variant='base'
          assistiveText={{label: 'Main Frame Loading...'}}
        />
        {text}
      </CenteredBox>
    </GlobalMask>
  );
}


/**
 * Generates navigation buttons and links in the upper left of the header
 */
function NavMenuView() {
  return (
    <React.Fragment>
      <Link
        className='slds-m-left_small slds-button slds-button_neutral'
        to='/app/maps/campaigns'
      >
        My Campaigns
      </Link>
    </React.Fragment>
  );
}


function HeaderView({
  visible,
}) {
  if (visible === false) {
    return null;
  }
  return (
    <div className='app-page-header'>
      <FlexBarLayout
        leftChildren={
          <NavMenuView />
        }
        rightChildren={
          <UserMenu />
        }
      />
    </div>
  )
}


function FooterView({
  visible,
}) {
  if (!visible) {
    return null;
  }
  return (
    <div className='app-page-header'>
      TODO: Footer Content
    </div>
  );
}


export default function AppPageTemplateView({
  children,
  menuChildren,
  onMenuClick,
  userInfo,
  userMenuVisible,
  onUserMenuClick,
  globalErrorVisible,
  globalError,
  globalLoadingVisible,
  globalLoading,
  headerVisible,
  footerVisible,
  breadcrumbs,
}) {
  return (
    <div className='app-page-wrapper'>
      <HeaderView
        visible={headerVisible}
      />
      <div className='app-page-content'>
        <GlobalLoadingView
          visible={globalLoadingVisible}
          text={globalLoading}
        />
        <FullErrorBoundary>
          {children}
        </FullErrorBoundary>
      </div>
      <FooterView
        visible={footerVisible}
      />
    </div>
  );
}


AppPageTemplateView.propTypes = {
  children: PropTypes.node,
  menuChildren: PropTypes.node,
  onMenuClick: PropTypes.func,
  userInfo: PropTypes.object,  // usersPb.User
  userMenuVisible: PropTypes.bool,
  onUserMenuClick: PropTypes.func,
  globalErrorVisible: PropTypes.bool,
  globalError: PropTypes.node,
  globalLoadingVisible: PropTypes.bool,
  globalLoading: PropTypes.node,
  headerVisible: PropTypes.bool,
  footerVisible: PropTypes.bool,
  breadcrumbs: PropTypes.array,  // Array of <a> nodes
};
