import statelib from 'statelib';

const PREVIEW_MAX_LEN = 10;


/**
 * FState for reading the internal state of an UndoHandler. Writing this state
 * is pointless. If you need to do anything with the associated undo handler,
 * refer to the `handler` property.
 *
 * The main purpose of this is to power UI components and allow them to react to
 * the current undo state.
 */
export default class UndoState extends statelib.FState {

  constructor(undoHandler, name) {
    super(name);
    this._handler = undoHandler;
    this._handler.setOnUpdate(this.$b._onHandlerUpdate);
    this._fullRefresh();
  }

  get handler() {
    return this._handler;
  }

  get undo() {
    return this.getValue('undo');
  }

  get redo() {
    return this.getValue('redo');
  }

  makeInitialData() {
    return {
      undo: {},
      redo: {},
    };
  }

  _onHandlerUpdate(context) {
    this._refreshContext(context);
  }

  _refreshContext(context) {
    const contextUndoState = this._handler.getUndoStack(context, PREVIEW_MAX_LEN);
    const contextRedoState = this._handler.getRedoStack(context, PREVIEW_MAX_LEN);
    const nextUndoData = {...this.undo};
    const nextRedoData = {...this.redo};
    if (contextUndoState.exists) {
      nextUndoData[context] = contextUndoState;
    } else {
      delete nextUndoData[context];
    }
    if (contextRedoState.exists) {
      nextRedoData[context] = contextRedoState;
    } else {
      delete nextRedoData[context];
    }
    this.setData({
      undo: nextUndoData,
      redo: nextRedoData,
    });
  }

  _fullRefresh() {
    const contexts = this._handler.getContexts();
    const nextUndoData = {};
    const nextRedoData = {};
    for (let context of contexts) {
      nextUndoData[context] = this._handler.getUndoStack(context, PREVIEW_MAX_LEN);
      nextRedoData[context] = this._handler.getRedoStack(context, PREVIEW_MAX_LEN);
    }
    this.setData({
      undo: nextUndoData,
      redo: nextRedoData,
    });
  }

}
