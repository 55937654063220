import React from 'react';

import {Input} from '@salesforce/design-system-react';
import {Button} from '@salesforce/design-system-react';

import QueuedAsyncState from 'statelib/queued-async-state';
import FormHandler from 'formlib/form-handler';
import AppReactComponent from 'utils/app-react-component';
import shared from 'global';
import {makeRpcCall} from 'utils/async';
import {maps as mapsPb} from 'proto-bundle';
import {protoEnumToFormValue, formValueToProtoEnum} from 'formlib/form-functions';

const callNewMapZoneRpc = makeRpcCall(
  '/rpc/maps/v1/new_map_zone',
  mapsPb.MapZone,
  mapsPb.MapZone,
);


export default class NewMapZoneForm extends AppReactComponent {

  constructor() {
    super();
    this.async = new QueuedAsyncState();
    this.formHandler = new FormHandler((data) => {
      return {
        name: data.name || '',
        publicName: data.publicName || '',
        gridType: protoEnumToFormValue(data.gridType),
      };
    }, 'NewMapZoneFormHandler');
    this.connect('async', this.async);
    this.addCleanup(this.formHandler.connectFieldsToComponentState(this, 'form'));
  }

  getMapId() {
    if (this.props.mapId) {
      return this.props.mapId;
    }
    return shared.mapDetails.mapId;
  }

  handleChangeName(event, data) {
    this.formHandler.setFormFieldAndData('name', data.value);
  }

  handleChangePublicName(event, data) {
    this.formHandler.setFormFieldAndData('publicName', data.value);
  }

  handleChangeGridType(event, data) {
    this.formHandler.setFormFieldAndData(
      'gridType', event.target.value, formValueToProtoEnum);
  }

  async submit(event) {
    event.preventDefault();
    try {
      const mapZone = await this.async.wrap(async () => {
        return await callNewMapZoneRpc({
          ...this.formHandler.getData(),
          mapId: this.getMapId(),
          gridType: mapsPb.MapZoneGridType.RECT,  // TODO: Make this not constant
        });
      });
      this.props.afterSubmit(mapZone);
    } catch (err) {
      console.error('Failed to create map zone', err);
      this.formHandler.setFormFieldError('$all', `${err}`);
    }
  }

  render() {
    const isLoading = this.state.async.isRunning;
    const fields = this.state.form;
    return (
      <form onSubmit={this.$b.submit}>
        <Input
          name='name'
          label='Name'
          value={fields.name.value}
          onChange={this.$b.handleChangeName}
          disabled={isLoading}
          errorText={fields.name.error}
          required={true}
        />
        <Input
          name='publicName'
          label='Public Name'
          value={fields.publicName.value}
          onChange={this.$b.handleChangePublicName}
          disabled={isLoading}
          errorText={fields.publicName.error}
          placeholder={fields.name.value}
        />
        <p>(Placeholder for grid-type drop down)</p>
        <div>
          <Button
            disabled={isLoading}
            className='big-button'
            label={'Create Map Zone'}
            variant='brand'
            type='submit'
          />
        </div>
      </form>
    );
  }
}
