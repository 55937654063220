import React from 'react';
import PropTypes from 'prop-types';

export default function SessionInfo(props) {
  if (props.isLoading) {
    return (
      <div>
        Loading session information...
      </div>
    );
  } else if (props.sessionData && props.sessionData.isAuthenticated) {
    return (
      <div>
        You are logged in!<br/>
        User Id: {props.sessionData.userId} <br/>
        Session Id: {props.sessionData.sessionId} <br/>
      </div>
    );
  } else {
    return (
      <div>
        You are not logged in.
      </div>
    );
  }
}

SessionInfo.propTypes = {
  isLoading: PropTypes.bool,
  sessionData: PropTypes.object,
};
