import InterfaceGrid from 'components/mapview/map-objects/interface-grid';


/**
 * Like `InterfaceGrid` but bound to a map zone
 */
export default class ZoneGrid extends InterfaceGrid {

  constructor(mapZoneContainer) {
    super();
    this._zoneContainer = mapZoneContainer;
    this._zoneContainerUnsub = mapZoneContainer.onData(
      this.$b.handleUpdatedMapZone, {immediate: true}
    );
  }

  destroy() {
    this._zoneContainerUnsub();
    this._zoneContainerUnsub = null;
    this._zoneContainer = null;
    super.destroy();
  }

  handleUpdatedMapZone(data) {
    if (data.async.isRunning) {
      // Wait for async to finish before making changes
    } else if (data.grid) {
      if (this.grid !== data.grid) {
        this.grid = data.grid;
      }
    } else {
      this.grid = null;
    }
  }

}
