import CanvasUtils from 'utils/graphics/canvas-utils';
import Squares from 'utils/squares';
import Rect from 'utils/rect';

import FrameLayer from './frame-layer';
import FrameRenderContext from './frame-render-context';
import FrameUpdateContext from './frame-update-context';

import type CanvasEngine from './canvas-engine';
import type CanvasLayer from './canvas-layer';
import type EventHitBox from './event-hit-box';
import type GameObject from './gameobjects/game-object';

// Firefox is much faster at `setTransform` using a DOMMatrix object rather
// than specific numeric values so we want to use those whenever possible
const IDENTITY_MATRIX = CanvasUtils.IDENTITY_MATRIX;

/** Defines whether we use clipping masks generated by the fog layer to
  * aggressively clip out contents of other layers. When true, layers will have
  * the appropriate fog of war mask applied as a clipping mask (in addition to
  * the normal redraw rect). If false, flag masks are not applied.
  *
  * When true, this may marginally improve performance because the browser needs
  * to copy less graphical data. It also provides "security" when browser
  * compositing is used (see canvas-engine.ts) because it simply does not
  * render any data that the user is not allowed to see. However, it can also
  * interact strangely with blurred fog of war since the clipping mask is NOT
  * blurred. The result is a weird combination of sharp and blurred edges. */
const AGGRESSIVE_FOG_CLIPPING = false;

/**
 * A single canvas-engine frame. This is tightly couple with it's parent
 * CanvasEngine instance. Because it's tightly coupled with the engine, it
 * access private variables at will.
 *
 * IDEA: In the future, figure out how to suspect execution (return done: false
 * from the execute method and have that be called again) if it's taking too
 * long. This will result in a frame-skip on our side BUT it may improve
 * perceived performance from the user perspective.
 */
export default class Frame {

  // The Frame's parent CanvasEngine. Most of the frame's information is
  // pulled directly from its parent.
  protected _parent: CanvasEngine;
  // See `CanvasEngine._layers`
  public layers: Map<string, CanvasLayer> | null = null;
  // See `CanvasEngine._gameObjects`
  public gameObjects: Map<any, any> | null = null;
  // See `CanvasEngine._eventHitBoxes`
  public eventHitBoxes: Map<GameObject, EventHitBox> | null = null;
  // The timestamp (in milliseconds) of when this frame started
  public startTimestamp: number | null = null;
  // The time delta (in milliseconds) between the last successful frame and
  // this one.
  public frameTimeDelta: number | null = null;
  // The "number" of this frame (from the frame handler)
  public frameNo: number | null = null;
  // Square representing the area of the source that is being rendered.
  public sourceRect: Rect | null = null;
  // Square representing the area on the target. THis should have the same
  // aspect ratio as source sq. This should be the exact same size as the
  // layer cnvases
  public targetRect: Rect | null = null;
  // Knowing the underlying scaling can be useful since it's not easily
  // accessible from the various view squares and such.
  public scale: number | null = null;
  // Pixel ratio isn't used internally, but some game objects use it to
  // scale certain things appropriately for the pixel density of the display.
  public pixelRatio: number | null = null;
  // A keyframe is one where EVERYTHING needs to be redrawn. This typically
  // only happens when the viewport itself somehow changes. There are
  // probably some clever tricks that we can do later to help optimze this.
  // During a keyframe, the underlying canvas will be completely cleared.
  // During a non-keyframe, the canvas will remain intact and will need to be
  // partially cleared as necessary.
  public isKeyframe: boolean | null = null;
  // A volatile frame is a special type of keyframe where we assume that the
  // graphical information is percieved by the user as changing rapidly.
  // Because of this, we can reduce graphical fidelity for the sake of speed.
  public isVolatile: boolean | null = null;
  // List of rects (in source-pixel-space) that have been updated and need to
  // be redrawn on the final canvas. Note that for a key-frame, it is assumed
  // that the entire `sourceRect` needs to be redrawn and these will be
  // ignored.
  public redrawRegions: Map<any, any> = new Map();
  // A transformation matrix going from source-pixel-space to
  // local-pixel-space. This makes it easier for objects so simply draw their
  // "real" (source) location and have it be properly displayed in the view.
  // Typically this will be unchanged between frames (and is stored in the
  // context's memory) so you will notice that it is only set in layer
  // context on keyframes.
  public viewTransform: DOMMatrixReadOnly | null = null;

  protected _knownMaskPath: Path2D | null = null;
  protected _visibleMaskPath: Path2D | null = null;

  constructor(parent: CanvasEngine) {
    this._parent = parent;
  }

  get sourceSq() {
    console.warn('sourceSq is deprecated, use sourceRect');
    return this.sourceRect;
  }

  get targetSq() {
    console.warn('targetSq is deprecated, use targetRect');
    return this.targetRect;
  }

  get knownMaskPath() {
    return this._knownMaskPath;
  }

  get visibleMaskPath() {
    return this._visibleMaskPath;
  }

  getLayer(layerName): FrameLayer {
    const layer = this.layers.get(layerName);
    if (!layer) {
      throw new Error(`Unknown layer name '${layerName}'`);
    }
    return new FrameLayer(this, layer);
  }

  getScratchLayer(layerName) {
    const scratchLayer = this._parent.getScratchLayer(layerName);
    // TODO: Figure out how to ONLY set the transform if new (otherwise it ends
    // up being set by the `prepareLayers` function. Without this, if a layer
    // is generated during a frame for the first time, it won't have the
    // expected transform resulting in some weird stuff.
    scratchLayer.getContext().setTransform(this.viewTransform);
    return scratchLayer;
  }

  deleteScratchLayer(layerName) {
    return this._parent.deleteScratchLayer(layerName);
  }

  getRenderBounds() {
    return this.sourceRect;
  }

  renderRectToSourceRect(renderRect) {
    // const renderToSourceScale = this.pixelRatio / this.scale;
    const renderToSourceScale = 1 / this.scale;
    return Squares.fromCoordinates(
      (renderRect.x * renderToSourceScale) + this.sourceRect.x,
      (renderRect.y * renderToSourceScale) + this.sourceRect.y,
      (renderRect.x2 * renderToSourceScale) + this.sourceRect.x,
      (renderRect.y2 * renderToSourceScale) + this.sourceRect.y,
    );
  }

  sourceRectToRenderRect(sourceRect) {
    // const sourceToRenderScale = this.scale / this.pixelRatio;
    const sourceToRenderScale = this.scale;
    return Squares.fromCoordinates(
      (sourceRect.x - this.sourceRect.x) * sourceToRenderScale,
      (sourceRect.y - this.sourceRect.y) * sourceToRenderScale,
      (sourceRect.x2 - this.sourceRect.x) * sourceToRenderScale,
      (sourceRect.y2 - this.sourceRect.y) * sourceToRenderScale,
    );
  }

  /**
   * Returns the combined redraw region across all layers in local-pixel space.
   *
   * If no layers have a redraw region, returns null. This does NOT take into
   * account `isKeyFrame` - if this is a keyframe, assume that the entire thing
   * needs to be redrawn.
   *
   * The `layerName` is optional. If provided, only returns the redraw region
   * for that specific layer.
   *
   * IDEA: Short circuit if key frame? No need to calculate all of the redraw
   * regions if we're just going to do the whole thing anyway.
   */
  getRedrawRegion(layerName?: string): Rect {
    let redrawRegion = null;
    if (layerName) {
      const layerRedrawRegions = this.redrawRegions.get(layerName);
      if (layerRedrawRegions && layerRedrawRegions.length) {
        // In the future, work with multiple redraw regions. For now the one is
        // fine.
        redrawRegion = layerRedrawRegions[0]
      }
    } else {
      for (const layerName of this.layers.keys()) {
        const layerRedrawRegions = this.redrawRegions.get(layerName);
        if (!layerRedrawRegions || layerRedrawRegions.length < 1) {
          continue;
        } else if (redrawRegion === null) {
          redrawRegion = layerRedrawRegions[0];
        } else {
          redrawRegion = Rect.containingRect(redrawRegion, layerRedrawRegions[0]).roundOuter();
        }
      }
    }
    return redrawRegion;
  }

  /**
   * Same as `getRedrawRegion` but in source-pixel-space.
   */
  getRedrawRegionSource(layerName) {
    const redrawRegion = this.getRedrawRegion(layerName);
    if (redrawRegion === null) {
      return null;
    }
    return this.renderRectToSourceRect(redrawRegion);
  }

  /**
   * Adds a rect Redraw Region to the internal state. Whenever an object
   * requires something be redrawn, it should call into this function.
   *
   * Redraw regions are in local-pixel-space
   */
  addRedrawRegion(layerName, redrawRect) {
    let layerRedrawRegions = this.redrawRegions.get(layerName);
    if (!layerRedrawRegions) {
      layerRedrawRegions = [Rect.fromObject(redrawRect).roundOuter()];
      this.redrawRegions.set(layerName, layerRedrawRegions);
    } else {
      // Currently only handle a single redraw region per layer. In the future
      // we may want to support multiple
      layerRedrawRegions[0] = Rect.containingRect(redrawRect, layerRedrawRegions[0]).roundOuter();
    }
  }

  /**
   * Like `addRedrawRegion` but in source-pixel-space
   */
  addRedrawRegionSource(layerName, redrawRect) {
    this.addRedrawRegion(layerName, this.sourceRectToRenderRect(redrawRect));
  }

  setFullRedraw(layerName) {
    let layerRedrawRegions = this.redrawRegions.get(layerName);
    if (!layerRedrawRegions) {
      layerRedrawRegions = [];
      this.redrawRegions.set(layerName, layerRedrawRegions);
    } else {
      layerRedrawRegions.length = 0;  // Clear
    }
    layerRedrawRegions.push(this.targetRect);
  }

  /**
   * Sets the two paths that can be used to clip underlying layer data. This
   * needs to be set by the fog of war game object on update (which is one
   * of the main reasons it needs to be on the special layer).
   *
   * If one of the masks is null, the assumption is that there is no masking.
   */
  setMaskingPaths(knownAreaMaskPath: Path2D | null, visibleAreaMaskPath: Path2D | null) {
    this._knownMaskPath = knownAreaMaskPath;
    this._visibleMaskPath = visibleAreaMaskPath;
  }

  execute() {
    this._prepareLayers();
    this._updateGameObjects();
    this._updateEventTargets();
    this._renderGameObjects();
    // IDEA: In the future, we might be able to suspend execution in the middle
    // if it is taking too long. In that case, return `done: false` and, if
    // `execute()` is called again, pick up where we left off.
    return {done: true};
  }

  _prepareLayers() {
    const targetRect = this.targetRect;
    this.viewTransform = IDENTITY_MATRIX
      .scale(this.scale, this.scale)
      .translate(-1 * this.sourceRect.x, -1 * this.sourceRect.y);
    const allLayers = [
      ...this.layers.entries(),
      ...this._parent.getScratchLayersEntries(),
    ];
    for (const [layerName, layer] of allLayers) {
      const canvas = layer.getCanvas();
      if (canvas.width !== targetRect.w || canvas.height !== targetRect.h) {
        canvas.width = targetRect.w;
        canvas.height = targetRect.h;
      }
      const context = layer.getContext();
      if (this.isKeyframe) {
        // Clear out the canvas - Note that it seems faster to reset to the
        // identity matrix and clear than it was to try and do it within the
        // current view transform.
        context.setTransform(IDENTITY_MATRIX);
        context.clearRect(
          0,
          0,
          targetRect.w,
          targetRect.h,
        );
        context.setTransform(this.viewTransform);
      }
      // TODO: Original CanvasView implementation set up the fancy layer wrapper
      // object for each non-scratch layer. Do we need to do that here?
    }
  }

  _updateGameObjects() {
    // IDEA: Maintain a list of all game objects that have been updated and
    // invert it to iterate in reverse for render.
    for (const [layerName, gameObjects] of this.gameObjects.entries()) {
      const layer = (layerName === 'special') ? null : this.getLayer(layerName);
      const frameContext = new FrameUpdateContext({
        timestamp: this.startTimestamp,
        timeDelta: this.frameTimeDelta,
        frameNo: this.frameNo,
        layer: layer,
        frame: this,
      });
      for (const gameObject of gameObjects.values()) {
        this._checkUpdateGameObject(gameObject, frameContext);
      }
    }
  }

  _checkUpdateGameObject(gameObject, frameContext) {
    if (gameObject.isEnabled()) {
      gameObject.onUpdate(frameContext);
    }
  }

  _updateEventTargets() {
    // Currently, we only need to worry about event targets if there is a
    // keyframe (since that will occur during pan/zoom which is the time when
    // game objects may be unaware that they are "moving").
    if (this.isKeyframe) {
      for (const eventHitBox of this.eventHitBoxes.values()) {
        eventHitBox.refreshPosition();
      }
    }
  }

  _renderGameObjects() {
    // Go through each layer
    // if it's the "special" layer, just run plain renders. This should run
    // last so that it can draw over whatever else has already been drawn.
    // TODO: Do we _actually_ need the special layer?
    // For each other layer, do context save/try/finally/restore at the
    // top level, then apply redraw region clipping (if applicable), then
    // do the same deal for each map object to be rendered.
    const gameObjectLayers = [...this.gameObjects.entries()];
    for (const [layerName, gameObjects] of gameObjectLayers.reverse()) {
      if (layerName === 'special') {
        this._renderGameObjectsLayerSpecial(layerName, gameObjects);
      } else {
        this._renderGameObjectsLayerNormal(layerName, gameObjects);
      }
    }
  }

  _renderGameObjectsLayerSpecial(layerName, gameObjects) {
    const frameContext = new FrameRenderContext({
      context: null,
      layer: null,
      frame: this,
    });
    for (const gameObject of gameObjects.values()) {
      this._checkRenderGameObject(gameObject, frameContext);
    }
  }

  _renderGameObjectsLayerNormal(layerName, gameObjects) {
    const layer = this.getLayer(layerName);
    const context = layer.getContext();
    const frameContext = new FrameRenderContext({
      context: context,
      layer: layer,
      frame: this,
    });
    // Determine the actual redraw region
    let redrawRectSource: Rect;
    if (this.isKeyframe) {
      redrawRectSource = this.sourceRect.roundOuter();
    } else {
      redrawRectSource = Rect.fromObject(this.getRedrawRegionSource(layerName))
        .getIntersection(this.sourceRect)
        .roundOuter();
    }
    // Short circuit if there is no visible redraw region for this layer (if
    // we didn't short circuit, the clipping path would prevent anything from
    // being rendered regardless). Remember, GameObject.render should NOT have
    // side effects, so this shouldn't skip any logic.
    if (!redrawRectSource || redrawRectSource === Rect.ZERO) {
      return;
    }
    context.save();
    try {
      context.clearRect(
        redrawRectSource.x,
        redrawRectSource.y,
        redrawRectSource.w,
        redrawRectSource.h,
      );
      // Since kyframes redraw the entire source, there's no point in adding
      // the overhead of a clipping mask.
      if (!this.isKeyframe) {
        const redrawPath = new Path2D();
        redrawPath.rect(
          redrawRectSource.x,
          redrawRectSource.y,
          redrawRectSource.w,
          redrawRectSource.h,
        );
        context.clip(redrawPath);
      }
      // Clip layer based on what is actually visible. See `AGGRESSIVE_FOG_CLIPPING`
      // for what this accomplishes.
      if (AGGRESSIVE_FOG_CLIPPING) {
        if (this._knownMaskPath && layerName == "map") {
          context.clip(this._knownMaskPath, "evenodd");
        } else if (this._visibleMaskPath && layerName == "tokens") {
          context.clip(this._visibleMaskPath, "evenodd");
        }
      }
      // Render each object in an "isolated" context
      for (const gameObject of gameObjects.values()) {
        context.save();
        try {
          this._checkRenderGameObject(gameObject, frameContext)
        } finally {
          context.restore();
        }
      }
    } finally {
      context.restore();
    }
  }

  _checkRenderGameObject(gameObject, frameContext) {
    // TODO: Should also skip rendering if the object is outside of the
    // renderable area (assuming not a keyframe). It is likely faster to check
    // for some type of path intersection with the context clipping path than
    // to perform the entire render of an object if it doesn't need to happen.
    if (gameObject.isEnabled() && gameObject.isVisible()) {
      gameObject.onRender(frameContext);
    }
  }
}
