const ZERO_SQUARE = Object.freeze({
  x: 0,
  y: 0,
  x2: 0,
  y2: 0,
  w: 0,
  h: 0,
})


/**
 * Contains helper functions for working with Squares. A square is a simple
 * object containing x, y, x2, y2, w, and h properties (as created by the
 * `from*` functions)
 */
const Squares = Object.freeze({

  /**
   * Function to get the constant "none" square which is all zeroes
   */
  zero: () => ZERO_SQUARE,

  /**
   * Generates a new square using 2 sets of coordinates. This will normalize
   * the coordinates so that (x, y) is the upper-left corner (minimum values)
   * and (x2, y2) is the lower-right corner (max values).
   */
  fromCoordinates: (x1, y1, x2, y2) => {
    if (
      x1 === undefined ||
      y1 === undefined ||
      x2 === undefined ||
      y2 === undefined ||
      isNaN(x1) ||
      isNaN(y1) ||
      isNaN(x2) ||
      isNaN(y2)
    ) {
      throw new Error('Invalid parameter');
    }
    return Object.freeze({
      x: (x1 < x2) ? x1 : x2,
      y: (y1 < y2) ? y1 : y2,
      x2: (x1 < x2) ? x2 : x1,
      y2: (y1 < y2) ? y2 : y1,
      w: Math.abs(x2 - x1),
      h: Math.abs(y2 - y1),
    });
  },

  /**
   * Generates a new square using upper-left coordinates and sizes.
   */
  fromDimensions: (x, y, w, h) => {
    if (
      x === undefined ||
      y === undefined ||
      w === undefined ||
      h === undefined ||
      isNaN(x) ||
      isNaN(y) ||
      isNaN(w) ||
      isNaN(h)
    ) {
      throw new Error('Invalid parameter');
    }
    return Object.freeze({
      x: x,
      y: y,
      x2: x + w,
      y2: y + h,
      w: w,
      h: h,
    });
  },

  /**
   * Generates a squares object from something like a `MaskPatch` that has an
   * x, y, and dimensions data.
   */
  fromPatch: (patch) => {
    if (patch === undefined) {
      throw new Error('Invalid patch');
    }
    return Squares.fromDimensions(patch.x, patch.y, patch.width, patch.height);
  },

  /**
   * Generates a new square based on the given canvas. The top left corner
   * will be at (0, 0) unles the optional `offsetX` and `offsetY` parameters
   * are provided.
   */
  fromCanvas: (canvas, offsetX, offsetY) => {
    if (offsetX === undefined) {
      offsetX = 0;
    }
    if (offsetY === undefined) {
      offsetY = 0;
    }
    return Object.freeze({
      x: offsetX,
      y: offsetY,
      x2: offsetX + canvas.width,
      y2: offsetY + canvas.height,
      w: canvas.width,
      h: canvas.height,
    })
  },

  /**
   * Moves returns a value representing `square` if it were shifted to fit
   * within `container`. If square is bigger than container in a dimension,
   * the returned value will be shifted so that the centers align.
   */
  shiftInside: (square, container) => {
    if (Squares.isContainedWithin(square, container)) {
      return square;
    }
    let newX = square.x;
    if (square.w > container.w) {
      newX = (container.x + (container.w / 2)) - (square.w / 2);
    } else if (square.x < container.x) {
      newX = container.x;
    } else if (square.x2 > container.x2) {
      newX = container.x2 - square.w;
    }
    let newY = square.y;
    if (square.h > container.h) {
      newY = (container.y + (container.h / 2)) - (square.h / 2);
    } else if (square.y < container.y) {
      newY = container.y;
    } else if (square.y2 > container.y2) {
      newY = container.y2 - square.h;
    }
    return Squares.fromDimensions(newX, newY, square.w, square.h);
  },

  /**
   * Fairly narrow use-case, but constrains the `srcSquare` within the container
   * while also modifying `dstSquare` to match.
   *
   * This is particularly useful when drawing from one place to another. Some
   * browsers do not support "pulling" content from outside of theboundaries of
   * the source when doing a `drawImage`. Using this function you can ensure
   * that the output `srcSquare` is within the container and that the output
   * `dstSquare` is reshaped appropriately.
   */
  constrain: ({container, srcSquare, dstSquare}) => {
    const dstToSrcXRatio = dstSquare.w / srcSquare.w;
    const dstToSrcYRatio = dstSquare.h / srcSquare.h;
    const nextSrcCoordinates = {
      x: srcSquare.x,
      y: srcSquare.y,
      x2: srcSquare.x2,
      y2: srcSquare.y2,
    };
    const nextDstCoordinates = {
      x: dstSquare.x,
      y: dstSquare.y,
      x2: dstSquare.x2,
      y2: dstSquare.y2,
    };
    if (srcSquare.x < container.x) {
      nextSrcCoordinates.x = container.x;
      nextDstCoordinates.x += (container.x - srcSquare.x) * dstToSrcXRatio;
    }
    if (srcSquare.y < container.y) {
      nextSrcCoordinates.y = container.y;
      nextDstCoordinates.y += (container.y - srcSquare.y) * dstToSrcYRatio;
    }
    if (srcSquare.x2 > container.x2) {
      nextSrcCoordinates.x2 = container.x2;
      nextDstCoordinates.x2 -= (srcSquare.x2 - container.x2) * dstToSrcXRatio;
    }
    if (srcSquare.y2 > container.y2) {
      nextSrcCoordinates.y2 = container.y2;
      nextDstCoordinates.y2 -= (srcSquare.y2 - container.y2) * dstToSrcYRatio;
    }
    return {
      container: container,
      srcSquare: Squares.fromCoordinates(
        nextSrcCoordinates.x,
        nextSrcCoordinates.y,
        nextSrcCoordinates.x2,
        nextSrcCoordinates.y2,
      ),
      dstSquare: Squares.fromCoordinates(
        nextDstCoordinates.x,
        nextDstCoordinates.y,
        nextDstCoordinates.x2,
        nextDstCoordinates.y2,
      ),
    }
  },

  /**
   * Returns true if both squares are exactly equal.
   */
  isEqual: (square1, square2) => {
    return (
      square1.x === square2.x &&
      square1.y === square2.y &&
      square1.x2 === square2.x2 &&
      square1.y2 === square2.y2
    );
  },

  /**
   * Checks if innerSquare is contained completely within
   * outerSquare. Equivalent positions are considered to be contained
   * within.
   */
  isContainedWithin: (innerSquare, outerSquare) => {
    return (innerSquare.x >= outerSquare.x &&
        innerSquare.y >= outerSquare.y &&
        innerSquare.x2 <= outerSquare.x2 &&
        innerSquare.y2 <= outerSquare.y2);
  },

  /**
   * Checks if the first square is larger than the second.
   */
  isLargerThan: (square1, square2) => {
    return square1.w > square2.w && square1.h > square2
  },

  /**
   * Returns the overflow amount in each dimension. Returns
   * data in the form [x1, y1, x2, y2].
   */
  getOverflow: (innerSquare, outerSquare) => {
    let overflowX1 = Math.max(0, innerSquare.x - outerSquare.x);
    let overflowY1 = Math.max(0, innerSquare.y - outerSquare.y);
    let overflowX2 = Math.max(0, outerSquare.x2 - innerSquare.x2);
    let overflowY2 = Math.max(0, outerSquare.y2 - innerSquare.y2);
    return [overflowX1, overflowY1, overflowX2, overflowY2];
  },

  /**
   * Returns true if `square` contains the point specified by the coordinates
   * `x`, `y`.
   */
  containsPoint: (square, x, y) => {
    return (
      x >= square.x &&
      x <= square.x2 &&
      y >= square.y &&
      y <= square.y2
    );
  },

  /**
   * Returns true if there is an intersection between square1 and square2
   *
   * See: https://stackoverflow.com/a/306332/703040
   *
   * TODO: Does this handle the case where square2 is completely inside of
   * square1? I'm not sure if it does.
   */
  hasIntersection: (square1, square2) => {
    return (
      square1.x < square2.x2 &&
      square1.x2 > square2.x &&
      square1.y < square2.y2 &&
      square1.y2 > square2.y
    );
  },

  /**
   * Gets the intersection between the two squares. If no such intersection
   * exists, returns null.
   */
  getIntersection: (square1, square2) => {
    if (!Squares.hasIntersection(square1, square2)) {
      return null;
    }
    return Squares.fromCoordinates(
      Math.max(square1.x, square2.x),
      Math.max(square1.y, square2.y),
      Math.min(square1.x2, square2.x2),
      Math.min(square1.y2, square2.y2),
    );
  },

  /**
   * Returns the [x, y] coordinates of the center of the square.
   */
  getCenter: (square) => {
    let cx = square.x + (square.w / 2);
    let cy = square.y + (square.h / 2);
    return [cx, cy];
  },

  /**
   * Returns a square that contains both squares
   */
  getContainingSquare: (square1, square2) => {
    return Squares.fromCoordinates(
      Math.min(square1.x, square2.x),
      Math.min(square1.y, square2.y),
      Math.max(square1.x2, square2.x2),
      Math.max(square1.y2, square2.y2),
    );
  },

  /**
   * Scales the provided square by `scaleValue`
   */
  scale: (square, scaleValue) => {
    return Squares.fromDimensions(
      square.x,
      square.y,
      square.w * scaleValue,
      square.h * scaleValue,
    );
  },

  /**
   * Inversely scales the provided square by `scaleValue`. This is often
   * used when trying to return to source coordinates after a zoom.
   */
  invScale: (square, scaleValue) => {
    return Squares.fromDimensions(
      square.x,
      square.y,
      square.w / scaleValue,
      square.h / scaleValue
    );
  },

  /**
   * Grows (or shrinks) the square about is upper-left corner. This is less
   * useful, but faster, than `growCentered`. It is typically only good
   * when the square itself may be moved or you are comparing the sizes of
   * squares rather than their locations.
   */
  grow: (square, wAmount, hAmount) => {
    hAmount = (hAmount === undefined) ? wAmount : hAmount;
    return Squares.fromDimensions(
      square.x,
      square.y,
      square.w + wAmount,
      square.h + hAmount
    );
  },

  /**
   * Grows (or shrinks) the square by the scaler values. The square will
   * grow or shrink about its current center point.
   */
  growCentered: (square, wAmount, hAmount) => {
    hAmount = (hAmount === undefined) ? wAmount : hAmount;
    return Squares.fromDimensions(
      square.x - (wAmount / 2),
      square.y - (hAmount / 2),
      square.w + wAmount,
      square.h + hAmount
    );
  },

  /**
   * Reorients the square based on a scaled coordinate system
   */
  reorient: (square, scaleValue) => {
    return Squares.fromCoordinates(
      square.x * scaleValue,
      square.y * scaleValue,
      square.x2 * scaleValue,
      square.y2 * scaleValue,
    );
  },

  /**
   * Shifts a square by an amount. Note that if `yAmount` is undefined, the
   * square will be shifted by `xAmount` along both axes.
   */
  shift: (square, xAmount, yAmount) => {
    yAmount = (yAmount === undefined) ? xAmount : yAmount;
    return Squares.fromDimensions(
      square.x + xAmount,
      square.y + yAmount,
      square.w,
      square.h,
    );
  },

  /**
   * Truncates all coordinates to integer values. This can be useful
   * immediately before working with canvases to remove subpixel nonsense.
   */
  trunc: (square) => {
    // TODO: Better to truncate width and height or x2, y2?
    return Squares.fromCoordinates(
      Math.trunc(square.x),
      Math.trunc(square.y),
      Math.trunc(square.x2),
      Math.trunc(square.y2)
    );
  },

  /**
   * Truncates all coordinates to integer values. This can be useful
   * immediately before working with canvases to remove subpixel nonsense.
   */
  round: (square) => {
    return Squares.fromCoordinates(
      Math.round(square.x),
      Math.round(square.y),
      Math.round(square.x2),
      Math.round(square.y2)
    );
  },

  toString: (square) => {
    return `(${square.x},${square.y});(${square.x2},${square.y2});(${square.w}x${square.h})`;
  },

});

export default Squares;
