/**
 * A simple data structure containing two stacks - one for undo operations and
 * one for redo operations. Provides basic stack functions.
 */
export default class UndoStack {

  constructor() {
    this._undoArr = [];
    this._redoArr = [];
  }

  get undoLength() {
    return this._undoArr.length;
  }

  get redoLength() {
    return this._redoArr.length;
  }

  getUndoState(maxLength) {
    const trueLen = this._undoArr.length;
    if (!maxLength || maxLength > trueLen) {
      maxLength = trueLen;
    }
    const state = []
    for (let i = 0; i < maxLength; i++) {
      const step = this._undoArr[trueLen - 1 - i];  // Reversed (because LIFO)
      state.push(
        {idx: i, id: step.id, description: step.config.description || 'Undo'}
      );
    }
    return state;
  }

  getRedoState(maxLength) {
    const trueLen = this._redoArr.length;
    if (!maxLength || maxLength > trueLen) {
      maxLength = trueLen;
    }
    const state = []
    for (let i = 0; i < maxLength; i++) {
      const step = this._redoArr[trueLen - 1 - i];  // Reversed (because LIFO)
      state.push(
        {idx: i, id: step.id, description: step.config.description || 'Redo'}
      );
    }
    return state;
  }

  clearRedo() {
    this._redoArr = [];
  }

  pushUndo(item) {
    this._undoArr.push(item);
  }

  pushRedo(item) {
    this._redoArr.push(item);
  }

  peekUndo() {
    if (this._undoArr.length) {
      return this._undoArr[this._undoArr.length - 1];
    }
    return null;
  }

  peekRedo() {
    if (this._redoArr.length) {
      return this._redoArr[this._redoArr.length - 1];
    }
    return null;
  }

  popUndo() {
    if (this._undoArr.length) {
      return this._undoArr.pop();
    }
    return null;
  }

  popRedo() {
    if (this._redoArr.length) {
      return this._redoArr.pop();
    }
    return null;
  }

}
