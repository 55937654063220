import debounce from 'lodash/debounce';  // TODO: Sort of bad practice
import uniqueId from 'lodash/uniqueid';

import makeBindable from 'utils/bound-methods';
import {deepGet} from 'utils/obj-paths';
import {pathParse} from 'utils/obj-paths';
import {shallowEqual} from 'utils/types';

// Internal path seperator when working with things like subscribers
const _ROOT_PATH = pathParse('').path;

// Whether or not callback exceptions should be re-raised. This can make
// debugging easier but can put
const RE_RAISE_CALLBACK_EXCEPTIONS = true;

// If true, the fstate class (and thus the full list of all states) will be
// exported to `window`
const GLOBAL_EXPORT = true;

// When enabled, prints much more debug information to the console
const DEBUG = false;

// Warning if the state is unchanged as the result of an action. Most of the
// time this isn't actually an issue, but it may be good to periodically check
// to avoid adding a bunch of no-op actions to the state.
const WARN_UNCHANGED_STATE = false;

// Warns if an action takes more than this many milliseconds to apply. Can be
// useful during performance tuning, but is otherwise typically just noise.
const WARN_LONG_APPLY = true;

// Warn if there are ever more than this many subscribers to process as a result
// of a single change. A high number of subscribers may result in slow code. It
// may also be due to a memory leak if an object isn't properly being destroyed.
const MAX_SUB_WARNING = 100;

// Used if debounce is simply set to `true` when subscribing. This debounce
// config is meant to feel very responsive while still providing some of the
// benefits of debouncing events.
const DEFAULT_DEBOUNCE_CONFIG = Object.freeze({
  wait: 83,  // Every 5 frames (12 fps)
  options: {maxWait: 200},  // Every 12 frames (5 fps)
});

const ACTION_RESET = 'ACTION_RESET';
const ACTION_SET_DATA = 'ACTION_SET_DATA';
const ACTION_SET_VALUE = 'ACTION_SET_VALUE';
const ACTION_PATCH_DATA = 'ACTION_PATCH_DATA';
const ACTION_PATCH_VALUE = 'ACTION_PATCH_VALUE';
const ACTION_ATTACHED_STATE_SET = 'ACTION_ATTACHED_STATE_SET';
const ACTION_ATTACHED_STATE_DEL = 'ACTION_ATTACHED_STATE_DEL';

// TODO: Sort this out
type DataType = any;

type UnsubscribeType = () => void;
type DataCallbackType = (newValue: DataType, oldValue: DataType, state: FState) => void;


/**
 * Convenience function for subscribing to multiple states/paths at once,
 * receiving the data for all of them. `statePaths` is an array of objects,
 * each has a `state` property referencing the state to subscribe to an a
 * `path` that indifies the value. Values will be sent to callback in the order
 * they are defined in statePaths. `oldData` will not be sent to the callback
 * since previous values across multiple states is difficult to track.
 */
function subscribeMany(statePaths, callback, options) {
  const finalOptions = (options) ? {...options} : {};
  const unsubscribes = [];
  const values = [];
  const genCallback = (idx, newData, oldData) => {
    values[idx] = newData;
    callback(...values);
  }
  // Add initial values/listeners for each state/path combination. Overwrite
  // `path` because it is state-specific and `immediate` because it will be
  // checked once all of the values have been initialized.
  for (let i = 0; i < statePaths.length; i++) {
    const {state, path} = statePaths[i];
    const stateOptions = {...finalOptions, path: path, immediate: false};
    const indexCallback = genCallback.bind(null, i);
    values.push(state.getValue(path));
    unsubscribes.push(state.onData(indexCallback, stateOptions));
  }
  // Handle immediate once everything is set up to prevent duplicate calls.
  if (finalOptions.immediate) {
    callback(...values);
  }
  // Return an unsubscribe function that will handle both unsubscribes and
  // clearing out of all stored, historical values.
  return () => {
    while (unsubscribes.length > 0) {
      unsubscribes.shift()();  // Call the unsubscribe function when popped
    }
    while (values.length > 0) {
      values.shift();
    }
  };
}


function slowObjectCompare(obj1, obj2) {
  try {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  } catch (err) {
    // Fall-back in case the data is too weird
    return shallowEqual(obj1, obj2);
  }
}


/**
 * Special exception raised when a condition does not occur fast enough.
 */
class TimeoutError extends Error {}


/**
 * Contains a single state.
 *
 * Some facts!
 *
 * `data` should always be immutable. There is technically nothing that enforces
 * this, but it is a good idea for any action handler functions to do
 * object.freeze.
 *
 * Note that it's called `FState` instead of just `State` to make it easier to
 * find. There's really no other reason. It also sort of shows my feelings
 * toward dealing with all of this application state stuff...
 *
 * The `DataType` generic should typically be some sort of interface. I have no
 * idea how
 */
export default class FState {

  static All = new WeakSet();
  static TimeoutError = TimeoutError;
  static ACTION_RESET = ACTION_RESET;
  static ACTION_SET_DATA = ACTION_SET_DATA;
  static ACTION_SET_VALUE = ACTION_SET_VALUE;
  static ACTION_PATCH_DATA = ACTION_PATCH_DATA;
  static ACTION_PATCH_VALUE = ACTION_PATCH_VALUE;
  static ACTION_ATTACHED_STATE_SET = ACTION_ATTACHED_STATE_SET;
  static ACTION_ATTACHED_STATE_DEL = ACTION_ATTACHED_STATE_DEL;
  static DEFAULT_NAME = 'UnknownState';

  static subscribeMany = subscribeMany;

  name: string;
  _destroyed: boolean;
  _data: DataType;
  _subscribers: Map<string, Map<any, DataCallbackType>>;
  _attachedStates: {[key: string]: FState};
  _attachedStateUnsubscribes: {[key: string]: UnsubscribeType};
  _attachedStateIsExternal: {[key: string]: boolean};

  $b: any;
  $bdestroy: any;

  constructor(name) {
    FState.All.add(this);
    this.name = `${name || FState.DEFAULT_NAME}_${uniqueId()}`;
    this._destroyed = false;
    this._data = this.makeInitialData();
    this._subscribers = new Map();
    this._attachedStates = {};
    this._attachedStateUnsubscribes = {};
    this._attachedStateIsExternal = {};
    makeBindable(this);
    if (new.target === FState) {
      this.apply(ACTION_RESET, {});
    }
  }

  get isDestroyed() {
    return this._destroyed;
  }

  get data() {
    return this._data;
  }

  /**
   * Virtual function used to set the initial data for the state. This will be
   * called ONCE during the constructor and then also by the ACTION_RESET
   * action.
   */
  makeInitialData() {
    return {};
  }

  /**
   * Function that will reduce a given action.
   *
   * The default reducer implements the standard actions. For classes that may
   * want to implement more than that, it is recommended that they call
   * `super.reduce` as their `default` switch handler.
   *
   * Note that any attempts to overwrite data in an attached state will either
   * fail loudly or, at the very least, be overwritten by the attached state
   * data.
   *
   * IDEA: Might be necessary to have a `DEEP_SET_VALUE` and `DEEP_PATCH_VALUE`.
   * On the other hand, that might be a little too complicated to make work in
   * a generic way and it could be better to just have the class implement it
   * manually (see apps/maps/state/embark-tools.js and how it deals with tool
   * options)
   */
  reduce(prevState, action, data) {
    switch (action) {
      case ACTION_RESET: {
        return {
          ...this.makeInitialData(),
          ...this.getCombinedAttachedStateData(),
        };
      }
      case ACTION_SET_DATA: {
        // TODO: Fail loudly if `data` attempts to overwrite attached state data.
        return {
          ...data,
          ...this.getCombinedAttachedStateData(),
        };
      }
      case ACTION_SET_VALUE: {
        const {name, value} = data;
        if (this._attachedStates[name]) {
          throw new Error(`Cannot directly set attached state data ${name}`);
        } else if (value === prevState[name]) {
          return prevState;
        } else if (value === undefined) {
          const nextState = {...prevState};
          delete nextState[name];
          return nextState;
        } else {
          return {...prevState, [name]: value};
        }
      }
      case ACTION_PATCH_DATA: {
        let hasChange = false;
        for (const [key, value] of Object.entries(data)) {
          if (this._attachedStates[key] !== undefined) {
            throw new Error(`Cannot patch over attached state "${key}"`);
          } else if (value !== prevState[key]) {
            hasChange = true;
            break;
          }
        }
        if (hasChange) {
          return {...prevState, ...data};
        } else {
          return prevState;
        }
      }
      case ACTION_PATCH_VALUE: {
        const {name, value} = data;
        const prevValue = prevState[name] || {};
        if (this._attachedStates[name]) {
          throw new Error(`Cannot directly patch attached state data ${name}`);
        } else if (value === undefined || value === null) {
          return prevState;
        } else {
          return {...prevState, [name]: {...prevValue, ...value}};
        }
      }
      case ACTION_ATTACHED_STATE_SET: {
        const {name, value} = data;
        if (!this._attachedStates[name]) {
          throw new Error(`Attached state does not exist ${name}`);
        } else if (value === prevState[name]) {
          return prevState;
        } else {
          return {...prevState, [name]: value};
        }
      }
      case ACTION_ATTACHED_STATE_DEL: {
        const name = data;
        if (!this._attachedStates[name]) {
          throw new Error(`Attached state does not exist ${name}`);
        } else {
          const nextState = {...prevState};
          delete nextState[name];
          return nextState;
        }
      }
      default: {
        throw new Error(`Unrecognized action: ${action}`);
      }
    }
  }

  /**
   * Walks `path` and gets the value stored at the end.
   *
   * This does not make any assumptions about the shape of the data not does it
   * do much in the way of type checking. If it fails to walk a value, it will
   * treat the child as undefined.
   *
   * If the returned value would otherwise be undefined, the `defaultValue` will
   * be used instead.
   */
  getValue(path: string, defaultValue?: any): any {
    const parsed = pathParse(path);
    const attachedState = this._attachedStates[parsed.localKey];
    if (attachedState) {
      return attachedState.getValue(parsed.childPath, defaultValue);
    } else {
      return deepGet(this._data, path, defaultValue);
    }
  }

  /**
   * This will return EITHER the attached state value OR the internal value. If
   * neither are populated, this will return null.
   *
   * In general, it's preferable to use `getValue` which returns the plain data
   * rather than the attached state. This primarily exists for AppShare.
   */
  getRealValue(path) {
    const parsed = pathParse(path);
    const key = parsed.localKey;
    if (!parsed.isLocal) {
      throw new Error(`Can only return local "state values"`);
    } else if (this._attachedStates[key]) {
      return this._attachedStates[key];
    } else if (this._data[key]) {
      return this._data[key];
    }
    return null;
  }

  /**
   * Attaches `state` at name. Note that `path` must be local.
   *
   * Note that this is technically compatible with DataEventers as well. You can
   * attach a DataEventer to an FState so that it will contain the
   * DataEventer's data.
   *
   * By default, any attached states that are present when this state is
   * destroyed will be destroyed as well. To prevent this, attach with the
   * `external` argument set to true. In that case, it is assumed that other
   * code "owns" the attached state and will be responsible for cleaning it
   * up.
   */
  attachState(path, state, external) {
    const parsed = pathParse(path);
    const key = parsed.localKey;
    if (!parsed.isLocal) {
      throw new Error('Can only attach state locally (at top level)');
    } else if (this._attachedStates[key]) {
      throw new Error(`There is already a state attached at ${key}`);
    } else if (this._data[key]) {
      throw new Error(`Cannot attach state that overwrites a value ${key}`);
    }
    this._attachedStates[key] = state;
    this._attachedStateIsExternal[key] = !!external;
    this._attachedStateUnsubscribes[key] = state.onData(
      (data) => this._applyImmediate([{
        action: ACTION_ATTACHED_STATE_SET,
        data: {name: key, value: data},
      }]),
      {immediate: true},
    );
  }

  /**
   * Detaches the the state at a certain "path" and returns it.
   */
  detachState(path) {
    const parsed = pathParse(path);
    const key = parsed.localKey;
    if (!parsed.isLocal) {
      throw new Error('Can only detach local state');
    } else if (!this._attachedStates[key]) {
      throw new Error(`There is no state attached at ${key}`);
    }
    this._applyImmediate([{
      action: ACTION_ATTACHED_STATE_DEL,
      data: key,
    }]),
    this._attachedStateUnsubscribes[key]();
    const state = this._attachedStates[key];
    delete this._attachedStateUnsubscribes[key];
    delete this._attachedStateIsExternal[key];
    delete this._attachedStates[key];
    return state;
  }

  /**
   * Returns the state attached at `path`, or null
   */
  getAttachedState(path) {
    const parsed = pathParse(path);
    const key = parsed.localKey;
    if (!parsed.isLocal) {
      throw new Error(`Requested state (${path}) is not local`);
    } else if (!this._attachedStates[key]) {
      return null;
    }
    return this._attachedStates[key];
  }

  /**
   * Generates an object containing all of the attached state data. This is
   * mainly meant to be used internally to ensure that things like set
   * data/reset don't overwrite the attached state data.
   */
  getCombinedAttachedStateData() {
    const attachedStateData = {};
    for (const [key, state] of Object.entries(this._attachedStates)) {
      attachedStateData[key] = state.data;
    }
    return attachedStateData;
  }

  /**
   * Checks if callback is subscribed at path. Mostly useful for internal
   * checks.
   */
  protected _isSubscribed(callback: DataCallbackType, path?: string): boolean {
    path = path !== undefined ? path : _ROOT_PATH;
    const pathSubs = this._subscribers.get(path);
    if (!pathSubs) {
      return false;
    }
    const handler = pathSubs.get(callback);
    return !!handler;
  }

  /**
   * Subscribe to receive callbacks whenever the value at `path` changes.
   *
   * If `immediate` is specified, the callback will be called immediately.
   *
   * The callback will receive 3 arguments, although most will only utilize the
   * first. They are:
   *
   * 1. The current (new) value
   * 2. The previous value. Note that this is _at the time of the update_.
   *    It means that for `immediate` calls this will always be `undefined`.
   *    It also means that if a `condition` is provided, that you may "miss"
   *    some old values.
   * 3. A reference to the FState itself. This allows for things like recursive
   *    state updates to be performed.
   *
   * The same callback (via ===) CANNOT be subscribed multiple times to the same
   * path, even if they specify different `options`.
   */
  subscribe(callback: DataCallbackType, options?): UnsubscribeType;
  subscribe(path: string, callback: DataCallbackType, options?): UnsubscribeType;
  subscribe(
    callbackOrPath: string | DataCallbackType,
    optionsOrCallback: DataCallbackType | any,
    options?
  ): UnsubscribeType {
    this.checkDestroyed();

    // Shim layer for compatibility with eventlib. This is deprecated
    let callback: DataCallbackType;
    if (typeof(callbackOrPath) === 'string') {
      options = options || {};
      options.path = callbackOrPath;
      callback = optionsOrCallback;
      console.info('fstate: Called subscribe with Eventer arguments', {name: this.name});
    } else {
      callback = callbackOrPath;
      options = optionsOrCallback;
    }

    // Type safety. In the future, may want to only check if `DEBUG` is true.
    if (typeof(callback) !== 'function') {
      throw new Error(`Expected function callback, got: ${callback}`);
    }

    options = options || {};
    const path = options.path || _ROOT_PATH;
    const immediate = options.immediate || false;
    const condition = options.condition || null;
    const parsedPath = pathParse(path);
    const pathStr = parsedPath.path;

    let debounceConfig = null;
    if (options.debounce === null || options.debounce === undefined) {
      debounceConfig = null;
    } else if (debounce === true) {
      debounceConfig = DEFAULT_DEBOUNCE_CONFIG;
    } else if (typeof options.debounce === 'number') {
      debounceConfig = {
        wait: options.debounce,
        options: {maxWait: Math.max(options.debounce * 2, 50)}
      };
    } else {
      debounceConfig = options.debounce;
    }

    let pathSubs = this._subscribers.get(pathStr);
    if (!pathSubs) {
      // According to MDN, `Map` values insertion order.
      pathSubs = new Map();
      this._subscribers.set(pathStr, pathSubs);
    }

    if (!pathSubs.has(callback)) {

      const debouncedCallback = (
        debounceConfig
        ? debounce(callback, debounceConfig.wait, debounceConfig.options)
        : callback
      );

      const conditionalCallback = (newValue, oldValue) => {
        if (condition && !condition(newValue, oldValue)) {
          if (DEBUG) console.log(`fstate: Skipping update at ${pathStr} due to condition`, {name: this.name, condition: callback, value: newValue});
          return;
        }
        callback(newValue, oldValue, this);
      }

      pathSubs.set(callback, conditionalCallback);

      if (immediate) {
        const immediateValue = this.getValue(pathStr, undefined);
        if (!condition || condition(immediateValue, undefined)) {
          callback(immediateValue, undefined, this);
        }
      }

    } else {
      console.warn(`fstate: Already subscribed at ${pathStr}`, {name: this.name, callback: callback});
    }

    return this.unsubscribe.bind(this, callback, path);;
  }

  /**
   * Unsubscribes the specified callback function from listening on path.
   *
   * Typically, you don't need to call this, instead calling the `stop` function
   * returned by subscribe.
   */
  unsubscribe(callback, path) {
    if (this._destroyed) {
      // Since destroying a state inherently unsubscribes everything,
      // unsubscribing after destruction is a no-op. It usually happens because
      // clenaup functions are added in a strange order. Because of this, we
      // warn while debugging to make it easier to track down.
      if (DEBUG) console.warn('fstate: Attempted to unsubscribe after state destroyed.', {name: this.name});
      return;
    }
    path = path || _ROOT_PATH;
    const pathStr = pathParse(path).path;
    let pathSubs = this._subscribers.get(pathStr);
    if (!pathSubs || !pathSubs.has(callback)) {
      console.log('fstate: Callback not registered', {name: this.name, path: path, callback: callback});
    }
    pathSubs.delete(callback);
    if (pathSubs.size === 0) {
      this._subscribers.delete(pathStr);
    }
  }

  /**
   * Convenience wrapper for `subscribe` that is intended to "sync" the state
   * data via `callback`.
   */
  syncData(callback) {
    if (DEBUG && typeof(callback) !== 'function') {
      throw new Error('Callback must be a function');
    }
    const options = {
      path: _ROOT_PATH,
      immediate: true,
    };
    return this.subscribe(callback, options);
  }

  /**
   * Convenience wrapper for `subscribe` that is intended to "sync" a value in
   * the state via `callback`.
   */
  syncValue(path, callback) {
    if (DEBUG && typeof(callback) !== 'function') {
      throw new Error('Callback must be a function');
    }
    const options = {
      path: path,
      immediate: true,
    };
    return this.subscribe(callback, options);
  }

  /**
   * Awaits until the data at `path` causes `condition` to return true.
   *
   * The goal of this is to handle a common case where code will be waiting for
   * a condition in the state to become true. This will have robust error
   * handling and (TODO: add this) a timeout feature to make sure that we don't
   * overwhelm subscribers any more than we have to.
   *
   * It is acceptible to have `condition` throw an error. In that case, the
   * error will be re-raised by this function.
   */
  async waitForCondition(condition, options?) {
    options = options || {};
    const path = options.path || _ROOT_PATH;
    const timeoutMs = options.timeoutMs || null;
    // Short-circuit if condition is already met
    let initValue = this.getValue(path);
    if (condition(initValue)) {
      if (DEBUG) console.debug('fstate: Wait condition immediately met', path, initValue, condition);
      return initValue;
    }
    // Set up a promise that will wait for the condition/timeout. Note that we
    // don't use `condition` in this case because we want to reject with an
    // exception if the condition throws one.
    const promise = new Promise((resolve, reject) => {
      let unsubscribe = this.subscribe(path, (value) => {
        try {
          if (condition(value)) {
            if (DEBUG) console.debug('fstate: Wait condition met', path, value, condition);
            unsubscribe();
            if (timeoutId) {
              clearTimeout(timeoutId);
            }
            resolve(value);
          } else if (DEBUG) {
            console.debug('fstate: Wait condition not yet met', path, value, condition);
          }
        } catch (err) {
          if (DEBUG) console.debug('fstate: Wait condition threw an error', path, value, err);
          unsubscribe();
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          reject(err);
        }
      }, {
        condition: condition,
      });
      let timeoutId = null;
      if (timeoutMs) {
        timeoutId = setTimeout(() => {
          unsubscribe();
          console.warn('fstate: Timeout exceeded waiting for condition', path, condition);
          reject(new TimeoutError('Condition did not occur withing required time'));
        }, timeoutMs);
      }
    });
    return await promise;
  }

  /**
   * Immediately applies the specified action.
   *
   * In general, you should call `apply` or `applyMany`.
   *
   * This allows for multiple actions to be applied in series. Doing so will
   * only trigger listeners after the last action is applied, comparing the the
   * initial state to the final state. While this is still not as efficient as
   * creating proper, bulk reducers, it can incrementally improve performance
   * in cases where multiple state updates need to be done "simultaneously".
   * It's important to note that intermediate states are, effectively, lost
   * during this process.
   */
  _applyImmediate(dataActions) {
    this.checkDestroyed();
    let curData = this._data;
    let actionNum = 0;
    for (const {action, data} of dataActions) {
      if (!action) {
        throw Error('Must provide actions in the form {action, data}');
      }
      actionNum += 1;
      const nextData = this.reduce(curData, action, data);
      if (DEBUG && WARN_UNCHANGED_STATE && nextData === curData) {
        console.warn('fstate: State is unchanged after apply', {name: this.name, action: action, data: data, curState: curData});
      } else if (DEBUG && WARN_UNCHANGED_STATE && slowObjectCompare(nextData, curData)) {
        console.warn('fstate: State appears unchanged after apply', {name: this.name, action: action, data: data, curState: curData});
      }
      if (DEBUG) console.debug(`fstate: Applying action ${actionNum} of ${dataActions.length}`, {name: this.name, action: action, data: data, newState: nextData, oldState: curData});
      curData = nextData;
    }
    if (curData !== this._data) {
      const prevData = this._data;
      this._data = curData;
      this._publishChanges(curData, prevData);
      this.afterDataPublish(curData, prevData);
      if (DEBUG) console.debug('fstate: Published state changes', {name: this.name, newState: curData, oldState: prevData});
    }
  }

  /**
   * Publishes changes to subscribers.
   *
   * Note that there are DEFINITELY some things we can do to make this more
   * efficient. For example, we can have a tree of subscribers. We know that if
   * a parent value hasn't changed, then a child value hasn't either. Therefore
   * we can do a breadth-first search for changes where we stop checking
   * children if a given parent was unchanged.
   */
  _publishChanges(state, prevState) {
    const handlersToProcess = [];
    for (const [pathStr, handlers] of this._subscribers.entries()) {
      const value = deepGet(state, pathStr);
      const prevValue = deepGet(prevState, pathStr);
      if (value !== prevValue) {
        for (const [callback, handler] of handlers.entries()) {
          handlersToProcess.push([pathStr, value, prevValue, callback, handler]);
        }
      }
    }

    if (handlersToProcess.length === 0) {
      return;
    } else if (handlersToProcess.length >= MAX_SUB_WARNING) {
      console.warn(`fstate: Batch of changes triggered ${handlersToProcess.length} subscribers`, {name: this.name});
    }

    for (const [pathStr, value, prevValue, callback, handler] of handlersToProcess) {
      // Check if the handler is still subscribed. This covers the case here a
      // previous handler in this publish resulted in this handler no longer
      // being subscribed.
      if (!this._isSubscribed(callback, pathStr)) {
        if (DEBUG) console.info(`fstate: Callback skipped at ${pathStr} since it has unsubscribed`, {name: this.name});
        continue;
      }
      // Run the handler
      try {
        handler(value, prevValue, this);
      } catch (err) {
        console.error(`fstate: Callback failed at ${pathStr}`, {name: this.name, err: err});
      }
    }
  }

  /**
   * Applies the action using the specified `data`
   *
   * Note that this is not guaranteed to happen synchronously. It might, but if
   * there is high load across all mini states, updates made via this function
   * may be delayed.
   */
  apply(action: string, data?) {
    this.checkDestroyed();
    const start = (WARN_LONG_APPLY) ? performance.now() : null;
    this._applyImmediate([{
      action: action,
      data: data,
    }]);
    if (WARN_LONG_APPLY) {
      const totalMs = performance.now() - start;
      if (totalMs > 12) {
        console.warn(`fstate: Long apply, ${totalMs.toFixed(2)} ms (${action})`, {name: this.name, data: data});
      }
    }
  }

  /**
   * Like `apply` except that it uses bulk application
   *
   * See the description for `_applyImmediate` for more information about how
   * bulk actions work.
   *
   * Essentially, this will defer all of the state change listeners until after
   * ALL actions have been applied (and the listeners only check initial vs.
   * final state). This can result in big performance gains if there are
   * expensive listeners, such as those that trigger React re-renders.
   */
  applyMany(dataActions) {
    this.checkDestroyed();
    const start = (WARN_LONG_APPLY) ? performance.now() : null;
    this._applyImmediate(dataActions);
    if (WARN_LONG_APPLY) {
      const totalMs = performance.now() - start;
      if (totalMs > 12) {
        console.warn(`fstate: Long applyMany, ${totalMs.toFixed(2)} ms (${dataActions.length} actions)`, {name: this.name, dataActions: dataActions});
      }
    }
  }

  /**
   * Convenience function for calling the set value action
   */
  setValue(name, value) {
    this.apply(ACTION_SET_VALUE, {name: name, value: value});
  }

  /**
   * Checks the current value of `name` against the supplied value (using simple
   * `===`). If the value has changed, spply the set value action. Otherwise do
   * nothing. This is a convenience wrapper to save a couple of lines of code
   * while also preventing a bunch of unecessary state updates.
   */
  setValueIfChanged(name, value) {
    if (this.getValue(name) !== value) {
      this.apply(ACTION_SET_VALUE, {name: name, value: value});
    }
  }

  /**
   * Convenience function for calling the patch value action
   */
  patchValue(name, value) {
    this.apply(ACTION_PATCH_VALUE, {name: name, value: value});
  }

  /**
   * Convenience function for resetting data to the `initialData` value. Note
   * that this does NOT reset any attached states, so there is a chance that
   * reset data may be different between calls.
   */
  resetData() {
    this.apply(ACTION_RESET);
  }

  /**
   * Convenience function for calling the set data action. Note that this does
   * NOT set/override any attached states, so the underlying data will still
   * contain attached state data.
   */
  setData(data) {
    this.apply(ACTION_SET_DATA, data);
  }

  /**
   * Like `setData` except that it first performs a shallow equality check
   * between the new data and the old data. If they are the same, do not
   * set the value (since it would very likely be a no-op). This can be useful
   * for cases with a small number of fields, like async states, to reduce
   * subscriber noise.
   */
  setDataIfChanged(data) {
    if (!shallowEqual(data, this._data)) {
      this.setData(data);
    }
  }

  /**
   * Convenience function for calling the patch data action
   */
  patchData(data) {
    this.apply(ACTION_PATCH_DATA, data);
  }

  /**
   * In general, it's a good idea to cleanup a state before getting rid of it.
   * Doing so will remove any listeners that it may have on attached states.
   */
  destroy() {
    // Unsubscribe from child states and destroy them. To preserve a child
    // state, either attach is using the `external` argument or detach it
    // before destroying its parent.
    for (const [key, value] of Object.entries(this._attachedStates)) {
      this._attachedStateUnsubscribes[key]();
      if (!this._attachedStateIsExternal[key]) {
        value.destroy();
      }
    }
    // Clear out all of the internal data to allow it to be garbage collected
    this._attachedStates = null;
    this._attachedStateIsExternal = null;
    this._attachedStateUnsubscribes = null;
    this._data = {};
    this._subscribers = null;
    this._destroyed = true;
    this.$bdestroy();
  }

  /**
   * Throw an error if the state has been destroyed. This will help make sure
   * that things don't accidentally try to work with a destroyed state.
   */
  checkDestroyed() {
    if (this._destroyed) {
      throw new Error('This state has been destroyed');
    }
  }

  /**
   * A virtual function that can be used by derivative classes to perform some
   * action any time internal data is updated without needing to subscribe.
   */
  afterDataPublish(data, prevData) {
    return;
  }


  /////////////////////////////////////////////////////////////////////////////
  // COMPATIBILITY
  // These functions are compatibility wrappers between eventlib and fstate.
  // Once everything has been consolidated into fstate, then these should be
  // fully deprecated and removed.

  close() {
    this.destroy();
  }

  getState() {
    return this.data;
  }

  onData(callback, options) {
    return this.subscribe(callback, options);
  }

}


if (GLOBAL_EXPORT) window['FState'] = FState;
