import AppReactComponent from 'utils/app-react-component.js';
import CanvasEngine from 'canvasengine/canvas-engine';
import FrameUpdateContext from 'canvasengine/frame-update-context';
import ScriptGameObject from 'canvasengine/gameobjects/script-game-object';

interface PartialPropsType {
  canvasEngine?: CanvasEngine | null
}


/**
 * A special type of AppReactComponent that is meant to interoperate with the
 * CanvasEngine
 */
export default abstract class
  CanvasEngineInteropComponent<P extends PartialPropsType, S, SS>
  extends AppReactComponent<P, S, SS>
{

  protected _engine: CanvasEngine | null = null;
  protected _script: ScriptGameObject;

  constructor(props: P) {
    super(props);
    this._script = new ScriptGameObject(this.$b.onUpdate);
    this.addSetup(() => {
      if (this.props.canvasEngine) {
        this._script.attach(this.props.canvasEngine, "special");
        this._engine = this.props.canvasEngine;
      }
    });
  }

  componentDidUpdate(prevProps: P, prevState: S): void {
    super.componentDidUpdate(prevProps, prevState);
    // Attach/detach the script game object from the specified canvas engine.
    const nextEngine = this.props.canvasEngine || null;
    if (nextEngine !== this._engine) {
      if (this._engine) {
        this._script.detach();
      }
      if (nextEngine) {
        this._script.attach(nextEngine, "special");
      }
      this._engine = nextEngine;
    }
  }

  /**
   * Abstract function called each time the underlying CanvasEngine updates.
   *
   * This should be the place the the component interacts with the underlying
   * canvasengine, if needed. It can use this time to update its internal state
   * based on canvas engine objects, etc.
   */
  abstract onUpdate(frameUpdateContext: FrameUpdateContext): void;

}
