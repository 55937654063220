import React from 'react';
import PropTypes from 'prop-types';

import {Button} from '@salesforce/design-system-react';
import {Input} from '@salesforce/design-system-react';

import AppGlobal from 'global';
import ReactComponent from 'utils/react-component';


export default class CampaignMemberLoginLink extends ReactComponent {

  static propTypes = {
    loginUrl: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {isHidden: true};
  }

  getLoginUrl() {
    return `${AppGlobal.rootRouter.origin}${this.props.loginUrl}`;
  }

  showUrl() {
    this.setState({isHidden: false});
  }

  hideUrl() {
    this.setState({isHidden: true});
  }

  copyUrl() {
    navigator.clipboard.writeText(this.getLoginUrl());
  }

  render() {
    const props = this.props;
    const state = this.state;
    return (
      <div>
        {/* if */(state.isHidden) ? (
          <React.Fragment>
            <Input
              readOnly={true}
              value="(Hidden)"
            />
            <Button
              label='Show Link'
              variant='neutral'
              onClick={this.$b.showUrl}
            />
            <Button
              label='Copy Link'
              variant='neutral'
              onClick={this.$b.copyUrl}
            />
          </React.Fragment>
        )/* else */ : (
          <React.Fragment>
            <Input
              readOnly={true}
              value={this.getLoginUrl()}
            />
            <Button
              label='Hide Link'
              variant='neutral'
              onClick={this.$b.hideUrl}
            />
            <Button
              label='Copy Link'
              variant='neutral'
              onClick={this.$b.copyUrl}
            />
          </React.Fragment>
        )/* endif */}
      </div>
    );
  }

}
