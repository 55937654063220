import Squares from 'utils/squares';
import BackgroundImage from 'utils/graphics/background-image';
import AppGlobal from 'global';
import canvasengine from 'canvasengine';

const MAP_CANVAS_BUFFER_SIZE = 0.4;

// How far beyond the visible area is a user allowed to scroll. Must be less
// than 0.5 (TODO: why?)
const BEYOND_VISIBLE_AREA_FACTOR = 0.3;

// The frequency (in frames) with which the controller will poll the container
// and adjust the canvas size if necessary.
//
// TODO: Like other things, this can be adjusted based on graphics intensity
// settings.
const VIEW_SIZE_POLL_PERIOD_FRAMES = 12;

// Cache for `_getBackgroundImage`
let _cachedBackgroundImage = null;


/**
 * Single-value cache for background images. Makes it slightly faster to
 * navigate between zones that share a background image.
 */
function _getBackgroundImage(document, imageUrl, width, height) {
  if (
    _cachedBackgroundImage !== null &&
    _cachedBackgroundImage.url === imageUrl &&
    _cachedBackgroundImage.width === width &&
    _cachedBackgroundImage.height === height &&
    !_cachedBackgroundImage.isDestroyed
  ) {
    return _cachedBackgroundImage;
  }
  const nextBackgroundImage = new BackgroundImage(document, imageUrl, width, height);
  if (_cachedBackgroundImage) {
    _cachedBackgroundImage.destroy();
  }
  _cachedBackgroundImage = nextBackgroundImage;
  return nextBackgroundImage;
}


/**
 * Compatibility layer between the MapViewController and the canvas engine
 */
class _MapViewGameObject extends canvasengine.GameObject {

  constructor(controller) {
    super();
    this.controller = controller;
  }

  isVisible() {
    return (
      this.controller._backgroundImage &&
      this.controller._backgroundImage.isReady &&
      super.isVisible()
    );
  }

  onUpdate() {
  }

  onRender(frameRenderContext) {
    const frame = frameRenderContext.frame;
    if (!frame.isKeyframe) {
      return;
    }
    const context = frameRenderContext.context;
    const sourceRect = frame.sourceRect;
    const targetRect = frame.targetRect;
    if (this.controller._imageSmoothing) {
      context.imageSmoothingEnabled = true;
      context.imageSmoothingQuality = this.controller._imageSmoothing;
    }
    // context.fillRect(renderSq.x, renderSq.y, renderSq.w, renderSq.h);
    this.controller._backgroundImage.drawDirect(
      context,
      sourceRect.x,
      sourceRect.y,
      targetRect.x,
      targetRect.y,
      targetRect.w,
      targetRect.h,
      frame.scale,
    );
  }

}


// TODO: Need to make the canvas work properly for high pixel density displays.
// See https://www.html5rocks.com/en/tutorials/canvas/hidpi/
// or https://www.kirupa.com/canvas/canvas_high_dpi_retina.htm

export default class MapViewController {

  constructor(document, rootElement) {
    this._document = document;
    this.root = rootElement;
    this._destroyed = false;
    this._pixelRatio = AppGlobal.settings.value('graphics.pixelRatio') || window.devicePixelRatio || 1;
    this._debugMapCanvasRoot = null;
    this._debugThumbCanvasRoot = null;
    this._fogColor = '#222';
    this._viewElem = null;
    this._viewWidth = null;
    this._viewHeight = null;
    this._curViewX = 0;
    this._curViewY = 0;
    this._curViewZoom = 1.0 * this._pixelRatio;
    this._backgroundImage = null;
    this._backgroundImageUrl = null;
    this._imageWidth = null;
    this._imageHeight = null;
    this._visibleBoundarySq = null;
    // Min and max zoom should be based on the image itself (DPI). Note that
    // the min zoom will also be constrained by the virtual map bounds of what's
    // revealed (via `setVisibleBoundary`)
    this._minZoom = 0.1 * this._pixelRatio;
    this._maxZoom = 4 * this._pixelRatio;
    this._canvasLayers = [
      'map',
      'tokens',
      'fog',
      'interface',
    ];
    this._frameHandlerCallbackId = null;
    this.onViewUpdate = null;
    this.onImageLoaded = null;

    this._imageSmoothing = false;  // Values are false | 'low' | 'medium' | 'high'
    if (AppGlobal.settings.value('graphics.quality') >= 4) {
      this._imageSmoothing = 'medium';
    } else if (AppGlobal.settings.value('graphics.quality') >= 3) {
      this._imageSmoothing = 'low';
    }
  }

  get engine() {
    return this._engine;
  }

  get isDestroyed() {
    return this._destroyed;
  }

  get curX() {
    return this._curViewX;
  }

  get curY() {
    return this._curViewY;
  }

  get curZoom() {
    return this._curViewZoom;
  }

  get pixelRatio() {
    return this._pixelRatio;
  }

  initialize = () => {
    console.log('map-view-controller: Initializing controller with root', this.root);
    this._recalculateSize();
    this._initializeViewElement();
    // TODO: Eventually we want to invert this so that the map is simply
    // attached to an existing CanvasEngine rather than the map view
    // controlling the canvas engine. For now, though, this is fine since at
    // least most of the functionality is kept separate.
    this._engine = new canvasengine.CanvasEngine({
      viewCanvas: this._viewElem,
      layerNames: [...this._canvasLayers],
    });
    this._engine.initialize();
    const mapGameObject = new _MapViewGameObject(this);
    const sizeRefreshGameObject = new canvasengine.ScriptGameObject(this._viewSizePoll);
    mapGameObject.attach(this._engine, 'map');
    sizeRefreshGameObject.attach(this._engine, 'special');
    // TODO: Do we need to save these to local state?
  }

  destroy = () => {
    console.log('map-view-controller: Destroy controller with root', this.root);
    this._engine.destroy();
    this._engine = null;
    // Do not destroy background image because it is memoized
    this._backgroundImage = null;
    this._mapObjects = null;
    this._destroyed = true;
  }

  _throwIfDestroyed = () => {
    if (this._destroyed === true) {
      throw new Error('The operation cannot be performed after a map view has been destroyed');
    }
  }

  // DEPRECATED: User CanvasEngine.setFocusPoint()
  setFocusPoint = (offsetX, offsetY) => {
    this._engine.setFocusPoint(offsetX, offsetY);
  }

  /**
   * Converts coordinates in the view (such as those generated by a UI event)
   * into their corresponding coordinates on the underlying map as a 2-tuple.
   */
  viewToMapCoordinates = (x, y) => {
    return [
      (this._pixelRatio * x / this._curViewZoom) + this._curViewX,
      (this._pixelRatio * y / this._curViewZoom) + this._curViewY,
    ];
  }

  /**
   * Gets a Squares object that represents that RAW image (not scaled through
   * the view). This is sometimes necessary for making sure that things
   * are constrained inside of the image.
   */
  getImageSquare = () => {
    return this._imageSquare;
  }

  // DEPRECATED: User CanvasEngine.getCursorX()
  getCursorX = () => {
    return this._engine.getCursorX();
  }

  // DEPRECATED: User CanvasEngine.getCursorX()
  getCursorY = () => {
    return this._engine.getCursorY();
  }

  setDebugCanvasRoots = (mapCanvasRoot, thumbCanvasRoot) => {
    this._debugMapCanvasRoot = mapCanvasRoot;
    this._debugThumbCanvasRoot = thumbCanvasRoot;
  }

  /**
   * Sets the image that will be used as the background of the map view.
   *
   * It is necessary to pass `imgWidth` and `imgHeight` to handle low-memory
   * devices that may load (either on purpose or due to a chrome
   * "intervention") a lower resolution version of the map. Given that grids
   * align to maps based on pixel values, we need to know the real size of the
   * original image (the browser is unreliable in this regard)
   */
  setImage = async (imgUrl, imgWidth, imgHeight) => {
    this._throwIfDestroyed();
    // Just wait if we're already loading the same image
    if (this._backgroundImage && this._backgroundImage.url === imgUrl) {
      console.log(`map-view-controller: Loading image (${this._backgroundImage._name})`, imgUrl);
      await this._backgroundImage.load();
      return;
    }
    // Destroy existing background image if we are changing it
    if (this._backgroundImage) {
      console.log(`map-view-controller: Destroying image (${this._backgroundImage._name})`);
      this._backgroundImage.destroy();
    }
    const nextBackgroundImage = _getBackgroundImage(
      this._document, imgUrl, imgWidth, imgHeight
    );
    this._backgroundImage = nextBackgroundImage;
    console.log(`map-view-controller: Loading new image (${this._backgroundImage._name})`, imgUrl);
    await nextBackgroundImage.load();
    if (this._backgroundImage === nextBackgroundImage) {
      this._imageWidth = this._backgroundImage.width;
      this._imageHeight = this._backgroundImage.height;
      this._imageSquare = this._backgroundImage.rect;
      this._visibleBoundarySq = this._imageSquare;
      this.fullMapRerender();
      if (this.onImageLoaded) {
        this.onImageLoaded(this._backgroundImage.image);
      }
    }
    return;
  }

  _getViewSquare = () => {
    return Squares.fromDimensions(
      this._curViewX,
      this._curViewY,
      this._pixelRatio * this._viewWidth,
      this._pixelRatio * this._viewHeight
    );
  }

  _getViewedImageSquare = () => {
    return Squares.invScale(
      this._getViewSquare(),
      this._curViewZoom.curValue,
    );
  }

  /**
   * Sets a boundary of what should actually be visible for the map. By
   * default, this is the size of the underlying image, but may be smaller
   * based on how much of that image has been revealed.
   */
  setVisibleBoundary = (x1, y1, x2, y2) => {
    this._throwIfDestroyed();
    this._visibleBoundarySq = Squares.fromCoordinates(x1, y1, x2, y2);
    this.refreshView();
  }

  refreshMap = () => {
    this._throwIfDestroyed();
    this.fullMapRerender();
  }

  /**
   * Performs a simple refresh of the view synchronously using the last
   * location the view was updated at. Functionally equivalent to calling
   * `panView(0, 0)` except that it is faster due to no coordinate
   * calculations need to occur.
   */
  refreshView = () => {
    this._throwIfDestroyed();
    this.updateView(this._curViewX, this._curViewY, this._curViewZoom);
  }

  /**
   * Pans the view, shifting x and y by the specified amounts. This accounts
   * for the current zoom. It also handles view constraints.
   */
  panView = (dx, dy) => {
    this._throwIfDestroyed();
    let newX = this._curViewX + (this._pixelRatio * dx / this._curViewZoom);
    let newY = this._curViewY + (this._pixelRatio * dy / this._curViewZoom);
    this.updateView(newX, newY, this._curViewZoom);
  }

  /**
   * Zoom deltas are usually on the order of 0.002 / frame.
   *
   * TODO: need to allow for a point other than center to be used.
   */
  zoomView = (dzoom, focusVPos, focusHPos) => {
    this.zoomViewAbsolute(
      this._curViewZoom + (dzoom * this._curViewZoom), focusVPos, focusHPos
    );
  }

  /**
   * Zooms the view to a specific value, adjusting the viewport so that the
   * (optional) position focusVPos, focusHPos appear to remain stationary.
   * If not provided, focuses on the center of the screen. If provided, they
   * should be a fraction of the viewport (0.0 - 1.0)
   */
  zoomViewAbsolute = (newZoom, focusVPos, focusHPos) => {
    this._throwIfDestroyed();
    focusVPos = (focusVPos !== null && focusVPos !== undefined) ? focusVPos : 0.5;
    focusHPos = (focusHPos !== null && focusHPos !== undefined) ? focusHPos : 0.5;
    // Zoom is mostly constrainted in `updateView`, but we do basic checks here
    // to handle a situation where things pan due to zooming while at max
    newZoom = Math.min(this._maxZoom, newZoom);
    newZoom = Math.max(this._minZoom, newZoom);
    let cx = this._curViewX + (this._pixelRatio * focusVPos * this._viewWidth / this._curViewZoom);
    let cy = this._curViewY + (this._pixelRatio * focusHPos * this._viewHeight / this._curViewZoom);
    let newX = cx - (this._pixelRatio * focusVPos * this._viewWidth / newZoom);
    let newY = cy - (this._pixelRatio * focusHPos * this._viewHeight / newZoom);
    this.updateView(newX, newY, newZoom);
  }

  /**
   * Requests that the view be updated. This does not perform the validation
   * or constraints that the other view update functions call. This ensures
   * that the view is only updated on the next animation frame.
   */
  updateView = (x, y, zoom) => {
    this._throwIfDestroyed();
    this._curViewZoom = this._constrainZoom(zoom);  // TODO: right?
    let [newX, newY] = this._constrainLocation(x, y);
    this._curViewX = newX;
    this._curViewY = newY;
    this._engine.setView(this._curViewX, this._curViewY, this._curViewZoom);
  }

  _constrainLocation = (newX, newY) => {
    // If there is no visible boundary, don't allow zooming (this typically only
    // happens when there isn't actually an image or grid loaded)
    if (!this._visibleBoundarySq) {
      return [0, 0];
    }
    let viewSq = Squares.fromDimensions(newX, newY, this._viewWidth, this._viewHeight);
    let viewedImageSq = Squares.scale(viewSq, this._pixelRatio / this._curViewZoom);
    let fieldOfViewSq = Squares.growCentered(
      this._visibleBoundarySq,
      viewedImageSq.w * BEYOND_VISIBLE_AREA_FACTOR * 2,
      viewedImageSq.h * BEYOND_VISIBLE_AREA_FACTOR * 2,
    );
    let newViewedImageSq = Squares.shiftInside(viewedImageSq, fieldOfViewSq);
    return [newViewedImageSq.x, newViewedImageSq.y];
  }

  /**
   * Makes sure that the zoom value is valid, constraining it based on DPI and
   * making sure that it isn't so zoomed out as to reveal more than it should
   * given the visibility bounds.
   *
   * `maxZoom` is incorporated second, so the maximum zoom will always take
   * priority over the minimum zoom given the visible boundaries.
   */
  _constrainZoom = (newZoom) => {
    // If there is no visible boundary, don't allow zooming (this typically only
    // happens when there isn't actually an image or grid loaded)
    if (!this._visibleBoundarySq) {
      return 1;
    }

    let viewSq = Squares.scale(this._getViewSquare(), (1.0 - (BEYOND_VISIBLE_AREA_FACTOR * 2)));
    let fieldOfViewSq = this._visibleBoundarySq;

    // We do `min` here so that you can fit more in a situation where a cooridor
    // is displayed (it doesn't give any extra information because a player
    // is still prevented from panning in that direction and there is enough
    // map to fit in the current zoom)
    let minZoom = Math.min(viewSq.w / fieldOfViewSq.w, viewSq.h / fieldOfViewSq.h);

    newZoom = Math.max(minZoom, newZoom);
    newZoom = Math.min(this._maxZoom, newZoom);
    newZoom = Math.max(this._minZoom, newZoom);
    return newZoom;
  }

  /**
   * Triggers a full re-render of the current display on the next frame.
   */
  fullMapRerender = () => {
    this._throwIfDestroyed();
    this._engine.setView(this._curViewX, this._curViewY, this._curViewZoom);
    this._engine.requestKeyframe();
  }

  _initializeViewElement = () => {
    this._viewElem = this._document.createElement('canvas');
    this._viewElem.style.imageRendering = 'crisp-edges';  // Better performance?
    this._viewElem.style.background = '#000';  // TODO: Fog color
    this._refreshViewSize();
    this.root.appendChild(this._viewElem);
  }

  _refreshViewSize = () => {
    const nextWidth = Math.round(this._viewWidth * this._pixelRatio);
    const nextHeight = Math.round(this._viewHeight * this._pixelRatio);
    const nextViewWidth = Math.round(this._viewWidth);
    const nextViewHeight = Math.round(this._viewHeight);
    if (this._viewElem && (
      this._viewElem.width !== nextWidth ||
      this._viewElem.height !== nextHeight
    )) {
      this._viewElem.width = nextWidth;
      this._viewElem.height = nextHeight;
      // Use CSS to scale down, allowing for higher-density drawings.
      this._viewElem.style.width = `${nextViewWidth}px`;
      this._viewElem.style.height = `${nextViewHeight}px`;
      // If the size has changed, it's a good idea to request a key frame to
      // make sure that all fo the view content adjusts accordingly.
      if (this._engine) {
        this.refreshView();
        this._engine.requestKeyframe();
      }
    }
  }

  _recalculateSize = () => {
    this._viewWidth = Math.floor(this.root.clientWidth);
    this._viewHeight = Math.floor(this.root.clientHeight);
  }

  _viewSizePoll = (frameUpdateContext) => {
    const frameNo = frameUpdateContext.frameNo;
    if (frameNo % VIEW_SIZE_POLL_PERIOD_FRAMES === 0) {
      this._recalculateSize();
      this._refreshViewSize();
    }
  }

}
