import React from 'react';

import UserInfo from 'utils/user-info';
import ReactComponent from 'utils/react-component';

import AppPageTemplate from './app-page-template';


export default class AppPageTemplateConnected extends ReactComponent {

  constructor() {
    super();
    this.state = {
      isLoadingUser: true,
      userInfo: null,
    };
    this.addClosableSetup(() => UserInfo.onUserInfo(this.updateUserInfo));
    this.addSetup(() => UserInfo.load());
  }

  updateUserInfo = (userInfo) => {
    if (!userInfo) {
      this.setState({
        isLoadingUser: true,
        userInfo: null,
      });
    } else {
      this.setState({
        isLoadingUser: false,
        userInfo: userInfo,
      });
    }
  };

  render = () => {
    const props = {
      ...this.props,
      userInfo: this.state.userInfo,
    };
    return (
      <AppPageTemplate {...props} />
    );
  }

}
