import classNames from 'classnames';
import memoizeOne from 'memoize-one';
import React from 'react';

import {Button} from '@salesforce/design-system-react';

import AppReactComponent from 'utils/app-react-component.js';
import Rect from 'utils/rect';

import type {MouseEventHandler} from 'react';

interface PropsType {
  className?: string,
  style?: object,
  mapObject: any,
  onEdit?: (event: MouseEvent, mapObject: any) => void;
  onRemove?: (event: MouseEvent, mapObject: any) => void;
  tokenSpaceH: number,
  tokenSpaceW: number,
  tokenSpaceX: number,
  tokenSpaceY: number,
};

interface StateType {
};

const OVERFLOW_VISIBLE_STYLE = {
  overflow: "visible",
}


/**
 * docstring
 */
export default class MapTokenFocusedControlElem extends AppReactComponent<PropsType, StateType, any> {

  constructor(props: PropsType) {
    super(props);
  }

  preventMouseDown(event) {
    // Prevent Default on mouse down to stop blur from happening. This is needed
    // in some cases so that the token itself doesn't blur.
    event.preventDefault();
  }

  handleRemoveClick(event) {
    if (this.props.onRemove) {
      this.props.onRemove(event, this.props.mapObject);
    }
  }

  handleEditClick(event) {
    if (this.props.onEdit) {
      this.props.onEdit(event, this.props.mapObject);
    }
  }

  render() {
    const props = this.props;
    const token = props.mapObject;
    const spaceRect = Rect.fromDimensions(
      props.tokenSpaceX, props.tokenSpaceY, props.tokenSpaceW, props.tokenSpaceH
    );
    const canWrite = !!token.permissions.canWrite;
    const [mainColor, glowColor] = this._getHighlightColors(token);
    return (
      <React.Fragment>
        <div
          className={classNames(props.className, {
            "canvasengine--interface-region": true,
          })}
          style={this._getSpaceContainerStyle(props.style, ...spaceRect.toXYWH())}
        >
          <svg
            className="canvasengine--clickthrough"
            style={OVERFLOW_VISIBLE_STYLE}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${spaceRect.w} ${spaceRect.h}`}
          >
            <path
              d={this._getRoundedRectPath(0, 0, spaceRect.w, spaceRect.h)}
              fill="none"
              stroke={glowColor}
              strokeWidth="5"
            />
            <path
              d={this._getRoundedRectPath(0, 0, spaceRect.w, spaceRect.h)}
              fill="none"
              stroke={mainColor}
              strokeWidth="2"
            />
          </svg>
        </div>
        <div
          className="canvasengine--label-and-edit-container"
          style={this._getNameLabelStyle(...spaceRect.toXYWH())}
        >
          {/* if */(canWrite) ? (
            <Button
              iconCategory='utility'
              iconName='delete'
              iconSize='medium'
              assistiveText={{icon: 'Remove'}}
              iconVariant='border-filled'
              variant='icon'
              onMouseDown={this.$b.preventMouseDown}
              onClick={this.$b.handleRemoveClick}
            />
          )/* endif */ : null}
          <label
            className="canvasengine--label flex--item-fixed"
          >
            {token.token.shortName || token.name}
          </label>
          {/* if */(canWrite) ? (
            <Button
              iconCategory='utility'
              iconName='edit'
              iconSize='medium'
              assistiveText={{icon: 'Edit'}}
              iconVariant='border-filled'
              variant='icon'
              onMouseDown={this.$b.preventMouseDown}
              onClick={this.$b.handleEditClick}
            />
          )/* endif */ : null}
        </div>
        {/* if */(token.token.description) ? (
          <div
            className="canvasengine--token-description-container"
            style={this._getDescriptionStyle(...spaceRect.toXYWH())}
          >
            <p>{token.token.description}</p>
          </div>
        )/* endif */ : null}
      </React.Fragment>
    );
  }

  _getSpaceContainerStyle = memoizeOne((style, x, y, w, h) => {
    return {
      ...style,
      left: `${x}px`,
      top: `${y}px`,
      width: `${w}px`,
      height: `${h}px`,
      // background: "#FF04",  // Debugging
    };
  });

  _getNameLabelStyle = memoizeOne((x, y, w, h) => {
    return {
      left: `${x + (w / 2)}px`,
      top: `${y + h + 2}px`,
    };
  });

  _getDescriptionStyle = memoizeOne((x, y, w, h) => {
    return {
      left: `${x + (w / 2)}px`,
      top: `${y + h + 2 + 32}px`,
    };
  });

  _getRoundedRectPath = memoizeOne((x, y, w, h) => {
    // Concept lifted from https://stackoverflow.com/a/38118843/703040
    // Radius is somewhat arbitrary. Just trying to scale it down a little bit
    // based on overall size of the box.
    const rad = Math.min(Math.min(w, h) / 2 - 10, 4);
    // If insufficient radius, just return a rect
    if (rad <= 1) {
      return [
        `M${x},${y}`,
        `h${w}`,
        `v${h}`,
        `h-${w}`,
        `v-${h}`,
        "z",
      ].join(" ");
    }
    return [
      `M${x + rad},${y}`,  // Move to starting location
      `h${w - (2 * rad)}`,  // top horizontal line
      `a${rad},${rad} 0 0 1 ${rad},${rad}`,  // upper-right arc
      `v${h - (2 * rad)}`,  // right vertical line
      `a${rad},${rad} 0 0 1 -${rad},${rad}`,  // lower-right arc
      `h-${w - (2 * rad)}`,  // bottom horizontal line
      `a${rad},${rad} 0 0 1 -${rad},-${rad}`,  // lower-left arc
      `v-${h - (2 * rad)}`,  // left vertical line
      `a${rad},${rad} 0 0 1 ${rad},-${rad}`,  // upper-left arc
      "z",  // Close
    ].join(" ");
  });

  _getHighlightColors = memoizeOne((token): [mainColor: string, glowColor: string] => {
    // TODO: Get these from CSS color variables:
    // getComputedStyle(document.documentElement).getPropertyValue('--variable');
    //
    // TODO: Do we want the colors to change based on whether the user can
    // edit (token.permissions.canWrite) or move
    // (token.permissions.canChangeLocation) the token?
    if (token.token.faction === 'HOSTILE_FACTION') {
      return [
        '#F23E3E',
        '#58222299',
      ];
    } else if (token.token.faction === 'PLAYER_FACTION') {
      return [
        '#4B7',
        '#11442299',
      ];
    } else if (token.token.faction === 'ALLY_FACTION') {
      return [
        '#4B7',
        '#11442299',
      ];
    } else if (token.token.faction === 'MYSTERY_FACTION') {
      return [
        '#CCC',
        '#22222299',
      ];
    }
    return [
      '#f1d734',
      '#886a2199',
    ];
  })

}
