import React from 'react';
import classNames from 'classnames';

/**
 * A specialized layout that has optional left and right toolboxes.
 *
 * TODO: Give props options for setting width, whether or not things
 * are collapsable, resizing, etc.
 *
 * TODO: Allow for some special width that lets it just be large
 * enough for internal content? Not actually sure if I like that...
 *
 * TODO: This will need to be responsive - for displays that are
 * too narrow for it to make sense each toolbar should collapse to
 * a floating button and then take up a max of 2/3 of the screen.
 * Similar to a modal,
 */
export default function ToolboxLayout(props) {

  let leftToolbar = null;
  if (props.hasLeftToolbar) {
    const leftToolbarWidth = props.leftToolbarWidth || '200px';
    leftToolbar = (
      <div
        className='toolbox-layout-left'
        style={{width: leftToolbarWidth}}
      >
        {props.leftChildren}
      </div>
    );
  }

  let rightToolbar = null;
  if (props.hasRightToolbar) {
    const rightToolbarWidth = props.rightToolbarWidth || '200px';
    rightToolbar = (
      <div
        className='toolbox-layout-right'
        style={{width: rightToolbarWidth}}
      >
        {props.rightChildren}
      </div>
    );
  }

  return (
    <div
      className={classNames('toolbox-layout-wrapper', props.className)}
      style={props.style}
    >
      {leftToolbar}
      <div className='toolbox-layout-content'>
        {props.children}
      </div>
      {rightToolbar}
    </div>
  );

}
