import RequestTracker from 'utils/request-tracker';
import { common as commonpb } from 'proto-bundle';

const DEBUG = true;

export const CONFIG = {
  // Using JSON as a protocol doesn't work due to incompatibilities in the way
  // that the server (betterproto) expects the json to look compared to the
  // javascript (namely: wrapper values are assumed to be objects with a value
  // property, but the betterproto assumes that they will simply be nullable
  // values).
  useBinaryProtobuf: true,
};

export const requestTrackerInstance = new RequestTracker();


export class AsyncError extends Error {
  constructor(code, message, data, stackTrace) {
    super(message);
    this.code = code;
    this.message = message;
    this.data = data;
    this.stackTrace = stackTrace;
  }
}


export class UnauthenticatedError extends AsyncError {}


/**
 * Sends the provided `xhr` request and returns a promise for when the
 * request has resolved. Resolves and rejects with a reference to the XHR
 * itself.
 */
export function asyncXHRSend(xhr, data) {
  return new Promise((resolve, reject) => {
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr);
      } else {
        reject(xhr);
      }
    };
    xhr.onerror = () => {
      reject(xhr);
    };
    xhr.onabort = () => {
      reject(xhr);
    };
    xhr.send(data);
  });
}


export function fetchAsyncJson(resource, init) {
  if (DEBUG) console.log(`async req: Begin`, resource);
  return fetch(resource, init)
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        // TODO: This should attempt to parse a JSON body first. Also the
        // Message should be trimmed in case it's a whole HTML page.
        return res.text().then((bodyText) => {
          throw new AsyncError(
            res.status,
            `${res.statusText}: ${bodyText}`,
            bodyText,
            null,
          );
        });
      }
    })
    .catch((err) => {
      console.error('TODO: Log this error', err);
      throw err;
    });
}


/**
 * A simple wrapper around `fetchAsyncJson` to handle getting JSON-encoded
 * data from an endpoint.
 */
export function simpleGetAsyncJson(resource) {
  return fetchAsyncJson(resource, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'X-Client-Request-Id': requestTrackerInstance.newRequest(),
    },
  });
}


/**
 * A simple wrapper around `fetchAsyncJson` to handle sending standard JSON
 * data to a post endpoint.
 */
export function simplePostAsyncJson(resource, data) {
  return fetchAsyncJson(resource, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Client-Request-Id': requestTrackerInstance.newRequest(),
    },
    body: JSON.stringify(data),
  });
}


/**
 * A simple wrapper around `fetchAsyncJson` to handle sending standard JSON
 * data to a patch endpoint.
 */
export function simplePatchAsyncJson(resource, data) {
  return fetchAsyncJson(resource, {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Client-Request-Id': requestTrackerInstance.newRequest(),
    },
    body: JSON.stringify(data),
  });
}


/**
 * Factory for generating `callRpc` partial functions that have the URL, and
 * protobuf types filled in.
 */
export function makeRpcCall(url, reqPbType, resPbType, options) {
  if (!options) {
    options = {
      enums: String,
      defaults: true,
      arrays: true,
      objects: true,
    };
  }
  return async function(data) {
    return await callRpc(url, reqPbType, resPbType, options, data);
  }
}


/**
 * Calls the RPC at the given URL, utilizing the protobuf types specified.
 * This is intended to be used with something like `lodash.partial` so that
 * the url and types can be defined once.
 */
export async function callRpc(url, reqPbType, resPbType, options, data) {
  let body = null;
  let contentType = null;
  let reqMessage = reqPbType.fromObject(data);

  let verifyError = reqPbType.verify(reqMessage)
  if (verifyError) {
    console.warn('WARNING: Protobuf verify failed: ' + verifyError);
  }

  if (DEBUG) console.debug(`async: Call RPC ${url}...`, data);
  if (CONFIG.useBinaryProtobuf) {
    let bodyWriter = reqPbType.encode(reqMessage);
    body = bodyWriter.finish();
    contentType = 'application/octet-stream';
  } else {
    body = JSON.stringify(reqMessage.toJSON());
    contentType = 'application/json';
  }

  if (DEBUG) console.debug(`async: Call RPC ${url}...`);
  let httpRes = await fetch(url, {
    method: 'POST',
    headers: {
      'Accept': contentType,
      'Content-Type': contentType,
      'X-Client-Request-Id': requestTrackerInstance.newRequest(),
    },
    body: body,
  });
  if (DEBUG) console.debug(`async: Call RPC ${url} DONE: ${httpRes.status}`);

  if (!httpRes.ok) {
    resPbType = commonpb.Error;
  }

  let resMessage = null;
  try {
    if (httpRes.headers.get('Content-Type') === 'application/octet-stream') {
      let resBuffer = await httpRes.arrayBuffer();
      resMessage = resPbType.decode(new Uint8Array(resBuffer));
    } else {
      let resObj = await httpRes.json();
      resMessage = resPbType.fromObject(resObj);
    }
    if (DEBUG) console.debug(`async: Call RPC ${url} PARSED`, resMessage);
  } catch (err) {
    console.error(`async: ERROR Call RPC ${url} parse error`, err);
    throw err;
  }

  verifyError = resPbType.verify(resMessage)
  if (verifyError) {
    console.warn('async: WARNING Protobuf verify failed: ' + verifyError);
  }

  if (!httpRes.ok) {
    let errCls = AsyncError;
    if (httpRes.status === 401) {
      errCls = UnauthenticatedError;
    }
    const err = new errCls(resMessage.code, resMessage.message, {}, resMessage.stackTrace);
    if (DEBUG) {
      console.error(`Received RCP Error: ${resMessage.message}`, err);
    }
    throw err;
  }
  return resPbType.toObject(resMessage, options);
}


/**
 * Uploads files using a traditional, multi-part form upload.
 *
 * `files` should be a list of files (for example, those from an "onDrop")
 * event, see the reference). `data` can be any additional form data that
 * may be relevant.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications
 */
export async function uploadFiles(url, files, data) {
  const xhr = new XMLHttpRequest();
  const formData = new FormData();
  try {

    for (let i = 0; i < files.length; i++) {
      formData.append(`uploadFile${i}`, files[i]);
    }

    if (data) {
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
    }

    if (DEBUG) console.debug(`async: Uploading files to ${url}`, files);
    // We already have the reference to the XHR, so no need to capture return
    // value or the exception value.
    xhr.open('POST', url, true);
    await asyncXHRSend(xhr, formData);
    if (DEBUG) console.debug(`async: File upload complete`, xhr);
    return xhr;

  } catch (err) {

    // Note that `err` may just be a reference to `xhr`
    if (DEBUG) console.debug(`async: File upload failed`, err);
    throw new AsyncError(0, 'Upload failed', {}, null);

  }
}
