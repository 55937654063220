import React from 'react';
import lodashUniqueId from 'lodash.uniqueid';

export default class SingleSelectField extends React.Component {

  constructor() {
    super();
    this.state = {
      inputId: lodashUniqueId('formField'),
    }
  }

  render() {
    let options = this.props.options || [];
    return (
      <div className='formField formFieldSingleSelect'>
        <label
          id={this.state.inputId + 'Label'}
          className='formFieldLabel formFieldSingleSelectLabel'
          htmlFor={this.state.inputId}
        >
          {this.props.label}
        </label>
        <select 
          id={this.state.inputId}
          className='formFieldInput formFieldSingleSelectInput'
          value={this.props.value}
          onChange={(event) => {
            this.props.onChange(event, {value: event.target.value});
          }}
        >
          {options.map((option) => {
            let [value, label] = option;
            return (
              <option key={value} value={value}>{label}</option>
            );
          })}
        </select>
      </div>
    );
  }

}
