import lodashUniqueId from 'lodash.uniqueid';

import makeBindable from 'utils/bound-methods.js'
import Squares from 'utils/squares';

// A default bounding box used if the implementing class does NOT specify a
// bounding box. Note that this may mess things up if the object does actually
// take up space
const _DEFAULT_BOUNDING_BOX = Squares.fromDimensions(0, 0, 0, 0);


/**
 * A single map object bound to a map view controller
 */
export default class MapObject {

  constructor() {
    this.id = lodashUniqueId('MapObject_');
    this.enabled = true;
    this.visible = true;
    this._mapViewController = null;
    makeBindable(this);
    // TODO: Do we need a property like `cleanRender` to indicate certain
    // map objects that don't require context save/restore when rendered?
  }

  get controller() {
    return this._mapViewController;
  }

  isEnabled() {
    return this.enabled;
  }

  isVisible() {
    return this.visible;
  }

  attach(mapViewController, layer) {
    if (!mapViewController || !layer) {
      throw new Error('Must include a map view controller and layer when attaching');
    }
    if (this._mapViewController) {
      this.detach();
    }
    this._mapViewController = mapViewController;
    this._mapViewController.addMapObject(layer, this);
  }

  detach() {
    const controller = this._mapViewController;
    // controller.removeMapObject(this);
  }

  /**
   * Returns the box that bounds that map object in map-pixel-space (sometimes
   * called source-pixel-space). This may be used to determine things like
   * collisions or whether a given map object may be visible.
   */
  getBoundingBox() {
    return _DEFAULT_BOUNDING_BOX;
  }

  /**
   * Cleans up any open resources required for the object.
   *
   * If an implementing class adds functionality, it is crucial that it call
   * `super.destroy()` so that the object is cleaned up in the parent controller
   */
  destroy() {
    if (this._mapViewController) {
      this.detach();
    }
  }

  /**
   * Called once per frame _before_ any rendering is performed. This is meant
   * for any calculations that need to occur for the object. This function will
   * only be called if the object is `enabled`. Note that this function will
   * be called EVEN IF the object is ultimately not rendered / off screen.
   *
   * IDEA: Could possible allow this to return the explicit value `false` to
   * prevent the object from being rendered this frame.
   */
  onUpdate(timestamp, timeDeltaMs, frameno, render) {
    // Virtual function - implement in derivative class
  }

  /**
   * Called to draw the object onto the render layer.
   *
   * Note that this should ONLY be used for drawing. Depending on system
   * load, position of the view, etc a component may not always have its
   * `onRender` method called. Note that a component will also not have its
   * `onRender` method called unless it is both `enabled` and `visible`
   */
  onRender(context, renderLayer, render) {
    // Virtual function - implement in derivative class
  }

}
